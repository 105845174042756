import { Component, OnInit, Renderer2 } from '@angular/core';
import {GridComponent} from '../grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {Location} from '@angular/common';
import {Style3Pages} from '../../configuration/details/style3-pages';
import { combineLatest } from 'rxjs';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import { filterBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-style3-grid',
  templateUrl: './style3-grid.component.html',
  styleUrls: ['./style3-grid.component.scss']
})
export class Style3GridComponent extends GridComponent implements OnInit {
  dataCols = [];
  dataRows = [];

  style3Pages = Style3Pages;
  config = null;
  public state = RequestState.Inactive;

  public menuItems: any[] = [];
  public offset: any;
  public selected: any;
  public showContextMenu: boolean = false;
  private documentClickSubscription: any;

  constructor(
    protected location: Location,
    protected apiService: ApiService,
    private renderer: Renderer2,
    protected refreshHandler: RefreshHandlerService
  ) {
    super();
    this.onCellClick = this.onCellClick.bind(this);
    this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
        this.showContextMenu = false;
    });
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style3Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style3Pages[currentRouterLocation];

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          // FORMAT API DATA FOR TABLE
          // format dataCols for kendo component
          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          const types = {};
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            const colData = {
              ref: this.config.table.dataCols[i].key,
              refLink: this.config.table.dataCols[i].refLink,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              renderRefStyle: this.config.table.dataCols[i].renderRefStyle,
              renderALStyle: this.config.table.dataCols[i].renderALStyle,
              alKey: this.config.table.dataCols[i].alKey,
              alConnectableCompareKey: this.config.table.dataCols[i].alConnectableCompareKey,
              renderALConnectableStyle: this.config.table.dataCols[i].renderALConnectableStyle,
              renderMaintenanceScoreStyle: this.config.table.dataCols[i].renderMaintenanceScoreStyle,
              renderHCIHSIStyle: this.config.table.dataCols[i].renderHCIHSIStyle,
              renderTSBDueStyle: this.config.table.dataCols[i].renderTSBDueStyle,
              class: this.config.table.dataCols[i].class,
              hidden: this.config.table.dataCols[i].hidden,
              export: false,
              customStyle: this.config.table.dataCols[i].customStyle,
              filterable: this.config.table.dataCols[i].filterable
            };
            this.dataCols.push(colData);
            if (colData.hidden !== true) {
              const colDataExcel = {
                ref: this.config.table.dataCols[i].key + 'Export',
                refLink: this.config.table.dataCols[i].refLink,
                description: this.config.table.dataCols[i].title,
                width: this.config.table.dataCols[i].width,
                renderRefStyle: this.config.table.dataCols[i].renderRefStyle,
                renderALStyle: this.config.table.dataCols[i].renderALStyle,
                alKey: this.config.table.dataCols[i].alKey,
                alConnectableCompareKey: this.config.table.dataCols[i].alConnectableCompareKey,
                renderALConnectableStyle: this.config.table.dataCols[i].renderALConnectableStyle,
                renderMaintenanceScoreStyle: this.config.table.dataCols[i].renderMaintenanceScoreStyle,
                renderHCIHSIStyle: this.config.table.dataCols[i].renderHCIHSIStyle,
                renderTSBDueStyle: this.config.table.dataCols[i].renderTSBDueStyle,
                class: this.config.table.dataCols[i].class,
                hidden: true,
                export: true,
                customStyle: this.config.table.dataCols[i].customStyle,
                filterable: this.config.table.dataCols[i].filterable
              };
              this.dataCols.push(colDataExcel);
              if ('type' in this.config.table.dataCols[i]) {
                types[this.config.table.dataCols[i].key + 'Export'] = this.config.table.dataCols[i].type;
              }
            }
            if ('type' in this.config.table.dataCols[i]) {
              types[this.config.table.dataCols[i].key] = this.config.table.dataCols[i].type;
            }
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            const row = apiData[this.config.dataArrayKey][r];
            const ALColumnInfo = this.getColumnInfo(this.dataCols, 'al');
            let alConnected = true;
            if ('al' in row && (row['al'] == 0 || row['al'] == 2)) {
              alConnected = false;
            }
            for (const rowCol in row) {
              const columnInfoExport = this.getColumnInfo(this.dataCols, rowCol + 'Export');
              const columnInfo = this.getColumnInfo(this.dataCols, rowCol);
              const value = row[rowCol];
              let newValue = value;
              if (columnInfoExport !== null && columnInfoExport.export === true) {
                if (columnInfoExport.renderALStyle) {
                  if (value == 0) {
                    newValue = 'AL NOT Installable';
                  } else if (value == 1) {
                    newValue = 'AL Installed';
                  } else if (value == 2) {
                    newValue = 'AL NOT Installed';
                  }
                } else if (columnInfoExport.renderHCIHSIStyle) {
                  if (value == 1 || value === true) {
                    newValue = 'Y';
                  } else if (value == 0 || value === false) {
                    newValue = 'N';
                  }
                }
                row[rowCol + 'Export'] = newValue;
              }
              if (columnInfo !== null && columnInfo.renderHCIHSIStyle) {
                if (value == 1 || value === true) {
                  row[rowCol] = 'Y';
                } else if (value == 0 || value === false) {
                  row[rowCol] = 'N';
                }
              } else if (rowCol in types) {
                if (types[rowCol] === 'int') {
                  row[rowCol] = parseInt(row[rowCol]);
                } else if (types[rowCol] === 'float') {
                  row[rowCol] = parseFloat(row[rowCol]);
                }
                if (isNaN(row[rowCol])) {
                  row[rowCol] = null;
                }
                if (rowCol + 'Export' in row) {
                  row[rowCol + 'Export'] = row[rowCol];
                }
              }
              if (!alConnected && ['connectableper', 'maintscore', 'testcount', 'costpertest'].includes(rowCol)) {
                row[rowCol] = null;
              }
              if (!alConnected && ['connectableperExport', 'maintscoreExport', 'testcountExport', 'costpertestExport'].includes(rowCol + 'Export')) {
                row[rowCol + 'Export'] = null;
              }
            }
            this.dataRows.push(row);
          }
          this.updateGrid();
        }
      });
    }
  }

  getColumnInfo(dataCols, rowCol) {
    for (let i = 0; i < dataCols.length; i++) {
      if (dataCols[i].ref === rowCol) {
        return dataCols[i];
      }
    }
    return null;
  }

  public onCellClick({dataItem, column, type, originalEvent}): void {
    let contextMenuItemArr = [];
    if (type === 'click' && column.field === this.config.table.contextMenuFieldName) {
      originalEvent.preventDefault();
      for (let r = 0; r < this.config.table.dataCols.length; r++) {
        let dataCol = this.config.table.dataCols[r];
        if (dataCol.contextMenuItems !== undefined) {
          for (let k = 0; k < dataCol.contextMenuItems.length; k++) {
            contextMenuItemArr.push({
              'text': dataCol.contextMenuItems[k].text,
              'iconUrl':  dataCol.contextMenuItems[k].iconUrl, 
              'textLink': dataItem[dataCol.contextMenuItems[k].fieldName][dataCol.contextMenuItems[k].columnIndex] });
          }
        }
      }
      this.menuItems = contextMenuItemArr;
      this.showContextMenu = true;
      this.offset = { left: originalEvent.pageX, top: originalEvent.pageY };
    } else {
      this.showContextMenu = false;
    }    
  }

  public menuItemSelected(item: any): void {
    this.selected = item;
  }

  getMaintenanceScoreStyle(item: any)  {
    if (item !== undefined && parseInt(item) >= 0 && parseInt(item) <= 29) {
      return {
        color: 'red'
      };
    }
    return {
      color: 'black'
    };
  }

  /** This could be customized for global search functionality in Kendo UI grids */
  onInput(filter) {
    this.gridData = filterBy(this.dataRows, {
      logic: 'or',
      filters: [
        { field: "customername", operator: "contains", value: filter, ignoreCase: true },
        { field: "instrumentsrno", operator: "contains", value: filter, ignoreCase: true },
      ]
    });
  }
}
