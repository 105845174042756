import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileComponent } from '../../../tiles/base/tile.component';
import { RenderEvent } from '@progress/kendo-angular-charts';
import { Rect, Circle as CircleGeometry } from '@progress/kendo-drawing/geometry';
import { Group, Path, Layout, Text } from '@progress/kendo-drawing';

@Component({
  selector: 'app-br-pie-chart',
  templateUrl: './br-pie-chart.component.html',
  styleUrls: ['./br-pie-chart.component.scss']
})

export class BrPieChartComponent extends TileComponent implements OnInit {

  private goalPercent: number;
  private center: any;
  private radius: number;

  // Necessary to bind `this` to the AppComponent instance
  public visualHandler = this.visual.bind(this);

  translateApiDataForChart(apiData) {
    const pieDataTemp: any[] = [];
    for (const pieItem of apiData.values) {
      pieDataTemp.push({
        value: pieItem.value,
        category:pieItem.category,
        dashType: 'solid'
      });
    }
    // console.log(this.goalPercent);

    this.chartOptions = {
      pieData: pieDataTemp
    };
    // console.log('chartOptions', this.chartOptions);
  }

  public legendItemVisual = (args: any) => {
    const path = new Path({
      stroke: {
        color: args.series.data[args.pointIndex].color,
        dashType: args.series.data[args.pointIndex].dashType,
        width: 3
      }
    }).moveTo(0, 0).lineTo(15, 0).close();
    const text = new Text(args.series.data[args.pointIndex].category, [0, 0]);
    const layout = new Layout(new Rect([0, 0], [Number.MAX_VALUE, 0]), {
      alignItems: 'center',
      spacing: 5
    });

    layout.append(path, text);
    layout.reflow();

    return layout;
  }

  public visual(e: any): Group {
    // Obtain parameters for the segments
    this.center = e.center;
    this.radius = e.radius;

    // console.log("Metric: " + this.apiData.title + ", Center: " + this.center + ", Start Angle: " + e.startAngle
    // + ", End Angle: " + e.endAngle + ", Percentage: " + e.percentage + ", Radius: " + this.radius
    // + ", Goal Percent: " + this.goalPercent);
    // Create default visual
    return e.createVisual();
  }

  public onRender(e: RenderEvent): void {
    // The center and radius are populated by now.
    // We can ask a circle geometry to calculate the bounding rectangle for us.
    //
    // http://www.telerik.com/kendo-angular-ui/components/drawing/api/geometry/Circle/

    if (this.preferences.showGoal === false) {
      return;
    }
    const c = this.center;
    // console.log("Metric: " + this.apiData.title + ", Center: " + c + ", radius: " + this.radius);
    const circleGeometry = new CircleGeometry([c.x, c.y], this.radius);

    // Reflow the text in the bounding box
    //
    // http://www.telerik.com/kendo-angular-ui/components/drawing/api/Layout
    // http://www.telerik.com/kendo-angular-ui/components/drawing/api/LayoutOptions
    /*const path = new Path({
      stroke: {
        color: '#000',
        dashType: 'dash',
        width: 1
      }
    });*/

    // The starting point (or when 0 is passed) is at: 0.25
    // 1 degree equals 1/360; starting point center-right
    const calcGoalPercent = this.goalPercent - 0.25; // 0.90 - 0.25 to make starting point relative to top-center

    const point = circleGeometry.pointAt(360 * calcGoalPercent);

    //path.moveTo(c.x, c.y).lineTo(point.x, point.y);

    // Draw it on the Chart drawing surface
    // http://www.telerik.com/kendo-angular-ui/components/charts/api/ChartComponent/#toc-surface
    //e.sender.surface.draw(path);
  }

  isDataFound(apiData) {
    const dataFound = super.isDataFound(apiData);
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }
}
