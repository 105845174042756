export const Style4Pages = {
    '/assay': {
        pageTitle: 'Top Assay Details',
        apiRequestHandler: '/metrics/assay/details',
        backPageLabel: 'Back',
        backPageLink: '/lastSelectedDashboard',
        dataArrayKey: 'customers',
        groupItemsKey: 'assays',
        groupField: 'customername',
        groupFieldId: 'custid',
        groupCountField: 'count',
        customerDetailsLink: 'customers',
        table: {
            type: 'style4',
            needEntireApiDataRecord: true,
            exportFileName: 'assay_details.xlsx',
            dataCols: [
                {
                    title: 'Customer Name',
                    key: 'customername',
                    hidden: true,
                    groupHeaderColumn: true
                },
                {
                    title: 'Assay Name',
                    key: 'assayname'
                },
                {
                    title: 'Test Counts (3 months)',
                    customStyle:  { 'text-align': 'right' },
                    key: 'testcount'
                },
                {
                    title: '% All Tests',
                    customStyle:  { 'text-align': 'right' },
                    key: 'alltestper',
                    addCustomerDetailsLink: true
                }
            ]
        }
    },
    '/assay/customers': {
        pageTitle: 'Assay Customer Details',
        apiRequestHandler: '/metrics/assay/details/customerdetails',
        backPageLabel: 'Assay Details',
        backPageLink: '/assay',
        dataArrayKey: 'instruments',
        groupItemsKey: 'assays',
        groupField: 'instrumentnameserialnum',
        groupFieldsToAppend: ['instrumentname', 'serialno'],
        groupCountField: 'count',
        customerDetailsLink: 'customers',
        table: {
            type: 'style4',
            needEntireApiDataRecord: true,
            exportFileName: 'assay_customer_details.xlsx',
            dataCols: [
                {
                    title: 'Instrument Name and Serial Number',
                    key: 'instrumentnameserialnum',
                    hidden: true,
                    groupHeaderColumn: true
                },
                {
                    title: 'Assay Name',
                    key: 'assayname'
                },
                {
                    title: 'Test Counts (3 months)',
                    customStyle:  { 'text-align': 'right' },
                    key: 'testcount'
                },
                {
                    title: '% All Tests',
                    customStyle:  { 'text-align': 'right' },
                    key: 'alltestper'
                }
            ]
        }
    }
};