import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api/api.service';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';
import { RequestState } from 'src/app/api/request-handler';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';

@Component({
  selector: 'app-gs-alerts-dashboard',
  templateUrl: './gs-alerts-dashboard.component.html',
  styleUrls: ['./gs-alerts-dashboard.component.scss']
})
export class GsAlertsDashboardComponent implements OnInit  {

  state = RequestState.Inactive;

  dataRows = [];

  config = {
    pageTitle: 'Global Service Alerts',
    apiRequestHandler: '/global_service_alerts'
  };

  constructor(private navbarBuilderService: NavbarBuilderService
    , protected apiService: ApiService
    , protected refreshHandler: RefreshHandlerService) { 

    }

  ngOnInit(): void {
    this.navbarBuilderService.setNavbarChoice('gs-alerts-dashboard');

    const requestHandler = this.apiService.get(this.config.apiRequestHandler);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(this.config.apiRequestHandler, { requestTime: (new Date()).getTime() });
    this.refreshHandler.onRefreshEvent(
      (index) => {
        requestHandler.sendRequest(this.config.apiRequestHandler, { requestTime: (new Date()).getTime() });
      },
      requestHandler.getRelativeParams(this.config.apiRequestHandler)
    );

    // no need to send request here as chart tile has already done so
    requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // format dataRows for kendo component
        this.dataRows = [];
        for (let r = 0; r < apiData['data'].length; r++) {
          this.dataRows.push(apiData['data'][r]);
        }            
      }
    });
  }

  // getTrimmedMessage(msg: string) {
  //   //if (msg && msg.length >= 500) {
  //   //  return msg.substring(0, 500) + "<a href='#' style='text-decoration: underline; color: blue'>Read More</a>";
  //   //}
  //   return msg;
  // }

  getHtmlMessage(msg: string) {
    return '<html><head></head><body>' + msg + "</body></html>";
  }

  toHTML(input) : any {
    //console.log(input);
    let docElement = new DOMParser().parseFromString(input, "text/html").documentElement;
    //console.log(docElement.innerHTML);
    console.log(docElement.textContent);
    return docElement.textContent;
  }

  isDataFound(dataRows) {
    if (!dataRows) {
      return false;
    }
    if (Array.isArray(dataRows) && dataRows.length === 0) {
      return false;
    }
    return true;
  }

}
