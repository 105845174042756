<button kendoButton fillMode="flat" #bkanchor>Saved Presentations</button>
<div style="text-align: left">
    <kendo-popup #bkpopup [anchor]="bkanchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" [collision]="collision" (anchorViewportLeave)="bkMenuViable = false" *ngIf="bkMenuViable">
        <div style="padding:2px; background-color: #cef7df;">
            <h6>Select Saved Presentation</h6>
            <app-autoselect-list
                    [state]="RequestState.Complete"
                    [height]="300"
                    [hasSearch]="false"
                    [data]="listPresentationsData.items"
                    [keyField]="'presentation_id'"
                    [displayField]="'presentation_name'"
                    (itemSelect)="presentationSelect($event)"
                    [selectedOption]="selectedOption">
            </app-autoselect-list>
            <h6>Save These Settings</h6>
            As "Current Selection" OR
            <kendo-textbox
                    [style.width.px]="250"
                    placeholder="Save As Name"
                    [clearButton]="false"
                    (valueChange)="nameChange($event)"
            >
            </kendo-textbox>
            <button kendoButton fillMode="flat" (click)="saveAs()">Save</button>
        </div>
    </kendo-popup>
</div>