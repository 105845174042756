import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {ApiService} from '../../api/api.service';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {DetailQueryParamsService} from '../detail-query-params.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {RequestState} from '../../api/request-handler';
import {DDTConfig} from '../configuration/details/ddt-config';

@Component({
  selector: 'app-ddt-loader',
  templateUrl: './ddt-loader.component.html',
  styleUrls: ['./ddt-loader.component.scss']
})
export class DdtLoaderComponent implements OnInit, OnDestroy {
  ddtConfig = DDTConfig;
  @Input() apiUrl = '/qbr/kpi/example_ddt/details';
  apiData = null;
  state = null;
  requestHandler;
  stateChangeHandler;

  dataCols = [];

  constructor(
    private apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private detailQueryParamsService: DetailQueryParamsService
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.setNavbarChoice('business-review');
    this.navbarBuilderService.setNavFilterSelection({
      preferences: false,
      dashboardSelector: false,
      toolSelection: ToolSelectionOptions.businessreview,
      bookmarksFlag: false,
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false
    });
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.ddtConfig.hasOwnProperty(currentRouterLocation)) {
        this.apiUrl = this.ddtConfig[currentRouterLocation];
      }
      this.requestHandler = this.apiService.get(this.apiUrl);
      // no need to send request here as chart tile has already done so
      this.stateChangeHandler = (state) => {
        this.state = state;
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.apiData = JSON.parse(response.body);
          const dataCols = this.apiData.dataTable.columns;
          for (let i = 0; i < dataCols.length; i++) {
            dataCols[i].ref = dataCols[i].value;
            if (!('width' in dataCols[i])) {
              dataCols[i].width = 80;
            }
            dataCols[i].hidden = false;
            dataCols[i].filterable = true;
            dataCols[i].export = false;
            if (!('type' in dataCols[i])) {
              dataCols[i].type = 'string';
            }
            dataCols[i].isLink = false;
            if (!('bg_color' in dataCols[i])) {
              dataCols[i].bg_color = '';
              dataCols[i].text_color = '#000';
            }
            if (!('text_color' in dataCols[i])) {
              dataCols[i].text_color = '#000';
            }
          }
          this.dataCols = dataCols;
        }
      };
      this.requestHandler.onStateChange(this.stateChangeHandler);
      this.requestHandler.sendRequest(this.apiUrl);
    });
    this.detailQueryParamsService.buildPersistedValuesFromQueryParams();
  }

  ngOnDestroy(): void {
    if (this.stateChangeHandler) {
      this.requestHandler.removeStateListener(this.stateChangeHandler);
    }
  }

  isDataColsSet() {
    if (Array.isArray(this.dataCols) && this.dataCols.length > 0) {
      return true;
    }
    return false;
  }
}
