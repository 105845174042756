import { Component, OnInit } from '@angular/core';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';

@Component({
  selector: 'app-alerts-navbar',
  templateUrl: './alerts-navbar.component.html',
  styleUrls: ['./alerts-navbar.component.scss']
})
export class AlertsNavbarComponent implements OnInit {
  ToolSelectionOptions = ToolSelectionOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
