<a [class.btn]="!displayTitles" [class.btn-secondary]="!displayTitles" [class.nav-link]="displayTitles" #bkanchor title="Preferences: Set the order for displaying dashboard metrics' order, My CRL Assignment Types and Ambassador Currency. Options will vary depending on level of access.">
    <fa-icon [icon]="faCog"></fa-icon><span *ngIf="displayTitles"> Preferences</span>
</a>
<kendo-popup #bkpopup [anchor]="bkanchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" [collision]="collision" (anchorViewportLeave)="bkMenuViable = false" *ngIf="bkMenuViable">
    <div class="loading" *ngIf="apiState !== RequestState.Complete">Please Wait!</div>
    <div class="content" *ngIf="apiState === RequestState.Complete">
        <div class="preference-section">
            <div class="preference-section-label">CRL Assignment Types</div>
            <div class="preference-section-content">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [disabled]="primaryDisabled" [checked]="preferences.primary === 'True'" (change)="toggle('primary')" id="preferencePrimary">
                    <label class="form-check-label" for="preferencePrimary">
                        Primary
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [disabled]="secondaryDisabled" [checked]="preferences.secondary === 'True'" (change)="toggle('secondary')" id="preferenceSecondary">
                    <label class="form-check-label" for="preferenceSecondary">
                        Secondary
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" [disabled]="tertiaryDisabled" [checked]="preferences.tertiary === 'True'" (change)="toggle('tertiary')" id="preferenceTertiary">
                    <label class="form-check-label" for="preferenceTertiary">
                        Tertiary
                    </label>
                </div>
            </div>
        </div>
        <!--<div class="preference-section">-->
            <!--<div class="preference-section-label">Temperature (Unit)</div>-->
            <!--<div class="preference-section-content">-->
                <!--<div class="form-check">-->
                    <!--<input class="form-check-input" type="radio" name="preferenceTempature" id="preferenceTempatureF"-->
                           <!--[checked]="preferences.fahrenheit === 'True'" (change)="preferences.fahrenheit = 'True'">-->
                    <!--<label class="form-check-label" for="preferenceTempatureF">-->
                        <!--F-->
                    <!--</label>-->
                <!--</div>-->
                <!--<div class="form-check">-->
                    <!--<input class="form-check-input" type="radio" name="preferenceTempature" id="preferenceTempatureC"-->
                           <!--[checked]="preferences.fahrenheit === 'False'" (change)="preferences.fahrenheit = 'False'">-->
                    <!--<label class="form-check-label" for="preferenceTempatureC">-->
                        <!--C-->
                    <!--</label>-->
                <!--</div>-->
            <!--</div>-->
        <!--</div>-->
        <div class="preference-section" *ngIf="showCurrency()">
            <div class="preference-section-label">Currency - Ambassador (tab)</div>
            <div class="preference-section-content">
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="preferenceCurrency" id="preferenceCurrencyLocal"
                           [checked]="preferences.localcurrency === 'True'" (change)="preferences.localcurrency = 'True'">
                    <label class="form-check-label" for="preferenceCurrencyLocal">
                        Local Currency
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="radio" name="preferenceCurrency" id="preferenceCurrencyUS"
                           [checked]="preferences.localcurrency === 'False'" (change)="preferences.localcurrency = 'False'">
                    <label class="form-check-label" for="preferenceCurrencyUS">
                        US Dollars
                    </label>
                </div>
            </div>
        </div>
        <button type="button" class="btn btn-primary" (click)="saveSelections()">Save</button>
    </div>
</kendo-popup>