import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef, Input,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentLookupRegistry} from '../../../decorators/component-lookup';

@Component({
  selector: 'app-filter-insert',
  templateUrl: './filter-insert.component.html',
  styleUrls: ['./filter-insert.component.scss']
})
export class FilterInsertComponent implements AfterViewInit, OnDestroy {
  dynamicComponentRef: ComponentRef<any>;

  @Input() componentRef;
  @Input() metricId;

  @ViewChild('dynamic', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef

  constructor(private factoryResolver: ComponentFactoryResolver, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.loadDynamicComponent();
    this.cd.detectChanges();
  }

  loadDynamicComponent() {
    const componentType: Type<any> = ComponentLookupRegistry.get(this.componentRef)
    const componentFactory = this.factoryResolver.resolveComponentFactory(componentType);

    this.viewContainerRef.clear();
    this.dynamicComponentRef = this.viewContainerRef.createComponent(componentFactory);
    // add inputs
    (this.dynamicComponentRef.instance).metricId = this.metricId;
  }

  ngOnDestroy() {
    if (this.dynamicComponentRef) {
      this.dynamicComponentRef.destroy();
    }
  }
}
