<div class="container-fluid">
    <div class="row">
        <!--<div class="col-12 visible-lt-1198">-->
            <!--<div class="highlight-item">-->
                <!--<a class="btn btn-ch-alerts" href="/aoh#customer-health-alerts" title="# of Customer Health Alerts You Have. Click to Navigate to Details.">-->
                    <!--<span class="badge badge-pill badge-ch-alerts">4</span> New Customer Health Alerts-->
                <!--</a>-->
            <!--</div>-->
            <!--<div class="highlight-item">-->
                <!--<a class="btn btn-healthy" href="/aoh#customer-health-metrics" title="Percent of your customers of which are healthy. Click to Navigate to Details.">-->
                    <!--<span class="badge badge-pill badge-healthy">All</span> Customers Found Appear Healthy-->
                <!--</a>-->
            <!--</div>-->
        <!--</div>-->
        <div class="account-lookup-message" *ngIf="showLoadingAccountMessage">
            Looking Up Account, Please Wait...
        </div>
        <div class="account-lookup-message account-lookup-message-error" *ngIf="showAccountNotFoundMessage">
            Could not find aoh alerts or metrics for this account number: {{accountNumber}}&nbsp;&nbsp;
            <a class="btn btn-outline-secondary btn-sm" (click)="closeAccountNotFoundMessage()">Hide</a>
        </div>
        <div class="col-12">
            <kendo-tabstrip #tabstrip>
                <kendo-tabstrip-tab title="Customer Health Alerts" [selected]="(selectedTab===0)" class="alerts-tab">
                    <ng-template kendoTabContent>
                        <div class="customer-health-alerts-box">
                            <app-customer-health-alerts-grid (onUpdate)="onAlertsUpdate($event)"></app-customer-health-alerts-grid>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab title="Customer Health Metrics" [selected]="(selectedTab===1)" class="metrics-tab">
                    <ng-template kendoTabContent>
                        <!--<div class="margin-adjust" id="customer-health-metrics"></div>-->
                        <div class="customer-health-metrics-box">
                            <app-customer-health-grid (onUpdate)="onHealthMetricsUpdate($event)"></app-customer-health-grid>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
                <kendo-tabstrip-tab *ngIf="selectedCustomerName !== null" [title]="" class="customer-tab">
                    <ng-template kendoTabTitle>
                        {{selectedCustomerName}}&nbsp;&nbsp;
                        <fa-icon [icon]="faWindowClose" (click)="clearCustomer()"></fa-icon>
                    </ng-template>
                    <ng-template kendoTabContent>
                        <app-customer-profile-info></app-customer-profile-info>
                        <div class="customer-data-trends-box">
                            <div class="margin-adjust" id="customer-data-trends"></div>
                            <app-customer-data-trends></app-customer-data-trends>
                        </div>
                        <div class="customer-health-alerts-box">
                            <app-customer-health-alerts-grid></app-customer-health-alerts-grid>
                        </div>
                    </ng-template>
                </kendo-tabstrip-tab>
            </kendo-tabstrip>
        </div>
    </div>
</div>