import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { ChartComponent } from '@progress/kendo-angular-charts';

@Component({
  selector: 'app-data-trend-chart',
  templateUrl: './data-trend-chart.component.html',
  styleUrls: ['./data-trend-chart.component.scss']
})
export class DataTrendChartComponent implements OnInit {
  @Input() chartData;
  public chartOptions;
  @ViewChild('chart') chartComponent: ChartComponent;
  dataSetYMax = [];

  constructor() { }

  ngOnInit(): void {
    let labelFormat = '{0:1}';
    switch (this.chartData.key) {
      case 'test_volume':
        labelFormat = '{0:0}';
        break;
      case 'test_completion':
        labelFormat = '{0:n1}';
        break;
      case 'controls':
        labelFormat = '{0:n1}';
        break;
      case 'repeat_tests':
        labelFormat = '{0:n1}';
        break;
      case 'hard_stops':
        labelFormat = '{0:n1}';
        break;
    }

    const categoryList = [];
    if ('xaxisData' in this.chartData) {
      for (const value of this.chartData.xaxisData) {
        categoryList.push(value);
      }

      let yleftmax = 0;
      const seriesList = [];
      const series1length = this.chartData.yaxisData.length;
      for (const i in this.chartData.yaxisData) {
        const dataSet = this.chartData.yaxisData[i];
        this.dataSetYMax[i] = [];

        let legendColor = null;
        if (dataSet.name === 'Alinity CC') {
          legendColor = '#cb20cb';
        }
        if (dataSet.name === 'Alinity IA') {
          legendColor = '#007bff';
        }

        let legendLabel = dataSet.name;
        if (this.chartData.key === 'hard_stops') {
          legendLabel += ' Avg';
        }
        seriesList[i] = {
          data: [],
          name: legendLabel,
          missingValues: 'gap',
          categoryAxis: 'cat1',
          axis: 'values1',
          visible: true,
          tooltip: {
            visible: true
          }
          // color: apiData.linecolors[i]
        };
        if (legendColor !== null) {
          seriesList[i].color = legendColor;
        }
        for (let a = 0; a < dataSet.values.length; a++) {
          this.dataSetYMax[i][a] = dataSet.values[a];
          if (dataSet.values[a] > yleftmax) {
            yleftmax = dataSet.values[a];
          }
          seriesList[i].data.push(dataSet.values[a]);
        }
      }

      const tickCount = 10;
      let axis2Max = this.nextHighestNumberDivisibleBy(yleftmax, tickCount - 1);
      axis2Max = axis2Max * 1.061;

      const seriesArray = seriesList;
      const valueAxisArray = [
        {
          name: 'values1',
          labels: {
            format: labelFormat,
            mirror: false,
            position: 'start'
          },
          title: {
            text: 'Avg ' + this.chartData.yaxisLabel
          },
          line: {
            visible: false
          },
          axisCrossingValue: -10,
          max: axis2Max,
          min: 0,
          majorUnit: axis2Max / (tickCount - 1)
        }
      ];

      if (this.chartData.hasOwnProperty('yaxis2Label')) {
        let yleftmax2 = 0;

        for (const i in this.chartData.yaxis2Data) {
          const newKey = parseInt(i) + parseInt(series1length);
          const dataSet2 = this.chartData.yaxis2Data[i];
          this.dataSetYMax[newKey] = [];

          let legendColor = null;
          if (dataSet2.name === 'Alinity CC') {
            legendColor = '#20c997';
          }
          if (dataSet2.name === 'Alinity IA') {
            legendColor = '#007bff';
          }

          let legendLabel = dataSet2.name;
          if (this.chartData.key === 'hard_stops') {
            legendLabel += ' HS/10000 (Dashed)';
          }

          seriesList[newKey] = {
            data: [],
            name: legendLabel,
            missingValues: 'gap',
            categoryAxis: 'cat1',
            dashType: 'dash',
            axis: 'values2',
            visible: true,
            tooltip: {
              visible: true
            }
            // color: apiData.linecolors[i]
          };

          if (legendColor !== null) {
            seriesList[newKey].color = legendColor;
          }

          for (let a = 0; a < dataSet2.values.length; a++) {

            this.dataSetYMax[newKey][a] = dataSet2.values[a];
            if (dataSet2.values[a] > yleftmax2) {
              yleftmax2 = dataSet2.values[a];
            }
            seriesList[newKey].data.push(dataSet2.values[a]);
          }
        }

        const tickCount2 = 10;
        let axis2Max2 = this.nextHighestNumberDivisibleBy(yleftmax2, tickCount2 - 1);
        axis2Max2 = axis2Max2 * 1.061;

        let seriesLabel = this.chartData.yaxis2Label;
        if (this.chartData.key === 'hard_stops' && seriesLabel === 'Hard Stop Scaled') {
          seriesLabel = 'HS/10000';
        }

        valueAxisArray.push(
          {
            name: 'values2',
            labels: {
              format: labelFormat,
              mirror: false,
              position: 'end'
            },
            title: {
              text: seriesLabel
            },
            line: {
              visible: false
            },
            axisCrossingValue: -10,
            max: axis2Max2,
            min: 0,
            majorUnit: axis2Max2 / (tickCount2 - 1)
          }
        );
      }

      this.chartOptions = {
        title: {
          text: this.chartData.title,
          position: 'top',
          font: 'bold 14px  Arial,Helvetica,sans-serif'
        },
        legend: {
          position: 'bottom'
        },
        seriesDefaults: {
          type: 'line',
          area: {
            line: {
              style: 'smooth'
            }
          }
        },
        series: seriesArray,
        valueAxis: valueAxisArray,
        categoryAxis: {
          name: 'cat1',
          categories: categoryList,
          majorGridLines: {
            visible: false
          },
          labels: {
            rotation: -45
          }
        }
      };
    }
  }

  legendItemClickEvent(event) {
    this.chartComponent.series[event.seriesIndex].visible = !this.chartComponent.series[event.seriesIndex].visible;
    let yleftmax = 0;
    let yleftmax2 = 0;
    for (let i = 0; i < this.chartComponent.series.length; i++) {
      console.log('i', i, this.dataSetYMax);
      const item = this.chartComponent.series[i];
      if (item.visible && item.axis === 'values1') {
        for (const index in this.dataSetYMax[i]) {
          if (this.dataSetYMax[i][index] > yleftmax) {
            yleftmax = this.dataSetYMax[i][index];
          }
        }
      }
      if (item.visible && item.axis === 'values2') {
        for (const index in this.dataSetYMax[i]) {
          if (this.dataSetYMax[i][index] > yleftmax2) {
            yleftmax2 = this.dataSetYMax[i][index];
          }
        }
      }
    }
    const tickCount = 10;
    const axisMax = (this.nextHighestNumberDivisibleBy(yleftmax, tickCount - 1) * 1.061);
    const axisMax2 = (this.nextHighestNumberDivisibleBy(yleftmax2, tickCount - 1) * 1.061);
    for (const key in this.chartComponent.valueAxis) {
      if (key in this.chartComponent.valueAxis) {
        if (this.chartComponent.valueAxis[key].name === 'values1') {
          this.chartComponent.valueAxis[key].max = axisMax;
        }
        if (this.chartComponent.valueAxis[key].name === 'values2') {
          this.chartComponent.valueAxis[key].max = axisMax2;
        }
      }
    }
    this.chartComponent.notifyChanges({});

    // if (dataSet.values[a] > yleftmax) {
    //   yleftmax = dataSet.values[a];
    // }
    //
    // const tickCount = 10;
    // let axis2Max = this.nextHighestNumberDivisibleBy(yleftmax, tickCount - 1);
    // axis2Max = axis2Max * 1.061;
    //
    // max: axis2Max,
    // majorUnit: axis2Max / (tickCount - 1)
  }

  nextHighestNumberDivisibleBy(num1, divisibleBy) {
    num1 = parseInt(num1);
    if (!Number.isInteger(num1)) {
      return false;
    }
    while (1) {
      if (num1 % divisibleBy === 0) {
        return num1;
      }
      num1++;
    }
  }
}
