import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {GridComponent} from '../grid.component';
import {RefreshHandlerService} from '../../api/refresh-handler.service';

@Component({
  selector: 'app-customer-order-details',
  templateUrl: './customer-order-details.component.html',
  styleUrls: ['./customer-order-details.component.scss']
})
export class CustomerOrderDetailsComponent extends GridComponent implements OnInit {

  stylePages = {
    '/orders/details': {
      pageTitle: 'Order Details',
      apiRequestHandler: '/metrics/orders/details',
      backPageLabel: 'Orders',
      backPageLink: '/orders/intermediate',
      dataArrayKey: 'orderdet',
      table: {
        needEntireApiDataRecord: true,
        exportFileName: 'customer_order_details.xlsx',
        dataCols: [
            {
                title: '#',
                key: 'linenumber',
                customStyle:  { 'text-align': 'right' },
                width: 50
            },
            {
                title: 'List Number',
                key: 'item',
                width: 50
            },
            {
                title: 'LN Desc',
                key: 'itemdesc',
                width: 80
            },
            {
                title: 'Lot #',
                key: 'lotnumber',
                width: 50
            },
            {
                title: 'Expiration Date',
                key: 'expirationdate',
                width: 50,
                dateFormat: "yyyy-MM-dd"
            },
            {
                title: 'Quantity',
                key: 'actualquantity',
                customStyle:  { 'text-align': 'right' },
                width: 50
            },
            {
                title: 'Forecast Ship Date',
                key: 'forecastedshipdate',
                width: 50,
                dateFormat: "yyyy-MM-dd"
            },
            {
                title: 'Actual Ship Date',
                key: 'actualshipdate',
                width: 50,
                dateFormat: "yyyy-MM-dd"
            },
            {
              title: 'Expected Delivery Date',
              key: 'expecteddeliverydate',
              width: 50,
              dateFormat: "yyyy-MM-dd"
          }
        ]
      }
    },
    '/amb_orders/details': {
      pageTitle: 'Order Details (USD)',
      apiRequestHandler: '/amb_orders/details',
      backPageLabel: 'Orders',
      backPageLink: '/amb_orders/intermediate',
      dataArrayKey: 'orderdet',
      table: {
        needEntireApiDataRecord: true,
        exportFileName: 'amb_customer_order_details.xlsx',
        dataCols: [
            {
                title: '#',
                key: 'linenumber',
                customStyle:  { 'text-align': 'right' },
                width: 50
            },
            {
                title: 'List Number',
                key: 'item',
                width: 50
            },
            {
                title: 'LN Desc',
                key: 'itemdesc',
                width: 80
            },
            {
                title: 'Lot #',
                key: 'lotnumber',
                width: 50
            },
            {
                title: 'Expiration Date',
                key: 'expirationdate',
                width: 50,
                dateFormat: "yyyy-MM-dd"
            },
            {
              title: 'Amount',
              key: 'localamount',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
                title: 'Quantity',
                key: 'actualquantity',
                customStyle:  { 'text-align': 'right' },
                width: 50
            },
            {
                title: 'Forecast Ship Date',
                key: 'forecastedshipdate',
                width: 50,
                dateFormat: "yyyy-MM-dd"
            },
            {
                title: 'Actual Ship Date',
                key: 'actualshipdate',
                width: 50,
                dateFormat: "yyyy-MM-dd"
            },
            {
              title: 'Expected Delivery Date',
              key: 'expecteddeliverydate',
              width: 50,
              dateFormat: "yyyy-MM-dd"
          }
        ]
      }
    }
  };

  config = null;
  public state = RequestState.Inactive;
  customername = null;
  ordernumber = null;
  orderstatus = null;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    private refreshHandler: RefreshHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.customername = params.get('customername');
      this.ordernumber = params.get('ordernumber');
      this.orderstatus = params.get('orderstatus');
    });

    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, ordernumber: this.ordernumber});
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, ordernumber: this.ordernumber});
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            this.dataCols.push({
              ref: this.config.table.dataCols[i].key,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              dateFormat: this.config.table.dataCols[i].dateFormat,
              customStyle: this.config.table.dataCols[i].customStyle,
            });
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            this.dataRows.push(apiData[this.config.dataArrayKey][r]);
          }

          for (const rowKey in this.dataRows) {
            if (this.dataRows.hasOwnProperty(rowKey)) {
              const row = this.dataRows[rowKey];
              for (const rowCol in row) {
                if (rowCol in row) {
                  let value = row[rowCol];
                  if (['expirationdate', 'forecastedshipdate', 'actualshipdate', 'expecteddeliverydate'].includes(rowCol)) {
                    value = new Date(value);
                    if (value instanceof Date && !isNaN(value.valueOf())) {
                      let month = '' + (value.getMonth() + 1);
                      let day = '' + value.getDate();
                      const year = value.getFullYear();
                      if (month.length < 2) {
                        month = '0' + month;
                      }
                      if (day.length < 2) {
                        day = '0' + day;
                      }
                      value = year + '-' + month + '-' + day;
                    } else {
                      value = '';
                    }
                  }
                  this.dataRows[rowKey][rowCol] = value;
                }
              }
            }
          }

          this.updateGrid();
        }
      });
    }
  }
}
