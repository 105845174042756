import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {BrHistoryRange} from './br-history-range.service';
import {BrParamsService} from './br-params.service';
import {PresentationToggleHandlerService} from './presentation-toggle-handler.service';
import { Location } from '@angular/common';
import {PresentationSelectService} from './presentation-select.service';
import {md5} from './md5';

@Injectable({
  providedIn: 'root'
})
export class DetailQueryParamsService {
  /**
   * Params to include in url params
   *  - months, sg, uck, pl, acct_pres_name, presentation_id
   *
   * Methods:
   * getQueryParams - to help build route to detail component
   * buildPersistedValuesFromQueryParams - for detail components to rebuild memory for business review selections
   */
  buildComplete = new EventEmitter<any>(true);
  returnPathReady = new EventEmitter<any>(true);
  returnUrl = null;
  returnParams = null;

  constructor(
    private brParamsService: BrParamsService,
    private brHistoryRange: BrHistoryRange,
    private presentationToggleHandlerService: PresentationToggleHandlerService,
    private route: ActivatedRoute,
    private location: Location,
    private presentationSelectService: PresentationSelectService
  ) {
  }

  generateUUID() { // Public Domain/MIT
    let d = new Date().getTime(); // Timestamp
    // Time in microseconds since page-load or 0 if unsupported \/
    let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16; // random number between 0 and 16
      if (d > 0) { // Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else { // Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  getCompleteUrl(url) {
    let completeUrl = '';
    for (const part of url) {
      completeUrl += '/' + part.path;
    }
    return completeUrl;
  }

  getQueryParams(params = {}) {
    const uuid = this.brParamsService.getUUID();
    try {
      localStorage.setItem(uuid, JSON.stringify(this.brParamsService.get('dump')));
    } catch (e) {
      localStorage.clear();
      localStorage.setItem(uuid, JSON.stringify(this.brParamsService.get('dump')));
    }
    params['sessionKey'] = uuid;
    if (this.returnUrl !== null) {
      const uuidForReturnPath = md5(this.getCompleteUrl(this.returnUrl));
      try {
        localStorage.setItem(uuidForReturnPath, JSON.stringify({
          url: this.getCompleteUrl(this.returnUrl),
          params: this.returnParams
        }));
      } catch (e) {
        localStorage.clear();
        localStorage.setItem(uuidForReturnPath, JSON.stringify({
          url: this.getCompleteUrl(this.returnUrl),
          params: this.returnParams
        }));
      }
      params['returnUrlKey'] = uuidForReturnPath;
    }

    return params;
    /*return {
      ckey: this.brParamsService.get('uck'),
      months: this.brParamsService.get('br_history_range'),
      sg: this.brParamsService.get('sg'),
      pl: this.brParamsService.get('pl'),
      presentation_id: this.brParamsService.get('presentationId'),
      acct_pres_name: this.brParamsService.get('presentedName'),
      acct_customer_query: this.brParamsService.get('acct_customer_query'),
    };*/
  }

  getBuildCompleteEvent() {
    return this.buildComplete;
  }

  prepReturnLink(currentUrl) {
    this.returnUrl = currentUrl;
  }

  prepReturnParams(params) {
    this.returnParams = params;
  }

  getReturnLink() {
    return this.returnPathReady;
  }

  processReturnLink() {
    this.route.queryParams
      .subscribe(params => {
        if (params.hasOwnProperty('returnUrlKey')) {
          const returnPathDetails = JSON.parse(localStorage.getItem(params.returnUrlKey));
          if (
            returnPathDetails.url === this.getCompleteUrl(this.returnUrl)
            && returnPathDetails.params.sessionKey === params.sessionKey
            && returnPathDetails.params.returnUrlKey === params.returnUrlKey
          ) {
            this.returnPathReady.emit(undefined);
          } else {
            this.returnPathReady.emit(returnPathDetails);
          }
        }
      });
  }

  buildPersistedValuesFromQueryParams() {
    this.route.queryParams
      .subscribe(params => {
        if (params.hasOwnProperty('sessionKey')) {
          const brParamsData = JSON.parse(localStorage.getItem(params.sessionKey));
          // console.log('brParamsData', params.sessionKey, localStorage.getItem(params.sessionKey));
          this.brParamsService.force(brParamsData);
        }
        if (params.hasOwnProperty('ckey')) {
          this.brParamsService.set('uck', Array.isArray(params.ckey) ? params.ckey : [params.ckey]);
        }
        if (params.hasOwnProperty('acct_pres_name')) {
          this.brParamsService.set('presentedName', params.acct_pres_name);
        }
        if (params.hasOwnProperty('period')) {
          this.brParamsService.set('detailperiod', params.period);
        }
        this.brParamsService.set('dashboardState', 'selected');
        this.buildComplete.emit();
      });
    return this;
  }

  buildPersistedValuesFromParams(params) {
    if (params.hasOwnProperty('sessionKey')) {
      const brParamsData = JSON.parse(localStorage.getItem(params.sessionKey));
      // console.log('brParamsData', params.sessionKey, localStorage.getItem(params.sessionKey));
      this.brParamsService.force(brParamsData);
    }
    if (params.hasOwnProperty('ckey')) {
      this.brParamsService.set('uck', Array.isArray(params.ckey) ? params.ckey : [params.ckey]);
    }
    if (params.hasOwnProperty('acct_pres_name')) {
      this.brParamsService.set('presentedName', params.acct_pres_name);
    }
    if (params.hasOwnProperty('period')) {
      this.brParamsService.set('detailperiod', params.period);
    }
    this.brParamsService.set('dashboardState', 'selected');
    this.buildComplete.emit();
    return this;
  }

  getCleanLocation() {
    const currentRouterLocation = this.location.path();
    return currentRouterLocation.split('?')[0];
  }
}
