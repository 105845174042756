import { Component, OnInit, OnDestroy, Output, EventEmitter, Renderer2 } from '@angular/core';
import {GridComponent} from '../../details/grid.component';
import {Location} from '@angular/common';
import {CtServiceService} from '../ct-service.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ApiService} from '../../api/api.service';
import {PersonaService} from '../../api/persona.service';
import {RequestState} from '../../api/request-handler';
import {PersonaTypes} from '../../configuration/persona-types';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {environment} from '../../../environments/environment';

interface CustomerHealthAlertGridRow {
  uniqueCustomerKey: string;
  customerName: string;
  countryName: string;
  area: string;
  alertPriority: string;
  alertMessage: string;
  alertAge: string;
  alertDateVal: string;
  alertAction: string;
  alertStyle: string;
}

@Component({
  selector: 'app-customer-health-alerts-grid',
  templateUrl: './customer-health-alerts-grid.component.html',
  styleUrls: ['./customer-health-alerts-grid.component.scss']
})
export class CustomerHealthAlertsGridComponent extends GridComponent implements OnInit, OnDestroy {
  apiUrl = 'cua/customer_health_alerts';
  apiData = null;
  state = null;
  customerId = null;
  tooltips = {
    alertHelp: false
  };
  opened = false;
  faInfoCircle = faInfoCircle;
  @Output() onUpdate = new EventEmitter<number>();
  refreshIndexes = [];
  requestHandler;
  stateChangeHandler;
  showContextMenu = false;
  offset: any;
  selectedSn;
  selectedPl;
  gsrUrl = environment.gsrUrl;
  private documentClickSubscription: any;

  dataCols = [
    {ref: 'uniqueCustomerKey', description: 'CustomerKey', width: 80, hidden: true, filterable: true, export: false},
    {ref: 'alertAge', description: 'Age', width: 80, hidden: false, filterable: false, export: true},
    {ref: 'alertPriority', description: 'Priority', width: 120, hidden: false, filterable: false, export: true},
    {ref: 'customerName', description: 'Customer', width: 150, hidden: false, filterable: true, export: true},
    {ref: 'customerNum', description: 'Customer #', width: 10, hidden: true, filterable: false, export: true},
    {ref: 'alertDateVal', description: 'Date/Time (UTC)', width: 120, hidden: false, filterable: false, export: true},
    {ref: 'alertMessage', description: 'Alert', width: 200, hidden: false, filterable: true, export: true},
    {ref: 'sn', description: 'SN', width: 100, hidden: false, filterable: true, export: true},
    {ref: 'pl', description: 'PL', width: 100, hidden: true, filterable: false, export: true},
    // {ref: 'alertAction', description: 'Action', width: '150', hidden: false, filterable: true},
  ];
  dataRows = [];

  constructor(
    private apiService: ApiService,
    private refreshHandler: RefreshHandlerService,
    private personaService: PersonaService,
    private ctService: CtServiceService,
    private renderer: Renderer2
  ) {
    super();
    this.onCellClick = this.onCellClick.bind(this);
    this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
      this.showContextMenu = false;
    });
  }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get(this.apiUrl);
    this.requestHandler.sendRequest(this.apiUrl);
    // console.log('refresh params', requestHandler.getRelativeParams(this.apiUrl));
    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest(this.apiUrl);
      },
      this.requestHandler.getRelativeParams(this.apiUrl)
    ));

    // no need to send request here as chart tile has already done so
    this.stateChangeHandler = (state) => {
      this.state = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        this.apiData = JSON.parse(response.body);
        // format dataRows for kendo component
        this.dataRows = [];
        this.onUpdate.emit(this.apiData.data.length);
        for (let i = 0; i < this.apiData.data.length; i++) {
          const apiDataRow: CustomerHealthAlertGridRow = this.apiData.data[i];
          const dataRow = {};
          for (const dataColConfig of this.dataCols) {
            let bgColorStyle = '';
            let textColor = 'black';
            if (dataColConfig.ref === 'alertPriority') {
              bgColorStyle = apiDataRow.alertStyle;
            }
            if (bgColorStyle === 'red') {
              bgColorStyle = '#FF6347';
              textColor = 'white';
            }
            // if (dataColConfig.ref )
            dataRow[dataColConfig.ref] = {
              value: apiDataRow[dataColConfig.ref],
              bgcolor: bgColorStyle,
              color: textColor
            };
          }
          this.dataRows.push(dataRow);
        }
        this.updateGrid();
        this.updateCustomerIdSelection();
      }
    };
    this.requestHandler.onStateChange(this.stateChangeHandler);
    this.updateGrid();

    /*this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.updateCustomerIdSelection();
      },
      ['ct_persona_type', 'ct_persona_value']
    ));*/
  }

  ngOnDestroy(): void {
    for (const index of this.refreshIndexes) {
      this.refreshHandler.cancelRefreshEvent(index);
    }
    this.requestHandler.removeStateListener(this.stateChangeHandler);
  }

  inArray(array, index) {
    return (index in array);
  }

  updateCustomerIdSelection() {
    const level1PersonaChoice = this.personaService.getLevel1Choice();
    if (level1PersonaChoice.type === 'Customer') {
      this.customerId = level1PersonaChoice.value;
      this.ctService.setCustomerId(level1PersonaChoice.value, level1PersonaChoice.label);
    }
  }

  selectCustomer(dataItem) {
    this.customerId = dataItem.uniqueCustomerKey.value;
    const customerName = dataItem.customerName.value;
    this.personaService.setCustomerOverride(true);
    this.personaService.setPersonaOption(PersonaTypes.Customer, this.customerId, customerName);
    this.ctService.setCustomerId(this.customerId, customerName);
  }

  toggleTooltip(type, changeTo = null) {
    this.opened = true;
    if (changeTo === null) {
      this.tooltips[type] = !this.tooltips[type];
    } else {
      this.tooltips[type] = changeTo;
    }
    if (this.tooltips[type]) {
      for (const type1 in this.tooltips) {
        if (type1 !== type) {
          this.tooltips[type1] = false;
        }
      }
    }
  }

  public close() {
    this.opened = false;
  }

  public onCellClick({dataItem, column, type, originalEvent}): void {
    if (type === 'click' && column.field === 'sn.value') {
      originalEvent.preventDefault();
      this.showContextMenu = true;
      this.selectedPl = dataItem.pl.value;
      this.selectedSn = dataItem.sn.value;
      this.offset = { left: originalEvent.pageX, top: originalEvent.pageY };
    } else {
      this.showContextMenu = false;
    }
  }
}
