import { Component, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {DASHBOARD_ARRANGEMENT} from '../configuration/dashboard-arrangement';
import {BrTileReference} from '../configuration/br-tile-reference';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {RequestState} from '../../api/request-handler';
import {PresentationToggleHandlerService} from '../presentation-toggle-handler.service';
import {ApiService} from '../../api/api.service';
import {BrTemplateSelectionService} from '../br-template-selection.service';
import {BrParamsService} from '../br-params.service';
import {DetailQueryParamsService} from '../detail-query-params.service';
import {ApiMapping} from '../../configuration/api-mapping';
import {NodatacheckService} from '../nodatacheck.service';

/**
 * work towards some template like demo
 *
 * work on filters
 *  - api connectivity
 *  - ui components
 *  - apply to kpis
 */
@Component({
  selector: 'app-br-dashboard',
  templateUrl: './br-dashboard.component.html',
  styleUrls: ['./br-dashboard.component.scss']
})

export class BrDashboardComponent implements OnInit, OnDestroy, OnChanges {
  RequestState = RequestState;
  protected requestHandler;
  protected refreshHandleKey;

  defaultOrder = null;
  stdKpiOrder = [];
  arrangement: BrTileReference[] = DASHBOARD_ARRANGEMENT;
  public savedPresentations = [];
  presenetationState = 0;
  slideTileReference = 0;

  state;
  newPresentationStep = 0;

  devApiTools = false;
  apiName = '';
  validationErrors = [];

  testComponentOptions = [
    'BrTextIndicatorComponent',
    'BrPieChartComponent',
    'BrTopItemsComponent',
    'BrLineChartComponent',
    'BrStackedBarLineChartComponent',
    'BrExamplePieChartComponent',
    'ApiTestComponent',
  ];
  testComponentSelectedOption = 'ApiTestComponent';

  currentStep = 0;
  public steps = [
    { label: 'Location', icon: 'globe', optional: true },
    { label: 'Business Segment', icon: 'brightness-contrast', optional: true },
    { label: 'Account/Customer', icon: 'search', isValid: true },
    { label: 'History Range', icon: 'calendar' },
    { label: 'Products', icon: 'cart', optional: true },
    { label: 'Servicing Group', icon: 'ungroup', optional: true },
    { label: 'Template', icon: 'grid' },
  ];
  showValidationMessages = false;

  protected stdKpiListSource = '/qbr/template/std/std_kpi_list';

  noDataRegistryPrep = [];
  noDataRegistry = [];
  presentationToggleSubscription;
  templateSelectionSubscription;
  nodatacheckSubscription;

  constructor(
    private apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private presentationToggleHandlerService: PresentationToggleHandlerService,
    private brTemplateSelectionService: BrTemplateSelectionService,
    private brParamsService: BrParamsService,
    private route: ActivatedRoute,
    private detailQueryParamsService: DetailQueryParamsService,
    private nodatacheckService: NodatacheckService
  ) { }

  ngOnInit(): void {
    this.brParamsService.set('uck', this.brParamsService.get('dashboardUck'));
    this.brParamsService.set('presentedName', this.brParamsService.get('dashboardPresentedName'));
    this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_dashboard'));
    this.navbarBuilderService.setNavbarChoice('business-review');
    this.navbarBuilderService.setNavFilterSelection({
      preferences: false,
      dashboardSelector: false,
      toolSelection: ToolSelectionOptions.businessreview,
      bookmarksFlag: false,
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false
    });
    if (this.route.snapshot.paramMap.get('devapitools') === 'devapitools') {
      this.devApiTools = true;
    }
    // this.startOver();
    this.presentationToggleSubscription = this.presentationToggleHandlerService
      .getToggleChange()
      .subscribe((state) => {
      this.presenetationState = state;
      if (state === 1) {
        this.navbarBuilderService.setIsVisible(false);
      } else {
        this.navbarBuilderService.setIsVisible(true);
      }
    });
    this.requestHandler = this.apiService.get(this.stdKpiListSource);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        // this.stdKpiOrder.push();
        const newOrder = [];
        for (const item of apiData.items) {
          const arranagementItem = this.findArrangementItemByApiSource(item.initial_route);
          if (arranagementItem) {
            if (!arranagementItem.hasOwnProperty('preferences')) {
              arranagementItem.preferences = {};
            }
            arranagementItem.preferences.id = item.id;
            arranagementItem.preferences.metric_group_name = item.metric_group_name;
            arranagementItem.preferences.metric_name = item.metric_name;
            newOrder.push(arranagementItem);
          } else {
            console.warn('need to add config for: ' + item.initial_route + ' to business_review/configuration/dashboard-arrangement');
          }
        }
        this.defaultOrder = newOrder;
        this.arrangement = newOrder;
      }
    });
    this.requestHandler.sendRequest(this.stdKpiListSource);
    this.templateSelectionSubscription = this.brTemplateSelectionService.getTemplateSelectionChange().subscribe((templateDetails) => {
      this.updateKpiOrder(templateDetails);
    });
    this.state = this.brParamsService.get('dashboardState');
    this.nodatacheckSubscription = this.nodatacheckService.getChangedEvent().subscribe((registry) => {
      this.noDataRegistryPrep = registry;
    });
  }
  ngOnChanges() {
    this.noDataRegistry = this.noDataRegistryPrep;
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
    this.presentationToggleSubscription.unsubscribe();
    this.templateSelectionSubscription.unsubscribe();
    this.nodatacheckSubscription.unsubscribe();
  }

  getQueryParams() {
    return this.detailQueryParamsService.getQueryParams();
  }

  findArrangementItemByApiSource(key) {
    for (const item of DASHBOARD_ARRANGEMENT) {
      if (item.apiSource === key) {
        return item;
      }
    }
    return null;
  }

  findArrangementItemById(key) {
    for (const item of DASHBOARD_ARRANGEMENT) {
      if (item && item.hasOwnProperty('preferences') && item.preferences.id == key) {
        return item;
      }
    }
    return null;
  }

  updateKpiOrder(templateDetails) {
    if (templateDetails.id !== 'br_kpi') {
      const order = templateDetails.string.split(',');
      const newOrder = [];
      for (const item of order) {
        const arranagementItem = this.findArrangementItemById(item);
        if (arranagementItem !== null) {
          newOrder.push(arranagementItem);
        }
      }
      this.arrangement = newOrder;
    } else {
      this.arrangement = this.defaultOrder;
    }
  }

  startNew() {
    this.changeState('new');
    this.navbarBuilderService.setIsVisible(false);
  }
  validateSelections() {
    let isValid = true;
    this.validationErrors = [];
    if (this.brParamsService.get('uck') === null || this.brParamsService.get('uck').length < 1) {
      this.validationErrors.push('At least one customer must be selected.');
      this.steps[2].isValid = false;
      this.currentStepChange(2, false);
      isValid = false;
    }
    if (this.brParamsService.get('presentedName') === null || this.brParamsService.get('presentedName') === '') {
      this.validationErrors.push('An Account/Customer Presentation Name is needed.');
      this.steps[2].isValid = false;
      this.currentStepChange(2, false);
      isValid = false;
    }
    return isValid;
  }
  selectPresentation() {
    if (this.validateSelections()) {
      this.changeState('selected');
      this.navbarBuilderService.setIsVisible(true);
    }
    this.brParamsService.set('dashboardUck', this.brParamsService.get('uck'));
    this.brParamsService.set('dashboardPresentedName', this.brParamsService.get('presentedName'));
  }
  changeState(state) {
    this.state = state;
    this.brParamsService.set('dashboardState', state);
  }
  startOver() {
    this.changeState('start');
    setTimeout(() => {
      this.navbarBuilderService.setIsVisible(false);
    });
  }
  currentStepChange(e, withValidate = false) {
    this.newPresentationStep = e;
    if (e == 2) {
      this.showValidationMessages = true;
    } else {
      this.showValidationMessages = false;
    }
    if (withValidate) {// this is to prevent endless loop
      this.validateSelections();
    }
  }
  next() {
    this.slideTileReference += 1;
    if (this.slideTileReference >= this.arrangement.length) {
      this.slideTileReference = 0;
    }
  }
  previous() {
    this.slideTileReference -= 1;
    if (this.slideTileReference < 0) {
      this.slideTileReference = this.arrangement.length - 1;
    }
  }
  addApiCall() {
    ApiMapping[this.apiName] = {
      params_to_include: [
        'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name'
      ] // 'force_call_via_unique_param',
    };
    this.arrangement.push({
      apiSource: this.apiName,
      componentName: this.testComponentSelectedOption,
      preferences: {
        title: this.apiName
      }
    });
  }
  selectTestOption(option) {
    this.testComponentSelectedOption = option;
  }
  nodataCheck(ref) {
    return this.nodatacheckService.hasData(ref);
  }
}
