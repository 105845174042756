import { Component, OnInit, ElementRef, HostListener, ViewChild, Input } from '@angular/core';
import {DetailChoicesService} from '../details/detail-choices.service';
import {DashboardOptions, NavFilterSelection} from '../../configuration/nav-filter-selection';
import {Personas} from '../../configuration/personas';
import {AuthenticationService} from '../../authentication/authentication.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {DetailAccountFilterComponent} from '../details/detail-account-filter/detail-account-filter.component';
import {DetailCapitalServiceAssaySwitchesFilterComponent} from '../details/detail-capital-service-assay-switches-filter/detail-capital-service-assay-switches-filter.component';
import {DetailCustomerFilterComponent} from '../details/detail-customer-filter/detail-customer-filter.component';
import {DetailProductLineFilterComponent} from '../details/detail-product-line-filter/detail-product-line-filter.component';
import {DetailDiseaseStateFilterComponent} from '../details/detail-disease-state-filter/detail-disease-state-filter.component';
import {DetailAssayFilterComponent} from '../details/detail-assay-filter/detail-assay-filter.component';
import {DetailCategorySwitchesFilterComponent} from '../details/detail-category-switches-filter/detail-category-switches-filter.component';

@Component({
  selector: 'app-detail-persona-selector',
  templateUrl: './detail-persona-selector.component.html',
  styleUrls: ['./detail-persona-selector.component.scss']
})
export class DetailPersonaSelectorComponent implements OnInit {
  DashboardOptions = DashboardOptions;
  Personas = Personas;
  PersonaTypes = PersonaTypes;
  navFilterSelection: NavFilterSelection;
  menuViable = false;
  filterSelections = [];

  selectedType = null;
  selectedValue = null;
  selectedLabel = null;
  menuText1 = '';
  menuText2 = '';

  @Input() anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  @Input() popupAlign = { horizontal: 'left', vertical: 'top' };

  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  constructor(
    private authService: AuthenticationService,
    private navbarBuilderService: NavbarBuilderService,
    private detailChoices: DetailChoicesService
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();

    this.authService.onAuth(() => {
      this.updateSelections();
    });

    this.detailChoices.onDisplayCallbacks(() => {
      this.updateSelections();
    });

    this.updateSelections();
    this.onResize();

    // this.detailChoicesService.getFilter('detailCustomerIdFilter');
  }

  /**
   * @todo: think about how display will handle toggle switches
   */
  updateSelections() {
    // this.selectedType = level1Choice.type;
    // this.selectedValue = level1Choice.value;
    // this.selectedLabel = level1Choice.label;
    const titles = {
      detailAccountFilter: 'Account',
      detailAssayFilter: 'Assay',
      detailCustomerIdFilter: 'Customer',
      detailDiseaseStateFilter: 'Disease State',
      detailIsCapitalFilter: 'Is Capital',
      detailIsFieldServiceContractFilter: 'Is Field Service Contract',
      detailIsOtherFilter: 'Is Other',
      detailIsReagentFilter: 'Is Reagent',
      detailIsServiceFilter: 'Is Service',
      detailProductLineFilter: 'Product Line'
    };
    this.filterSelections = [];
    const filterSelections = this.detailChoices.getFilterSelections();
    for (const filterKey in filterSelections) {
      if (filterKey in filterSelections) {
        const filter = filterSelections[filterKey];
        this.filterSelections.push({key: titles[filterKey], value: filter});
      }
    }
    this.menuText1 = 'View Selections';
    this.menuText2 = '';

    // const filterDescriptions = {
    //   DetailCustomerFilterComponent: 'Customer',
    //   DetailProductLineFilterComponent: 'Product Line',
    //   DetailAccountFilterComponent: 'Account',
    //   DetailAssayFilterComponent: 'Assay',
    //   DetailDiseaseStateFilterComponent: 'Disease State',
    //   DetailCategorySwitchesFilterComponent: 'Categories',
    //   DetailCapitalServiceAssaySwitchesFilterComponent: 'Capital/Service/Assay Switches'
    // };
    // // if (this.selectedType === null || this.selectedType === undefined) {
    // const textPieces = [];
    // for (const filterKey of this.navFilterSelection.detailFilters) {
    //   textPieces.push(filterDescriptions[filterKey]);
    // }
    //
    // this.menuText1 = this.buildPhrase(textPieces);
    //
    // this.menuText2 = '';
    // } else {
    //   this.menuText1 = this.selectedType;
    //   this.menuText2 = this.selectedLabel;
    // }
  }

  buildPhrase(list) {
    list[list.length - 1] = 'or ' + list[list.length - 1];
    let str = list.join(', ');
    const pos = str.lastIndexOf(',');
    str = str.substring(0, pos) + str.substring(pos + 1);
    return str;
  }

  onToggle(): void {
    this.menuViable = !this.menuViable;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.menuViable) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target) && this.menuViable) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (target.classList.contains('list-group-item')) {
      return false;
    }
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }

  applyDetailFilters() {
    this.detailChoices.applyFilters();
    return this;
  }

  clearDetailFilters() {
    this.detailChoices.clearFilters();
    return this;
  }
}
