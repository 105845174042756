<div class="customer-data-trends-title">
    Customer Data Trends
    <fa-icon [icon]="faInfoCircle" (click)="openTooltip()"></fa-icon>
</div>
<kendo-dialog
        title="Data Trends"
        *ngIf="opened"
        (close)="close()"
        [width]="'80%'"
        [height]="'80%'"
>
<div class="tooltip2">
    <p>Data displayed is for full weeks only starting on Monday.</p>
</div>
</kendo-dialog>
<div class="tab-buttons nav-tabs" *ngIf="chartData && customerIsSelected">
    <div class="tab-buttons-fold">
        <kendo-buttongroup [selection]="'single'" class="mx-auto" *ngIf="chartData">
            <button kendoButton *ngFor="let chart of chartData"
                    [toggleable]="true"
                    [selected]="(selectedTab === chart.key)"
                    (selectedChange)="onSelectedChange(chart.key)">
                {{chart.title}}
            </button>
            <button kendoButton *ngFor="let grid of gridData"
                    [toggleable]="true"
                    [selected]="(selectedTab === grid.key)"
                    (selectedChange)="onSelectedChange(grid.key)">
                {{grid.title}}
            </button>
        </kendo-buttongroup>
    </div>
</div>
<div class="tab-content" *ngIf="chartData && customerIsSelected">
    <div class="tab" *ngFor="let chart of chartData"
         [class.active-tab]="(selectedTab === chart.key)">
        <div class="no-data" *ngIf="chart.hasOwnProperty('noData')">No Data for {{chart.title}}!</div>
        <app-data-trend-chart [chartData]="chart"></app-data-trend-chart>
    </div>
    <div class="tab" *ngFor="let grid of gridData"
         [class.active-tab]="(selectedTab === grid.key)">
        <div class="no-data" *ngIf="grid.hasOwnProperty('noData')">No Data for {{grid.title}}!</div>
        <app-data-trend-table *ngIf="!grid.hasOwnProperty('noData')" [chartData]="grid"></app-data-trend-table>
    </div>
</div>
<div class="select-customer-required" *ngIf="!chartData || !customerIsSelected">
    Please Select a Client via the Customer Health Metrics Table.
</div>