import {ParamSource} from './param-mapping';

const DashboardMetricParams = [
  // sgm_orgname: orgname should be empty when customerid is set
  'metricid', 'months12', 'culture',
  'abbottid', 'managerid', 'sgm_orgname', 'detail_customerid', 'detail_productlineid', 'sn',
  'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary'
  // 'metrictypeid', 'managerid', 'sn', 'istrack', 'openstar', 'months', 'orgname'
  // 'primary', 'secondary', 'tertiary'
];
const DashboardMetricParamsAmbFalse = [// orgname should be empty of customerid is set
  'metricid', 'months12', 'culture',
  'abbottid', 'managerid', 'sgm_orgname', 'detail_customerid', 'detail_productlineid', 'sn',
  'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary', 'isAMBfalse'
];
const DashboardMetricParamOrders = [
  'metricid', 'months12', 'culture',
  'abbottid', 'managerid', 'sgm_orgname', 'detail_customerid', 'detail_customerid_accountid', 'detail_productlineid', 'sn',
  'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary', 'isAMBfalse', 'issuperuser_false'
  // 'metrictypeid', 'managerid', 'sn', 'istrack', 'openstar', 'months', 'orgname'
  // 'primary', 'secondary', 'tertiary'
];
const DashboardMetricParamsOpenStarTrue = [
  'metricid', 'months12', 'culture',
  'abbottid', 'managerid', 'sgm_orgname', 'detail_customerid', 'detail_productlineid', 'sn',
  'flagship', 'openstartrue', 'istrack', 'primary', 'secondary', 'tertiary'
  // 'metrictypeid', 'managerid', 'sn', 'istrack', 'openstar', 'months', 'orgname'
  // 'primary', 'secondary', 'tertiary'
];
const SGMMetricParams = [
  'metricid', 'abbottid', 'managerid', 'orgname', 'isAMBfalse', 'months3'
];
const AMBParams = [
  'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
  'months12', 'accountid', 'openstar', 'culture', 'isAMB',
  'primary', 'metricid', 'customerid_all', 'issuperuser'
];
const AMBParamsSales = [
  'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
  'months12', 'accountid', 'openstar', 'culture', 'isAMB',
  'primary', 'metricid', 'customerid_all', 'issuperuser',
  'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'ln',
  'detail_isother', 'detail_isreagent'
];
const AMBParamsRCS = [
  'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
  'months12', 'accountid', 'openstar', 'culture', 'isAMB',
  'primary', 'metricid', 'customerid_all', 'issuperuser',
  'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'ln', 'detail_iscapital',
  'detail_isother', 'detail_isreagent', 'detail_isservice'
];
const AMBParamsCompliance = [
  'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
  'months12', 'accountid', 'openstar', 'culture', 'isAMB',
  'primary', 'metricid', 'customerid_all', 'issuperuser',
  'detail_assayid', 'detail_diseasestate', 'ln', 'detail_iscapital',
  'detail_isother', 'detail_isreagent', 'detail_isservice'
];

// detail_customerid
// detail_accountid
// detail_assayid
// detail_diseasestate
// detail_iscapital
// detail_isservice
// detail_isreagent
// detail_isfieldservicecontract
// detail_isother

export const ApiMapping = {
  '/links/en-US-US': {
    params_to_include: []
  },
  'control_tower/customer_health_metrics': {
    params_to_include: [
      'ct_persona_type', 'ct_persona_value',
      'ct_primary', 'ct_secondary', 'ct_tertiary',
      'customerid', 'product_set_number'
    ]
  },
  'control_tower/customer_data_trends_v2': {
    params_to_include: [
      'ct_customer_id', 'product_set_number'
    ]
  },
  'cua/product_families': {
    params_to_include: []
  },
  'cua/get_mycrl_customer_id': {
    params_to_include: ['customerIdHack']
  },
  'cua/customer_health_metrics': {
    params_to_include: [
      'cua_persona_type', 'cua_persona_value',
      'cua_primary', 'cua_secondary', 'cua_tertiary',
      'cua_product_family_set'
      // 'customerid', 'product_set_number'
    ]
    /*
    {
			"personaType": "tss",
			"personaValue": "COLEYAR",
			"primary": true,
			"secondary": true,
			"tertiary": true,
			"uniqueCustomerKey": null,
			"productFamilySetId": 1,
			"accountEntityId": null
		}
		API	param_name	required?	Notes
		customer_health_metrics	personaType	required	valid values: tss,mgr,amb,ambmgr,geo,su,customer,account
		customer_health_metrics	personaValue	required	 For personaType, amb and ambmgr, personaValue has to be amb_upi and amb_mgr_upi (not the 511 ids)
		customer_health_metrics	primary	optional	true|false
		customer_health_metrics	secondary	optional	true|false
		customer_health_metrics	tertiary	optional	true|false
		customer_health_metrics	uniqueCustomerKey	required when personaType is 'customer'	countryCode + '|' + customerNum
		customer_health_metrics	productFamilySetId	required	Id value of the product_family selection
		customer_health_metrics	accountEntityId	required when personaType is 'account'	Can be leveraged while linking from CE PBI dashboard into ONeview
    */
  },
  'cua/customer_health_trend_data': {
    params_to_include: [
      // 'cua_persona_type', 'cua_persona_value',
      'cua_product_family_set', 'cua_uniqueCustomerKey'
    ]
  },
  /*
  {
    "uniqueCustomerKey": "225|307109-0506",
    "productFamilySetId": 3
  }
  */
  'cua/customer_health_alerts': {
    params_to_include: [
      'cua_persona_type', 'cua_persona_value',
      'cua_primary', 'cua_secondary', 'cua_tertiary',
      'cua_product_family_set'
    ]
  },
  /*
  Request data params and validation rules are same for Customer Health Metrics and Customer Health Alerts
		{
			"personaType": "tss",
			"personaValue": "COLEYAR",
			"primary": true,
			"secondary": true,
			"tertiary": true,
			"uniqueCustomerKey": null,
			"productFamilySetId": 1,
			"accountEntityId": null
		}
		{
			"personaType": "geo",
			"personaValue": "Adriana Parra",
			"productFamilySetId": 3
		}
		{
			"uniqueCustomerKey": "056|0050170299",
			"productFamilySetId": 1
		}
  */
  '/search': {
    params_to_include: ['customersearch', 'productlinesearch']
  },
  'cua/customer_search': {
    params_to_include: ['cuacustomersearch', 'cua_search_record_limit']
  },
  'cua/account_search': {
    params_to_include: ['accountsearch', 'cua_search_record_limit']
  },
  '/preference': {
    params_to_include: ['abbottid_auth', 'appid', 'appversion']
  },
  // '/users/savepersona': {
  //   // isAmb false if for service metrics, true if for amb tab
  //   params_to_include: ['isAMBCall', 'loginid_auth', 'personaCall', 'personanameCall', 'personatypeCall']
  // },
  // persona api calls
  '/users/tss': {
    params_to_include: ['abbottid_auth', 'issuperuser_false', 'loggedinuserisambmanager_false', 'managerid_auth'],
    // cache: false // default: true
  },
  '/users/manager': {
    params_to_include: ['abbottid_auth', 'issuperuser_false', 'loggedinuserisambmanager_false', 'managerid_auth'],
  },
  'cua/manager_list': {
    params_to_include: ['abbottid_auth', 'issuperuser_false', 'loggedinuserisambmanager_false', 'managerid_auth'],
  },
  'cua/amb_tss': {
    apiOverride: 'cua/manager_list',
    params_to_include: ['abbottid_auth', 'issuperuser', 'loggedinuserisambmanager', 'managerid_auth'],
  },
  'cua/account_rep_list': {
    params_to_include: [],
  },
  '/geography_service': {
    params_to_include: ['issuperuser_false', 'abbottid_auth'],
    apiOverride: '/geography'
  },
  '/geography_amb': {
    params_to_include: ['issuperuser_true', 'abbottid_auth'],
    apiOverride: '/geography'
  },
  '/customer/details': {
    params_to_include: ['abbottid', 'isAMBfalse', 'isSuperUser_false', 'istrack', 'managerid', 'openstar', 'orgname']
    // , 'flagship', 'productlineid'
  },
  '/customer/details2': {
    params_to_include: ['abbottid', 'isAMBfalse', 'isSuperUser_false', 'istrack', 'openstar', 'orgname'],
    apiOverride: '/customer/details'
    // , 'flagship', 'productlineid'
  },
  '/search/productlinesearchbyid': {// used by customer select menu
    params_to_include: ['search_persona_menu_customerid', 'search_persona_menu_productlineid']
    // , 'flagship', 'productlineid'
  },
  '/search/customersearchbyid': {// used by productline select menu
    params_to_include: ['search_persona_menu_customerid', 'search_persona_menu_productlineid']
    // , 'flagship', 'productlineid'
  },
  '/productline/details': {
    params_to_include: ['abbottid', 'flagship', 'istrack', 'managerid', 'openstar', 'orgname']
    // , 'flagship', 'customerid'
  },
  '/productline/sn': {
    params_to_include: ['abbottid', 'managerid', 'orgname', 'flagship', 'customerid']
  },
  '/users/amb_tss': {
    apiOverride: '/users/tss',
    params_to_include: ['abbottid_auth', 'issuperuser', 'loggedinuserisambmanager', 'managerid_auth'],
  },

  // metric api calls
  '/metrics/hsi': {
    metric_id: 1,
    params_to_include: DashboardMetricParams
  },
  '/metrics/hsi/details': {
    metric_id: 1,
    params_to_include: DashboardMetricParams
  },
  '/metrics/hotsheets': {
    metric_id: 2,
    params_to_include: DashboardMetricParams
  },
  '/metrics/hotsheets/details': {
    metric_id: 2,
    params_to_include: DashboardMetricParams
  },
  '/metrics/opencalls': {
    metric_id: 3,
    params_to_include: DashboardMetricParams
  },
  '/metrics/opencalls/details': {
    metric_id: 3,
    params_to_include: DashboardMetricParams
  },
  '/metrics/cpyr': {
    metric_id: 4,
    params_to_include: DashboardMetricParams
  },
  '/metrics/cpyr/details': {
    metric_id: 4,
    params_to_include: DashboardMetricParams
  },
  '/metrics/servicecost': {
    metric_id: 5,
    params_to_include: DashboardMetricParams
  },
  '/metrics/servicecost/details': {
    metric_id: 5,
    params_to_include: DashboardMetricParams
  },
  '/metrics/alconnected': { // alinstalled
    metric_id: 6,
    params_to_include: DashboardMetricParams
  },
  '/metrics/alconnected/details': { // alinstalled
    metric_id: 6,
    params_to_include: DashboardMetricParams
  },
  '/metrics/alconnectivity': {
    metric_id: 7,
    params_to_include: DashboardMetricParams
  },
  '/metrics/alconnectivity/intermediate': {
    metric_id: 7,
    params_to_include: DashboardMetricParams
  },
  '/metrics/alconnectivity/details': {
    metric_id: 7,
    params_to_include: DashboardMetricParams
  },
  '/metrics/hci': {
    metric_id: 8,
    params_to_include: DashboardMetricParams
  },
  '/metrics/hci/details': {
    metric_id: 8,
    params_to_include: DashboardMetricParams
  },
  '/metrics/sdfr': {
    metric_id: 9,
    params_to_include: DashboardMetricParams
  },
  '/metrics/sdfr/details': {
    metric_id: 9,
    params_to_include: DashboardMetricParams
  },
  '/metrics/ftfr': {
    metric_id: 10,
    params_to_include: DashboardMetricParams
  },
  '/metrics/ftfr/details': {
    metric_id: 10,
    params_to_include: DashboardMetricParams
  },
  '/metrics/uptime': {
    metric_id: 11,
    params_to_include: DashboardMetricParams
  },
  '/metrics/uptime/details': {
    metric_id: 11,
    params_to_include: DashboardMetricParams
  },
  '/metrics/uptime/customerdetails': {
    metric_id: 11,
    params_to_include: [
      'metricid', 'months12', 'culture', 'abbottid', 'managerid', 'orgname', 'customerdetail_id', 'productlineid', 'sn',
      'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary'
    ]
  },
  '/metrics/assay': {
    metric_id: 12,
    params_to_include: DashboardMetricParams
  },
  '/metrics/assay/details': {
    metric_id: 12,
    params_to_include: DashboardMetricParams
  },
  '/metrics/assay/details/customerdetails': {
    metric_id: 12,
    params_to_include: [
      'metricid', 'months12', 'culture', 'abbottid', 'managerid', 'orgname', 'customerdetail_id', 'productlineid', 'sn',
      'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary'
    ]
  },
  '/metrics/cpyp': {
    metric_id: 13,
    params_to_include: DashboardMetricParams
  },
  '/metrics/cpyp/details': {
    metric_id: 13,
    params_to_include: DashboardMetricParams
  },
  '/metrics/ticketrending': {
    metric_id: 14,
    params_to_include: DashboardMetricParams
  },
  '/metrics/ticketrending/details': {
    metric_id: 14,
    params_to_include: DashboardMetricParams
  },
  '/metrics/trt': {
    metric_id: 15,
    params_to_include: DashboardMetricParams
  },
  '/metrics/trt/details': {
    metric_id: 15,
    params_to_include: DashboardMetricParams
  },
  '/metrics/tsb': {
    metric_id: 16,
    params_to_include: DashboardMetricParams
  },
  '/metrics/tsb/details': {
    metric_id: 16,
    params_to_include: DashboardMetricParams
  },
  '/metrics/pm': {
    metric_id: 17,
    params_to_include: DashboardMetricParams
  },
  '/metrics/pm/details': {
    metric_id: 17,
    params_to_include: DashboardMetricParams
  },
  '/metrics/nps': {
    metric_id: 18,
    params_to_include: DashboardMetricParams
  },
  '/metrics/nps/intermediate': {
    metric_id: 18,
    params_to_include: DashboardMetricParams
  },
  '/metrics/nps/detail': {
    metric_id: 18,
    params_to_include: DashboardMetricParams
  },
  '/metrics/servicecosthours': {
    metric_id: 19,
    params_to_include: DashboardMetricParams
  },
  '/metrics/servicecosthours/details': {
    metric_id: 19,
    params_to_include: DashboardMetricParams
  },
  '/metrics/utilization': {
    metric_id: 20,
    params_to_include: DashboardMetricParams
  },
  '/metrics/utilization/intermediate': {
    metric_id: 20,
    params_to_include: [
      'metricid', 'abbottid', 'managerid', 'culture', 'detail_customerid', 'detail_productlineid', 'months6', 'flagship',
      'istrack', 'openstar', 'primary', 'secondary', 'tertiary', 'orgname'
    ]
  },
  '/metrics/utilization/details': {
    metric_id: 20,
    params_to_include: [
      'metricid', 'abbottid', 'managerid', 'culture', 'detail_customerid', 'detail_productlineid', 'months6', 'flagship',
      'istrack', 'openstar', 'primary', 'secondary', 'tertiary', 'orgname'
    ]
  },
  '/metrics/servicecontracts': {
    metric_id: 21,
    params_to_include: DashboardMetricParams
  },
  '/metrics/servicecontracts/details': {
    metric_id: 21,
    params_to_include: DashboardMetricParams
  },
  '/metrics/servicecontracts/details2': {
    metric_id: 21,
    params_to_include: [
      'metricid', 'months12', 'culture', 'abbottid', 'managerid', 'orgname', 'detail_customerid',
      'detail_productlineid', 'sn', 'flagship', 'openstar', 'istrack', 'primary', 'secondary',
      'tertiary', 'servicecontract_entitlementid'
    ]
  },
  '/metrics/rnps': {
    metric_id: 22,
    // params_to_include: AMBParams // NPSMetricParams
    params_to_include: [
      'metricid', 'abbottid_amb', 'managerid_amb', 'flagship', 'istrack', 'productlineid_all', 'months12', 'orgname',
      'accountid', 'openstar', 'culture', 'isAMB', 'primary', 'secondary', 'tertiary', 'customerid_all', 'issuperuser'
    ]
  },
  /*'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
  'months12', 'accountid_all', 'openstar', 'culture', 'isAMB',
  'primary', 'metricid', 'customerid_all', 'issuperuser'*/
  '/metrics/rnps/intermediate': {
    metric_id: 22,
    // params_to_include: [
    //   'metricid', 'abbottid', 'managerid', 'orgname', 'isreagent', 'isfieldservicecontract',
    //   'isother', 'accountid', 'diseasestateid', 'assaysid', 'months12', 'issuperuser'
    // ]
    params_to_include: [
      'metricid', 'abbottid_amb', 'managerid_amb', 'flagship', 'istrack', 'productlineid_all', 'months12', 'orgname',
      'accountid', 'openstar', 'culture', 'isAMB', 'primary', 'secondary', 'tertiary', 'customerid_all', 'issuperuser'
    ]
  },
  '/metrics/rnps/detail': {
    metric_id: 22,
    params_to_include: [
      'metricid', 'abbottid_amb', 'managerid_amb', 'flagship', 'istrack', 'productlineid_all', 'months12', 'orgname',
      'accountid', 'openstar', 'culture', 'isAMB', 'primary', 'secondary', 'tertiary', 'customerid_all', 'issuperuser'
    ]
  },
  '/metrics/CRLSummary': {// current instrument quantity
    metric_id: 23,
    params_to_include: [
      'secondary', 'abbottid', 'flagship', 'tertiary', 'detail_productlineid',
      'istrack', 'months12', 'orgname', 'managerid', 'openstar', 'culture',
      'primary', 'metricid', 'detail_customerid', 'sn'
    ]
  },
  '/metrics/CRLSummary/details': {// current instrument quantity
    metric_id: 23,
    params_to_include: [
      'secondary', 'abbottid', 'flagship', 'tertiary', 'detail_productlineid',
      'istrack', 'months12', 'orgname', 'managerid', 'openstar', 'culture',
      'primary', 'metricid', 'detail_customerid', 'sn'
    ]
  },
  '/metrics/proactive': {
    metric_id: 24,
    params_to_include: DashboardMetricParams
  },
  '/metrics/proactive/details': {
    metric_id: 24,
    params_to_include: DashboardMetricParams
  },
  '/metrics/orders/thumbnail': { // Customer Orders
    metric_id: 25,
    params_to_include: DashboardMetricParamOrders
  },
  '/metrics/orders/intermediate': { // Customer Orders
    metric_id: 25,
    params_to_include: DashboardMetricParamOrders
  },
  '/metrics/orders/details': { // Customer Orders
    metric_id: 25,
    params_to_include: [
      'metricid', 'months12', 'culture',
      'abbottid', 'managerid', 'orgname', 'detail_customerid', 'detail_productlineid', 'sn',
      'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary', 'ordernumber'
    ]
  },
  '/amb_orders': { // Ambassador tab - Customer Orders
    apiOverride: '/metrics/orders/thumbnail',
    metric_id: 25,
    // params_to_include: AMBParams
    params_to_include: [
      'metricid', 'abbottid_amb', 'managerid_amb', 'flagship', 'istrack', 'productlineid_all', 'months12', 'orgname',
      'accountid', 'openstar', 'culture', 'isAMB', 'primary', 'secondary', 'tertiary', 'customerid_all', 'issuperuser'
    ]
  },
  '/amb_orders/intermediate': { // Ambassador tab - Customer Orders
    apiOverride: '/metrics/orders/intermediate',
    metric_id: 25,
    // params_to_include: AMBParams
    params_to_include: [
      'metricid', 'abbottid_amb', 'managerid_amb', 'flagship', 'istrack', 'productlineid_all', 'months12', 'orgname',
      'accountid', 'openstar', 'culture', 'isAMB', 'primary', 'secondary', 'tertiary', 'customerid_all', 'issuperuser'
    ]
  },
  '/amb_orders/details': { // Ambassador tab - Customer Orders
    apiOverride: '/metrics/orders/details',
    metric_id: 25,
    // params_to_include: AMBParams
    params_to_include: [
      'metricid', 'abbottid_amb', 'managerid_amb', 'flagship', 'istrack', 'productlineid_all', 'months12', 'orgname',
      'accountid', 'openstar', 'culture', 'isAMB', 'primary', 'secondary', 'tertiary', 'customerid_all', 'issuperuser', 'ordernumber'
    ]
  },
  '/metrics/sales': {
    metric_id: 26,
    // params_to_include: AMBParamsSales
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
      'months12', 'accountid', 'openstar', 'culture', 'isAMB',
      'primary', 'metricid', 'customerid_all', 'issuperuser',
      'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'ln',
      'detail_isother', 'detail_isreagent'
    ]
  },
  '/metrics/sales/summary': {
    metric_id: 26,
    // params_to_include: AMBParamsSales
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
      'months12', 'accountid', 'openstar', 'culture', 'isAMB',
      'primary', 'metricid', 'customerid_all', 'issuperuser',
      'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'ln',
      'detail_isother', 'detail_isreagent', 'startingrow'
    ]
  },
  '/metrics/sales/details': {
    metric_id: 26,
    // params_to_include: AMBParamsSales
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
      'months12', 'accountid', 'openstar', 'culture', 'isAMB',
      'primary', 'metricid', 'customerid_all', 'issuperuser',
      'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'detail_ln', 'customerdetail_id',
      'detail_isother', 'detail_isreagent'
    ]
  }, // use: amb state must be set for persona service
  '/search/accountdetails': {   // Utilization Workload % (3MA)
    // params_to_include: ['abbottid_amb', 'isAMB', 'issuperuser', 'managerid_amb', 'orgname']
    params_to_include: ['abbottid_amb', 'managerid_amb', 'isAMB', 'issuperuser', 'orgname']
  },
  // use: metricid must be set in api call instance
  '/search/assaysdetails': {   // Utilization Workload % (3MA)
    params_to_include: ['abbottid_amb', 'metricid_call', 'issuperuser', 'managerid_amb', 'detail_diseasestate', 'orgname']
  },
  '/search/diseasestatedetails': {   // Utilization Workload % (3MA)
    params_to_include: ['abbottid_amb', 'metricid_call', 'issuperuser', 'managerid_amb', 'detail_assayid', 'orgname']
  },

  '/metrics/rcs': {
    metric_id: 27,
    params_to_include: AMBParamsRCS
  },
  '/metrics/rcs/summary': {
    metric_id: 27,
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
      'months12', 'accountid', 'openstar', 'culture', 'isAMB',
      'primary', 'metricid', 'customerid_all', 'issuperuser',
      'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'ln', 'detail_iscapital',
      'detail_isother', 'detail_isreagent', 'detail_isservice', 'startingrow'
    ]
  },
  '/metrics/rcs/details': {
    metric_id: 27,
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
      'months12', 'accountid', 'openstar', 'culture', 'isAMB',
      'primary', 'metricid', 'customerid_all', 'issuperuser',
      'detail_isfieldservicecontract', 'detail_assayid', 'detail_diseasestate', 'ln', 'detail_iscapital',
      'detail_isother', 'detail_isreagent', 'detail_isservice', 'detail_ln', 'customerdetail_id',
    ]
  },
  '/metrics/compliance': {
    metric_id: 28,
    params_to_include: AMBParamsCompliance
  },
  '/metrics/compliance/details': {
    metric_id: 28,
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
    'months12', 'accountid', 'openstar', 'culture', 'isAMB',
    'primary', 'metricid', 'customerid_all', 'issuperuser',
    'detail_assayid', 'detail_diseasestate', 'ln', 'detail_iscapital',
    'detail_isother', 'detail_isreagent', 'detail_isservice', 'startingrow'
    ]
  },
  '/metrics/alwaysonuptime': {
    metric_id: 29,
    params_to_include: DashboardMetricParams
  },
  '/metrics/alwaysonuptime/details': {
    metric_id: 29,
    params_to_include: DashboardMetricParams
  },
  '/metrics/fsrutilization': {
    apiOverride: '/metrics/fsrutilization',
    metric_id: 30,
    months3: 3,
    params_to_include: ['metricid', 'abbottid', 'managerid', 'isAMB', 'months3', 'orgname']
  },
  '/metrics/util_workload': {   // Utilization Workload % (3MA)
    apiOverride: '/metrics/fsrutilization/details',
    metric_id: 30,
    params_to_include: SGMMetricParams
  },
  '/metrics/fsrtpd/details': {   // Utilization Workload % (3MA)
    apiOverride: '/metrics/fsrtpd/details',
    metric_id: 30,
    params_to_include: SGMMetricParams
  },
  '/metrics/tickets_per_day': {   // Tickets Per Day Per Person (3MA)
    apiOverride: '/metrics/tpd/details',
    metric_id: 32,
    params_to_include: SGMMetricParams
  },
  '/metrics/servicegrossmargin_customerSGM': {
    apiOverride: '/metrics/servicegrossmargin',
    metric_id: 31,
    params_to_include: DashboardMetricParamsAmbFalse
  },
  '/metrics/servicegrossmargin_amb': {
    apiOverride: '/metrics/servicegrossmargin',
    metric_id: 31,
    params_to_include: AMBParams
  },
  '/metrics/servicegrossmargin_customerSGM_details': {
    apiOverride: '/metrics/servicegrossmargin/details',
    metric_id: 31,
    params_to_include: [
      'metricid', 'months12', 'culture',
      'abbottid_amb', 'managerid', 'sgm_orgname', 'accountid', 'detail_customerid', 'detail_productlineid',
      'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary', 'isAMB'
    ]
  },
  '/metrics/amb_servicegrossmargin_customerSGM_details': {
    apiOverride: '/metrics/servicegrossmargin/details',
    metric_id: 31,
    params_to_include: AMBParams
  },
  '/metrics/servicegrossmargin_customerSGM/grid': {
    apiOverride: '/metrics/servicegrossmargin/grid',
    metric_id: 31,
    params_to_include: [
      'metricid', 'months12', 'culture',
      'abbottid', 'managerid', 'orgname', 'detail_customerid', 'detail_productlineid', 'sn',
      'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary', 'band'
    ]
  },
  '/metrics/amb_servicegrossmargin_customerSGM/grid': {
    apiOverride: '/metrics/servicegrossmargin/grid',
    metric_id: 31,
    params_to_include: [
      'secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
      'months12', 'accountid', 'openstar', 'culture', 'isAMB',
      'primary', 'metricid', 'customerid_all', 'issuperuser', 'band'
    ]
  },
  '/metrics/customerswithoutservicecontracts': {   // Predictive Alerts without Service Contracts
    metric_id: 33,
    params_to_include: DashboardMetricParams
  },
  '/metrics/customerswithoutservicecontracts/grid': {   // Utilization Workload % (3MA)
    metric_id: 33,
    params_to_include: DashboardMetricParams
  },
  '/metrics/customerswithoutservicecontracts/griddetails': {   // Utilization Workload % (3MA)
    metric_id: 33,
    params_to_include: ['metricid', 'months12', 'culture',
      'abbottid', 'managerid', 'orgname', 'detail_customerid', 'detail_productlineid', 'sn',
      'flagship', 'openstar', 'istrack', 'primary', 'secondary', 'tertiary',
      'predictive_detail_sn', 'predictive_detail_customerid', 'predictive_detail_orgname']
  },
  '/customerswithoutservicecontracts_amb': {   // Utilization Workload % (3MA)
    apiOverride: '/metrics/customerswithoutservicecontracts',
    metric_id: 33,
    params_to_include: AMBParams
  },
  '/metrics/amb_customerswithoutservicecontracts': {
    apiOverride: '/metrics/customerswithoutservicecontracts',
    metric_id: 33,
    params_to_include: AMBParams
  },
  '/metrics/amb_customerswithoutservicecontracts/grid': {   // Utilization Workload % (3MA)
    apiOverride: '/metrics/customerswithoutservicecontracts/grid',
    metric_id: 33,
    params_to_include: AMBParams
  },
  '/metrics/amb_customerswithoutservicecontracts/griddetails': {   // Utilization Workload % (3MA)
    apiOverride: '/metrics/customerswithoutservicecontracts/griddetails',
    metric_id: 33,
    params_to_include: ['secondary', 'abbottid_amb', 'managerid_amb', 'orgname', 'flagship', 'tertiary', 'productlineid_all', 'istrack',
    'months12', 'accountid', 'openstar', 'culture', 'isAMB',
    'primary', 'metricid', 'customerid_all', 'issuperuser',
      'predictive_detail_sn', 'predictive_detail_customerid', 'predictive_detail_orgname']
  },
  '/predictive_system_gsmm_ws_alerts': {
    apiOverride: '/alerts',
    params_to_include: ['abbottid_auth', 'alerttype', 'isloadall']
  },
  '/predictive_system_alerts': {
    params_to_include: ['abbottid_auth', 'requestTime']
  },
  '/global_service_alerts': {
    params_to_include: ['requestTime']
  },
  'pushnotifications/subscribe': {
    params_to_include: [
      'abbottid_auth', 'subscribe_endpoint', 'subscribe_expirationTime', 'subscribe_auth', 'subscribe_p256dh'
    ]
  },
  '/qbr/kpi/total_inbound_calls/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/total_inbound_calls/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/total_inbound_calls/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/total_inbound_calls/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tsb/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/nps/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/pm_tickets/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/pm_tickets/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/pm_tickets/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/pm_tickets/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/uptime/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/uptime/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/uptime/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tsb/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tsb/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tsb/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/response_time/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/instrument_test_counts/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/mttr/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/mttr/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/mttr/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_history/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_history/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_history/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_data/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_data/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_data/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/nps/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/nps/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/nps/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/alcm/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/alcm/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/alcm/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/instrument_test_counts/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/instrument_test_counts/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/response_time/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/response_time/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/response_time/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/instrument_test_counts/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },

  '/qbr/kpi/assay_test_counts/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/assay_test_counts/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/assay_test_counts/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/assay_test_counts/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },



  '/qbr/kpi/phn/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/phn/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/phn/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/phn/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/mttr/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_history/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_errors/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_errors/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_errors/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/ticket_errors/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_data/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_text_indicator/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/alcm/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_top_items/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },

  '/qbr/kpi/calls_without_service_number/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/calls_without_service_number/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/calls_without_service_number/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/calls_without_service_number/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },


  '/qbr/kpi/hard_stops/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/hard_stops/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/hard_stops/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/hard_stops/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },


  '/qbr/kpi/abandoned_calls/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/abandoned_calls/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/abandoned_calls/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/abandoned_calls/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },



  '/qbr/kpi/average_wait_time/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/average_wait_time/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/average_wait_time/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/average_wait_time/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },


  '/qbr/kpi/service_level/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/service_level/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/service_level/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/service_level/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/uptime/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_pie_chart/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tcr/thumbnail': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tcr/system_comparison': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tcr/timeline': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_show_sql'
    ]
  },
  '/qbr/kpi/tcr/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/kpi/example_ddt/details': {
    params_to_include: [
      'br_months', 'br_sg', 'br_uck', 'br_pl', 'acct_pres_name', 'br_period', 'br_show_sql'
    ]
  },
  '/qbr/app_config/location/location': {
    params_to_include: []
  },
  '/qbr/search_options/account_customer/search_results': {
    params_to_include: [
      'br_acct_cust_search', 'br_cust_bs', 'br_org', 'br_location', 'br_locallevel'
    ],
    // apiOverride: '/qbr/search_options/account_customer2/search_results'
  },
  '/qbr/app_config/cust_bs/cust_bs': {
    params_to_include: []
  },
  '/qbr/app_config/servicing_groups/servicing_groups': {
    params_to_include: []
  },
  '/qbr/app_config/products/products': {
    params_to_include: ['br_uck']
  },
  '/qbr/template/custom/list_user_templates': {
    params_to_include: ['trigger_list_user_templates_update']
  },
  '/qbr/template/custom/save_user_template_add': {
    apiOverride: '/qbr/template/custom/save_user_template',
    params_to_include: ['br_template_name', 'br_template_string']
  },
  '/qbr/template/custom/save_user_template_edit': {
    apiOverride: '/qbr/template/custom/save_user_template',
    params_to_include: ['br_template_name', 'br_template_string', 'br_template_id']
  },
  '/qbr/template/std/std_kpi_list': {
    params_to_include: []
  },
  '/qbr/user_presentations/custom/list_user_presentations': {
    params_to_include: ['trigger_list_user_presentations_update']
  },
  '/qbr/user_presentations/custom/save_user_presentation': {
    // turns out that presentation params can have any defination and they get saved as presentation_post
    params_to_include: ['br_presentation_name', 'br_presentation_settings']
  },
  '/qbr/user_presentations/custom/get_user_presentation': {
    params_to_include: ['br_presentation_id']
  }
};
console.log(ApiMapping);
//  undetermined routes for api calls / could not find in code exploration
// 'Utilization Workload % (3MA): 30 / Tickets Per Day Per Person (3MA)': 32
// 'Service Gross Margin (SGM %)': guessing 31 but unsure
// 'Customers SGM % (12MA)': 31
// 'Proactive Alerts w/o Service Contracts': 33

// typedef NS_ENUM(NSUInteger, MetricsID) {
//
//   MetricsIDHSI = 1,
//     MetricsIDNPSHotsheets = 2,
//     MetricsIDOpenCalls = 3,
//     MetricsIDCPYR = 4,
//     MetricsIDServiceCost = 5,
//     MetricsIDAbbottLinkConnected = 6,
//     MetricsIDAbbottlinkConnectivity= 7,
//     MetricsIDHCI = 8,
//     MetricsIDSDFR = 9,
//     MetricsIDFTFR = 10,
//     MetricsIDUpTime = 11,
//     MetricsIDTop5Assays = 12,
//     MetricsIDCPYP = 13,
//     MetricsIDTicketTrending = 14,
//     MetricsIDTotalResolutionTime = 15,
//     MetricsIDOpenTSB = 16,
//     MetricsIDPMStatus = 17,
//     MetricsIDCustomerNPSHotSheet = 18,
//     MetricsIDServiceCostHour = 19,
//     MetricsIDLowUtilization = 20,
//     MetricsIDServiceContracts = 21,
//     MetricsIDRNPS = 22,
//     MetricsIDCRLSummary = 23,
//     MetricsIDProactiveTicketRate = 24,
//     MetricsIDCustomerOrders = 25,
//     MetricsIDSales = 26,
//     MetricsIDContractCompliance = 28,
//     MetricsIDRCS = 27,
//     MetricsIDAlwaysOnUptime = 29
// };
