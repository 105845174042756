import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreferenceSelectionService {
  private preferences;

  constructor() { }

  arePreferencesLoaded() {
    // console.log(this.preferences);
    return (this.preferences !== undefined);
  }

  isPreferenceKeyValid(key) {
    if (this.arePreferencesLoaded() && (key in this.preferences)) {
      return true;
    }
    return false;
  }

  getPreference(key) {
    return this.preferences[key];
  }

  getPreferences() {
    return this.preferences;
  }

  setPreferences(preferences) {
    this.preferences = preferences;
    return this;
  }
}
