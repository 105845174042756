<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
            <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                <div class="container">
                    <div class="row">
                        <div class="col-6">
                            <p style="text-align: center;">%</p>
                            <kendo-lineargauge [pointer]="{ value: gaugeData1.value, shape: 'arrow' }" >
                                <kendo-lineargauge-area [height]="230"></kendo-lineargauge-area>
                                <kendo-lineargauge-scale [minorUnit]="gaugeData1.minorUnit" [majorUnit]="gaugeData1.majorUnit" [max]="gaugeData1.max">
                                    <kendo-lineargauge-scale-ranges>
                                        <kendo-lineargauge-scale-range [from]="gaugeData1.compliantScaleFrom" [to]="gaugeData1.compliantScaleTo" color="red">
                                        </kendo-lineargauge-scale-range>
                                        <kendo-lineargauge-scale-range [from]="gaugeData1.notCompliantScaleFrom" [to]="gaugeData1.notCompliantScaleTo" color="#0aec0a">
                                        </kendo-lineargauge-scale-range>
                                    </kendo-lineargauge-scale-ranges>
                                </kendo-lineargauge-scale>
                            </kendo-lineargauge>
                        </div>
                        <div class="col-6">
                            <p style="text-align: center;">{{currency}}</p>
                            <kendo-lineargauge [pointer]="{ value: gaugeData2.value, shape: 'arrow' }" >
                                <kendo-lineargauge-area [height]="230"></kendo-lineargauge-area>
                                <kendo-lineargauge-scale [minorUnit]="gaugeData2.minorUnit" [majorUnit]="gaugeData2.majorUnit" [max]="gaugeData2.max">
                                    <kendo-lineargauge-scale-labels [format]="'#'"></kendo-lineargauge-scale-labels>
                                    <kendo-lineargauge-scale-ranges>
                                        <kendo-lineargauge-scale-range [from]="gaugeData2.compliantScaleFrom" [to]="gaugeData2.compliantScaleTo" color="red">
                                        </kendo-lineargauge-scale-range>
                                        <kendo-lineargauge-scale-range [from]="gaugeData2.notCompliantScaleFrom" [to]="gaugeData2.notCompliantScaleTo" color="#0aec0a">
                                        </kendo-lineargauge-scale-range>
                                    </kendo-lineargauge-scale-ranges>
                                </kendo-lineargauge-scale>
                            </kendo-lineargauge>
                        </div>
                    </div>
                </div>
                <div style="text-align: center;">
                    <img src="../../../../assets/contract-compliance.PNG" />
                </div>
            </a>
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
            No data found
        </div>
    </div>
</app-state-handler>
