import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ChartsModule } from '@progress/kendo-angular-charts';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';
import { PieChartComponent } from './tiles/base/pie-chart/pie-chart.component';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { TileInsertComponent } from './dashboards/tile-insert/tile-insert.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ShapeIndicatorsComponent } from './tiles/base/shape-indicators/shape-indicators.component';
import { DashboardNavbarComponent } from './navigation/dashboard-navbar/dashboard-navbar.component';
import { MetricDetailNavbarComponent } from './navigation/metric-detail-navbar/metric-detail-navbar.component';
import { ParameterSwitchesComponent } from './navigation/parameter-switches/parameter-switches.component';
import { BookmarksComponent } from './navigation/bookmarks/bookmarks.component';
import { SearchComponent } from './navigation/search/search.component';
import { CustomerSelectorComponent } from './filter_selection/customer-selector/customer-selector.component';
import { StackedBarLineChartComponent } from './tiles/base/stacked-bar-line-chart/stacked-bar-line-chart.component';
import { MultiLineChartComponent } from './tiles/base/multi-line-chart/multi-line-chart.component';
import { EmptyValueMaskPipe } from './ui_tools/formatter/empty-value-mask-pipe';
import { CustomerComponent } from './dashboards/customer/customer.component';
import { InstrumentsComponent } from './dashboards/instruments/instruments.component';
import { AmbassadorComponent } from './dashboards/ambassador/ambassador.component';
import { PlaceholderComponent } from './tiles/placeholder/placeholder.component';
import { AreaLineChartComponent } from './tiles/base/area-line-chart/area-line-chart.component';
import { CustomerToggleComponent } from './filter_selection/toggle-switches/customer-toggle/customer-toggle.component';
import { InstrumentToggleComponent } from './filter_selection/toggle-switches/instrument-toggle/instrument-toggle.component';
import { TrackToggleComponent } from './filter_selection/toggle-switches/track-toggle/track-toggle.component';
import { FlagshipToggleComponent } from './filter_selection/toggle-switches/flagship-toggle/flagship-toggle.component';
import { PersonaSelectorComponent } from './filter_selection/persona-selector/persona-selector.component';
import { DropDownsModule} from '@progress/kendo-angular-dropdowns';
import { TssAmbassadorSelectorComponent} from './filter_selection/tss-ambassador-selector/tss-ambassador-selector.component';
import { ManagerSelectorComponent} from './filter_selection/manager-selector/manager-selector.component';
import { InstrumentSelectorComponent} from './filter_selection/instrument-selector/instrument-selector.component';
import { GeoLocationSelectorComponent} from './filter_selection/geo-location-selector/geo-location-selector.component';
import { InputsModule} from '@progress/kendo-angular-inputs';
import { LabelModule} from '@progress/kendo-angular-label';
import { AutoselectListComponent } from './ui_tools/autoselect-list/autoselect-list.component';
import { TextIndicatorsComponent } from './tiles/base/text-indicators/text-indicators.component';
import { VerticalSplitTextIndicatorsComponent } from './tiles/base/vertical-split-text-indicators/vertical-split-text-indicators.component';
import { StateHandlerComponent } from './api/state-handler/state-handler.component';
import { TopStatsListComponent } from './tiles/base/top-stats-list/top-stats-list.component';
import { ToolSelectorComponent } from './navigation/tool-selector/tool-selector.component';
import { AlertsNavbarComponent } from './navigation/alerts-navbar/alerts-navbar.component';
import { CtNavbarComponent } from './navigation/ct-navbar/ct-navbar.component';
import { CtDashboardComponent } from './control_tower/ct-dashboard/ct-dashboard.component';
import { AlertsDashboardComponent } from './alerts/alerts-dashboard/alerts-dashboard.component';
import { Style1Component } from './details/style1/style1.component';
import { Style1GridComponent } from './details/style1-grid/style1-grid.component';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { FilterInsertComponent } from './filter_selection/details/filter-insert/filter-insert.component';
import { DetailCustomerFilterComponent } from './filter_selection/details/detail-customer-filter/detail-customer-filter.component';
import { DetailProductLineFilterComponent } from './filter_selection/details/detail-product-line-filter/detail-product-line-filter.component';
import { SetValuesGridComponent } from './details/set-values-grid/set-values-grid.component';
import { StackedBarChartByProductComponent } from './tiles/base/stacked-bar-chart-by-product/stacked-bar-chart-by-product.component';
import { CustomGridComponent } from './details/custom-grid/custom-grid.component';
import { AbbottBigNumberFormatterPipe } from './ui_tools/formatter/abbott-big-number-formatter-pipe';
import { CustomComplianceGridComponent } from './details/custom-compliance-grid/custom-compliance-grid.component';
import { Style2Component } from './details/style2/style2.component';
import { Style2GridComponent } from './details/style2-grid/style2-grid.component';
import { ComplianceChartComponent } from './tiles/metrics/compliance-chart/compliance-chart.component';
import { LinearGaugeModule } from '@progress/kendo-angular-gauges';
import { SnSelectorComponent } from './filter_selection/sn-selector/sn-selector.component';
import { UtilizationTpdComponent } from './tiles/metrics/utilization-tpd/utilization-tpd.component';
import { ManagerAmbSelectorComponent } from './filter_selection/manager-amb-selector/manager-amb-selector.component';
import { Style3Component } from './details/style3/style3.component';
import { Style3GridComponent } from './details/style3-grid/style3-grid.component';
import { Style4Component } from './details/style4/style4.component';
import { NpsComponent } from './tiles/metrics/nps/nps.component';
import { DetailAccountFilterComponent } from './filter_selection/details/detail-account-filter/detail-account-filter.component';
import { DetailAssayFilterComponent } from './filter_selection/details/detail-assay-filter/detail-assay-filter.component';
import { DetailDiseaseStateFilterComponent } from './filter_selection/details/detail-disease-state-filter/detail-disease-state-filter.component';
import { DetailCategorySwitchesFilterComponent } from './filter_selection/details/detail-category-switches-filter/detail-category-switches-filter.component';
import { DetailCapitalServiceAssaySwitchesFilterComponent } from './filter_selection/details/detail-capital-service-assay-switches-filter/detail-capital-service-assay-switches-filter.component';
import { ServiceContractsLevel2Component } from './details/service-contracts/service-contracts-level2/service-contracts-level2.component';
import { ServiceContractsLevel3Component } from './details/service-contracts/service-contracts-level3/service-contracts-level3.component';
import { ServiceContractsLevel4Component } from './details/service-contracts/service-contracts-level4/service-contracts-level4.component';
import { UptimeCustomerDetailsComponent } from './details/uptime-customer-details/uptime-customer-details.component';
import { SurveyCommentDetailsComponent } from './details/survey-comment-details/survey-comment-details.component';
import { CustomerOrderDetailsComponent } from './details/customer-order-details/customer-order-details.component';
import { PersonaSelector2Component } from './filter_selection/persona-selector2/persona-selector2.component';
import { SearchIconComponent } from './navigation/search-icon/search-icon.component';
import { SGMDetailsComponent } from './details/sgm-details/sgm-details.component';
import { PredictiveAlertsLevel2Component } from './details/predictive-alerts/predictive-alerts-level2/predictive-alerts-level2.component';
import { PredictiveAlertsLevel3Component } from './details/predictive-alerts/predictive-alerts-level3/predictive-alerts-level3.component';
import { DetailPersonaSelectorComponent } from './filter_selection/detail-persona-selector/detail-persona-selector.component';
import { CustomerHealthGridComponent } from './control_tower/customer-health-grid/customer-health-grid.component';
import { CustomerDataTrendsComponent } from './control_tower/customer-data-trends/customer-data-trends.component';
import { CustomerHealthAlertsGridComponent } from './control_tower/customer-health-alerts-grid/customer-health-alerts-grid.component';
import { DataTrendChartComponent } from './control_tower/data-trend-chart/data-trend-chart.component';
import { CtCustomerSelectorComponent } from './filter_selection/ct-customer-selector/ct-customer-selector.component';
import { RnpsSurveyCommentDetailsComponent } from './details/rnps-survey-comment-details/rnps-survey-comment-details.component';
import { SalesSummaryComponent } from './details/sales/sales-summary/sales-summary.component';
import { SalesDetailsComponent } from './details/sales/sales-details/sales-details.component';
import { CtProductSetSelectorComponent } from './filter_selection/ct-product-set-selector/ct-product-set-selector.component';
import { AlertsGridComponent } from './alerts/alerts-grid/alerts-grid.component';
import { DialogsModule, WindowModule } from '@progress/kendo-angular-dialog';
import { SearchPopupComponent } from './filter_selection/search-popup/search-popup.component';
import { ApiselectListComponent } from './ui_tools/apiselect-list/apiselect-list.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { GsAlertsDashboardComponent } from './alerts/gs-alerts-dashboard/gs-alerts-dashboard.component';
import { GsAlertsNavbarComponent } from './navigation/gs-alerts-navbar/gs-alerts-navbar.component';
import { SafeHtmlPipe, SafeStylePipe } from './ui_tools/formatter/safe-html-pipe';
import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { PreferencesComponent } from './navigation/preferences/preferences.component';
import { AccountComponent } from './filter_selection/account/account.component';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { CustomDateFormatterPipe } from './ui_tools/formatter/custom-date-formatter-pipe';
import { DataTrendTableComponent } from './control_tower/data-trend-table/data-trend-table.component';
import { MetricDetailNoFilterComponent } from './navigation/metric-detail-no-filter/metric-detail-no-filter.component';
import { CtAccountSelectorComponent } from './filter_selection/ct-account-selector/ct-account-selector.component';
import { CustomerProfileInfoComponent } from './control_tower/customer-profile-info/customer-profile-info.component';
import { CuaManagerSelectorComponent } from './filter_selection/cua-manager-selector/cua-manager-selector.component';
import { AccountSelectListComponent } from './ui_tools/account-select-list/account-select-list.component';
import { CustomerSelectListComponent } from './ui_tools/customer-select-list/customer-select-list.component';
import { StateHandlerSearchComponent } from './api/state-handler-search/state-handler-search.component';
import { CtManagerAmbSelectorComponent } from './filter_selection/ct-manager-amb-selector/ct-manager-amb-selector.component';
import { AccountRepComponent } from './filter_selection/account-rep/account-rep.component';
import { BrDashboardComponent } from './business_review/br-dashboard/br-dashboard.component';
import { BrTileInsertComponent } from './business_review/br-tile-insert/br-tile-insert.component';
import { BrPlaceholderComponent } from './business_review/snapshots/br-placeholder/br-placeholder.component';
import { BrNavbarComponent } from './business_review/br-navbar/br-navbar.component';
import { BrTemplateEditorComponent } from './business_review/br-template-editor/br-template-editor.component';
import { BrTemplateSelectorComponent } from './business_review/br-template-selector/br-template-selector.component';
import { AccountCustomerSelectorComponent } from './business_review/search_options/account-customer-selector/account-customer-selector.component';
import { AccountCustomerMenuComponent } from './business_review/search_options/account-customer-menu/account-customer-menu.component';
import { MultiLevelSelectWidgetComponent } from './business_review/search_options/multi-level-select-widget/multi-level-select-widget.component';
import { ProductMenuComponent } from './business_review/search_options/product-menu/product-menu.component';
import { HistoryRangeMenuComponent } from './business_review/search_options/history-range-menu/history-range-menu.component';
import { ServicingGroupMenuComponent } from './business_review/search_options/servicing-group-menu/servicing-group-menu.component';
import { BusinessSegmentMenuComponent } from './business_review/search_options/business-segment-menu/business-segment-menu.component';
import { BrStackedBarLineChartComponent } from './business_review/snapshots/br-stacked-bar-line-chart/br-stacked-bar-line-chart.component';
import { UiMultiColumnListSelectComponent } from './business_review/ui-multi-column-list-select/ui-multi-column-list-select.component';
import { PresentationSelectComponent } from './business_review/presentation-select/presentation-select.component';
import { BrLocationSelectorComponent } from './business_review/search_options/br-location-selector/br-location-selector.component';
import { BrProductSelectorComponent } from './business_review/search_options/br-product-selector/br-product-selector.component';
import { BrBusinessSegmentSelectorComponent } from './business_review/search_options/br-business-segment-selector/br-business-segment-selector.component';
import { BrHistoryRangeSelectorComponent } from './business_review/search_options/br-history-range-selector/br-history-range-selector.component';
import { PresentationToggleSwitchComponent } from './business_review/presentation-toggle-switch/presentation-toggle-switch.component';
import { BrServicingGroupSelectorComponent } from './business_review/search_options/br-servicing-group-selector/br-servicing-group-selector.component';
import { BrMultiLineChartComponent } from './business_review/snapshots/br-multi-line-chart/br-multi-line-chart.component';
import { BrExpandedChartViewComponent } from './business_review/detail_pages/br-expanded-chart-view/br-expanded-chart-view.component';
import { BrListViewComponent } from './business_review/detail_pages/br-list-view/br-list-view.component';
import { BrSystemComparisonListViewComponent } from './business_review/system-comparison-pages/br-system-comparison-list-view/br-system-comparison-list-view.component';
import { BrPieChartComponent } from './business_review/snapshots/br-pie-chart/br-pie-chart.component';
import { BrTemplateViewerComponent } from './business_review/br-template-viewer/br-template-viewer.component';
import { BrStackedBarChartByProductComponent } from './business_review/snapshots/br-stacked-bar-chart-by-product/br-stacked-bar-chart-by-product.component';
import { BrTimelineViewComponent } from './business_review/timeline-pages/br-timeline-view/br-timeline-view.component';
import { BrTextIndicatorComponent } from './business_review/snapshots/br-text-indicator/br-text-indicator.component';
import { BrTopItemsComponent } from './business_review/snapshots/br-top-items/br-top-items.component';
import { BrNpsScoresComponent } from './business_review/snapshots/br-nps-scores/br-nps-scores.component';
import { BrLineChartComponent } from './business_review/snapshots/br-line-chart/br-line-chart.component';
import { ApiTestComponent } from './business_review/snapshots/api-test/api-test.component';
import { FormsModule } from '@angular/forms';
import { BrExamplePieChartComponent } from './business_review/snapshots/br-example-pie-chart/br-example-pie-chart.component';
import { FilterPresentationHeaderComponent } from './business_review/filter-presentation-header/filter-presentation-header.component';
import { FilterPresentationFooterComponent } from './business_review/filter-presentation-footer/filter-presentation-footer.component';
import { DynamicDataTableComponent } from './business_review/dynamic-data-table/dynamic-data-table.component';
import { DdtLoaderComponent } from './business_review/ddt-loader/ddt-loader.component';
import { BrDetailComponentViewerComponent } from './business_review/br-detail-component-viewer/br-detail-component-viewer.component';
import { BrMultiBarChartComponent } from './business_review/snapshots/br-multi-bar-chart/br-multi-bar-chart.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    AreaLineChartComponent,
    PieChartComponent,
    DashboardComponent,
    TileInsertComponent,
    ShapeIndicatorsComponent,
    TextIndicatorsComponent,
    DashboardNavbarComponent,
    MetricDetailNavbarComponent,
    ParameterSwitchesComponent,
    BookmarksComponent,
    SearchComponent,
    CustomerSelectorComponent,
    StackedBarLineChartComponent,
    MultiLineChartComponent,
    EmptyValueMaskPipe,
    CustomerComponent,
    InstrumentsComponent,
    AmbassadorComponent,
    PlaceholderComponent,
    AreaLineChartComponent,
    CustomerToggleComponent,
    InstrumentToggleComponent,
    TrackToggleComponent,
    FlagshipToggleComponent,
    PersonaSelectorComponent,
    TssAmbassadorSelectorComponent,
    ManagerSelectorComponent,
    CustomerSelectorComponent,
    InstrumentSelectorComponent,
    GeoLocationSelectorComponent,
    AutoselectListComponent,
    VerticalSplitTextIndicatorsComponent,
    StateHandlerComponent,
    TopStatsListComponent,
    ToolSelectorComponent,
    AlertsNavbarComponent,
    CtNavbarComponent,
    CtDashboardComponent,
    AlertsDashboardComponent,
    Style1Component,
    Style1GridComponent,
    FilterInsertComponent,
    DetailCustomerFilterComponent,
    DetailProductLineFilterComponent,
    SetValuesGridComponent,
    StackedBarChartByProductComponent,
    CustomGridComponent,
    AbbottBigNumberFormatterPipe,
    CustomComplianceGridComponent,
    Style2Component,
    Style2GridComponent,
    ComplianceChartComponent,
    SnSelectorComponent,
    UtilizationTpdComponent,
    ManagerAmbSelectorComponent,
    Style3Component,
    Style3GridComponent,
    Style4Component,
    NpsComponent,
    DetailAccountFilterComponent,
    DetailAssayFilterComponent,
    DetailDiseaseStateFilterComponent,
    DetailCategorySwitchesFilterComponent,
    DetailCapitalServiceAssaySwitchesFilterComponent,
    ServiceContractsLevel2Component,
    ServiceContractsLevel3Component,
    ServiceContractsLevel4Component,
    UptimeCustomerDetailsComponent,
    SurveyCommentDetailsComponent,
    CustomerOrderDetailsComponent,
    PersonaSelector2Component,
    SearchIconComponent,
    SGMDetailsComponent,
    PredictiveAlertsLevel2Component,
    PredictiveAlertsLevel3Component,
    DetailPersonaSelectorComponent,
    RnpsSurveyCommentDetailsComponent,
    SalesSummaryComponent,
    SalesDetailsComponent,
    CustomerHealthGridComponent,
    CustomerDataTrendsComponent,
    CustomerHealthAlertsGridComponent,
    DataTrendChartComponent,
    CtCustomerSelectorComponent,
    RnpsSurveyCommentDetailsComponent,
    CtProductSetSelectorComponent,
    AlertsGridComponent,
    SearchPopupComponent,
    ApiselectListComponent,
    GsAlertsDashboardComponent,
    GsAlertsNavbarComponent,
    SafeHtmlPipe,
    SafeStylePipe,
    PreferencesComponent,
    AccountComponent,
    CustomDateFormatterPipe,
    DataTrendTableComponent,
    MetricDetailNoFilterComponent,
    CtAccountSelectorComponent,
    CustomerProfileInfoComponent,
    CuaManagerSelectorComponent,
    AccountSelectListComponent,
    CustomerSelectListComponent,
    StateHandlerSearchComponent,
    CtManagerAmbSelectorComponent,
    AccountRepComponent,
    BrDashboardComponent,
    BrTileInsertComponent,
    BrPlaceholderComponent,
    BrNavbarComponent,
    BrTemplateEditorComponent,
    BrTemplateSelectorComponent,
    AccountCustomerSelectorComponent,
    AccountCustomerMenuComponent,
    MultiLevelSelectWidgetComponent,
    ProductMenuComponent,
    HistoryRangeMenuComponent,
    ServicingGroupMenuComponent,
    BusinessSegmentMenuComponent,
    BrStackedBarLineChartComponent,
    UiMultiColumnListSelectComponent,
    PresentationSelectComponent,
    BrLocationSelectorComponent,
    BrProductSelectorComponent,
    BrBusinessSegmentSelectorComponent,
    BrHistoryRangeSelectorComponent,
    PresentationToggleSwitchComponent,
    BrServicingGroupSelectorComponent,
    BrMultiLineChartComponent,
    BrExpandedChartViewComponent,
    BrListViewComponent,
    BrSystemComparisonListViewComponent,
    BrPieChartComponent,
    BrStackedBarChartByProductComponent,
    BrTemplateViewerComponent,
    BrTimelineViewComponent,
    BrTextIndicatorComponent,
    BrTopItemsComponent,
    BrNpsScoresComponent,
    BrLineChartComponent,
    ApiTestComponent,
    BrExamplePieChartComponent,
    FilterPresentationHeaderComponent,
    FilterPresentationFooterComponent,
    DynamicDataTableComponent,
    DdtLoaderComponent,
    BrDetailComponentViewerComponent,
    BrMultiBarChartComponent
  ],
  imports: [
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    GridModule,
    ExcelModule,
    ButtonsModule,
    PopupModule,
    DropDownsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FontAwesomeModule,
    InputsModule,
    LayoutModule,
    LabelModule,
    LinearGaugeModule,
    TooltipModule,
    DialogsModule,
    WindowModule,
    ListViewModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MsalModule.forRoot({
      auth: {
        clientId: environment.clientId,
        authority: 'https://login.microsoftonline.com/' + environment.tenantId, // This is your tenant info
        redirectUri: environment.authRedirectUrl // This is your redirect URI
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // set to true for IE 11
      }
    }
    // ,
    //   {
    //     popUp: true,//isIE?
    //     consentScopes: [
    //       'openid',
    //     ]
    //   }
      )
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: MsalInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    AreaLineChartComponent,
    PieChartComponent,
    MultiLineChartComponent,
    ShapeIndicatorsComponent,
    TextIndicatorsComponent,
    VerticalSplitTextIndicatorsComponent,
    StackedBarLineChartComponent,
    PlaceholderComponent,
    TopStatsListComponent,
    DetailCustomerFilterComponent,
    DetailProductLineFilterComponent,
    StackedBarChartByProductComponent,
    ComplianceChartComponent,
    UtilizationTpdComponent,
    NpsComponent,
    DetailAccountFilterComponent,
    DetailAssayFilterComponent,
    DetailDiseaseStateFilterComponent,
    DetailCategorySwitchesFilterComponent,
    DetailCapitalServiceAssaySwitchesFilterComponent,
    BrPlaceholderComponent,
    BrStackedBarLineChartComponent,
    BrMultiLineChartComponent,
    BrMultiBarChartComponent,
    BrPieChartComponent,
    BrStackedBarChartByProductComponent,
    BrTextIndicatorComponent,
    BrTopItemsComponent,
    BrNpsScoresComponent,
    BrLineChartComponent,
    BrExamplePieChartComponent,
    ApiTestComponent

  ]
})
export class AppModule { }
