<div *ngIf="state === RequestState.Complete">
    <div class="search-container" *ngIf="hasSearch">
        <kendo-maskedtextbox class="search-box" (valueChange)="onValueChange($event)" [style.width]="'100%'"></kendo-maskedtextbox>
    </div>
    <div class="filter-results" *ngIf="result.data.length > 0" [style.height.px]="height">
        <div class="list-group">
            <!--<a class="list-group-item list-group-item-action"-->
               <!--*ngFor="let item of result.data"-->
               <!--[class.active]="(item[keyField]===selectedOption)"-->
               <!--(click)="onListItemSelect(item[keyField])">-->
                <!--{{item[displayField]}}-->
            <!--</a>-->
            <kendo-listview
                    height="300"
                    [data]="view"
                    [containerClass]="'k-d-flex k-flex-col k-flex-nowrap'"
                    (scrollBottom)="loadMore()"
            >
                <ng-template
                        kendoListViewItemTemplate
                        let-dataItem="dataItem"
                        let-isLast="isLast"
                >
                    <a class="list-group-item list-group-item-action"
                       [class.active]="(dataItem[keyField]===selectedOption)"
                       (click)="onListItemSelect(dataItem[keyField])">
                        <fa-icon [icon]="faCheck" class="check" *ngIf="useCheckIcon"></fa-icon>
                        {{dataItem[displayField]}}
                        <span class="sub-label" *ngIf="subDisplayField">{{dataItem[subDisplayField]}}</span>
                    </a>
                </ng-template>
            </kendo-listview>
        </div>
    </div>
</div>
<div class="loading-message" *ngIf="state !== RequestState.Complete || result.data.length === 0">
    <app-state-handler [requestState]="state" [data]="result.data"></app-state-handler>
</div>