import { OnInit, OnDestroy } from '@angular/core';
import {ApiService} from '../../api/api.service';
import {DetailChoicesService} from './detail-choices.service';

export class ToggleSwitches implements OnInit, OnDestroy {
  config = [];
  updateListener = '';
  selectedValues = {};

  constructor(
    private apiService: ApiService,
    private detailChoicesService: DetailChoicesService
  ) { }

  ngOnInit(): void {
    for (const item of this.config) {
      this.selectedValues[item.key] = this.detailChoicesService.getFilter(item.key);
    }
    this.detailChoicesService.onUpdate(this.updateListener, (diseaseState) => {
      // update selectedItem to empty
      for (const item of this.config) {
        this.selectedValues[item.key] = item.defaultValue;
      }
    });
  }

  ngOnDestroy(): void {
    this.detailChoicesService.clearUpdateListener(this.updateListener);
  }

  toggleSwitchValue(key, value) {
    value = !value;
    this.selectedValues[key] = value;
    this.detailChoicesService.setFilter(key, value, true);
  }
}
