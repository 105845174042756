import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NodatacheckService {
  registry = [];
  changed = new EventEmitter<any>(true);

  constructor() { }

  setHasData(ref, value: boolean) {
    const currentValue = this.registry[ref];
    this.registry[ref] = value;
    if (currentValue !== value) {
      this.changed.emit(this.registry);
    }
  }

  getChangedEvent() {
    return this.changed;
  }

  hasData(ref) {
    return this.registry[ref];
  }
}
