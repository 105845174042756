import { Component, OnInit } from '@angular/core';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { Style3Pages } from 'src/app/configuration/details/style3-pages';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';
import {Location} from '@angular/common';

@Component({
  selector: 'app-style3',
  templateUrl: './style3.component.html',
  styleUrls: ['./style3.component.scss']
})
export class Style3Component implements OnInit {

  style3Pages = Style3Pages;
  config = null;

  constructor(
    private location: Location,
    private navbarBuilderService: NavbarBuilderService
  ) { }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style3Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style3Pages[currentRouterLocation];
      // console.log(this.config);

      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: true,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });
    }
  }
}
