import {StackedBarChartByProductComponent} from '../../tiles/base/stacked-bar-chart-by-product/stacked-bar-chart-by-product.component';
import {DetailAssayFilterComponent} from '../../filter_selection/details/detail-assay-filter/detail-assay-filter.component';

export const Style1Pages = {
  '/hsi': {
    metricId: 1,
    serviceVsAmb: true,
    pageTitle: 'Highly Serviced Instruments',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/hsi',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/hsi_0',
    table: {
      type: 'style1',
      exportFileName: 'hsi.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'nonHsi',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Non-HSI',
            stackIndex: 1
          }
        },
        {
          key: 'hsi',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'HSI',
            stackIndex: 0
          }
        },
        {
          key: 'totals',
          parser: 'stackTotals',
          inject: ['hsi', 'nonHsi'],
          attributes: {
            label: 'Total',
          }
        },
        {
          key: 'percents',
          parser: 'percentGoals',
          inject: 'apiData',
          attributes: {
            label: 'HSI %',
            stackGoalsIndex: 0
          }
        },
        {
          key: 'percentGoals',
          parser: 'percentGoals',
          inject: 'apiData',
          attributes: {
            label: 'HSI % Goal',
            stackGoalsIndex: 1
          }
        }
      ],
      dataRows: ['nonHsi', 'hsi', 'totals', 'percents', 'percentGoals'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[1],
          apiData.stackcolors[0],
          '',
          apiData.stackgoalcolors[0],
          apiData.stackgoalcolors[1]
        ];
      }
    }
  },
  '/cpyr': {
    metricId: 4,
    serviceVsAmb: true,
    pageTitle: 'CPY-R',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/cpyr',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false,
        excludeLines: [3]
      }
    },
    apiRequestHandler: '/metrics/cpyr_0',
    table: {
      type: 'style1',
      exportFileName: 'cpyr.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'cpyr-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'CPY-R 12MA',
            stackIndex: 0
          }
        },
        {
          key: 'cpyr-3ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'CPY-R 3MA',
            stackIndex: 1
          }
        },
        {
          key: 'cpyr-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'CPY-R Goal',
            stackIndex: 2
          }
        },
        {
          key: 'census',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Census',
            stackIndex: 3
          }
        },
      ],
      dataRows: ['cpyr-12ma', 'cpyr-3ma', 'cpyr-goal', 'census'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          ''
        ];
      }
    }
  },
  '/servicecost': {
    metricId: 5,
    serviceVsAmb: true,
    pageTitle: 'Service Cost',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/servicecost',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        valueAxis1Label: '#K',
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/servicecost_0',
    table: {
      type: 'style1',
      exportFileName: 'servicecost.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'labor',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Labor',
            stackIndex: 0
          }
        },
        {
          key: 'parts',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Parts',
            stackIndex: 1
          }
        },
        {
          key: 'labor_and_parts',
          parser: 'stackTotals',
          inject: ['labor', 'parts'],
          attributes: {
            label: 'Labor and Parts',
          }
        },
        {
          key: 'labor_and_parts_goal',
          parser: 'percentGoals',
          inject: 'apiData',
          attributes: {
            label: 'Labor and Parts Goal',
            stackGoalsIndex: 0
          }
        },
        {
          key: 'travel',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Travel',
            stackIndex: 2
          }
        },
        {
          key: 'total_service_cost',
          parser: 'stackTotals',
          inject: ['labor_and_parts', 'travel'],
          attributes: {
            label: 'Total Service Cost',
          }
        }
      ],
      dataRows: ['labor', 'parts', 'labor_and_parts', 'labor_and_parts_goal', 'travel', 'total_service_cost'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[0],
          apiData.stackcolors[1],
          '',
          apiData.stackgoalcolors[0],
          apiData.stackcolors[2],
          ''
        ];
      }
    }
  },
  '/alconnected': {
    metricId: 6,
    serviceVsAmb: true,
    pageTitle: 'AbbottLink - Installed',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/alconnected', // this is actually abbottlink installed but uses alconnected
      componentName: 'PieChartComponent',
      preferences: {
        showGoal: true,
        goalPercentageSlideLabel: 'Installed',
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/alconnected_0',
    table: {
      type: 'set-values',
      exportFileName: 'alconnected.xlsx',
      level2DetailLink: 'details',
      dataCols: [
        {
          ref: 'percentInstalled',
          description: '% Installed',
          width: 80
        },
        {
          ref: 'numberInstalled',
          description: '# Installed',
          width: 80
        },
        {
          ref: 'percentNotInstalled',
          description: '% Not Installed',
          width: 80
        },
        {
          ref: 'numberNotInstalled',
          description: '# Not Installed',
          width: 80
        },
        {
          ref: 'goalPercent',
          description: 'Goal %',
          width: 80
        }
      ],
      dataValues: (apiData) => {
        return {
          percentInstalled: apiData.values[0].percentage,
          numberInstalled: apiData.values[0].count,
          percentNotInstalled: apiData.values[1].percentage,
          numberNotInstalled: apiData.values[1].count,
          goalPercent: apiData.values[0].goalpercentage
        };
      },
      valueColors: (apiData) => {
        return [
          apiData.values[0].color,
          '',
          apiData.values[1].color,
          '',
          '',
        ];
      }
    }
  },
  '/alconnectivity': {
    metricId: 7,
    serviceVsAmb: true,
    pageTitle: 'AbbottLink - Connectivity',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/alconnectivity/intermediate',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/alconnectivity/intermediate_0',
    table: {
      type: 'style1',
      exportFileName: 'alconnectivity.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'online_percentage',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Online Percentage',
            stackIndex: 0
          }
        },
        {
          key: 'more_than_8_days',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: '> 8 days',
            stackIndex: 1
          }
        },
        {
          key: '4_7_days',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: '4 - 7 days',
            stackIndex: 2
          }
        },
        {
          key: 'goal_percent',
          parser: 'percentGoals',
          inject: 'apiData',
          attributes: {
            label: 'Goal %',
            stackGoalsIndex: 0
          }
        }
      ],
      dataRows: ['online_percentage', 'more_than_8_days', '4_7_days', 'goal_percent'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[0],
          apiData.stackcolors[1],
          apiData.stackcolors[2],
          apiData.stackgoalcolors[0],
        ];
      }
    }
  },
  '/hci': {
    metricId: 8,
    serviceVsAmb: true,
    pageTitle: 'High Cost Instruments',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/hci',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/hci_0',
    table: {
      type: 'style1',
      exportFileName: 'hsi.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'nonHci',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Non-HCI',
            stackIndex: 1
          }
        },
        {
          key: 'hci',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'HCI',
            stackIndex: 0
          }
        },
        {
          key: 'totals',
          parser: 'stackTotals',
          inject: ['hci', 'nonHci'],
          attributes: {
            label: 'Total',
          }
        },
        {
          key: 'percents',
          parser: 'percentGoals',
          inject: 'apiData',
          attributes: {
            label: 'HCI Percentage',
            stackGoalsIndex: 0
          }
        },
        {
          key: 'percentGoals',
          parser: 'percentGoals',
          inject: 'apiData',
          attributes: {
            label: 'HCI % Goal',
            stackGoalsIndex: 1
          }
        }
      ],
      dataRows: ['nonHci', 'hci', 'totals', 'percents', 'percentGoals'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[1],
          apiData.stackcolors[0],
          '',
          apiData.stackcolors[0],
          apiData.stackcolors[1]
        ];
      }
    }
  },
  '/customerNps': {
    metricId: 18,
    serviceVsAmb: true,
    pageTitle: 'Customer NPS',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/nps/intermediate',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false,
        yaxisleftlabel: 'NPS Score',
        min: -105
      }
    },
    apiRequestHandler: '/metrics/nps/intermediate_0',
    table: {
      type: 'style1',
      exportFileName: 'customer-nps.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'overallCustomer',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Overall Customer',
            stackIndex: 0
          }
        },
        {
          key: 'fieldService',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Field Service',
            stackIndex: 1
          }
        }
      ],
      dataRows: ['overallCustomer', 'fieldService'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
        ];
      }
    }
  },
  '/sdfr': {
    metricId: 9,
    serviceVsAmb: true,
    pageTitle: 'Same Day Fix Rate',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/sdfr',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/sdfr_0',
    table: {
      type: 'style1',
      exportFileName: 'sdfr.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'sdfr-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'SDFR 12MA',
            stackIndex: 0
          }
        },
        {
          key: 'sdfr-1ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'SDFR 1MA',
            stackIndex: 1
          }
        },
        {
          key: 'sdfr-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'SDFR Goal',
            stackIndex: 2
          }
        },
        {
          key: 'ticket_qty',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Ticket Qty',
            stackIndex: 3
          }
        },
      ],
      dataRows: ['sdfr-12ma', 'sdfr-1ma', 'sdfr-goal', 'ticket_qty'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          ''
        ];
      }
    }
  },
  '/ftfr': {
    metricId: 10,
    serviceVsAmb: true,
    pageTitle: 'First Time Fix Rate',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/ftfr',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false,
        excludeLines: [2, 3]
        // rowColorOverrides: [null, null]// , '#6f42c1', '#dc3545']
      }
    },
    apiRequestHandler: '/metrics/ftfr_0',
    table: {
      type: 'style1',
      exportFileName: 'ftfr.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'ftfr-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'FTFR 12 MA',
            stackIndex: 0
          }
        },
        {
          key: 'ftfr-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Goal',
            stackIndex: 1
          }
        },
        {
          key: 'ftf',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'First Time Fixes',
            stackIndex: 2
          }
        },
        {
          key: 'nftf',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Non-First Time Fixes',
            stackIndex: 3
          }
        },
      ],
      dataRows: ['ftfr-12ma', 'ftfr-goal', 'ftf', 'nftf'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          '#6f42c1',
          '#dc3545'
        ];
      }
    }
  },
  '/uptime': {
    metricId: 11,
    serviceVsAmb: true,
    pageTitle: 'Uptime',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/uptime',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/uptime_0',
    table: {
      type: 'style1',
      exportFileName: 'uptime.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'uptime-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Uptime 12MA',
            stackIndex: 0
          }
        },
        {
          key: 'uptime-1ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Uptime 1MA',
            stackIndex: 1
          }
        },
        {
          key: 'uptime-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Uptime Goal',
            stackIndex: 2
          }
        }
      ],
      dataRows: ['uptime-12ma', 'uptime-1ma', 'uptime-goal'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2]
        ];
      }
    }
  },
  '/cpyp': {
    metricId: 13,
    serviceVsAmb: true,
    pageTitle: 'CPY-P',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/cpyp',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false,
        colorsByIndex: [0, 2]
      }
    },
    apiRequestHandler: '/metrics/cpyp_0',
    table: {
      type: 'style1',
      exportFileName: 'cpyp.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'cpyp-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'CPY-P 12MA',
            stackIndex: 0
          }
        },
        {
          key: 'cpyp-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'CPY-P Goal',
            stackIndex: 1
          }
        },
      ],
      dataRows: ['cpyp-12ma', 'cpyp-goal'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1]
        ];
      }
    }
  },
  '/ticketrending': {
    metricId: 14,
    serviceVsAmb: true,
    pageTitle: 'Ticket Trending',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/ticketrending',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/ticketrending_0',
    table: {
      type: 'style1',
      exportFileName: 'ticketrending.xlsx',
      level2DetailLink: 'details',
      colorRowDescriptions: true,
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'complaintWFsv',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Complaint w/FSV',
            stackIndex: 0
          }
        },
        {
          key: 'serviceWFsv',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Service w/FSV',
            stackIndex: 1
          }
        },
        {
          key: 'proactive',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Proactive',
            stackIndex: 2
          }
        },
        {
          key: 'fsrHwsPhoneFix',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'FSR/HWS Phone Fix',
            stackIndex: 3
          }
        },
        {
          key: 'cscPhoneFix',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'CSC Phone Fix',
            stackIndex: 4
          }
        },
        {
          key: 'pm',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'PM',
            stackIndex: 5
          }
        },
        {
          key: 'installIntegration',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Install/ Integration',
            stackIndex: 6
          }
        },
        {
          key: 'tsb',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'TSB',
            stackIndex: 7
          }
        },
        {
          key: 'inquiry',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Inquiry',
            stackIndex: 8
          }
        },
        {
          key: 'otherTicket',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Other Ticket',
            stackIndex: 9
          }
        }
      ],
      dataRows: [
        'complaintWFsv', 'serviceWFsv', 'proactive',
        'fsrHwsPhoneFix', 'cscPhoneFix', 'pm', 'installIntegration',
        'tsb', 'inquiry', 'otherTicket'
      ], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[0],
          apiData.stackcolors[1],
          apiData.stackcolors[2],
          apiData.stackcolors[3],
          apiData.stackcolors[4],
          apiData.stackcolors[5],
          apiData.stackcolors[6],
          apiData.stackcolors[7],
          apiData.stackcolors[8],
          apiData.stackcolors[9]
        ];
      }
    }
  },
  '/trt': {
    metricId: 15,
    serviceVsAmb: true,
    pageTitle: 'Total Resolution Time',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/trt',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/trt_0',
    table: {
      type: 'style1',
      exportFileName: 'trt.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'trt-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '12MA',
            stackIndex: 0
          }
        },
        {
          key: 'trt-3ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '3MA',
            stackIndex: 1
          }
        },
        {
          key: 'trt-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Goal',
            stackIndex: 2
          }
        }
      ],
      dataRows: ['trt-12ma', 'trt-3ma', 'trt-goal'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          ''
        ];
      }
    }
  },
  '/servicecosthours': {
    metricId: 19,
    serviceVsAmb: true,
    pageTitle: 'Service Hours',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/servicecosthours',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/servicecosthours_0',
    table: {
      type: 'style1',
      exportFileName: 'servicecosthours.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'total-service-hours',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Total Service Hours',
            stackIndex: 0
          }
        },
        {
          key: 'labor-hours-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Labor Hours (12MA)',
            stackIndex: 1
          }
        },
        {
          key: '12ma-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Goal (12 MA)',
            stackIndex: 2
          }
        },
        {
          key: 'total-service-hours-3ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Total Service Hours (3MA)',
            stackIndex: 3
          }
        },
        {
          key: 'labor-hours-3ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Labor Hours (3 MA)',
            stackIndex: 4
          }
        },
      ],
      dataRows: ['total-service-hours', 'labor-hours-12ma', '12ma-goal', 'total-service-hours-3ma', 'labor-hours-3ma'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          apiData.linecolors[3],
          apiData.linecolors[4],
        ];
      }
    }
  },
  '/utilization': {
    metricId: 20,
    serviceVsAmb: true,
    pageTitle: 'Low Utilization',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    charts: [
      {
        apiSource: '/metrics/utilization/intermediate',
        componentName: 'StackedBarChartByProductComponent',
        preferences: {
          title: 'Lowest Range',
          useDataArray: true,
          showTitle: false,
          apiObjectKey: 0
        }
      },
      {
        apiSource: '/metrics/utilization/intermediate',
        componentName: 'StackedBarChartByProductComponent',
        preferences: {
          title: '2nd Lowest Range',
          useDataArray: true,
          showTitle: false,
          apiObjectKey: 1
        }
      }
    ],
    apiRequestHandler: '/metrics/utilization/intermediate_0',
    table: {
      type: 'custom',
      exportFileName: 'low_utilization.xlsx',
      level2DetailLink: 'details',
      dataCols: [
        {
          ref: 'instrument',
          description: 'Instrument',
          width: 80
        },
        {
          ref: 'lowRangeNumberOfTests',
          description: 'Low Range # Of Tests',
          customStyle:  { 'text-align': 'right' },
          width: 80
        },
        {
          ref: 'lowRangeInstrumentsCount',
          description: 'Low Range Instrument Count',
          customStyle:  { 'text-align': 'right' },
          width: 80
        },
        {
          ref: 'secondLowestRange',
          description: '2nd Lowest Range',
          customStyle:  { 'text-align': 'right' },
          width: 80
        },
        {
          ref: 'secondLowestInstrumentCount',
          description: '2nd Lowest Instrument Count',
          customStyle:  { 'text-align': 'right' },
          width: 80
        }
      ]
    }
  },
  '/proactive': {
    metricId: 24,
    serviceVsAmb: true,
    pageTitle: 'Proactive Ticket Rate %',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/proactive',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/proactive_0',
    table: {
      type: 'style1',
      exportFileName: 'proactive.xlsx',
      level2DetailLink: 'details',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'proactive-12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Proactive % 12MA',
            stackIndex: 0
          }
        },
        {
          key: 'proactive-1ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Proactive % 1MA',
            stackIndex: 1
          }
        },
        {
          key: 'proactive-goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Proactive % Goal',
            stackIndex: 2
          }
        },
        {
          key: 'overall-ticket-qty',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Overall Ticket Qty',
            stackIndex: 3
          }
        },
      ],
      dataRows: ['proactive-12ma', 'proactive-1ma', 'proactive-goal', 'overall-ticket-qty'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          apiData.linecolors[3]
        ];
      }
    }
  },
  '/orders': {
    metricId: 25,
    serviceVsAmb: true,
    pageTitle: 'Customer Orders',
    filters: ['DetailCustomerFilterComponent'],
    tileReference: {
      apiSource: '/metrics/orders/thumbnail',
      componentName: 'PieChartComponent',
      preferences: {
        showGoal: false,
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/orders/thumbnail_0',
    table: {
      type: 'set-values',
      exportFileName: 'customerorders.xlsx',
      level2DetailLink: 'intermediate',
      dataCols: [
        {
          ref: 'percentHold',
          description: '% Hold',
          width: 80
        },
        {
          ref: 'numberHold',
          description: '# Hold',
          width: 80
        },
        {
          ref: 'percentOpen',
          description: '% Open',
          width: 80
        },
        {
          ref: 'numberOpen',
          description: '# Open',
          width: 80
        },
        {
          ref: 'percentClosed',
          description: '% Closed',
          width: 80
        },
        {
          ref: 'numberClosed',
          description: '# Closed',
          width: 80
        }
      ],
      dataValues: (apiData) => {
        return {
          percentHold: apiData.values[0].percentage,
          numberHold: apiData.values[0].quantity,
          percentOpen: apiData.values[1].percentage,
          numberOpen: apiData.values[1].quantity,
          percentClosed: apiData.values[2].percentage,
          numberClosed: apiData.values[2].quantity,
        };
      },
      valueColors: (apiData) => {
        return [
          apiData.values[0].color,
          '',
          apiData.values[1].color,
          '',
          apiData.values[2].color,
          '',
        ];
      }
    }
  },
  '/alwaysonuptime': {
    metricId: 29,
    serviceVsAmb: true,
    pageTitle: 'ALWAYS ON Uptime Summary',
    filters: ['DetailCustomerFilterComponent', 'DetailProductLineFilterComponent'],
    tileReference: {
      apiSource: '/metrics/alwaysonuptime',
      componentName: 'PieChartComponent',
      preferences: {
        showGoal: false,
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/alwaysonuptime_0',
    table: {
      type: 'set-values',
      exportFileName: 'alwaysonuptime.xlsx',
      level2DetailLink: 'details',
      dataCols: [
        {
          ref: 'percentGuaranteeAchieved',
          description: '% Guarantee Achieved',
          width: 80
        },
        {
          ref: 'numberGuaranteeAchieved',
          description: '# Guarantee Achieved',
          width: 80
        },
        {
          ref: 'percentGuaranteeNotAchieved',
          description: '% Guarantee Not Achieved',
          width: 80
        },
        {
          ref: 'numberGuaranteeNotAchieved',
          description: '# Guarantee Not Achieved',
          width: 80
        },
        {
          ref: 'percentNotEligible',
          description: '% Not Eligible',
          width: 80
        },
        {
          ref: 'numberNotEligible',
          description: '# Not Eligible',
          width: 80
        }
      ],
      dataValues: (apiData) => {
        return {
          percentGuaranteeAchieved: apiData.values[0].percentage,
          numberGuaranteeAchieved: apiData.values[0].count,
          percentGuaranteeNotAchieved: apiData.values[1].percentage,
          numberGuaranteeNotAchieved: apiData.values[1].count,
          percentNotEligible: apiData.values[2].percentage,
          numberNotEligible: apiData.values[2].count,
        };
      },
      valueColors: (apiData) => {
        return [
          apiData.values[0].color,
          '',
          apiData.values[1].color,
          '',
          apiData.values[2].color,
          '',
        ];
      }
    }
  },
  '/util_workload': {
    metricId: 30,
    serviceVsAmb: true,
    pageTitle: 'Utilization',
    filters: [],
    tileReference: {
      apiSource: '/metrics/util_workload',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false,
        excludeLines: [4, 5]
      }
    },
    apiRequestHandler: '/metrics/util_workload_0',
    table: {
      type: 'style1',
      exportFileName: 'utilization.xlsx',
      level2DetailLink: 'details',
      colorRowDescriptions: true,
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: '1ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '1MA',
            stackIndex: 0
          }
        },
        {
          key: '3ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '3MA',
            stackIndex: 1
          }
        },
        {
          key: '12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '12MA',
            stackIndex: 2
          }
        },
        {
          key: 'goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'GOAL',
            stackIndex: 3
          }
        },
        {
          key: 'travel_labor_hrs',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Travel/ Labor Hrs',
            stackIndex: 4
          }
        },
        {
          key: 'availible_hrs',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Avail Hours',
            stackIndex: 5
          }
        }
      ],
      dataRows: ['1ma', '3ma', '12ma', 'goal', 'travel_labor_hrs', 'availible_hrs'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          apiData.linecolors[3],
          apiData.linecolors[4],
          apiData.linecolors[5],
        ];
      }
    }
  },
  '/tickets_per_day': {
    metricId: 32,
    serviceVsAmb: true,
    pageTitle: 'Tickets Per Day',
    filters: [],
    tileReference: {
      apiSource: '/metrics/tickets_per_day',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/tickets_per_day_0',
    table: {
      type: 'style1',
      exportFileName: 'tickets_per_day.xlsx',
      level2DetailLink: 'details',
      colorRowDescriptions: true,
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: '1ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '1MA',
            stackIndex: 0
          }
        },
        {
          key: '3ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '3MA',
            stackIndex: 1
          }
        },
        {
          key: '12ma',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: '12MA',
            stackIndex: 2
          }
        },
        {
          key: 'goal',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'GOAL',
            stackIndex: 3
          }
        }
      ],
      dataRows: ['1ma', '3ma', '12ma', 'goal'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
          apiData.linecolors[2],
          apiData.linecolors[3],
        ];
      }
    }
  },
  '/servicegrossmargin_customerSGM': {
    metricId: 31,
    serviceVsAmb: true,
    pageTitle: 'Service Gross Margin (SGM)',
    subTitle: '(Country YTD, Customer  12MA)',
    filters: ['DetailCustomerFilterComponent'],
    tileReference: {
      apiSource: '/metrics/servicegrossmargin_customerSGM_details',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/servicegrossmargin_customerSGM_details_0',
    table: {
      type: 'style1',
      exportFileName: 'servicegrossmargin_customerSGM.xlsx',
      level2DetailLink: 'details',
      colorRowDescriptions: true,
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'sgmPercent',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'SGM %',
            stackIndex: 0
          }
        },
        {
          key: 'goalPercent',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Goal %',
            stackIndex: 1
          }
        }
      ],
      dataRows: ['sgmPercent', 'goalPercent'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
        ];
      }
    }
  },
  '/customerswithoutservicecontracts': {
    metricId: 33,
    serviceVsAmb: true,
    pageTitle: 'Predictive Alerts w/o Service Contracts',
    filters: ['DetailCustomerFilterComponent'],
    tileReference: {
      apiSource: '/metrics/customerswithoutservicecontracts',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/customerswithoutservicecontracts_0',
    table: {
      type: 'style1',
      exportFileName: 'customerswithoutservicecontracts.xlsx',
      level2DetailLink: 'grid',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'alertCount',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Alert Count',
            stackIndex: 0
          }
        }
      ],
      dataRows: ['alertCount'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[0]
        ];
      }
    }
  },
  '/rnps': {
    metricId: 22,
    serviceVsAmb: false,
    pageTitle: 'rNPS',
    filters: [], // 'DetailCustomerFilterComponent'
    tileReference: {
      apiSource: '/metrics/rnps/intermediate',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false,
        yaxisleftlabel: 'NPS Score',
        min: -105
      }
    },
    apiRequestHandler: '/metrics/rnps/intermediate_0',
    table: {
      type: 'style1',
      exportFileName: 'rnps.xlsx',
      level2DetailLink: 'detail',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {},
        customValueIfBlank: 'N'
      },
      dataParse: [ // build the rows as array data
        {
          key: 'overallRNps',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Overall rNPS',
            stackIndex: 0
          }
        }
      ],
      dataRows: ['overallRNps'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0]
        ];
      }
    }
  },
  '/sales': {
    metricId: 26,
    serviceVsAmb: false,
    pageTitle: 'Sales',
    filters: [
      'DetailAccountFilterComponent',
      'DetailCategorySwitchesFilterComponent',
      'DetailDiseaseStateFilterComponent',
      'DetailAssayFilterComponent'
    ],
    tileReference: {
      apiSource: '/metrics/sales',
      componentName: 'AreaLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/sales_0',
    table: {
      type: 'style1',
      exportFileName: 'amb_sales.xlsx',
      level2DetailLink: 'summary',
      currencyStyleForAMB: true,
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'cumulative',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Cumulative',
            stackIndex: 0
          }
        },
        {
          key: 'monthly',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Monthly',
            stackIndex: 1
          }
        }
      ],
      dataRows: ['cumulative', 'monthly'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1]
        ];
      }
    }
  },
  '/rcs': {
    metricId: 27,
    serviceVsAmb: false,
    pageTitle: 'Reagent/Capital/Service',
    filters: [
      'DetailAccountFilterComponent',
      'DetailCapitalServiceAssaySwitchesFilterComponent',
      'DetailDiseaseStateFilterComponent',
      'DetailAssayFilterComponent'
    ],
    tileReference: {
      apiSource: '/metrics/rcs', // this is actually abbottlink installed but uses alconnected
      componentName: 'PieChartComponent',
      preferences: {
        showGoal: false,
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/rcs_0',
    table: {
      type: 'set-values',
      exportFileName: 'rcs.xlsx',
      level2DetailLink: 'summary',
      currencyStyleForAMB: true,
      dataCols: [
        {
          ref: 'percentReagent',
          description: '% Reagent',
          width: 80
        },
        {
          ref: 'reagentUSD',
          description: 'Reagent (USD)',
          width: 80
        },
        {
          ref: 'percentCapital',
          description: '% Capital',
          width: 80
        },
        {
          ref: 'capatalUSD',
          description: 'Capital (USD)',
          width: 80
        },
        {
          ref: 'percentService',
          description: '% Service',
          width: 80
        },
        {
          ref: 'serviceUSD',
          description: 'Service (USD)',
          width: 80
        },
        {
          ref: 'percentOther',
          description: '% Other',
          width: 80
        },
        {
          ref: 'otherUSD',
          description: 'Other (USD)',
          width: 80
        }
      ],
      dataColsLocal: [
        {
          ref: 'percentReagent',
          description: '% Reagent',
          width: 80
        },
        {
          ref: 'reagentLocal',
          description: 'Reagent (Local)',
          width: 80
        },
        {
          ref: 'percentCapital',
          description: '% Capital',
          width: 80
        },
        {
          ref: 'capitalLocal',
          description: 'Capital (Local)',
          width: 80
        },
        {
          ref: 'percentService',
          description: '% Service',
          width: 80
        },
        {
          ref: 'serviceLocal',
          description: 'Service (Local)',
          width: 80
        },
        {
          ref: 'percentOther',
          description: '% Other',
          width: 80
        },
        {
          ref: 'otherLocal',
          description: 'Other (Local)',
          width: 80
        }
      ],
      dataValues: (apiData) => {
        return {
          percentReagent: apiData.values[0].percentage + ' %',
          reagentUSD: apiData.values[0].amtdollar,
          percentCapital: apiData.values[1].percentage + ' %',
          capatalUSD: apiData.values[1].amtdollar,
          percentService: apiData.values[2].percentage + ' %',
          serviceUSD: apiData.values[2].amtdollar,
          percentOther: apiData.values[3].percentage + ' %',
          otherUSD: apiData.values[3].amtdollar
        };
      },
      dataValuesLocal: (apiData) => {
        return {
          percentReagent: apiData.values[0].percentage + ' %',
          reagentLocal: apiData.values[0].amtlocal,
          percentCapital: apiData.values[1].percentage + ' %',
          capitalLocal: apiData.values[1].amtlocal,
          percentService: apiData.values[2].percentage + ' %',
          serviceLocal: apiData.values[2].amtlocal,
          percentOther: apiData.values[3].percentage + ' %',
          otherLocal: apiData.values[3].amtlocal
        };
      },
      valueColors: (apiData) => {
        return [
          apiData.values[0].color,
          '',
          apiData.values[1].color,
          '',
          apiData.values[2].color,
          '',
          apiData.values[3].color,
          ''
        ];
      }
    }
  },
  '/compliance': {
    metricId: 28,
    serviceVsAmb: false,
    pageTitle: 'Contract Compliance/Revenue Gain/Loss',
    filters: [
      'DetailAccountFilterComponent',
      'DetailDiseaseStateFilterComponent',
      'DetailAssayFilterComponent'
    ],
    tileReference: {
      apiSource: '/metrics/compliance', // this is actually abbottlink installed but uses alconnected
      componentName: 'ComplianceChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/compliance_0',
    table: {
      type: 'custom-compliance',
      exportFileName: 'compliance.xlsx',
      level2DetailLink: 'details'
    }
  },
  '/amb_orders': {
    metricId: 25,
    serviceVsAmb: false,
    pageTitle: 'Customer Orders',
    filters: ['DetailAccountFilterComponent'],
    tileReference: {
      apiSource: '/amb_orders',
      componentName: 'PieChartComponent',
      preferences: {
        showGoal: false,
        showTitle: false
      }
    },
    apiRequestHandler: '/amb_orders_0',
    table: {
      type: 'set-values',
      exportFileName: 'amb_customer_orders.xlsx',
      level2DetailLink: 'intermediate',
      dataCols: [
        {
          ref: 'percentHold',
          description: '% Hold',
          width: 80
        },
        {
          ref: 'numberHold',
          description: '# Hold',
          width: 80
        },
        {
          ref: 'percentOpen',
          description: '% Open',
          width: 80
        },
        {
          ref: 'numberOpen',
          description: '# Open',
          width: 80
        },
        {
          ref: 'percentClosed',
          description: '% Closed',
          width: 80
        },
        {
          ref: 'numberClosed',
          description: '# Closed',
          width: 80
        }
      ],
      dataValues: (apiData) => {
        return {
          percentHold: apiData.values[0].percentage,
          numberHold: apiData.values[0].quantity,
          percentOpen: apiData.values[1].percentage,
          numberOpen: apiData.values[1].quantity,
          percentClosed: apiData.values[2].percentage,
          numberClosed: apiData.values[2].quantity,
        };
      },
      valueColors: (apiData) => {
        return [
          apiData.values[0].color,
          '',
          apiData.values[1].color,
          '',
          apiData.values[2].color,
          '',
        ];
      }
    }
  },
  '/amb_servicegrossmargin_customerSGM': {
    metricId: 31,
    serviceVsAmb: false,
    pageTitle: 'Service Gross Margin (SGM)',
    subTitle: '(Country YTD, Customer  12MA)',
    filters: ['DetailAccountFilterComponent'],
    tileReference: {
      apiSource: '/metrics/amb_servicegrossmargin_customerSGM_details',
      componentName: 'MultiLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/amb_servicegrossmargin_customerSGM_details_0',
    table: {
      type: 'style1',
      exportFileName: 'amb_servicegrossmargin_customerSGM.xlsx',
      level2DetailLink: 'details',
      colorRowDescriptions: true,
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'sgmPercent',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'SGM %',
            stackIndex: 0
          }
        },
        {
          key: 'goalPercent',
          parser: 'pointValues',
          inject: 'apiData',
          attributes: {
            label: 'Goal %',
            stackIndex: 1
          }
        }
      ],
      dataRows: ['sgmPercent', 'goalPercent'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.linecolors[0],
          apiData.linecolors[1],
        ];
      }
    }
  },
  '/amb_customerswithoutservicecontracts': {
    metricId: 33,
    serviceVsAmb: false,
    pageTitle: 'Predictive Alerts w/o Service Contracts',
    filters: ['DetailAccountFilterComponent'],
    tileReference: {
      apiSource: '/metrics/amb_customerswithoutservicecontracts',
      componentName: 'StackedBarLineChartComponent',
      preferences: {
        showTitle: false
      }
    },
    apiRequestHandler: '/metrics/amb_customerswithoutservicecontracts_0',
    table: {
      type: 'style1',
      exportFileName: 'amb_customerswithoutservicecontracts.xlsx',
      level2DetailLink: 'grid',
      dataCols: {
        parser: 'stackKeys',
        inject: 'apiData',
        attributes: {}
      },
      dataParse: [ // build the rows as array data
        {
          key: 'alertCount',
          parser: 'stackValues',
          inject: 'apiData',
          attributes: {
            label: 'Alert Count',
            stackIndex: 0
          }
        }
      ],
      dataRows: ['alertCount'], // arrange the rows
      rowColors: (apiData) => {
        return [
          apiData.stackcolors[0]
        ];
      }
    }
  },
};
