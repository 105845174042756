<div class="navbar-fixed-top">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="justify-content-between d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-row flex-fill">
                <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                <div class="navbar-expanded-only">
                    <!--<app-persona-selector></app-persona-selector>-->
                    <span class="mx-auto title">
                        Control Tower
                    </span>
                </div>
                <button class="navbar-toggler ml-auto"
                        type="button"
                        data-toggle="collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        aria-label="Toggle navigation"
                        (click)="clickEvent()">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="navbar-collapse flex-row d-none d-sm-none d-md-flex d-lg-flex d-xl-flex">
                <div class="d-flex flex-row flex-fill">
                    <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                    <div>
                        <app-tool-selector [selectedOption]="ToolSelectionOptions.ct"></app-tool-selector>
                    </div>
                    <span class="mx-auto title">
                        <div style="
                            font-family: georgia;
                            color: #FFF;
                            font-size: 15pt;
                            font-weight: bold;
                                    "><span style="
                            /* font-family: Arial; */
                            /* font-size: 12pt; */
                            /* background-color: #81c341; */
                            /* padding: 4px 10px; */
                            /* border-radius: 2px; */
                            /* font-weight: bold; */
                            /* letter-spacing: 0.5px; */
                        ">Always ON Customer Health</span></div>
                    </span>
                    <app-preferences *ngIf="navFilterSelection && navFilterSelection.preferences" [displayTitles]="false"></app-preferences>
                    <!--<div class="header-buttons">-->
                        <!--<a class="btn btn-healthy" href="/aoh#customer-health-metrics" title="Percent of your customers of which are healthy. Click to Navigate to Details.">-->
                            <!--Healthy Customers-->
                            <!--<span class="badge badge-pill badge-healthy">4</span>-->
                        <!--</a>&nbsp;-->
                        <!--<a class="btn btn-ch-alerts" href="/aoh#customer-health-alerts" title="# of Customer Health Alerts You Have. Click to Navigate to Details.">-->
                            <!--Customer Health Alerts-->
                            <!--<span class="badge badge-pill badge-ch-alerts">4</span>-->
                        <!--</a>-->
                    <!--</div>-->
                    <!--<a class="btn btn-secondary" href="#" *ngIf="navFilterSelection.bookmarksFlag" title="Bookmarks: Links to access other Abbott Systems.">-->
                        <!--<fa-icon [icon]="faBookmark"></fa-icon>-->
                    <!--</a>-->
                </div>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-sm-none d-md-block d-lg-block d-xl-block" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse d-flex flex-row flex-fill" id="navbarSupportedContent">
                <div class="filter-text">
                    <!--<fa-icon [icon]="faFilter"></fa-icon><br/>-->
                    Filters
                </div>
                <div>
                    <app-persona-selector [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector>
                </div>
                <!--<div>-->
                    <!--<app-persona-selector2 [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector2>-->
                <!--</div>-->
                <div>
                    <app-ct-product-set-selector></app-ct-product-set-selector>
                </div>
                <!--<div class="ml-auto switch-container">-->
                    <!--<label style="font-size:10pt;padding:5px;color:#565656;">Single Customer</label>-->
                    <!--<kendo-switch (valueChange)="customerScopeChange($event)"></kendo-switch>-->
                <!--</div>-->
            </div>
        </div>
    </nav>
</div>
<div class="wrapper">
    <div class="sidebar" [ngClass]="status ? 'active' : 'inactive'">
        <span>
            <div class="tool-selector">
                <app-tool-selector [selectedOption]="ToolSelectionOptions.ct"></app-tool-selector>
            </div>
            <div>
                <app-persona-selector [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector>
            </div>
            <!--<div>-->
                <!--<app-persona-selector2 [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector2>-->
            <!--</div>-->
            <div>
                <app-ct-product-set-selector></app-ct-product-set-selector>
            </div>
            <div>

            </div>
            <!--<a class="nav-link" href="#" *ngIf="navFilterSelection.bookmarksFlag">-->
                <!--<fa-icon [icon]="faBookmark"></fa-icon> Global Service/Vendor Links-->
            <!--</a>-->
            <app-preferences *ngIf="navFilterSelection && navFilterSelection.preferences" [displayTitles]="true"></app-preferences>
        </span>
    </div>
    <div class="overlay" (click)="closeSidebar()" [ngClass]="status ? 'active' : 'inactive'"></div>
</div>
