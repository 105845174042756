import { Component, OnInit } from '@angular/core';
import {faBookmark, faChevronLeft, faFlag, faList, faStar, faUserTag} from '@fortawesome/free-solid-svg-icons';
import {DashboardOptions, NavFilterSelection} from '../../configuration/nav-filter-selection';
import {NavbarBuilderService} from '../navbar-builder.service';
import {SelectedTabService} from '../../dashboards/selected-tab.service';

@Component({
  selector: 'app-metric-detail-navbar',
  templateUrl: './metric-detail-navbar.component.html',
  styleUrls: ['./metric-detail-navbar.component.scss']
})
export class MetricDetailNavbarComponent implements OnInit {
  navFilterSelection: NavFilterSelection = null;
  faList = faList;
  faStar = faStar;
  faUserTag = faUserTag;
  faFlag = faFlag;
  faBookmark = faBookmark;
  faChevronLeft = faChevronLeft;
  status = false;
  lastSelectedDashboardTabInfo = null;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private selectedTabService: SelectedTabService
  ) { }

  ngOnInit(): void {
    // @todo: understand the setTimeout hack used here
    // https://indepth.dev/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error/
    // https://blog.angular-university.io/angular-debugging/
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
          if (navFilterSelection.navigateBackwardsLink === '/lastSelectedDashboard') {
            navFilterSelection.navigateBackwardsLink = this.getNavigateBackwardsLink();
          }
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();
    this.selectedTabService.onTabChange(() => {
      this.lastSelectedDashboardTabInfo = this.selectedTabService.getLastSelectedTabInfo();
    });
    this.lastSelectedDashboardTabInfo = this.selectedTabService.getLastSelectedTabInfo();
  }

  getNavigateBackwardsLink() {
    const selectedTab = this.selectedTabService.getLastSelectedTabInfo();
    if (selectedTab === DashboardOptions.dashboard) {
      return '/';
    }
    if (selectedTab === DashboardOptions.customer) {
      return '/customerDashboard';
    }
    if (selectedTab === DashboardOptions.instruments) {
      return '/instrumentsDashboard';
    }
    if (selectedTab === DashboardOptions.ambassador) {
      return '/ambassadorDashboard';
    }
    return '/';
  }

  clickEvent() {
    this.status = !this.status;
  }
  closeSidebar() {
    this.status = false;
  }
}
