import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {RequestState} from '../../../api/request-handler';
import {DetailChoicesService} from '../detail-choices.service';
import {ApiService} from '../../../api/api.service';
import {RefreshHandlerService} from '../../../api/refresh-handler.service';

@ComponentLookup('DetailAccountFilterComponent')
@Component({
  selector: 'app-detail-account-filter',
  templateUrl: './detail-account-filter.component.html',
  styleUrls: ['./detail-account-filter.component.scss']
})
export class DetailAccountFilterComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  selectedItem;
  requestHandler;
  @Input() metricId;

  constructor(
    private apiService: ApiService,
    private detailChoicesService: DetailChoicesService,
    private refreshHandler: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('/search/accountdetails');
    this.requestHandler.sendRequest('/search/accountdetails');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // console.log(apiData);
        if (apiData.hasOwnProperty('accountdetails')) {
          const buildData = [];
          for (const account of apiData.accountdetails) {
            buildData.push({
              label: account.accountname, // or customersrno
              key: account.accountid
            });
          }
          this.data = buildData;
        }
      }
    });
    this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest('/search/accountdetails');
      },
      this.requestHandler.getRelativeParams('/search/accountdetails')
    );

    this.selectedItem = this.detailChoicesService.getFilter('detailAccountFilter');
    this.detailChoicesService.onUpdate('accountId', (productLine) => {
      // update selectedItem to empty
      this.selectedItem = null;
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
    this.detailChoicesService.clearUpdateListener('accountId');
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    this.detailChoicesService.setFilter('detailAccountFilter', selectedItem, true);
  }
}
