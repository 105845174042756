import { Injectable } from '@angular/core';
import {RefreshHandlerService} from '../api/refresh-handler.service';

// session of filter selections
@Injectable({
  providedIn: 'root'
})
export class FilterChoicesService {
  private filterSelections = {};

  constructor(protected refreshHandlerService: RefreshHandlerService) {
    // prevent metric tiles from being loaded until configurations are in place
    // this.setFilter('tssid', null);
    // this.setFilter('managerid', null);
    // this.setFilter('orgname', null);
    // this.setFilter('productlineid', null);
    // this.setFilter('customerid', null);
  }

  setFilter(key, value) {
    // console.log('filterSet', key, value);
    let triggerCallbacks = false;
    if (this.filterSelections[key] !== value) {
      triggerCallbacks = true;
    }
    this.filterSelections[key] = value;
    // console.log('set', key, triggerCallbacks, this.filterSelections[key], value);
    if (triggerCallbacks) {
      this.refreshHandlerService.triggerCallbacks([key]);
    }
  }

  getFilter(key) {
    if (this.filterSelections.hasOwnProperty(key)) {
      return this.filterSelections[key];
    }
    return null;
  }

  isFilterSet(key) {
    if (this.filterSelections.hasOwnProperty(key)) {
      return true;
    }
    return false;
  }
}
