import { Injectable, EventEmitter } from '@angular/core';
import {ParamSource} from '../configuration/param-mapping';
import {md5} from './md5';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class BrParamsService {
  paramChanged = new EventEmitter<any>(true);

  savedParams = {
    org: 'WorldWide',
    location: '000',
    locallevel: '000',
    location_description: '',
    uck: [],
    pl: ['000'],
    product_description: '',
    br_periods_to_show: 6, // should be months
    sg: ['Abbott'],
    sg_description: '--- (All) Abbott ---',
    cust_bs: '000',
    cust_bs_description: 'All Business Segments',
    acct_customer_query: '',
    br_template_name: '',
    br_template_string: '',
    br_template_id: '',
    br_presentation_name: '',
    list_user_presentations_update: 0,
    list_user_templates_update: 0,
    products_data: null,
    acct_pres_name: null,
    location_data: null,
    presentedName: '',
    presentationId: null,
    acct_cust_data: null,
    br_history_range_dashboard: 4,
    br_history_range_detail: 6, // used only to for save presentation, see history range service for persistance to api calls
    detailperiod: null,
    br_selected_template_name: null, // used only to for save presentation
    br_selected_template_string: null, // used only to for save presentation
    br_selected_template_id: null, // used only to for save presentation
    dashboardState: 'start',
    dashboardUck: null,
    dashboardPresentedName: null
  };

  showSql = false;

  uuid = '';

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.hasOwnProperty('show_sql')) {
        if (params.show_sql === '1') {
          this.showSql = true;
        }
      }
    });
  }

  set(key, value) {
    this.savedParams[key] = value;
    const brParamsDump = JSON.stringify(this.get('dump'));
    this.uuid = md5(brParamsDump);
    this.paramChanged.emit(key);
    return this;
  }

  getUUID() {
    return this.uuid;
  }

  get(sourceParamKey) {
    if (sourceParamKey === 'dump') {
      return this.savedParams;
    }
    if (sourceParamKey === 'showSql') {
      return this.showSql;
    }
    return this.savedParams[sourceParamKey];
  }

  getParamChangedListener() {
    return this.paramChanged;
  }

  dumpParams() {
    return this.savedParams;
  }

  force(newSettings) {
    this.savedParams = newSettings;
    for (const key in this.savedParams) {
      // console.log('keyUpdate', key);
      if (this.savedParams.hasOwnProperty(key)) {
        this.paramChanged.emit(key);
      }
    }
  }
}
