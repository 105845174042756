import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CachedResponseRepoService {
  protected cache = {};
  protected activeRequest = {};

  constructor() { }

  cacheResponse(key, response) {
    this.cache[key] = response;
  }

  isCached(key) {
    return (key in this.cache);
  }

  getResponse(key) {
    return this.cache[key];
  }

  cacheActiveRequest(key, subscription) {
    this.activeRequest[key] = subscription;
  }

  clearActiveRequest(key) {
    delete this.activeRequest[key];
  }

  isActiveRequest(key) {
    return (key in this.activeRequest);
  }

  getActiveRequest(key) {
    return this.activeRequest[key];
  }

  getAllActiveRequest() {
    return this.activeRequest;
  }

  /**
   * @todo: may want to compress this at some point
   * https://pieroxy.net/blog/pages/lz-string/guide.html
   * https://github.com/pieroxy/lz-string/
   * output must be a string
   */
  formatKey(key) {
    return JSON.stringify(key);
  }
}
