import { Component, OnInit, OnDestroy } from '@angular/core';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {CtServiceService} from '../ct-service.service';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-customer-data-trends',
  templateUrl: './customer-data-trends.component.html',
  styleUrls: ['./customer-data-trends.component.scss']
})
export class CustomerDataTrendsComponent implements OnInit, OnDestroy {
  public state;
  public apiData;
  public apiUrl = 'cua/customer_health_trend_data'; // 'control_tower/customer_data_trends_v2';
  public chartData = [];
  public gridData = [];
  public selectedTab = null;
  public customerIsSelected = false;
  opened = false;
  faInfoCircle = faInfoCircle;
  refreshIndexes = [];
  requestHandler;
  customerChangeEventIndex;
  stateChangeHandler;

  constructor(
    private apiService: ApiService,
    private refreshHandler: RefreshHandlerService,
    private ctService: CtServiceService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get(this.apiUrl);
    this.requestHandler.sendRequest(this.apiUrl, [], false);

    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest(this.apiUrl, [], false);
      },
      this.requestHandler.getRelativeParams(this.apiUrl)
    ));

    // no need to send request here as chart tile has already done so
    this.stateChangeHandler = (state) => {
      this.state = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        this.apiData = JSON.parse(response.body);

        if (this.apiData.status === 'success') {
          this.chartData = this.apiData.data.chartData;
          this.gridData = this.apiData.data.gridData;
        }

        const chartKeys = [];
        for (const item of this.chartData) {
          chartKeys.push(item.key);
        }
        if (!chartKeys.includes('test_volume')) {
          this.chartData.push({ key: 'test_volume', title: 'Test Volume by Week', noData: true });
        }
        if (!chartKeys.includes('test_completion')) {
          this.chartData.push({ key: 'test_completion', title: 'Percent Tests Completed by Week', noData: true });
        }
        if (!chartKeys.includes('controls')) {
          this.chartData.push({ key: 'controls', title: 'Percent Controls Out-of-Range by Week', noData: true });
        }
        if (!chartKeys.includes('repeat_tests')) {
          this.chartData.push({ key: 'repeat_tests', title: 'Percent Repeat Tests by Week', noData: true });
        }
        if (!chartKeys.includes('hard_stops')) {
          this.chartData.push({ key: 'hard_stops', title: 'Hard Stops by Week', noData: true });
        }

        const gridKeys = [];
        for (const item of this.gridData) {
          gridKeys.push(item.key);
        }
        if (!gridKeys.includes('open_tickets')) {
          this.gridData.push({ key: 'open_tickets', title: 'Open Tickets', noData: true });
        }


        this.selectedTab = 'test_volume';
        // FORMAT API DATA FOR TABLE
        // format dataCols for kendo component
        // this.dataCols = [];
        // for (const colKey in this.apiData.data.heading_section) {
        //   if (this.apiData.data.heading_section.hasOwnProperty(colKey)) {
        //     const col = this.apiData.data.heading_section[colKey];
        //     this.dataCols.push({
        //       ref: colKey,
        //       description: col.label,
        //       width: col.width
        //     });
        //     // col.format
        //     // col.hidden_field
        //     // col.purpose
        //     // col.visible_by_default
        //   }
        // }
        // format dataRows for kendo component
        // this.dataRows = this.apiData.data.data_section;
        // for (const colKey in this.apiData.data.data_section) {
        //   if (this.apiData.data.heading_section.hasOwnProperty(colKey)) {
        //     const col = this.apiData.data.heading_section[colKey];
        //     this.dataCols.push({
        //       ref: colKey,
        //       description: col.label,
        //       width: col.width
        //     });
        //     // col.format
        //     // col.hidden_field
        //     // col.purpose
        //     // col.visible_by_default
        //   }
        // }
      }
    };
    this.requestHandler.onStateChange(this.stateChangeHandler);

    this.customerChangeEventIndex = this.ctService.onCustomerSelectionChange(() => {
      this.customerIsSelected = (this.ctService.getCustomerId() !== 'all');
    });
  }

  ngOnDestroy(): void {
    for (const index of this.refreshIndexes) {
      this.refreshHandler.cancelRefreshEvent(index);
    }
    this.ctService.clearCustomerSelectionChangeEvent(this.customerChangeEventIndex);
    this.requestHandler.removeStateListener(this.stateChangeHandler);
  }

  onSelectedChange(selectedTab) {
    this.selectedTab = selectedTab;
  }

  openTooltip() {
    this.opened = true;
  }

  public close() {
    this.opened = false;
  }
}
