import {Input, OnInit} from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {NavFilterSelection} from '../../configuration/nav-filter-selection';
import {FilterChoicesService} from '../filter-choices.service';

export abstract class ToggleSwitchComponent implements OnInit {
  @Input() displayTitles;
  stateOn = true;
  navFilterSelection: NavFilterSelection;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    protected filterChoices: FilterChoicesService
  ) { }

  ngOnInit(): void {
    this.stateOn = this.defaultValue();
    if (!this.filterChoices.isFilterSet(this.filterKey())) {
      this.filterChoices.setFilter(this.filterKey(), this.stateOn);
    } else {
      this.stateOn = this.filterChoices.getFilter(this.filterKey());
    }
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();
  }

  abstract filterKey();
  abstract defaultValue();

  toggle() {
    this.stateOn = !this.stateOn;
    this.filterChoices.setFilter(this.filterKey(), this.stateOn);
  }
}
