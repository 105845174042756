import {Input} from '@angular/core';
import {TileReference} from '../../dashboards/tile-reference';
import {ApiService} from '../../api/api.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {RequestState} from '../../api/request-handler';
import {PreferenceApiConnectorService} from '../../preferences/preference-api-connector.service';
import {DetailQueryParamsService} from '../../business_review/detail-query-params.service';
import {NodatacheckService} from '../../business_review/nodatacheck.service';

export abstract class BaseTileComponent {
  @Input() tileReference: TileReference;

  public preferences;

  public state = RequestState.Inactive;

  constructor(
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
    protected preferenceApiConnectorService: PreferenceApiConnectorService,
    protected detailQueryParamsService: DetailQueryParamsService,
    protected nodatacheckService: NodatacheckService
  ) { }

  // RequestState {
  //   Inactive, // initial state: request not ordered yet
  //   Waiting, // request not ordered yet due to pre requisite, must be set outside
  //   NotCallable, // pre requisite failed, must be set outside
  //   Loading, // request has been started, waiting for response
  //   Complete, // request has been completed
  //   Error // request failed
  // }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  protected defaultPreferences(): any {
    return {
      showTitle: true,
      title: ''
    };
  }

  setupPreferences() {
    const preferences = this.defaultPreferences();
    if (this.tileReference.hasOwnProperty('preferences')) {
      for (const prefKey in this.tileReference.preferences) {
        if (this.tileReference.preferences.hasOwnProperty(prefKey)) {
          const prefValue = this.tileReference.preferences[prefKey];
          preferences[prefKey] = prefValue;
        }
      }
    }
    this.preferences = preferences;
  }

  getPreference(key) {
    return this.preferences[key];
  }

  getQueryParams() {
    return this.detailQueryParamsService.getQueryParams();
  }

  abstract setApiCallResponse(apiData, apiDataIndex);

}
