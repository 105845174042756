import { Component, OnInit, Renderer2 } from '@angular/core';
import {Location} from '@angular/common';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';
import { ServiceContractStylePages } from '../service-contract-pages';
import { GridComponent } from '../../grid.component';
import { ApiService } from 'src/app/api/api.service';
import { RequestState } from 'src/app/api/request-handler';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';


@Component({
  selector: 'app-service-contracts-level2',
  templateUrl: './service-contracts-level2.component.html',
  styleUrls: ['./service-contracts-level2.component.scss']
})
export class ServiceContractsLevel2Component extends GridComponent implements OnInit {

  stylePages = ServiceContractStylePages;
  config = null;
  public parentState = RequestState.Inactive;
  public state = RequestState.Inactive;
  parentApiData = null;

  public menuItems: any[] = [];
  public offset: any;
  public selected: any;
  public showContextMenu: boolean = false;
  private documentClickSubscription: any;

  constructor(
    private location: Location,
    private navbarBuilderService: NavbarBuilderService,
    protected apiService: ApiService,
    private renderer: Renderer2,
    protected refreshHandler: RefreshHandlerService
  ) { 
    super();
    this.onCellClick = this.onCellClick.bind(this);
    this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
        this.showContextMenu = false;
    });
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {

      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      }); 
      
      const parentPageRequestHandler = this.apiService.get(this.config.parentPageRequestHandler);
      parentPageRequestHandler.onStateChange((parentState) => {
        this.parentState = parentState;
      });
      parentPageRequestHandler.sendRequest(this.config.parentPageRequestHandler, { });
      this.refreshHandler.onRefreshEvent(
        (index) => {
          parentPageRequestHandler.sendRequest(this.config.parentPageRequestHandler, { });
        },
        parentPageRequestHandler.getRelativeParams(this.config.parentPageRequestHandler)
      );

      let subTitleText = '';
      // no need to send request here as chart tile has already done so
      parentPageRequestHandler.onStateChange((parentState) => {
        if (parentState === RequestState.Complete) {
          const response = parentPageRequestHandler.getResponse();
          this.parentApiData = JSON.parse(response.body);

          if (this.parentApiData != null) {
            subTitleText = this.parentApiData.contractexpNdays + " expiring in 90 days (max 1000 records shown below)";
            const pageTitle = this.config.pageTitle + " - " + subTitleText;
            this.navbarBuilderService.getNavFilterSelection().viewTitleText = pageTitle;    
          }
        }
      });      
    }

    const requestHandler = this.apiService.get(this.config.apiRequestHandler);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });    
    requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
    this.refreshHandler.onRefreshEvent(
      (index) => {
        requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
      },
      requestHandler.getRelativeParams(this.config.apiRequestHandler)
    );

    // no need to send request here as chart tile has already done so
    requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // FORMAT API DATA FOR TABLE
        // format dataCols for kendo component
        const dataColsConfig = this.config.table.dataCols;
        this.dataCols = [];
        for (let i = 0; i < this.config.table.dataCols.length; i++) {
          this.dataCols.push({
            ref: this.config.table.dataCols[i].key,
            description: this.config.table.dataCols[i].title,
            width: this.config.table.dataCols[i].width,
            hidden: this.config.table.dataCols[i].hidden,
            renderRefStyle: this.config.table.dataCols[i].renderRefStyle,
            export: this.config.table.dataCols[i].export,
            renderCustomerNameNumStyle: this.config.table.dataCols[i].renderCustomerNameNumStyle
          });
        }
        if (this.config.table.drillDownCols !== undefined) {
          for (let i = 0; i < this.config.table.drillDownCols.length; i++) {
            this.dataCols.push({
              ref: this.config.table.drillDownCols[i].key,
              description: this.config.table.drillDownCols[i].title,
              width: this.config.table.drillDownCols[i].width,
              export: this.config.table.drillDownCols[i].export,
              entitlementLink: this.config.table.drillDownCols[i].entitlementLink
            });
          }            
        }
        // format dataRows for kendo component
        this.dataRows = [];
        for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
          this.dataRows.push(apiData[this.config.dataArrayKey][r]);
        }
        this.updateGrid();
      }
    });
  }

  public onCellClick({dataItem, column, type, originalEvent}): void {
    let contextMenuItemArr = [];
    if (type === 'click' && column.field === this.config.table.contextMenuFieldName) {
      originalEvent.preventDefault();
      for (let r = 0; r < this.config.table.dataCols.length; r++) {
        let dataCol = this.config.table.dataCols[r];
        if (dataCol.contextMenuItems !== undefined) {
          for (let k = 0; k < dataCol.contextMenuItems.length; k++) {
            contextMenuItemArr.push({
              'text': dataCol.contextMenuItems[k].text,
              'iconUrl':  dataCol.contextMenuItems[k].iconUrl, 
              'textLink': dataItem[dataCol.contextMenuItems[k].fieldName][dataCol.contextMenuItems[k].columnIndex] });
          }  
        }
      }
      this.menuItems = contextMenuItemArr;
      this.showContextMenu = true;
      this.offset = { left: originalEvent.pageX, top: originalEvent.pageY };
    } else {
      this.showContextMenu = false;
    }    
  }

  public menuItemSelected(item: any): void {
    this.selected = item;
  }

}
