<div *ngIf="chartOptions">
    <kendo-chart #chart
            [title]="chartOptions.title"
            [seriesDefaults]="chartOptions.seriesDefaults"
            [series]="chartOptions.series"
            [valueAxis]="chartOptions.valueAxis"
            [categoryAxis]="chartOptions.categoryAxis"
            [legend]="chartOptions.legend"
            (legendItemClick)="legendItemClickEvent($event)">
    </kendo-chart>
    <span style="display: block;text-align: center;font-size: 10pt;">
        You may toggle chart lines by clicking the above legend toggles.
    </span>
</div>