import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileComponent } from '../../../tiles/base/tile.component';

@ComponentLookup('BrMultiLineChartComponent')
@Component({
  selector: 'app-br-multi-line-chart',
  templateUrl: './br-multi-line-chart.component.html',
  styleUrls: ['./br-multi-line-chart.component.scss']
})

export class BrMultiLineChartComponent extends TileComponent implements OnInit {
  getLineColor(apiData, i) {
    if (this.preferences.hasOwnProperty('rowColorOverrides') && this.preferences.rowColorOverrides[i] !== null) {
      return this.preferences.rowColorOverrides[i];
    }
    if (this.preferences.hasOwnProperty('colorsByIndex')) {
      const colorsByIndex = this.preferences.colorsByIndex;
      return apiData.linecolors[colorsByIndex[i]];
    }
    return apiData.linecolors[i];
  }

  translateApiDataForChart(apiData) {
    const categoryList = [];
    const seriesList = [];
    let categoryField = [];

    if (apiData.names) {
      categoryField = apiData.names;
    }

    let yaxisleftlabel = apiData.yaxisleftlabel;
    if ('yaxisleftlabel' in this.preferences) {
      yaxisleftlabel = this.preferences.yaxisleftlabel;
    }

    let yaxisrightlabel = apiData.yaxisrightlabel;
    if ('yaxisrightlabel' in this.preferences) {
      yaxisrightlabel = this.preferences.yaxisrightlabel;
    }

    let values = apiData.values;
    //if ('periodsToShow' in this.preferences) {
       // only use the last x periods in the chart
    //   values = values.slice(Math.max(values.length - this.preferences.periodsToShow, 1));
    //}
    let minValue = 0;
    if ('min' in this.preferences) {
       minValue = this.preferences.min;
    }

    //console.log('Testing Function', values);
    for (const value of values) {
      categoryList.push(value.xlabel);
      for (let i = 0; i < value.points.length; i++) {
        let excludeLines = [];
        if (this.preferences.hasOwnProperty('excludeLines')) {
          excludeLines = this.preferences.excludeLines;
        }

        //console.log("TEST Exclude Lines", excludeLines);
        if (!excludeLines.includes(i)) { // if exclusions do not include i
          if (typeof seriesList[i] === 'undefined') {
            seriesList[i] = {
              data: [],
              missingValues: 'gap',
              color: this.getLineColor(apiData, i)
            };
            if ('names2' in apiData) {
              seriesList[i].name = apiData.names2[i];
            }
            if ('axisassigments' in apiData) {
              seriesList[i].axis = apiData.axisassigments[i];
            }
          }
          seriesList[i].data.push(((value.points[i] !== 'N/A') ? value.points[i] : null));
        }
      }
    }

    //console.log("Series List", seriesList);
    let tickCount = 4;
    if ('tickCount' in this.preferences) {
      tickCount = this.preferences.tickCount;
    }
    const axis2Max = this.nextHighestNumberDivisibleBy(apiData.yleftmax, tickCount - 1);
    const valueAxis = [];
    if ('yaxisleftlabel' in apiData) {
      valueAxis.push({
        name: 'left',
        labels: {
          format: '{0}',
          position: 'start'
        },
        title: {
          text: yaxisleftlabel
        },
        line: {
          visible: false
        },
        axisCrossingValue: -10,
        max: axis2Max,
        min: minValue,
        majorUnit: axis2Max / (tickCount - 1)
      });
    }
    if ('yaxisrightlabel' in apiData) {
      let yrightmax = axis2Max;
      if ('yrightmax' in apiData) {
        yrightmax = this.apiData.yrightmax;
      }
      let tickCountRight = tickCount;
      if ('yrighttickcount' in apiData) {
        tickCountRight = this.apiData.yrighttickcount;
      }
      valueAxis.push({
        name: 'right',
        labels: {
          format: '{0}',
          position: 'end'
        },
        title: {
          text: yaxisrightlabel
        },
        line: {
          visible: false
        },
        axisCrossingValue: -10,
        max: yrightmax,
        min: minValue,
        majorUnit: yrightmax / (tickCountRight - 1)
      });
    }
    this.chartOptions = {
      legend: {
        align: 'center',
        position: 'top',
        labels: {
          font: '10pt sans-serif'
        }
      },
      seriesDefaults: {
        type: 'line',
        area: {
          line: {
            style: 'smooth'
          }
        }
      },
      series: seriesList,
      valueAxis: valueAxis,
      categoryAxis: {
        categories: categoryList,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45,
          position: 'start'
        }
      }
    };
  }
}
