import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {Router} from '@angular/router';
import {DashboardOptions, NavFilterSelection} from '../../configuration/nav-filter-selection';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {FilterChoicesService} from '../filter-choices.service';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {SelectedTabService} from '../../dashboards/selected-tab.service';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';

@Component({
  selector: 'app-search-popup',
  templateUrl: './search-popup.component.html',
  styleUrls: ['./search-popup.component.scss']
})
export class SearchPopupComponent implements OnInit, OnDestroy {
  RequestState = RequestState;
  DashboardOptions = DashboardOptions;
  @Input() displayTitles;
  stateOn = false;
  searchValue = '';
  navFilterSelection: NavFilterSelection;
  refreshHandleKey;
  requestHandler;
  apiState;
  apiRoute = '/search';
  apiData;
  selectedType;
  // selectedSnKeys;
  selectedKey;
  searchQuery;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private filterChoices: FilterChoicesService,
    private apiService: ApiService,
    private refreshHandlerService: RefreshHandlerService,
    private selectedTabService: SelectedTabService,
    private personaService: PersonaService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();

    this.requestHandler = this.apiService.get(this.apiRoute);
    this.requestHandler.sendRequest(this.apiRoute);
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.apiData = apiData;
        this.resetSelection(true);
        // console.log(this.apiData);
        /**
         * note instrument selections switch to the instruments dashboard
         * customers [
             address: "325 E 15TH ST BETWEEN 1ST AND 2ND  "
             city: "NEW YORK"
             country: "056"
             customerid: "45891"
             customersrno: "0050082684"
             isnew: "False"
             name: "MOUNT SINAI BETH ISRAEL"
             productlines: []
             state: "NY"
         ]
         * productlines [
             alconnectable: null
             alwaysonuptime: null
             country: null
             customers: [
               0: "1511 (256580-0)"
               1: "12782 (4051401-0)"
               2: "13080 (2093001-1)"
               3: "13184 (8175001-1)"
             ]
             productdescription: "ARCHITECT I2000"
             productlineid: "115"
             productname: "ARCHITECT I2000"
             sn: null
         ]
         * snlist [
             alconnectable: null
             alwaysonuptime: null
             country: "JAPAN"
             customers: null
             productdescription: null
             productlineid: "116#ISR00141"
             productname: "ARCHITECT i2000SR"
             sn: "ISR00141"
         ]
         */
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.apiRoute);
    }, this.requestHandler.getRelativeParams(this.apiRoute));
  }

  ngOnDestroy() {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
  }

  toggle() {
    this.stateOn = !this.stateOn;
  }

  public change(event) {
    this.searchQuery = event.target.value;
    if (event.key === 'Enter') {
      this.search();
    }
  }
  public search() {
    this.filterChoices.setFilter('searchQuery', this.searchQuery);
  }

  resetSelection(withSn = false) {
    for (const k in this.apiData.customers) {
      if (this.apiData.customers.hasOwnProperty(k)) {
        this.apiData.customers[k].selected = false;
      }
    }
    for (const k in this.apiData.productlines) {
      if (this.apiData.productlines.hasOwnProperty(k)) {
        this.apiData.productlines[k].selected = false;
      }
    }
    // if (withSn) {
    for (const k in this.apiData.snlist) {
      if (this.apiData.snlist.hasOwnProperty(k)) {
        this.apiData.snlist[k].selected = false;
      }
    }
    // }
  }

  getCurrentSelectedItems() {
    if (this.selectedType === 'customer') {
      for (const item of this.apiData.customers) {
        if (item.customerid === this.selectedKey) {
          return item;
        }
      }
    }
    if (this.selectedType === 'pl') {
      for (const item of this.apiData.productlines) {
        if (item.productlineid === this.selectedKey) {
          return item;
        }
      }
    }
    if (this.selectedType === 'sn') {
      // const selectedItems = [];
      for (const item of this.apiData.snlist) {
        if (item.productlineid === this.selectedKey) {
          return item;
          // selectedItems.push(item);
        }
      }
      // return selectedItems;
    }
    return null;
  }

  public select(item, type, key) {
    if (!item.selected) {
      if (type === 'sn') {
        this.resetSelection();
        // if (!Array.isArray(this.selectedKey)) {
        //   this.selectedSnKeys = [];
        // }
        // this.selectedSnKeys.push(key);
        // this.selectedKey = this.selectedSnKeys;
        this.selectedKey = key;
      } else {
        this.resetSelection(true);
        this.selectedKey = key;
        // this.selectedSnKeys = [];
      }
    }
    // else if (type === 'sn') {
    //   const index = this.selectedSnKeys.indexOf(key);
    //   if (index > -1) {
    //     this.selectedSnKeys.splice(index, 1);
    //   }
    //   this.selectedKey = this.selectedSnKeys;
    // }

    this.selectedType = type;
    item.selected = !item.selected;
    this.submit();
  }

  public close() {
    this.stateOn = false;
  }

  public open() {
    this.stateOn = true;
  }

  public submit() {
    /**
     * set to appropriate dashboard
     * set persona selections
     * trigger refreshes
     * close the window
     */
    const selectedItems = this.getCurrentSelectedItems();
    if (this.selectedType === 'customer') {
      this.selectedTabService.selectTab(DashboardOptions.customer);
      this.personaService.setServiceVsAmbState(true);
      this.personaService.setPersonaOption(PersonaTypes.GEO, 'WorldWide', 'WorldWide');
      this.personaService.setPersonaOption(PersonaTypes.Customer, this.selectedKey, selectedItems.name);
      this.router.navigate(['/customerDashboard']);
      // this.filterChoices.setFilter('searchCustomerId', this.selectedKey);
      // this.filterChoices.setFilter('searchProductLineId', '');
    }
    if (this.selectedType === 'pl') {
      this.selectedTabService.selectTab(DashboardOptions.instruments);
      this.personaService.setServiceVsAmbState(true);
      this.personaService.setPersonaOption(PersonaTypes.GEO, 'WorldWide', 'WorldWide');
      this.personaService.setPersonaOption(PersonaTypes.ProductLine, this.selectedKey, selectedItems.productname);
      this.router.navigate(['/instrumentsDashboard']);
      // this.filterChoices.setFilter('searchProductLineId', this.selectedKey);
      // this.filterChoices.setFilter('searchCustomerId', '');
    }
    if (this.selectedType === 'sn') {
      // selectedItems = selectedItems[0];
      this.selectedTabService.selectTab(DashboardOptions.instruments);
      this.personaService.setServiceVsAmbState(true);
      // persona service needs modification to allow multi select
      this.personaService.setPersonaOption(PersonaTypes.GEO, 'WorldWide', 'WorldWide');
      this.personaService.setPersonaOption(PersonaTypes.SN, this.selectedKey, selectedItems.sn);
      this.router.navigate(['/instrumentsDashboard']);
      // this.filterChoices.setFilter('searchProductLineId', '');
      // this.filterChoices.setFilter('searchCustomerId', '');
    }
    this.close();
  }
}
