import { Injectable } from '@angular/core';
import {RefreshHandlerService} from './refresh-handler.service';
import {PersonaLevels} from '../configuration/persona-levels';
import {PersonaTypes} from '../configuration/persona-types';
import {UserInfo} from '../domain/user-info';
import {AuthenticationService} from '../authentication/authentication.service';
import {DetailChoicesService} from '../filter_selection/details/detail-choices.service';
import {DashboardOptions} from '../configuration/nav-filter-selection';
import {SelectedTabService} from '../dashboards/selected-tab.service';
import {RequestState} from './request-handler';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {ParamMapping, ParamSource} from '../configuration/param-mapping';

/**
 * req_1: savePersona is caching for when app opens back up
 * req_2: preload persona options
 * req_3: when a geo, tss or manager is selected for service metrics, clear the other level1 selections
 * req_4: when an instrument is selected, also apply selection for drill down detail page
 * drill down detail pages will work off of filter service for instrument and customer selections
 */
@Injectable({
  providedIn: 'root'
})
export class PersonaService {
  serviceVsAmbState = true;

  config = PersonaLevels;

  authAbbottId = '';
  authUPI = '';
  authName = '';
  isAMB = false;
  isAMBManager = false;
  isSuperUser = false;

  /**
   * initial auth user info that comes back for new users:
   * persona:null
   * personatype:null
   * personaName:null
   * ambpersona:null
   * ambpersonatype:null
   * ambpersonaName:null
   * persona menu shows * Joshua Young as selected under the TSS/Ambassador menu
   */

  // @todo: req_1 - set these using auth service
  // initial default values will no longer work as auth overrides any values setup with app saved personas
  personaType = ''; // GEO, TSS, Manager, CuaManager, Customer, ProductLine, SN, Amb, ManagerOverview, ManagersSpecifcAmb
  persona = '';
  personaLabel = '';

  customer = 'all';
  customerLabel = '';
  customerOverride = false;

  account = 'all';
  accountLabel = '';
  accountOverride = false;

  instrumentPersonaType = '';
  instrumentPersona = '';
  instrumentPersonaLabel = '';

  ambPersonaType = '';
  ambPersona = '';
  ambPersonaLabel = '';

  accountPersona = 'all';
  accountPersonaLabel = '';

  callbacks = [];

  savePersonaRequestHandler;
  savePersonaAmbRequestHandler;

  lastPersonaSave = null;
  lastPersonaAmbSave = null;

  constructor(
    private refreshHandlerService: RefreshHandlerService,
    private auth: AuthenticationService,
    private detailChoices: DetailChoicesService,
    private selectedTabService: SelectedTabService,
    private http: HttpClient
  ) { }

  initialLoad() {
    this.auth.onAuth(() => {
      const userInfo = this.auth.getUserInfo();

      this.authAbbottId = userInfo.abbottid;
      this.authUPI = userInfo.upi;
      this.authName = userInfo.firstname + ' ' + userInfo.lastname;

      this.isAMB = userInfo.isAMB;
      this.isAMBManager = userInfo.isAMBManager;
      this.isSuperUser = userInfo.isSuperUser;

      const tmpServiceVsAmbState = this.serviceVsAmbState;
      // console.log('userinfo', tmpServiceVsAmbState, userInfo);

      // other user auth: persona, personatype, personaName, ambpersona, ambpersonatype, ambpersonaName

      // const isService = this.getServiceVsAmbState();

      // set service metric saved persona
      this.setServiceVsAmbState(true);
      if (['TSS', 'Manager', 'CuaManager', 'GEO', 'AccountRep'].indexOf(userInfo.personatype) !== -1) {
        let key = '';
        switch (userInfo.personatype) {
          case 'TSS':
            key = PersonaTypes.TSS;
            break;
          case 'Manager':
            key = PersonaTypes.Manager;
            break;
          case 'CuaManager':
            key = PersonaTypes.CuaManager;
            break;
          case 'AccountRep':
            key = PersonaTypes.AccountRep;
            break;
          case 'GEO':
            key = PersonaTypes.GEO;
            break;
        }
        this.setPersonaOption(key, userInfo.persona, userInfo.personaName);
      } else {
        this.setPersonaOption(PersonaTypes.TSS, this.authAbbottId, this.authName);
      }

      // set amb saved persona
      this.setServiceVsAmbState(false);
      if (['Ambassador', 'GEO'].indexOf(userInfo.ambpersonatype) !== -1) {
        let key = '';
        switch (userInfo.ambpersonatype) {
          case 'Ambassador':
            key = PersonaTypes.ManagerOverview;
            break;
          case 'GEO':
            key = PersonaTypes.GEO;
            break;
        }
        this.setPersonaOption(key, userInfo.ambpersona, userInfo.ambpersonaName);
      } else if (this.isAMBManager) {// rm_2020_11_05: } || !this.isAMB) {
        this.setPersonaOption(PersonaTypes.ManagerOverview, this.authUPI, this.authName);
      } else {
        this.setPersonaOption(PersonaTypes.ManagersSpecifcAmb, this.authAbbottId, this.authName);
      }
      this.setServiceVsAmbState(tmpServiceVsAmbState);
    }, 'personaService');
    this.refreshDetailChoices();
  }

  setCustomerOverride(value) {
    this.customerOverride = value;
  }

  setAccountOverride(value) {
    this.accountOverride = value;
  }

  setServiceVsAmbState(value) {
    // console.trace('setServiceVsAmbState', value);
    this.serviceVsAmbState = value;
    return this;
  }

  getServiceVsAmbState() {
    return this.serviceVsAmbState;
  }

  /**
   * this is the method used for the param resolver regarding persona's
   */
  getPersonaOption(key) {
    /**
     * if (this.serviceVsAmbState) {
     *  if is in config.level1Service.apiCalls and personaType === key
     *   return persona
     *  else if is in config.level2ServiceInstruments.apiCalls and instrumentPersonaType === key
     *   return instrumentPersona
     *  else if is in config.level2ServiceCustomers.apiCalls
     *   return customer
     *  else return null;
     * } else {
     *  if is in config.level1Amb.apiCalls and key === ambPersonaType
     *   return ambPersona
     *  else return null;
     * }
     */
    const level1Service = [PersonaTypes.Manager, PersonaTypes.CuaManager, PersonaTypes.AccountRep, PersonaTypes.CuaAmbManager, PersonaTypes.CuaAmb,  PersonaTypes.TSS, PersonaTypes.GEO];
    const level2ServiceInstruments = [PersonaTypes.ProductLine, PersonaTypes.SN];
    if (this.customerOverride && key !== PersonaTypes.Customer && this.customer !== 'all' && this.customer) {
      if (
        this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.customer
        || this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower
      ) {
        return '';
      }
    }
    if (this.accountOverride && key !== PersonaTypes.CtAccount && this.account !== 'all' && this.account) {
      if (this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower) {
        return '';
      }
    }
    if (this.serviceVsAmbState) {
      if (
        (key === PersonaTypes.ProductLine && this.instrumentPersona === '')
        || (key === PersonaTypes.Customer && this.customer === '')
      ) {
        return 'all';
      }
      if (key === PersonaTypes.Customer) {
        if (
          this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.customer
          || this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower
        ) {
          return this.customer;
        }
      } else if (level2ServiceInstruments.indexOf(key) !== -1 && this.instrumentPersonaType === key) {
        if (this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.instruments) {
          return this.instrumentPersona;
        }
      } else if (this.personaType === key) {
        return this.persona;
      }
    } else {
      if (PersonaTypes.GEO === key && this.ambPersonaType === PersonaTypes.GEO) {
        return this.ambPersona;
      }
      if (PersonaTypes.ManagerAmb === key && this.ambPersonaType === PersonaTypes.ManagerOverview) {
        return this.ambPersona;
      }
      if (PersonaTypes.AbbottIdAmb === key && this.ambPersonaType === PersonaTypes.ManagersSpecifcAmb) {
        return this.ambPersona;
      }
      if (PersonaTypes.Account === key) {
        return this.accountPersona;
      }
    }
    return '';
  }

  getLevel1Choice() {
    if (
      this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower
      && this.customerOverride && this.customer && this.customer !== 'all'
    ) {
      return {
        type: PersonaTypes.Customer,
        value: this.customer,
        label: this.customerLabel
      };
    } else if (this.serviceVsAmbState) {
      return {
        type: this.personaType,
        value: this.persona,
        label: this.personaLabel
      };
    } else {
      return {
        type: this.ambPersonaType,
        value: this.ambPersona,
        label: this.ambPersonaLabel
      };
    }
  }

  getLevel2Choice() {
    const level2ServiceInstruments = [PersonaTypes.ProductLine, PersonaTypes.SN];
    // console.log(this.selectedTabService.getLastSelectedTabInfo());
    if (this.serviceVsAmbState || this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower) {
      if (
        this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.customer
        || this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower
      ) {
        return {
          type: PersonaTypes.Customer,
          value: this.customer,
          label: this.customerLabel
        };
      }
      if (this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.instruments) {
        return {
          type: this.instrumentPersonaType,
          value: this.instrumentPersona,
          label: this.instrumentPersonaLabel
        };
      }
    } else if (this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.ambassador) {
      return {
        type: PersonaTypes.Account,
        value: this.accountPersona,
        label: this.accountPersonaLabel
      };
    }
    return null;
  }

  getPersonaLabel(key) {
    // const level1Service = [PersonaTypes.Manager, PersonaTypes.TSS, PersonaTypes.GEO];
    const level2ServiceInstruments = [PersonaTypes.ProductLine, PersonaTypes.SN];
    if (this.serviceVsAmbState) {
      if (key === PersonaTypes.Customer) {
        return this.customerLabel;
      } else if (level2ServiceInstruments.indexOf(key) !== -1) {
        return this.instrumentPersonaLabel;
      } else {
        return this.personaLabel;
      }
    } else if (PersonaTypes.GEO === key) { // amb
      return this.ambPersonaLabel;
    }
    return '';
  }

  setPersonaOption(key, value, label) {
    // console.log('setPersona', this.serviceVsAmbState, key, value, label);
    // console.log('personaType', key);
    // const level1Service = [PersonaTypes.Manager, PersonaTypes.TSS, PersonaTypes.GEO];
    const level2ServiceInstruments = [PersonaTypes.ProductLine, PersonaTypes.SN];
    if (this.serviceVsAmbState) {
      if (key === PersonaTypes.Customer) {
        this.customer = value;
        this.customerLabel = label;
        this.refreshDetailChoices();
      } else if (level2ServiceInstruments.indexOf(key) !== -1) {
        this.instrumentPersonaType = key;
        this.instrumentPersona = value;
        this.instrumentPersonaLabel = label;
        this.refreshDetailChoices();
      } else { // level1Service.indexOf(key) !== -1 and clear level1
        this.personaType = key;
        this.persona = value;
        this.personaLabel = label;

        this.customer = 'all';
        this.customerLabel = '';
        this.instrumentPersonaType = '';
        this.instrumentPersona = '';
        this.instrumentPersonaLabel = '';
        this.refreshDetailChoices();

        this.savePersona(this.persona, this.personaLabel, this.personaType);
      }
    } else { // amb geo and clear
      if ((this.isAMBManager || this.isSuperUser || this.isAMB) && key === PersonaTypes.ManagersSpecifcAmb) {
        this.ambPersonaType = key;
        this.ambPersona = value;
        this.ambPersonaLabel = label;
        this.savePersonaAmb(this.ambPersona, this.ambPersonaLabel, 'Ambassador');
        key = PersonaTypes.AbbottIdAmb;
      } else if (this.isAMBManager && key === PersonaTypes.ManagerOverview) {
        this.ambPersonaType = key;
        this.ambPersona = value;
        this.ambPersonaLabel = label;
        this.savePersonaAmb(this.ambPersona, this.ambPersonaLabel, 'Ambassador');
        key = PersonaTypes.ManagerAmb;
      } else if (this.isSuperUser && key === PersonaTypes.GEO) {
        this.ambPersonaType = key;
        this.ambPersona = value;
        this.ambPersonaLabel = label;
        this.savePersonaAmb(this.ambPersona, this.ambPersonaLabel, 'GEO');
      } else if ((this.isAMBManager || !this.isAMB) && key === PersonaTypes.ManagerOverview) {
        // need to handle as default if above selections occur, handled in initialLoad
        this.ambPersonaType = PersonaTypes.ManagerOverview;
        this.ambPersona = this.authUPI;
        this.ambPersonaLabel = 'Manager Overview';
        key = PersonaTypes.ManagerAmb;
        this.savePersonaAmb(this.ambPersona, this.ambPersonaLabel, 'Ambassador');
      } else if (key === PersonaTypes.Account) {
        // need to handle as default if above selections occur, handled in initialLoad
        this.accountPersona = value;
        this.accountPersonaLabel = label;
        key = PersonaTypes.Account;
      } else { // PersonaTypes.Amb
        this.ambPersonaType = PersonaTypes.Amb;
        this.ambPersona = this.authAbbottId;
        this.ambPersonaLabel = label;
        key = PersonaTypes.AbbottIdAmb;
        this.savePersonaAmb('', '', '');
      }
    }
    // console.log('t1', key, this.personaType, this.persona, this.personaLabel);
    // console.log('t2', key, this.ambPersonaType, this.ambPersona, this.ambPersonaLabel);
    // console.log('t2', key, this.customer, this.customerLabel);
    // const triggers = this.refreshHandlerService.getParamMappingKeysForSourceKey(key);
    // console.log('triggerCallbacks', key);
    this.refreshHandlerService.triggerCallbacks([key]);
    this.triggerPersonaChoiceUpdates();
  }

  savePersona(personaCall, personanameCall, personatypeCall) {
    // call should be a PUT not POST
    // isAmb false if for service metrics, true if for amb tab
    const personaSaveKey = JSON.stringify({p: personaCall, pn: personanameCall, pt: personatypeCall});
    if (this.lastPersonaSave !== personaSaveKey && this.lastPersonaSave) {
      const userInfo: UserInfo = this.auth.getUserInfo();
      if (userInfo && ('abbottid' in userInfo)) {
        this.sendPut({
          loginid: userInfo.abbottid,
          isAMB: 'false',
          persona: personaCall,
          personaname: personanameCall,
          personatype: personatypeCall
        });
      }
    }
    this.lastPersonaSave = personaSaveKey;
  }
  savePersonaAmb(personaCall, personanameCall, personatypeCall) {
    // call should be a PUT not POST
    // isAmb false if for service metrics, true if for amb tab
    const personaSaveKey = JSON.stringify({p: personaCall, pn: personanameCall, pt: personatypeCall});
    if (this.lastPersonaAmbSave !== personaSaveKey && this.lastPersonaAmbSave) {
      const userInfo: UserInfo = this.auth.getUserInfo();
      if (userInfo && ('abbottid' in userInfo)) {
        this.sendPut({
          loginid: userInfo.abbottid,
          isAMB: 'true',
          persona: personaCall,
          personaname: personanameCall,
          personatype: personatypeCall
        });
      }
    }
    this.lastPersonaAmbSave = personaSaveKey;
  }

  sendPut(params) {
    const formData = new HttpParams({
      fromObject: {
        route: environment.apiProxyRouteKey + '/users/savepersona',
        post: JSON.stringify(params) // formData.toString()
      }
    });
    const httpHeaders = new HttpHeaders();
    const url = environment.proxyServerUrl + '/proxy2.php?route=/users/savepersona';
    const subscription = this.http.post(url, formData, {
      headers: httpHeaders,
      responseType: 'text',
      withCredentials: true,
      observe: 'response'
    });
    // console.log('SavePersona Started');
    subscription.subscribe(
      response => {
        console.log('SavePersona Complete');
      },
      error => {
        // console.log('SavePersona Failed.');
      }
    );
  }

  isAmbOptionSet() {
    return (this.ambPersonaType !== '');
  }

  onPersonaChoiceUpdate(callback) {
    this.callbacks.push(callback);
  }

  triggerPersonaChoiceUpdates() {
    for (const callback of this.callbacks) {
      callback();
    }
    return this;
  }

  refreshDetailChoices() {
    this.detailChoices.setFilter('detailCustomerIdFilter', this.customer);
    if (this.instrumentPersonaType === PersonaTypes.ProductLine) {
      this.detailChoices.setFilter('detailProductLineFilter', this.instrumentPersona);
    }
  }
}

/*
  star icon switches on/off additional customers ???

  search will search all customers regardless of first set of persona selections but will select the item in the persona menu
    should wipe out persona

  [recalled when org, tss or manager changes
    productline/details
    productline/sn
    abbottid: (tss)
    flagship: true
    istrack: true
    managerid: (manager)
    openstar: true
    orgname: AMT

    customer/details
    abbottid:
    isamb: false
    isSuperUser: false
    istrack: true
    managerid:
    openstar: true
    orgname: AMT
  ]

  either abbottid or managerid is passed not both
  either productlineid or sn is passed not both

  metrics/opencalls
  abbottid: (if tss selected)
  managerid: (if manager selected)
  customerid: (if customer selected)
    note: continues to pass abbottid and managerid if selected on dashboard
    but dashboard does not pass customerid
  productlineid: (if instrument selected)
    note: same function as customerid, does not continue to pass preselected customer id
  sn: (if sn selected)


  on the customer and instruments dashboard not only do geo/tss/and manager selections affect metrics but they update the customers, instruments, and serial number menus

  the available customer and product line menus carry down to style 1 page filters but selections do not carry back up

  after selecting tss||manager
  savePersona[
    isAMB: false
    loginid: Youngjx13
    persona: moenax || 1166
    personaname: ARTHUR MOEN || ADRIAN LEMA
    personatype: TSS || Manager
  ]
*/