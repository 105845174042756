import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
  selector: 'app-tss-ambassador-selector',
  templateUrl: './tss-ambassador-selector.component.html',
  styleUrls: ['./tss-ambassador-selector.component.scss']
})
export class TssAmbassadorSelectorComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  selectedItem;
  requestHandler;

  userName;
  user511;
  userUPI;
  authSet = false;
  dataHasExtras = false;

  constructor(
    private apiService: ApiService,
    private personaService: PersonaService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('/users/tss');
    this.requestHandler.sendRequest('/users/tss');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        const buildData = [];
        for (const user of apiData.users) {
          buildData.push({
            label: user.firstname + ' ' + user.lastname,
            key: user.abbottid
          });
        }
        this.data = buildData;
        this.dataHasExtras = false;
        this.customizeData();
      }
    });

    this.authService.onAuth(() => {
      const userInfo = this.authService.getUserInfo();

      this.userName = userInfo.firstname + ' ' + userInfo.lastname;
      this.user511 = userInfo.abbottid;
      this.userUPI = userInfo.upi;
      this.authSet = true;
      this.customizeData();
    });
    this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.TSS);
    this.personaService.onPersonaChoiceUpdate(() => {
      this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.TSS);
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  customizeData() {
    if (!this.dataHasExtras && this.authSet) {
      this.data.unshift({ label: '* ' + this.userName, key: this.user511 });
      this.dataHasExtras = true;
    }
  }

  getLabel(key) {
    if (this.data) {
      for (const item of this.data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    const label = this.getLabel(selectedItem);
    this.personaService.setPersonaOption(PersonaTypes.TSS, selectedItem, label);
  }
}
