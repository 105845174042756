<div class="br-snapshot">
  <app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
      <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
      <h4 *ngIf="'subHeading' in preferences">{{preferences.subHeading}}</h4>
      <div class="tile-chart-container" *ngIf="isDataFound(apiData) && chartOptions !== null && chartOptions !== undefined">
        <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          <kendo-chart [seriesDefaults]="chartOptions.seriesDefaults"
                       [series]="chartOptions.series"
                       [valueAxis]="chartOptions.valueAxis"
                       [categoryAxis]="chartOptions.categoryAxis"
                       [legend]="chartOptions.legend">
          </kendo-chart>
        </a>
      </div>
      <div *ngIf="!isDataFound(apiData)" class="no-data">
        No data!
      </div>
    </div>
  </app-state-handler>
</div>
