import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {CustomHttpParamEncoder} from '../CustomHttpParamEncoder';
import {UserInfo} from '../domain/user-info';
import {RefreshHandlerService} from '../api/refresh-handler.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private devuser = 'josh';
  private subscription = null;
  private isAuthActive = false;
  private userInfo: UserInfo;
  private callbacks: any[] = [];
  private callbackRefs: any[] = [];
  private callbackPreReqs: any[] = [];
  private pausedCallbacks: any[] = [];
  private isAuthorized = false;

  constructor(
    private http: HttpClient,
    private refreshHandlerService: RefreshHandlerService
  ) {}

  isAuth() {
    return this.isAuthActive;
  }

  isAuthorizedCheck() {
    return this.isAuthorized;
  }

  isAuthenticated() {
    if (!this.isAuthActive) {
      return this.pingAuth();
    }

    return this.isAuthActive;
  }

  pingAuth() {
    const formData = new HttpParams({
      fromObject: {
        devuser: this.devuser,
      }
    });
    const headers = new HttpHeaders();
    this.subscription = this.http.post(environment.proxyServerUrl + '/auth2.php', formData, {
      headers: headers,
      responseType: 'text',
      withCredentials: true,
      observe: 'response'
    });
    this.subscription.subscribe(
      response => {
        // console.log('auth complete');
        const userInfoTemp = JSON.parse(response.headers.get('userinfo'));
        const authorizedInfo = JSON.parse(response.headers.get('isauthorized'));
        this.isAuthorized = (authorizedInfo === true);
        for (const key in userInfoTemp) {
          if (key in userInfoTemp && key !== 'token') {
            userInfoTemp[key] = decodeURIComponent(userInfoTemp[key]).replace(/\+/g, ' ');
          }
        }
        if (userInfoTemp.isAMB === '') {
          userInfoTemp.isAMB = 'false';
        }
        if (userInfoTemp.isAMBManager === '') {
          userInfoTemp.isAMBManager = 'false';
        }
        if (userInfoTemp.isSuperUser === '1') {
          userInfoTemp.isSuperUser = 'true';
        }
        if (userInfoTemp.isSuperUser === '0') {
          userInfoTemp.isSuperUser = 'false';
        }
        this.userInfo = userInfoTemp;
        // this.userInfo.isAMB = true;
//         this.userInfo.isAMBManager = true;
//         this.userInfo.abbottid = 'CAROZCX';
//         this.userInfo.persona = 'CAROZCX';
//         this.userInfo.personaName = 'the Ceaser';
//         this.userInfo.personatype = 'AMB';
//         this.userInfo.ambpersona = 'CAROZCX';
//         this.userInfo.ambpersonaName = 'the Ceaser';
//         this.userInfo.ambpersonatype = 'AMB';
//         this.userInfo.firstname = 'Ceaser';
//         this.userInfo.lastname = 'Ceaser';
        // console.log('userinfo', this.userInfo);

        // temp hack for testing amb menus
        // this.userInfo.isAMBManager = true;
        // this.userInfo.isAMB = true;
        // this.userInfo.isSuperUser = true;
        // this.userInfo.abbottid = 'TRUKOGX';
        // this.userInfo.upi = '10004438';

        this.isAuthActive = true;
        this.callCallbacks();

        return this.isAuthActive;
      },
      error => {
        console.log(error);
        this.isAuthActive = false;
        return this.isAuthActive;
      }
    );
    return this.subscription;
  }

  callCallbacks() {
    for (const i in this.callbacks) {
      if (i in this.callbacks) {
        const callback = this.callbacks[i];
        if (this.callbackPreReqs[i]) {
          if (!(this.callbackPreReqs[i] in this.pausedCallbacks)) {
            this.pausedCallbacks[this.callbackPreReqs[i]] = [];
          }
          this.pausedCallbacks[this.callbackPreReqs[i]].push(callback);
        } else {
          callback();
          if (this.callbackRefs[i] && this.callbackRefs[i] in this.pausedCallbacks) {
            this.runPreReqsFor(this.callbackRefs[i]);
          }
        }
      }
    }
  }

  runPreReqsFor(ref) {
    if (ref in this.pausedCallbacks) {
      for (const playCallback of this.pausedCallbacks[ref]) {
        playCallback();
      }
    }
  }

  getUserInfo() {
    return this.userInfo;
  }

  public onAuth(callback, ref = null, preReq = null) {
    this.callbacks.push(callback);
    this.callbackRefs.push(ref);
    this.callbackPreReqs.push(preReq);
    if (this.userInfo) {
      callback();
      if (ref) {
        this.runPreReqsFor(ref);
      }
    }
    return this;
  }
}
