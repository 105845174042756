<div *ngIf="operation === 1">
    <div class="dropdown-btn-secondary">
        <kendo-dropdownlist [data]="userTemplatesData"
                            [textField]="'title'"
                            [valueField]="'key'"
                            (valueChange)="valueChange($event)"
                            [valuePrimitive]="true"
                            [value]="selectedOption"></kendo-dropdownlist>
    </div>
    <button class="btn btn-secondary" title="Add" (click)="addLink()">
        Add
        <!--<span class="icon-container">-->
        <!--<fa-icon [icon]="faPlus"></fa-icon>-->
        <!--</span>-->
    </button>
    <button class="btn btn-secondary" title="Edit" (click)="editLink()" *ngIf="selectedOption !== 'br_kpi'">
        Edit
        <!--<span class="icon-container">-->
        <!--<fa-icon [icon]="faICursor"></fa-icon>-->
        <!--</span>-->
    </button>
    <span class="selected-template-heading">Selected Template Order</span>
    <app-br-template-viewer [templateString]="selectedTemplateString"></app-br-template-viewer>
</div>
<div *ngIf="operation === 2">
    <app-br-template-editor [id]="selectedOption" [templateString]="selectedTemplateString" (saveEvent)="onSave()" (cancelEvent)="onCancel()"></app-br-template-editor>
</div>
<div *ngIf="operation === 3">
    <app-br-template-editor [id]="selectedOption" (saveEvent)="onSave()" (cancelEvent)="onCancel()"></app-br-template-editor>
</div>
<!--template/custom-->
<!--list_user_templates-->
<!--save_user_template-->
<!--delete_user_template-->

<!--template/std-->
<!--std_kpi_list-->