import { Component, OnInit } from '@angular/core';
import { TileComponent } from '../tile.component';
import { ComponentLookup } from 'src/app/decorators/component-lookup';

@ComponentLookup('TopStatsListComponent')
@Component({
  selector: 'app-top-stats-list',
  templateUrl: './top-stats-list.component.html',
  styleUrls: ['./top-stats-list.component.scss']
})
export class TopStatsListComponent extends TileComponent implements OnInit {

  defaultPreferences() {
    return {
      showTitle: true
    };
  }
  translateApiDataForChart(apiData) {}

}
