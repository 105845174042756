import {Component, Input, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';

@Component({
  selector: 'app-tool-selector',
  templateUrl: './tool-selector.component.html',
  styleUrls: ['./tool-selector.component.scss']
})
export class ToolSelectorComponent implements OnInit {
  @Input() selectedOption = ToolSelectionOptions.mycrl;

  toolOptions = [
    {
      key: ToolSelectionOptions.mycrl,
      title: 'MyCRL',
      icon: '',
      tooltip: '',
      routerLink: '/'
    },
    {
      key: ToolSelectionOptions.ct,
      title: 'Always ON Customer Health',
      icon: '',
      tooltip: '',
      routerLink: '/aoh'
    },
    {
      key: ToolSelectionOptions.alerts,
      title: 'Predictive Alerts',
      icon: '',
      tooltip: '',
      routerLink: '/alerts'
    },
    {
      key: ToolSelectionOptions.gsalerts,
      title: 'Global Alerts',
      icon: '',
      tooltip: '',
      routerLink: '/gsalerts'
    },
    {
      key: ToolSelectionOptions.businessreview,
      title: 'Business Review',
      icon: '',
      tooltip: '',
      routerLink: '/businessReview'
    }
  ];

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
  }

  getToolOption(key) {
    for (const option of this.toolOptions) {
      if (option.key === key) {
        return option;
      }
    }
    return null;
  }

  valueChange(value) {
    const toolOption = this.getToolOption(value);
    this.router.navigate([toolOption.routerLink], {relativeTo: this.route});
  }
}
