<div class="container-fluid" *ngIf="apiDataRecord">
    <div class="row">
        <div class="col-12">
            <app-state-handler [requestState]="state" [data]="apiDataRecord">
            <table class='survey-comments survey-comments-table'>
                <tr>
                    <td>Customer:</td>
                    <td>Interview Date:</td>
                    <td>Overall Score:</td>
                </tr>
                <tr>
                    <td>{{apiDataRecord['customername'] == null ? 'Anonymous' : apiDataRecord['customername'] }}</td>
                    <td>{{apiDataRecord['interviewdate']}}</td>
                    <td>{{apiDataRecord['value']}}</td>
                </tr>
            </table>                
            <table class='survey-comments-table' style='width: 100%'>
                <ng-container *ngFor="let comment of apiDataRecord['detractor']">
                    <tr>
                        <td colspan="2" class='survey-comments-table-title'>{{comment.label}}</td>
                    </tr>
                    <tr>
                        <td style='font-weight: 500; width: 150px; text-align: right'>Translation:</td>
                        <td>{{comment.transvalue}}</td>
                    </tr>
                    <tr>
                        <td style='font-weight: 500; text-align: right'>Native Language:</td>
                        <td>{{comment.nativevalue}}</td>
                    </tr>
                </ng-container>
            </table>
            </app-state-handler>
        </div>
    </div>
</div>
