import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrTemplateSelectionService {
  templateChanged = new EventEmitter<any>(true);
  templateDetails = {
    id: 'br_kpi',
    name: 'Default Template',
    string: ''
  };

  constructor() { }

  getTemplateSelectionChange() {
    return this.templateChanged;
  }

  getSelectedTemplate() {
    return this.templateDetails;
  }

  updateSelectedTemplate(templateId, templateName, templateString) {
    this.templateDetails = {
      id: templateId,
      name: templateName,
      string: templateString
    };
    this.templateChanged.emit(this.templateDetails);
  }
}
