<!--<kendo-textbox
        [style.width.px]="350"
        placeholder="Search Locations"
        [clearButton]="true"
>
</kendo-textbox><br/>-->
<div class="column">
    <div class="column-heading">Organizations</div>
    <div class="items" *ngIf="state === RequestState.Complete">
        <div *ngFor="let orgPrefixItem of data2.org_prefixes.items">
            {{orgPrefixItem.display_name}}
            <div class="item"
                 *ngFor="let orgItem of orgPrefixItem.relationships"
                 (click)="selectOrg(orgItem.id)"
                 [title]="orgItem.display_name"
                 [class.item-selected]="selectedOrg===orgItem.id">
                <span class="none"></span>
                <fa-icon [icon]="faCheck" class="check"></fa-icon>
                {{orgItem.display_name}}
            </div>
        </div>
    </div>
</div>
<div class="column">
    <div class="column-heading">Locations</div>
    <div class="items">
        <div class="item"
             *ngFor="let locationItem of locationColumn"
             (click)="selectLocation(locationItem.id)"
             [title]="locationItem.display_name"
             [class.item-selected]="selectedLocation===locationItem.id">
            <span class="none"></span>
            <fa-icon [icon]="faCheck" class="check"></fa-icon>
            {{locationItem.display_name}}
        </div>
    </div>
</div>
<div class="column">
    <div class="column-heading">LocalLevels</div>
    <div class="items">
        <div class="item"
             *ngFor="let localLevelItem of localLevelColumn"
             (click)="selectLocalLevel(localLevelItem.id)"
             [title]="localLevelItem.display_name"
             [class.item-selected]="selectedLocalLevel===localLevelItem.id">
            <span class="none"></span>
            <fa-icon [icon]="faCheck" class="check"></fa-icon>
            {{localLevelItem.display_name}}
        </div>
    </div>
</div>

