import { Component, OnInit } from '@angular/core';
import {TileComponent} from '../../base/tile.component';
import {ComponentLookup} from '../../../decorators/component-lookup';

@ComponentLookup('MultiLineChartComponent')
@Component({
  selector: 'app-multi-line-chart',
  templateUrl: './multi-line-chart.component.html',
  styleUrls: ['./multi-line-chart.component.scss']
})
export class MultiLineChartComponent extends TileComponent implements OnInit {
  getLineColor(apiData, i) {
    if (this.preferences.hasOwnProperty('rowColorOverrides') && this.preferences.rowColorOverrides[i] !== null) {
      return this.preferences.rowColorOverrides[i];
    }
    if (this.preferences.hasOwnProperty('colorsByIndex')) {
      const colorsByIndex = this.preferences.colorsByIndex;
      return apiData.linecolors[colorsByIndex[i]];
    }
    return apiData.linecolors[i];
  }
  translateApiDataForChart(apiData) {
    const categoryList = [];
    const seriesList = [];

    let yaxisleftlabel = apiData.yaxisleftlabel;
    if ('yaxisleftlabel' in this.preferences) {
      yaxisleftlabel = this.preferences.yaxisleftlabel;
    }

    let values = apiData.values;
    if ('periodsToShow' in this.preferences) {
      // only use the last x periods in the chart
      values = values.slice(Math.max(values.length - this.preferences.periodsToShow, 1));
    }
    let minValue = 0;
    if ('min' in this.preferences) {
      minValue = this.preferences.min;
    }
    for (const value of values) {
      categoryList.push(value.xlabel);
      for (let i = 0; i < value.points.length; i++) {
        let excludeLines = [];
        if (this.preferences.hasOwnProperty('excludeLines')) {
          excludeLines = this.preferences.excludeLines;
        }
        // console.log(excludeLines);
        if (!excludeLines.includes(i)) { // if exclusions do not include i
          if (typeof seriesList[i] === 'undefined') {
            seriesList[i] = {
              data: [],
              missingValues: 'gap',
              color: this.getLineColor(apiData, i)
            };
          }
          // if (this.preferences.title === 'Same Day Fix Rate') {
          //   console.log(value);
          // }
          // N/A values were showing above chart instead of being gaps
          seriesList[i].data.push(((value.points[i] !== 'N/A') ? value.points[i] : null));
        }
      }
    }

    let tickCount = 4;
    if ('tickCount' in this.preferences) {
      tickCount = this.preferences.tickCount;
    }
    const axis2Max = this.nextHighestNumberDivisibleBy(apiData.yleftmax, tickCount - 1);
    this.chartOptions = {
      // title: {
      //     text: apiData.title,
      //     position: 'top',
      //     font: 'bold 14px  Arial,Helvetica,sans-serif'
      // },
      seriesDefaults: {
        type: 'line',
        area: {
          line: {
            style: 'smooth'
          }
        }
      },
      series: seriesList,
      valueAxis: {
        labels: {
          format: '{0}'
        },
        title: {
          text: yaxisleftlabel
        },
        line: {
          visible: false
        },
        axisCrossingValue: -10,
        max: axis2Max,
        min: minValue,
        majorUnit: axis2Max / (tickCount - 1)
      },
      categoryAxis: {
        categories: categoryList,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45,
          position: 'start'
        }
      }
    };
  }
}
