import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ApiService } from 'src/app/api/api.service';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';
import { RequestState } from 'src/app/api/request-handler';
import { GridComponent } from '../../grid.component';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';
import {PreferenceApiConnectorService} from '../../../preferences/preference-api-connector.service';

@Component({
  selector: 'app-sales-summary',
  templateUrl: './sales-summary.component.html',
  styleUrls: ['./sales-summary.component.scss']
})
export class SalesSummaryComponent extends GridComponent implements OnInit {
  /* MULTI CURRENCY FIELDS
   rcs
   currencycode: "BRL"
   annualamtdollar: "0"
   annualamtlocal: "0.00"
   monthlyamtdollar: "0"
   monthlyamtlocal: "0.000000"
   ytdamtdollar: "0"
   ytdamtlocal: "0.00"

   compliance
   currencycode: "BRL"
   expectedrevenue: "440.00"
   expectedrevenuedollar: "110.55"
   revenue: "0.00"
   revenuedollar: "0.00"

   sales
   currencycode: "BRL"
   annualamtdollar: "0"
   annualamtlocal: "0.00"
   monthlyamtdollar: "0"
   monthlyamtlocal: "0.000000"
   ytdamtdollar: "0"
   ytdamtlocal: "0.00"
   */
  stylePages = {
    '/sales/summary': {
      pageTitle: 'Sales Summary (USD)',
      apiRequestHandler: '/metrics/sales/summary',
      backPageLabel: 'Back',
      backPageLink: '/sales',
      dataArrayKey: 'details',
      table: {
        exportFileName: 'ambassador_sales_summary.xlsx',
        dataCols: [
          {
              title: 'Customer',
              key: 'customername',
              width: 80
          },
          {
              title: 'LOB - Disease State',
              key: 'diseasestate',
              width: 60
          },
          {
              title: 'List Number',
              key: 'ln',
              width: 60
          },
          {
              title: 'Desc',
              key: 'desc',
              width: 80
          },
          {
              title: 'Annual Sales',
              key: 'annualamtdollar',
              altKey: 'annualamtlocal',
              customStyle:  { 'text-align': 'right' },
              width: 60
          },
          {
              title: 'YTD Sales',
              key: 'ytdamtdollar',
              altKey: 'ytdamtlocal',
              customStyle:  { 'text-align': 'right' },
              width: 60
          },
          {
              title: 'Monthly Avg',
              key: 'monthlyamtdollar',
              altKey: 'monthlyamtlocal',
              customStyle:  { 'text-align': 'right' },
              width: 60
          }
        ],
        drillDownCols: [
          {
              title: 'Details',
              width: 60,
              drillDownDetailsColName: 'area_sales_details',
              drillDownDetailsLabel: '>',
              drillDownDetailsLink: '/sales/details'
          }
        ]
      }
    },
    '/rcs/summary': {
      pageTitle: 'Reagent/Capital/Service (RCS) Summary (USD)',
      apiRequestHandler: '/metrics/rcs/summary',
      backPageLabel: 'Back',
      backPageLink: '/rcs',
      dataArrayKey: 'details',
      table: {
        exportFileName: 'ambassador_rcs_summary.xlsx',
        dataCols: [
          {
              title: 'Customer',
              key: 'customername',
              width: 80
          },
          {
              title: 'LOB - Disease State',
              key: 'diseasestate',
              width: 60
          },
          {
              title: 'List Number',
              key: 'ln',
              width: 60
          },
          {
              title: 'Desc',
              key: 'desc',
              width: 80
          },
          {
              title: 'Annual Sales',
              key: 'annualamtdollar',
              altKey: 'annualamtlocal',
              customStyle:  { 'text-align': 'right' },
              width: 60
          },
          {
              title: 'YTD Sales',
              key: 'ytdamtdollar',
              altKey: 'ytdamtlocal',
              customStyle:  { 'text-align': 'right' },
              width: 60
          },
          {
              title: 'Monthly Avg',
              key: 'monthlyamtdollar',
              altKey: 'monthlyamtlocal',
              customStyle:  { 'text-align': 'right' },
              width: 60
          }
        ],
        drillDownCols: [
          {
              title: 'Details',
              width: 60,
              drillDownDetailsColName: 'rcs_details',
              drillDownDetailsLabel: '>',
              drillDownDetailsLink: '/rcs/details'
          }
        ]
      }
    },
    '/compliance/details': {
      pageTitle: 'Contract Compliance/Revenue Gain/Loss Summary (12 Months) (USD)',
      apiRequestHandler: '/metrics/compliance/details',
      backPageLabel: 'Back',
      backPageLink: '/compliance',
      dataArrayKey: 'details',
      table: {
        exportFileName: 'ambassador_compliance_details.xlsx',
        dataCols: [
          {
              title: 'Customer',
              key: 'customername',
              width: 80
          },
          {
              title: 'LOB - Disease State',
              key: 'diseasestate',
              width: 60
          },
          {
              title: 'List Number',
              key: 'ln',
              width: 60
          },
          {
              title: 'Assay Desc',
              key: 'assays',
              width: 80
          },
          {
              title: 'Contract Start Date',
              key: 'contractstartdate',
              width: 60
          },
          {
              title: 'Contract End Date',
              key: 'contractenddate',
              width: 60
          },
          {
              title: 'Compliance %',
              key: 'compliance',
              customStyle:  { 'text-align': 'right' },
              width: 60
          },
          {
              title: 'Revenue',
              key: 'revenuedollar',
              altKey: 'revenue',
              customStyle:  { 'text-align': 'right' },
              width: 60
          },
          {
            title: 'Expected Revenue',
              key: 'expectedrevenuedollar',
              altKey: 'expectedrevenue',
              customStyle:  { 'text-align': 'right' },
              width: 60
          }
        ]
      }
    }
  };

  config = null;
  public state = RequestState.Inactive;
  reccount = 0;
  totalrecords = 0;
  startingrow = 0;
  disableLoadAll: boolean = false;
  isLocalCurrency = false;
  localCurrencyCode = null;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    protected refreshHandler: RefreshHandlerService,
    protected preferenceApiConnectorService: PreferenceApiConnectorService
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail-no-filter');

      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText:  this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });
      this.preferenceApiConnectorService.onUpdate(() => {
        this.isLocalCurrency = false;
        if (this.preferenceApiConnectorService.getIsLocalCurrency() === 'True') {
          this.isLocalCurrency = true;
        }
        this.loadDataForRows(this.startingrow, true);
        if (this.localCurrencyCode) {
          this.updateNavFilterSelection(this.localCurrencyCode);
        }
      });
    }
  }

  updateNavFilterSelection(currencyCode) {
    const navFilterSelection = this.navbarBuilderService.getNavFilterSelection();
    const pageTitle = navFilterSelection.viewTitleText;
    if (this.isLocalCurrency) {
      pageTitle.replace("USD", currencyCode);
    } else {
      pageTitle.replace(currencyCode, "USD");
    }
    this.navbarBuilderService.setNavFilterSelection(navFilterSelection);
  }

  public getDrillDownDetailsLinkWithParams(colName: any, drillDownLink: any, dataItem: any) {
    return drillDownLink + "/" + dataItem['customerid'] + "/" + dataItem['ln'] + "/" + dataItem['desc'] + "/" + dataItem['customername'];
  }

  loadDataForRows(startingRow: number, createGrid: boolean): void {
    const requestHandler = this.apiService.get(this.config.apiRequestHandler);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, startingrow: startingRow });
    this.refreshHandler.onRefreshEvent(
      (index) => {
        requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
      },
      requestHandler.getRelativeParams(this.config.apiRequestHandler)
    );

    // no need to send request here as chart tile has already done so
    requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {


        const response = requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        if (createGrid === true) {
          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            let refKey;
            if (this.isLocalCurrency && 'altKey' in this.config.table.dataCols[i]) {
              refKey = this.config.table.dataCols[i].altKey;
            } else {
              refKey = this.config.table.dataCols[i].key;
            }
            this.dataCols.push({
              ref: refKey,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              customStyle: this.config.table.dataCols[i].customStyle
            });
          }
          if (this.config.table.drillDownCols !== undefined) {
            for (let i = 0; i < this.config.table.drillDownCols.length; i++) {
              this.dataCols.push({
                description: this.config.table.drillDownCols[i].title,
                width: this.config.table.drillDownCols[i].width,
                drillDownDetailsColName: this.config.table.drillDownCols[i].drillDownDetailsColName,
                drillDownDetailsLabel: this.config.table.drillDownCols[i].drillDownDetailsLabel,
                drillDownDetailsLink: this.config.table.drillDownCols[i].drillDownDetailsLink
              });
            }            
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            this.dataRows.push(apiData[this.config.dataArrayKey][r]);
            if (r === 0) {
              this.totalrecords = apiData[this.config.dataArrayKey][0]['totalrecords'];
            }
          }
          this.reccount = this.dataRows.length;
        } else {          
          if (startingRow === -1) {
            this.dataRows = [];
          }
          // append records to the existing grid data
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            this.dataRows.push(apiData[this.config.dataArrayKey][r]);
            if (r === 0) {
              this.totalrecords = apiData[this.config.dataArrayKey][0]['totalrecords'];
            }
          }
          // if load all was clicked, disable Load All link after loading all the rows
          if (startingRow === -1) {
            this.disableLoadAll = true;
            this.totalrecords = this.dataRows.length;
          }
          this.reccount = this.dataRows.length;
        }
        if (this.dataRows.length > 0 && 'currencycode' in this.dataRows[0]) {
          this.localCurrencyCode = this.dataRows[0]['currencycode'];
        }
        this.updateGrid();
        if (this.localCurrencyCode) {
          this.updateNavFilterSelection(this.localCurrencyCode);
        }
      }
    });
  }
  
  loadAllRecords() {
    this.loadDataForRows(-1, false);
  }

  loadNextDataSet() {
    this.startingrow += 500;
    this.loadDataForRows(this.startingrow, false);
  }

  isDisabled() {
    return this.disableLoadAll;
  }

}
