<div *ngIf="config">
    <app-state-handler [requestState]="state" [data]="gridData" [noDataCheck]="false">
    <kendo-grid
        [data]="gridData"
        [pageable]="true"
        [pageSize]="gridState.take"
        [skip]="gridState.skip"
        [filter]="gridState.filter"
        filterable="menu"
        [sort]="gridState.sort"
        [sortable]="true"
        [ngStyle]="{'max-height': getMaxHeight()}" 
        [resizable]="true"
        (pageChange)="pageChange($event)"
        (sortChange)="sortChange($event)"
        (filterChange)="filterChange($event)"
        (cellClick)="onCellClick($event)">
        <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData">
            <ng-container *ngFor="let col of dataCols">
                <kendo-excelexport-column [field]="col.ref" [title]="col.description" *ngIf="col.export"></kendo-excelexport-column>
            </ng-container>
        </kendo-grid-excel>
        <ng-template kendoGridToolbarTemplate>
            <!-- <input placeholder="Search in all columns..." kendoTextBox (input)="onInput($event.target.value)"/> -->
            <!--<kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>-->
            <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
            <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
        </ng-template>        
        <ng-container *ngFor="let col of dataCols">
            <kendo-grid-column
                    [field]="col.ref"
                    [title]="col.description"
                    [width]="col.width"                    
                    [format]="col.format"
                    [hidden]="col.hidden"
                    [filterable]="(col.filterable === false) ? false: true"
                    [headerClass]="'data-grid-header'"
                    [style]="col.customStyle">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                        <ng-container *ngIf="col.refLink; else two">
                            <span  class="whole-cell">
                                <a href="{{dataItem[col.refLink]}}" target="blank" style="color:#007bff; font-weight: 500">{{dataItem[col.ref]}}</a>
                            </span>
                        </ng-container>
                        <ng-template #two>
                            <ng-container *ngIf="col.renderRefStyle === true; else three">
                                <span class="whole-cell" style="color:#007bff; font-weight: bold; cursor: pointer;">{{dataItem[col.ref]}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #three>
                            <ng-container *ngIf="col.renderALStyle === true; else four">
                                <div *ngIf="dataItem[col.ref] == 0" class="doughnut doughnut-gray"></div>
                                <div *ngIf="dataItem[col.ref] == 1" class="doughnut doughnut-green"></div>
                                <div *ngIf="dataItem[col.ref] == 2" class="doughnut doughnut-red"></div>
                            </ng-container>
                        </ng-template>
                        <ng-template #four>
                            <ng-container *ngIf="col.renderALConnectableStyle === true; else five">
                                <!-- AL should be INSTALLED: 1 (for the next level of checks)  -->
                                <ng-container *ngIf="dataItem[col.alKey] == 1">
                                    <span *ngIf="dataItem[col.alConnectableCompareKey] == 1" class="square-border square-border-green">{{dataItem[col.ref]}}</span>
                                    <span *ngIf="dataItem[col.alConnectableCompareKey] == 2" class="square-border square-border-yellow">{{dataItem[col.ref]}}</span>
                                    <span *ngIf="dataItem[col.alConnectableCompareKey] == 3" class="square-border square-border-red">{{dataItem[col.ref]}}</span>
                                </ng-container>
                            </ng-container>
                        </ng-template>
                        <ng-template #five>
                            <ng-container *ngIf="col.renderMaintenanceScoreStyle === true; else six">
                                <span class="whole-cell" [style]="getMaintenanceScoreStyle(dataItem[col.ref])">
                                    {{dataItem[col.ref]}}
                                </span>
                                <!-- <ng-container *ngIf="dataItem[col.ref] && parseInt(dataItem[col.ref]) >= 0 && parseInt(dataItem[col.ref]) <= 29; else fiveA">
                                    <span style='color: red'>{{dataItem[col.ref]}}</span>
                                </ng-container>
                                <ng-template #fiveA>
                                    <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                </ng-template>                                 -->
                            </ng-container>
                        </ng-template>
                        <ng-template #six>
                            <ng-container *ngIf="col.renderHCIHSIStyle === true; else eight">
                                <span *ngIf="dataItem[col.ref] == 'Y'" style='color: red'>{{dataItem[col.ref]}}</span>
                                <span *ngIf="dataItem[col.ref] == 'N'">{{dataItem[col.ref]}}</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #seven>
                            <ng-container *ngIf="col.renderTSBDueStyle === true; else eight">
                                <span *ngIf="dataItem[col.ref] && dataItem[col.ref] == 0">{{dataItem[col.ref] | emptyMask}}</span>
                                <span *ngIf="dataItem[col.ref] && dataItem[col.ref] != 0" style='color: red'>{{dataItem[col.ref]}}</span>                                
                            </ng-container>
                        </ng-template>
                        <ng-template #eight>
                            <!-- <span class="whole-cell">{{dataItem[col.ref] | emptyMask}}</span> -->
                            <span class="whole-cell">{{dataItem[col.ref]}}</span>
                        </ng-template>
                    </ng-template>                        
            </kendo-grid-column>
        </ng-container>
    </kendo-grid>
    </app-state-handler>
    <!-- https://www.telerik.com/kendo-angular-ui/components/grid/how-to/context-menu/ -->
    <kendo-popup *ngIf="showContextMenu" [offset]="offset">
        <ul class='menu'>
            <li *ngFor="let item of menuItems" (click)="menuItemSelected(item)">
                <span style='padding-right: 8px' *ngIf="item.iconUrl">
                    <img width='15' height="15" src="{{item.iconUrl}}" />
                </span>
                <a href="{{item.textLink}}" target="blank">{{item.text}}</a>                
            </li>
        </ul>
    </kendo-popup>
</div>