import { Component, OnInit } from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import { ActivatedRoute } from '@angular/router';
import { State } from '@progress/kendo-data-query';
import {ExcelExportData} from '@progress/kendo-angular-excel-export';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import { Style4Pages } from 'src/app/configuration/details/style4-pages';

@Component({
  selector: 'app-style4',
  templateUrl: './style4.component.html',
  styleUrls: ['./style4.component.scss']
})
export class Style4Component implements OnInit {

  dataCols = [];
  dataRows = [];
  groups = [];
  aggregates: any[] = [];

  gridData: any;
  exportState: State = {};

  style4Pages = Style4Pages;
  config = null;
  public state = RequestState.Inactive;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    private refreshHandler: RefreshHandlerService
  ) {
    // super();
    this.allData = this.allData.bind(this);
  }

  // checkIf(value: any){
  //   debugger;  //open the devtools and go to the view...code execution will stop here!
  //   //..code to be checked... `value` can be inspected now along with all of the other component attributes
  // }

  ngOnInit(): void {

    const currentRouterLocation = this.location.path();
    Object.keys(this.style4Pages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.style4Pages[key];
        return;
      }
    });
    if (this.config != null) {

      let customerid;
      let customername;

      this.route.paramMap.subscribe(params => {
        customerid = params.get('customerid');
        customername = params.get('customername');
      });

      this.groups = [{ field: this.config.groupField }];

      const titleText = customername != null && customername.trim().length > 0 ? customername : this.config.pageTitle;

      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: true,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: titleText,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      const params = { months: 12 };
      if (customerid !== undefined) {
        params['customerdetail_id'] = customerid;
      }
      requestHandler.sendRequest(this.config.apiRequestHandler, params);
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, params});
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          this.dataCols = [];
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            this.dataCols.push({
              ref: this.config.table.dataCols[i].key,
              description: this.config.table.dataCols[i].title,
              hidden: this.config.table.dataCols[i].hidden,
              addCustomerDetailsLink: this.config.table.dataCols[i].addCustomerDetailsLink,
              groupHeaderColumn: this.config.table.dataCols[i].groupHeaderColumn,
              customStyle: this.config.table.dataCols[i].customStyle
            });
          }
          // this.gridData = process(apiData[this.config.dataArrayKey], { group: this.groups });
          // format dataRows for kendo component
          // GroupResulst -> aggregates -> The aggregated values for the group; field -> The field by which the data items are grouped.
          //              -> items -> Contains either the subgroups, or the data items.; value -> The group key.; 
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            let objectToParse = apiData[this.config.dataArrayKey][r];
            let groupRow = {};
            groupRow['aggregates'] = [];
            groupRow['aggregates'][this.config.groupCountField] = objectToParse[this.config.groupCountField];
            groupRow['aggregates'][this.config.groupFieldId] = objectToParse[this.config.groupFieldId];

            groupRow['field'] = this.config.groupField;
            if (this.config.groupFieldsToAppend !== undefined && this.config.groupFieldsToAppend.length == 2) {
              let groupFieldVal = objectToParse[this.config.groupFieldsToAppend[0]] + "(" + objectToParse[this.config.groupFieldsToAppend[1]] + ")";
              groupRow['value'] = groupFieldVal;
            } else {
              groupRow['value'] = objectToParse[this.config.groupField];
            }
            groupRow['items'] = [];
            for (let i = 0; i < objectToParse[this.config.groupItemsKey].length; i++) {
              // groupRow['items'][this.config.groupField] = groupRow['value'];
              groupRow['items'].push(objectToParse[this.config.groupItemsKey][i]);
            }
            this.dataRows.push(groupRow);
          }
          const groupData = { 'data': this.dataRows, 'total': this.dataRows.length };
          this.gridData = groupData;

         /* let dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            let objectToParse = apiData[this.config.dataArrayKey][r];
            for (let i = 0; i < objectToParse[this.config.groupItemsKey].length; i++) {
              let groupRow = {};
              let itemsArray = objectToParse[this.config.groupItemsKey][i];
              groupRow[this.config.groupField] = objectToParse[this.config.groupField];
              // copy all items element attributes to groupRow
              Object.entries(itemsArray).forEach(([key,value]) => { groupRow[key] = value });

              // groupRow['aggregates'] = [{ 'field': this.config.groupField, 'count': objectToParse[this.config.groupCountField] }
              //           , { 'field': this.config.groupField, 'id': objectToParse[this.config.groupFieldId] }];
              dataRows.push(groupRow); 
            }            
          }
          this.gridData = process(dataRows, { group: this.groups });*/
        }             
      });
    }
  }

  public getAggregateData(aggregateVal: any) {
    console.log(aggregateVal);
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData =  {
      // data: process(this.gridData, this.exportState).data,
      data: this.dataRows,
      group: this.groups
    };

    return result;
  }

  private detectMob() {
    return /iPad|iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public getMaxHeight(): string {
    const userWindowHeight = window.screen.availHeight;
    const userWindowWidth = window.screen.availWidth;

    if (userWindowHeight < 300) {
      return 300 + 'px';
    } else if (userWindowWidth <= 768) {
      return (userWindowHeight - 100) + 'px';
    } else if (this.detectMob()) {
      return (userWindowHeight - 150) + 'px';
    }
    return (userWindowHeight - 250) + 'px';
  }


}
