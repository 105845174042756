<kendo-textbox
        [style.width.px]="350"
        placeholder="Search Accounts/Customers"
        [clearButton]="false"
        (valueChange)="searchChange($event)"
        [(ngModel)]="searchQuery"
></kendo-textbox><br/>
<div *ngIf="searchQuery === ''" style="padding: 10px; font-style: italic; font-weight: 500;">
    Please type an Account or Customer to find.<br/>
    <span class="min-chars-message">(3 Characters are Required to Search)</span>
</div>
<div *ngIf="searchQuery !== ''">
    <div *ngIf="state === RequestState.Loading">Results Updating.</div>
    <div *ngIf="state !== RequestState.Loading">
        <div *ngIf="data.hasOwnProperty('type') && data.type === 'NO_RESULTS_FOUND'">
            No Results Found.
        </div>
        <div *ngIf="!(data.hasOwnProperty('type') && data.type === 'NO_RESULTS_FOUND')">
            <app-ui-multi-column-list-select
                    [mockData]="data"
                    (valueChange)="valueChanged($event)"
                    (valueChangeColumnSelected)="valueChangeColumnSelected($event)"
                    (valueChangeData)="valueChangedData($event)"
                    [bringSelectedToTop]="true"
            ></app-ui-multi-column-list-select>
        </div>
    </div>
</div><br/><br/><br/>
<!--<div *ngIf="searchQuery !== ''">-->
    <!--<span class="no-selections" *ngIf="noSelections">No Selections...</span>-->
<!--</div>-->
<hr>
<div>Presented Name to represent Account/Customer Selection</div>
<kendo-textbox
        [style.width.px]="420"
        placeholder="presented presentation name..."
        [clearButton]="false"
        (valueChange)="presentedNameChange($event)"
        [(ngModel)]="presentedName"
></kendo-textbox><br/>
<div *ngIf="searchQuery !== ''">
    <span *ngIf="!noSelections">
        Update To: <button class="recommended-text" (click)="selectRecommendedText()">{{recommendedText}}</button>
    </span>
</div>
