import { Component, OnInit } from '@angular/core';
import {NavFilterSelection, ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {CtServiceService} from '../../control_tower/ct-service.service';
import {NavbarBuilderService} from '../navbar-builder.service';

@Component({
  selector: 'app-ct-navbar',
  templateUrl: './ct-navbar.component.html',
  styleUrls: ['./ct-navbar.component.scss']
})
export class CtNavbarComponent implements OnInit {
  navFilterSelection: NavFilterSelection = null;
  ToolSelectionOptions = ToolSelectionOptions;
  status = false;

  constructor(
    private ctService: CtServiceService,
    private navbarBuilderService: NavbarBuilderService
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection: NavFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();
  }

  clickEvent() {
    this.status = !this.status;
  }
  closeSidebar() {
    this.status = false;
  }

  customerScopeChange(evt) {
    this.ctService.setCustomerScope(evt);
  }
}
