import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { GridComponent } from '../../grid.component';
import { RequestState } from 'src/app/api/request-handler';
import { ApiService } from 'src/app/api/api.service';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';

@Component({
  selector: 'app-predictive-alerts-level3',
  templateUrl: './predictive-alerts-level3.component.html',
  styleUrls: ['./predictive-alerts-level3.component.scss']
})
export class PredictiveAlertsLevel3Component extends GridComponent implements OnInit {

  stylePages = {
    '/customerswithoutservicecontracts/griddetails': {
        pageTitle: 'Proactive Health Alerts for Customer without Service Contracts',
        apiRequestHandler: '/metrics/customerswithoutservicecontracts/griddetails',
        parentPageRequestHandler: '/metrics/customerswithoutservicecontracts/grid',
        backPageLabel: 'Back',
        backPageLink: 'customerswithoutservicecontracts/grid',
        dataArrayKey: 'details',
        table: {
        exportFileName: 'predictive_alerts_without_service_contract_grid_details.xlsx',
        dataCols: [
            {
                title: 'Algorithm Category',
                key: 'algorithmGroup',
                width: 80
            },
            {
                title: 'Total Alert Qty',
                key: 'flaggedTotal',
                customStyle:  { 'text-align': 'right' },
                width: 50
            },
            {
                title: 'M12 Qty',
                key: 'flaggedMonth12',
                customStyle:  { 'text-align': 'right' },
                width: 50
            },
            {
              title: 'M11 Qty',
              key: 'flaggedMonth11',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M10 Qty',
              key: 'flaggedMonth10',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M9 Qty',
              key: 'flaggedMonth9',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M8 Qty',
              key: 'flaggedMonth8',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M7 Qty',
              key: 'flaggedMonth7',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M6 Qty',
              key: 'flaggedMonth6',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M5 Qty',
              key: 'flaggedMonth5',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M4 Qty',
              key: 'flaggedMonth4',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M3 Qty',
              key: 'flaggedMonth3',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M2 Qty',
              key: 'flaggedMonth2',
              customStyle:  { 'text-align': 'right' },
              width: 50
            },
            {
              title: 'M1 Qty',
              key: 'flaggedMonth1',
              customStyle:  { 'text-align': 'right' },
              width: 50
            }
        ]
      }
    },
    '/amb_customerswithoutservicecontracts/griddetails': {
      pageTitle: 'Proactive Health Alerts for Customer without Service Contracts',
      apiRequestHandler: '/metrics/amb_customerswithoutservicecontracts/griddetails',
      parentPageRequestHandler: '/metrics/amb_customerswithoutservicecontracts/grid',
      backPageLabel: 'Back',
      backPageLink: 'amb_customerswithoutservicecontracts/grid',
      dataArrayKey: 'details',
      table: {
      exportFileName: 'predictive_alerts_without_service_contract_grid_details.xlsx',
      dataCols: [
          {
              title: 'Algorithm Category',
              key: 'algorithmGroup',
              width: 80
          },
          {
              title: 'Total Alert Qty',
              key: 'flaggedTotal',
              customStyle:  { 'text-align': 'right' },
              width: 50
          },
          {
              title: 'M12 Qty',
              key: 'flaggedMonth12',
              customStyle:  { 'text-align': 'right' },
              width: 50
          },
          {
            title: 'M11 Qty',
            key: 'flaggedMonth11',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M10 Qty',
            key: 'flaggedMonth10',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M9 Qty',
            key: 'flaggedMonth9',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M8 Qty',
            key: 'flaggedMonth8',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M7 Qty',
            key: 'flaggedMonth7',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M6 Qty',
            key: 'flaggedMonth6',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M5 Qty',
            key: 'flaggedMonth5',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M4 Qty',
            key: 'flaggedMonth4',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M3 Qty',
            key: 'flaggedMonth3',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M2 Qty',
            key: 'flaggedMonth2',
            customStyle:  { 'text-align': 'right' },
            width: 50
          },
          {
            title: 'M1 Qty',
            key: 'flaggedMonth1',
            customStyle:  { 'text-align': 'right' },
            width: 50
          }
        ]
      }
    }
  };
  
  config = null;
  apiDataRecord = null;
  public state = RequestState.Inactive;
  countryCode = null;
  customerID = null;
  sn = null;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    private refreshHandler: RefreshHandlerService
  ) { 
    super();
  }

  ngOnInit(): void {
    
    this.route.paramMap.subscribe(params => {
      this.countryCode = params.get('countryCode');
      this.customerID = params.get('customerID');
      this.sn = params.get('sn');
    });      
  
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      const parentPageRequestHandler = this.apiService.get(this.config.parentPageRequestHandler);
      parentPageRequestHandler.onStateChange((state) => {
        this.state = state;
      });
      parentPageRequestHandler.sendRequest(this.config.parentPageRequestHandler, { months: 12});
      this.refreshHandler.onRefreshEvent(
        (index) => {
          parentPageRequestHandler.sendRequest(this.config.parentPageRequestHandler, { months: 12});
        },
        parentPageRequestHandler.getRelativeParams(this.config.parentPageRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      parentPageRequestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = parentPageRequestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          // find the correct apiData record for the routing params
          for (let r = 0; r < apiData['Details'].length; r++) {
            let currentRec = apiData['Details'][r];
            if (currentRec['customerID'] == this.customerID 
                && currentRec['countryCode'] == this.countryCode 
                && currentRec['sn'] == this.sn) {
              this.apiDataRecord = currentRec;
              break;
            }
          }
        }
      });       

      if (this.apiDataRecord != null) {
        const requestHandler = this.apiService.get(this.config.apiRequestHandler);
        requestHandler.onStateChange((state) => {
          this.state = state;
        });

        const params = {
          months: 12,
          countryCode: this.countryCode,
          customerID: this.customerID,
          sn: this.sn
        }

        requestHandler.sendRequest(this.config.apiRequestHandler, params);
        this.refreshHandler.onRefreshEvent(
          (index) => {
            requestHandler.sendRequest(this.config.apiRequestHandler, params);
          },
          requestHandler.getRelativeParams(this.config.apiRequestHandler)
        );

        // no need to send request here as chart tile has already done so
        requestHandler.onStateChange((state) => {
          if (state === RequestState.Complete) {
            const response = requestHandler.getResponse();
            const apiData = JSON.parse(response.body);

            const dataColsConfig = this.config.table.dataCols;
            this.dataCols = [];
            for (let i = 0; i < this.config.table.dataCols.length; i++) {
              this.dataCols.push({
                ref: this.config.table.dataCols[i].key,
                description: this.config.table.dataCols[i].title,
                width: this.config.table.dataCols[i].width,
                dateFormat: this.config.table.dataCols[i].dateFormat,
                customStyle: this.config.table.dataCols[i].customStyle
              });
            }
            // format dataRows for kendo component
            this.dataRows = [];
            for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
              this.dataRows.push(apiData[this.config.dataArrayKey][r]);
            }
            this.updateGrid();
          }             
        });
      }      
    }
  }
}
