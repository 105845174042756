import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef, Input,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {ComponentLookupRegistry} from '../../decorators/component-lookup';
import {TileReference} from '../tile-reference';

@Component({
  selector: 'app-tile-insert',
  templateUrl: './tile-insert.component.html',
  styleUrls: ['./tile-insert.component.scss']
})
export class TileInsertComponent implements AfterViewInit, OnDestroy {
  dynamicComponentRef: ComponentRef<any>;

  @Input() tileReference: TileReference;

  @ViewChild('dynamic', {read: ViewContainerRef}) viewContainerRef: ViewContainerRef;

  constructor(private factoryResolver: ComponentFactoryResolver, private cd: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.loadDynamicComponent();
    });
    this.cd.detectChanges();
  }

  loadDynamicComponent() {
    const componentType: Type<any> = ComponentLookupRegistry.get(this.tileReference.componentName);
    if (componentType !== undefined) {
      const componentFactory = this.factoryResolver.resolveComponentFactory(componentType);

      this.viewContainerRef.clear();
      this.dynamicComponentRef = this.viewContainerRef.createComponent(componentFactory);
      // add tileReference to component data inputs
      (this.dynamicComponentRef.instance).tileReference = this.tileReference;
    }
  }

  ngOnDestroy() {
    if (this.dynamicComponentRef) {
      this.dynamicComponentRef.destroy();
    }
  }
}
