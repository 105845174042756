<div class="navbar-fixed-top">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="justify-content-between d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-row flex-fill" *ngIf="navFilterSelection">
                <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                <div class="navbar-expanded-only">
                    <!--<app-persona-selector></app-persona-selector>-->
                    <span class="mx-auto title">
                        {{navFilterSelection.viewTitleText}}
                    </span>
                </div>
                <button class="navbar-toggler ml-auto"
                        type="button"
                        data-toggle="collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        aria-label="Toggle navigation"
                        (click)="clickEvent()">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="navbar-collapse flex-row d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" *ngIf="navFilterSelection">
                <div class="d-flex flex-row flex-fill">
                    <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                    <a class="nav-link colorwhite" routerLink="{{navFilterSelection.navigateBackwardsLink}}">
                        <fa-icon [icon]="faChevronLeft"></fa-icon> {{navFilterSelection.navigateBackwardsLabel}}
                    </a>
                    <span class="mx-auto title">
                        {{navFilterSelection.viewTitleText}}
                    </span>
                    <app-bookmarks *ngIf="navFilterSelection.bookmarksFlag" [displayTitles]="false"></app-bookmarks>
                </div>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-sm-none d-md-block d-lg-block d-xl-block" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse d-flex flex-row flex-fill" id="navbarSupportedContent" *ngIf="navFilterSelection">
                <div class="filter-text">
                    <!--<fa-icon [icon]="faFilter"></fa-icon><br/>-->
                    Filters
                </div>
                <div>
                    <!--title="Persona: Filter metrics to the user or manager selected or set the CRL based on Location."-->
                    <app-persona-selector [disabled]="true" [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector>
                </div>
                <div>
                    <app-detail-persona-selector [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-detail-persona-selector>
                </div>
                <div class="ml-auto switch-container">
                    <app-parameter-switches [displayTitles]="false"></app-parameter-switches>
                </div>
            </div>
        </div>
    </nav>
</div>
<div class="wrapper">
    <div class="sidebar" [ngClass]="status ? 'active' : 'inactive'">
        <span *ngIf="navFilterSelection">
            <a class="nav-link" routerLink="{{navFilterSelection.navigateBackwardsLink}}">
                <fa-icon [icon]="faChevronLeft"></fa-icon> {{navFilterSelection.navigateBackwardsLabel}}
            </a>
            <div>
                <!--title="Persona: Filter metrics to the user or manager selected or set the CRL based on Location."-->
                <app-persona-selector [disabled]="true" [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"></app-persona-selector>
            </div>
            <div>
                <app-detail-persona-selector [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"></app-detail-persona-selector>
            </div>
            <div>
                <app-parameter-switches [displayTitles]="true"></app-parameter-switches>
            </div>
            <app-bookmarks *ngIf="navFilterSelection.bookmarksFlag" [displayTitles]="true"></app-bookmarks>
        </span>
    </div>
    <div class="overlay" (click)="closeSidebar()" [ngClass]="status ? 'active' : 'inactive'"></div>
</div>