<div *ngIf="gridData">
    <a [routerLink]="config.table.level2DetailLink ? [config.table.level2DetailLink] : []">
        <kendo-grid
            [data]="gridData"
            [reorderable]="false"
            [pageable]="false"
            [navigable]="false"
        >
            <!--scrollable="none"-->
            <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                    [field]="col.ref"
                    [title]="col.description"
                    [width]="col.width"
                    [headerClass]="'data-grid-header'">
                    <ng-template kendoGridCellTemplate let-dataItem let-columnIndex="columnIndex" let-rowIndex="rowIndex">
                        <!-- <span class="whole-cell" [style]="cellStyle(columnIndex)">
                            {{dataItem[columnIndex] | emptyMask | abbottBigNumber}}
                        </span> -->
                        <ng-container *ngIf="config.table.currencyStyleForAMB === true; else two">
                            <span class="whole-cell" [style]="cellStyle(columnIndex)">
                                {{dataItem[col.ref] | emptyMask | abbottBigNumber}}
                            </span>
                        </ng-container>
                        <ng-template #two>
                            <span class="whole-cell" [style]="cellStyle(columnIndex)">
                                {{dataItem[col.ref] | emptyMask}}
                            </span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
        </kendo-grid>
    </a>
</div>
