import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import {TileReference} from '../../../dashboards/tile-reference';
import {BaseTileComponent} from '../../../tiles/base/base-tile.component';
import {RequestState} from '../../../api/request-handler';

@ComponentLookup('ApiTestComponent')
@Component({
  selector: 'app-api-test',
  templateUrl: './api-test.component.html',
  styleUrls: ['./api-test.component.scss']
})
export class ApiTestComponent extends BaseTileComponent implements OnInit, OnDestroy {
  @Input() tileReference: TileReference;
  public apiData;
  public chartOptions;
  protected refreshHandleKey;
  protected requestHandler;
  apiCallIncrement = 1;
  queryParams;

  ngOnInit(): void {
    this.setupPreferences();
    this.requestHandler = this.callApiSource(this.tileReference.apiSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.setApiCallResponse(apiData, 0);
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.tileReference.apiSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.tileReference.apiSource));
  }

  refreshCall() {
    // { force_call_via_unique_param: this.apiCallIncrement}
    this.requestHandler.sendRequest(this.tileReference.apiSource); // , null, true
    this.apiCallIncrement++;
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
  }

  setApiCallResponse(apiData, apiDataIndex) {
    this.apiData = apiData;
  }

  getQueryParams2() {
    this.queryParams = this.detailQueryParamsService.getQueryParams();
    this.queryParams['component'] = 'DdtLoaderComponent';
    this.queryParams['apiUrl'] = this.tileReference.apiSource;
    return this.queryParams;
  }
}
