import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { BrParamsService } from '../../br-params.service';
import { BrHistoryRange } from '../../br-history-range.service';
import { NavbarBuilderService } from '../../../navigation/navbar-builder.service';
import { ToolSelectionOptions } from '../../../configuration/nav-filter-selection';
import { ListViewPages } from '../../configuration/details/list-view-pages';
import { RequestState } from '../../../api/request-handler';
import { ApiService } from '../../../api/api.service';
import { RefreshHandlerService } from '../../../api/refresh-handler.service';
import { GridComponent } from '../../../details/grid.component';
import {DetailQueryParamsService} from '../../detail-query-params.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-br-timeline-view',
  templateUrl: './br-timeline-view.component.html',
  styleUrls: ['./br-timeline-view.component.scss']
})

export class BrTimelineViewComponent extends GridComponent implements OnInit, OnDestroy {

  kpiConfig;
  listViewPages = ListViewPages;
  public apiData;
  public state = RequestState.Inactive;
  apiUrl;
  apiDisplayTitle;
  apiFolder;
  apiChartType;
  sCustomerName;
  sUck;
  requestHandler;
  stateChangeHandler;
  dataCols = [];
  dataRows = [];
  chartData = [];
  chartLabels = [];
  returnLink;
  returnLinkSubscription;
  queryParamsSubscription;
  routeUrlSubscription;
  detailQueryCompleteSubscription;
  multiChartSeriesArray = [];

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private brParamsService: BrParamsService,
    private brHistoryRange: BrHistoryRange,
    private location: Location,
    private apiService: ApiService,
    private detailQueryParamsService: DetailQueryParamsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    this.detailQueryCompleteSubscription = this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.listViewPages.hasOwnProperty(currentRouterLocation)) {
        this.kpiConfig = this.listViewPages[currentRouterLocation];
      }

      this.apiUrl = this.kpiConfig.apiSource;
      this.apiDisplayTitle = this.kpiConfig.apiTitle;
      this.apiFolder = this.kpiConfig.apiFolder;
      // console.log('API CONFIGS', this.kpiConfig);
      if (this.kpiConfig.apiChartType) { this.apiChartType = this.kpiConfig.apiChartType; }

      this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_detail'));

      this.navbarBuilderService.setNavbarChoice('business-review');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        dashboardSelector: false,
        toolSelection: ToolSelectionOptions.businessreview,
        bookmarksFlag: false,
        viewTitle: false,
        viewTitleText: '',
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      this.requestHandler = this.apiService.get(this.apiUrl);
      this.requestHandler.sendRequest(this.apiUrl);
      // no need to send request here as chart tile has already done so
      this.stateChangeHandler = (state) => {
        this.state = state;
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.apiData = JSON.parse(response.body);
          // console.log('TIMELINE DATA', this.apiData.values);

          // SET CUSTOMER NAME
          if (this.apiData.customer) {
            this.sCustomerName = this.apiData.customer;
          }

          // FORMAT API DATA FOR TABLE - format dataCols for kendo component
          this.dataCols = this.kpiConfig.table.columns;
          if (this.apiData.columns) { this.dataCols = this.apiData.columns; }

          // format dataRows for kendo component
          this.dataRows = [];
          this.chartData = [];
          for (let i = 0; i < this.apiData.values.length; i++) {
            const apiDataRow = this.apiData.values[i];
            const dataRow = {};
            const multiChartSeries = [];
            let multiChartSeriesTitle;
            for (let c = 0; c < this.dataCols.length; c++) {
              const dataColConfig = this.dataCols[c];
              dataRow[dataColConfig.ref] = {
                value: apiDataRow[dataColConfig.ref]
              };
              // console.log('Data Element', dataRow[dataColConfig.ref].value);

              if (this.apiChartType === 'MultiBar' || this.apiChartType === 'StackBar') {
                if ( dataColConfig.ref !== 'title') {
                  multiChartSeries.push(apiDataRow[dataColConfig.ref]);
                } else {
                  multiChartSeriesTitle = apiDataRow[dataColConfig.ref];
                }
              } else {
                if ( dataColConfig.ref !== 'title') {
                  this.chartData.push(dataRow[dataColConfig.ref].value);
                }
              }
            }
            if (this.apiChartType === 'MultiBar') {
              this.multiChartSeriesArray.push({
                data: multiChartSeries,
                name: multiChartSeriesTitle,
                stack: false
              });
            } else if (this.apiChartType === 'StackBar') {
              this.multiChartSeriesArray.push({
                data: multiChartSeries,
                name: multiChartSeriesTitle,
                stack: true
              });
            }
            this.dataRows.push(dataRow);
          }
          this.updateGrid();
        }
      };
      this.requestHandler.onStateChange(this.stateChangeHandler);
      this.updateGrid();
    });

    this.returnLinkSubscription = this.detailQueryParamsService.getReturnLink().subscribe((pathDetails) => {
      this.returnLink = pathDetails;
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.detailQueryParamsService.prepReturnParams(params);
      this.detailQueryParamsService.buildPersistedValuesFromParams(params);
    });
    this.routeUrlSubscription = this.route.url.subscribe((url) => {
      this.detailQueryParamsService.prepReturnLink(url);
      this.detailQueryParamsService.processReturnLink();
    });
  }

  getDetailLink() {
    return '/businessReview/' + this.apiFolder + '/list_view';
  }

  ngOnDestroy(): void {
    if (this.stateChangeHandler) {
      this.requestHandler.removeStateListener(this.stateChangeHandler);
    }
    this.detailQueryCompleteSubscription.unsubscribe();
    this.returnLinkSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.routeUrlSubscription.unsubscribe();
  }

  getQueryParams(dataItem) {
    let params = {};
    if ('uck' in dataItem) {
      params['ckey'] = dataItem.uck.value;
    }
    if ('acct_pres_name' in dataItem) {
      params['acct_pres_name'] = dataItem.acct_pres_name.value;
    }
    if (dataItem['period']) {
      params['period'] = dataItem['period'];
    }
    if (this.apiData.uck) {
      params['uck'] = this.apiData.uck;
    }

    return this.detailQueryParamsService.getQueryParams( params );
  }

}
