import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {
  faListAlt,
  faChartBar,
  faWindowClose
} from '@fortawesome/free-solid-svg-icons';
import {Personas} from '../../configuration/personas';
import {DashboardOptions, ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {PersonaService} from '../../api/persona.service';
import {SelectedTabService} from '../../dashboards/selected-tab.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {CtServiceService} from '../ct-service.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {PreferenceSelectionService} from '../../preferences/preference-selection.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import {FilterChoicesService} from '../../filter_selection/filter-choices.service';

@Component({
  selector: 'app-ct-dashboard',
  templateUrl: './ct-dashboard.component.html',
  styleUrls: ['./ct-dashboard.component.scss']
})
export class CtDashboardComponent implements OnInit, OnDestroy {
  faListAlt = faListAlt;
  faChartBar = faChartBar;
  faWindowClose = faWindowClose;
  refreshIndex = null;
  selectedCustomerName = null;
  @ViewChild('tabstrip') public tabstrip;
  selectedTab = 0;
  alertsCount;
  customersCount;
  requestHandler;
  prefRequestHandler;
  managerApiData;
  managerDataLoaded = false;
  preferencesLoaded = false;
  refreshIndexes = [];
  customerChangeEventIndex;
  showLoadingAccountMessage = false;
  showAccountNotFoundMessage = false;
  accountNumber = '';

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService,
    private refreshHandler: RefreshHandlerService,
    private ctService: CtServiceService,
    private apiService: ApiService,
    private preferenceSelectionService: PreferenceSelectionService,
    private authService: AuthenticationService,
    private filterService: FilterChoicesService,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.selectedTabService.selectTab(DashboardOptions.controltower); // this must proceed managerId hack
    /**
     * HACK TO FIX ISSUE WHERE MANAGER UPI IS CONFIGURED FOR MYCRL ROUTES AND AOH USES 511
     */
    this.authService.onAuth(() => {
      this.updateManagerIdHackLoadManagerListAndPreferences();
    });
    this.updateManagerIdHackLoadManagerListAndPreferences();
    // HACK END

    this.navbarBuilderService.setNavbarChoice('ct-dashboard');
    this.personaService.setServiceVsAmbState(true);
    this.personaService.refreshDetailChoices();
    this.navbarBuilderService.setNavFilterSelection({
      preferences: true,
      personaSelector: true,
      dashboardSelector: false,
      selectedDashboard: null,
      search: true,
      toolSelection: null,
      legacyCustomersFlag: false,
      myVsAllInstrumentsFlag: false,
      customerWTrackSystemsFlag: false,
      flagshipProductLinesFlag: false,
      bookmarksFlag: false,
      navigateBackwards: false,
      navigateBackwardsLabel: 'Back',
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false,
      personaOptions: [
        Personas.TssAmbassador,
        Personas.CuaManager,
        Personas.GeoLocation,
        Personas.AccountRep,
        Personas.CtCustomer,
        Personas.CtAccount
      ],
      prefCurrencyChoices: false
    });

    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.refreshHandler.triggerCallbacks(['ct_persona_type', 'ct_persona_value']);
        // close customer page
        this.clearCustomer();
      },
      ['TSS', 'CuaManager', 'ManagersSpecifcAmb', 'AccountRep', 'CuaAmb', 'GEO', 'Amb', 'Account']
    ));

    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.refreshHandler.triggerCallbacks([
          'cua_product_family_set', 'cua_uniqueCustomerKey', 'ct_uniqueCustomerKey',
          'cua_persona_type', 'cua_persona_value', 'ct_persona_type', 'ct_persona_value'
        ]);
      },
      ['Customer']
    ));
    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.refreshHandler.triggerCallbacks(['cua_primary', 'cua_secondary', 'cua_tertiary']);
      },
      ['primary', 'secondary', 'tertiary']
    ));

    this.customerChangeEventIndex = this.ctService.onCustomerSelectionChange(() => {
      if (this.selectedCustomerName !== this.ctService.getCustomerName() && this.ctService.getCustomerName() !== null) {
        setTimeout(() => {
          this.tabstrip.selectTab(2);
        }, 500);
      }
      this.selectedCustomerName = this.ctService.getCustomerName();
      this.cdRef.detectChanges();
    });

    // fix so that when returning from mycrl it handles setting customer back the way it was
    if (this.ctService.isReturnCustomerSet()) {
      const aohCustomerLabel = this.personaService.getPersonaLabel(PersonaTypes.Customer);
      this.personaService.setPersonaOption(PersonaTypes.Customer, this.ctService.getReturnCustomer(), aohCustomerLabel);
    }

    this.route.paramMap.subscribe(params => {
      const accountUrlParam = params.get('account');
      this.accountNumber = accountUrlParam;
      if (accountUrlParam !== null) {
        this.showLoadingAccountMessage = true;
        const accountRequestHandler = this.apiService.get('cua/account_search');
        this.filterService.setFilter('searchQueryCuaAccount', accountUrlParam);
        accountRequestHandler.sendRequest('cua/account_search');
        accountRequestHandler.onStateChange((state) => {
          if (state === RequestState.Complete) {
            this.showLoadingAccountMessage = false;
            const response = accountRequestHandler.getResponse();
            const apiData = JSON.parse(response.body);

            let selectedItem = null;
            for (const account of apiData.data) {
              if (accountUrlParam && accountUrlParam === account.acctEntityId) {
                selectedItem = {
                  label: account.acctEntityName, // or customersrno
                  key: account.acctEntityId
                };
              }
            }

            if (selectedItem) {
              this.personaService.setPersonaOption(PersonaTypes.Account, selectedItem.key, selectedItem.label);
              this.personaService.setCustomerOverride(true);
            } else {
              this.showAccountNotFoundMessage = true;
            }
          }
        });
      }
    });
  }

  closeAccountNotFoundMessage() {
    this.showAccountNotFoundMessage = false;
  }

  ngOnDestroy(): void {
    for (const index of this.refreshIndexes) {
      this.refreshHandler.cancelRefreshEvent(index);
    }
    this.ctService.clearCustomerSelectionChangeEvent(this.customerChangeEventIndex);
  }

  updateManagerIdHackLoadManagerListAndPreferences() {
    if (!this.preferenceSelectionService.arePreferencesLoaded() && this.authService.isAuth()) {
      this.prefRequestHandler = this.apiService.get('/preference');
      this.prefRequestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          this.preferencesLoaded = true;
          this.updateManagerIdHackFixPersona();
        }
      });
    } else {
      this.preferencesLoaded = true;
    }
    if (this.authService.isAuth()) {
      this.requestHandler = this.apiService.get('cua/manager_list');
      this.requestHandler.sendRequest('cua/manager_list', null, false);
      this.requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.managerApiData = JSON.parse(response.body);
          this.managerDataLoaded = true;
          this.updateManagerIdHackFixPersona();
        }
      });
    }
  }

  updateManagerIdHackFixPersona() {
    let level1PersonaChoice = this.personaService.getLevel1Choice();
    if (
      this.managerDataLoaded
      && this.preferencesLoaded
      && level1PersonaChoice.type === PersonaTypes.Manager
      && this.selectedTabService.getLastSelectedTabInfo() === DashboardOptions.controltower // do not call unless ct dashboard page active
    ) {
      level1PersonaChoice = this.personaService.getLevel1Choice();
      for (const user of this.managerApiData) {
        if (user.mgrUpi === level1PersonaChoice.value) {
          this.filterService.setFilter('product_set_number', 3);
          this.personaService.setPersonaOption(
            PersonaTypes.CuaManager,
            user.mgr511,
            user.firstName + ' ' + user.lastName);
          this.refreshHandler.triggerCallbacks(['ct_persona_type', 'ct_persona_value']);
        }
      }
    }
  }

  onAlertsUpdate(alertsCount) {
    this.alertsCount = alertsCount;
    this.determineTab();
  }
  onHealthMetricsUpdate(customersCount) {
    this.customersCount = customersCount;
  }

  determineTab() {
    if (this.alertsCount > 0) {
      this.selectedTab = 0;
    } else {
      this.selectedTab = 1;
    }
    this.cdRef.detectChanges();
  }

  clearCustomer() {
    this.ctService.setReturnCustomer(null);
    this.personaService.setPersonaOption(PersonaTypes.Customer, 'all', '');
    this.personaService.setCustomerOverride(false);
    this.ctService.setCustomerId('all');
    this.tabstrip.selectTab(0);
  }
}
