import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
  selector: 'app-manager-amb-selector',
  templateUrl: './manager-amb-selector.component.html',
  styleUrls: ['./manager-amb-selector.component.scss']
})
export class ManagerAmbSelectorComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  selectedItem;
  requestHandler;
  userName;
  user511;
  userUPI;
  isAMB;
  isAMBManager;
  isSuperUser;
  authSet = false;
  dataHasExtras = false;

  constructor(
    private apiService: ApiService,
    private personaService: PersonaService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    if (this.isAMBManager) {
      this.requestHandler = this.apiService.get('/users/amb_tss');
      this.requestHandler.sendRequest('/users/amb_tss');
      this.requestHandler.onStateChange((state) => {
        this.apiState = state;
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          const buildData = [];
          for (const user of apiData.users) {
            buildData.push({
              label: user.firstname,
              key: user.abbottid,
              type: 'amb'
            });
          }
          this.data = buildData;
          this.dataHasExtras = false;
          this.customizeData();
        }
      });
    }

    this.authService.onAuth(() => {
      const userInfo = this.authService.getUserInfo();

      this.userName = userInfo.firstname + ' ' + userInfo.lastname;
      this.user511 = userInfo.abbottid;
      this.userUPI = userInfo.upi;
      this.isAMB = userInfo.isAMB;
      this.isAMBManager = userInfo.isAMBManager;
      this.isSuperUser = userInfo.isSuperUser;
      this.authSet = true;
      this.customizeData();
    });
    // this.data = [
    //   { label: 'Manager Overview', key: '12117547', overview: true },
    //   { label: 'Test Amb 1', key: 'YOUNGJX13', overview: true },
    //   { label: 'Test Amb 2', key: 'COLEYAR', overview: true },
    //   { label: 'Test Amb 3', key: 'CHANDSN', overview: true }
    // ];
  }

  customizeData() {
    /**
     * if isamb add item for non manager
     * if ismanager add item for manager overview
     */
    if (!this.dataHasExtras && this.authSet) {
      if (this.isSuperUser || !this.isAMBManager || this.isAMB) {
        this.data.unshift({ label: '* ' + this.userName, key: this.user511, type: 'authuser' });
        this.apiState = RequestState.Complete;
      }
      if (this.isAMBManager) {
        this.data.unshift({ label: 'Manager Overview', key: this.userUPI, type: 'ambmanager' });
      }
      this.dataHasExtras = true;
    }
  }

  ngOnDestroy(): void {
    if (this.requestHandler !== undefined) {
      this.requestHandler.cancelSubscription();
    }
  }

  getDataItemByKeyField(keyField) {
    for (const item of this.data) {
      if (keyField === item.key) {
        return item;
      }
    }
    return null;
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    const dataItem = this.getDataItemByKeyField(selectedItem);
    if (dataItem.type === 'authuser') {
      this.personaService.setPersonaOption(PersonaTypes.ManagersSpecifcAmb, this.user511, this.userName);
    } else if (dataItem.type === 'ambmanager') {
      this.personaService.setPersonaOption(PersonaTypes.ManagerOverview, this.userUPI, this.userName);
    } else {
      this.personaService.setPersonaOption(PersonaTypes.ManagersSpecifcAmb, dataItem.key, dataItem.label);
    }
  }
}
