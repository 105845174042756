import { Component, OnInit } from '@angular/core';
import {GridComponent} from '../grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {Location} from '@angular/common';
import {Style1Pages} from '../../configuration/details/style1-pages';

/**
 * this component is designed specifically for low utilization
 * @todo: give component a more meaningful name
 */
@Component({
  selector: 'app-custom-grid',
  templateUrl: './custom-grid.component.html',
  styleUrls: ['./custom-grid.component.scss']
})
export class CustomGridComponent extends GridComponent implements OnInit {
  rowFormatting = [];
  dataCols = [];
  dataRows = [];

  style1Pages = Style1Pages;

  config = null;

  protected rowParserData = {};

  constructor(
    protected location: Location,
    protected apiService: ApiService
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style1Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style1Pages[currentRouterLocation];

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          // FORMAT API DATA FOR TABLE
          const lowestRange = apiData.range[0];
          const secondLowestRange = apiData.range[1];

          // format dataCols for kendo component
          this.dataCols = this.config.table.dataCols;

          // format dataRows for kendo component
          this.dataRows = [];
          for (let i = 0; i < lowestRange.values.length; i++) {
            this.dataRows.push({
              instrument: lowestRange.values[i].xlabel,
              lowRangeNumberOfTests: lowestRange.values[i].stacks[1],
              lowRangeInstrumentsCount: lowestRange.values[i].stacks[0],
              secondLowestRange: secondLowestRange.values[i].stacks[1],
              secondLowestInstrumentCount: secondLowestRange.values[i].stacks[0]
            });
          }

          // setup data for formatting row colors
          this.rowFormatting = lowestRange.stackcolors;

          this.updateGrid();
        }
      });
    }
  }

  color(rowIndex) {
    return this.rowFormatting[rowIndex];
  }
}
