import { Component, OnInit, ElementRef, HostListener, ViewChild, Input } from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {DashboardOptions, NavFilterSelection} from '../../configuration/nav-filter-selection';
import {Personas} from '../../configuration/personas';
import {AuthenticationService} from '../../authentication/authentication.service';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {SelectedTabService} from '../../dashboards/selected-tab.service';

@Component({
  selector: 'app-persona-selector2',
  templateUrl: './persona-selector2.component.html',
  styleUrls: ['./persona-selector2.component.scss']
})
export class PersonaSelector2Component implements OnInit {
  DashboardOptions = DashboardOptions;
  Personas = Personas;
  PersonaTypes = PersonaTypes;
  navFilterSelection: NavFilterSelection;
  menuViable = false;
  selectedTab = null;
  isAMB = false;
  isAMBManager = false;
  isSuperUser = false;

  lastSelectedDashboardTabInfo = null;
  selectedType = null;
  selectedValue = null;
  selectedLabel = null;
  menuText1 = '';
  menuText2 = '';

  @Input() anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  @Input() popupAlign = { horizontal: 'left', vertical: 'top' };

  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private authService: AuthenticationService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();

    this.authService.onAuth(() => {
      const userInfo = this.authService.getUserInfo();

      this.isAMB = userInfo.isAMB;
      this.isAMBManager = userInfo.isAMBManager;
      this.isSuperUser = userInfo.isSuperUser;
      this.updateSelections();
    });

    this.personaService.onPersonaChoiceUpdate(() => {
      this.updateSelections();
    });
    this.selectedTabService.onTabChange(() => {
      this.updateSelections();
    });
    this.updateSelections();
    this.onResize();
  }

  updateSelections() {
    this.lastSelectedDashboardTabInfo = this.selectedTabService.getLastSelectedTabInfo();
    const level2Choice = this.personaService.getLevel2Choice();
    if (level2Choice) {
      this.selectedType = level2Choice.type;
      this.selectedValue = level2Choice.value;
      this.selectedLabel = level2Choice.label;

      if (this.selectedType === null
        || this.selectedType === undefined
        || this.selectedValue === null
        || this.selectedValue === undefined
        || this.selectedValue === ''
        || this.selectedValue === 'all'
      ) {
        if (
          this.lastSelectedDashboardTabInfo === DashboardOptions.customer
          || this.lastSelectedDashboardTabInfo === DashboardOptions.controltower
        ) {
          this.menuText1 = 'Select Customer';
        } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.instruments) {
          this.menuText1 = 'Select Instrument or SN';
          this.selectedType = PersonaTypes.ProductLine;
        } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.ambassador) {
          this.menuText1 = 'Select Account';
          this.selectedType = PersonaTypes.Account;
        }
        this.menuText2 = '';
      } else {
        this.menuText1 = this.selectedType;
        this.menuText2 = this.selectedLabel;
      }
      this.onSelectedChange(this.selectedType);
    } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.controltower) {
      this.menuText1 = 'Select Customer';
    }
  }

  onToggle(): void {
    this.menuViable = !this.menuViable;
  }

  isActiveOption(persona) {
    return this.navFilterSelection.personaOptions.includes(persona);
  }

  countActiveOptions(personas) {
    let count = 0;
    for (const persona of personas) {
      if (this.navFilterSelection.personaOptions.includes(persona)) {
        count += 1;
      }
    }
    return count;
  }

  onSelectedChange(selectedPersona) {
    this.selectedTab = selectedPersona;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.menuViable) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target) && this.menuViable) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (target.classList.contains('list-group-item')) {
      return false;
    }
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }
}
