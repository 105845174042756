import {Component, Input, OnInit} from '@angular/core';
import {ComponentLookup} from '../../decorators/component-lookup';
import {TileReference} from '../../dashboards/tile-reference';

@ComponentLookup('PlaceholderComponent')
@Component({
  selector: 'app-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss']
})
export class PlaceholderComponent implements OnInit {
  @Input() tileReference: TileReference;

  preferences;

  constructor() { }

  ngOnInit(): void {
    this.setupPreferences();
  }

  defaultPreferences() {
    return {
      title: ''
    };
  }

  setupPreferences() {
    const preferences = this.defaultPreferences();
    if (this.tileReference.hasOwnProperty('preferences')) {
      for (const prefKey in this.tileReference.preferences) {
        if (this.tileReference.preferences.hasOwnProperty(prefKey)) {
          const prefValue = this.tileReference.preferences[prefKey];
          preferences[prefKey] = prefValue;
        }
      }
    }
    this.preferences = preferences;
  }

  getPreference(key) {
    return this.preferences[key];
  }
}
