<div class="filter-section">
    <div class="filter-header">Categories</div>
    <div class="filter-results">
        <div class="switch-row" *ngFor="let item of config">
            <div class="switch-container">
                <kendo-switch [checked]="selectedValues[item.key]" (valueChange)="toggleSwitchValue(item.key, selectedValues[item.key])"></kendo-switch>
            </div>
            <div class="switch-label">
                {{item.label}}
            </div>
        </div>
    </div>
</div>
