<div class="br-snapshot">
  <h3 *ngIf="preferences.showTitle && strChartTitle === undefined">{{preferences.title}}</h3>
  <app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData">
      <h3>{{strChartTitle}}</h3>
      <h4 *ngIf="'subHeading' in preferences">{{preferences.subHeading}}</h4>
      <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
        <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          <div *ngIf="chartOptions !== null && chartOptions != undefined">
            <kendo-chart [seriesDefaults]="chartOptions.seriesDefaults"
                         [series]="chartOptions.series"
                         [valueAxis]="chartOptions.valueAxis"
                         [categoryAxis]="chartOptions.categoryAxis"
                         [plotArea]="chartOptions.chartArea">
            </kendo-chart>
          </div>
        </a>
        </div>
        <!--<kendo-chart>
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item [categories]="['2022-03', '2022-04', '2022-05', '2022-06']"
                                          [title]="{ text: 'Periods' }">
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-series>
          <kendo-chart-series-item type="line"
                                   [data]="[ 212, 240, 156, 98]">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="line"
                                   [data]="[144, 190, 167, 212]">
          </kendo-chart-series-item>
          <kendo-chart-series-item type="line"
                                   [data]="[ 46, 123, 78, 95]">
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart> -->
        <!--
      <kendo-chart [categoryAxis]='{categories: chartCategories}'>
        <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
        <kendo-chart-series>
          <kendo-chart-series-item *ngFor="let item of chartValues"
                                   typeof='line' style="smooth" [data]="item.data" [name]="item.name" [markers]="{visible: false }">
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
      -->
      </div>
      <div *ngIf="!isDataFound(apiData)" class="no-data">
        No data!
      </div>
  </app-state-handler>
</div>
