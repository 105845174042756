<div *ngIf="config">
    <app-state-handler [requestState]="state" [data]="gridData">
    <a *ngIf="config.table.detailLink"  [routerLink]="[config.table.detailLink]">
        <kendo-grid
                [data]="gridData"
                [pageable]="true"
                [pageSize]="gridState.take"
                [skip]="gridState.skip"
                [filter]="gridState.filter"
                filterable="menu"
                [sort]="gridState.sort"
                [sortable]="true"
                [ngStyle]="{'max-height': getMaxHeight()}"
                [resizable]="true"
                (pageChange)="pageChange($event)"
                (sortChange)="sortChange($event)"
                (filterChange)="filterChange($event)">
            <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData">
                <ng-container *ngFor="let col of dataCols">
                    <kendo-excelexport-column [field]="col.ref" [title]="col.description" *ngIf="col.export"></kendo-excelexport-column>
                </ng-container>
            </kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <!--<kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>-->
                <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                        [field]="col.ref"
                        [title]="col.description"
                        [width]="col.width"
                        [hidden]="col.hidden"
                        [filterable]="(col.filterable === false) ? false: true"
                        [headerClass]="'data-grid-header'"
                        [style]="col.customStyle">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                        <ng-container *ngIf="col.refLink; else two">
                            <span  class="whole-cell">
                                <a href="{{dataItem[col.refLink]}}" target="blank" style="color:#007bff; font-weight: bold;">{{dataItem[col.ref]}}</a>
                            </span>
                        </ng-container>
                        <ng-template #two>
                            <ng-container *ngIf="col.renderCustomerNameNumStyle === true; else three">
                                <!-- make sure add customernumber column as hidden column before customer name if both needs to be combined and presented as one column -->
                                <span class="whole-cell">{{dataItem[col.ref]}}&nbsp;({{dataItem['customernumber']}})</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #three>
                            <ng-container *ngIf="col.renderAgedYNStyle === true; else threeA">
                                <div *ngIf="dataItem[col.ref] == ' ' || dataItem[col.ref] == '' || dataItem[col.ref] === false || dataItem[col.ref] === 'false'">N</div>
                                <div *ngIf="dataItem[col.ref] === true || dataItem[col.ref] === 'true'">Y</div>
                            </ng-container>
                        </ng-template>
                        <ng-template #threeA>
                            <ng-container *ngIf="col.renderALC12MAStyle === true; else four">
                                <div *ngIf="dataItem[col.ref] == 'Yes'" class="square-border square-border-green">{{dataItem[col.ref]}}</div>
                                <div *ngIf="dataItem[col.ref] == 'No'" class="square-border square-border-red">{{dataItem[col.ref]}}</div>
                            </ng-container>
                        </ng-template>
                        <ng-template #four>
                            <ng-container *ngIf="col.renderHitMissStyle === true; else five">
                                <ng-container *ngIf="dataItem[col.ref] && (dataItem[col.ref] === 'Yes' || dataItem[col.ref] === 'Hit'); else fourA">
                                    <span style='text-align: center; display: block'><img height="30" width="30" src="./assets/icons/green_checkmark.png" /></span>
                                </ng-container>
                                <ng-template #fourA>
                                    <ng-container *ngIf="dataItem[col.ref] && (dataItem[col.ref] === 'No' || dataItem[col.ref] === 'Miss'); else fourB">
                                        <span style='text-align: center; display: block'><img height="30" width="30" src="./assets/icons/red_crossmark.png" /></span>
                                    </ng-container>
                                </ng-template>
                                <ng-template #fourB>
                                    <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-template>
                        <ng-template #five>
                            <ng-container *ngIf="col.drillDownDetailsLink !== undefined && showDetailsLink(col.drillDownDetailsColName, dataItem); else six">
                                <a [routerLink]="getDrillDownDetailsLinkWithParams(col.drillDownDetailsColName, col.drillDownDetailsLink, dataItem)"
                                   style="font-weight: 500; font-size: 12pt; color: #007bff">{{col.drillDownDetailsLabel}}</a>
                            </ng-container>
                        </ng-template>
                        <ng-template #six>
                            <ng-container *ngIf="col.dateFormat !== undefined; else seven">
                                {{dataItem[col.ref] |  date: col.dateFormat }}
                            </ng-container>
                        </ng-template>
                        <ng-template #seven>
                            <ng-container *ngIf="(col.renderCustomerStyleForAmbMetrics === true && dataItem[col.ref] == null); else eight">
                                <span class="whole-cell">Anonymous</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #eight>
                            <ng-container *ngIf="col.customDateFormat !== undefined; else nine">
                                {{dataItem[col.ref] | customDateFormatter: col.customDateFormat.inputFormat:col.customDateFormat.outputFormat }}
                            </ng-container>
                        </ng-template>
                        <ng-template #nine>
                            <!-- <span class="whole-cell">{{dataItem[col.ref] | emptyMask}}</span> -->
                            <span class="whole-cell">{{dataItem[col.ref] | emptyMask: col.customValueIfBlank }}</span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
        </kendo-grid>
    </a>
    <a *ngIf="!config.table.detailLink">
        <kendo-grid
                [data]="gridData"
                [pageable]="true"
                [pageSize]="gridState.take"
                [skip]="gridState.skip"
                [filter]="gridState.filter"
                filterable="menu"
                [sort]="gridState.sort"
                [sortable]="true"
                [ngStyle]="{'max-height': getMaxHeight()}"
                [resizable]="true"
                (pageChange)="pageChange($event)"
                (sortChange)="sortChange($event)"
                (filterChange)="filterChange($event)">
            <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData">
                <ng-container *ngFor="let col of dataCols">
                    <kendo-excelexport-column [field]="col.ref" [title]="col.description" *ngIf="col.export"></kendo-excelexport-column>
                </ng-container>
            </kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <!--<kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>-->
                <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                        [field]="col.ref"
                        [title]="col.description"
                        [width]="col.width"
                        [hidden]="col.hidden"
                        [filterable]="(col.filterable === false) ? false: true"
                        [headerClass]="'data-grid-header'"
                        [style]="col.customStyle">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                        <ng-container *ngIf="col.refLink; else two">
                            <span  class="whole-cell">
                                <a href="{{dataItem[col.refLink]}}" target="blank" style="color:#007bff; font-weight: bold;">{{dataItem[col.ref]}}</a>
                            </span>
                        </ng-container>
                        <ng-template #two>
                            <ng-container *ngIf="col.renderCustomerNameNumStyle === true; else three">
                                <!-- make sure add customernumber column as hidden column before customer name if both needs to be combined and presented as one column -->
                                <span class="whole-cell">{{dataItem[col.ref]}}&nbsp;({{dataItem['customernumber']}})</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #three>
                            <ng-container *ngIf="col.renderAgedYNStyle === true; else threeA">
                                <div *ngIf="dataItem[col.ref] == ' ' || dataItem[col.ref] == '' || dataItem[col.ref] === false || dataItem[col.ref] === 'false'">N</div>
                                <div *ngIf="dataItem[col.ref] === true || dataItem[col.ref] === 'true'">Y</div>
                            </ng-container>
                        </ng-template>
                        <ng-template #threeA>
                            <ng-container *ngIf="col.renderALC12MAStyle === true; else four">
                                <div *ngIf="dataItem[col.ref] == 'Yes'" class="square-border square-border-green">{{dataItem[col.ref]}}</div>
                                <div *ngIf="dataItem[col.ref] == 'No'" class="square-border square-border-red">{{dataItem[col.ref]}}</div>
                            </ng-container>
                        </ng-template>
                        <ng-template #four>
                            <ng-container *ngIf="col.renderHitMissStyle === true; else five">
                                <ng-container *ngIf="dataItem[col.ref] && (dataItem[col.ref] === 'Yes' || dataItem[col.ref] === 'Hit'); else fourA">
                                    <span style='text-align: center; display: block'><img height="30" width="30" src="./assets/icons/green_checkmark.png" /></span>
                                </ng-container>
                                <ng-template #fourA>
                                    <ng-container *ngIf="dataItem[col.ref] && (dataItem[col.ref] === 'No' || dataItem[col.ref] === 'Miss'); else fourB">
                                        <span style='text-align: center; display: block'><img height="30" width="30" src="./assets/icons/red_crossmark.png" /></span>
                                    </ng-container>
                                </ng-template>
                                <ng-template #fourB>
                                    <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                </ng-template>
                            </ng-container>
                        </ng-template>
                        <ng-template #five>
                            <ng-container *ngIf="col.drillDownDetailsLink !== undefined && showDetailsLink(col.drillDownDetailsColName, dataItem); else six">
                                <a [routerLink]="getDrillDownDetailsLinkWithParams(col.drillDownDetailsColName, col.drillDownDetailsLink, dataItem)"
                                   style="font-weight: 500; font-size: 12pt; color: #007bff">{{col.drillDownDetailsLabel}}</a>
                            </ng-container>
                        </ng-template>
                        <ng-template #six>
                            <ng-container *ngIf="col.dateFormat !== undefined; else seven">
                                {{dataItem[col.ref] |  date: col.dateFormat }}
                            </ng-container>
                        </ng-template>
                        <ng-template #seven>
                            <ng-container *ngIf="(col.renderCustomerStyleForAmbMetrics === true && dataItem[col.ref] == null); else eight">
                                <span class="whole-cell">Anonymous</span>
                            </ng-container>
                        </ng-template>
                        <ng-template #eight>
                            <ng-container *ngIf="col.customDateFormat !== undefined; else nine">
                                {{dataItem[col.ref] | customDateFormatter: col.customDateFormat.inputFormat:col.customDateFormat.outputFormat }}
                            </ng-container>
                        </ng-template>
                        <ng-template #nine>
                            <!-- <span class="whole-cell">{{dataItem[col.ref] | emptyMask}}</span> -->
                            <span class="whole-cell">{{dataItem[col.ref] | emptyMask: col.customValueIfBlank }}</span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
        </kendo-grid>
    </a>
    </app-state-handler>
</div>