import { Component, OnInit } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import { TileComponent } from '../../base/tile.component';

@ComponentLookup('ComplianceChartComponent')
@Component({
  selector: 'app-compliance-chart',
  templateUrl: './compliance-chart.component.html',
  styleUrls: ['./compliance-chart.component.scss']
})
export class ComplianceChartComponent extends TileComponent implements OnInit {
  complianceArea = 0.95; // multiplier
  extraSpace = 1; // multiplier
  majorUnitCount = 10;
  minorUnitCount = 20;
  currency = 'Local';

  gaugeData1 = null;
  gaugeData2 = null;

  defaultPreferences() {
    return {
      showTitle: true,
      shape: 'circle'
    };
  }

  translateApiDataForChart(apiData) {
    // override for currency preference
    if (this.tileReference.apiSource === '/metrics/compliance') {
      this.preferenceApiConnectorService.onUpdate(() => {
        this.buildChartOptions(apiData);
      });
    }
  }

  buildChartOptions(apiData) {
    // 1 if local, 2 if not local
    // Compliance, Sales, SalesDollar
    let offset = 1;

    if (this.tileReference.apiSource === '/metrics/compliance') {
      const isLocal = this.preferenceApiConnectorService.getIsLocalCurrency();
      if (isLocal === 'True') {
        offset = 1;
        this.currency = 'Local';
      } else if (isLocal === 'False') {
        offset = 2;
        this.currency = 'USD';
      }
    }

    const compliance12Month = apiData.values[0];
    let goal = parseFloat(compliance12Month.stackgoals[0]);
    let stackValue = parseFloat(compliance12Month.stacks[0]);

    this.gaugeData1 = this.getGaugeData(goal, stackValue);

    goal = Math.abs(parseFloat(compliance12Month.stackgoals[offset])); // @todo: fix this as the number should not be negative
    stackValue = Math.abs(parseFloat(compliance12Month.stacks[offset]));

    this.gaugeData2 = this.getGaugeData(goal, stackValue);
    // console.log(this.gaugeData2);
  }

  getGaugeData(goal, stackValue) {
    const calcLargerValue = ((stackValue > goal) ? stackValue : goal);
    let calcMax = calcLargerValue * this.extraSpace;
    calcMax = this.nextHighestNumberDivisibleBy(calcMax, this.majorUnitCount);

    return {
      value: stackValue,
      max: calcMax,
      compliantScaleFrom: 0,
      compliantScaleTo: goal * this.complianceArea,
      notCompliantScaleFrom: goal * this.complianceArea,
      notCompliantScaleTo: calcMax,
      minorUnit: calcMax / this.minorUnitCount,
      majorUnit: calcMax / this.majorUnitCount,
    };
  }

  nextHighestNumberDivisibleBy(num1, divisibleBy) {
    num1 = parseInt(num1);
    if (!Number.isInteger(num1)) {
      return false;
    }
    while (1) {
      if (num1 % divisibleBy === 0) {
        return num1;
      }
      num1++;
    }
  }
}
