import { Component, OnInit, OnDestroy } from '@angular/core';
import {RequestState} from '../../../api/request-handler';
import {RefreshHandlerService} from '../../../api/refresh-handler.service';
import {ApiService} from '../../../api/api.service';
import {BrParamsService} from '../../br-params.service';

enum Selected {
  None = 0,
  Complete = 1,
  Partial = 2
}

@Component({
  selector: 'app-account-customer-selector',
  templateUrl: './account-customer-selector.component.html',
  styleUrls: ['./account-customer-selector.component.scss']
})
export class AccountCustomerSelectorComponent implements OnInit, OnDestroy {
  data = null;
  /*{
    column_list: [
      {
        id: 'l_2_6',
        label: 'Account (Level 2-5)',
        post_selected: false,
        single_select: true,
        items: [
          {column: 'l_2_6', id: '001', display: 'St. Marys\' Conglomerate', selected: Selected.None},
          {column: 'l_2_6', id: '002', display: 'St. Marys\' SubLayer', selected: Selected.None},
          {column: 'l_2_6', id: '003', display: 'St. Francis Hosp.', selected: Selected.None},
        ],
        extra_items: []
      },
      {
        id: 'l_1',
        label: 'Account (Level 1)',
        post_selected: false,
        single_select: true,
        items: [
          {column: 'l_1', id: '001', display: 'St. Mary\'s Group', selected: Selected.None},
          {column: 'l_1', id: '002', display: 'St. Francis Hosp.', selected: Selected.None},
          {column: 'l_1', id: '003', display: 'Harris methodist', selected: Selected.None},
          {column: 'l_1', id: '004', display: 'Texas Health', selected: Selected.None}
        ],
        extra_items: []
      },
      {
        id: 'l_c_0',
        label: 'Customer (Level 0)',
        post_selected: true,
        single_select: false,
        items: [
          {column: 'l_c_0', id: '001', display: 'St. Mary\'s Hosp', selected: Selected.None},
          {column: 'l_c_0', id: '002', display: 'St. Mary\'s Clinic', selected: Selected.None},
          {column: 'l_c_0', id: '003', display: 'St. Francis Hosp', selected: Selected.None},
          {column: 'l_c_0', id: '004', display: 'Joe Bob Clinic Super Long Text', selected: Selected.None},
          {column: 'l_c_0', id: '005', display: 'Harris Meth 1', selected: Selected.None},
          {column: 'l_c_0', id: '006', display: 'Texas Health 2', selected: Selected.None}
        ],
        extra_items: []
      }
    ],
    relationships: [
      {
        column: 'l_2_6',
        id: '001',
        display: '',
        children: [
          {column: 'l_2_6', id: '002', display: 'St. Marys\' SubLayer'},
          {column: 'l_1', id: '001', display: 'St. Mary\'s Group'},
          // {column: 'l_1', id: '005', display: 'Joe Bob List'}
        ]
      },
      {
        column: 'l_2_6',
        id: '002',
        display: '',
        children: [
          {column: 'l_1', id: '001', display: 'St. Mary\'s Group'}
        ]
      },
      {
        column: 'l_2_6',
        id: '003',
        display: 'Harris methodist',
        children: [
          {column: 'l_1', id: '002', display: 'St. Francis Hosp.'}
        ]
      },
      {
        column: 'l_2_6',
        id: '004',
        display: '',
        children: [
          {column: 'l_1', id: '003', display: 'Harris methodist'},
          {column: 'l_1', id: '004', display: 'Texas Health'}
        ]
      },
      {
        column: 'l_1',
        id: '001',
        display: 'St. Mary\'s Group',
        children: [
          {column: 'l_c_0', id: '001', display: 'St. Mary\'s Hosp'},
          {column: 'l_c_0', id: '002', display: 'St. Mary\'s Clinic'}
        ]
      },
      /*{
        column: 'l_1',
        id: '005',
        display: '',
        children: [
          {column: 'l_c_0', id: '004', display: 'Joe Bob Clinic'}
        ]
      },*/
  /*
      {
        column: 'l_1',
        id: '002',
        display: 'St. Francis Hosp.',
        children: [
          {column: 'l_c_0', id: '003', display: 'St. Francis Hosp'}
        ]
      },
      {
        column: 'l_1',
        id: '003',
        display: 'St. Francis Hosp',
        children: [
          {column: 'l_c_0', id: '005', display: 'Harris Meth 1'},
          {column: 'l_c_0', id: '009', display: 'Harris Meth 2'}
        ]
      },
      {
        column: 'l_1',
        id: '004',
        display: 'Texas Health',
        children: [
          {column: 'l_c_0', id: '006', display: 'Texas Health 2'}
        ]
      }
    ]
  };*/
  protected requestHandler;
  protected apiSource = '/qbr/search_options/account_customer/search_results';
  protected refreshHandleKey;
  public state = RequestState.Inactive;
  public RequestState = RequestState;
  public searchQuery = '';
  public presentedName = '';
  public recommendedText = '';
  public noSelections = true;
  callback;
  firstCall = true;

  constructor(
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
    private brParamsService: BrParamsService // @todo: set selected account customer in BrParamsService
  ) { }

  ngOnInit(): void {
    this.searchQuery = this.brParamsService.get('acct_customer_query');
    this.requestHandler = this.callApiSource(this.apiSource, 0);
    this.callback = (state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        const persistedChoices = this.brParamsService.get('acct_cust_data');
        if (this.firstCall && persistedChoices != null && persistedChoices !== undefined) {
          this.data = persistedChoices;
          this.firstCall = false;
          this.valueChangeColumnSelected(this.data.column_list);
        } else {
          this.data = apiData;
          if (!this.data.hasOwnProperty('status') || this.data.status !== 'fail') {
            this.brParamsService.set('acct_cust_data', this.data);
          }
        }
      }
    };
    this.requestHandler.onStateChange(this.callback);
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.apiSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.apiSource));

    this.presentedName = this.brParamsService.get('presentedName');
    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'presentedName') {
        this.presentedName = this.brParamsService.get('presentedName');
      }
      if (brParamKey === 'acct_customer_query') {
        this.searchQuery = this.brParamsService.get('acct_customer_query');
        this.refreshHandlerService.triggerCallbacks(['acct_customer_query']);
      }
    });
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.removeStateListener(this.callback);
    this.requestHandler.cancelSubscription();
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  searchChange(e) {
    this.searchQuery = e;
    setTimeout(() => {
      if (this.searchQuery === e && e.length >= 4) {
        this.brParamsService.set('acct_customer_query', e);
        this.refreshHandlerService.triggerCallbacks(['acct_customer_query']);
      }
    }, 1000);
  }

  valueChanged(e) {
    this.brParamsService.set('uck', this.getArrayOfPropertyFromObject(e, 'id'));
    this.brParamsService.set('acct_cust_data', this.data);
  }

  presentedNameChange(e) {
    this.brParamsService.set('presentedName', this.presentedName);
  }

  selectRecommendedText() {
    this.presentedName = this.recommendedText;
    this.brParamsService.set('presentedName', this.presentedName);
  }

  objectPropertyJoin(arrayOfObjects, property, glue) {
    let isFirst = true;
    let result = '';
    for (const item of arrayOfObjects) {
      if (!isFirst) {
        result += glue;
      } else {
        isFirst = false;
      }
      result += this.rmCustomerNumber(item[property]);
    }
    return result;
  }

  rmCustomerNumber(input) {
    const parts = input.split('(');
    return parts[0];
  }

  valueChangeColumnSelected(e) {
    let colL25; let colL1; let colL0;
    e.forEach((columnItem) => {
      if (columnItem.id === 'L_2_5') {
        colL25 = columnItem;
      } else if (columnItem.id === 'L_1') {
        colL1 = columnItem;
      } else if (columnItem.id === 'L_0') {
        colL0 = columnItem;
      }
    });
    this.noSelections = false;
    if (colL25.selectedItems !== undefined && colL25.selectedItems.length > 1) {
      this.recommendedText = 'Multiple Accounts ' + this.objectPropertyJoin(colL25.selectedItems, 'display', ', ');
    } else if (colL25.selectedItems !== undefined && colL25.selectedItems.length === 1) {
      this.recommendedText = colL25.selectedItems[0].display;
    } else {
      if (colL1.selectedItems !== undefined && colL1.selectedItems.length > 1) {
        this.recommendedText = 'Multiple Accounts ' + this.objectPropertyJoin(colL1.selectedItems, 'display', ', ');
      } else if (colL1.selectedItems !== undefined && colL1.selectedItems.length === 1) {
        this.recommendedText = colL1.selectedItems[0].display;
      } else {
        if (colL0.selectedItems !== undefined && colL0.selectedItems.length > 1) {
          this.recommendedText = 'Multiple Customers ' + this.objectPropertyJoin(colL0.selectedItems, 'display', ', ');
        } else if (colL0.selectedItems !== undefined && colL0.selectedItems.length === 1) {
          this.recommendedText = this.rmCustomerNumber(colL0.selectedItems[0].display);
        } else {
          this.noSelections = true;
        }
      }
    }
  }

  valueChangedData(e) {}

  getArrayOfPropertyFromObject(object, property) {
    const result = [];
    for (const item of object) {
      result.push(item[property]);
    }
    return result;
  }
}
