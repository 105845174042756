import {Component, EventEmitter, OnInit, OnDestroy, Input, Output} from '@angular/core';
import {RequestState} from '../../api/request-handler';
import {PersonaService} from '../../api/persona.service';
import {ApiService} from '../../api/api.service';
import {FilterChoicesService} from '../../filter_selection/filter-choices.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';

@Component({
  selector: 'app-account-select-list',
  templateUrl: './account-select-list.component.html',
  styleUrls: ['./account-select-list.component.scss']
})
export class AccountSelectListComponent implements OnInit, OnDestroy {
  apiRoute = 'cua/account_search';
  requestHandler;
  RequestState = RequestState;
  state = RequestState.Waiting;
  data;
  keyField = 'key';
  displayField = 'label';
  @Input() selectedOption;
  @Output() itemSelect = new EventEmitter();
  totalRecordsFound;
  totalRecordsReturned;

  result = {
    data: []
  };

  constructor(
    private apiService: ApiService,
    private personaService: PersonaService,
    private filterChoices: FilterChoicesService,
    protected refreshHandlerService: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get(this.apiRoute);
    this.requestHandler.sendRequest(this.apiRoute);
    this.requestHandler.onStateChange((state) => {
      this.state = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        this.totalRecordsFound = apiData.totalRecordsFound;
        this.totalRecordsReturned = apiData.totalRecordsReturned;

        const buildData = [];
        for (const account of apiData.data) {
          buildData.push({
            label: account.acctEntityName + ' (' + account.acctEntityId + ')',
            key: account.acctEntityId
          });
        }
        this.data = buildData;
      }
    });
    this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.apiRoute);
    }, this.requestHandler.getRelativeParams(this.apiRoute));
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  onValueChange(query) { // on search textbox change
    if (query.length >= 4) {
      this.filterChoices.setFilter('searchQueryCuaAccount', query);
    }
  }

  onListItemSelect(key) {
    this.selectedOption = key;
    this.itemSelect.emit(this.selectedOption);
  }
}
