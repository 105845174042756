import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {RequestState} from '../../../api/request-handler';
import {DetailChoicesService} from '../detail-choices.service';
import {ApiService} from '../../../api/api.service';
import {RefreshHandlerService} from '../../../api/refresh-handler.service';

@ComponentLookup('DetailAssayFilterComponent')
@Component({
  selector: 'app-detail-assay-filter',
  templateUrl: './detail-assay-filter.component.html',
  styleUrls: ['./detail-assay-filter.component.scss']
})
export class DetailAssayFilterComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  rawList = [];
  selectedItem;
  requestHandler;
  @Input() metricId;

  constructor(
    private apiService: ApiService,
    private detailChoicesService: DetailChoicesService,
    private refreshHandler: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('/search/assaysdetails');
    this.requestHandler.sendRequest('/search/assaysdetails', { metricid: this.metricId });
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // console.log(apiData);
        if (apiData.hasOwnProperty('assaysDetails')) {
          const buildData = [];
          for (const assay of apiData.assaysDetails) {
            buildData.push({
              label: assay.assays, // or customersrno
              key: assay.assaysid
            });
          }
          this.data = buildData;
        }
        this.rawList = apiData.assaysDetails;
      }
    });
    this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest('/search/assaysdetails', { metricid: this.metricId });
      },
      this.requestHandler.getRelativeParams('/search/assaysdetails')
    );
    this.selectedItem = this.detailChoicesService.getFilter('detailAssayFilter');
    this.detailChoicesService.onUpdate('diseaseStateId', (diseaseState) => {
      // filter list contents
      const buildData = [];
      const added = [];
      for (const assay of this.rawList) {
        for (const ds of assay.diseasestate) {
          if ((ds === diseaseState || diseaseState === null)
            && added.indexOf(assay.assaysid) === -1
          ) {
            buildData.push({
              label: assay.assays, // or customersrno
              key: assay.assaysid
            });
            added.push(assay.assaysid);
          }
        }
      }
      this.data = buildData;

      // update selectedItem to empty
      this.selectedItem = null;
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
    this.detailChoicesService.clearUpdateListener('diseaseStateId');
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    this.detailChoicesService.setFilter('detailAssayFilter', selectedItem, true);
    this.detailChoicesService.callUpdateListenerByKey('assayId', selectedItem);
  }
}
