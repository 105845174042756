import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {faCog} from '@fortawesome/free-solid-svg-icons';
import {PreferenceSelectionService} from '../../preferences/preference-selection.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {PreferenceApiConnectorService} from '../../preferences/preference-api-connector.service';
import {NavFilterSelection} from '../../configuration/nav-filter-selection';
import {NavbarBuilderService} from '../navbar-builder.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrls: ['./preferences.component.scss']
})
export class PreferencesComponent implements OnInit {
  navFilterSelection: NavFilterSelection = null;
  faCog = faCog;
  RequestState = RequestState;
  @Input() displayTitles;
  primaryDisabled = false;
  secondaryDisabled = false;
  tertiaryDisabled = false;

  bkMenuViable = false;
  anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  popupAlign = { horizontal: 'left', vertical: 'top' };
  collision = { horizontal: 'fit', vertical: 'fit' };

  @ViewChild('bkanchor') public anchor: ElementRef;
  @ViewChild('bkpopup', { read: ElementRef }) public popup: ElementRef;

  requestHandler;
  apiState;
  preferences;

  constructor(
    private apiService: ApiService,
    private refreshHandlerService: RefreshHandlerService,
    private preferenceSelectionService: PreferenceSelectionService,
    private preferenceApiConnectorService: PreferenceApiConnectorService,
    private navbarBuilderService: NavbarBuilderService
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection: NavFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();
    this.requestHandler = this.apiService.get('/preference');
    this.requestHandler.sendRequest('/preference');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        if (!this.preferenceSelectionService.arePreferencesLoaded()) {
          this.preferences = JSON.parse(response.body);
          this.requireOne();
        } else {
          this.preferences = this.preferenceSelectionService.getPreferences();
        }
      }
    });
    this.refreshHandlerService.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest('/preference');
      },
      this.requestHandler.getRelativeParams('/preference')
    );
  }

  saveSelections() {
    this.preferenceApiConnectorService.savePreferences(this.preferences);
    this.onToggle();
    // this.preferenceSelectionService.setPreferences(this.preferences);
  }

  showCurrency() {
     return !this.navFilterSelection.hasOwnProperty('prefCurrencyChoices') || this.navFilterSelection.prefCurrencyChoices === true;
  }

  toggle(type) {
    if (this.preferences[type] === 'True') {
      this.preferences[type] = 'False';
    } else {
      this.preferences[type] = 'True';
    }
    this.requireOne();
  }
  requireOne() {
    this.primaryDisabled = false;
    this.secondaryDisabled = false;
    this.tertiaryDisabled = false;
    let countChecked = 0;
    if (this.preferences.primary === 'True') {
      countChecked++;
    }
    if (this.preferences.secondary === 'True') {
      countChecked++;
    }
    if (this.preferences.tertiary === 'True') {
      countChecked++;
    }
    if (countChecked === 1) {
      if (this.preferences.primary === 'True') {
        this.primaryDisabled = true;
      }
      if (this.preferences.secondary === 'True') {
        this.secondaryDisabled = true;
      }
      if (this.preferences.tertiary === 'True') {
        this.tertiaryDisabled = true;
      }
    }
  }

  onToggle(): void {
    this.bkMenuViable = !this.bkMenuViable;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.bkMenuViable) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if ((this.bkMenuViable && !this.contains(event.target)) || (!this.bkMenuViable && this.contains(event.target))) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }
}
