import { Component, OnInit } from '@angular/core';
import {BrHistoryRange} from '../../br-history-range.service';
import {BrParamsService} from '../../br-params.service';

@Component({
  selector: 'app-br-history-range-selector',
  templateUrl: './br-history-range-selector.component.html',
  styleUrls: ['./br-history-range-selector.component.scss']
})
export class BrHistoryRangeSelectorComponent implements OnInit {
  periodOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
  selectedValue;

  constructor(
    private brHistoryRangeService: BrHistoryRange,
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    let presistedValue = this.brParamsService.get('br_history_range_detail');
    this.selectedValue = presistedValue;
    this.brHistoryRangeService.setHistoryRange(presistedValue);
    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'br_history_range_detail') {
        presistedValue = this.brParamsService.get('br_history_range_detail');
        this.selectedValue = presistedValue;
        this.brHistoryRangeService.setHistoryRange(presistedValue);
      }
    });
  }

  setMonths(value) {
    this.brHistoryRangeService.setHistoryRange(value);
    this.brParamsService.set('br_history_range_detail', value);
    this.selectedValue = value;
  }
}
