<div style="margin-top:-70px;">
  <button [routerLink]="['/businessReview']">KPI Home</button>
  <button *ngIf="this.returnLink !== undefined" [routerLink]="this.returnLink.url" [queryParams]="this.returnLink.params">Return</button>
  <div style="width: 100%;">
    <app-filter-presentation-header [thumbnailMonths]="isThumbnailMonths()"></app-filter-presentation-header>
  </div>
  <table class='sales-details-table'>
    <tr>
      <td colspan="2" style="font-weight:bold;font-size:15pt;color:Highlight">{{apiDisplayTitle}} - Network List</td>
    </tr>
    <!--<tr style="color: Highlight; font-weight: bold;">-->
      <!--<td>CUSTOMER: &nbsp;&nbsp;</td>-->
      <!--<td style="text-align:right;">{{ParentCustomer}}</td>-->
    <!--</tr>-->
  </table>
  <!--<kendo-chart>
    <kendo-chart-series>
      <kendo-chart-series-item type="column" [data]="[60, 70, 80, 90, 100]">
      </kendo-chart-series-item>
    </kendo-chart-series>
  </kendo-chart>-->
  <div style="width:80%;" *ngIf="chartLabels.length > 0">
    <div *ngIf="apiChartType !== 'MultiBar'">
      <kendo-chart>
        <kendo-chart-area [height]="chartRowCount*30"></kendo-chart-area>
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item [categories]="chartLabels">
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-value-axis>
          <!--https://www.telerik.com/kendo-angular-ui/components/charts/api/ValueAxisLabels/-->
          <kendo-chart-value-axis-item [min]="chartBottomLimit" [max]="chartTopLimit">
            <!--<kendo-chart-value-axis-item-labels [position]="start">-->
            <!--</kendo-chart-value-axis-item-labels>-->
          </kendo-chart-value-axis-item>
          <!--<kendo-chart-value-axis-item [min]="chartBottomLimit" [max]="chartTopLimit">-->
            <!--<kendo-chart-value-axis-item-labels [position]="end">-->
            <!--</kendo-chart-value-axis-item-labels>-->
          <!--</kendo-chart-value-axis-item>-->
        </kendo-chart-value-axis>
        <kendo-chart-series>
          <kendo-chart-series-item type="bar"
                                   [gap]="0"
                                   [spacing]="0"
                                   [stack]=false
                                   [data]="chartData"
          >
            <kendo-chart-series-item-highlight [visible]="false"></kendo-chart-series-item-highlight>
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
    <div *ngIf="apiChartType === 'MultiBar'">
      <kendo-chart>
        <kendo-chart-legend align="center" position="top"></kendo-chart-legend>
        <kendo-chart-area [height]="chartRowCount*30"></kendo-chart-area>
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item [categories]="chartLabels">
          </kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-value-axis>
          <kendo-chart-value-axis-item [min]="chartBottomLimit" [max]="chartTopLimit" [majorUnit]="chartMajorUnit">
          </kendo-chart-value-axis-item>
        </kendo-chart-value-axis>
        <kendo-chart-series>
          <kendo-chart-series-item *ngFor="let item of multiChartSeriesArray"
          type="bar"
          [data]="item.data"
          [color]="item.color"
          [name]="item.name">
            <kendo-chart-series-item-highlight [visible]="false"></kendo-chart-series-item-highlight>
            <!--[stack]=true-->
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
  </div>
  <br />
  <app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData !== null">
      <kendo-grid #grid="kendoGrid"
                  [data]="gridData"
                  [pageable]="false"
                  [pageSize]="gridState.take"
                  [skip]="gridState.skip"
                  [filter]="gridState.filter"
                  filterable="menu"
                  [sort]="gridState.sort"
                  [sortable]="true"
                  [resizable]="true"
                  (pageChange)="pageChange($event)"
                  (sortChange)="sortChange($event)"
                  (filterChange)="filterChange($event)"
                  style="max-height: 400px;max-width: 800px;">
        <ng-container *ngFor="let col of dataCols">
          <kendo-grid-column [field]="col.ref+'.value'"
                             [title]="col.description"
                             [width]="col.width">
            <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
              {{column.title}}
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span class="cell-formatting" [style.background-color]="dataItem[col.ref].bgcolor" [style.color]="dataItem[col.ref].textColor">
              <a [routerLink]="'/businessReview/'+apiFolder+'/'+apiDrillDown+'/'" [queryParams]="getQueryParams( dataItem )">{{dataItem[col.ref].value}}</a>
            </span>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
      </kendo-grid>
    </div>
  </app-state-handler>
  <div style="width: 100%; text-align: center;">
    <app-filter-presentation-footer></app-filter-presentation-footer>
  </div>
</div>
