<div style="margin-top:-70px;">
    <button [routerLink]="['/businessReview']">KPI Home</button>
    <button *ngIf="this.returnLink !== undefined" [routerLink]="this.returnLink.url" [queryParams]="this.returnLink.params">Return</button>
    <div style="width: 100%;">
        <app-filter-presentation-header></app-filter-presentation-header>
    </div>
    <div *ngIf="apiData !== null">
        <div class="ct-section-alerts-title">
            {{apiData.dataTable.tableName}}
        </div>
        <app-state-handler [requestState]="state" [data]="apiData">
            <div *ngIf="apiData !== null && dataCols.length > 0">
                <kendo-grid
                        #grid="kendoGrid"
                        [data]="gridData"
                        [pageable]="true"
                        [pageSize]="gridState.take"
                        [skip]="gridState.skip"
                        [filter]="gridState.filter"
                        filterable="menu"
                        [sort]="gridState.sort"
                        [sortable]="true"
                        [resizable]="true"
                        (pageChange)="pageChange($event)"
                        (sortChange)="sortChange($event)"
                        (filterChange)="filterChange($event)"
                >
                    <!--scrollable="none"-->
                    <kendo-grid-excel [fileName]="'grid'" [fetchData]="allData">
                        <ng-container *ngFor="let col of dataCols">
                            <kendo-excelexport-column [field]="col.ref+'.value'" [title]="col.heading" *ngIf="col.export"></kendo-excelexport-column>
                        </ng-container>
                    </kendo-grid-excel>
                    <ng-template kendoGridToolbarTemplate>
                        <button type="button" kendoGridExcelCommand icon="file-excel" style="position: absolute;margin-top: -31px;z-index: 1;">Export to Excel</button>
                    </ng-template>
                    <ng-container *ngFor="let col of dataCols">
                        <kendo-grid-column
                                [field]="col.ref+'.value'"
                                [title]="col.heading"
                                [width]="col.width"
                                [hidden]="col.hidden"
                                [filterable]="col.filterable"
                                [headerStyle]="{'background-color': col.bg_color,'color': col.text_color,'line-height': '1em','font-weight': 'bold'}">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <span *ngIf="col.isLink">
                                    <span class="link-feel" [ngClass]="'format-col-' + col.type">{{dataItem[col.ref].value}}</span>
                                </span>
                                <span *ngIf="!col.isLink" class="whole-cell" [ngClass]="'format-col-' + col.type" [style.color]="dataItem[col.ref].color" [style.background-color]="dataItem[col.ref].bgcolor">
                                    {{dataItem[col.ref].value}}
                                    <!--<span [style.background-color]="dataItem[col.ref].bgcolor">.</span>-->
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                    </ng-container>
                </kendo-grid>
            </div>
        </app-state-handler>
    </div>
</div>