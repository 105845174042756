import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {RequestState} from '../../../api/request-handler';
import {DetailChoicesService} from '../detail-choices.service';
import {ApiService} from '../../../api/api.service';
import {RefreshHandlerService} from '../../../api/refresh-handler.service';

@ComponentLookup('DetailDiseaseStateFilterComponent')
@Component({
  selector: 'app-detail-disease-state-filter',
  templateUrl: './detail-disease-state-filter.component.html',
  styleUrls: ['./detail-disease-state-filter.component.scss']
})
export class DetailDiseaseStateFilterComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  rawList = [];
  selectedItem;
  requestHandler;
  @Input() metricId;

  constructor(
    private apiService: ApiService,
    private detailChoicesService: DetailChoicesService,
    private refreshHandler: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('/search/diseasestatedetails');
    this.requestHandler.sendRequest('/search/diseasestatedetails', { metricid: this.metricId });
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // console.log(apiData);
        if (apiData.hasOwnProperty('diseaseStateDetails')) {
          const buildData = [];
          for (const diseasestate of apiData.diseaseStateDetails) {
            buildData.push({
              label: diseasestate.diseasestate, // or customersrno
              key: diseasestate.diseasestateid
            });
          }
          this.data = buildData;
        }
        this.rawList = apiData.diseaseStateDetails;
      }
    });
    this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest('/search/diseasestatedetails', { metricid: this.metricId });
      },
      this.requestHandler.getRelativeParams('/search/diseasestatedetails')
    );
    this.selectedItem = this.detailChoicesService.getFilter('detailDiseaseStateFilter');
    this.detailChoicesService.onUpdate('assayId', (assay) => {
      // filter list contents
      const buildData = [];
      const added = [];
      for (const diseasestate of this.rawList) {
        for (const asid of diseasestate.assays) {
          if ((asid === assay || assay === null)
            && added.indexOf(diseasestate.diseasestateid) === -1
          ) {
            buildData.push({
              label: diseasestate.diseasestate, // or customersrno
              key: diseasestate.diseasestateid
            });
            added.push(diseasestate.diseasestateid);
          }
        }
      }
      this.data = buildData;

      // update selectedItem to empty
      this.selectedItem = null;
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
    this.detailChoicesService.clearUpdateListener('assayId');
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    this.detailChoicesService.setFilter('detailDiseaseStateFilter', selectedItem, true);
    this.detailChoicesService.callUpdateListenerByKey('diseaseStateId', selectedItem);
    // this.personaService.setPersonaOption(PersonaTypes.Customer, selectedItem, '');
  }
}
