import { Component, OnInit } from '@angular/core';
import {
  faDesktop,
  faBorderAll
} from '@fortawesome/free-solid-svg-icons';
import {PresentationToggleHandlerService} from '../presentation-toggle-handler.service';

@Component({
  selector: 'app-presentation-toggle-switch',
  templateUrl: './presentation-toggle-switch.component.html',
  styleUrls: ['./presentation-toggle-switch.component.scss']
})
export class PresentationToggleSwitchComponent implements OnInit {
  faDesktop = faDesktop;
  faBorderAll = faBorderAll;

  state = 0;

  constructor(private presentationToggleHandlerService: PresentationToggleHandlerService) { }

  ngOnInit(): void {
  }

  changeState() {
    if (this.state === 0) {
      this.state = 1;
    } else {
      this.state = 0;
    }
    this.presentationToggleHandlerService.updateToggleState(this.state);
  }
}
