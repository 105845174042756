<a [class.btn]="!displayTitles" [class.btn-secondary]="!displayTitles" [class.nav-link]="displayTitles" #bkanchor title="Bookmarks: Links to access other Abbott Systems.">
    <fa-icon [icon]="faBookmark"></fa-icon><span *ngIf="displayTitles"> Global Service/Vendor Links</span>
</a>
<kendo-popup #bkpopup [anchor]="bkanchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" [collision]="collision" (anchorViewportLeave)="bkMenuViable = false" *ngIf="bkMenuViable">
    <div class="content" *ngIf="apiState === RequestState.Complete && data.length > 0">
        <a *ngFor="let item of data" class="bookmark" [href]="item.hyperlink" target="_blank">
            <div class="bookmark-label">{{item.displayname}}</div>
            <div class="bookmark-link">{{item.hyperlink}}</div>
        </a>
    </div>
</kendo-popup>