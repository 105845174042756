<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
            <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                <div class="subtitle">{{apiData.count}} Unique Assays</div>
                <div class="content_border" *ngIf="apiData.assays">
                    <div *ngFor="let assayName of apiData.assays">
                        <!-- <div class="{{dataVal.visualization}}" style="color: {{dataVal.color}}">{{dataVal.displayvalue}}</div> -->
                        <div class="billboard">{{assayName}}</div>
                    </div>
                </div>
            </a>
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
            No data found
        </div>
    </div>
</app-state-handler>