<div style="margin-top:-70px;">
    <button [routerLink]="'/businessReview'">KPI Home</button>
    <button *ngIf="this.returnLink !== undefined" [routerLink]="this.returnLink.url" [queryParams]="this.returnLink.params">Return</button>
    <div style="width: 100%;">
        <app-filter-presentation-header></app-filter-presentation-header>
    </div>
    <app-br-tile-insert [tileReference]="tileReference"></app-br-tile-insert>
<a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []"
   [queryParams]="getQueryParams()">Drill Down</a>
    <div style="width: 100%; text-align: center;">
        <app-filter-presentation-footer></app-filter-presentation-footer>
    </div>
</div>