<h3>Build Template of KPI Selections</h3>
<kendo-textbox
        [style.width.px]="350"
        placeholder="Template Name"
        [clearButton]="false"
        (valueChange)="nameChange($event)"
>
</kendo-textbox>&nbsp;
<div class="btn-group">
    <button class="btn btn-secondary" title="Save" (click)="saveAs()">
        Save
    </button>
    <button class="btn btn-secondary" title="Cancel" (click)="cancel()">
        Cancel
    </button>&nbsp;
    <button (click)="setRecommended()">Auto Select Only Recommended KPI's</button>
</div>
<br/>
<p class="note">Note: recommended items are denoted with an astrix.</p>
<table *ngIf="stdKpiListData !== null">
    <tr *ngFor="let item of newListOrder; let i = index" [ngClass]="item.active?'itemActive':'itemInActive'">
        <td [class.is-recommended]="isRecommended(item)">{{item.metric_name}} <span *ngIf="isRecommended(item)">*</span></td>
        <td>
            <span class="action-btn" (click)="moveUp(i)" *ngIf="i > 0"><fa-icon [icon]="faUp"></fa-icon></span>
        </td>
        <td>
            <span class="action-btn" (click)="moveDown(i)" *ngIf="i < newListOrder.length - 1"><fa-icon [icon]="faDown"></fa-icon></span>
        </td>
        <td>
            <span class="action-btn inactive-btn" (click)="switchActive(i)" *ngIf="!item.active">Inactive</span>
            <span class="action-btn active-btn" (click)="switchActive(i)" *ngIf="item.active">Active</span>
        </td>
    </tr>
</table>
