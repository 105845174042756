import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { RequestState } from 'src/app/api/request-handler';
import { ApiService } from 'src/app/api/api.service';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';
import { GridComponent } from 'src/app/details/grid.component';

@Component({
  selector: 'app-alerts-grid',
  templateUrl: './alerts-grid.component.html',
  styleUrls: ['./alerts-grid.component.scss']
})
export class AlertsGridComponent extends GridComponent implements OnInit {

  dataRecord;
  dialog_title;
  opened = false;
  apolloAlertCount = 0;
  davinciAlertCount = 0;
  pomAlertCount = 0;

  config = {
    pageTitle: 'Predictive System Alerts',
    apiRequestHandler: '/predictive_system_alerts',
    table: {
      exportFileName: 'predictive_system_alerts.xlsx',
      dataCols: [
        {
            title: 'Alert Type',
            key: 'alerttype',
            width: 80,
            renderAlertTypeStyle: true
        },
        {
          title: 'Details',
          width: 60,
          renderDetailsStyle: true
        },
        {
            title: 'Customer',
            key: 'customer',
            width: 80
        },
        {
          title: 'SN',
          key: 'sn',
          width: 80
        },
        {
            title: 'Instrument',
            key: 'devicename',
            width: 65
        },
        {
            title: 'Alert Code',
            key: 'alertcode',
            width: 60
        },
        {
            title: 'Alert Desc',
            key: 'alertdesc',
            width: 80
        },
        {
            title: 'Message Date/Time',
            key: 'msgdatetime',
            width: 60
        },
        {
          title: 'Experience Code',
          key: 'experiencecode',
          width: 60,
          hidden: true
        },
        {
          title: 'Experience Code Desc',
          key: 'experiencecodedescription',
          width: 80,
          hidden: true
        },
        {
          title: 'Country',
          key: 'country',
          width: 50,
          hidden: true
        },
        {
          title: 'Message Detail',
          key: 'msg',
          width: 80,
          hidden: true
        }
      ]
    }  
  };

  public state = RequestState.Inactive;

  constructor(
    protected location: Location,
    protected apiService: ApiService,
    protected refreshHandler: RefreshHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadData();    
  }

  protected loadData() {
    const requestHandler = this.apiService.get(this.config.apiRequestHandler);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(this.config.apiRequestHandler, { requestTime: (new Date()).getTime() });
    this.refreshHandler.onRefreshEvent(
      (index) => {
        requestHandler.sendRequest(this.config.apiRequestHandler, { requestTime: (new Date()).getTime() });
      },
      requestHandler.getRelativeParams(this.config.apiRequestHandler)
    );

    // no need to send request here as chart tile has already done so
    requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // FORMAT API DATA FOR TABLE
        // format dataCols for kendo component
        const dataColsConfig = this.config.table.dataCols;
        this.dataCols = [];
        for (let i = 0; i < this.config.table.dataCols.length; i++) {
          this.dataCols.push({
            ref: this.config.table.dataCols[i].key,
            description: this.config.table.dataCols[i].title,
            width: this.config.table.dataCols[i].width,
            hidden: this.config.table.dataCols[i].hidden,
            renderAlertTypeStyle: this.config.table.dataCols[i].renderAlertTypeStyle,
            renderDetailsStyle: this.config.table.dataCols[i].renderDetailsStyle
          });
        }
        // format dataRows for kendo component
        this.dataRows = [];
        this.apolloAlertCount = 0;
        this.davinciAlertCount = 0;
        this.pomAlertCount = 0;
        for (let r = 0; r < apiData['data'].length; r++) {
          let dataRecord = apiData['data'][r];
          if (dataRecord.alerttype === 'Apollo') {
            this.apolloAlertCount++;
          } else if (dataRecord.alerttype === 'DaVinci') {
            this.davinciAlertCount++;
          } else if (dataRecord.alerttype === 'POM') {
            this.pomAlertCount++;
          }
          this.dataRows.push(apiData['data'][r]);
        }            
        this.updateGrid();
      }
    });
  }

  public getMaxHeight(): string {    
    const userWindowHeight = window.screen.availHeight;
    const userWindowWidth = window.screen.availWidth;

    if (userWindowHeight < 300) {
      return 300 + 'px';
    } else if (userWindowWidth <= 768) {
      return (userWindowHeight - 100) + 'px';
    } else if (this.detectMob()) {
      return (userWindowHeight - 100) + 'px';
    }
    return (userWindowHeight - 180) + 'px';
  }

  getAlertTypeStyle(alertType) {
    if (alertType === 'Apollo') {
      return {
        'color': '#009CDE',
        'font-size': '10pt',
        'font-weight': '500'
      }
    } else if (alertType === 'DaVinci') {
      return {
        color: '#A1CD5D',
        'font-size': '10pt',
        'font-weight': '500'
      }
    } else if (alertType === 'POM') {
      return {
        color: '#FA9F53',
        'font-size': '10pt',
        'font-weight': '500'
      }
    } else {
      return {
        color: '#000',
        'font-size': '10pt',
        'font-weight': '500'
      }
    }
  }

  getHRStyle(alertType) {
    if (alertType === 'Apollo') {
      return 'hr_alert_apollo';
    } else if (alertType === 'DaVinci') {
      return 'hr_alert_davinci';
    } else if (alertType === 'POM') {
      return 'hr_alert_pom';
    } 
  }

  getDateLabel(alertType) {
    if (alertType === 'Apollo') {
      return 'PHM Date';
    } else if (alertType === 'DaVinci') {
      return 'Date';
    } 
  }

  openDetailsWindow(dataItem: any) {
    // console.log(dataItem);
    if (dataItem && dataItem.alerttype == 'Apollo') {
      this.dialog_title = 'Apollo/Prognostic Health Monitoring (PHM) - Alert Details';    
      this.dataRecord = dataItem;
      let customerAddr = this.dataRecord['customeraddress'];
      // customeraddress: "1527 MADISON ST@FREDONIA@"
      if (customerAddr !== undefined && customerAddr.length > 0) {
        let streetIndex = customerAddr.indexOf('@');
        if (streetIndex != -1) {                
          this.dataRecord['customerAddrStreet'] = customerAddr.substring(0, streetIndex); 
          if (customerAddr.indexOf('@', streetIndex) != -1 && customerAddr.indexOf('@', streetIndex + 1) != -1) {
            this.dataRecord['customerAddrCity'] = customerAddr.substring(customerAddr.indexOf('@', streetIndex) + 1, customerAddr.indexOf('@', streetIndex + 1));
          }            
        }
      }            
      this.opened = true;  
    } else if (dataItem && dataItem.alerttype == 'DaVinci') {
      this.dialog_title = 'DaVinci Alert Details';    
      this.dataRecord = dataItem;
      this.dataRecord['customerAddrStreet'] = this.dataRecord['customeraddress'];
      this.opened = true;  
    } else if (dataItem && dataItem.alerttype == 'POM') {
      this.dialog_title = 'POM Alert Details';    
      this.dataRecord = dataItem;
      this.opened = true;
    }  
  }

  closeDetailsWindow() {
    this.opened = false;
  }

  isDataFound(dataRows) {
    if (!dataRows) {
      return false;
    }
    if (Array.isArray(dataRows) && dataRows.length === 0) {
      return false;
    }
    return true;
  }

}
