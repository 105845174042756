import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {GridComponent} from '../grid.component';
import {RefreshHandlerService} from '../../api/refresh-handler.service';


@Component({
  selector: 'app-uptime-customer-details',
  templateUrl: './uptime-customer-details.component.html',
  styleUrls: ['./uptime-customer-details.component.scss']
})

export class UptimeCustomerDetailsComponent extends GridComponent implements OnInit {

  stylePages = {
    '/uptime/details/customerdetails': {
        pageTitle: 'Uptime Details - ',
        apiRequestHandler: '/metrics/uptime/customerdetails',
        backPageLabel: 'Uptime Details',
        backPageLink: '/uptime/details',
        dataArrayKey: 'instruments',
        table: {
            needEntireApiDataRecord: true,
            exportFileName: 'uptime_customer_details.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'cust',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'instrumentname',
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'instrumentsrno',
                    width: 80
                },
                {
                    title: 'Hours Down',
                    key: 'hrsdown',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                },
                {
                    title: 'Days Down',
                    key: 'daysdown',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                },
                {
                    title: 'Uptime %',
                    key: 'uptimeper',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                }
            ]
        }
    }
  };
  
  config = null;
  public state = RequestState.Inactive;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    private refreshHandler: RefreshHandlerService
  ) { 
    super();
  }

  ngOnInit(): void {
    
    let customerid;
    let customername;
    let country;

    this.route.paramMap.subscribe(params => {
      customerid = params.get('customerid');
      customername = params.get('customername');
      country = params.get('country');
    });      
  
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    let titleText = this.config.pageTitle + customername;
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: titleText,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, customerdetail_id: customerid});
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, customerdetail_id: customerid});
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            this.dataCols.push({
              ref: this.config.table.dataCols[i].key,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              class: this.config.table.dataCols[i].class,
              hidden: this.config.table.dataCols[i].hidden,
              customStyle: this.config.table.dataCols[i].customStyle
            });
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            let dataRow = {};
            for (let i = 0; i < this.config.table.dataCols.length; i++) {
              dataRow['cust'] = customername;
              dataRow['country'] = country;
              dataRow[this.config.table.dataCols[i].key] = apiData[this.config.dataArrayKey][r][this.config.table.dataCols[i].key];
            }
            for (const rowCol in dataRow) {
              let value = dataRow[rowCol];
              if (rowCol === 'hrsdown' || rowCol === 'daysdown') {
                if (value === '' || value === null) { // || value == 0 || value == 0.0 || string of 0) {
                  value = null;
                }
              }
              dataRow[rowCol] = value;
            }
            this.dataRows.push(dataRow);
          }
          this.updateGrid();
        }
      });
    }
  }

}
