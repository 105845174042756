export const PersonaLevels = {
  level1Service: {
    apiCalls: [
      { mappingKey: '/users/manager' },
      { mappingKey: '/users/tss' },
      { mappingKey: '/geography_service' }
    ]
  },
  level2ServiceCustomers: {
    apiCalls: [
      { mappingKey: '/customer/details' }
    ]
  },
  level2ServiceInstruments: {
    apiCalls: [
      { mappingKey: '/productline/details' },
      { mappingKey: '/productline/sn' }
    ]
  },
  level1Amb: {
    apiCalls: [
      { mappingKey: '/users/amb_tss' },
      { mappingKey: '/geography_amb' },
      { mappingKey: '/search/accountdetails' }
    ]
  }
};