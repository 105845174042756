import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';

@Component({
  selector: 'app-account-rep',
  templateUrl: './account-rep.component.html',
  styleUrls: ['./account-rep.component.scss']
})
export class AccountRepComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  selectedItem;
  requestHandler;

  constructor(
    private apiService: ApiService,
    private personaService: PersonaService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('cua/account_rep_list');
    this.requestHandler.sendRequest('cua/account_rep_list');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        const buildData = [];
        for (const user of apiData) {
          buildData.push({
            label: user.firstName + ' ' + user.lastName + ' (' + user.acctRep511 + ')',
            key: user.acctRepUpi
          });
        }
        this.data = buildData;
      }
    });
  }

  ngOnDestroy(): void {
    if (this.requestHandler !== undefined) {
      this.requestHandler.cancelSubscription();
    }
  }

  getDataItemByKeyField(keyField) {
    for (const item of this.data) {
      if (keyField === item.key) {
        return item;
      }
    }
    return null;
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    const dataItem = this.getDataItemByKeyField(selectedItem);
    this.personaService.setPersonaOption(PersonaTypes.AccountRep, dataItem.key, dataItem.label);
  }
}
