import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StackRequestService {
  stacks = {};

  constructor() { }

  getStackKey(params, requestUniqueParams) {
    const paramsCopy = Object.assign({}, params);
    for (const exemptParam of requestUniqueParams) {
      delete paramsCopy[exemptParam];
    }
    return JSON.stringify(paramsCopy);
  }

  getRequestUniqueParams(params, requestUniqueParams) {
    const ruParams = {};
    for (const item of requestUniqueParams) {
      if (item in params) {
        ruParams[item] = params[item];
      }
    }
    return ruParams;
  }

  addToStack(routeY, requestUniqueParamsY, stackKey) {
    if (!(stackKey in this.stacks)) {
      this.stacks[stackKey] = [];
    }
    this.stacks[stackKey].push({
      route: routeY,
      requestUniqueParams: requestUniqueParamsY
    });
  }

  getStack(stackKey) {
    return this.stacks[stackKey];
  }
}
