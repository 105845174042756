<div class="filter-section">
    <div class="filter-header">Customers</div>
    <app-autoselect-list
            [state]="apiState"
            [data]="data"
            [keyField]="'key'"
            [displayField]="'label'"
            [selectedOption]="selectedItem"
            (itemSelect)="setSelectedItem($event)">
    </app-autoselect-list>
</div>

<!--<div class="input-group mb-2 mr-sm-2" style="padding: 7px 13px;background-color: #bfbfbf;">-->
    <!--<div class="input-group-prepend">-->
        <!--<div class="input-group-text" style="background: #ffffff;"><fa-icon [icon]="faSearch"></fa-icon></div>-->
    <!--</div>-->
    <!--<input type="text" class="form-control" style="border-left: none;">-->
<!--</div>-->
<!--<div class="filter-item">CLINICAL LABORATORY PARTNERS (0056621376)</div>-->
<!--<div class="filter-item">DEPT VA MED CTR RCVG 1 (0050142652)</div>-->
<!--<div class="filter-item">DIABETES & ENDOCRINOLOGY ASSOCIATES (0056636319)</div>-->
<!--<div class="filter-item">HARTFORD HOSPITAL (0050018886)</div>-->
<!--<div class="filter-item">JACOBI MEDICAL CENTER (0050309491)</div>-->
<!--<div class="filter-item">KELLER ARMY HOSP MED SUPPLY (0050142215)</div>-->