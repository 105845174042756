import { Component, OnInit, OnDestroy } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {
  faICursor,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import {RequestState} from '../../api/request-handler';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ApiService} from '../../api/api.service';
import {BrTemplateSelectionService} from '../br-template-selection.service';
import {BrParamsService} from '../br-params.service';

@Component({
  selector: 'app-br-template-selector',
  templateUrl: './br-template-selector.component.html',
  styleUrls: ['./br-template-selector.component.scss']
})
export class BrTemplateSelectorComponent implements OnInit, OnDestroy {
  faICursor = faICursor;
  faPlus = faPlus;

  operation = 1; // 1: select, 2: edit, 3: add

  public userTemplatesData = [
    {
      key: 'br_kpi',
      title: 'Default Template',
      template_string: '',
      icon: '',
      tooltip: ''
    }
  ];
  protected requestHandler2;
  protected stdKpiListSource = '/qbr/template/std/std_kpi_list';
  protected stdKpiData;
  protected requestHandler;
  protected listUserTemplatesSource = '/qbr/template/custom/list_user_templates';
  protected refreshHandleKey;
  public state = RequestState.Inactive;

  selectedOption = 'br_kpi';
  selectedTemplateString = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
    private brTemplateSelectionService: BrTemplateSelectionService,
    private brParamsService: BrParamsService
  ) {}


  ngOnInit(): void {
    this.requestHandler2 = this.callApiSource(this.stdKpiListSource, 0);
    this.requestHandler2.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler2.getResponse();
        const apiData = JSON.parse(response.body);
        this.stdKpiData = apiData;
      }
    });
    this.requestHandler = this.callApiSource(this.listUserTemplatesSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.userTemplatesData = [];
        this.userTemplatesData.push({
          key: 'br_kpi',
          title: 'Default Template',
          template_string: '',
          icon: '',
          tooltip: ''
        });
        if (apiData.hasOwnProperty('items')) {
          for (const item of apiData.items) {
            this.userTemplatesData.push({
              key: item.template_id,
              title: item.template_name,
              template_string: item.template_string,
              icon: '',
              tooltip: ''
            });
          }
        }
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.listUserTemplatesSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.listUserTemplatesSource));
    let persistedId = this.brParamsService.get('br_selected_template_id');
    let persistedName = this.brParamsService.get('br_selected_template_name');
    let persistedString = this.brParamsService.get('br_selected_template_string');
    let persistedValue = {id: persistedId, name: persistedName, string: persistedString};
    if (persistedId !== null) {
      this.brTemplateSelectionService.updateSelectedTemplate(persistedId, persistedName, persistedString);
    } else {
      persistedValue = this.brTemplateSelectionService.getSelectedTemplate();
    }
    this.selectedOption = persistedValue.id;
    this.selectedTemplateString = persistedValue.string;

    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'br_selected_template_id') {
        persistedId = this.brParamsService.get('br_selected_template_id');
        persistedName = this.brParamsService.get('br_selected_template_name');
        persistedString = this.brParamsService.get('br_selected_template_string');
        persistedValue = {id: persistedId, name: persistedName, string: persistedString};
        this.selectedOption = persistedValue.id;
        this.selectedTemplateString = persistedValue.string;
      }
    });
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
    this.requestHandler2.cancelSubscription();
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  getToolOption(key) {
    for (const option of this.userTemplatesData) {
      if (option.key === key) {
        return option;
      }
    }
    return null;
  }

  getOrderFromStdKpiList() {
    const order = [];
    for (const item of this.stdKpiData.items) {
      order.push(item.id);
    }
    return order.join(',');
  }

  valueChange(value) {
    const toolOption = this.getToolOption(value);
    this.selectedOption = toolOption.key;
    if (toolOption.key === 'br_kpi') {
      this.selectedTemplateString = this.getOrderFromStdKpiList();
    } else {
      this.selectedTemplateString = toolOption.template_string;
    }
    this.brTemplateSelectionService.updateSelectedTemplate(this.selectedOption, toolOption.title, this.selectedTemplateString);
    this.brParamsService.set('br_selected_template_name', toolOption.title);
    this.brParamsService.set('br_selected_template_string', this.selectedTemplateString);
    this.brParamsService.set('br_selected_template_id', this.selectedOption);
  }

  addLink() {
    this.operation = 3;
    // this.router.navigate(['brTemplateEditor'], {relativeTo: this.route});
  }

  editLink() {
    this.operation = 2;
    // this.router.navigate(['brTemplateEditor'], {relativeTo: this.route});
  }

  onSave() {
    this.operation = 1;
  }

  onCancel() {
    this.operation = 1;
  }
}
