<div class="search-container">
    <kendo-maskedtextbox class="search-box" (valueChange)="onValueChange($event)" [style.width]="'100%'"></kendo-maskedtextbox>
</div>
<div class="loading-message" *ngIf="state !== RequestState.Complete || data.length === 0">
    <app-state-handler-search [requestState]="state" [data]="data"></app-state-handler-search>
</div>
<div *ngIf="state === RequestState.Complete && data.length > 0">
    <div class="filter-results">
        <div class="list-group">
            <a class="list-group-item list-group-item-action"
               *ngFor="let item of data"
               [class.active]="(item[keyField]===selectedOption)"
               (click)="onListItemSelect(item[keyField])">
                {{item[displayField]}}
            </a>
        </div>
        <div *ngIf="totalRecordsFound>100" class="more-records-message">
            {{totalRecordsReturned}} account<span *ngIf="totalRecordsReturned>1">s</span> listed out of {{totalRecordsFound}}.
            <span *ngIf="totalRecordsReturned<totalRecordsFound"><br/>Please narrow your search criteria more to view additional results.</span>
        </div>
    </div>
</div>
