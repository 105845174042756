<app-state-handler [requestState]="state" [data]="gridData">
    <div *ngIf="isDataFound(dataRows) else noData">
        <kendo-grid
            [data]="gridData"
            [pageable]="true"
            [pageSize]="gridState.take"
            [skip]="gridState.skip"
            [filterable]="true"  
            [filter]="gridState.filter"
            [sort]="gridState.sort"
            [sortable]="true"
            [ngStyle]="{'max-height': getMaxHeight()}" 
            [resizable]="true"
            (pageChange)="pageChange($event)"
            (sortChange)="sortChange($event)"
            (filterChange)="filterChange($event)">            
            <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <kendo-grid-column-chooser allowHideAll='true' autoSync='true'></kendo-grid-column-chooser>
                <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
                <span *ngIf="apolloAlertCount > 0" class="k-button" style='background-color: #009CDE; color: #FFF'>Apollo - {{apolloAlertCount}}</span>
                <span *ngIf="davinciAlertCount > 0" class="k-button" style='background-color: #A1CD5D; color: #FFF'>DaVinci - {{davinciAlertCount}}</span>
                <span *ngIf="pomAlertCount > 0" class="k-button" style='background-color: #FA9F53; color: #FFF'>POM - {{pomAlertCount}}</span>
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                    [field]="col.ref"
                    [title]="col.description"
                    [width]="col.width"
                    [hidden]="col.hidden"
                    [headerClass]="'data-grid-header'">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                        <ng-container *ngIf="col.renderAlertTypeStyle === true; else two">
                            <span class="whole-cell" [style]="getAlertTypeStyle(dataItem[col.ref])">{{dataItem[col.ref]}}</span>
                        </ng-container>                    
                        <ng-template #two>
                            <ng-container *ngIf="col.renderDetailsStyle == true; else three">
                                <img height="25" width="25" (click)="openDetailsWindow(dataItem)"  src="./assets/icons/info_icon.png" />
                                <span *ngIf="dataItem['kmarticleurl']" style='padding-left: 10px'>
                                    <a href="{{dataItem['kmarticleurl']}}" target="blank">
                                        <img src="./assets/kmarticle_icon.png" width="25" height="25" />   
                                    </a>
                                </span>
                                <span *ngIf="dataItem['qlikviewdatachart']" style='padding-left: 10px'>
                                    <a href="{{dataItem['qlikviewdatachart']}}" target="blank">
                                        <img src="./assets/QV_40x40.png" width="25" height="25" />   
                                    </a>
                                </span> 
                                <span *ngIf="dataItem['ihnurl']" style='padding-left: 10px'>
                                    <a href="{{dataItem['ihnurl']}}" target="blank">
                                        <img src="./assets/ihr.gif" width="25" height="25" />   
                                    </a>
                                </span>
                            </ng-container>                        
                        </ng-template>
                        <ng-template #three>
                            <!-- <span class="whole-cell">{{dataItem[col.ref] | emptyMask}}</span> -->
                            <span class="whole-cell">{{dataItem[col.ref]}}</span>
                        </ng-template>
                    </ng-template>                        
                </kendo-grid-column>
            </ng-container>
        </kendo-grid>
        <kendo-dialog *ngIf="opened" (close)="closeDetailsWindow()" [minWidth]="400" [width]="600">        
            <kendo-dialog-titlebar style='border-color: #fff; font-size: 16pt; font-weight: 500; line-height: 1.5em; text-align: center'>
                {{dialog_title}}
            </kendo-dialog-titlebar>  
            <hr [ngClass]='getHRStyle(dataRecord.alerttype)' />
            <ng-container *ngIf="dataRecord.alerttype == 'Apollo' || dataRecord.alerttype == 'DaVinci'">
                <div style='width: 100%; padding: 5px; margin: auto'>
                    <div style='width: 60%; font-size: 12pt; font-weight: 500; float: left'>
                        {{dataRecord.devicename}}
                    </div>
                    <div style='width: 40%; text-align: right; font-weight: 500; float: right'>
                        SN: {{dataRecord.sn}}
                    </div>
                </div>
                <div style='color: #009CDE; padding: 5px; font-weight: 500; font-size: 14pt'>
                    {{dataRecord.customer}}
                </div>
                <hr style='height: 2px; border-width: 0; margin-top: 2px !important; margin-bottom: 2px !important; color: gray; background-color: gray;' />
                <div *ngIf="dataRecord.alerttype == 'Apollo'" style='padding: 5px; color: blue'>
                    {{dataRecord.alertcode}} - {{dataRecord.alertdesc}}
                </div>
                <div *ngIf="dataRecord.experiencecode" style='padding: 5px'>
                    <span>{{dataRecord.experiencecode}} - {{dataRecord.experiencecodedescription}}</span>
                </div>
                <div style='padding: 5px'>
                    <span *ngIf="dataRecord.kmarticleurl" style='padding-right: 20px'>
                        <a href="{{dataRecord.kmarticleurl}}" target="blank">
                            <img src="./assets/kmarticle_icon.png" width="40" height="40" />   
                        </a>
                    </span>
                    <span *ngIf="dataRecord.qlikviewdatachart" style='padding-right: 20px'>
                        <a href="{{dataRecord.qlikviewdatachart}}" target="blank">
                            <img src="./assets/QV_40x40.png" width="40" height="40" />   
                        </a>
                    </span> 
                    <span *ngIf="dataRecord.ihnurl" style='padding-right: 20px'>
                        <a href="{{dataRecord.ihnurl}}" target="blank">
                            <img src="./assets/ihr.gif" width="40" height="40" />   
                        </a>
                    </span>               
                </div>
                <div style='width: 100%; padding: 5px; margin: auto'>
                    <div style='width: 40%; padding: 5px; float: left'>
                        <span *ngIf="dataRecord.alerttype == 'Apollo'">{{dataRecord.noofalertsxdays}}</span>
                    </div>
                    <div style='width: 60%; text-align: right; float: right'>
                        {{getDateLabel(dataRecord.alerttype)}} {{dataRecord.msgdatetime}}
                    </div>
                </div>
                <div style='clear: both; height: 5px'></div>
                <div style='width: 100%; padding: 5px; margin: auto'>
                    <div style='width: 40%; font-size: 12pt; font-weight: 500; float: left'>
                        <span *ngIf="dataRecord.ticketcreated == 'N'">Ticket is not created</span>
                        <span *ngIf="dataRecord.ticketcreated == 'Y'">Ticket is created</span>
                    </div>
                    <div style='width: 60%; text-align: right; float: right'>
                        <span *ngIf="dataRecord.alerttype == 'Apollo'">Remaining Useful Life (RUL) {{dataRecord.rul}}</span>                
                    </div>
                </div>
                <div style='clear: both; height: 5px'></div>
                <hr style='height: 2px; border-width: 0; margin-top: 2px !important; margin-bottom: 2px !important; color: gray; background-color: gray;' />
                <div style='color: #009CDE; padding: 5px; font-weight: 500; font-size: 14pt'>
                    {{dataRecord.customer}}
                </div>
                <div>
                    {{dataRecord.customerAddrStreet}}
                </div>
                <div>
                    {{dataRecord.customerAddrCity}}
                </div>
                <div>
                    {{dataRecord.country}}
                </div>
                <div style='clear: both; height: 5px'></div>
                <div style='width: 100%; padding: 5px; border: 1px; background-color: lightgray; font-size: 16pt; font-weight: 500;'>
                    {{dataRecord.msg}}    
                </div>
            </ng-container>
            <ng-container *ngIf="dataRecord.alerttype == 'POM'">
                <div style='padding: 5px; font-weight: 500; font-size: 14pt'>{{dataRecord.devicename}}</div>
                <div style='color: #009CDE; padding: 5px; font-weight: 500; font-size: 14pt'>
                    {{dataRecord.customer}}
                </div>
                <hr style='height: 2px; border-width: 0; margin-top: 2px !important; margin-bottom: 2px !important; color: gray; background-color: gray;' />
                <table class='pom_alert_table'>
                    <tr>
                        <td>Contact No:</td>
                        <td>{{dataRecord.customercontactno}}</td>
                    </tr>
                    <tr>
                        <td>Instrument:</td>
                        <td>{{dataRecord.modulename}}, {{dataRecord.sn}}, {{dataRecord.moduletype}}</td>
                    </tr>
                    <tr>
                        <td>Occurence Count:</td>
                        <td>{{dataRecord.occurrencecount}}</td>
                    </tr>
                    <tr>
                        <td>Event:</td>
                        <td>{{dataRecord.alertcode}}</td>
                    </tr>
                    <tr>
                        <td>Date:</td>
                        <td>{{dataRecord.msgdatetime}}</td>
                    </tr>
                </table>
                <div style='clear: both; height: 5px'></div>
                <hr style='height: 2px; border-width: 0; margin-top: 2px !important; margin-bottom: 2px !important; color: gray; background-color: gray;' />
                <div style='clear: both; height: 5px'></div>
                <div>Message:</div>
                <div style='clear: both; height: 5px'></div>
                <div style='width: 100%; padding: 5px; border: 1px; background-color: lightgray; font-size: 16pt; font-weight: 500;'>
                    {{dataRecord.msg}}    
                </div>
            </ng-container>
            <kendo-dialog-actions>
                <button kendoButton (click)="closeDetailsWindow()" primary="true" style='background-color: #009CDE !important'>Done</button>
            </kendo-dialog-actions>
        </kendo-dialog>
    </div>
    <ng-template #noData>
        <div *ngIf="!isDataFound(dataRows)" class="no-data">
            No Predictive System alerts found for the logged-in user!
        </div>    
    </ng-template>
</app-state-handler>

