<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
  <div style="text-align: center; display: flex; justify-content: center; align-items: center;">
    <table style="width:55%;">
      <tr><td colspan="2" style="text-align:center;"><h3>{{title}}</h3></td></tr>
      <tr><td style="text-align: center; font-size: 60pt; color: {{strColor}};">
        <a class="link" [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          {{strScore}}
        </a>
      </td></tr>
      <tr><td style="text-align: center; font-size: 12pt; color: black;">
        <a class="link" [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          <b>{{strSubTitle}}</b>
        </a>
      </td></tr>
    </table>
  </div>
</app-state-handler>
