export const Style2Pages = {
    '/opencalls': {
        pageTitle: 'Assigned Open Calls Details',
        apiRequestHandler: '/metrics/opencalls/details',
        backPageLabel: 'Back',
        backPageLink: '/lastSelectedDashboard',
        dataArrayKey: 'tickets',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'assigned_open_call_details.xlsx',
            dataCols: [
                {
                    title: 'Ticket No',
                    key: 'number',
                    refLink: 'numberlink',
                    width: 100
                },
                {
                    title: 'Ticket Owner',
                    key: 'ticketowner',
                    width: 60
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 60
                },
                {
                    title: 'SN',
                    key: 'instrumentsrno',
                    width: 80
                },
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Ticket Comment',
                    key: 'comment',
                    width: 60
                },
                {
                    title: 'Open Date',
                    key: 'opendate',
                    customDateFormat: { inputFormat: 'yyyyMMdd', outputFormat: 'yyyy-MM-dd' },
                    width: 50
                },
                {
                    title: 'Aged',
                    key: 'aged',
                    renderAgedYNStyle: true,
                    width: 50
                },
                {
                    title: 'Aging Days',
                    key: 'agingdays',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Ticket Category',
                    key: 'type',
                    width: 50
                },
                {
                    title: 'Exp Code',
                    key: 'expcode',
                    width: 50
                },
                {
                    title: 'Exp Code Description',
                    key: 'expcodedesc',
                    width: 80
                }
            ]
        }
    },
    '/hotsheets': {
        pageTitle: 'NPS Open Hotsheet Details',
        apiRequestHandler: '/metrics/hotsheets/details',
        backPageLabel: 'Back',
        backPageLink: '/',
        dataArrayKey: 'nps',
        dataArraySplitColKey: 'scores',
        table: {
            type: 'style2',
            exportFileName: 'nps_open_hotsheet_details.xlsx',
            dataCols: [
                {
                    title: 'NPS Category',
                    key: 'resolved',
                    width: 80
                },
                {
                    title: 'Customer Number',
                    key: 'customernumber',
                    hidden: true,
                    export: true,
                    width: 50
                },
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80,
                    renderCustomerNameNumStyle: true
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Interview Date',
                    key: 'interviewdate',
                    width: 50
                }
            ],
            dataArraySplitCols: [
                {
                    title: 'Overall',  // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'overall',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Field Service', // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'fieldservice',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Product Quality',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'productquality',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Product Delivery',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'productdelivery',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Sales Rep',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'salesrep',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Technical Support',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'technicalsupport',
                    width: 50,
                    type: 'int'
                }
            ],
            drillDownCols: [
                {
                    title: 'Survey Comments',
                    width: 80,
                    drillDownDetailsColName: 'hotsheets_survey_comments',
                    drillDownDetailsLabel: 'More',
                    drillDownDetailsLink: 'survey_comments'
                }
            ]
        }
    },
    '/sdfr/details': {
        pageTitle: 'SDFR Details - Service Demand Tickets Only',
        apiRequestHandler: '/metrics/sdfr/details',
        backPageLabel: 'Back',
        backPageLink: '/sdfr',
        dataArrayKey: 'details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'sdfr_details_service_demand_tkts_only.xlsx',
            dataCols: [
                {
                    title: 'Ticket Number',
                    key: 'ticketnumber',
                    refLink: 'ticketnumberlink',
                    width: 100
                },
                {
                    title: 'Serial Number',
                    key: 'serialno',
                    width: 80
                },
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Open Date',
                    key: 'opendate',
                    width: 50
                },
                {
                    title: 'Closed Date',
                    key: 'closeddate',
                    width: 50
                },
                {
                    title: 'Elapsed Hours',
                    key: 'elapsedhours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Hit/Miss',
                    key: 'hit',
                    width: 50,
                    renderHitMissStyle: true
                }
            ]
        }
    },
    '/ftfr/details': {
        pageTitle: 'FTFR Details - Only Graded Tickets Shown',
        apiRequestHandler: '/metrics/ftfr/details',
        backPageLabel: 'Back',
        backPageLink: '/ftfr',
        dataArrayKey: 'details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'ftfr_details_graded_tkts_only.xlsx',
            dataCols: [
                {
                    title: 'Ticket #1',
                    key: 'ticket1',
                    refLink: 'ticketlink',
                    width: 80
                },
                {
                    title: 'Serial Number',
                    key: 'ticksrno',
                    width: 80
                },
                {
                    title: 'T1 Closed Date',
                    key: 't1closeddate',
                    width: 50
                },
                {
                    title: 'Ticket #2',
                    key: 'ticket2',
                    refLink: 'ticketlink',
                    width: 80
                },
                {
                    title: 'T2 Opened Date',
                    key: 'ticket2opendate',
                    width: 50
                },
                {
                    title: 'Elapsed Days',
                    key: 'elapseddate',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Hit/Miss',
                    key: 'hitmiss',
                    width: 50,
                    renderHitMissStyle: true
                }
            ]
        }
    },
    '/uptime/details': {
        pageTitle: 'Uptime Details',
        apiRequestHandler: '/metrics/uptime/details',
        backPageLabel: 'Back',
        backPageLink: '/uptime',
        dataArrayKey: 'details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'uptime_details.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'cust',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Total Hours Down',
                    key: 'aggdhrsdown',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Total Days Down',
                    key: 'aggdaysdown',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Uptime %',
                    key: 'agguptimeper',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ],
            drillDownCols: [
                {
                    title: 'Details',
                    width: 80,
                    drillDownDetailsColName: 'uptime_customer_details',
                    drillDownDetailsLabel: '>',
                    drillDownDetailsLink: 'customerdetails'
                }
            ]
        }
    },
    '/ticketrending/details': {
        pageTitle: 'Ticket Trending Details',
        apiRequestHandler: '/metrics/ticketrending/details',
        backPageLabel: 'Back',
        backPageLink: '/ticketrending',
        dataArrayKey: 'tickets',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'ticket_trending_details.xlsx',
            dataCols: [
                {
                    title: 'Ticket No',
                    key: 'ticketdetails',
                    refLink: 'numberlink',
                    width: 80
                },
                {
                    title: 'Ticket Owner',
                    key: 'ticketowner',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'instrument',
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'instrumentsrno',
                    width: 80
                },
                {
                    title: 'Customer',
                    key: 'customer',
                    width: 80
                },
                {
                    title: 'Summary Desc',
                    key: 'summarydesc',
                    width: 80
                },
                {
                    title: 'Ticket Trending Name',
                    key: 'tickettrendingname',
                    width: 60
                },
                {
                    title: 'Open Date',
                    key: 'opendate',
                    width: 50
                },
                {
                    title: 'Closed Date',
                    key: 'closeddate',
                    width: 50
                },
                {
                    title: 'Experience Code',
                    key: 'expcode',
                    width: 50
                },
                {
                    title: 'Experience Code Desc',
                    key: 'expcodedesc',
                    width: 80
                }
            ]
        }
    },
    '/trt/details': {
        pageTitle: 'Total Resolution Time Details',
        apiRequestHandler: '/metrics/trt/details',
        backPageLabel: 'Back',
        backPageLink: '/trt',
        dataArrayKey: 'details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'total_resolultion_time_details.xlsx',
            dataCols: [
                {
                    title: 'Ticket Number',
                    key: 'ticketnumber',
                    refLink: 'ticketnumberlink',
                    width: 80
                },
                {
                    title: 'Resolution Time (Days)',
                    key: 'resolutiontime',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Start Date',
                    key: 'startdate',
                    width: 50
                },
                {
                    title: 'End Date',
                    key: 'enddate',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'pldesc',
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'sn',
                    width: 80
                },
                {
                    title: 'Customer',
                    key: 'cust',
                    width: 80
                },
                {
                    title: 'Experience Code and Desc',
                    key: 'expcodedesc',
                    width: 80
                },
                {
                    title: 'Ticket Comments',
                    key: 'ticketcomments',
                    width: 80
                }
            ]
        }
    },
    '/tsb': {
        pageTitle: 'Open TSB Details',
        apiRequestHandler: '/metrics/tsb/details',
        backPageLabel: 'Back',
        backPageLink: '/lastSelectedDashboard',
        dataArrayKey: 'tickets',
        table: {
            type: 'style2',
            exportFileName: 'open_tsb_details.xlsx',
            dataCols: [
                {
                    title: 'TSB Due Date',
                    key: 'tsbduedate',
                    width: 50,
                    dateFormat: 'yyyy-MM-dd',
                    filter: 'date'
                },
                {
                    title: 'Due in NNN days',
                    key: 'duein',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'TSB Required',
                    key: 'tsbrequired',
                    width: 50
                },
                {
                    title: 'TSB #',
                    key: 'tsbno',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                },
                {
                    title: 'TSB Desc',
                    key: 'tsbdesc',
                    width: 60
                },
                {
                    title: 'Instrument',
                    key: 'instrument',
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'sn',
                    width: 75
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Customer',
                    key: 'custname',
                    width: 80
                },
                {
                    title: 'Ticket Number',
                    key: 'ticketno',
                    customValueIfBlank: '',
                    width: 80
                },
                {
                    title: 'Ticket Owner',
                    key: 'ticketowner',
                    customValueIfBlank: '-',
                    width: 50
                },
                {
                    title: 'Primary TSS',
                    key: 'PrimaryTSS',
                    width: 50
                },
                {
                    title: 'Secondary TSS',
                    key: 'SecondaryTSS',
                    width: 50
                },
                {
                    title: 'Tertiary TSS',
                    key: 'TertiaryTSS',
                    width: 50
                }
            ]
        }
    },
    '/pm': {
        pageTitle: 'PM Status Details',
        apiRequestHandler: '/metrics/pm/details',
        backPageLabel: 'Back',
        backPageLink: '/lastSelectedDashboard',
        dataArrayKey: 'details',
        table: {
            type: 'style2',
            exportFileName: 'pm_status_details.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customer',
                    width: 80
                },
                {
                    title: 'Instrument',
                    key: 'instrument',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'srno',
                    width: 80
                },
                {
                    title: 'Last PM Date',
                    key: 'lastpm',
                    customValueIfBlank: '',
                    width: 50
                },
                {
                    title: 'Days Until Due',
                    key: 'daysdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Next PM Date',
                    key: 'nextpm',
                    customValueIfBlank: '',
                    width: 50
                }
            ]
        }
    },
    '/customerNps/details': {
        pageTitle: 'Customer NPS Survey Details',
        apiRequestHandler: '/metrics/nps/detail',
        backPageLabel: 'Back',
        backPageLink: '/customerNps',
        dataArrayKey: 'details',
        dataArraySplitColKey: 'scores',
        table: {
            type: 'style2',
            exportFileName: 'customer_nps_survey_details.xlsx',
            dataCols: [
                {
                    title: 'NPS Category',
                    key: 'resolved',
                    width: 60
                },
                {
                    title: 'Customer Number',
                    key: 'customernumber',
                    hidden: true,
                    export: true,
                    width: 50
                },
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80,
                    renderCustomerNameNumStyle: true
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Interview Date',
                    key: 'interviewdate',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                }
            ],
            dataArraySplitCols: [
                {
                    title: 'Overall',  // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    customStyle:  { 'text-align': 'right' },
                    // customValueIfBlank: 'N/A',
                    key: 'overall',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Field Service', // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    customStyle:  { 'text-align': 'right' },
                    // customValueIfBlank: 'N/A',
                    key: 'fieldservice',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Product Quality',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    customStyle:  { 'text-align': 'right' },
                    // customValueIfBlank: 'N/A',
                    key: 'productquality',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Product Delivery',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    customStyle:  { 'text-align': 'right' },
                    // customValueIfBlank: 'N/A',
                    key: 'productdelivery',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Sales Rep',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    customStyle:  { 'text-align': 'right' },
                    // customValueIfBlank: 'N/A',
                    key: 'salesrep',
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Technical Support',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    customStyle:  { 'text-align': 'right' },
                    // customValueIfBlank: 'N/A',
                    key: 'technicalsupport',
                    width: 50,
                    type: 'int'
                }
            ],
            drillDownCols: [
                {
                    title: 'Survey Comments',
                    width: 60,
                    drillDownDetailsColName: 'customer_nps_survey_comments',
                    drillDownDetailsLabel: 'More',
                    drillDownDetailsLink: 'survey_comments'
                }
            ]
        }
    },
    '/CRLSummary': {
        pageTitle: 'Current Instrument Quantity',
        apiRequestHandler: '/metrics/CRLSummary',
        dataArrayKey: 'values',
        table: {
            type: 'style2',
            exportFileName: 'current_instrument_quantity_details.xlsx',
            detailLink: '/CRLSummary/details',
            dataCols: [
                {
                    title: 'Instrument',
                    key: 'PL',
                    width: 100
                },
                {
                    title: 'Quantity',
                    key: 'Quantity',
                    customStyle:  { 'text-align': 'right' },
                    width: 100,
                  type: 'int'
                }
            ]
        }
    },
    '/proactive/details': {
        pageTitle: 'Proactive/Reactive Ticket Details',
        apiRequestHandler: '/metrics/proactive/details',
        backPageLabel: 'Back',
        backPageLink: '/proactive',
        dataArrayKey: 'details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'proactive_reactive_ticket_details.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Ticket Number',
                    key: 'ticketnumber',
                    refLink: 'ticketnumberlink',
                    width: 80
                },
                {
                    title: 'SN',
                    key: 'serialno',
                    width: 80
                },
                {
                    title: 'Open Date',
                    key: 'opendate',
                    width: 50
                },
                {
                    title: 'Closed Date',
                    key: 'closeddate',
                    width: 50
                },
                {
                    title: 'Experience Code Desc',
                    key: 'experiencecodedesc',
                    width: 80
                },
                {
                    title: 'Summary Desc',
                    key: 'summarydesc',
                    width: 80
                },
                {
                    title: 'Hit/Miss',
                    key: 'hit',
                    width: 80,
                    renderHitMissStyle: true
                }
            ]
        }
    },
    '/orders/intermediate': {
        pageTitle: 'Orders',
        apiRequestHandler: '/metrics/orders/intermediate',
        backPageLabel: 'Back',
        backPageLink: '/orders',
        dataArrayKey: 'orders',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'customer_orders.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customer',
                    width: 80
                },
                {
                    title: 'Order #',
                    key: 'ordernumber',
                    customStyle:  { 'text-align': 'right' },
                    width: 80
                },
                {
                    title: 'Order Type',
                    key: 'ordertype',
                    width: 80
                },
                {
                    title: 'Order Date',
                    key: 'orderdate',
                    dateFormat: 'yyyy-MM-dd',
                    width: 50
                },
                {
                    title: 'Order Status',
                    key: 'orderstatus',
                    width: 60
                },
                {
                    title: 'Recurs',
                    key: 'recurrence',
                    customValueIfBlank: '',
                    width: 60
                }
            ],
            drillDownCols: [
                {
                    title: 'Details',
                    width: 80,
                    drillDownDetailsColName: 'customer_order_details',
                    drillDownDetailsLabel: '>',
                    drillDownDetailsLink: '/orders/details'
                }
            ]
        }
    },
    '/alwaysonuptime/details': {
        pageTitle: 'ALWAYS ON Uptime Details',
        apiRequestHandler: '/metrics/alwaysonuptime/details',
        backPageLabel: 'Back',
        backPageLink: '/alwaysonuptime',
        dataArrayKey: 'instruments',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'always_on_uptime_details.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customer',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'countryDesc',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlineDescription',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'sn',
                    width: 80
                },
                {
                    title: 'Guarantee Achieved',
                    key: 'guaranteeAchieved',
                    width: 50
                },
                {
                    title: 'Uptime % 12 MA',
                    key: 'uptime12ma',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Uptime % 3 MA',
                    key: 'uptime3ma',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'AL 12 MA',
                    key: 'alInstalled',
                    width: 50
                },
                {
                    title: 'ALC % 12 MA',
                    key: 'alcpercent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'ALC 12 MA',
                    key: 'alc',
                    width: 50,
                    renderALC12MAStyle: true
                },
                {
                    title: 'Maint Score',
                    key: 'alcMaintainenceScore',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Service Contract LN Desc',
                    key: 'serviceContractLNDesc',
                    width: 50
                },
                {
                    title: 'Contract Start Date',
                    key: 'contractStartDate',
                    width: 50
                },
                {
                    title: 'Contract End Date',
                    key: 'contractEndDate',
                    width: 50
                }
            ]
        }
    },
    '/util_workload/details': {
        pageTitle: 'Utilization and Tickets Per Day Summary Previous 12 months',
        apiRequestHandler: '/metrics/fsrtpd/details',
        backPageLabel: 'Back',
        backPageLink: '/util_workload',
        dataArrayKey: 'Details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'utilization_workload_details.xlsx',
            dataCols: [
                {
                    title: 'YYYY-MM',
                    key: 'Period',
                    width: 60
                },
                {
                    title: 'Ticket Quantity',
                    key: 'TicketCount',
                    customStyle:  { 'text-align': 'right' },
                    customValueIfBlank: 'N/A',
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Tickets Per Day',
                    key: 'TicketsPerDay',
                    customStyle:  { 'text-align': 'right' },
                    customValueIfBlank: 'N/A',
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Labor Hours',
                    key: 'LaborHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Travel Hours',
                    key: 'TravelHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Total Hours',
                    key: 'TotalHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Available Hours',
                    key: 'AvailableHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Utilization % (1MA)',
                    key: 'Utilization',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ]
        }
    },
    '/tickets_per_day/details': {
        pageTitle: 'Utilization and Tickets Per Day Summary Previous 12 months',
        apiRequestHandler: '/metrics/fsrtpd/details',
        backPageLabel: 'Back',
        backPageLink: '/tickets_per_day',
        dataArrayKey: 'Details',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'utilization_workload_details.xlsx',
            dataCols: [
                {
                    title: 'YYYY-MM',
                    key: 'Period',
                    width: 60
                },
                {
                    title: 'Ticket Quantity',
                    key: 'TicketCount',
                    customStyle:  { 'text-align': 'right' },
                    customValueIfBlank: 'N/A',
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Tickets Per Day',
                    key: 'TicketsPerDay',
                    customStyle:  { 'text-align': 'right' },
                    customValueIfBlank: 'N/A',
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Labor Hours',
                    key: 'LaborHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Travel Hours',
                    key: 'TravelHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Total Hours',
                    key: 'TotalHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Available Hours',
                    key: 'AvailableHours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Utilization % (1MA)',
                    key: 'Utilization',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ]
        }
    },
    '/rnps/detail': {
        pageTitle: 'rNPS Surveys',
        apiRequestHandler: '/metrics/rnps/detail',
        backPageLabel: 'Back',
        backPageLink: '/rnps',
        dataArrayKey: 'rows',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'rnps_surveys_ambassador_details.xlsx',
            dataCols: [
                {
                    title: 'Overall Score',
                    key: 'value',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Customer Number',
                    key: 'customernum',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80,
                    renderCustomerStyleForAmbMetrics: true
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50,
                },
                {
                    title: 'Interview Date',
                    key: 'interviewdate',
                    width: 50
                },
                {
                    title: 'rNPS Category',
                    key: 'resolved',
                    width: 50
                }
            ],
            drillDownCols: [
                {
                    title: 'Survey Comments',
                    width: 80,
                    drillDownDetailsColName: 'rnps_survey_comments',
                    drillDownDetailsLabel: 'More',
                    drillDownDetailsLink: 'survey_comments'
                }
            ]
        }
    },
    '/amb_orders/intermediate': {
        pageTitle: 'Orders',
        apiRequestHandler: '/amb_orders/intermediate',
        backPageLabel: 'Back',
        backPageLink: '/amb_orders',
        dataArrayKey: 'orders',
        table: {
            type: 'style2',
            needEntireApiDataRecord: true,
            exportFileName: 'amb_customer_orders.xlsx',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customer',
                    width: 80
                },
                {
                    title: 'Order #',
                    key: 'ordernumber',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                },
                {
                    title: 'Order Type',
                    key: 'ordertype',
                    width: 80
                },
                {
                    title: 'Order Date',
                    key: 'orderdate',
                    dateFormat: 'yyyy-MM-dd',
                    width: 50
                },
                {
                    title: 'Order Status',
                    key: 'orderstatus',
                    width: 50
                },
                {
                    title: 'Recurs',
                    key: 'recurrence',
                    width: 50
                }
            ],
            drillDownCols: [
                {
                    title: 'Details',
                    width: 60,
                    drillDownDetailsColName: 'amb_customer_order_details',
                    drillDownDetailsLabel: '>',
                    drillDownDetailsLink: '/amb_orders/details'
                }
            ]
        }
    }
};
