import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PresentationToggleHandlerService {
  toggleChanged = new EventEmitter<number>();
  state = 0;

  constructor() { }

  getToggleChange() {
    return this.toggleChanged;
  }

  updateToggleState(newState) {
    this.state = newState;
    this.toggleChanged.emit(this.state);
  }
}
