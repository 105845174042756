import { Component, OnInit } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {TileComponent} from '../../base/tile.component';

@ComponentLookup('StackedBarChartByProductComponent')
@Component({
  selector: 'app-stacked-bar-chart-by-product',
  templateUrl: './stacked-bar-chart-by-product.component.html',
  styleUrls: ['./stacked-bar-chart-by-product.component.scss']
})
export class StackedBarChartByProductComponent extends TileComponent implements OnInit {
  protected defaultPreferences() {
    return {
      showTitle: true,
      valueAxis1Label: '{0}',
      valueAxis2Label: '{0}'
    };
  }
  translateApiDataForChart(apiData) {
    if (this.preferences.hasOwnProperty('apiObjectKey')) {
      apiData = apiData.range[this.preferences.apiObjectKey];
    }

    const chartStackSeries = {
      name: '',
      colorField: 'valueColor',
      type: 'column',
      gap: .5,
      border: {
        color: '#000',
        width: 1,
        dashType: 'solid'
      },
      data: [],
      axis: 'axis1'
    };
    const chartCategories = [];
    const valueAxis1Label = this.getPreference('valueAxis1Label');
    const valueAxis2Label = this.getPreference('valueAxis2Label');

    let values = apiData.values;
    if ('periodsToShow' in this.preferences) {
      // only use the last x periods in the chart
      values = values.slice(Math.max(values.length - this.preferences.periodsToShow, 1));
    }
    for (const chartDataColumnKey in values) {
      if (chartDataColumnKey in values) {
        const chartDataColumn = values[chartDataColumnKey];
        const chartDataColumnColor = apiData.stackcolors[chartDataColumnKey];
        chartCategories.push(chartDataColumn.xlabel);
        const chartDataItem = chartDataColumn.stacks[0];
        let intValue = parseInt(chartDataItem, 10);
        if (valueAxis1Label === '#K') {
          intValue /= 1000;
        }
        chartStackSeries.data.push({
          value: intValue,
          valueColor: chartDataColumnColor,
          tooltip: {
            visible: true,
            format: '{0}'
          }
        });
      }
    }

    const chartSeries = [chartStackSeries];

    // console.log(chartCategories);
    // console.log(chartSeries);

    const valueAxis1 = {
      name: 'axis1',
      labels: {
        // https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
        // cannot currently add format of #K, add issue to https://github.com/telerik/kendo-intl/issues
        format: valueAxis1Label,
        position: 'start',
        visible: true
      },
      line: {
        visible: false
      },
      title: {}
    };
    // const valueAxis2 = {
    //   name: 'axis2',
    //   labels: {
    //     // https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
    //     // cannot currently add format of #K, add issue to https://github.com/telerik/kendo-intl/issues
    //     format: valueAxis2Label,
    //     position: 'end',
    //     visible: true
    //   },
    //   line: {
    //     visible: false
    //   },
    //   title: {}
    // };

    if ('yaxisleftlabel' in apiData) {
      valueAxis1.title = {
        text: apiData.yaxisleftlabel,
        color: apiData.yaxisleftlabelcolor
      };
    } else {
      valueAxis1.labels.visible = false;
    }
    // if ('yaxisrightlabel' in apiData) {
    //   valueAxis2.title = {
    //     text: apiData.yaxisrightlabel,
    //     color: apiData.yaxisrightlabelcolor
    //   };
    // } else {
    //   valueAxis2.labels.visible = false;
    // }

    this.chartOptions = {
      seriesDefaults: {
        type: 'column',
        stack: false
      },
      series: chartSeries,
      chartArea: {
        border: {
          color: '#000',
          width: 1,
          dashType: 'solid'
        },
        height: 400
      },
      valueAxis: [valueAxis1],
      categoryAxis: {
        categories: chartCategories,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45
        }
      }
    };
  }
}
