import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';

@Component({
  selector: 'app-geo-location-selector',
  templateUrl: './geo-location-selector.component.html',
  styleUrls: ['./geo-location-selector.component.scss']
})
export class GeoLocationSelectorComponent implements OnInit, OnDestroy {
  selectedTab = 'areas';
  selectedItem;
  requestHandler;

  @Input() apiConfig;

  apiState;
  areaData = [];
  countryData = [];
  molecularOrgData = [];
  regionData = [];
  serviceManagerData = [];

  // selectedArea;
  // selectedCountry;
  // selectedMolecularOrg;
  // selectedRegion;
  // selectedServiceManager;

  constructor(private apiService: ApiService, private personaService: PersonaService) { }

  ngOnInit(): void {
    let apiRoute = null;
    if (this.apiConfig === 'serviceMetrics') {
      apiRoute = '/geography_service';
    }
    if (this.apiConfig === 'ambassador') {
      apiRoute = '/geography_amb';
    }
    this.requestHandler = this.apiService.get(apiRoute);
    this.requestHandler.sendRequest(apiRoute);
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        let buildData;

        // areas
        buildData = [];
        if (apiData.areas) {
          for (const location of apiData.areas) {
            buildData.push({
              label: location.name,
              key: location.id
            });
          }
        }
        this.areaData = buildData;

        // countries
        buildData = [];
        if (apiData.countries) {
          for (const location of apiData.countries) {
            buildData.push({
              label: location.name,
              key: location.id
            });
          }
        }
        this.countryData = buildData;

        // molecular orgs
        buildData = [];
        if (apiData.molecularorgs) {
          for (const location of apiData.molecularorgs) {
            buildData.push({
              label: location.name,
              key: location.id
            });
          }
        }
        this.molecularOrgData = buildData;

        // regions
        buildData = [];
        if (apiData.regions) {
          for (const location of apiData.regions) {
            buildData.push({
              label: location.name,
              key: location.id
            });
          }
        }
        this.regionData = buildData;

        // service managers
        buildData = [];
        if (apiData.servicemanagers) {
          for (const location of apiData.servicemanagers) {
            buildData.push({
              label: location.name,
              key: location.id
            });
          }
        }
        this.serviceManagerData = buildData;
        this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.GEO);
        if (this.selectedItem !== '' && this.selectedItem !== null) {
          this.selectedTab = this.getTypeOfSelectedItem(this.selectedItem);
        }
      }
    });
    this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.GEO);
    if (this.selectedItem !== '' && this.selectedItem !== null && this.apiState === RequestState.Complete) {
      this.selectedTab = this.getTypeOfSelectedItem(this.selectedItem);
    }
    this.personaService.onPersonaChoiceUpdate(() => {
      this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.GEO);
      if (this.selectedItem !== '' && this.selectedItem !== null) {
        this.selectedTab = this.getTypeOfSelectedItem(this.selectedItem);
      }
    });
  }

  getTypeOfSelectedItem(selectedItem) {
    for (const item of this.areaData) {
      if (item.key === selectedItem) {
        return 'areas';
      }
    }
    for (const item of this.countryData) {
      if (item.key === selectedItem) {
        return 'countries';
      }
    }
    for (const item of this.molecularOrgData) {
      if (item.key === selectedItem) {
        return 'molecular';
      }
    }
    for (const item of this.regionData) {
      if (item.key === selectedItem) {
        return 'regions';
      }
    }
    for (const item of this.serviceManagerData) {
      if (item.key === selectedItem) {
        return 'service';
      }
    }
  }

  getLabel(type, key) {
    let data = null;
    switch (type) {
      case 'areas':
        data = this.areaData;
        break;
      case 'countries':
        data = this.countryData;
        break;
      case 'molecular':
        data = this.molecularOrgData;
        break;
      case 'regions':
        data = this.regionData;
        break;
      case 'service':
        data = this.serviceManagerData;
        break;
    }
    if (data) {
      for (const item of data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  onSelectedChange(selectedTab) {
    this.selectedTab = selectedTab;
  }

  setSelectedItem(type, selectedItem) {
    this.selectedItem = selectedItem;
    this.selectedTab = type;
    const label = this.getLabel(type, selectedItem);
    this.personaService.setPersonaOption(PersonaTypes.GEO, selectedItem, label);
  }
}
