import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileComponent } from '../../../tiles/base/tile.component';
import { RenderEvent } from '@progress/kendo-angular-charts';
import { Rect, Circle as CircleGeometry } from '@progress/kendo-drawing/geometry';
import { Group, Path, Layout, Text } from '@progress/kendo-drawing';

@Component({
  selector: 'app-br-example-pie-chart',
  templateUrl: './br-example-pie-chart.component.html',
  styleUrls: ['./br-example-pie-chart.component.scss']
})
export class BrExamplePieChartComponent extends TileComponent implements OnInit {

  years = ['assay1', 'assay2', 'assay3', 'assay4', 'assay5'];

  students = [
    { name: 'Al', values: [90, 91, 92, 93, 94] },
    { name: 'Brad', values: [89, 88, 87, 86, 85] },
    { name: 'Carl', values: [80, 81, 82, 83, 84] },
    { name: 'Dave', values: [85, 86, 87, 88, 89] },
  ];

  translateApiDataForChart(apiData) {

  }
}
