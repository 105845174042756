<a class="nav-link" (click)="toggle()" *ngIf="navFilterSelection && navFilterSelection.customerWTrackSystemsFlag">
    <span class="icon" [class.switch-on]="stateOn"></span>
    <span class="icon-state-label" *ngIf="displayTitles">
        <span *ngIf="stateOn">
            Customers w/Track Systems
        </span>
        <span *ngIf="!stateOn">
            All Customers
        </span>
    </span>
</a>