import { Injectable } from '@angular/core';
import {RowParsers} from '../configuration/details/row-parsers';
import {RowParserInterface} from './row-parser-interface';

@Injectable({
  providedIn: 'root'
})
export class RowParserService {
  protected rowParsers = RowParsers;
  protected registry = {};

  constructor() { }

  public get(key): RowParserInterface {
    if (!(key in this.registry)) {
      this.registry[key] = new this.rowParsers[key]();
    }

    return this.registry[key];
  }
}
