export const SurveyCommentPages = {
    'hotsheets/survey_comments': {
        pageTitle: 'Translation Details',
        apiRequestHandler: '/metrics/hotsheets/details',
        backPageLabel: 'NPS Open Hotsheet Details',
        backPageLink: '/hotsheets',
        dataArrayKey: 'nps',
        dataArraySplitColKey: 'scores',
        table: {
            dataCols: [
                {
                    key: 'customernumber'
                },
                {
                    key: 'customername'
                },
                {
                    key: 'country'
                },
                {
                    key: 'interviewdate'
                }
            ],
            dataArraySplitCols: [
                {
                    title: 'Overall',  // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'overall'
                },
                {
                    title: 'Field Service', // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'fieldservice'
                },
                {
                    title: 'Product Quality',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'productquality'
                },
                {
                    title: 'Product Delivery',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'productdelivery'
                },
                {
                    title: 'Sales Rep',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'salesrep'
                },
                {
                    title: 'Technical Support',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'technicalsupport'
                }
            ],
            drillDownCols: [
                {
                    title: 'Survey Comments',
                    key: 'detractor',
                }
            ]
        }
    },
    'customerNps/details/survey_comments': {
        pageTitle: 'Translation Details',
        apiRequestHandler: '/metrics/nps/detail',
        backPageLabel: 'Customer NPS Survey Details',
        backPageLink: '/customerNps/details',
        dataArrayKey: 'details',
        dataArraySplitColKey: 'scores',
        table: {
            dataCols: [
                {
                    key: 'customernumber'
                },
                {
                    key: 'customername'
                },
                {
                    key: 'country'
                },
                {
                    key: 'interviewdate'
                }
            ],
            dataArraySplitCols: [
                {
                    title: 'Overall',  // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'overall'
                },
                {
                    title: 'Field Service', // maps to label
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'fieldservice'
                },
                {
                    title: 'Product Quality',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'productquality'
                },
                {
                    title: 'Product Delivery',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'productdelivery'
                },
                {
                    title: 'Sales Rep',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'salesrep'
                },
                {
                    title: 'Technical Support',
                    arrayMappingKey: 'label',
                    arrayMappingValue: 'value',
                    key: 'technicalsupport'
                }
            ],
            drillDownCols: [
                {
                    title: 'Survey Comments',
                    key: 'detractor',
                }
            ]
        }
    },
    'rnps/detail/survey_comments': {
        pageTitle: 'rNPS Survey - Translation Details',
        apiRequestHandler: '/metrics/rnps/detail',
        backPageLabel: 'rNPS Surveys',
        backPageLink: '/rnps/detail',
        dataArrayKey: 'rows',
        table: {
            dataCols: [
                {
                    key: 'customernum'
                },
                {
                    key: 'customername'
                },
                {
                    key: 'interviewdate'
                },
                {
                    key: 'value'
                }
            ],
            drillDownCols: [
                {
                    title: 'Survey Comments',
                    key: 'detractor',
                }
            ]
        }
    }
};