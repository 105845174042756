 <kendo-grid
    [data]="gridData"
    [pageable]="true"
    [pageSize]="gridState.take"
    [skip]="gridState.skip"
    [filter]="gridState.filter"
    filterable="menu"
    [sort]="gridState.sort"
    [sortable]="true"
    [resizable]="true"
    (pageChange)="pageChange($event)"
    (sortChange)="sortChange($event)"
    (filterChange)="filterChange($event)"
    (cellClick)="onCellClick($event)"
>
    <ng-container *ngFor="let col of dataCols">
        <kendo-grid-column
                [field]="col.ref"
                [title]="col.description"
                [width]="col.width"
                [hidden]="col.hidden"
                [filterable]="false">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span *ngIf="col.ref !== 'sn' && col.ref !== 'ticketNum'">
                    {{dataItem[col.ref]}}
                </span>
                <span *ngIf="col.ref === 'ticketNum'">
                    <span class="link-feel"><a
                        href="{{gsrUrl}}/gsr/ticket_details/ticket_details.php?ticket={{dataItem[col.ref]}}"
                        target="blank">
                        {{dataItem[col.ref]}}
                    </a></span>
                </span>
                <span *ngIf="col.ref === 'sn'">
                    <span class="link-feel">{{dataItem[col.ref]}}</span>
                </span>
                <span *ngIf="col.ref === 'plName'">
                    ({{dataItem.pl}})
                </span>
            </ng-template>
        </kendo-grid-column>
    </ng-container>
</kendo-grid>
<kendo-popup *ngIf="showContextMenu" [offset]="offset">
    <ul class='menu'>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/sift_icon.gif" /></span>
            <a href="{{gsrUrl}}/gsr/sift/sift_details.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">SIFT</a>
        </li>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/ticket_history.gif" /></span>
            <a href="{{gsrUrl}}/gsr/ticket_details/ticket_load.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">Ticket History</a>
        </li>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/TSB_Button.gif" /></span>
            <a href="{{gsrUrl}}/gsr/tsb/tsb_serial_number_history.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">TSB</a>
        </li>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/ihr.gif" /></span>
            <a href="{{gsrUrl}}/gsr/ihr/ihr.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">IHR</a>
        </li>
    </ul>
</kendo-popup>
