import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import {GridComponent} from '../../details/grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {DetailQueryParamsService} from '../detail-query-params.service';
import {DDTConfig} from '../configuration/details/ddt-config';
import {BrParamsService} from '../br-params.service';
import { ActivatedRoute } from '@angular/router';

// columns need to be predefined. set in a parent component
@Component({
  selector: 'app-dynamic-data-table',
  templateUrl: './dynamic-data-table.component.html',
  styleUrls: ['./dynamic-data-table.component.scss']
})
export class DynamicDataTableComponent extends GridComponent implements OnInit, OnDestroy {
  ddtConfig = DDTConfig;
  @Input() apiUrl = '/qbr/kpi/example_ddt/details';
  apiData = null;
  state = null;
  requestHandler;
  stateChangeHandler;
  returnLink;
  detailQueryCompleteSubscription;
  returnLinkSubscription;
  queryParamsSubscription;
  routeUrlSubscription;

  @Input() dataCols = [];
  dataRows = [];

  // data col metadata:
  // {ref: 'uniqueCustomerKey', description: 'CustomerKey', isLink: false, width: 80, hidden: true, filterable: true, export: false},


  constructor(
    private apiService: ApiService,
    private detailQueryParamsService: DetailQueryParamsService,
    private brParamsService: BrParamsService,
    private route: ActivatedRoute
    ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    this.detailQueryCompleteSubscription = this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.ddtConfig.hasOwnProperty(currentRouterLocation)) {
        this.apiUrl = this.ddtConfig[currentRouterLocation];
      }
      this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_detail'));

      this.requestHandler = this.apiService.get(this.apiUrl);
      this.requestHandler.sendRequest(this.apiUrl);
      // no need to send request here as chart tile has already done so
      this.stateChangeHandler = (state) => {
        this.state = state;
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.apiData = JSON.parse(response.body);
          // format dataRows for kendo component
          this.dataRows = [];
          for (let i = 0; i < this.apiData.dataTable.rowData.length; i++) {
            const apiDataRow = this.apiData.dataTable.rowData[i];
            const dataRow = {};
            // console.log('dataCols', this.dataCols);
            for (const dataColConfig of this.dataCols) {
              // if (dataColConfig.ref )
              let colorCalc = '';
              if (dataColConfig.ref + '_COLOR' in apiDataRow) {
                colorCalc = apiDataRow[dataColConfig.ref + '_COLOR'];
              }
              if (dataColConfig.bg_color.length > 0) {
                colorCalc = apiDataRow[dataColConfig.bg_color];
              }
              dataRow[dataColConfig.ref] = {
                value: apiDataRow[dataColConfig.ref],
                bgcolor: colorCalc,
                color: ''
              };
            }
            this.dataRows.push(dataRow);
          }
          this.updateGrid();
        }
      };
      this.requestHandler.onStateChange(this.stateChangeHandler);
      this.updateGrid();
    });

    this.returnLinkSubscription = this.detailQueryParamsService.getReturnLink().subscribe((pathDetails) => {
      this.returnLink = pathDetails;
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.detailQueryParamsService.prepReturnParams(params);
      this.detailQueryParamsService.buildPersistedValuesFromParams(params);
    });
    this.routeUrlSubscription = this.route.url.subscribe((url) => {
      this.detailQueryParamsService.prepReturnLink(url);
      this.detailQueryParamsService.processReturnLink();
    });
  }

  ngOnDestroy(): void {
    if (this.stateChangeHandler) {
      this.requestHandler.removeStateListener(this.stateChangeHandler);
    }
    this.detailQueryCompleteSubscription.unsubscribe();
    this.returnLinkSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.routeUrlSubscription.unsubscribe();
  }

  // getQueryParams() {
  //   return this.detailQueryParamsService.getQueryParams();
  // }

  inArray(array, index) {
    return (index in array);
  }

  getQueryParams() {
    return this.detailQueryParamsService.getQueryParams();
  }
}
