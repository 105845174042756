<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div class="tile-chart-container" *ngIf="apiData">
        <div *ngFor="let apiDataVal of apiData; index as i; last as isLast">
            <div *ngFor="let dataVal of apiDataVal.data; index as idx; last as isLast">
                <a [routerLink]="detailLinkExist(idx) ? [preferences.detailLinks[idx]] : []">
                    <div class="{{apiDataVal.visualization}}" style="color: {{dataVal.color}}">
                        <h3>{{dataVal.label}}</h3>
                        <span class="textLabel" [class]="'label_'+idx">{{dataVal.displayvalue}}</span>
                    </div>
                </a>
                <span *ngIf="!isLast"><hr class="tile_separator" /></span>
            </div>
        </div>
    </div>
</app-state-handler>