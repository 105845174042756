<a class="nav-link" (click)="toggle()" *ngIf="navFilterSelection && navFilterSelection.myVsAllInstrumentsFlag">
    <span class="icon" [class.switch-on]="stateOn"></span>
    <span class="icon-state-label" *ngIf="displayTitles">
        <span *ngIf="stateOn">
            My Instruments for Customers
        </span>
        <span *ngIf="!stateOn">
            All Instruments for Customers
        </span>
    </span>
</a>