import { Input, Component, OnInit } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import { TileReference } from '../../../dashboards/tile-reference';
import { TileComponent } from '../../../tiles/base/tile.component';

@ComponentLookup('BrPlaceholderComponent')
@Component({
  selector: 'app-br-placeholder',
  templateUrl: './br-placeholder.component.html',
  styleUrls: ['./br-placeholder.component.scss']
})
export class BrPlaceholderComponent extends TileComponent implements OnInit {
  @Input() tileReference: TileReference;

  translateApiDataForChart(apiData) {
    // override for currency preference
    console.log(this.tileReference);
    console.log(apiData);
  }

}
