<div class="br-snapshot">
  <h3 *ngIf="preferences.showTitle">{{preferences.title}}</h3>
  <app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData">
      <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
        <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          <kendo-chart>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [categories]="['2022-05','2022-06','2022-07','2022-08']">
                <kendo-chart-category-axis-item-labels [rotation]="-45">
                </kendo-chart-category-axis-item-labels>
              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-y-axis>
              <kendo-chart-y-axis-item [title]="{ text: 'Minutes' }">
              </kendo-chart-y-axis-item>
            </kendo-chart-y-axis>
            <kendo-chart-series>
              <kendo-chart-legend position="bottom" orientation="horizontal">
              </kendo-chart-legend>
              <kendo-chart-series-item name="Average" type="column" [data]="[1, 2, 3, 2]">
              </kendo-chart-series-item>
              <kendo-chart-series-item name="Longest" type="column" [data]="[2, 3, 4, 4]">
              </kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
        </a>
      </div>
      <div *ngIf="!isDataFound(apiData)" class="no-data">
        No data found
      </div>
    </div>
    </app-state-handler>
    <!--
    <h3 *ngIf="preferences.showTitle">{{preferences.title}}</h3>
    <app-state-handler [requestState]="state" [data]="apiData">
      <div *ngIf="apiData">
        <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
          <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
            <div *ngIf="chartOptions !== null && chartOptions != undefined">
              <kendo-chart [seriesDefaults]="chartOptions.seriesDefaults"
                           [series]="chartOptions.series"
                           [valueAxis]="chartOptions.valueAxis"
                           [categoryAxis]="chartOptions.categoryAxis"
                           [plotArea]="chartOptions.chartArea">
              </kendo-chart>
            </div>
          </a>
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
          No data found
        </div>
      </div>
    </app-state-handler>-->
</div>
