import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from 'src/app/decorators/component-lookup';
import { BaseTileComponent } from '../base-tile.component';
import { MultiAPITileComponent } from '../multi-api-tile.component';

@ComponentLookup('VerticalSplitTextIndicatorsComponent')
@Component({
  selector: 'app-vertical-split-text-indicators',
  templateUrl: './vertical-split-text-indicators.component.html',
  styleUrls: ['./vertical-split-text-indicators.component.scss']
})
export class VerticalSplitTextIndicatorsComponent extends MultiAPITileComponent implements OnInit {
  defaultPreferences() {
    return {
      showTitle: true
    };
  }
  translateApiDataForChart(apiData) {}

  detailLinkExist(i) {
    if (this.preferences.hasOwnProperty('detailLinks') && this.preferences.detailLinks.length > i) {
      return true;
    }
    return false;
  }
}
