import { Component, OnInit, OnDestroy, ElementRef, HostListener, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import {ApiService} from '../../../api/api.service';
import {RequestState} from '../../../api/request-handler';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {BrParamsService} from '../../br-params.service';

@Component({
  selector: 'app-br-servicing-group-selector',
  templateUrl: './br-servicing-group-selector.component.html',
  styleUrls: ['./br-servicing-group-selector.component.scss']
})
export class BrServicingGroupSelectorComponent implements OnInit, OnDestroy {
  RequestStateComplete = RequestState.Complete; // temporary until api call is implemented

  protected requestHandler;
  protected apiSource = '/qbr/app_config/servicing_groups/servicing_groups';
  protected refreshHandleKey;
  public state = RequestState.Inactive;

  selectedValues = [];
  apiState;
  data = [];

  constructor(
    private authService: AuthenticationService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    this.updateSelections();

    this.requestHandler = this.apiService.get(this.apiSource);
    this.requestHandler.sendRequest(this.apiSource);
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        const buildData = [];
        let lookForPersistedValue = false;
        const presistedValue = this.brParamsService.get('sg');
        if (presistedValue.length !== 0) {
          lookForPersistedValue = true;
        }
        for (const item of apiData.items) {
          const tempItem = {
            label: item.display_name,
            key: item.id
          };
          buildData.push(tempItem);
          if (lookForPersistedValue) {
            if (presistedValue.includes(item.id)) {
              this.selectedValues.push(tempItem);
            }
          } else if (item.selected) {
            this.selectedValues.push(tempItem);
          }
        }
        this.data = buildData;

        this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
          if (brParamKey === 'sg') {
            this.selectedValues = [];
            let lookForPersistedValue2 = false;
            const presistedValue2 = this.brParamsService.get('sg');
            if (presistedValue2.length !== 0) {
              lookForPersistedValue2 = true;
            }
            for (const item of apiData.items) {
              const tempItem = {
                label: item.display_name,
                key: item.id
              };
              if (lookForPersistedValue2) {
                if (presistedValue2.includes(item.id)) {
                  this.selectedValues.push(tempItem);
                }
              } else if (item.selected) {
                this.selectedValues.push(tempItem);
              }
            }
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  updateSelections() {

    // this.menuText1 = this.selectedType;
    // this.menuText2 = this.selectedLabel;

    this.cdRef.detectChanges();
  }

  getLabel(key) {
    if (this.data) {
      for (const item of this.data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  valueChange(selectedItems) {
    // this.selectedValues = this.getArrayOfPropertyFromObject(selectedItems, 'key');
    this.brParamsService.set('sg', this.getArrayOfPropertyFromObject(selectedItems, 'key'));
    this.brParamsService.set('sg_description', this.getArrayOfPropertyFromObject(selectedItems, 'key').join(', '));
  }

  getArrayOfPropertyFromObject(object, property) {
    const result = [];
    for (const item of object) {
      result.push(item[property]);
    }
    return result;
  }
}
