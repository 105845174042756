import { Component, OnInit, ElementRef, HostListener, ViewChild, Input } from '@angular/core';
import {FilterChoicesService} from '../filter-choices.service';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';

@Component({
  selector: 'app-ct-product-set-selector',
  templateUrl: './ct-product-set-selector.component.html',
  styleUrls: ['./ct-product-set-selector.component.scss']
})
export class CtProductSetSelectorComponent implements OnInit {
  public apiUrl = 'cua/product_families';
  apiState = RequestState.Complete;
  menuVisible = false;

  data = [];
  selectedItem;

  menuText1 = '';
  menuText2 = '';

  @Input() anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  @Input() popupAlign = { horizontal: 'left', vertical: 'top' };

  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  constructor(
    private filterService: FilterChoicesService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    const requestHandler = this.apiService.get(this.apiUrl);
    requestHandler.sendRequest(this.apiUrl, [], false);

    requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = requestHandler.getResponse();
        const data = JSON.parse(response.body);
        this.data = [];
        for (const item of data) {
          this.data.push({
            label: item.prdFamilySetDisplayVal,
            key: item.prdFamilySetId // prdFamilySetKey
          });
          if (item.prdFamilySetDefault) {
            this.setSelectedItem(item.prdFamilySetId);
          }
        }
        // console.log('cuatest', this.data);
      }
    });

    this.updateSelections();
    this.onResize();
  }

  updateSelections() {
    this.menuText1 = 'Product Families';
    this.menuText2 = this.getLabel(this.selectedItem);
  }


  getLabel(key) {
    if (this.data) {
      for (const item of this.data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    this.filterService.setFilter('product_set_number', selectedItem);
    this.updateSelections();
  }

  onToggle(): void {
    this.menuVisible = !this.menuVisible;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.menuVisible) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target) && this.menuVisible) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }
}
