import { RowParserInterface } from '../row-parser-interface';

export class StackKeys implements RowParserInterface {
  get(sourceData, params) {
    const stackKeys = [];
    stackKeys.push({
      ref: 'description',
      description: 'YYYY-MM',
      width: '80',
      class: null
    });
    let i = 0;
    for (const value of sourceData.values) {
      const key = value.xlabel;
      stackKeys.push({
        ref: key,
        description: key,
        width: '65',
        class: null
      });
      i++;
    }
    return stackKeys;
  }
}
