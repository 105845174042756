import { Component, OnInit } from '@angular/core';
import {TileReference} from '../tile-reference';
import {AuthenticationService} from '../../authentication/authentication.service';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {Personas} from '../../configuration/personas';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {DashboardOptions} from '../../configuration/nav-filter-selection';
import {PersonaService} from '../../api/persona.service';
import {DetailChoicesService} from '../../filter_selection/details/detail-choices.service';
import {SelectedTabService} from '../selected-tab.service';

@Component({
  selector: 'app-ambassador',
  templateUrl: './ambassador.component.html',
  styleUrls: ['./ambassador.component.scss']
})
export class AmbassadorComponent implements OnInit {
  arrangement: TileReference[] = [
    {
      apiSource: '/metrics/rnps',
      componentName: 'TextIndicatorsComponent',
      detailLink: '/rnps',
      preferences: {
        title: 'Overall rNPS Score'
      }
    },
    {
      apiSource: '/amb_orders', // Customer Orders
      componentName: 'PieChartComponent',
      preferences: {
        showGoal: false,
        title: 'Customer Orders'
      },
      detailLink: '/amb_orders'
    },
    {
      apiSource: '/metrics/sales',
      componentName: 'AreaLineChartComponent',
      detailLink: '/sales',
      preferences: {
        periodsToShow: 4,
        title: 'Sales'
      }
    },
    {
      apiSource: '/metrics/rcs',
      componentName: 'PieChartComponent', // RCS
      preferences: {
        showGoal: false,
        title: 'RCS'
      },
      detailLink: '/rcs'
    },
    {
      apiSource: '/metrics/compliance',
      componentName: 'ComplianceChartComponent',
      detailLink: '/compliance',
      preferences: {
        title: 'Contract Compliance'
      }
    },
    {
      apiSource: '/metrics/servicegrossmargin_amb',
      componentName: 'TextIndicatorsComponent',
      detailLink: '/amb_servicegrossmargin_customerSGM',
      preferences: {
        lowerTitleColor: '#000000',
        title: 'Service Gross Margin'
      }
    },
    {
      apiSource: '/customerswithoutservicecontracts_amb',
      componentName: 'StackedBarLineChartComponent',
      detailLink: '/amb_customerswithoutservicecontracts',
      preferences: {
        periodsToShow: 4,
        title: 'Predictive Alerts w/o Service Contracts'
      }
    }
  ];

  // Overall rNPS Score
  // Sales
  // Customer Orders
  // RCS
  // Contract Compliance
  // Customers SGM % (12MA)
  // Service Gross Margin (SGM%)
  // Predictive Alerts w/o Service Contracts

  constructor(
    private authService: AuthenticationService,
    private navbarBuilderService: NavbarBuilderService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService
  ) { }

  ngOnInit(): void {
    this.personaService.setServiceVsAmbState(false); // change to from other tab
    this.navbarBuilderService.setNavbarChoice('dashboard');
    this.navbarBuilderService.setNavFilterSelection({
      preferences: true,
      personaSelector: true,
      dashboardSelector: true,
      selectedDashboard: DashboardOptions.ambassador,
      search: true,
      toolSelection: ToolSelectionOptions.mycrl,
      legacyCustomersFlag: false,
      myVsAllInstrumentsFlag: false,
      customerWTrackSystemsFlag: false,
      flagshipProductLinesFlag: false,
      bookmarksFlag: true,
      navigateBackwards: false,
      navigateBackwardsLabel: 'Back',
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false,
      personaOptions: [Personas.AmbManager, Personas.GeoLocation, Personas.Account]
    });
    this.selectedTabService.selectTab(DashboardOptions.ambassador);
    this.authService.onAuth(() => {
      this.personaService.setServiceVsAmbState(false); //after auth
      this.selectedTabService.selectTab(DashboardOptions.ambassador);
    }, null, 'appComponent');
    this.authService.onAuth(() => {
      this.personaService.setServiceVsAmbState(false);
      this.selectedTabService.selectTab(DashboardOptions.ambassador);
    }, null, 'personaService');
  }
}
