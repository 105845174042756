<div class="container-fluid" *ngIf="config">
    <div class="row">
        <div class="col-9">
            <div class="py-3">
                <div *ngIf="charts">
                    <div class="container">
                        <div class="row">
                            <div *ngFor="let chartTileReference of charts" class="col">
                                <span style="text-align:center;display:block;padding:10px;padding-left:20px;">
                                    {{chartTileReference.preferences.title}}
                                </span>
                                <app-tile-insert [tileReference]="chartTileReference"></app-tile-insert>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!charts && tileReference">
                    <app-tile-insert [tileReference]="tileReference"></app-tile-insert>
                </div>
                <br/>
                <div *ngIf="config.table.type === 'style1'">
                    <app-style1-grid></app-style1-grid>
                </div>
                <div *ngIf="config.table.type === 'set-values'">
                    <app-set-values-grid></app-set-values-grid>
                </div>
                <div *ngIf="config.table.type === 'custom'">
                    <app-custom-grid></app-custom-grid>
                </div>
                <div *ngIf="config.table.type === 'custom-compliance'">
                    <app-custom-compliance-grid></app-custom-compliance-grid>
                </div>
            </div>
        </div>
        <div class="col-3 px-3" *ngIf="config.filters.length > 0">
            <div class="filter-bar">
                <div *ngFor="let filterComponentName of config.filters">
                    <app-filter-insert [componentRef]="filterComponentName" [metricId]="config.metricId"></app-filter-insert>
                </div>
                <a class="btn btn-secondary" (click)="applyDetailFilters()">Fetch Metric Data</a>
                <a class="btn btn-secondary" (click)="clearDetailFilters()">Clear</a>
            </div>
        </div>
    </div>
</div>
