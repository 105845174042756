import { Input, Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileReference } from '../../../dashboards/tile-reference';
import { TileComponent } from '../../../tiles/base/tile.component';

//@ComponentLookup('BrMultiBarChartComponent')
@Component({
   selector: 'app-br-multi-bar-chart',
   templateUrl: './br-multi-bar-chart.component.html',
   styleUrls: ['./br-multi-bar-chart.component.scss']
})


export class BrMultiBarChartComponent extends TileComponent implements OnInit {

  public name1;
  public name2;
  public periods = [];
  public value1 = [];
  public value2 = [];
  yAxisLabel = '';

  defaultPreferences() {
    return {
      periodsToShow: 4,
      showTitle: true,
    };
  }

  translateApiDataForChart(apiData: any) {
    if (apiData.names[0]) { this.name1 = apiData.names[0]; }
    if (apiData.names[1]) { this.name2 = apiData.names[1]; }
    // console.log('WAIT TIME VALUES', apiData);

    const periods: any[] = [];
    const value1: any[] = [];
    const value2: any[] = [];

    let maxValue = 0;

    let values = [];
    values = apiData.values;

    for (const row of values.reverse()) {
      periods.push(row.key);
      value1.push(row.value1);
      value2.push(row.value2);
    }
    //console.log('Values From value1', value1);
  }

  isDataFound(apiData) {
    const dataFound = super.isDataFound(apiData);
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }
}
