<div class="container-fluid" *ngIf="config">
    <div class="row">
        <div class="col-12">    
            <app-state-handler [requestState]="state" [data]="gridData">
                <kendo-grid
                    [data]="gridData"
                    [pageable]="true"
                    [pageSize]="gridState.take"
                    [skip]="gridState.skip"
                    [filter]="gridState.filter"
                    filterable="menu"
                    [sort]="gridState.sort"
                    [sortable]="true"
                    [ngStyle]="{'max-height': getMaxHeight()}" 
                    [resizable]="true"
                    (pageChange)="pageChange($event)"
                    (sortChange)="sortChange($event)"
                    (filterChange)="filterChange($event)"
                    (cellClick)="onCellClick($event)">            
                    <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
                    <ng-template kendoGridToolbarTemplate>
                        <kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>
                        <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                        <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
                    </ng-template>
                        <ng-container *ngFor="let col of dataCols">
                        <kendo-grid-column
                                [field]="col.ref"
                                [title]="col.description"
                                [width]="col.width"                    
                                [format]="col.format"
                                [hidden]="col.hidden"
                                [filterable]="(col.filterable === false) ? false: true"
                                [headerClass]="'data-grid-header'"
                                [style]="col.customStyle">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                                    <ng-container *ngIf="col.refLink != undefined; else two">
                                        <ng-container *ngIf="dataItem[col.refLink].length > 0; else oneA">
                                            <a href="{{dataItem[col.refLink]}}" target="blank" style="color:#007bff; font-weight: 500">{{dataItem[col.ref]}}</a>
                                        </ng-container>
                                        <ng-template #oneA>
                                            <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                        </ng-template>
                                    </ng-container>
                                    <ng-template #two>
                                        <ng-container *ngIf="col.renderRefStyle === true; else three">
                                            <span class="whole-cell" style="color:#007bff; font-weight: bold;">{{dataItem[col.ref]}}</span>
                                        </ng-container>
                                    </ng-template>                                    
                                    <ng-template #three>
                                        <ng-container *ngIf="col.drillDownDetailsLink !== undefined; else four">
                                            <a [routerLink]="[col.drillDownDetailsLink, dataItem['countryCode'], dataItem['customerID'], dataItem['sn']]" 
                                                style="font-weight: 500; font-size: 12pt; color: #007bff">{{col.drillDownDetailsLabel}}</a>                                
                                        </ng-container>
                                    </ng-template>
                                    <ng-template #four>
                                        <!-- <span class="whole-cell">{{dataItem[col.ref] | emptyMask}}</span> -->
                                        <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                    </ng-template>
                                </ng-template>                        
                        </kendo-grid-column>
                    </ng-container>
                </kendo-grid>
                <!-- https://www.telerik.com/kendo-angular-ui/components/grid/how-to/context-menu/ -->
                <kendo-popup *ngIf="showContextMenu" [offset]="offset">
                    <ul class='menu'>
                        <li *ngFor="let item of menuItems" (click)="menuItemSelected(item)">
                            <span style='padding-right: 8px' *ngIf="item.iconUrl">
                                <img width='15' height="15" src="{{item.iconUrl}}" />
                            </span>
                            <a href="{{item.textLink}}" target="blank">{{item.text}}</a>                
                        </li>
                    </ul>
                </kendo-popup>                
            </app-state-handler>
        </div>
    </div>
</div>            