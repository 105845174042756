import { Component, OnInit } from '@angular/core';
import {TileReference} from '../tile-reference';
import {AuthenticationService} from '../../authentication/authentication.service';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {Personas} from '../../configuration/personas';
import {SERVICE_METRIC_TILE_REFERENCE_DEFAULT_ARRANGEMENT} from '../../configuration/service-metric-tile-reference-default-arrangement';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {DashboardOptions} from '../../configuration/nav-filter-selection';
import {PersonaService} from '../../api/persona.service';
import {ChartArea} from '@progress/kendo-angular-charts';
import {SelectedTabService} from '../selected-tab.service';
import {PreferenceSelectionService} from '../../preferences/preference-selection.service';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {PersonaTypes} from '../../configuration/persona-types';
import {RefreshHandlerService} from '../../api/refresh-handler.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  arrangement: TileReference[] = SERVICE_METRIC_TILE_REFERENCE_DEFAULT_ARRANGEMENT;
  requestHandler;
  prefRequestHandler;
  managerApiData;
  managerDataLoaded = false;
  preferencesLoaded = false;

  constructor(
    private authService: AuthenticationService,
    private navbarBuilderService: NavbarBuilderService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService,
    private apiService: ApiService,
    private preferenceSelectionService: PreferenceSelectionService,
    private refreshHandler: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    this.selectedTabService.selectTab(DashboardOptions.dashboard); // this must proceed managerId hack
    // this.arrangement = [this.arrangement[0]];
    // for testing specific metrics
    // Stacked Bar Line Charts
    // this.arrangement = [this.arrangement[0], this.arrangement[4], this.arrangement[7], this.arrangement[13], this.arrangement[27]];
    // Multi Line Charts
    // this.arrangement = [this.arrangement[3], this.arrangement[8], this.arrangement[9], this.arrangement[10], this.arrangement[12], this.arrangement[14], this.arrangement[18], this.arrangement[22]];
    // Text Indicators Chart
    // this.arrangement = [this.arrangement[1], this.arrangement[21], this.arrangement[26]];
    // Shape Indicators
    // this.arrangement = [this.arrangement[2], this.arrangement[15], this.arrangement[16]];
    // Vertical Split Text Charts
    // this.arrangement = [this.arrangement[17], this.arrangement[19], this.arrangement[25]];
    // Pie Charts
    // this.arrangement = [this.arrangement[5], this.arrangement[6], this.arrangement[20], this.arrangement[23], this.arrangement[24]];
    // Top Assays
    // this.arrangement = [this.arrangement[11]];

    /**
     * HACK TO FIX ISSUE WHERE MANAGER UPI IS CONFIGURED FOR MYCRL ROUTES AND AOH USES 511
     */
    this.authService.onAuth(() => {
      this.updateManagerIdHackLoadManagerListAndPreferences();
    });
    this.updateManagerIdHackLoadManagerListAndPreferences();
    // HACK END

    this.personaService.setServiceVsAmbState(true);
    this.personaService.refreshDetailChoices();
    this.navbarBuilderService.setNavbarChoice('dashboard');
    this.navbarBuilderService.setNavFilterSelection({
      preferences: true,
      personaSelector: true,
      dashboardSelector: true,
      selectedDashboard: DashboardOptions.dashboard,
      search: true,
      toolSelection: ToolSelectionOptions.mycrl,
      legacyCustomersFlag: true,
      myVsAllInstrumentsFlag: true,
      customerWTrackSystemsFlag: true,
      flagshipProductLinesFlag: true,
      bookmarksFlag: true,
      navigateBackwards: false,
      navigateBackwardsLabel: 'Back',
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false,
      personaOptions: [Personas.TssAmbassador, Personas.Manager, Personas.GeoLocation]
    });
  }

  updateManagerIdHackLoadManagerListAndPreferences() {
    if (!this.preferenceSelectionService.arePreferencesLoaded() && this.authService.isAuth()) {
      this.prefRequestHandler = this.apiService.get('/preference');
      this.prefRequestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          this.preferencesLoaded = true;
          this.updateManagerIdHackFixPersona();
        }
      });
    } else {
      this.preferencesLoaded = true;
    }
    if (this.authService.isAuth()) {
      this.requestHandler = this.apiService.get('cua/manager_list');
      this.requestHandler.sendRequest('cua/manager_list', null, false);
      this.requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.managerApiData = JSON.parse(response.body);
          this.managerDataLoaded = true;
          this.updateManagerIdHackFixPersona();
        }
      });
    }
  }

  updateManagerIdHackFixPersona() {
    let level1PersonaChoice = this.personaService.getLevel1Choice();
    if (
      this.managerDataLoaded
      && this.preferencesLoaded
      && level1PersonaChoice.type === PersonaTypes.CuaManager
      && this.selectedTabService.getLastSelectedTabInfo() !== DashboardOptions.controltower // do not call unless ct dashboard page active
    ) {
      level1PersonaChoice = this.personaService.getLevel1Choice();
      for (const user of this.managerApiData) {
        if (user.mgr511 === level1PersonaChoice.value) {
          this.personaService.setPersonaOption(
            PersonaTypes.Manager,
            user.mgrUpi,
            user.firstName + ' ' + user.lastName
          );
        }
      }
    }
  }
}
