export const ServiceContractStylePages = {
    '/servicecontracts': {
        pageTitle: 'Service Contracts',
        apiRequestHandler: '/metrics/servicecontracts/details',
        parentPageRequestHandler: '/metrics/servicecontracts',
        backPageLabel: 'Back',
        backPageLink: '/',
        dataArrayKey: 'details',
        table: {
            type: 'level2',
            exportFileName: 'service_contract_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer Number',
                    key: 'customernumber',
                    hidden: true,
                    export: true,
                    width: 50
                },
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80,
                    export: true,
                    renderCustomerNameNumStyle: true
                },
                {
                    title: 'Instrument',
                    key: 'pldescription',
                    export: true,
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'instrumentsrno',
                    width: 80,
                    renderRefStyle: true,
                    export: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ]
                },
                {
                    title: 'Seas Code',
                    key: 'seacode',
                    export: true,
                    width: 50
                },
                {
                    title: 'Contract Name',
                    key: 'contractname',
                    export: true,
                    width: 80
                },
                {
                    title: 'Contract Number',
                    key: 'contractno',
                    export: true,
                    width: 50
                },
                {
                    title: 'Start Date',
                    key: 'startdate',
                    export: true,
                    width: 50
                },
                {
                    title: 'Expires',
                    key: 'expires',
                    export: true,
                    width: 50
                },
                {
                    title: 'Auto-Renew',
                    key: 'autorenew',
                    export: true,
                    width: 50,
                }
            ],
            drillDownCols: [
                {
                    title: 'Entitlement',
                    key: 'entitlements',
                    width: 60,
                    entitlementLink: 'entitlements',
                    export: false
                }
            ]
        }
    },
    '/servicecontracts/entitlements': {
        pageTitle: 'Service Contract Entitlements',
        apiRequestHandler: '/metrics/servicecontracts/details',
        backPageLabel: 'Service Contracts',
        backPageLink: '/servicecontracts',
        dataArrayKey: 'entitlements',
        table: {
            type: 'level3',
            exportFileName: 'service_contract_entitlements.xlsx',
            dataCols: [
                {
                    title: 'Product Name',
                    key: 'productname',
                    width: 60,
                    export: true
                },
                {
                    title: 'Response Time',
                    key: 'responsetime',
                    width: 50,
                    export: true
                },
                {
                    title: 'End Date',
                    key: 'enddate',
                    width: 50,
                    export: true
                },
                {
                    title: 'Entitlement ID',
                    key: 'entitlementid',
                    hidden: true,
                    width: 50,
                    export: false
                },
                {
                    title: 'Entitlement Description',
                    key: 'description',
                    width: 60,
                    export: true
                },
                {
                    title: 'List Number',
                    key: 'listnumber',
                    width: 50,
                    export: true
                },
                {
                    title: 'Cost Type',
                    key: 'costtype',
                    width: 50,
                    export: true
                },
                {
                    title: 'Contract Price',
                    key: 'contractprice',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    export: true
                }
            ],
            drillDownCols: [
                {
                    title: 'Entitlement Terms',
                    key: 'entitlementid',
                    width: 60,
                    entitlementTermsLink: '/servicecontracts/entitlements/terms',
                    export: false
                }
            ]
        }
    },
    '/servicecontracts/entitlements/terms': {
        pageTitle: 'Service Contract Entitlement Terms',
        apiRequestHandler: '/metrics/servicecontracts/details2',
        parentPageRequestHandler: '/metrics/servicecontracts/details',
        backPageLabel: 'Service Contract Entitlements',
        backPageLink: '/servicecontracts/entitlements',
        dataArrayKey: 'details',
        table: {
            type: 'level4',
            exportFileName: 'service_contract_entitlement_terms.xlsx',
            dataCols: [
                {
                    title: 'Hours of Service Support',
                    key: 'servicehours',
                    width: 60
                },
                {
                    title: 'Description',
                    key: 'desc',
                    width: 80
                },
                {
                    title: 'Terms Type',
                    key: 'termstype',
                    width: 50
                },
                {
                    title: 'Terms Category',
                    key: 'termscategory',
                    width: 50
                },
                {
                    title: 'Terms Name',
                    key: 'termsname',
                    width: 50
                },
                {
                    title: 'Cap',
                    key: 'cap',
                    customStyle:  { 'text-align': 'right' },
                    width: 50
                },
                {
                    title: 'Covers Parts',
                    key: 'coverparts',
                    width: 50
                },
                {
                    title: 'Covers Labor',
                    key: 'coverslabor',
                    width: 50
                },
                {
                    title: 'Covers Travel',
                    key: 'coverstravels',
                    width: 50
                }
            ]
        }
    }
};