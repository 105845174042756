<div class="br-snapshot">
  <h3 *ngIf="preferences.showTitle">{{preferences.title}}</h3>
  <app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData">
      <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
        <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          <div *ngIf="chartOptions !== null && chartOptions != undefined">
            <kendo-chart [seriesDefaults]="chartOptions.seriesDefaults"
                         [series]="chartOptions.series"
                         [valueAxis]="chartOptions.valueAxis"
                         [categoryAxis]="chartOptions.categoryAxis"
                         [plotArea]="chartOptions.chartArea">
            </kendo-chart>
          </div>
        </a>
      </div>
      <div *ngIf="!isDataFound(apiData)" class="no-data">
        No data!
      </div>
    </div>
  </app-state-handler>
</div>
