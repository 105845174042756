<div class="navbar-fixed-top">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="justify-content-between d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-row flex-fill" *ngIf="navFilterSelection">
                <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                <div class="navbar-expanded-only">
                    <!--<app-persona-selector></app-persona-selector>-->
                    <span class="mx-auto title">
                        {{navFilterSelection.viewTitleText}}
                    </span>
                </div>
                <button class="navbar-toggler ml-auto"
                        type="button"
                        data-toggle="collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        aria-label="Toggle navigation"
                        (click)="clickEvent()">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="navbar-collapse flex-row d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" *ngIf="navFilterSelection">
                <div class="d-flex flex-row flex-fill">
                    <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                    <a class="nav-link colorwhite" routerLink="{{navFilterSelection.navigateBackwardsLink}}">
                        <fa-icon [icon]="faChevronLeft"></fa-icon> {{navFilterSelection.navigateBackwardsLabel}}
                    </a>
                    <span class="mx-auto title">
                        {{navFilterSelection.viewTitleText}}
                    </span>
                    <app-bookmarks *ngIf="navFilterSelection.bookmarksFlag" [displayTitles]="false"></app-bookmarks>
                </div>
            </div>
        </div>
    </nav>
</div>