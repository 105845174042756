import { Component, OnInit } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {TileComponent} from '../../base/tile.component';

@ComponentLookup('StackedBarLineChartComponent')
@Component({
  selector: 'app-stacked-bar-line-chart',
  templateUrl: './stacked-bar-line-chart.component.html',
  styleUrls: ['./stacked-bar-line-chart.component.scss']
})
export class StackedBarLineChartComponent extends TileComponent implements OnInit {
  axis1LabelSegments = 4;
  axis2LabelSegments = 4;

  defaultPreferences() {
    return {
      showTitle: true,
      valueAxis1Label: '{0}',
      valueAxis2Label: '{0}'
    };
  }
  translateApiDataForChart(apiData) {
    const chartStackSeries = [];
    let stackPosition = 'axis1';
    if (!('yaxisleftlabel' in apiData)) {
      stackPosition = 'axis2';
    }
    for (const chartBarColor of apiData.stackcolors) {
      chartStackSeries.push({
        name: '',
        color: chartBarColor,
        type: 'column',
        gap: .5,
        border: {
          color: '#000',
          width: 1,
          dashType: 'solid'
        },
        data: [],
        axis: stackPosition,
        tooltip: {
          visible: true,
          format: '{0}',
          background: '#aa0061',
          padding: '2'
        }
      });
    }
    const chartStackGoalSeries = [];
    let stackGoalPosition = 'axis2';
    if (!('yaxisrightlabel' in apiData)) {
      stackGoalPosition = 'axis1';
    }
    // let goalAxis = 'axis2';
    // if ('goalAxis' in this.preferences) {
    //   goalAxis = this.preferences['goalAxis'];
    // }
    if (apiData.stackgoalcolors != null) {
      for (const chartLineColor of apiData.stackgoalcolors) {
        chartStackGoalSeries.push({
          color: chartLineColor,
          type: 'line',
          stack: false,
          data: [],
          axis: stackGoalPosition,
          tooltip: {
            visible: true,
            format: '{0}',
            background: '#aa0061',
            padding: '2'
          }
        });
      }
    }
    const chartCategories = [];
    let chartDataItemKey;
    let valueAxis1Label = this.getPreference('valueAxis1Label');

    let axis1Max = apiData.yleftmax;
    if (axis1Max / this.axis1LabelSegments > 1000) {
      valueAxis1Label = '#K';
    }
    if (valueAxis1Label === '#K') {
      axis1Max = this.nextHighestNumberDivisibleBy(axis1Max / 1000, this.axis1LabelSegments) * 1000;
    } else {
      axis1Max = this.nextHighestNumberDivisibleBy(axis1Max, this.axis1LabelSegments);
    }
    const axis1MaxOption2 = (axis1Max / this.axis1LabelSegments);
    if (apiData.yleftmax <= axis1MaxOption2 * 3) {
      axis1Max = axis1MaxOption2 * 3;
      this.axis1LabelSegments = 3;
    }
    if (apiData.yleftmax <= axis1MaxOption2 * 1) {
      axis1Max = axis1MaxOption2 * 1;
      this.axis1LabelSegments = 1;
    }
    // if (this.preferences.title === 'Service Cost') {
    //   console.log('y', apiData.yleftmax, 'a', axis1Max, 'ls', this.axis1LabelSegments);
    // }
    const valueAxis2Label = this.getPreference('valueAxis2Label');

    let values = apiData.values;
    if ('periodsToShow' in this.preferences) {
      // only use the last x periods in the chart
      values = values.slice(Math.max(values.length - this.preferences.periodsToShow, 1));
    }
    for (const chartDataColumn of values) {
      chartCategories.push(chartDataColumn.xlabel);
      for (chartDataItemKey = 0; chartDataItemKey < chartDataColumn.stacks.length; chartDataItemKey++) {
        const chartDataItem = chartDataColumn.stacks[chartDataItemKey];
        const intValue = parseInt(chartDataItem, 10);
        // if (valueAxis1Label === '#K') {
        //   intValue /= 1000;
        // }
        chartStackSeries[chartDataItemKey].data.push(intValue);
      }

      if (chartDataColumn.stackgoals != null) {
        for (chartDataItemKey = 0; chartDataItemKey < chartDataColumn.stackgoals.length; chartDataItemKey++) {
          const chartDataItem = chartDataColumn.stackgoals[chartDataItemKey];
          const intValue = parseFloat(chartDataItem);
          // if (valueAxis2Label === '#K') {
          //   intValue /= 1000;
          // }
          chartStackGoalSeries[chartDataItemKey].data.push(intValue);
        }
      }
    }
    const chartSeries = chartStackSeries.concat(chartStackGoalSeries);

    // console.log(chartCategories);
    // console.log(chartSeries);

    const valueAxis1 = {
      name: 'axis1',
      labels: {
        // https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
        // cannot currently add format of #K, add issue to https://github.com/telerik/kendo-intl/issues
        format: valueAxis1Label,
        content: (e) => {
          if (valueAxis1Label === '#K') {
            return (e.value / 1000) + 'K';
          }
          return e.value;
        },
        position: 'start',
        visible: true
      },
      max: axis1Max,
      min: 0,
      majorUnit: axis1Max / this.axis1LabelSegments,
      line: {
        visible: false
      },
      title: {},
      tooltip: {
        visible: true,
        format: '{0}',
        background: '#aa0061',
        padding: '2'
      }
    };
    let axis2Source = Math.ceil(apiData.yrightmax);
    if ('axis2Source' in this.preferences) {
      axis2Source = apiData[this.preferences.axis2Source];
    }
    const axis2Max = this.nextHighestNumberDivisibleBy(axis2Source, this.axis2LabelSegments);

    // if ('axis2VisibleLabels' in this.preferences) {
    //
    // }
    const valueAxis2 = {
      name: 'axis2',
      labels: {
        // https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
        // cannot currently add format of #K, add issue to https://github.com/telerik/kendo-intl/issues
        format: valueAxis2Label,
        content: (e) => {
          if (valueAxis2Label === '#K') {
            return (e.value / 1000) + 'K';
          }
          return e.value;
        },
        position: 'end',
        visible: true
      },
      max: axis2Max,
      majorUnit: axis2Max / this.axis2LabelSegments,
      min: 0,
      line: {
        visible: false
      },
      title: {},
      tooltip: {
        visible: true,
        format: '{0}',
        background: '#aa0061',
        padding: '2'
      }
    };

    if ('yaxisleftlabel' in apiData) {
      valueAxis1.title = {
        text: apiData.yaxisleftlabel,
        color: apiData.yaxisleftlabelcolor
      };
    } else {
      valueAxis1.labels.visible = false;
    }
    if ('yaxisrightlabel' in apiData) {
      valueAxis2.title = {
        text: apiData.yaxisrightlabel,
        color: apiData.yaxisrightlabelcolor
      };
    } else {
      valueAxis2.labels.visible = false;
    }

    this.chartOptions = {
      seriesDefaults: {
        type: 'column',
        stack: true
      },
      series: chartSeries,
      chartArea: {
        border: {
          color: '#000',
          width: 1,
          dashType: 'solid'
        }
      },
      valueAxis: [valueAxis1, valueAxis2],
      categoryAxis: {
        categories: chartCategories,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45
        }
      }
    };
  }
}
