import { Component, OnInit, Renderer2 } from '@angular/core';
import {GridComponent} from '../grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {Location} from '@angular/common';
import {Style2Pages} from '../../configuration/details/style2-pages';
import {RefreshHandlerService} from '../../api/refresh-handler.service';

@Component({
  selector: 'app-style2-grid',
  templateUrl: './style2-grid.component.html',
  styleUrls: ['./style2-grid.component.scss']
})
export class Style2GridComponent extends GridComponent implements OnInit {

  style2Pages = Style2Pages;
  config = null;
  public state = RequestState.Inactive;

  constructor(
    protected location: Location,
    protected apiService: ApiService,
    protected refreshHandler: RefreshHandlerService
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style2Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style2Pages[currentRouterLocation];

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          // FORMAT API DATA FOR TABLE
          // format dataCols for kendo component
          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          const types = {};
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            const colData = {
              ref: this.config.table.dataCols[i].key,
              refLink: this.config.table.dataCols[i].refLink,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              dateFormat: this.config.table.dataCols[i].dateFormat,
              class: this.config.table.dataCols[i].class,
              hidden: this.config.table.dataCols[i].hidden,
              export: false,
              renderCustomerNameNumStyle: this.config.table.dataCols[i].renderCustomerNameNumStyle,
              renderHitMissStyle: this.config.table.dataCols[i].renderHitMissStyle,
              renderALC12MAStyle: this.config.table.dataCols[i].renderALC12MAStyle,
              renderCustomerStyleForAmbMetrics: this.config.table.dataCols[i].renderCustomerStyleForAmbMetrics,
              customStyle: this.config.table.dataCols[i].customStyle,
              customDateFormat: this.config.table.dataCols[i].customDateFormat,
              customValueIfBlank: this.config.table.dataCols[i].customValueIfBlank,
              renderAgedYNStyle: this.config.table.dataCols[i].renderAgedYNStyle,

            };
            this.dataCols.push(colData);
            if (colData.hidden !== true || this.config.table.dataCols[i].export) {
              const colDataExcel = {
                ref: this.config.table.dataCols[i].key + 'Export',
                refLink: this.config.table.dataCols[i].refLink,
                description: this.config.table.dataCols[i].title,
                width: this.config.table.dataCols[i].width,
                dateFormat: this.config.table.dataCols[i].dateFormat,
                class: this.config.table.dataCols[i].class,
                hidden: true,
                export: true,
                renderCustomerNameNumStyle: this.config.table.dataCols[i].renderCustomerNameNumStyle,
                renderHitMissStyle: this.config.table.dataCols[i].renderHitMissStyle,
                renderALC12MAStyle: this.config.table.dataCols[i].renderALC12MAStyle,
                renderCustomerStyleForAmbMetrics: this.config.table.dataCols[i].renderCustomerStyleForAmbMetrics,
                customStyle: this.config.table.dataCols[i].customStyle,
                customDateFormat: this.config.table.dataCols[i].customDateFormat,
                customValueIfBlank: this.config.table.dataCols[i].customValueIfBlank,
                renderAgedYNStyle: this.config.table.dataCols[i].renderAgedYNStyle,
              };
              this.dataCols.push(colDataExcel);
              if ('type' in this.config.table.dataCols[i]) {
                types[this.config.table.dataCols[i].key + 'Export'] = this.config.table.dataCols[i].type;
              }
            }
            if ('type' in this.config.table.dataCols[i]) {
              types[this.config.table.dataCols[i].key] = this.config.table.dataCols[i].type;
            }
          }
          if (this.config.dataArraySplitColKey !== undefined) {
            for (let i = 0; i < this.config.table.dataArraySplitCols.length; i++) {
              const splitColData = {
                ref: this.config.table.dataArraySplitCols[i].key,
                description: this.config.table.dataArraySplitCols[i].title,
                width: this.config.table.dataArraySplitCols[i].width,
                customStyle: this.config.table.dataArraySplitCols[i].customStyle,
                customValueIfBlank: this.config.table.dataArraySplitCols[i].customValueIfBlank,
                hidden: false,
                export: false
              };
              this.dataCols.push(splitColData);
              if (splitColData.hidden !== true) {
                const splitColDataExcel = {
                  ref: this.config.table.dataArraySplitCols[i].key + 'Export',
                  description: this.config.table.dataArraySplitCols[i].title,
                  width: this.config.table.dataArraySplitCols[i].width,
                  customStyle: this.config.table.dataArraySplitCols[i].customStyle,
                  customValueIfBlank: this.config.table.dataArraySplitCols[i].customValueIfBlank,
                  hidden: true,
                  export: true,
                };
                this.dataCols.push(splitColDataExcel);
                if ('type' in this.config.table.dataArraySplitCols[i]) {
                  types[this.config.table.dataArraySplitCols[i].key + 'Export'] = this.config.table.dataArraySplitCols[i].type;
                }
              }
              if ('type' in this.config.table.dataArraySplitCols[i]) {
                types[this.config.table.dataArraySplitCols[i].key] = this.config.table.dataArraySplitCols[i].type;
              }
            }
          }
          if (this.config.table.drillDownCols !== undefined) {
            for (let i = 0; i < this.config.table.drillDownCols.length; i++) {
              this.dataCols.push({
                description: this.config.table.drillDownCols[i].title,
                width: this.config.table.drillDownCols[i].width,
                drillDownDetailsColName: this.config.table.drillDownCols[i].drillDownDetailsColName,
                drillDownDetailsLabel: this.config.table.drillDownCols[i].drillDownDetailsLabel,
                drillDownDetailsLink: this.config.table.drillDownCols[i].drillDownDetailsLink
              });
            }
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            let dataRow = {};
            if (this.config.table.needEntireApiDataRecord === true) {
              dataRow = apiData[this.config.dataArrayKey][r];
              for (const rowCol in dataRow) {
                if (rowCol in types) {
                  if (types[rowCol] === 'int') {
                    dataRow[rowCol] = parseInt(dataRow[rowCol]);
                  } else if (types[rowCol] === 'float') {
                    dataRow[rowCol] = parseFloat(dataRow[rowCol]);
                  }
                  if (isNaN(dataRow[rowCol])) {
                    dataRow[rowCol] = null;
                  }
                  if (rowCol + 'Export' in dataRow) {
                    dataRow[rowCol + 'Export'] = dataRow[rowCol];
                  }
                }
              }
              this.dataRows.push(dataRow);
            } else {
              for (let i = 0; i < this.config.table.dataCols.length; i++) {
                dataRow[this.config.table.dataCols[i].key] = apiData[this.config.dataArrayKey][r][this.config.table.dataCols[i].key];
              }
              if (this.config.dataArraySplitColKey !== undefined) {
                let arrayObjToSplit = apiData[this.config.dataArrayKey][r][this.config.dataArraySplitColKey];
                for (let i = 0; i < this.config.table.dataArraySplitCols.length; i++) {
                  for (let j = 0; j < arrayObjToSplit.length; j++) {
                    if (this.config.table.dataArraySplitCols[i].title === arrayObjToSplit[j][this.config.table.dataArraySplitCols[i].arrayMappingKey]) {
                      dataRow[this.config.table.dataArraySplitCols[i].key] = arrayObjToSplit[j][this.config.table.dataArraySplitCols[i].arrayMappingValue];
                      break;
                    }
                  }
                }
              }
              // if (this.config.table.drillDownCols !== undefined) {
              //   for (let i = 0; i < this.config.table.drillDownCols.length; i++) {
              //     dataRow[this.config.table.drillDownCols[i].drillDownDetailsKey]
              //           = apiData[this.config.dataArrayKey][r][this.config.table.drillDownCols[i].drillDownDetailsKey];
              //   }
              // }
              for (const rowCol in dataRow) {
                if (rowCol in types) {
                  if (types[rowCol] === 'int') {
                    dataRow[rowCol] = parseInt(dataRow[rowCol]);
                  } else if (types[rowCol] === 'float') {
                    dataRow[rowCol] = parseFloat(dataRow[rowCol]);
                  }
                  if (isNaN(dataRow[rowCol])) {
                    dataRow[rowCol] = null;
                  }
                  if (rowCol + 'Export' in dataRow) {
                    dataRow[rowCol + 'Export'] = dataRow[rowCol];
                  }
                }
              }
              this.dataRows.push(dataRow);
            }
          }
          // transform data rows to add in export data
          for (const rowKey in this.dataRows) {
            if (this.dataRows.hasOwnProperty(rowKey)) {
              const row = this.dataRows[rowKey];
              for (const rowCol in row) {
                if (rowCol in row) {
                  const columnInfoExport = this.getColumnInfo(this.dataCols, rowCol + 'Export');
                  const columnInfo = this.getColumnInfo(this.dataCols, rowCol);
                  let value = row[rowCol];
                  if (columnInfo !== null && columnInfo.renderHitMissStyle) {
                    if (value === 'Yes' || value === 'hit') {
                      value = 'Hit';
                    } else if (value === 'No' || value === 'miss') {
                      value = 'Miss';
                    }
                  }
                  if (columnInfo !== null && this.config.pageTitle == 'Open TSB Details' && columnInfo.ref === 'tsbduedate') {
                    value = new Date(value);
                    if (value instanceof Date && !isNaN(value.valueOf())) {
                      let month = '' + (value.getMonth() + 1);
                      let day = '' + value.getDate();
                      const year = value.getFullYear();
                      if (month.length < 2) {
                        month = '0' + month;
                      }
                      if (day.length < 2) {
                        day = '0' + day;
                      }
                      value = year + '-' + month + '-' + day;
                    } else {
                      value = '';
                    }
                  }
                  if (columnInfo !== null && this.config.pageTitle == 'Orders' && columnInfo.ref === 'orderdate') {
                    value = new Date(value);
                    if (value instanceof Date && !isNaN(value.valueOf())) {
                      let month = '' + (value.getMonth() + 1);
                      let day = '' + value.getDate();
                      const year = value.getFullYear();
                      if (month.length < 2) {
                        month = '0' + month;
                      }
                      if (day.length < 2) {
                        day = '0' + day;
                      }
                      value = year + '-' + month + '-' + day;
                    } else {
                      value = '';
                    }
                  }
                  if (columnInfo !== null
                    && this.config.pageTitle === 'Uptime Details'
                    && (columnInfo.ref === 'aggdhrsdown' || columnInfo.ref === 'aggdaysdown')) {
                    if (value === '' || value === null) { // || value == 0 || value == 0.0) {
                      value = null;
                    }
                  }
                  let newValue = value;
                  if (columnInfoExport !== null && columnInfoExport.export === true) {
                    if (columnInfoExport.renderAgedYNStyle) {
                      if (value == 1 || value === true || value === 'true' || value === 'Y') {
                        newValue = 'Y';
                      } else if (value != 1 && value !== true && value !== 'true' && value !== 'Y') {
                        newValue = 'N';
                      }
                    }
                    this.dataRows[rowKey][rowCol] = value;
                    this.dataRows[rowKey][rowCol + 'Export'] = newValue;
                  }
                  /*if (columnInfo !== null) {// && columnInfo.) {// for changing non excel grid values
                    if (value == 1 || value === true) {
                      this.dataRows[rowKey][rowCol] = 'Y';
                    } else if (value == 0 || value === false) {
                      this.dataRows[rowKey][rowCol] = 'N';
                    }
                  } else if (rowCol in types) {
                    if (types[rowCol] === 'int') {
                      this.dataRows[rowKey][rowCol] = parseInt(dataRow[rowCol]);
                    } else if (types[rowCol] === 'float') {
                      this.dataRows[rowKey][rowCol] = parseFloat(dataRow[rowCol]);
                    }
                  }*/
                }
              }
            }
          }
          this.updateGrid();
        }
      });
    }
  }

  getColumnInfo(dataCols, rowCol) {
    for (let i = 0; i < dataCols.length; i++) {
      if (dataCols[i].ref === rowCol) {
        return dataCols[i];
      }
    }
    return null;
  }

  public getDrillDownDetailsLinkWithParams(colName: any, drillDownLink: any, dataItem: any) {
    let hrefRouterLink = '';
    if (colName === 'hotsheets_survey_comments' || colName === 'customer_nps_survey_comments') {
      hrefRouterLink = drillDownLink + "/" + dataItem['customernumber'] + "/" + dataItem['country'] + "/" + dataItem['interviewdate'];
    } else if (colName == 'uptime_customer_details') {
      hrefRouterLink = drillDownLink + "/" + dataItem['custid'] + "/" + dataItem['cust'] + "/" + dataItem['country'];
    } else if (colName == 'customer_order_details' || colName == 'amb_customer_order_details') {
      hrefRouterLink = drillDownLink + "/" + dataItem['customer'] + "/" + dataItem['ordernumber'] + "/" + dataItem['orderstatus'];
    } else if (colName == 'rnps_survey_comments') {
      hrefRouterLink = drillDownLink + "/" + dataItem['value'] + "/" + (dataItem['customername'] == null ? 'NA' : encodeURIComponent(dataItem['customername']))
                + "/" + dataItem['country'] + "/" + dataItem['interviewdate'] + "/" + dataItem['resolved'];
    } else {
      hrefRouterLink = drillDownLink;
    }

    return hrefRouterLink;
  }

  public showDetailsLink(colName: any, dataItem: any) {
    if (colName == 'rnps_survey_comments') {
      if (dataItem['detractor'] != undefined && dataItem['detractor'].length > 0) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }

}
