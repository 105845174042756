import {Component, Input, OnInit} from '@angular/core';
import {NavbarBuilderService} from '../navbar-builder.service';
import {NavFilterSelection} from '../../configuration/nav-filter-selection';

@Component({
  selector: 'app-parameter-switches',
  templateUrl: './parameter-switches.component.html',
  styleUrls: ['./parameter-switches.component.scss']
})
export class ParameterSwitchesComponent implements OnInit {
  navFilterSelection: NavFilterSelection;

  @Input() displayTitles;

  constructor(private navbarBuilderService: NavbarBuilderService) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();
  }
}
