import { Component, OnInit, Input, Renderer2 } from '@angular/core';
import {GridComponent} from '../../details/grid.component';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-data-trend-table',
  templateUrl: './data-trend-table.component.html',
  styleUrls: ['./data-trend-table.component.scss']
})
export class DataTrendTableComponent extends GridComponent implements OnInit {
  @Input() chartData;
  dataCols = [];
  showContextMenu = false;
  offset: any;
  selectedSn;
  selectedPl;
  gsrUrl = environment.gsrUrl;
  private documentClickSubscription: any;

  constructor(private renderer: Renderer2) {
    super();
    this.onCellClick = this.onCellClick.bind(this);
    this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
      this.showContextMenu = false;
    });
  }

  ngOnInit(): void {
    this.dataCols = [
      {ref: 'pl', description: '', width: 100, hidden: true},
      {ref: 'plName', description: 'Product', width: 100},
      {ref: 'sn', description: 'SN', width: 100},
      {ref: 'ticketNum', description: 'Ticket #', width: 130},
      {ref: 'dateOpened', description: 'Open Date', width: 100},
      {ref: 'ticketDesc', description: 'Description', width: 400}
    ];
    // for (const colKey in this.chartData.data) {
    //   if (this.chartData.data.hasOwnProperty(colKey)) {
    //     const col = this.chartData.data[colKey];
    //     this.dataCols.push({
    //       ref: colKey,
    //       description: col,
    //       width: 100
    //     });
    //   }
    // }
    this.dataRows = this.chartData.data;
    this.updateGrid();
  }

  public onCellClick({dataItem, column, type, originalEvent}): void {
    if (type === 'click' && column.field === 'sn') {
      originalEvent.preventDefault();
      this.showContextMenu = true;
      this.selectedPl = dataItem.pl;
      this.selectedSn = dataItem.sn;
      this.offset = { left: originalEvent.pageX, top: originalEvent.pageY };
    } else {
      this.showContextMenu = false;
    }
  }
}
