import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {process, SortDescriptor} from '@progress/kendo-data-query';
import {RequestState} from '../../api/request-handler';
import {faCheck} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-autoselect-list',
  templateUrl: './autoselect-list.component.html',
  styleUrls: ['./autoselect-list.component.scss']
})
export class AutoselectListComponent implements OnInit, OnChanges {
  RequestState = RequestState;
  @Input() hasSearch = true;
  @Input() height = 300;
  @Input() state = RequestState.Waiting;
  @Input() data;
  @Input() keyField;
  @Input() displayField;
  @Input() subDisplayField;
  @Input() selectedOption;
  @Input() sort = true;
  @Input() useCheckIcon = false;
  @Output() itemSelect = new EventEmitter();
  faCheck = faCheck;

  result = {
    data: []
  };

  public view: any[] = [];

  constructor() { }

  ngOnInit(): void {
    this.updateResults('');
    this.loadMore();
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(JSON.stringify(this.state), RequestState.Complete, changes, JSON.stringify(this.data));
    this.updateResults('');
  }

  onValueChange(query) { // on search textbox change
    this.updateResults(query);
  }

  onListItemSelect(key) {
    this.selectedOption = key;
    this.itemSelect.emit(this.selectedOption);
  }

  updateResults(query) {
    // apply filters
    this.result = process(this.data, {
      sort: ((this.sort) ? [{ field: 'label', dir: 'asc' }] : null),
      filter: {
        logic: 'and',
        filters: [
          {field: 'label', operator: 'contains', value: query},
        ]
      }
    });
    // put selected items from main array
    const selectedItems = [];
    for (let key = 0; key < this.result.data.length; key++) {
      const item = this.result.data[key];
      if (item[this.keyField] === this.selectedOption) {
        selectedItems.push(item);
        this.result.data.splice(key, 1);
      }
    }
    // add selected items back at top
    for (let key = selectedItems.length - 1; key >= 0; key--) {
      this.result.data.unshift(selectedItems[key]);
    }
    // console.log(this.result);
    // reset view
    this.view = [];
    this.loadMore();
  }

  loadMore(): void {
    const next = this.view.length;
    this.view = [
      ...this.view,
      ...this.result.data.slice(next, next + 25)
    ];
  }
}
