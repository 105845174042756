import { Component, OnInit, OnDestroy } from '@angular/core';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ApiService} from '../../api/api.service';
import {CtServiceService} from '../ct-service.service';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {RequestState} from '../../api/request-handler';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';
import {SelectedTabService} from '../../dashboards/selected-tab.service';
import {DashboardOptions} from '../../configuration/nav-filter-selection';
import {SelectedManagerService} from '../selected-manager.service';
import {AuthenticationService} from '../../authentication/authentication.service';

interface CustomerHealthGridRow {
  // styles are string of color or null
  countryCode: string;
  customerNum: string;
  customerName: string;
  uniqueCustomerKey: string;
  countryName: string;
  area: string;
  overAllHealthScore: number;
  overAllHealthScoreStyle: string;
  tnpsRawScore: number;
  tnpsRawScoreStyle: string;
  snCountIA: number;
  snCountCC: number;
  testVolumeIA: number;
  testVolumeIAScoreStyle: string;
  testVolumeCC: number;
  testVolumeCCScoreStyle: string;
  testCompletionPercent: number;
  testCompletionPercentScoreStyle: string;
  hardStopCount: number;
  hardStopCountScoreStyle: string;
  qcFailPercent: number;
  qcFailPercentScoreStyle: string;
  retestPercent: number;
  retestPercentScoreStyle: string;
  openTicketCount: number;
}

@Component({
  selector: 'app-customer-profile-info',
  templateUrl: './customer-profile-info.component.html',
  styleUrls: ['./customer-profile-info.component.scss']
})
export class CustomerProfileInfoComponent implements OnInit, OnDestroy {
  state = null;
  apiUrl = 'cua/customer_health_metrics';
  apiData = null;
  colorLegend = null;
  npsLegend = null;
  customerId = null;
  selectedCustomerData = null;
  dataCols = [];
  customerMyCRLLinkReady = false;
  myCRLCustomerId = null;
  // tooltips
  tooltips = {
    overAllHealthScore: false,
    tnpsRawScore: false,
    snCountIA: false,
    snCountCC: false,
    testVolumeIA: false,
    testVolumeCC: false,
    testCompletionPercent: false,
    hardStopCount: false,
    qcFailPercent: false,
    retestPercent: false,
    openTicketCount: false
  };
  helpWindowTitle = '';
  opened = false;
  helpActions = [{ text: 'Previous' }, { text: 'Next', primary: true }];
  actionsLayout = 'normal';
  faInfoCircle = faInfoCircle;
  DashboardOptions = DashboardOptions;
  refreshIndexes = [];
  customerChangeEventIndex = null;
  requestHandler;
  stateChangeHandler;
  countryIdsByName;

  constructor(
    private apiService: ApiService,
    private refreshHandler: RefreshHandlerService,
    private personaService: PersonaService,
    private ctService: CtServiceService,
    private selectedTabService: SelectedTabService,
    private selectedManagerService: SelectedManagerService,
    private authService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.requestHandler = this.apiService.get(this.apiUrl);
    // this.ctService.onCustomerSelectionChange(() => {
    //   requestHandler.sendRequest(this.apiUrl);
    // });
    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest(this.apiUrl);
      },
      this.requestHandler.getRelativeParams(this.apiUrl)
    ));

    // no need to send request here as chart tile has already done so
    this.stateChangeHandler = (state) => {
      this.state = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        this.apiData = JSON.parse(response.body);
        this.buildLegendInfo(this.apiData);

        this.dataCols = [
          'uniqueCustomerKey', 'acctEntityName', 'customerName', 'customerNum', 'countryName',
          'overAllHealthScore', 'tnpsRawScore', 'snCountIA', 'snCountCC', 'testVolumeIA',
          'testVolumeCC', 'testCompletionPercent', 'hardStopCount', 'hardStopScaled', 'qcFailPercent',
          'retestPercent', 'openTicketCount'
        ];

        this.customerId = this.ctService.getCustomerId();
        this.selectedCustomerData = null;
        for (const apiDataRow of this.apiData.data) {
          if (apiDataRow.uniqueCustomerKey === this.customerId) {
            this.selectedCustomerData = {};
            for (const dataCol of this.dataCols) {
              let style = 'white';
              let textcolor = 'black';
              if (dataCol + 'Style' in apiDataRow) {
                style = apiDataRow[dataCol + 'Style'];
                if (style === 'red') {
                  textcolor = 'white';
                }
              }
              if (dataCol + 'ScoreStyle' in apiDataRow) {
                style = apiDataRow[dataCol + 'ScoreStyle'];
                if (style === 'red') {
                  textcolor = 'white';
                }
              }
              if (style === 'red') {
                style = '#FF6347';
              }
              if (style === '#0ee30e') {
                style = '#52B84D';
              }
              this.selectedCustomerData[dataCol] = {
                value: apiDataRow[dataCol],
                bgcolor: style,
                color: textcolor
              };
            }
          }
        }

        if (this.customerChangeEventIndex !== null) {
          this.ctService.clearCustomerSelectionChangeEvent(this.customerChangeEventIndex);
        }
        this.customerChangeEventIndex = this.ctService.onCustomerSelectionChange(() => {
          const aohCustomerId = this.personaService.getPersonaOption(PersonaTypes.Customer);

          const requestHandler2 = this.apiService.get('cua/get_mycrl_customer_id');
          if (aohCustomerId !== 'all' && typeof aohCustomerId === 'string') {
            const aohCustomerIdParts = aohCustomerId.split('|');
            requestHandler2.sendRequest('cua/get_mycrl_customer_id', {customer_id: aohCustomerIdParts[1]});
            this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
              (index) => {
                requestHandler2.sendRequest(this.apiUrl);
              },
              requestHandler2.getRelativeParams(this.apiUrl)
            ));
          }
          // console.log('a1', requestHandler2.getRelativeParams(this.apiUrl));
          // 20211028 - a1  Array(6) [ "ct_persona_type", "ct_persona_value",
          // "cua_primary", "cua_secondary", "cua_tertiary", "product_set_number" ]

          // no need to send request here as chart tile has already done so
          requestHandler2.onStateChange((state2) => {
            this.state = state;
            if (state2 === RequestState.Complete) {
              const response2 = requestHandler2.getResponse();
              if (response2.body !== '') {
                this.myCRLCustomerId = JSON.parse(response2.body);

                const countryRequestHandler = this.apiService.get('/geography_service');
                countryRequestHandler.sendRequest('/geography_service');
                countryRequestHandler.onStateChange((countryState) => {
                  if (state === RequestState.Complete) {
                    const countryResponse = countryRequestHandler.getResponse();
                    const countryData = JSON.parse(countryResponse.body);

                    this.countryIdsByName = {};
                    if (countryData.countries) {
                      for (const location of countryData.countries) {
                        this.countryIdsByName[location.name] = location.id;
                      }
                    }
                    this.customerMyCRLLinkReady = true;
                  }
                });
              }
            }
          });
        });
      }
    };

    this.requestHandler.onStateChange(this.stateChangeHandler);
    this.requestHandler.sendRequest(this.apiUrl);
  }

  ngOnDestroy(): void {
    for (const index of this.refreshIndexes) {
      this.refreshHandler.cancelRefreshEvent(index);
    }
    this.ctService.clearCustomerSelectionChangeEvent(this.customerChangeEventIndex);
    this.customerChangeEventIndex = null;

    this.requestHandler.removeStateListener(this.stateChangeHandler);
  }

  buildLegendInfo(apiData) {
    this.colorLegend = {
      title: {label: '', bgcolor: '', color: 'black', format: 'num1'},
      items: []
    };
    for (const apiLegendItem of apiData.legends['Overall Scores']) {
      let textcolor = 'black';
      if (apiLegendItem.color === 'red') {
        textcolor = 'white';
      }
      const legendItem = {
        label: Math.floor(apiLegendItem.minVal * 100) + '-' + Math.floor(apiLegendItem.maxVal * 100) + '%',
        count: 0,
        pct: apiLegendItem.maxVal * 100,
        bgcolor: apiLegendItem.color,
        color: textcolor
      };
      this.colorLegend.items.push(legendItem);
      if (this.colorLegend.title.label === '') {
        this.colorLegend.title.label = apiLegendItem.legendTitle;
      }
    }
    this.npsLegend = {
      title: {label: '', bgcolor: '', color: 'black', format: 'num1'},
      items: []
    };
    for (const apiLegendItem of apiData.legends['tNPS Scores']) {
      const legendItem = {
        label: Math.floor(apiLegendItem.minVal) + '-' + Math.floor(apiLegendItem.maxVal),
        count: 0,
        pct: apiLegendItem.maxVal,
        bgcolor: apiLegendItem.color,
        color: 'black'
      };
      this.npsLegend.items.push(legendItem);
      if (this.npsLegend.title.label === '') {
        this.npsLegend.title.label = apiLegendItem.legendTitle;
      }
    }
  }

  updateTooltipTitle(type) {
    switch (type) {
      case 'overAllHealthScore':
        this.helpWindowTitle = 'Overall Health';
        break;
      case 'tnpsRawScore':
        this.helpWindowTitle = 'tNPS Rating';
        break;
      case 'snCountIA':
        this.helpWindowTitle = 'IA';
        break;
      case 'snCountCC':
        this.helpWindowTitle = 'CC';
        break;
      case 'testVolumeIA':
        this.helpWindowTitle = 'Average Test Volume IA';
        break;
      case 'testVolumeCC':
        this.helpWindowTitle = 'Average Test Volume CC';
        break;
      case 'testCompletionPercent':
        this.helpWindowTitle = 'Test Completion';
        break;
      case 'hardStopCount':
        this.helpWindowTitle = 'Hard Stops';
        break;
      case 'qcFailPercent':
        this.helpWindowTitle = 'Controls Out of Range';
        break;
      case 'retestPercent':
        this.helpWindowTitle = 'Repeat Tests';
        break;
      case 'openTicketCount':
        this.helpWindowTitle = 'Open Tickets';
        break;
    }
  }

  toggleTooltip(type, changeTo = null) {
    this.opened = true;
    if (changeTo === null) {
      this.tooltips[type] = !this.tooltips[type];
    } else {
      this.tooltips[type] = changeTo;
    }
    if (this.tooltips[type]) {
      for (const type1 in this.tooltips) {
        if (type1 !== type) {
          this.tooltips[type1] = false;
        }
      }
    }
    this.updateTooltipTitle(type);
  }

  public onAction(action): void {
    let currentTooltip = null;
    for (const type in this.tooltips) {
      if (type in this.tooltips) {
        if (this.tooltips[type]) {
          currentTooltip = type;
        }
      }
    }
    if (currentTooltip !== null) {
      const keys = Object.keys(this.tooltips);
      const activeTooltipLocation = keys.indexOf(currentTooltip);
      let newKey;
      if (action.text === 'Previous') {
        if (activeTooltipLocation - 1 < 0) {
          newKey = keys[keys.length - 1];
        } else {
          newKey = keys[activeTooltipLocation - 1];
        }
      } else if (action.text === 'Next') {
        if (activeTooltipLocation + 1 >= keys.length) {
          newKey = keys[0];
        } else {
          newKey = keys[activeTooltipLocation + 1];
        }
      }
      this.tooltips[currentTooltip] = false;
      this.tooltips[newKey] = true;
      this.updateTooltipTitle(newKey);
    }
  }

  public close() {
    this.opened = false;
  }

  dashboardChange(dashboardOption) {
    this.authService.onAuth(() => {
      const userInfo = this.authService.getUserInfo();
      const aohCustomerLabel = this.personaService.getPersonaLabel(PersonaTypes.Customer);
      this.personaService.setPersonaOption(PersonaTypes.GEO, this.countryIdsByName[this.selectedCustomerData.countryName.value], this.selectedCustomerData.countryName.value);
      this.ctService.setReturnCustomer(this.personaService.getPersonaOption(PersonaTypes.Customer));
      // console.log('switch to myCrl', 'returnCustomerSetTo', this.personaService.getPersonaOption(PersonaTypes.Customer));
      // this.personaService.setPersonaOption(PersonaTypes.Customer, this.myCRLCustomerId, aohCustomerLabel);
      this.personaService.setPersonaOption(PersonaTypes.Customer, this.myCRLCustomerId, aohCustomerLabel);
      this.selectedTabService.selectTab(dashboardOption);
      // this.refreshHandlerService.triggerCallbacks();
    });
  }
}
