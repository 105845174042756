import { Injectable } from '@angular/core';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {RequestState} from '../../api/request-handler';

@Injectable({
  providedIn: 'root'
})
export class DetailChoicesService {
  private filterSelections = {};

  // used to make detail filters for customers and product lines talk
  callbacks = {};
  displayCallbacks = [];

  constructor(protected refreshHandlerService: RefreshHandlerService) {}

  setFilter(key, value, holdUpdate = false) {
    // console.log('setFilter', key, value);
    let triggerCallbacks = false;
    if (this.filterSelections[key] !== value) {
      triggerCallbacks = true;
    }
    this.filterSelections[key] = value;
    // console.log('set', key, triggerCallbacks, this.filterSelections[key], value);
    if (triggerCallbacks && !holdUpdate) {
      this.refreshHandlerService.triggerCallbacks([key]);
      // console.log('triggerCallbacks');
    }
    this.callDisplayCallbacks();
  }

  getFilter(key) {
    if (this.filterSelections.hasOwnProperty(key)) {
      return this.filterSelections[key];
    }
    return null;
  }

  isFilterSet(key) {
    if (this.filterSelections.hasOwnProperty(key)) {
      return true;
    }
    return false;
  }

  getKeys() {
    const keys = [];
    for (const key in this.filterSelections) {
      if (this.filterSelections.hasOwnProperty(key)) {
        keys.push(key);
      }
    }
    return keys;
  }

  getFilterSelections() {
    return this.filterSelections;
  }

  applyFilters() {
    // console.log(this.filterSelections, this.getKeys());
    this.refreshHandlerService.triggerCallbacks(this.getKeys());
    return this;
  }

  clearFilters() {
    for (const key of this.getKeys()) {
      this.setFilter(key, null);
    }
    this.callUpdateListenerByKey('customerId');
    this.callUpdateListenerByKey('productLineId');
    this.callUpdateListenerByKey('accountId');
    this.callUpdateListenerByKey('assayId');
    this.callUpdateListenerByKey('diseaseStateId');
    this.callUpdateListenerByKey('categorySwitches');
    return this;
  }

  public onDisplayCallbacks(callback) {
    this.displayCallbacks.push(callback);
    return this;
  }

  callDisplayCallbacks() {
    for (const callback of this.displayCallbacks) {
      callback();
    }
    return this;
  }

  public onUpdate(key, callback) {
    this.callbacks[key] = callback;
    return this;
  }

  public clearUpdateListener(key) {
    delete this.callbacks[key];
    return this;
  }

  public callUpdateListenerByKey(key, data = null) {
    for (const keyI in this.callbacks) {
      if (this.callbacks.hasOwnProperty(keyI) && keyI === key) {
        const callback = this.callbacks[keyI];
        callback(data);
      }
    }
    return this;
  }
}
