import { Injectable } from '@angular/core';
import {ApiMapping} from '../configuration/api-mapping';
import {ParamSource, ParamMapping} from '../configuration/param-mapping';
import {AuthenticationService} from '../authentication/authentication.service';
import {UserInfo} from '../domain/user-info';
import {FilterChoicesService} from '../filter_selection/filter-choices.service';
import {DefaultParams} from '../configuration/default-params';
import {PersonaService} from './persona.service';
import {PreferenceSelectionService} from '../preferences/preference-selection.service';
import {DetailChoicesService} from '../filter_selection/details/detail-choices.service';
import {ParamLogicService} from '../configuration/param-logic.service';
import {BrParamsService} from '../business_review/br-params.service';

@Injectable({
  providedIn: 'root'
})
export class ParameterResolverService {
  constructor(
    private auth: AuthenticationService,
    private filterChoices: FilterChoicesService,
    private personaService: PersonaService,
    private preferenceSelectionService: PreferenceSelectionService,
    private detailChoices: DetailChoicesService,
    private paramLogic: ParamLogicService,
    private brParamsService: BrParamsService
  ) { }

  filtersReady(route, debug = false) {
    if (!(route in ApiMapping)) {
      console.error('parameter-resolver: route "' + route + '" not found in ApiMapping.');
    }
    const paramsToInclude = ApiMapping[route].params_to_include;
    for (const paramKey of paramsToInclude) {
      if (!ParamMapping[paramKey]) {
        console.error(route + ': Missing Param Mapping: ' + paramKey);
      } else {
        if (!('sourceKey' in ParamMapping[paramKey])) {
          console.error(route + ': See param-mapping.ts, for object reference of: ' + paramKey + ' (must have a property of sourceKey)');
        }
        if (!('apiParamName' in ParamMapping[paramKey])) {
          console.error(route + ': See param-mapping.ts, for object reference of: ' + paramKey + ' (must have a property of apiParamName)');
        }
      }
      const sourceParamKey = ParamMapping[paramKey].sourceKey;
      if (ParamMapping[paramKey].source === ParamSource.FilterChoices
        && !this.filterChoices.isFilterSet(sourceParamKey)) {
        if (debug) {
          console.warn(route + ': Filter not yet set for "' + sourceParamKey + '"');
        }
        return false;
      }
      if (ParamMapping[paramKey].source === ParamSource.DetailChoices
        && !this.detailChoices.isFilterSet(sourceParamKey)) {
        if (debug) {
          console.warn(route + ': Detail Filter not yet set for "' + sourceParamKey + '"');
        }
        return false;
      }
      if (ParamMapping[paramKey].source === ParamSource.ParamLogic
        && !this.paramLogic.isParamReady(sourceParamKey)) {
        if (debug) {
          console.warn(route + ': Param Logic not yet available for "' + sourceParamKey + '"');
        }
        return false;
      }
      const userInfo: UserInfo = this.auth.getUserInfo();
      if (ParamMapping[paramKey].source === ParamSource.AuthUserInfo
        && (!userInfo || !(sourceParamKey in userInfo))) {
        if (debug) {
          console.warn(route + ': Either User Auth as not yet been loaded or "' + sourceParamKey + '" is not found in Auth UserInfo');
        }
        return false;
      }
      if (ParamMapping[paramKey].source === ParamSource.Preferences
        && !this.preferenceSelectionService.isPreferenceKeyValid(sourceParamKey)) {
        if (debug) {
          console.warn(route + ': Either Preferences have not yet been loaded or "' + sourceParamKey + '" is not found in Preferences');
        }
        return false;
      }
    }
    return true;
  }
  getParameter(parameter, route?) {
    const userInfo: UserInfo = this.auth.getUserInfo();
    if (!ParamMapping[parameter]) {
      console.error('Missing Param Mapping: ' + parameter);
    }
    const sourceParamKey = ParamMapping[parameter].sourceKey;
    switch (ParamMapping[parameter].source) {
      case ParamSource.AuthUserInfo:
        if (userInfo) {
          return userInfo[sourceParamKey];
        }
        break;
      case ParamSource.FilterChoices:
        return this.filterChoices.getFilter(sourceParamKey);
        break;
      case ParamSource.DetailChoices:
        return this.detailChoices.getFilter(sourceParamKey);
        break;
      case ParamSource.ParamLogic:
        return this.paramLogic.get(sourceParamKey);
        break;
      case ParamSource.ApiMapping:
        return ApiMapping[route][sourceParamKey];
        break;
      case ParamSource.DefaultParams:
        return DefaultParams[sourceParamKey];
        break;
      case ParamSource.Persona:
        // @todo: implement check to ensure must be valid PersonaTypes property
        return this.personaService.getPersonaOption(sourceParamKey);
        break;
      case ParamSource.Preferences:
        return this.preferenceSelectionService.getPreference(sourceParamKey);
        break;
      case ParamSource.BrParams:
        return this.brParamsService.get(sourceParamKey);
        break;
    }
  }
  getParametersForRoute(route, parameters) {
    const finalParams = {};
    const userInfo: UserInfo = this.auth.getUserInfo();
    const paramsToInclude = ApiMapping[route].params_to_include;
    for (const paramKey of paramsToInclude) {
      if (!ParamMapping[paramKey]) {
        console.error('Missing Param Mapping: ' + paramKey);
      }
      const source = ParamMapping[paramKey].source;
      if ('apiParamName' in ParamMapping[paramKey]) {
        const apiParamName = ParamMapping[paramKey].apiParamName;
        if (source === ParamSource.ApiCallInstance) {
          const sourceParamKey = ParamMapping[paramKey].sourceKey;
          finalParams[apiParamName] = parameters[sourceParamKey];
        } else {
          const paramValue = this.getParameter(paramKey, route);
          if (paramKey !== 'br_show_sql' || (paramKey === 'br_show_sql' && paramValue === true)) {
            finalParams[apiParamName] = paramValue;
          }
        }
        if (finalParams[apiParamName] === null && ParamMapping[paramKey].hasOwnProperty('default')) {
          finalParams[apiParamName] = ParamMapping[paramKey].default;
        }
      }
    }
    // console.log(route, finalParams);

    return finalParams;
  }
}
