import { Component, Input } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {ToggleSwitches} from '../toggle-switches';

@ComponentLookup('DetailCategorySwitchesFilterComponent')
@Component({
  selector: 'app-detail-category-switches-filter',
  templateUrl: './detail-category-switches-filter.component.html',
  styleUrls: ['./detail-category-switches-filter.component.scss']
})
export class DetailCategorySwitchesFilterComponent extends ToggleSwitches {
  @Input() metricId;

  updateListener = 'categorySwitches';
  config = [
    {
      key: 'detailIsReagentFilter',
      label: 'Reagent',
      defaultValue: true
    },
    {
      key: 'detailIsFieldServiceContractFilter',
      label: 'Field Service Contract',
      defaultValue: true
    },
    {
      key: 'detailIsOtherFilter',
      label: 'Other',
      defaultValue: true
    }
  ];
}
