import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import {DashboardOptions, NavFilterSelection} from '../../configuration/nav-filter-selection';
import {
  faDesktop,
  faBorderAll,
  faBookmark,
  faCog,
  faGlobeAmericas,
  faSearch,
  faUser,
  faUsers,
  faFilter,
  faVial,
  faClinicMedical,
  faGripHorizontal,
  faUserTie,
  faLaptopMedical,
  faMortarPestle,
  faSyringe,
  faHandHoldingMedical,
  faTachometerAlt,
  faMicroscope,
} from '@fortawesome/free-solid-svg-icons';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {AuthenticationService} from '../../authentication/authentication.service';
import {SelectedTabService} from '../../dashboards/selected-tab.service';

@Component({
  selector: 'app-br-navbar',
  templateUrl: './br-navbar.component.html',
  styleUrls: ['./br-navbar.component.scss']
})
export class BrNavbarComponent implements OnInit {
  isVisible = true;
  navFilterSelection: NavFilterSelection = null;
  DashboardOptions = DashboardOptions;
  faDesktop = faDesktop;
  faBorderAll = faBorderAll;
  // faGlobeAmericas = faGlobeAmericas;
  // faSearch = faSearch;
  // faBookmark = faBookmark;
  faCog = faCog;
  // faUsers = faUsers;
  // faUser = faUser;
  // faFilter = faFilter;
  // faVial = faVial;
  // faClinicMedical = faClinicMedical;
  // faGripHorizontal = faGripHorizontal;
  // faUserTie = faUserTie;
  // faLaptopMedical = faLaptopMedical;
  // faMortarPestle = faMortarPestle;
  // faSyringe = faSyringe;
  // faHandHoldingMedical = faHandHoldingMedical;
  status = false;
  // isAmbTabVisible = false;
  // lastSelectedDashboardTabInfo = null;
  // faMicroscope = faMicroscope;
  // faTachometerAlt = faTachometerAlt;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private refreshHandlerService: RefreshHandlerService,
    private authService: AuthenticationService,
    // private selectedTabService: SelectedTabService,
    // private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    // @todo: understand the setTimeout hack used here
    // https://indepth.dev/everything-you-need-to-know-about-the-expressionchangedafterithasbeencheckederror-error/
    // https://blog.angular-university.io/angular-debugging/
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection: NavFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();

    this.authService.onAuth(() => {
      const userInfo = this.authService.getUserInfo();

      // this.isAmbTabVisible = (userInfo.isAMB || userInfo.isAMBManager || userInfo.isSuperUser);
    });
    // this.selectedTabService.onTabChange(() => {
    //   this.lastSelectedDashboardTabInfo = this.selectedTabService.getLastSelectedTabInfo();
    //   this.cdRef.detectChanges();
    // });
    // this.lastSelectedDashboardTabInfo = this.selectedTabService.getLastSelectedTabInfo();
    // this.cdRef.detectChanges();
    this.navbarBuilderService.getNavbarIsVisibleSubject().subscribe(
      (isVisible) => {
        this.isVisible = isVisible;
      }
    );
  }

  clickEvent() {
    this.status = !this.status;
  }
  closeSidebar() {
    this.status = false;
  }
  dashboardChange(dashboardOption) {
    // this.selectedTabService.selectTab(dashboardOption);
    // this.refreshHandlerService.triggerCallbacks();
  }
}
