import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import { SurveyCommentPages } from '../survey-comment-details/survey-comment-pages';

@Component({
  selector: 'app-rnps-survey-comment-details',
  templateUrl: './rnps-survey-comment-details.component.html',
  styleUrls: ['./rnps-survey-comment-details.component.scss']
})
export class RnpsSurveyCommentDetailsComponent implements OnInit {

  stylePages = SurveyCommentPages;
  config = null;
  apiDataRecord = null;
  public state = RequestState.Inactive;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    private refreshHandler: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      this.route.paramMap.subscribe(params => {

        let rnpsScore = params.get('score');
        let customerName = params.get('customer');
        if (customerName != undefined && customerName == 'NA') {
          customerName = null;
        } else {
          customerName = decodeURIComponent(customerName);
        }
        let country = params.get('country');
        let interviewDate = params.get('interviewdate');
        let rnpsCategory = params.get('category');

        const requestHandler = this.apiService.get(this.config.apiRequestHandler);
        requestHandler.onStateChange((state) => {
          this.state = state;
        });
        requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
        this.refreshHandler.onRefreshEvent(
          (index) => {
            requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
          },
          requestHandler.getRelativeParams(this.config.apiRequestHandler)
        );

        // no need to send request here as chart tile has already done so
        requestHandler.onStateChange((state) => {
          if (state === RequestState.Complete) {
            const response = requestHandler.getResponse();
            const apiData = JSON.parse(response.body);

            // find the correct apiData record for the routing params
            for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
              let currentRec = apiData[this.config.dataArrayKey][r];
              if (currentRec['value'] == rnpsScore && currentRec['customername'] == customerName 
                    && currentRec['country'] == country && currentRec['interviewdate'] == interviewDate
                    && currentRec['resolved'] == rnpsCategory) {
                this.apiDataRecord = currentRec;
                break;
              }
            } 
          }             
        });
      });
    }
  }
}
