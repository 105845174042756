import {Component, OnDestroy, OnInit, Input} from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {RequestState} from '../../../api/request-handler';
import {ApiService} from '../../../api/api.service';
import {DetailChoicesService} from '../detail-choices.service';

@ComponentLookup('DetailProductLineFilterComponent')
@Component({
  selector: 'app-detail-product-line-filter',
  templateUrl: './detail-product-line-filter.component.html',
  styleUrls: ['./detail-product-line-filter.component.scss']
})
export class DetailProductLineFilterComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  rawProductLines = [];
  selectedItem;
  requestHandler;
  @Input() metricId;

  constructor(
    private apiService: ApiService,
    private detailChoicesService: DetailChoicesService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('/productline/details');
    this.requestHandler.sendRequest('/productline/details');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        if (apiData.hasOwnProperty('productlines')) {
          const buildData = [];
          for (const productline of apiData.productlines) {
            buildData.push({
              label: productline.productname,
              key: productline.productlineid
            });
          }
          this.data = buildData;
        }
        this.rawProductLines = apiData.productlines;
      }
    });
    this.selectedItem = this.detailChoicesService.getFilter('detailProductLineFilter');
    this.detailChoicesService.onUpdate('customerId', (customerId) => {
      // filter list contents
      const buildData = [];
      const addedProductLines = [];
      for (const productline of this.rawProductLines) {
        for (const customerId2 of productline.customers) {
          if ((customerId === customerId2 || customerId === null)
              && addedProductLines.indexOf(productline.productlineid) === -1) {
            buildData.push({
              label: productline.productname,
              key: productline.productlineid
            });
            addedProductLines.push(productline.productlineid);
          }
        }
      }
      this.data = buildData;

      // update selectedItem to empty
      this.selectedItem = null;
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
    this.detailChoicesService.clearUpdateListener('customerId');
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    this.detailChoicesService.setFilter('detailProductLineFilter', selectedItem, true);
    this.detailChoicesService.callUpdateListenerByKey('productLineId', selectedItem);
    // this.personaService.setPersonaOption(PersonaTypes.ProductLine, selectedItem, '');
  }
}
