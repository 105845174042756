<app-state-handler [requestState]="state" [data]="dataRows" [noDataCheck] = "false">
    <div *ngIf="isDataFound(dataRows) else noData">
        <div style='width: 100%; border: gray 1px solid'>
            <div style='font-weight: 500; background-color: #AA0061; color: #FFF; padding: 5px'>
                Global Service Alerts (most recent first)
                <span style='float: right'>Total Alerts: {{dataRows.length}}</span>
            </div>
            <div *ngFor="let dataRecord of dataRows">
                <div style='width: 100%; margin: auto; padding: 5px;'>
                    <div style='width: 30%; float: left; font-weight: 500'>
                        {{dataRecord.category}}
                    </div>
                    <div style='width: 70%; float: right; text-align: right;'>
                    <span style='font-weight: 500'>Message Date:</span> {{dataRecord.msgdate}},  
                    <span style='font-weight: 500'>Expires:</span> {{dataRecord.expirationdate}}
                    </div>
                </div>
                <div style='height: 5px; clear: both'></div>
                <div style='padding: 5px; word-break: break-all' [innerHTML]="toHTML(dataRecord.msg) | safeHtml"></div>
                <hr style='height: 1px; border-width: 0; margin-top: 2px !important; margin-bottom: 2px !important; color: gray; background-color: gray;' />
            </div>
        </div>
    </div>
    <ng-template #noData>
        <div *ngIf="!isDataFound(dataRows)" class="no-data">
            No Global Service alerts found!
        </div>
    </ng-template>
<!-- <div style='margin: 5px'>
        <table class='gs-alerts-table'>
            <tr>
                <th style='width: 25%'>Alert Info</th>
                <th style='width: 75%'>Details</th>
            </tr>
            <tr *ngFor="let dataRecord of dataRows">
                <td style='width: 25%'>{{dataRecord.category}}</td>
                <td style='width: 75%'>
                    <div style='text-align: right'>
                        <b>Message Date:</b> {{dataRecord.msgdate}}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <b>Expiry Date:</b> {{dataRecord.expirationdate}}
                    </div>
                    <div [innerHTML]="dataRecord.msg | safeHtml"></div>
                </td>
            </tr>
        </table>        
    </div> -->
</app-state-handler>



