import { Component, OnInit } from '@angular/core';
import {GridComponent} from '../grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {RowParserService} from '../row-parser.service';
import {Location} from '@angular/common';
import {Style1Pages} from '../../configuration/details/style1-pages';
import {PreferenceApiConnectorService} from '../../preferences/preference-api-connector.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-style1-grid',
  templateUrl: './style1-grid.component.html',
  styleUrls: ['./style1-grid.component.scss']
})
export class Style1GridComponent extends GridComponent implements OnInit {
  rowFormatting = [];

  style1Pages = Style1Pages;

  config = null;
  private colorRowDescriptions = false;

  protected rowParserData = {};

  state;
  RequestState = RequestState;

  constructor(
    protected location: Location,
    protected apiService: ApiService,
    protected rowParserRegistry: RowParserService,
    protected preferenceApiConnectorService: PreferenceApiConnectorService,
    protected router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style1Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style1Pages[currentRouterLocation];

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        this.state = state;
        this.buildGridData();
      });

      // override for currency preference
      if (this.config.tileReference.apiSource === '/metrics/sales') {
        this.preferenceApiConnectorService.onUpdate(() => {
          this.buildGridData();
        });
      }
    }
  }

  buildGridData() {
    if (this.state === RequestState.Complete) {
      // 0 if local, 2 if not local
      // cumulativeLocal, MonthlyLocal, Cumulative Dollar, MonthlyDollar
      let offset = 0;

      if (this.config.tileReference.apiSource === '/metrics/sales') {
        const isLocal = this.preferenceApiConnectorService.getIsLocalCurrency();
        if (isLocal === 'True') {
          offset = 0;
        } else if (isLocal === 'False') {
          offset = 2;
        }
      }

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);

      const response = requestHandler.getResponse();
      const apiData = JSON.parse(response.body);

      // FORMAT API DATA FOR TABLE
      // format dataCols for kendo component
      const dataColsConfig = this.config.table.dataCols;
      let rowParser = this.rowParserRegistry.get(dataColsConfig.parser);
      let sourceData = this.calculateInject(dataColsConfig.inject, apiData);
      this.dataCols = rowParser.get(sourceData, dataColsConfig.attributes);

      // format dataRows for kendo component
      for (const parseConfig of this.config.table.dataParse) {
        rowParser = this.rowParserRegistry.get(parseConfig.parser);
        sourceData = this.calculateInject(parseConfig.inject, apiData);
        const parserAttributes = parseConfig.attributes;
        parserAttributes.stackIndex += offset;
        this.rowParserData[parseConfig.key] = rowParser.get(sourceData, parserAttributes);
      }
      this.dataRows = [];
      for (const dataRowKey of this.config.table.dataRows) {
        const dataRowCheck = this.rowParserData[dataRowKey];
        for (const rowCol in dataRowCheck) {
          if (rowCol in dataRowCheck) {
            if (this.numberType(this.rowParserData[dataRowKey][rowCol]) === 'int') {
              this.rowParserData[dataRowKey][rowCol] = parseInt(dataRowCheck[rowCol]);
            } else if (this.numberType(this.rowParserData[dataRowKey][rowCol]) === 'float') {
              this.rowParserData[dataRowKey][rowCol] = parseFloat(dataRowCheck[rowCol]);
            }
          }
        }
        this.dataRows.push(this.rowParserData[dataRowKey]);
      }

      // setup data for formatting row colors
      this.rowFormatting = this.config.table.rowColors(apiData);

      if (
        ('colorRowDescriptions' in this.config.table)
        && (this.config.table.colorRowDescriptions === true)
      ) {
        this.colorRowDescriptions = true;
      }
      this.updateGrid();
    }
    if (this.state === RequestState.Loading) {
      this.dataRows = [];
      this.updateGrid();
    }
  }

  numberType(value) {
    if (isNaN(value)) {
      return 'other';
    }
    if (Math.floor(value) === value) {
      return 'int';
    } else {
      return 'float';
    }
  }

  calculateInject(key, apiData) {
    if (key === 'apiData') {
      return apiData;
    }
    if (Array.isArray(key)) {
      const injectArray = [];
      for (const item of key) {
        if (item === 'apiData') {
          injectArray.push(apiData);
        } else {
          injectArray.push(this.rowParserData[item]);
        }
      }
      return injectArray;
    }
    return this.rowParserData[key];
  }

  goToDetails(location) {
    this.router.navigateByUrl(location);
  }

  // https://www.telerik.com/kendo-angular-ui/components/grid/styling
  cellStyle(colRef, rowIndex = -1) {
    if (colRef === 'description') {
      if (this.colorRowDescriptions && rowIndex >= 0) {
        return {
          'background-color': this.rowFormatting[rowIndex],
          color: '#FFFFFF',
          display: 'block',
          padding: '12px'
        };
      }
      return {
        'background-color': 'blue',
        color: '#FFFFFF'
      };
    }
    return {
      'color': this.rowFormatting[rowIndex],
      'text-align': 'right',
      'display': 'block'
    };
  }

  headerStyle(colRef) {
    if (colRef === 'description') {
      if (this.colorRowDescriptions) {
        return {
          'background-color': 'blue',
          color: '#FFFFFF',
          padding: '0'
        };
      }
      return {
        'background-color': 'blue',
        color: '#FFFFFF'
      };
    }
    return {
      'background-color': '#AA0061',
      color: '#FFFFFF',
      padding: '5px'
    };
  }

  colStyle(colRef) {
    if (colRef === 'description') {
      if (this.colorRowDescriptions) {
        return {
          'background-color': 'blue',
          'color': '#FFFFFF',
          'padding': '0'
        };
      }
      return {
        'background-color': 'blue',
        'color': '#FFFFFF'
      };
    }
  }
}
