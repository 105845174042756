import { Component } from '@angular/core';
import {ToggleSwitchComponent} from '../toggle-switch.component';

@Component({
  selector: 'app-track-toggle',
  templateUrl: './track-toggle.component.html',
  styleUrls: ['./track-toggle.component.scss']
})
export class TrackToggleComponent extends ToggleSwitchComponent {
  filterKey() {
    return 'trackToggle';
  }
  defaultValue() {
    return false;
  }
}
