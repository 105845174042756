import { Component, OnInit } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import { TileComponent } from '../tile.component';

@ComponentLookup('ShapeIndicatorsComponent')
@Component({
  selector: 'app-shape-indicators',
  templateUrl: './shape-indicators.component.html',
  styleUrls: ['./shape-indicators.component.scss']
})

export class ShapeIndicatorsComponent extends TileComponent implements OnInit {
  defaultPreferences() {
    return {
      showTitle: true,
      shape: 'circle'
    };
  }

  translateApiDataForChart(apiData) {
    // console.log(this.tileReference.apiSource);
    // console.log(JSON.stringify(apiData));
    if (this.isDataFound(apiData)) {
      if ('orderByLabel' in this.preferences) {
        const newOrder = [];
        for (const label of this.preferences.orderByLabel) {
          newOrder.push(this.findItemByKey(apiData.values, 'label', label));
        }
        apiData.values = newOrder;
      }
    }
  }

  isDataFound(apiData) {
    if ('dataexists' in apiData && apiData.dataexists === false) {
      return false;
    }
    return ('values' in apiData && apiData.values.length > 0);
  }

  findItemByKey(array, key, value) {
    for (const item of array) {
      if (item[key] === value) {
        return item;
      }
    }
    return null;
  }

  getTextSize() {
    let biggestCharCount = 0;
    for (const shape of this.apiData.values) {
      if (shape.values[0]) {
        let charCount = shape.values[0].length;
        if (shape.values[1] > 0) {
          charCount += shape.values[1].length;
          charCount += 3;
        }
        if (charCount > biggestCharCount) {
          biggestCharCount = charCount;
        }
      }
    }
    let maxWidth = 0;
    switch (this.preferences.shape) {
      case 'diamond':
        maxWidth = 35;
        if (biggestCharCount < 3) {
          maxWidth = 30;
        }
        if (biggestCharCount < 2) {
          maxWidth = 25;
        }
        break;
      case 'circle':
        maxWidth = 70;
        if (biggestCharCount < 3) {
          maxWidth = 50;
        }
        if (biggestCharCount < 2) {
          maxWidth = 25;
        }
        break;
      case 'square':
        maxWidth = 40;
        if (biggestCharCount < 3) {
          maxWidth = 32;
        }
        if (biggestCharCount < 2) {
          maxWidth = 25;
        }
        break;
    }
    return ((maxWidth / biggestCharCount) * 2) + 'px';
  }
}
