import {TileReference} from '../dashboards/tile-reference';

export const SERVICE_METRIC_TILE_REFERENCE_DEFAULT_ARRANGEMENT: TileReference[] = [
  {
    apiSource: '/metrics/hsi',
    componentName: 'StackedBarLineChartComponent',
    detailLink: '/hsi',
    preferences: {
      periodsToShow: 4,
      title: 'Highly Serviced Instruments'
    }
  },
  {
    apiSource: '/metrics/hotsheets',
    componentName: 'TextIndicatorsComponent',
    detailLink: '/hotsheets',
    preferences: {
      title: 'NPS Open Hotsheets'
    }
  },
  {
    apiSource: '/metrics/opencalls',
    componentName: 'ShapeIndicatorsComponent',
    preferences: {
      shape: 'circle',
      orderByLabel: ['Complaint', 'Service Demand', 'PM', 'TSB'],
      title: 'Assigned Open Calls'
    },
    detailLink: '/opencalls'
  },
  {
    apiSource: '/metrics/cpyr',
    componentName: 'MultiLineChartComponent',
    detailLink: '/cpyr',
    preferences: {
      excludeLines: [3],
      periodsToShow: 4,
      title: 'CPY-R'
    }
  },
  {
    apiSource: '/metrics/servicecost',
    componentName: 'StackedBarLineChartComponent',
    preferences: {
      valueAxis1Label: '#K',
      axis2Source: 'yleftmax',
      axis2VisibleLabels: false,
      periodsToShow: 4,
      title: 'Service Cost'
    },
    detailLink: '/servicecost'
  },
  {
    apiSource: '/metrics/alconnected', // this is actually abbottlink installed but uses alconnected
    componentName: 'PieChartComponent',
    preferences: {
      showGoal: true,
      goalPercentageSlideLabel: 'Installed',
      title: 'AbbottLink - Installed'
    },
    detailLink: '/alconnected'
  },
  {
    apiSource: '/metrics/alconnectivity',
    componentName: 'PieChartComponent',
    preferences: {
      showGoal: true,
      goalPercentageSlideLabel: 'Connected (%)',
      title: 'AbbottLink - Connectivity'
    },
    detailLink: '/alconnectivity'
  },
  {
    apiSource: '/metrics/hci',
    componentName: 'StackedBarLineChartComponent',
    detailLink: '/hci',
    preferences: {
      periodsToShow: 4,
      title: 'High Cost Instruments'
    }
  },
  {
    apiSource: '/metrics/sdfr',
    componentName: 'MultiLineChartComponent',
    preferences: {
      periodsToShow: 4,
      excludeLines: [3],
      tickCount: 5,
      title: 'Same Day Fix Rate'
    },
    detailLink: '/sdfr'
  },
  {
    apiSource: '/metrics/ftfr',
    componentName: 'MultiLineChartComponent',
    preferences: {
      periodsToShow: 4,
      excludeLines: [2, 3],
      tickCount: 5,
      title: 'First Time Fix Rate'
    },
    detailLink: '/ftfr'
  },
  {
    apiSource: '/metrics/uptime',
    componentName: 'MultiLineChartComponent',
    preferences: {
      periodsToShow: 4,
      title: 'Uptime'
    },
    detailLink: '/uptime'
  },
  {
    apiSource: '/metrics/assay',
    componentName: 'TopStatsListComponent',
    detailLink: '/assay',
    preferences: {
      title: 'Top Assays'
    }
  },
  {
    apiSource: '/metrics/cpyp',
    componentName: 'MultiLineChartComponent',
    preferences: {
      tickCount: 3,
      periodsToShow: 4,
      title: 'CPY-P',
      colorsByIndex: [0, 2]
    },
    detailLink: '/cpyp'
  },
  {
    apiSource: '/metrics/ticketrending',
    componentName: 'StackedBarLineChartComponent',
    detailLink: '/ticketrending',
    preferences: {
      periodsToShow: 4,
      title: 'Ticket Trending'
    }
  },
  {
    apiSource: '/metrics/trt',
    componentName: 'MultiLineChartComponent',
    preferences: {
      periodsToShow: 4,
      tickCount: 5,
      title: 'Total Resolution Time'
    },
    detailLink: '/trt'
  },
  {
    apiSource: '/metrics/tsb',
    componentName: 'ShapeIndicatorsComponent',
    preferences: {
       shape: 'diamond',
      title: 'TSB\'s'
    },
    detailLink: '/tsb'
  },
  {
    apiSource: '/metrics/pm',
    componentName: 'ShapeIndicatorsComponent',
    preferences: {
      shape: 'square',
      orderByLabel: ['0-1 Month', '1-2 Months', '2-3 Months', '>3 Months'],
      title: 'PM\'s'
    },
    detailLink: '/pm'
  },
  {
    apiSource: '/metrics/nps',
    componentName: 'VerticalSplitTextIndicatorsComponent', // Customer NPS
    apiMappingLinks: ['/metrics/nps'],
    preferences: {
      detailLinks: ['/customerNps', '/customerNps'],
      useDataArray: true,
      title: 'Customer NPS'
    },
    // detailLink: '/customerNps'
  },
  {
    apiSource: '/metrics/servicecosthours',
    componentName: 'MultiLineChartComponent',
    preferences: {
      tickCount: 7,
      periodsToShow: 4,
      title: 'Service Hours'
    },
    detailLink: '/servicecosthours'
  },
  {
    apiSource: '/metrics/utilization',
    componentName: 'VerticalSplitTextIndicatorsComponent',
    apiMappingLinks: ['/metrics/utilization'],
    preferences: {
      detailLinks: ['/utilization', '/utilization'],
      useDataArray: true,
      title: 'Low Utilization',
      preSpacing: 2
    }
    // , detailLink: '/utilization'
  },
  {
    apiSource: '/metrics/servicecontracts', // Service Contracts (excluding RAP)
    componentName: 'PieChartComponent',
    preferences: {
      showGoal: false,
      renderForServiceContracts: true,
      title: 'Service Contracts'
    },
    detailLink: '/servicecontracts'
  },
  {
    apiSource: '/metrics/CRLSummary',
    componentName: 'TextIndicatorsComponent', // Current Instrument Quantity
    preferences: {
      calculateTotalFromData: true,
      title: 'Current Instrument Quantity'
    },
    detailLink: '/CRLSummary'
  },
  {
    apiSource: '/metrics/proactive',
    componentName: 'MultiLineChartComponent',
    preferences: {
      excludeLines: [3],
      periodsToShow: 4,
      tickCount: 5,
      title: 'Proactive Ticket Rate %'
    },
    detailLink: '/proactive'
  },
  {
    apiSource: '/metrics/orders/thumbnail', // Customer Orders
    componentName: 'PieChartComponent',
    preferences: {
      showGoal: false,
      title: 'Customer Orders'
    },
    detailLink: '/orders'
  },
  {
    apiSource: '/metrics/alwaysonuptime',
    componentName: 'PieChartComponent',
    preferences: {
      showGoal: false,
      title: 'ALWAYS ON Uptime'
    },
    detailLink: '/alwaysonuptime'
  },
  {
    apiSource: '/metrics/fsrutilization',
    componentName: 'UtilizationTpdComponent',
    apiMappingLinks: ['/metrics/fsrutilization'],
    preferences: {
      detailLinks: ['/util_workload', '/tickets_per_day'],
      useDataArray: true,
      showTitle: false,
      title: 'Utilization Workload % / Tickets Per Day Per Person'
    }
  },
  {
    apiSource: '/metrics/servicegrossmargin_customerSGM',
    componentName: 'TextIndicatorsComponent',
    detailLink: '/servicegrossmargin_customerSGM',
    preferences: {
      lowerTitleColor: '#000000',
      title: 'Service Gross Margin'
    }
  },
  {
    apiSource: '/metrics/customerswithoutservicecontracts',
    componentName: 'StackedBarLineChartComponent',
    detailLink: '/customerswithoutservicecontracts',
    preferences: {
      periodsToShow: 4,
      title: 'Predictive Alerts w/o Service Contracts'
    }
  }/**/
  // ,
  // {
  //   apiSource: 'null',
  //   componentName: 'PlaceholderComponent', // TextIndicatorsComponent
  //   preferences: {
  //     title: 'Service Gross Margin (SGM %)'
  //   }
  // },
];

// Highly Serviced Instruments
// NPS Open Hotsheets
// Assigned Open Calls
// CPY-R
// Service Cost
// AbbottLink - Installed
//
// AbbottLink - Connectivity
// High Cost Instruments
//
// Same Day Fix Rate
// First Time Fix Rate
// Uptime
// Top Assays
//
// CPY-P
// Ticket Trending
// Total Resolution Time
// TSB's
//
// PM's
// Customer NPS
//
// Service Hours
// Low Utilization
// Service Contracts (Including RAP)
// Current Instrument Quantity
// Proactive Ticket Rate %
// Customer Orders
//
// ALWAYS ON Uptime (12 Months)
// Utilization Workload % (3MA) / Tickets Per Day Per Person (3MA)
// Service Gross Margin (SGM %)
// Customers SGM % (12MA)
// Proactive Alerts w/o Service Contracts
