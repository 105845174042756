<span class="tooltip-link" title="Search for customers (by name / account #) or instruments (by type / serial #) determined by which tab you are on.">
    <a class="nav-link" (click)="toggle()" *ngIf="navFilterSelection && navFilterSelection.search">
        <span class="icon" [class.switch-on]="stateOn"></span>
        <span class="icon-state-label" *ngIf="displayTitles">
            Search
        </span>
    </a>
</span>
<kendo-window
        [draggable]="false"
        [resizable]="false"
        state="maximized"
        *ngIf="stateOn">
    <kendo-window-titlebar>
        <nav class="navbar navbar-light bg-light">
            <div class="d-flex justify-content-center" style="width: 100%;">
                <div class="mr-auto p-1">
                    <button type="button" class="k-button" (click)="close()">Cancel</button>
                </div>
                <div class="p-1" style="width: 100%;">
                    <input kendoTextBox placeholder="Search" class="search-input" [value]="searchValue" (change)="change($event)" (keyup)="change($event)" />
                </div>
                <div class="ml-auto p-1">
                    <button type="button" class="k-button k-primary mr-0" (click)="search()">Search</button>
                </div>
            </div>
        </nav>
    </kendo-window-titlebar>
    <div class="loading-search" *ngIf="apiState === RequestState.Loading">Updating Results...</div>
    <div class="container" *ngIf="apiData">
        <div class="row" style="width:100%;">
            <div class="col-4" *ngIf="apiData.customers" [class.selectedType]="selectedType === 'customer'">
                <div class="title">Customers</div>
                <div class="results">
                    <div *ngFor="let item of apiData.customers" (click)="select(item, 'customer', item.customerid)" class="result-item" [class.selected]="item.selected">
                        <div>{{item.name}}</div>
                        {{item.address}}, {{item.city}}
                        <!--customerid: "45891"-->
                        <!--customersrno: "0050082684"-->
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="apiData.productlines" [class.selectedType]="selectedType === 'pl'">
                <div class="title">Instruments</div>
                <div class="results">
                    <div *ngFor="let item of apiData.productlines" (click)="select(item, 'pl', item.productlineid)" class="result-item" [class.selected]="item.selected">
                        {{item.productname}}
                    </div>
                </div>
            </div>
            <div class="col-4" *ngIf="apiData.snlist" [class.selectedType]="selectedType === 'sn'">
                <div class="title">Serial Numbers</div>
                <div class="results">
                    <div *ngFor="let item of apiData.snlist" (click)="select(item, 'sn', item.productlineid)" class="result-item" [class.selected]="item.selected">
                        <div>{{item.productname}} SN: {{item.sn}}</div>
                        {{item.country}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</kendo-window>