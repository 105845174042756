<div class="br-snapshot">
  <h3 *ngIf="preferences.showTitle">{{preferences.title}}</h3>
  <!--<p style="text-align:center;">Multi-Product Stacked<br />Bar Chart Test Display</p> -->
  <app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData">
        <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
          <kendo-chart>
            <kendo-chart-category-axis>
              <kendo-chart-category-axis-item [categories]="['Q1', 'Q2', 'Q3', 'Q4']">
              </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
              <kendo-chart-series-item name="test 1" type="column"
                                       [stack]="{ type: '0%' }"
                                       [data]="[1, 2, 3, 5]"></kendo-chart-series-item>
              <kendo-chart-series-item name="test 2" type="bar" [data]="[1, 1, 1, 1]"></kendo-chart-series-item>
              <kendo-chart-series-item name="test 3" type="bar" [data]="[2, 1, 3, 2]"></kendo-chart-series-item>
            </kendo-chart-series>
          </kendo-chart>
        </a>
      <div *ngIf="!isDataFound(apiData)" class="no-data">
        No data found
      </div>
    </div>
  </app-state-handler>
</div>