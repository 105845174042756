import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import {Location} from '@angular/common';
import {BrParamsService} from '../../br-params.service';
import {BrHistoryRange} from '../../br-history-range.service';
import {NavbarBuilderService} from '../../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../../configuration/nav-filter-selection';
import {ListViewPages} from '../../configuration/details/list-view-pages';
import {RequestState} from '../../../api/request-handler';
import {ApiService} from '../../../api/api.service';
import {GridComponent} from '../../../details/grid.component';
import {DetailQueryParamsService} from '../../detail-query-params.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-br-list-view',
  templateUrl: './br-list-view.component.html',
  styleUrls: ['./br-list-view.component.scss']
})

export class BrListViewComponent extends GridComponent implements OnInit, OnDestroy {
  kpiConfig;
  listViewPages = ListViewPages;
  public apiData;
  public state = RequestState.Inactive;
  apiUrl;
  apiDisplayTitle;
  requestHandler;
  stateChangeHandler;
  dataCols = [];
  dataRows = [];
  CustomerName;
  returnLink;
  detailQueryCompleteSubscription;
  returnLinkSubscription;
  queryParamsSubscription;
  routeUrlSubscription;
  routeUrl;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private brParamsService: BrParamsService,
    private brHistoryRange: BrHistoryRange,
    private location: Location,
    private apiService: ApiService,
    private detailQueryParamsService: DetailQueryParamsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    // console.log('OnInit Executed', currentRouterLocation);
    this.detailQueryCompleteSubscription = this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.listViewPages.hasOwnProperty(currentRouterLocation)) {
        this.kpiConfig = this.listViewPages[currentRouterLocation];
      }
      this.routeUrl = currentRouterLocation;

      this.apiUrl = this.kpiConfig.apiSource;

      this.apiDisplayTitle = this.kpiConfig.apiTitle;

      this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_detail'));

      this.navbarBuilderService.setNavbarChoice('business-review');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        dashboardSelector: false,
        toolSelection: ToolSelectionOptions.businessreview,
        bookmarksFlag: false,
        viewTitle: false,
        viewTitleText: '',
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      this.requestHandler = this.apiService.get(this.apiUrl);
      this.requestHandler.sendRequest(this.apiUrl);

      // console.log('Test Event: Line 111');
      // no need to send request here as chart tile has already done so
      this.stateChangeHandler = (state) => {
        this.state = state;
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.apiData = JSON.parse(response.body);

          if (this.apiData.customer) { this.CustomerName = this.apiData.customer; }
          // console.log('List Data', this.apiData.customer);

          // FORMAT API DATA FOR TABLE
          // format dataCols for kendo component
          this.dataCols = this.kpiConfig.table.columns;
          if (this.apiData.columns) { this.dataCols = this.apiData.columns; }

          this.dataRows = [];
          for (let i = 0; i < this.apiData.values.length; i++) {
            const apiDataRow = this.apiData.values[i];
            const dataRow = {};
            for (let c = 0; c < this.dataCols.length; c++) {
              const dataColConfig = this.dataCols[c];
              dataRow[dataColConfig.ref] = {
                value: apiDataRow[dataColConfig.ref]
              };
            }
            this.dataRows.push(dataRow);
          }
          this.updateGrid();
        }
        this.updateGrid();
      };
      this.requestHandler.onStateChange(this.stateChangeHandler);
    });

    this.returnLinkSubscription = this.detailQueryParamsService.getReturnLink().subscribe((pathDetails) => {
      this.returnLink = pathDetails;
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.detailQueryParamsService.prepReturnParams(params);
      this.detailQueryParamsService.buildPersistedValuesFromParams(params);
    });
    this.routeUrlSubscription = this.route.url.subscribe((url) => {
      this.detailQueryParamsService.prepReturnLink(url);
      this.detailQueryParamsService.processReturnLink();
    });
  }

  ngOnDestroy(): void {
    if (this.stateChangeHandler) {
      this.requestHandler.removeStateListener(this.stateChangeHandler);
    }
    this.detailQueryCompleteSubscription.unsubscribe();
    this.returnLinkSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.routeUrlSubscription.unsubscribe();
  }

  getQueryParams() {
    return this.detailQueryParamsService.getQueryParams();
  }
}
