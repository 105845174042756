import { Component, OnInit } from '@angular/core';
import {GridComponent} from '../grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {Location} from '@angular/common';
import {Style1Pages} from '../../configuration/details/style1-pages';
import {PreferenceApiConnectorService} from '../../preferences/preference-api-connector.service';

@Component({
  selector: 'app-custom-compliance-grid',
  templateUrl: './custom-compliance-grid.component.html',
  styleUrls: ['./custom-compliance-grid.component.scss']
})
export class CustomComplianceGridComponent extends GridComponent implements OnInit {
  rowFormatting = [];
  dataCols = [];
  dataRows = [];
  currency = 'Local';

  style1Pages = Style1Pages;

  state;
  config = null;

  protected rowParserData = {};

  constructor(
    protected location: Location,
    protected apiService: ApiService,
    protected preferenceApiConnectorService: PreferenceApiConnectorService
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style1Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style1Pages[currentRouterLocation];

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        this.state = state;
        this.buildGridData();
      });
    }
  }

  buildGridData() {
    if (this.state === RequestState.Complete) {
      // 1 if local, 2 if not local
      // Compliance, Sales, SalesDollar
      let offset = 1;

      const isLocal = this.preferenceApiConnectorService.getIsLocalCurrency();
      if (isLocal === 'True') {
        offset = 1;
        this.currency = 'Local';
      } else if (isLocal === 'False') {
        offset = 2;
        this.currency = 'USD';
      }

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);

      const response = requestHandler.getResponse();
      const apiData = JSON.parse(response.body);

      // FORMAT API DATA FOR TABLE
      // format dataCols for kendo component
      this.dataCols = [
        {
          description: 'YYYY-MM',
          width: 80
        },
        {
          description: apiData.values[0].xlabel,
          width: 80
        },
        {
          description: apiData.values[1].xlabel,
          width: 80
        },
        {
          description: apiData.values[2].xlabel,
          width: 80
        }
      ];

      // format dataRows for kendo component
      this.dataRows = [];
      this.dataRows.push([
        'Compliance %',
        apiData.values[0].stacks[0],
        apiData.values[1].stacks[0],
        apiData.values[2].stacks[0],
      ]);
      this.dataRows.push([
        'Goal %',
        apiData.values[0].stackgoals[0],
        apiData.values[1].stackgoals[0],
        apiData.values[2].stackgoals[0],
      ]);
      this.dataRows.push([
        'Revenue Gain/Loss (' + this.currency + ')',
        apiData.values[0].stacks[offset],
        apiData.values[1].stacks[offset],
        apiData.values[2].stacks[offset],
      ]);
      this.dataRows.push([
        'Goal USD',
        apiData.values[0].stackgoals[offset],
        apiData.values[1].stackgoals[offset],
        apiData.values[2].stackgoals[offset],
      ]);

      // setup data for formatting row colors
      this.rowFormatting = ['#006fca', '#02b3fd', '#006fca', '#02b3fd'];

      this.updateGrid();
    }
    // if (this.state === RequestState.Loading) {}
  }

  cellStyle(rowIndex, columnIndex) {
    if (columnIndex === 0) {
      return {
        'background-color': 'blue',
        color: '#FFFFFF',
        display: 'block',
        padding: '12px'
      };
    }
    return {
      color: this.rowFormatting[rowIndex]
    };
  }

  colStyle(columnIndex) {
    if (columnIndex === 0) {
      return {
        'background-color': 'blue'
      };
    }
    return {};
  }
}
