<div class="v-wrapper" *ngIf="isAuthorized">
    <div [ngSwitch]="navbar" *ngIf="navbar" class="navbar-area">
        <ng-template [ngSwitchCase]="'dashboard'"><!--myCRL-->
            <app-dashboard-navbar></app-dashboard-navbar>
        </ng-template>
        <ng-template [ngSwitchCase]="'metric-detail'">
            <app-metric-detail-navbar></app-metric-detail-navbar>
        </ng-template>
        <ng-template [ngSwitchCase]="'ct-dashboard'">
            <app-ct-navbar></app-ct-navbar>
        </ng-template>
        <ng-template [ngSwitchCase]="'alerts-dashboard'">
            <app-alerts-navbar></app-alerts-navbar>
        </ng-template>
        <ng-template [ngSwitchCase]="'gs-alerts-dashboard'">
            <app-gs-alerts-navbar></app-gs-alerts-navbar>
        </ng-template>
        <ng-template [ngSwitchCase]="'metric-detail-no-filter'">
            <app-metric-detail-no-filter></app-metric-detail-no-filter>
        </ng-template>
        <ng-template [ngSwitchCase]="'business-review'">
            <app-br-navbar></app-br-navbar>
        </ng-template>
    </div>
    <!-- <div class="router-content-container">
        <router-outlet></router-outlet>
    </div> -->
    <div [ngClass]="
    (navbar == 'alerts-dashboard' || navbar == 'gs-alerts-dashboard')
        ? 'router-content-container-alerts'
        : (
            (navbar == 'metric-detail-no-filter-option')
            ? 'router-content-metric-detail-no-filter'
            : (
                (navbar == 'metric-detail-no-filter')
                ? 'content-metric-detail-no-filter'
                : 'router-content-container'
            )
        )">
        <div *ngIf="isAuthenticating" style="text-align: center; padding: 100px; color: #cecece;">
            <div>Authenticating...</div>
            <div class="spinner-border loading-spinner" role="status"></div>
        </div>
        <div *ngIf="!isAuthenticating">
            <router-outlet></router-outlet>
        </div>
    </div>
    <!-- <div>
        <button class="button button-primary" (click)="subscribeToNotifications()">
            Subscribe
        </button>
    </div> -->
</div>
<div *ngIf="!isAuthorized">
    Not Authorized!
</div>