import {Component, OnInit, OnDestroy} from '@angular/core';
import {NavbarBuilderService} from './navigation/navbar-builder.service';
import {PersonaOptionsService} from './api/persona-options.service';
import {PreferenceApiConnectorService} from './preferences/preference-api-connector.service';
import {PersonaService} from './api/persona.service';
import {DetailChoicesService} from './filter_selection/details/detail-choices.service';
import {FilterChoicesService} from './filter_selection/filter-choices.service';
import {AuthenticationService} from './authentication/authentication.service';
import {PersonaTypes} from './configuration/persona-types';
import { SwPush } from '@angular/service-worker';
import { PushNotificationService } from './api/push-notification-service';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import {environment} from '../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import { Logger, CryptoUtils, AuthenticationParameters } from 'msal';
import {ApiMapping} from './configuration/api-mapping';
import {RequestState} from './api/request-handler';
import {RefreshHandlerService} from './api/refresh-handler.service';
import {SelectedTabService} from './dashboards/selected-tab.service';
import {ComponentLookup, ComponentLookupRegistry} from './decorators/component-lookup';
import {StackedBarLineChartComponent} from './tiles/base/stacked-bar-line-chart/stacked-bar-line-chart.component';
import {TextIndicatorsComponent} from './tiles/base/text-indicators/text-indicators.component';
import {StackedBarChartByProductComponent} from './tiles/base/stacked-bar-chart-by-product/stacked-bar-chart-by-product.component';
import {FilterInsertComponent} from './filter_selection/details/filter-insert/filter-insert.component';
import {TopStatsListComponent} from './tiles/base/top-stats-list/top-stats-list.component';
import {VerticalSplitTextIndicatorsComponent} from './tiles/base/vertical-split-text-indicators/vertical-split-text-indicators.component';
import {ShapeIndicatorsComponent} from './tiles/base/shape-indicators/shape-indicators.component';
import {PieChartComponent} from './tiles/base/pie-chart/pie-chart.component';
import {AreaLineChartComponent} from './tiles/base/area-line-chart/area-line-chart.component';
import {MultiLineChartComponent} from './tiles/base/multi-line-chart/multi-line-chart.component';
import {ComplianceChartComponent} from './tiles/metrics/compliance-chart/compliance-chart.component';
import {NpsComponent} from './tiles/metrics/nps/nps.component';
import {UtilizationTpdComponent} from './tiles/metrics/utilization-tpd/utilization-tpd.component';
import {PlaceholderComponent} from './tiles/placeholder/placeholder.component';
import {DetailAccountFilterComponent} from './filter_selection/details/detail-account-filter/detail-account-filter.component';
import {DetailAssayFilterComponent} from './filter_selection/details/detail-assay-filter/detail-assay-filter.component';
import {DetailCapitalServiceAssaySwitchesFilterComponent} from './filter_selection/details/detail-capital-service-assay-switches-filter/detail-capital-service-assay-switches-filter.component';
import {DetailCategorySwitchesFilterComponent} from './filter_selection/details/detail-category-switches-filter/detail-category-switches-filter.component';
import {DetailCustomerFilterComponent} from './filter_selection/details/detail-customer-filter/detail-customer-filter.component';
import {DetailDiseaseStateFilterComponent} from './filter_selection/details/detail-disease-state-filter/detail-disease-state-filter.component';
import {DetailProductLineFilterComponent} from './filter_selection/details/detail-product-line-filter/detail-product-line-filter.component';
import {BrPlaceholderComponent} from './business_review/snapshots/br-placeholder/br-placeholder.component';
import {BrStackedBarLineChartComponent} from './business_review/snapshots/br-stacked-bar-line-chart/br-stacked-bar-line-chart.component';
import { BrMultiLineChartComponent } from './business_review/snapshots/br-multi-line-chart/br-multi-line-chart.component';
import { BrMultiBarChartComponent } from './business_review/snapshots/br-multi-bar-chart/br-multi-bar-chart.component'
import { BrLineChartComponent } from './business_review/snapshots/br-line-chart/br-line-chart.component';
import { BrPieChartComponent } from './business_review/snapshots/br-pie-chart/br-pie-chart.component';
import { BrStackedBarChartByProductComponent } from './business_review/snapshots/br-stacked-bar-chart-by-product/br-stacked-bar-chart-by-product.component';
import { BrTextIndicatorComponent } from './business_review/snapshots/br-text-indicator/br-text-indicator.component';
import { BrTopItemsComponent } from './business_review/snapshots/br-top-items/br-top-items.component';
import { BrExamplePieChartComponent } from './business_review/snapshots/br-example-pie-chart/br-example-pie-chart.component';
import { ApiTestComponent } from './business_review/snapshots/api-test/api-test.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'oneview-spa';
  navbar;
  isIframe = false;
  loggedIn = false;
  loginSuccessSubscription;
  loginFailureSubscription;
  acquireTokenFailureSubscription;
  acquireTokenSuccessSubscription;
  subscription;
  isAuthenticating = true;
  isAuthorized = true;
  // this.isAuthorized = this.authService.isAuthorizedCheck();
  // console.log('isauth', this.isAuthorized);

  readonly VAPID_PUBLIC_KEY = "BNCy-6pMOFBprNvbFya5TCXTR4-RuTDjq1EA8c4GknLb848K19abxzmVLEzYXXKNWsGkEkS0vMWnkqI9K8fylic";

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private personaService: PersonaService,
    private personaOptionsService: PersonaOptionsService,
    private preferenceApiConnectorService: PreferenceApiConnectorService,
    private detailChoicesService: DetailChoicesService,
    private authService: AuthenticationService,
    protected filterChoices: FilterChoicesService,
    private swPush: SwPush,
    private pushNotificationService: PushNotificationService,
    private broadcastService: BroadcastService,
    private http: HttpClient,
    private msalAuthService: MsalService,
    private refreshHandlerService: RefreshHandlerService,
    private selectedTabService: SelectedTabService
  ) {}

  ngOnInit(): void {

    //localStorage.clear();
    ComponentLookupRegistry.set('StackedBarLineChartComponent', StackedBarLineChartComponent);
    ComponentLookupRegistry.set('TextIndicatorsComponent', TextIndicatorsComponent);
    ComponentLookupRegistry.set('TopStatsListComponent', TopStatsListComponent);
    ComponentLookupRegistry.set('VerticalSplitTextIndicatorsComponent', VerticalSplitTextIndicatorsComponent);
    ComponentLookupRegistry.set('StackedBarChartByProductComponent', StackedBarChartByProductComponent);
    ComponentLookupRegistry.set('ShapeIndicatorsComponent', ShapeIndicatorsComponent);
    ComponentLookupRegistry.set('PieChartComponent', PieChartComponent);
    ComponentLookupRegistry.set('AreaLineChartComponent', AreaLineChartComponent);
    ComponentLookupRegistry.set('MultiLineChartComponent', MultiLineChartComponent);
    ComponentLookupRegistry.set('ComplianceChartComponent', ComplianceChartComponent);
    ComponentLookupRegistry.set('NpsComponent', NpsComponent);
    ComponentLookupRegistry.set('UtilizationTpdComponent', UtilizationTpdComponent);
    ComponentLookupRegistry.set('PlaceholderComponent', PlaceholderComponent);
    ComponentLookupRegistry.set('DetailAccountFilterComponent', DetailAccountFilterComponent);
    ComponentLookupRegistry.set('DetailAssayFilterComponent', DetailAssayFilterComponent);
    ComponentLookupRegistry.set('DetailCapitalServiceAssaySwitchesFilterComponent', DetailCapitalServiceAssaySwitchesFilterComponent);
    ComponentLookupRegistry.set('DetailCategorySwitchesFilterComponent', DetailCategorySwitchesFilterComponent);
    ComponentLookupRegistry.set('DetailCustomerFilterComponent', DetailCustomerFilterComponent);
    ComponentLookupRegistry.set('DetailDiseaseStateFilterComponent', DetailDiseaseStateFilterComponent);
    ComponentLookupRegistry.set('DetailProductLineFilterComponent', DetailProductLineFilterComponent);
    ComponentLookupRegistry.set('BrPlaceholderComponent', BrPlaceholderComponent);
    ComponentLookupRegistry.set('BrStackedBarLineChartComponent', BrStackedBarLineChartComponent);
    ComponentLookupRegistry.set('BrMultiLineChartComponent', BrMultiLineChartComponent);
    ComponentLookupRegistry.set('BrMultiBarChartComponent', BrMultiBarChartComponent);
    ComponentLookupRegistry.set('BrLineChartComponent', BrLineChartComponent);
    ComponentLookupRegistry.set('BrPieChartComponent', BrPieChartComponent);
    ComponentLookupRegistry.set('BrStackedBarChartByProductComponent', BrStackedBarChartByProductComponent);
    ComponentLookupRegistry.set('BrTextIndicatorComponent', BrTextIndicatorComponent);
    ComponentLookupRegistry.set('BrTopItemsComponent', BrTopItemsComponent);
    ComponentLookupRegistry.set('BrExamplePieChartComponent', BrExamplePieChartComponent);
    ComponentLookupRegistry.set('ApiTestComponent', ApiTestComponent);
    // this.isIframe = window !== window.parent && !window.opener;

    // this.checkAccount();

    // this.broadcastService.subscribe('msal:loginSuccess', payload => {
    //   console.log('loginSuccess', payload);
    //   this.checkAccount();
    // });
    // this.loginSuccessSubscription = this.broadcastService.subscribe('msal:loginSuccess', payload => {
    //   console.log('loginSuccess', payload);
    // });
    // this.loginFailureSubscription = this.broadcastService.subscribe('msal:loginFailure', payload => {
    //   console.log('loginFailure', payload);
    // });

    // this.msalAuthService.handleRedirectCallback((authError, response) => {
    //   if (authError) {
    //     console.error('Redirect Error: ', authError.errorMessage);
    //     return;
    //   }
    //
    //   console.log('Redirect Success: ', response.accessToken);
    // });

    // this.msalAuthService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    //   console.log('MSAL Logging: ', message);
    // }, {
    //   correlationId: CryptoUtils.createNewGuid(),
    //   piiLoggingEnabled: false
    // }));
    // this.broadcastService.subscribe("msal:ssoSuccess", payload => {
    //   console.log('tokenSuccess', payload);
    // });
    this.acquireTokenSuccessSubscription = this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
      if (!this.authService.isAuth()) {
        const formData = new HttpParams({
          fromObject: {
            id_token: payload.idToken.rawIdToken,
          }
        });
        const headers = new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded');
        console.log('auth url', environment.proxyServerUrl + '/sso.php', formData);
        this.subscription = this.http.post(environment.proxyServerUrl + '/sso.php', formData, {
          headers: headers,
          responseType: 'text',
          withCredentials: true,
          observe: 'response'
        }).subscribe({
          next: (response) => {
            console.log('Auth: Success!');
            this.isAuthenticating = false;
            const authSubscription = this.authService.pingAuth();
            authSubscription.subscribe(
              response => {
                this.personaService.initialLoad();
                this.authService.onAuth(() => { // this must happen after persona service initialLoad
                  this.preferenceApiConnectorService.loadPreferences();
                  this.personaService.setServiceVsAmbState(true);
                  this.selectedTabService.triggerCallbacks();

                  // personaOptionsService is intended to pre load persona menus
                  this.personaOptionsService.initialLoad();

                  // kick off all request for dashboard tiles
                  this.refreshHandlerService.triggerCallbacks([]);
                }, 'appComponent');
              }
            );
          },
          error: error => console.error('Auth: there was an error!', error)
        });
      }
    });

    this.acquireTokenFailureSubscription = this.broadcastService.subscribe('msal:acquireTokenFailure', payload => {
      console.log('acquireTokenFailure', payload);
      const userRequest: AuthenticationParameters = {
        scopes: ['openid']
      };
      this.msalAuthService.acquireTokenRedirect(userRequest);
      // https://docs.microsoft.com/en-us/azure/active-directory/develop/scenario-spa-acquire-token?tabs=javascript
      // https://npm.runkit.com/msal-angular-garbledtech
      // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-angular/classes/_msal_service_.msalservice.html
    });

    this.navbarBuilderService.getNavbarChoiceSubject().subscribe(
      (navbarChoice) => {
        setTimeout(() => {
          this.navbar = navbarChoice;
        });
      }
    );
    this.filterChoices.setFilter('customerToggle', false);
    this.filterChoices.setFilter('flagshipToggle', false);
    this.filterChoices.setFilter('instrumentToggle', false);
    this.filterChoices.setFilter('trackToggle', false);

    this.detailChoicesService.setFilter('detailCustomerIdFilter', 'all');
    this.detailChoicesService.setFilter('detailProductLineFilter', 'all');
    this.detailChoicesService.setFilter('detailAccountFilter', 'all');
    this.detailChoicesService.setFilter('detailAssayFilter', '');
    this.detailChoicesService.setFilter('detailDiseaseStateFilter', '');
    this.detailChoicesService.setFilter('detailIsCapitalFilter', 'true');
    this.detailChoicesService.setFilter('detailIsServiceFilter', 'true');
    this.detailChoicesService.setFilter('detailIsReagentFilter', 'true');
    this.detailChoicesService.setFilter('detailIsFieldServiceContractFilter', 'true');
    this.detailChoicesService.setFilter('detailIsOtherFilter', 'true');
  }
  // https://autologon.microsoftazuread-sso.com/abbott.com/winauth/ssoprobe?client-request-id=ab46d319-3e69-4a7a-af86-ddfc4a1901f9&_=1602685842900
  ngOnDestroy() {
    this.broadcastService.getMSALSubject().next(1);
    if (this.loginSuccessSubscription) {
      this.loginSuccessSubscription.unsubscribe();
    }
    if (this.loginFailureSubscription) {
      this.loginFailureSubscription.unsubscribe();
    }
    if (this.acquireTokenFailureSubscription) {
      this.acquireTokenFailureSubscription.unsubscribe();
    }
    if (this.acquireTokenSuccessSubscription) {
      this.acquireTokenSuccessSubscription.unsubscribe();
    }
  }

  checkAccount() {
    this.loggedIn = !!this.msalAuthService.getAccount();
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    if (isIE) {
      this.msalAuthService.loginRedirect();
    } else {
      this.msalAuthService.loginPopup();
    }
  }

  logout() {
    this.msalAuthService.logout();
  }

  ngAfterViewInit() {
    //this.subscribeToNotifications();
  }

  subscribeToNotifications() {
    this.swPush.requestSubscription({
      serverPublicKey: this.VAPID_PUBLIC_KEY
    })
      .then(sub => this.pushNotificationService.addPushSubscriber(sub).subscribe())
      .catch(err => console.error("Could not subscribe to push notifications!!", err));
  }
}
