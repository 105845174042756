import { Component, OnInit } from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {GridComponent} from '../grid.component';
import {RefreshHandlerService} from '../../api/refresh-handler.service';

@Component({
  selector: 'app-sgm-details',
  templateUrl: './sgm-details.component.html',
  styleUrls: ['./sgm-details.component.scss']
})
export class SGMDetailsComponent extends GridComponent implements OnInit {

  stylePages = {
    '/servicegrossmargin_customerSGM/details': {
      pageTitle: 'Service Gross Margin (SGM)',
      subTitle: '(Country YTD, Customer  12MA)',
      apiRequestHandler: '/metrics/servicegrossmargin_customerSGM/grid',
      backPageLabel: 'Back',
      backPageLink: '/servicegrossmargin_customerSGM',
      dataArrayKey: 'details',
      table: {
          exportFileName: 'service_gross_margin_details.xlsx',
          dataCols: [
              {
                  title: 'Customer',
                  key: 'customer',
                  width: 100
              },
              {
                  title: 'SGM %',
                  key: 'sgm',
                  customStyle:  { 'text-align': 'right' },
                  width: 100
              }
          ]
      }
    },
    '/amb_servicegrossmargin_customerSGM/details': {
      pageTitle: 'Service Gross Margin (SGM) (USD)',
      subTitle: '(Country YTD, Customer  12MA)',
      apiRequestHandler: '/metrics/amb_servicegrossmargin_customerSGM/grid',
      backPageLabel: 'Back',
      backPageLink: '/servicegrossmargin_customerSGM',
      dataArrayKey: 'details',
      table: {
          exportFileName: 'amb_service_gross_margin_details.xlsx',
          dataCols: [
            {
                title: 'Account',
                key: 'customer',
                width: 100
            },
            {
                title: 'Direct Revenue',
                key: 'directrevenue',
                customStyle:  { 'text-align': 'right' },
                width: 60
            },
            {
              title: 'RAP',
              key: 'rap',
              customStyle:  { 'text-align': 'right' },
              width: 60
            },
            {
              title: 'Net Revenue',
              key: 'netrevenue',
              customStyle:  { 'text-align': 'right' },
              width: 60
            },
            {
              title: 'Service Expenses',
              key: 'serviceexpenses',
              customStyle:  { 'text-align': 'right' },
              width: 60
            },
            {
              title: 'SGM %',
              key: 'sgm',
              customStyle:  { 'text-align': 'right' },
              width: 60
            }
        ]
      }
    }
  };

  config = null;
  public state = RequestState.Inactive;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private refreshHandler: RefreshHandlerService
  ) {
    super();
  }

  ngOnInit(): void {

    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: true,
        viewSubtitleText: this.config.subTitle,
        filtered: false
      });

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, band: 0  });
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, band: 0 });
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            this.dataCols.push({
              ref: this.config.table.dataCols[i].key,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              dateFormat: this.config.table.dataCols[i].dateFormat,
              customStyle: this.config.table.dataCols[i].customStyle
            });
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            this.dataRows.push(apiData[this.config.dataArrayKey][r]);
          }

          for (const rowKey in this.dataRows) {
            if (this.dataRows.hasOwnProperty(rowKey)) {
              const row = this.dataRows[rowKey];
              for (const rowCol in row) {
                if (rowCol in row) {
                  let value = row[rowCol];
                  if (['sgm'].includes(rowCol) && value === 'N/A') {
                    value = null;
                    console.log('value', value);
                  }
                  this.dataRows[rowKey][rowCol] = value;
                }
              }
            }
          }

          this.updateGrid();
        }
      });
    }
  }

}
