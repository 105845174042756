import { Component, OnInit, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { BrParamsService } from '../../br-params.service';
import { BrHistoryRange } from '../../br-history-range.service';
import { NavbarBuilderService } from '../../../navigation/navbar-builder.service';
import { ToolSelectionOptions } from '../../../configuration/nav-filter-selection';
import { ListViewPages } from '../../configuration/details/list-view-pages';
import { RequestState } from '../../../api/request-handler';
import { ApiService } from '../../../api/api.service';
import { RefreshHandlerService } from '../../../api/refresh-handler.service';
import { GridComponent } from '../../../details/grid.component';
import { DetailQueryParamsService } from '../../detail-query-params.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-br-system-comparison-list-view',
  templateUrl: './br-system-comparison-list-view.component.html',
  styleUrls: ['./br-system-comparison-list-view.component.scss']
})

export class BrSystemComparisonListViewComponent extends GridComponent implements OnInit, OnDestroy {

  kpiConfig;
  listViewPages = ListViewPages;
  public apiData;
  public apiMultiData;
  public state = RequestState.Inactive;
  apiUrl;
  apiDisplayTitle;
  requestHandler;
  stateChangeHandler;
  dataCols = [];
  dataRows = [];
  multiDataRows = [];
  chartData = [];
  multiChartData = [];
  chartRowCount = 0;
  chartLabels = [];
  chartOptions;
  apiFolder;
  apiDrillDown;
  ParentCustomer;
  apiChartType;
  returnLink;
  detailQueryCompleteSubscription;
  returnLinkSubscription;
  queryParamsSubscription;
  routeUrlSubscription;
  multiChartCategories;
  multiChartSeries;
  multiChartSeriesArray;
  chartBottomLimit;
  chartTopLimit;
  chartMajorUnit;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private brParamsService: BrParamsService,
    private brHistoryRange: BrHistoryRange,
    private location: Location,
    private apiService: ApiService,
    private detailQueryParamsService: DetailQueryParamsService,
    private route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    this.detailQueryCompleteSubscription = this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.listViewPages.hasOwnProperty(currentRouterLocation)) {
        this.kpiConfig = this.listViewPages[currentRouterLocation];
      }

      this.apiUrl = this.kpiConfig.apiSource;
      this.apiFolder = this.kpiConfig.apiFolder;
      this.apiDrillDown = 'timeline_view';
      if (this.kpiConfig.apiDrillDown) { this.apiDrillDown = this.kpiConfig.apiDrillDown; }
      if (this.kpiConfig.apiChartType) { this.apiChartType = this.kpiConfig.apiChartType; }

      // console.log('DRILL DOWN ITEM', this.apiChartType);

      this.apiDisplayTitle = this.kpiConfig.apiTitle;
      // console.log('Display Title', this.apiDisplayTitle);
      // console.log('KPI Config Follow-up Link: ', this.kpiConfig.apiFollowUpLink);

      this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_detail'));

      this.navbarBuilderService.setNavbarChoice('business-review');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        dashboardSelector: false,
        toolSelection: ToolSelectionOptions.businessreview,
        bookmarksFlag: false,
        viewTitle: false,
        viewTitleText: '',
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      this.requestHandler = this.apiService.get(this.apiUrl);
      this.requestHandler.sendRequest(this.apiUrl);

      // no need to send request here as chart tile has already done so
      this.stateChangeHandler = (state) => {
        this.state = state;

        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          this.apiData = JSON.parse(response.body);
          // console.log('API DATA', this.apiData);

          // format dataCols for kendo component
          this.dataCols = this.kpiConfig.table.columns;
          if (this.apiData.columns) { this.dataCols = this.apiData.columns; }

          this.chartBottomLimit = this.apiData.chartBottomLimit;
          this.chartTopLimit = this.apiData.chartTopLimit;

          // format dataRows for kendo component
          this.dataRows = [];
          this.chartData = [];
          this.multiChartData = [];
          this.chartLabels = [];

          this.multiChartCategories = [];
          this.multiChartSeries = {};
          if (this.apiChartType === 'MultiBar') {
            const firstRow = this.apiData.values[0];
            for (let c = 0; c < this.dataCols.length; c++) {
              const dataColConfig = this.dataCols[c];
              if (dataColConfig.ref !== 'customer') {
                let colColor = '';
                if (dataColConfig.ref + 'bgColor' in firstRow) {
                  colColor = firstRow[dataColConfig.ref + 'bgColor'];
                }
                // if (dataColConfig.ref + 'textColor' in firstRow) {
                //   colColor = firstRow[dataColConfig.ref + 'textColor'];
                // }
                if (!('included_in_chart' in dataColConfig) && dataColConfig.included_in_chart !== false) {
                  this.multiChartSeries[dataColConfig.ref] = {
                    color: colColor,
                    data: [],
                    name: dataColConfig.description
                  };
                }
              }
            }
          }
          let highestValue = 0;
          for (let i = 0; i < this.apiData.values.length; i++) {
            const apiDataRow = this.apiData.values[i];
            const dataRow = {};
            this.chartLabels.push(' ');
            this.chartLabels.push(this.apiData.values[i].customer);
            if (i == 0) { this.ParentCustomer = this.apiData.values[i].customer; }
            for (let c = 0; c < this.dataCols.length; c++) {
              const dataColConfig = this.dataCols[c];
              let colorCalc = '';
              let textColor = '';
              if (dataColConfig.ref + 'bgColor' in apiDataRow) {
                colorCalc = apiDataRow[dataColConfig.ref + 'bgColor'];
              }
              if (dataColConfig.ref + 'textColor' in apiDataRow) {
                textColor = apiDataRow[dataColConfig.ref + 'textColor'];
              }
              dataRow[dataColConfig.ref] = {
                value: apiDataRow[dataColConfig.ref],
                bgcolor: colorCalc,
                textColor: textColor
              };
              if (!('included_in_chart' in dataColConfig) && dataColConfig.included_in_chart !== false) {
                if (this.apiChartType === 'MultiBar') {
                  if (dataColConfig.ref !== 'customer') {
                    this.multiChartSeries[dataColConfig.ref].data.push(apiDataRow[dataColConfig.ref]);
                    if (!isNaN(apiDataRow[dataColConfig.ref]) && highestValue < apiDataRow[dataColConfig.ref]) {
                      highestValue = apiDataRow[dataColConfig.ref];
                    }
                  } else {
                    this.multiChartCategories.push(apiDataRow[dataColConfig.ref]);
                  }
                } else {
                  this.chartData.push(dataRow[dataColConfig.ref].value);
                }
              }
              this.chartRowCount++;
            }

            if ('uck' in apiDataRow) {
              dataRow['uck'] = {value: apiDataRow.uck};
            }
            if ('acct_pres_name' in apiDataRow) {
              dataRow['acct_pres_name'] = {value: apiDataRow.acct_pres_name};
            }
            this.dataRows.push(dataRow);
          }
          this.chartMajorUnit = highestValue / 5;
          if (this.chartMajorUnit < 1) {
            this.chartMajorUnit = 1;
          } else {
            this.chartMajorUnit = Math.floor(this.chartMajorUnit);
          }

          if (this.apiChartType === 'MultiBar') {
            this.chartLabels = this.multiChartCategories;
            this.multiChartSeriesArray = [];
            for (const item in this.multiChartSeries) {
              this.multiChartSeriesArray.push(this.multiChartSeries[item]);
            }
          }
          this.updateGrid();
          // console.log('CHART DATA', this.chartData);
          // console.log('CHART DATA LENGTH', this.apiData.values.length);

          /*
          this.apiMultiData = JSON.parse(response.body);

          // format dataRows for kendo component
          this.multiDataRows = []
          this.multiChartData = [];

          for (let i = 0; i < this.apiData.values.length; i++) {
            const apiMultiDataRow = this.apiData.values[i];
            const multiDataRow = {};
            this.chartLabels.push(' ');
            this.chartLabels.push(this.apiData.values[i].customer);

            for (let j = 0; j < this.apiData.values[i].length; j++) {
              const apiMultiDataRowArray = this.apiData.value[i][j];
              this.multiChartData[j] = []

              for (let c = 0; c < this.dataCols.length; c++) {
                const dataColConfig = this.dataCols[c];
                multiDataRow[dataColConfig.ref] = {
                  value: apiMultiDataRowArray[dataColConfig.ref]
                };
                this.multiChartData[j].push( multiDataRow[ dataColConfig.ref ].value );
                this.chartRowCount++;
              }
            }

            for (let c = 0; c < this.dataCols.length; c++) {
              const dataColConfig = this.dataCols[c];
              multiDataRow[ dataColConfig.ref ] = {
                value: apiMultiDataRow[ dataColConfig.ref ]
              };
              this.chartData.push( multiDataRow[dataColConfig.ref].value );
              this.chartRowCount++;
            }


            if ('uck' in apiMultiDataRow) {
              multiDataRow['uck'] = { value: apiMultiDataRow.uck };
            }
            if ('customer_num' in apiMultiDataRow) {
              multiDataRow['customer_num'] = { value: apiMultiDataRow.customer_num };
            }
            if ('acct_pres_name' in apiMultiDataRow) {
              multiDataRow['acct_pres_name'] = { value: apiMultiDataRow.acct_pres_name };
            }
            this.multiDataRows.push(multiDataRow);
          } //*/
        }
      };
      this.requestHandler.onStateChange(this.stateChangeHandler);
      this.updateGrid();
    });

    this.returnLinkSubscription = this.detailQueryParamsService.getReturnLink().subscribe((pathDetails) => {
      this.returnLink = pathDetails;
    });
    this.queryParamsSubscription = this.route.queryParams.subscribe(params => {
      this.detailQueryParamsService.prepReturnParams(params);
      this.detailQueryParamsService.buildPersistedValuesFromParams(params);
    });
    this.routeUrlSubscription = this.route.url.subscribe((url) => {
      this.detailQueryParamsService.prepReturnLink(url);
      this.detailQueryParamsService.processReturnLink();
    });
  }

  routerBack() {
    this.location.back();
  }

  ngOnDestroy(): void {
    if (this.stateChangeHandler) {
      this.requestHandler.removeStateListener(this.stateChangeHandler);
    }
    this.detailQueryCompleteSubscription.unsubscribe();
    this.returnLinkSubscription.unsubscribe();
    this.queryParamsSubscription.unsubscribe();
    this.routeUrlSubscription.unsubscribe();
  }

  isThumbnailMonths() {
    return (this.apiFolder === 'ticket_errors' || this.apiFolder === 'assay_test_counts');
  }

  getDetailLink() {
    return '/businessReview/' + this.apiFolder + '/' + this.apiDrillDown;
  }

  getQueryParams(dataItem) {
    let params = {};
    if ('uck' in dataItem) {
      params['ckey'] = dataItem.uck.value;
    }
    if ('acct_pres_name' in dataItem) {
      params['acct_pres_name'] = dataItem.acct_pres_name.value;
    }
    if ('customer_num' in dataItem) {
      params['customer_num'] = dataItem.customer_num.value;
    }
    return this.detailQueryParamsService.getQueryParams(params);
  }

}
