import { Injectable } from '@angular/core';
import {RequestState} from '../api/request-handler';
import {ApiService} from '../api/api.service';
import {BrParamsService} from './br-params.service';
import {RefreshHandlerService} from '../api/refresh-handler.service';

@Injectable({
  providedIn: 'root'
})
export class PresentationSelectService {
  RequestState = RequestState;
  protected requestHandler;
  protected requestHandler2;
  protected getPresentationSource = '/qbr/user_presentations/custom/get_user_presentation';
  protected refreshHandleKey;
  public state = RequestState.Inactive;

  constructor(
    private brParamsService: BrParamsService,
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
  ) { }

  getUserPresentation() {
    this.requestHandler2 = this.callApiSource(this.getPresentationSource, 0);
    this.requestHandler2.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler2.getResponse();
        const apiData = JSON.parse(response.body);
        // console.log('getUserPresentation', apiData);
        this.brParamsService.force(apiData.br_presentation_settings);
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.getPresentationSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.getPresentationSource));
  }

  selectPresentation(presentationId) {
    this.brParamsService.set('presentationId', presentationId);
    this.getUserPresentation();
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }
}
