import {Personas} from './personas';

export enum ToolSelectionOptions {mycrl, ct, alerts, gsalerts, businessreview}
export enum DashboardOptions {dashboard, customer, instruments, ambassador, controltower}

export class NavFilterSelection {
  preferences: boolean;
  personaSelector?: boolean;
  dashboardSelector: boolean;
  selectedDashboard?: DashboardOptions;
  search?: boolean;
  toolSelection: ToolSelectionOptions;
  legacyCustomersFlag?: boolean;
  myVsAllInstrumentsFlag?: boolean;
  customerWTrackSystemsFlag?: boolean;
  flagshipProductLinesFlag?: boolean;
  bookmarksFlag: boolean;
  navigateBackwards?: boolean;
  navigateBackwardsLabel?: string;
  navigateBackwardsLink?: string = "/";
  viewTitle: boolean;
  viewTitleText: string;
  viewSubtitle: boolean;
  viewSubtitleText: string;
  filtered?: boolean;
  personaOptions?: Personas[];
  detailFilters?: string[];
  detailMetricId?: any;
  prefCurrencyChoices?: boolean;
}
