<div style="margin-top:-70px;">
  <button [routerLink]="'/businessReview'">KPI Home</button>
  <button *ngIf="this.returnLink !== undefined" [routerLink]="this.returnLink.url" [queryParams]="this.returnLink.params">Return</button>
    <div style="width: 100%;">
      <app-filter-presentation-header></app-filter-presentation-header>
    </div>
  <table class='sales-details-table'>
  <tr>
    <td colspan="2" style="font-weight:bold;font-size:15pt;color:Highlight">{{apiDisplayTitle}}</td>
  </tr>
  <tr style="color: Highlight; font-weight: bold;">
    <td>CUSTOMER: &nbsp;&nbsp;</td>
    <td style="text-align:right;">{{CustomerName}}</td>
  </tr>
</table>
<app-state-handler [requestState]="state" [data]="apiData">
  <div *ngIf="apiData !== null">
    <kendo-grid #grid="kendoGrid"
                [data]="gridData"
                [pageable]="true"
                [pageSize]="gridState.take"
                [skip]="gridState.skip"
                [filter]="gridState.filter"
                filterable="menu"
                [sort]="gridState.sort"
                [sortable]="true"
                [resizable]="true"
                (pageChange)="pageChange($event)"
                (sortChange)="sortChange($event)"
                (filterChange)="filterChange($event)"
                style="max-height: 650px">
      <ng-container *ngFor="let col of dataCols">
        <kendo-grid-column [field]="col.ref+'.value'"
                           [title]="col.description"
                           [width]="col.width">
          <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
            {{column.title}}
          </ng-template>
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <span *ngIf="col.ref !== 'TICKET' || routeUrl !== '/businessReview/ticket_errors/list_view'">
              {{dataItem[col.ref].value}}
            </span>
            <a *ngIf="col.ref === 'TICKET' && routeUrl === '/businessReview/ticket_errors/list_view'"
               target="_blank"
               [href]="'https://gsr.abbott.com/gsr/ticket_details/ticket_details.php?ticket='+dataItem[col.ref].value">
              {{dataItem[col.ref].value}}
            </a>
          </ng-template>
        </kendo-grid-column>
      </ng-container>
    </kendo-grid>
  </div>
</app-state-handler>
  <div style="width: 100%; text-align: center;">
    <app-filter-presentation-footer></app-filter-presentation-footer>
  </div>
  </div>
