import { RowParserInterface } from '../row-parser-interface';

export class StackTotals implements RowParserInterface {
  get(sourceData, params) {
    const rowData = {
      description: params.label
    };

    for (const index of Object.keys(sourceData[0])) {
      if (index !== 'description') {
        rowData[index] = 0;
        for (const sourceItem of sourceData) {
          rowData[index] = rowData[index] + parseFloat(sourceItem[index]);
        }
      }
    }
    return rowData;
  }
}
