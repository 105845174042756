import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'customDateFormatter'})
export class CustomDateFormatterPipe implements PipeTransform {

  constructor(@Inject(LOCALE_ID) private locale: string) {
    
  }

  transform(value: string, inputFormat: string, outputFormat: string): string {
    if (value !== undefined) {
      if (inputFormat !== undefined && inputFormat === 'yyyyMMdd') {
        return [value.slice(0, 4), "-", value.slice(4, 6), "-", value.slice(6, 8)].join('');
      }      
      return formatDate(value, outputFormat, this.locale);
    }
    return value;
  }
}
