import {CompositeFilterDescriptor, FilterDescriptor, process, SortDescriptor, State} from '@progress/kendo-data-query';
import {PageChangeEvent, DataStateChangeEvent} from '@progress/kendo-angular-grid';
import * as _ from 'lodash';
import {ExcelExportData} from '@progress/kendo-angular-excel-export';

export class GridComponent {
  dataRows = [];
  dataCols = [];
  filterOnChildProperty = null;

  gridState: State = {
    skip: 0,
    take: 100,
    group: [],
    sort: [],
    filter: { logic: 'and', filters: [] }
  };
  gridData: any;
  exportState: State = {};

  constructor() {
    this.allData = this.allData.bind(this);
  }

  pageChange(gridState: PageChangeEvent): void {
    this.gridState.skip = gridState.skip;
    this.gridState.take = gridState.take;
    this.updateGrid();
  }

  sortChange(sort: SortDescriptor[]): void {
    // if sort changes return to first page
    // if (!_.isEqual(this.gridState.sort, sort)) {
    //   this.gridState.skip = 0;
    // }
    this.gridState.sort = sort;
    this.updateGrid();
  }

  filterChange(filter: CompositeFilterDescriptor): void {
    // if filter change is called return to first page
    // menu filters are automatically updating this.gridState.filter making comparison not feasible
    // to do this without a check is a necessary compromise
    this.gridState.skip = 0;
    this.gridState.filter = filter;
    this.updateGrid();
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    this.updateGrid();
  }

  updateGrid() {
    /**
     * This was an attempt to make filters work the way existing data was structured
     * but it does not allow the indicator to work showing filters have been selected
     */
    // this.gridState.filter = this.updateFiltersToHandleChildProperty(this.gridState.filter);
    this.gridData = process(this.dataRows, this.gridState);
  }

  // use disabled
  updateFiltersToHandleChildProperty(inFilter) {
    if (this.filterOnChildProperty) {
      for (let key = 0; key < inFilter.filters.length; key++) {
        const item = inFilter.filters[key];
        if ((item as FilterDescriptor).field !== undefined
          && ((item as FilterDescriptor).field as string).indexOf(this.filterOnChildProperty) === -1
        ) {
          (inFilter.filters[key] as FilterDescriptor).field += '.' + this.filterOnChildProperty;
        } else if ((item as CompositeFilterDescriptor).filters !== undefined) {
          inFilter.filters[key] = this.updateFiltersToHandleChildProperty(item); // note: recursion
        }
      }
      return inFilter;
    }
  }

  public allData(): ExcelExportData {
    const result: ExcelExportData =  {
      data: process(this.dataRows, this.exportState).data,
      group: []
    };
    return result;
  }

  public forceDataReload() {
    // this.updateGrid();
    this.loadData();
  }

  protected loadData() {
    console.log('refresh called!!');
  }

  protected detectMob() {
    return /iPad|iPhone|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  public getHeight() {
    return -1;
  }

  public getMaxHeight(): string {
    const userWindowHeight = window.screen.availHeight;
    const userWindowWidth = window.screen.availWidth;

    if (userWindowHeight < 300) {
      return 300 + 'px';
    } else if (userWindowWidth <= 768) {
      return (userWindowHeight - 100) + 'px';
    } else if (this.detectMob()) {
      return (userWindowHeight - 150) + 'px';
    }
    return (userWindowHeight - 250) + 'px';
  }

}
