import { Component, OnInit } from '@angular/core';
import {BrParamsService} from '../br-params.service';

@Component({
  selector: 'app-filter-presentation-footer',
  templateUrl: './filter-presentation-footer.component.html',
  styleUrls: ['./filter-presentation-footer.component.scss']
})
export class FilterPresentationFooterComponent implements OnInit {
  locationDescription;
  productDescription;
  businessSegment;
  servicingGroup;

  constructor(
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    this.locationDescription = this.brParamsService.get('location_description');
    this.productDescription = this.brParamsService.get('product_description');
    this.businessSegment = this.brParamsService.get('cust_bs_description');
    this.servicingGroup = this.brParamsService.get('sg_description');
    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'location_description') {
        this.locationDescription = this.brParamsService.get('location_description');
      }
      if (brParamKey === 'product_description') {
        this.productDescription = this.brParamsService.get('product_description');
      }
      if (brParamKey === 'cust_bs_description') {
        this.businessSegment = this.brParamsService.get('cust_bs_description');
      }
      if (brParamKey === 'sg_description') {
        this.servicingGroup = this.brParamsService.get('sg_description');
      }
    });
  }
}
