import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import {AuthenticationService} from '../../authentication/authentication.service';

@Component({
  selector: 'app-bookmarks',
  templateUrl: './bookmarks.component.html',
  styleUrls: ['./bookmarks.component.scss']
})
export class BookmarksComponent implements OnInit {
  faBookmark = faBookmark;
  RequestState = RequestState;
  @Input() displayTitles;

  bkMenuViable = false;
  anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  popupAlign = { horizontal: 'left', vertical: 'top' };
  collision = { horizontal: 'fit', vertical: 'fit' };

  @ViewChild('bkanchor') public anchor: ElementRef;
  @ViewChild('bkpopup', { read: ElementRef }) public popup: ElementRef;

  apiRoute = '/links/en-US-US';
  requestHandler;
  apiState;
  data = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authService.onAuth(() => {
      this.requestHandler = this.apiService.get(this.apiRoute);
      this.requestHandler.sendRequest(this.apiRoute, []);
      this.requestHandler.onStateChange((state) => {
        this.apiState = state;
        if (state === RequestState.Complete) {
          const response = this.requestHandler.getResponse();
          const bookmarks = JSON.parse(response.body);

          const links = bookmarks.globallink;
          links.sort(this.sortBookmarks);

          this.data = links;
        }
      });
    });
  }

  sortBookmarks(i, j) {
    const iValue = i.displayname.toUpperCase();
    const jValue = j.displayname.toUpperCase();
    let compare = 0;
    if (iValue > jValue) {
      compare = 1;
    } else if (iValue < jValue) {
      compare = -1;
    }
    return compare;
  }

  onToggle(): void {
    this.bkMenuViable = !this.bkMenuViable;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.bkMenuViable) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if ((this.bkMenuViable && !this.contains(event.target)) || (!this.bkMenuViable && this.contains(event.target))) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }
}
