export const Style3Pages = {
    '/hsi/details': {
        pageTitle: 'HSI - Instruments',
        apiRequestHandler: '/metrics/hsi/details',
        backPageLabel: 'Back',
        backPageLink: '/hsi',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'hsi_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 80
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    width: 50,
                    customStyle:  { 'text-align': 'right' },
                    renderMaintenanceScoreStyle: true,
                    type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    width: 50,
                    customStyle:  { 'text-align': 'right' },
                    renderTSBDueStyle: true,
                    type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'float'
                }
            ]
        }
    },
    '/cpyr/details': {
        pageTitle: 'CPY-R - Instruments',
        apiRequestHandler: '/metrics/cpyr/details',
        backPageLabel: 'Back',
        backPageLink: '/cpyr',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'cpyr_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 80
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    width: 50,
                    customStyle:  { 'text-align': 'right' },
                    renderMaintenanceScoreStyle: true,
                    type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    width: 50,
                    customStyle:  { 'text-align': 'right' },
                    renderTSBDueStyle: true,
                    type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'float'
                }
            ]
        }
    },
    '/servicecost/details': {
        pageTitle: 'Service Cost - Instruments',
        apiRequestHandler: '/metrics/servicecost/details',
        backPageLabel: 'Back',
        backPageLink: '/servicecost',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'servicecost_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 75
                },
                {
                    title: 'Instrument Name',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 75
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                    type: 'float'
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderMaintenanceScoreStyle: true,
                    type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'CPY-T 12mo',
                    key: 'cpy12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderTSBDueStyle: true,
                    type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'Actual Cost',
                    key: 'actualcost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Goal Cost',
                    key: 'goalcost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Parts Cost',
                    key: 'partscostcomponent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Travel Cost',
                    key: 'travelcostcomponent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Labor Cost',
                    key: 'laborcostcomponent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                }
            ]
        }
    },
    '/alconnected/details': {
        pageTitle: 'AbbottLink Installed - Instruments',
        apiRequestHandler: '/metrics/alconnected/details',
        backPageLabel: 'Back',
        backPageLink: '/alconnected',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'al_installed_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 80
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    width: 50,
                    customStyle:  { 'text-align': 'right' },
                    renderMaintenanceScoreStyle: true,
                  type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderTSBDueStyle: true,
                  type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ]
        }
    },
    '/alconnectivity/details': {
        pageTitle: 'AbbottLink Connectivity - Instruments',
        apiRequestHandler: '/metrics/alconnectivity/details',
        backPageLabel: 'Back',
        backPageLink: '/alconnectivity',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'alconnectivity_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 80
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderMaintenanceScoreStyle: true,
                  type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderTSBDueStyle: true,
                  type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ]
        }
    },
    '/hci/details': {
        pageTitle: 'HCI - Instruments',
        apiRequestHandler: '/metrics/hci/details',
        backPageLabel: 'Back',
        backPageLink: '/hci',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'hci_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 80
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderMaintenanceScoreStyle: true,
                  type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderTSBDueStyle: true,
                  type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ]
        }
    },
    '/cpyp/details': {
        pageTitle: 'CPY-P - Instruments',
        apiRequestHandler: '/metrics/cpyp/details',
        backPageLabel: 'Back',
        backPageLink: '/cpyp',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'cpyp_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Country',
                    key: 'country',
                    width: 50
                },
                {
                    title: 'Instrument',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'Serial Number',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 80
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable %',
                    key: 'connectable',
                    hidden: true,
                    width: 50
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderMaintenanceScoreStyle: true,
                  type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true,
                  type: 'int'
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true,
                  type: 'int'
                },
                {
                    title: 'Service Cost',
                    key: 'servicecost',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Service Cost Goal',
                    key: 'servicecostgoal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderTSBDueStyle: true,
                  type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                }
            ]
        }
    },
    '/servicecosthours/details': {
        pageTitle: 'Service Hours - Instruments',
        apiRequestHandler: '/metrics/servicecosthours/details',
        backPageLabel: 'Back',
        backPageLink: '/servicecosthours',
        dataArrayKey: 'instruments',
        table: {
            type: 'style3',
            exportFileName: 'servicecosthours_instrument_details.xlsx',
            contextMenuFieldName: 'instrumentsrno',
            dataCols: [
                {
                    title: 'Customer',
                    key: 'customername',
                    width: 80
                },
                {
                    title: 'Instrument Name',
                    key: 'productlinename',
                    width: 65
                },
                {
                    title: 'SN',
                    key: 'instrumentsrno',
                    renderRefStyle: true,
                    contextMenuItems: [
                        {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                        {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                        {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                        {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                    ],
                    width: 75
                },
                {
                    title: 'HSI',
                    key: 'hsi',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'AL',
                    key: 'al',
                    width: 50,
                    filterable: false,
                    renderALStyle: true
                },
                {
                    title: 'Connectable',
                    key: 'connectable',
                    width: 50,
                    hidden: true
                },
                {
                    title: 'ALC %',
                    key: 'connectableper',
                    alKey: 'al',
                    alConnectableCompareKey: 'connectable',
                    width: 60,
                    renderALConnectableStyle: true,
                  type: 'float'
                },
                {
                    title: 'Test Count',
                    key: 'testcount',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'HCI',
                    key: 'hci',
                    width: 50,
                    renderHCIHSIStyle: true
                },
                {
                    title: 'CPY-T 12mo',
                    key: 'cpy12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Maint Score',
                    key: 'maintscore',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderMaintenanceScoreStyle: true,
                  type: 'int'
                },
                {
                    title: 'TSB Due',
                    key: 'tsbdue',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                    renderTSBDueStyle: true,
                  type: 'int'
                },
                {
                    title: 'CPY-P 12mo',
                    key: 'cpyp12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'SCpT',
                    key: 'costpertest',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
                {
                    title: 'CPY-R 12mo',
                    key: 'cpyr12mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R 3mo',
                    key: 'cpyr3mo',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'CPY-R Goal',
                    key: 'cpyr3goal',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'float'
                },
              {
                title: 'Service Cost',
                key: 'servicecost',
                customStyle:  { 'text-align': 'right' },
                width: 50,
                type: 'int'
              },
              {
                title: 'Service Cost Goal',
                key: 'servicecostgoal',
                customStyle:  { 'text-align': 'right' },
                width: 50,
                type: 'int'
              },
              // appears that these two are duplicates of the Service Cost columns
                // {
                //     title: 'Actual Cost',
                //     key: 'actualcost',
                //     customStyle:  { 'text-align': 'right' },
                //     width: 50
                // },
                // {
                //     title: 'Goal Cost',
                //     key: 'goalcost',
                //     customStyle:  { 'text-align': 'right' },
                //     width: 50
                // },
                {
                    title: 'Parts Cost',
                    key: 'partscostcomponent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Travel Hours 1mo',
                    key: 'travelhours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Travel Cost',
                    key: 'travelcostcomponent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Labor Hours 1mo',
                    key: 'laborhours',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                },
                {
                    title: 'Labor Cost',
                    key: 'laborcostcomponent',
                    customStyle:  { 'text-align': 'right' },
                    width: 50,
                  type: 'int'
                }
            ]
        }
    },
    '/utilization/details': {
    pageTitle: 'Low Utilization - Instruments',
    apiRequestHandler: '/metrics/utilization/details',
    backPageLabel: 'Back',
    backPageLink: '/utilization',
    dataArrayKey: 'instruments',
    table: {
      type: 'style3',
      exportFileName: 'utilization_instrument_details.xlsx',
      contextMenuFieldName: 'instrumentsrno',
      dataCols: [
        {
          title: 'Customer',
          key: 'customername',
          width: 80
        },
        {
          title: 'Country',
          key: 'country',
          width: 50
        },
        {
          title: 'Instrument',
          key: 'productlinename',
          width: 65
        },
        {
          title: 'Serial Number',
          key: 'instrumentsrno',
          renderRefStyle: true,
          contextMenuItems: [
            {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
            {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
            {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
            {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
          ],
          width: 80
        },
        {
          title: 'CPY-R 12mo',
          key: 'cpyr12mo',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'AL',
          key: 'al',
          width: 50,
          filterable: false,
          renderALStyle: true
        },
        {
          title: 'Connectable %',
          key: 'connectable',
          hidden: true,
          width: 50
        },
        {
          title: 'ALC %',
          key: 'connectableper',
          alKey: 'al',
          alConnectableCompareKey: 'connectable',
          width: 60,
          renderALConnectableStyle: true,
          type: 'float'
        },
        {
          title: 'Maint Score',
          key: 'maintscore',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          renderMaintenanceScoreStyle: true,
          type: 'int'
        },
        {
          title: 'HCI',
          key: 'hci',
          width: 50,
          renderHCIHSIStyle: true
        },
        {
          title: 'HSI',
          key: 'hsi',
          width: 50,
          renderHCIHSIStyle: true
        },
        {
          title: 'Service Cost',
          key: 'servicecost',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'Service Cost Goal',
          key: 'servicecostgoal',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'Test Count',
          key: 'testcount',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'TSB Due',
          key: 'tsbdue',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          renderTSBDueStyle: true,
          type: 'int'
        },
        {
          title: 'SCpT',
          key: 'costpertest',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'float'
        },
        {
          title: 'CPY-P 12mo',
          key: 'cpyp12mo',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'CPY-R 3mo',
          key: 'cpyr3mo',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'CPY-R Goal',
          key: 'cpyr3goal',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'float'
        }
      ]
    }
  },
    '/CRLSummary/details': {
    pageTitle: 'Current Instrument Quantity',
    apiRequestHandler: '/metrics/CRLSummary/details',
    backPageLabel: 'Back',
    backPageLink: '/CRLSummary',
    dataArrayKey: 'instruments',
    table: {
      type: 'style3',
      exportFileName: 'current_instrument_quantity.xlsx',
      contextMenuFieldName: 'instrumentsrno',
      dataCols: [
        {
          title: 'Customer',
          key: 'customername',
          width: 80
        },
        /*{
          title: 'Country',
          key: 'country',
          width: 50
        },*/
        {
          title: 'Instrument',
          key: 'productlinename',
          width: 65
        },
        {
          title: 'Serial Number',
          key: 'instrumentsrno',
          renderRefStyle: true,
          contextMenuItems: [
            {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
            {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
            {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
            {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
          ],
          width: 80
        },
        {
          title: 'CPY-R 12mo',
          key: 'cpyr12mo',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'AL',
          key: 'al',
          width: 50,
          filterable: false,
          renderALStyle: true
        },
        {
          title: 'ALC %',
          key: 'connectableper',
          alKey: 'al',
          alConnectableCompareKey: 'connectable',
          width: 60,
          renderALConnectableStyle: true,
          type: 'float'
        },
        {
          title: 'Maint Score',
          key: 'maintscore',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          renderMaintenanceScoreStyle: true,
          type: 'int'
        },
        {
          title: 'HCI',
          key: 'hci',
          width: 50,
          renderHCIHSIStyle: true
        },
        {
          title: 'HSI',
          key: 'hsi',
          width: 50,
          renderHCIHSIStyle: true
        },
        {
          title: 'Service Cost',
          key: 'servicecost',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'Test Count',
          key: 'testcount',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          type: 'int'
        },
        {
          title: 'TSB Due',
          key: 'tsbdue',
          customStyle:  { 'text-align': 'right' },
          width: 50,
          renderTSBDueStyle: true,
          type: 'int'
        },
        {
          title: 'Primary TSS',
          key: 'primarytssid',
          width: 50,
        },
        {
          title: 'Secondary TSS',
          key: 'secondarytssid',
          width: 50,
        },
        {
          title: 'Tertiary TSS',
          key: 'tertiarytssid',
          width: 50,
        }
      ]
    }
  }
    /*
     [Route("CRLSummary")]
        [HttpPost]
        public HttpResponseMessage GetCRLSummaryIntermediate([FromBody]JObject obj)
        {
            var jsonObj = JsonConvert.SerializeObject(obj);
            CRLSummaryIntermediateInput inputObj = JsonConvert.DeserializeObject<CRLSummaryIntermediateInput>(jsonObj);

            inputObj.abbottid = WebAPIUtilies.ConvertAllBlankNull(inputObj.abbottid);
            inputObj.customerid = WebAPIUtilies.ConvertAllBlankNull(inputObj.customerid);
            inputObj.productlineid = WebAPIUtilies.ConvertAllBlankNull(inputObj.productlineid);
            inputObj.sn = WebAPIUtilies.ConvertAllBlankNull(inputObj.sn);
            inputObj.managerid = Convert.ToInt32(WebAPIUtilies.ConvertAllBlankNull(inputObj.managerid.ToString()));
            inputObj.metrictypeid = (int)MetricConstant.MetricType.Billboard;
            CRLSummaryIntermediateOutput CRLSummaryIntermediate = _metricsService.GetCRLSummaryIntermediateData(inputObj);
            return WebAPIUtilies.ConvertJsonToHttpResponseMessage(CRLSummaryIntermediate, this.Request);
        }

        /// <summary>
        /// Retrieves CRL Summary metric details, parsing a JSON request contained in the HttpRequest body.
        /// </summary>
        [Route("CRLSummary/details")]
        [HttpPost]
        public HttpResponseMessage GetCRLSummaryDetails([FromBody]JObject obj)
        {
            var jsonObj = JsonConvert.SerializeObject(obj);
            CRLSummaryDetailInput inputObj = JsonConvert.DeserializeObject<CRLSummaryDetailInput>(jsonObj);

            inputObj.abbottid = WebAPIUtilies.ConvertAllBlankNull(inputObj.abbottid);
            inputObj.customerid = WebAPIUtilies.ConvertAllBlankNull(inputObj.customerid);
            inputObj.productlineid = WebAPIUtilies.ConvertAllBlankNull(inputObj.productlineid);
            inputObj.sn = WebAPIUtilies.ConvertAllBlankNull(inputObj.sn);
            inputObj.managerid = Convert.ToInt32(WebAPIUtilies.ConvertAllBlankNull(inputObj.managerid.ToString()));

            CRLSummaryDetailOutput CRLSummaryDetail = _metricsService.GetCRLSummaryDetailData(inputObj);
            return WebAPIUtilies.ConvertJsonToHttpResponseMessage(CRLSummaryDetail, this.Request);
        }

     */
};
