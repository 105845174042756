import { Input, Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileReference } from '../../../dashboards/tile-reference';
import { TileComponent } from '../../../tiles/base/tile.component';

@Component({
  selector: 'app-br-top-items',
  templateUrl: './br-top-items.component.html',
  styleUrls: ['./br-top-items.component.scss']
})

export class BrTopItemsComponent extends TileComponent implements OnInit {
  protected defaultPreferences() {
    return {
      showTitle: true
    };
  }

  title = 'Customer NPS Scores';
  overallColor = 'gray';
  fieldColor = 'gray';
  techColor = 'gray';

  overallScore = 'N/A';
  fieldScore = 'N/A';
  techScore = 'N/A';

  translateApiDataForChart(apiData: any) {
    //console.log('Test JSON ITEM--', JSON.stringify(apiData));
    //console.log('NPS Details--', apiData.values);
    this.title = apiData.title;
    if (apiData.values['overall'].overallScore) { this.overallScore = apiData.values['overall'].overallScore; }
    if (apiData.values['overall'].overallColor) { this.overallColor = apiData.values['overall'].overallColor };
    if (apiData.values['field'].fieldScore) { this.fieldScore = apiData.values['field'].fieldScore };
    if (apiData.values['field'].fieldColor) { this.fieldColor = apiData.values['field'].fieldColor };
    if (apiData.values['tech'].techScore) { this.techScore = apiData.values['tech'].techScore };
    if (apiData.values['tech'].techColor) { this.techColor = apiData.values['tech'].techColor; };
    // throw new Error('Method not implemented.');
  }
  isDataFound(apiData) {
    const dataFound = super.isDataFound(apiData);
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }
}
