import { Injectable } from '@angular/core';
import {PersonaLevels} from '../configuration/persona-levels';
import {ApiService} from './api.service';
import {RefreshHandlerService} from './refresh-handler.service';
import {AuthenticationService} from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class PersonaOptionsService {
  requestHandlers = {};

  config = PersonaLevels;

  constructor(
    private apiService: ApiService,
    private refreshHandlerService: RefreshHandlerService,
    private authService: AuthenticationService
  ) { }

  initialLoad() {
    // preload values for the menus, so user need not wait
    this.loadPersonaOptions('level1Service');
    this.loadPersonaOptions('level2ServiceCustomers');
    this.loadPersonaOptions('level2ServiceInstruments');
    this.loadPersonaOptions('level1Amb');
  }

  loadPersonaOptions(configType) {
    // api calls
    if (!(configType in this.config)) {
      console.error(
        'persona.service.ts: Cannot call loadPersonaOptions(configType)' +
        ' for a configType not existing in the configuration array.'
      );
    }
    for (const apiCall of this.config[configType].apiCalls) {
      this.loadPersonaOptionList(apiCall.mappingKey, configType);
    }
  }

  loadPersonaOptionList(mappingKey, configType) {
    if (!(mappingKey in this.requestHandlers)) {
      this.requestHandlers[mappingKey] = this.apiService.get(mappingKey);
      this.requestHandlers[mappingKey].sendRequest(mappingKey);
      if (configType === 'level2ServiceCustomers' || configType === 'level2ServiceInstruments') {
        this.refreshHandlerService.onRefreshEvent(
          (index) => {
            this.requestHandlers[mappingKey].sendRequest(mappingKey);
          },
          this.requestHandlers[mappingKey].getRelativeParams(mappingKey)
        );
      }
    } else { // this may not be needed due to the refresh handler
      this.requestHandlers[mappingKey].sendRequest(mappingKey);
    }
  }
}
