import { Component, OnInit, OnDestroy, ViewChild, ElementRef, HostListener } from '@angular/core';
import {faBookmark} from '@fortawesome/free-solid-svg-icons';
import {RequestState} from '../../api/request-handler';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ApiService} from '../../api/api.service';
import {BrParamsService} from '../br-params.service';
import {PresentationSelectService} from '../presentation-select.service';

// #bcfffc
@Component({
  selector: 'app-presentation-select',
  templateUrl: './presentation-select.component.html',
  styleUrls: ['./presentation-select.component.scss']
})
export class PresentationSelectComponent implements OnInit, OnDestroy {
  faBookmark = faBookmark;
  RequestState = RequestState;
  protected requestHandler;
  protected requestHandler2;
  public state = RequestState.Inactive;
  protected savePresentationSource = '/qbr/user_presentations/custom/save_user_presentation';
  protected listPresentationsSource = '/qbr/user_presentations/custom/list_user_presentations';
  listPresentationsData = {
    items: []
  };
  protected refreshHandleKey;

  bkMenuViable = false;
  anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  popupAlign = { horizontal: 'left', vertical: 'top' };
  collision = { horizontal: 'fit', vertical: 'fit' };

  @ViewChild('bkanchor') public anchor: ElementRef;
  @ViewChild('bkpopup', { read: ElementRef }) public popup: ElementRef;

  selectedOption;

  constructor(
    private brParamsService: BrParamsService,
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
    private presentationSelectService: PresentationSelectService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.callApiSource(this.listPresentationsSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        if (!apiData.hasOwnProperty('type') && apiData.type !== 'NO_RESULTS_FOUND') {
          this.listPresentationsData = apiData;
        }
        this.selectedOption = this.brParamsService.get('presentationId'); // not showing selected on reopen after selection
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.listPresentationsSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.listPresentationsSource));
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
    if (this.requestHandler2) {
      this.requestHandler2.cancelSubscription();
    }
  }

  presentationSelect(presentationId) {
    this.brParamsService.set('presentationId', presentationId);
    this.presentationSelectService.selectPresentation(presentationId);
    this.bkMenuViable = false;
  }

  onToggle(): void {
    this.bkMenuViable = !this.bkMenuViable;
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.bkMenuViable) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if ((this.bkMenuViable && !this.contains(event.target)) || (!this.bkMenuViable && this.contains(event.target))) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  nameChange(e) {
    this.brParamsService.set('br_presentation_name', e);
    // this.refreshHandlerService.triggerCallbacks(['br_template_name']);
  }

  saveAs() {
    this.requestHandler = this.callApiSource(this.savePresentationSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.brParamsService.set('list_user_presentations_update', this.brParamsService.get('list_user_presentations_update') + 1);
        this.refreshHandlerService.triggerCallbacks(['list_user_presentations_update']);
        this.requestHandler = this.callApiSource(this.listPresentationsSource, 0);
        this.bkMenuViable = false;
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.savePresentationSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.savePresentationSource));
  }

}
