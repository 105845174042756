import { Component } from '@angular/core';
import {ToggleSwitchComponent} from '../toggle-switch.component';

@Component({
  selector: 'app-customer-toggle',
  templateUrl: './customer-toggle.component.html',
  styleUrls: ['./customer-toggle.component.scss']
})
export class CustomerToggleComponent extends ToggleSwitchComponent {
  filterKey() {
    return 'customerToggle';
  }
  defaultValue() {
    return false;
  }
}
