<!-- see: https://angular-2-training-book.rangle.io/components/projection -->
<div *ngIf="requestState === RequestState.Complete">
    <div *ngIf="hasData(data) || !noDataCheck">
        <ng-content></ng-content>
    </div>
    <div class="state-no-data" *ngIf="noDataCheck && !hasData(data)">
        No Data!
    </div>
</div>
<div class="state-handler-data-not-yet-determined">
    <div *ngIf="requestState === RequestState.Loading">
        <h3>{{title}}</h3>
        <div class="loading-wrapper">
            <div class="spinner-border loading-spinner" role="status">
            </div>
            <div class="loading-text">Loading...</div>
        </div>
    </div>
    <div *ngIf="requestState === RequestState.Inactive">
        <h3>{{title}}</h3>
        <div class="loading-wrapper">
            Inactive!
        </div>
    </div>
    <div *ngIf="requestState === RequestState.Waiting">
        <h3>{{title}}</h3>
        <div class="loading-wrapper">
            <div class="spinner-border loading-spinner" role="status">
            </div>
            <div class="loading-text">Waiting...</div>
        </div>
    </div>
    <div *ngIf="requestState === RequestState.NotCallable">
        <h3>{{title}}</h3>
        <div class="loading-wrapper">
            Request could not be called, a pre requisite failed.
        </div>
    </div>
    <div *ngIf="requestState === RequestState.Error">
        <h3>{{title}}</h3>
        <div class="loading-wrapper">
            There was an error!
        </div>
    </div>
</div>