import { Injectable } from '@angular/core';
import {RefreshHandlerService} from '../api/refresh-handler.service';
import {ApiService} from '../api/api.service';
import {PreferenceSelectionService} from './preference-selection.service';
import {RequestState} from '../api/request-handler';
import {ParameterResolverService} from '../api/parameter-resolver.service';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreferenceApiConnectorService {
  private requestHandler;
  loadComplete = false;
  callbacks = [];

  constructor(
    private apiService: ApiService,
    private refreshHandlerService: RefreshHandlerService,
    private preferenceSelectionService: PreferenceSelectionService,
    private parameterResolverService: ParameterResolverService,
    private http: HttpClient
  ) { }

  onUpdate(callback) {
    this.callbacks.push(callback);
    if (this.loadComplete) {
      callback();
    }
  }

  triggerUpdates() {
    for (const callback of this.callbacks) {
      callback();
    }
    return this;
  }

  getIsLocalCurrency() {
    return this.preferenceSelectionService.getPreference('localcurrency');
  }

  loadPreferences() {
    this.requestHandler = this.apiService.get('/preference');
    this.requestHandler.sendRequest('/preference');
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const preferences = JSON.parse(response.body);
        // save locally
        if (!this.preferenceSelectionService.arePreferencesLoaded()) {
          this.preferenceSelectionService.setPreferences(preferences);
        } else {
          this.preferenceSelectionService.setPreferences(this.preferenceSelectionService.getPreferences());
        }
        // not including specifics here will cause an endless loop that will consume all resources
        this.refreshHandlerService.triggerCallbacks(['cua_primary', 'cua_secondary', 'cua_tertiary', 'primary', 'secondary', 'tertiary']);
        this.triggerUpdates();
        this.loadComplete = true;
      }
    });
    this.refreshHandlerService.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest('/preference');
      },
      this.requestHandler.getRelativeParams('/preference')
    );
  }
  savePreferences(preferences) {
    this.preferenceSelectionService.setPreferences(preferences);
    // this.refreshHandlerService.triggerCallbacks(['dashboardRefresh']);
    this.triggerUpdates();
    const formData = {
      abbottid: this.parameterResolverService.getParameter('abbottid_auth'),
      appid: '2.0',
      appversion: '7.0.2',
      localcurrency: preferences.localcurrency,
      fahrenheit: preferences.fahrenheit,
      celsius: preferences.celsius,
      primary: preferences.primary,
      secondary: preferences.secondary,
      tertiary: preferences.tertiary,
      displayorder: [],
      ambDisplayOrder: []
    };
    for (const value of preferences.displayorder) {
      formData.displayorder.push(value);
    }
    for (const value of preferences.ambDisplayOrder) {
      formData.ambDisplayOrder.push(value);
    }
    // let formData: HttpParams = new HttpParams()
    //   .set('abbottid', this.parameterResolverService.getParameter('abbottid_auth'))
    //   .set('appid', '2.0')
    //   .set('appversion', '7.0.2')
    //   .set('localcurrency', preferences.localcurrency)
    //   .set('fahrenheit', preferences.fahrenheit)
    //   .set('celsius', preferences.celsius)
    //   .set('primary', preferences.primary)
    //   .set('secondary', preferences.secondary)
    //   .set('tertiary', preferences.tertiary);
    // for (const value of preferences.displayorder) {
    //   formData = formData.append('displayorder[]', value);
    // }
    // for (const value of preferences.ambDisplayOrder) {
    //   formData = formData.append('ambDisplayOrder[]', value);
    // }

    const formData2 = new HttpParams({
      fromObject: {
        route: environment.apiProxyRouteKey + '/preference/save',
        post: JSON.stringify(formData) // formData.toString()
      }
    });

    const httpHeaders = new HttpHeaders();
    const url = environment.proxyServerUrl + '/proxy2.php'; // ?' + environment.apiProxyRouteKey + '/preference/save';
    const subscription = this.http.post(url, formData2, {
      headers: httpHeaders,
      responseType: 'text',
      withCredentials: true,
      observe: 'response'
    });
    subscription.subscribe(
      response => {
        console.log('Preferences Saved.');
        // not including specifics here will cause an endless loop that will consume all resources
        this.refreshHandlerService.triggerCallbacks(['primary', 'secondary', 'tertiary']);
      },
      error => {
        console.log('Save Preferences Failed.');
      }
    );
  }
}
