import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import {GridComponent} from '../../details/grid.component';
import {Location} from '@angular/common';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {PersonaService} from '../../api/persona.service';
import {CtServiceService} from '../ct-service.service';
import {faInfoCircle} from '@fortawesome/free-solid-svg-icons';

interface CustomerHealthGridRow {
  // styles are string of color or null
  countryCode: string;
  customerNum: string;
  customerName: string;
  uniqueCustomerKey: string;
  countryName: string;
  area: string;
  overAllHealthScore: number;
  overAllHealthScoreStyle: string;
  tnpsRawScore: number;
  tnpsRawScoreStyle: string;
  snCountIA: number;
  snCountCC: number;
  testVolumeIA: number;
  testVolumeIAScoreStyle: string;
  testVolumeCC: number;
  testVolumeCCScoreStyle: string;
  testCompletionPercent: number;
  testCompletionPercentScoreStyle: string;
  hardStopCount: number;
  hardStopCountScoreStyle: string;
  hardStopScaled: number;
  qcFailPercent: number;
  qcFailPercentScoreStyle: string;
  retestPercent: number;
  retestPercentScoreStyle: string;
  openTicketCount: number;
}

@Component({
  selector: 'app-customer-health-grid',
  templateUrl: './customer-health-grid.component.html',
  styleUrls: ['./customer-health-grid.component.scss']
})
export class CustomerHealthGridComponent extends GridComponent implements OnInit, OnDestroy {
  rowFormatting = [];
  filterOnChildProperty = 'value';
  apiData = null;
  state = null;
  colorLegend = null;
  npsLegend = null;
  dataCols = [];
  columnSets = {
    type: 'all',
    ia_cc: 'all',
    alinity_architect: 'all'
  };
  faInfoCircle = faInfoCircle;
  tooltips = {
    overAllHealthScore: false,
    tnpsRawScore: false,
    snCountIA: false,
    snCountCC: false,
    testVolumeIA: false,
    testVolumeCC: false,
    testCompletionPercent: false,
    hardStopCount: false,
    qcFailPercent: false,
    retestPercent: false,
    openTicketCount: false
  };
  helpWindowTitle = '';
  opened = false;
  helpActions = [{ text: 'Previous' }, { text: 'Next', primary: true }];
  actionsLayout = "normal";
  customerId = null;
  //   {ref: 'test', description: 'Customer Name', width: '230'},
  //   {ref: 'test1', description: 'Overall Health', width: '120'},
  //   {ref: 'test2', description: 'tNPS Raw', width: '120'},
  //   {ref: 'test3', description: 'TOR IA', width: '120'},
  //   {ref: 'test4', description: 'TOR CC', width: '120'},
  //   {ref: 'test5', description: 'Total Test Volume IA', width: '150'},
  //   {ref: 'test6', description: 'Total Test Volume CC', width: '150'},
  //   {ref: 'test7', description: 'Test Completion', width: '120'},
  //   {ref: 'test8', description: 'Hard Stops', width: '120'},
  //   {ref: 'test9', description: 'Controls Out of Range', width: '150'},
  //   {ref: 'test9', description: 'Repeat Test', width: '120'},
  //   {ref: 'test9', description: 'Open Tickets', width: '120'}
  // ];
  dataRows = [];
  apiUrl = 'cua/customer_health_metrics';
  showOnlySingleCustomer = false;
  @Output() onUpdate = new EventEmitter<number>();
  refreshIndexes = [];
  customerScopeChangeEventIndex;
  customerChangeEventIndex;
  requestHandler;
  stateChangeHandler;

  constructor(
    private apiService: ApiService,
    private refreshHandler: RefreshHandlerService,
    private personaService: PersonaService,
    private ctService: CtServiceService
  ) {
    super();
  }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get(this.apiUrl);
    this.requestHandler.sendRequest(this.apiUrl);
    // console.log('refresh params', requestHandler.getRelativeParams(this.apiUrl));
    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.requestHandler.sendRequest(this.apiUrl);
      },
      this.requestHandler.getRelativeParams(this.apiUrl)
    ));
    // console.log('relparams', requestHandler.getRelativeParams(this.apiUrl));

    // no need to send request here as chart tile has already done so
    this.stateChangeHandler = (state) => {
      this.state = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        this.apiData = JSON.parse(response.body);
        this.colorLegend = {
          title: {label: '', bgcolor: '', color: 'black', format: 'num1'},
          items: []
        };
        for (let i = 0; i < this.apiData.legends['Overall Scores'].length; i++) {
          const apiLegendItem = this.apiData.legends['Overall Scores'][i];
          let textcolor = 'black';
          if (apiLegendItem.color === 'red') {
            textcolor = 'white';
          }
          const legendItem = {
            label: Math.floor(apiLegendItem.minVal * 100) + '-' + Math.floor(apiLegendItem.maxVal * 100) + '%',
            count: 0,
            pct: apiLegendItem.maxVal * 100,
            bgcolor: apiLegendItem.color,
            color: textcolor
          };
          this.colorLegend.items.push(legendItem);
          if (this.colorLegend.title.label === '') {
            this.colorLegend.title.label = apiLegendItem.legendTitle;
          }
        }
        this.npsLegend = {
          title: {label: '', bgcolor: '', color: 'black', format: 'num1'},
          items: []
        };
        for (let i = 0; i < this.apiData.legends['tNPS Scores'].length; i++) {
          const apiLegendItem = this.apiData.legends['tNPS Scores'][i];
          const legendItem = {
            label: Math.floor(apiLegendItem.minVal) + '-' + Math.floor(apiLegendItem.maxVal),
            count: 0,
            pct: apiLegendItem.maxVal,
            bgcolor: apiLegendItem.color,
            color: 'black'
          };
          this.npsLegend.items.push(legendItem);
          if (this.npsLegend.title.label === '') {
            this.npsLegend.title.label = apiLegendItem.legendTitle;
          }
        }

        // FORMAT API DATA FOR TABLE
        // format dataCols for kendo component
        this.dataCols = [];
        this.dataCols.push({ref: 'uniqueCustomerKey', description: '', width: 100, hidden: true, filterable: false});
        this.dataCols.push({ref: 'acctEntityName', description: 'Account', width: 100, hidden: true, filterable: false, export: true});
        this.dataCols.push({ref: 'customerName', description: 'Customer Name', width: 300, hidden: false, filterable: true, export: true});
        this.dataCols.push({ref: 'customerNum', description: 'Customer Number', width: 100, hidden: false, filterable: true, export: true});
        this.dataCols.push({ref: 'countryName', description: 'Location', width: 80, hidden: false, filterable: true, export: true});
        this.dataCols.push({ref: 'overAllHealthScore', description: 'Alinity or ARCHITECT Overall Health', width: 80, hidden: false, filterable: false, export: false});
        this.dataCols.push({ref: 'overAllHealthScoreExcelPercent', description: 'Alinity or ARCHITECT Overall Health', width: 80, hidden: true, filterable: false, export: true});
        this.dataCols.push({ref: 'tnpsRawScore', description: 'tNPS Rating', width: 80, hidden: false, filterable: false, export: true});
        this.dataCols.push({ref: 'snCountIA', description: '# S/N\'s IA', width: 80, hidden: false, filterable: false, export: true});
        this.dataCols.push({ref: 'snCountCC', description: '# S/N\'s CC', width: 80, hidden: false, filterable: false, export: true});
        this.dataCols.push({ref: 'testVolumeIA', description: 'Average Test Volume IA', width: 100, hidden: false, filterable: false, export: true});
        this.dataCols.push({ref: 'testVolumeCC', description: 'Average Test Volume CC', width: 100, hidden: false, filterable: false, export: true});
        this.dataCols.push({ref: 'testCompletionPercent', description: 'Test Completion Rate', width: 100, hidden: false, filterable: false, export: false});
        this.dataCols.push({ref: 'testCompletionPercentExcelPercent', description: 'Test Completion Rate', width: 100, hidden: true, filterable: false, export: true});
        this.dataCols.push({ref: 'hardStopScaled', description: 'HS/10000', width: 100, hidden: false, filterable: false, export: true});
        this.dataCols.push({ref: 'qcFailPercent', description: 'Controls Out of Range %', width: 100, hidden: false, filterable: false, export: false});
        this.dataCols.push({ref: 'qcFailPercentExcelPercent', description: 'Controls Out of Range %', width: 100, hidden: true, filterable: false, export: true});
        this.dataCols.push({ref: 'retestPercent', description: 'Repeat Test %', width: 80, hidden: false, filterable: false, export: false});
        this.dataCols.push({ref: 'retestPercentExcelPercent', description: 'Repeat Test %', width: 80, hidden: true, filterable: false, export: true});
        this.dataCols.push({ref: 'openTicketCount', description: 'Open Tickets', width: 80, hidden: false, filterable: false, export: true});

        // for (const colKey in this.apiData.data.heading_section) {
        //   if (this.apiData.data.heading_section.hasOwnProperty(colKey)) {
        //     const col = this.apiData.data.heading_section[colKey];
        //
        //     // col.format
        //     // col.hidden_field
        //     // col.purpose
        //     // col.visible_by_default
        //   }
        // }
        // format dataRows for kendo component
        this.dataRows = [];
        this.onUpdate.emit(this.apiData.data.length);
        for (let i = 0; i < this.apiData.data.length; i++) {
          const apiDataRow: CustomerHealthGridRow = this.apiData.data[i];
          const dataRow = {};
          for (let c = 0; c < this.dataCols.length; c++) {
            const dataColConfig = this.dataCols[c];
            let style = '';
            let textcolor = 'black';
            if (dataColConfig.ref + 'Style' in apiDataRow) {
              style = apiDataRow[dataColConfig.ref + 'Style'];
              if (style === 'red') {
                textcolor = 'white';
              }
            }
            if (dataColConfig.ref + 'ScoreStyle' in apiDataRow) {
              style = apiDataRow[dataColConfig.ref + 'ScoreStyle'];
              if (style === 'red') {
                textcolor = 'white';
              }
            }
            if (style === 'red') {
              style = '#FF6347';
            }
            if (style === '#0ee30e') {
              style = '#52B84D';
            }
            let value;
            if (dataColConfig.ref.includes('ExcelPercent')) {
              value = apiDataRow[dataColConfig.ref.replace('ExcelPercent', '')]*100;
            } else {
              value = apiDataRow[dataColConfig.ref];
            }
            dataRow[dataColConfig.ref] = {
              value: value,
              bgcolor: style,
              color: textcolor
            };
          }
          this.dataRows.push(dataRow);
        }
        // this.dataRows = this.apiData.data.data_section;
        // for (const colKey in this.apiData.data.data_section) {
        //   if (this.apiData.data.heading_section.hasOwnProperty(colKey)) {
        //     const col = this.apiData.data.heading_section[colKey];
        //     this.dataCols.push({
        //       ref: colKey,
        //       description: col.label,
        //       width: col.width
        //     });
        //     // col.format
        //     // col.hidden_field
        //     // col.purpose
        //     // col.visible_by_default
        //   }
        // }


        this.updateGrid();
        this.updateCustomerIdSelection();
      }
    };
    this.requestHandler.onStateChange(this.stateChangeHandler);
    this.updateGrid();
/*
    this.customerScopeChangeEventIndex = this.ctService.onCustomerScopeChange(() => {
      this.updateCustomerScope();
    });

    this.refreshIndexes.push(this.refreshHandler.onRefreshEvent(
      (index) => {
        this.updateCustomerIdSelection();
      },
      ['ct_persona_type', 'ct_persona_value']
    ));

    this.customerChangeEventIndex = this.ctService.onCustomerSelectionChange(() => {
      if (this.ctService.getCustomerId() === 'all') {
        this.updateCustomerScope();
      }
    });*/
  }

  ngOnDestroy(): void {
    for (const index of this.refreshIndexes) {
      this.refreshHandler.cancelRefreshEvent(index);
    }
    // this.ctService.clearCustomerScopeChangeEvent(this.customerScopeChangeEventIndex);
    // this.ctService.clearCustomerSelectionChangeEvent(this.customerChangeEventIndex);
    this.requestHandler.removeStateListener(this.stateChangeHandler);
  }

  updateCustomerIdSelection() {
    const level1PersonaChoice = this.personaService.getLevel1Choice();
    if (level1PersonaChoice.type === 'Customer') {
      this.customerId = level1PersonaChoice.value;
      // this.updateCustomerScope();
      this.ctService.setCustomerId(level1PersonaChoice.value, level1PersonaChoice.label);
    }
  }

  updateCustomerScope() {
    this.showOnlySingleCustomer = this.ctService.getCustomerScope();
    this.customerId = this.personaService.getPersonaOption(PersonaTypes.Customer);
    if (this.showOnlySingleCustomer && this.customerId && this.customerId !== 'all') {
      this.filterChange({ logic: 'and', filters: [
        {
          logic: 'and',
          filters: [
            { field: 'oneview_customerid_int.value', operator: 'eq', value: this.customerId }
          ]
        }
      ]});
      this.updateGrid();
    } else {
      this.filterChange({ logic: 'and', filters: [] });
      this.updateGrid();
    }
  }

  selectCustomer(dataItem) {
    this.customerId = dataItem.uniqueCustomerKey.value;
    const customerName = dataItem.customerName.value;
    this.personaService.setCustomerOverride(true);
    this.personaService.setPersonaOption(PersonaTypes.Customer, this.customerId, customerName);
    // this.updateCustomerScope();
    this.ctService.setCustomerId(this.customerId, customerName);
  }

  // hot buttons
  // https://www.telerik.com/kendo-angular-ui/components/grid/api/ColumnBase/

  setColumns(type) {
    if (type === 'all') {
      this.columnSets.type = 'all';
    } else if (type === 'tcr') {// Test Completion Rate
      this.columnSets.type = 'tcr';
    } else if (type === 'nps') {
      this.columnSets.type = 'nps';
      // overall_nps_survey_score
      // field_service_nps_survey_score
    } else if (type === 'alcm') {
      this.columnSets.type = 'alcm';
      // alcm_daily_score
    } else if (type === 'tor') {
      this.columnSets.type = 'tor';
      // count_alinity_ia_tor
      // count_alinity_cc_tor
    } else if (type === 'tv') {// Test Volume
      this.columnSets.type = 'tv';
      // total_test_volume_ia_completed
      // total_test_volume_cc_completed
    } else if (type === 'ia_cc') {
      this.columnSets.ia_cc = 'all';
    } else if (type === 'ia') {
      this.columnSets.ia_cc = 'ia';
    } else if (type === 'cc') {
      this.columnSets.ia_cc = 'cc';
    } else if (type === 'alinity_architect') {
      this.columnSets.alinity_architect = 'all';
    } else if (type === 'alinity') {
      this.columnSets.alinity_architect = 'alinity';
    } else if (type === 'architect') {
      this.columnSets.alinity_architect = 'architect';
    }
    // always show non metrics:
    //   customer_num, customer, oneview_customerid_int,
    //   country_desc, locallevel1, locallevel2, business_segment, servicing_group
    let keeps = [
      'overall_health_score',
      'overall_nps_survey_score',
      'field_service_nps_survey_score',
      'alcm_daily_score',
      'latest_worst_abbottlink_last_conn_days',
      'count_alinity_ia_pls',
      'count_alinity_cc_pls',
      'count_alinity_ia_tor',
      'count_alinity_cc_tor',
      'total_test_volume_ia_completed',
      'total_test_volume_cc_completed',
      'test_completion_rate',
      'proc_and_run_hard_stop_count',
      'repeat_tests_rate',
      'open_sd_ticket_qty'
    ];
    this.hideCols(keeps);
    if (this.columnSets.ia_cc === 'ia') {
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_pls');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_cc_completed');
    }
    if (this.columnSets.ia_cc === 'cc') {
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_pls');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_ia_completed');
    }
    if (this.columnSets.alinity_architect === 'alinity') {}
    if (this.columnSets.alinity_architect === 'architect') {
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_pls');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_pls');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
    }
    if (this.columnSets.type !== 'all') {
      keeps = this.removeItemFromArray(keeps, 'overall_health_score');
      keeps = this.removeItemFromArray(keeps, 'latest_worst_abbottlink_last_conn_days');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_pls');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_pls');
      keeps = this.removeItemFromArray(keeps, 'proc_and_run_hard_stop_count');
      keeps = this.removeItemFromArray(keeps, 'repeat_tests_rate');
      keeps = this.removeItemFromArray(keeps, 'open_sd_ticket_qty');
    }
    if (this.columnSets.type === 'tcr') {
      keeps = this.removeItemFromArray(keeps, 'overall_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'field_service_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'alcm_daily_score');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_ia_completed');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_cc_completed');
    }
    if (this.columnSets.type === 'nps') {
      keeps = this.removeItemFromArray(keeps, 'test_completion_rate');
      keeps = this.removeItemFromArray(keeps, 'alcm_daily_score');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_ia_completed');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_cc_completed');
    }
    if (this.columnSets.type === 'alcm') {
      keeps = this.removeItemFromArray(keeps, 'test_completion_rate');
      keeps = this.removeItemFromArray(keeps, 'overall_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'field_service_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_ia_completed');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_cc_completed');
    }
    if (this.columnSets.type === 'tor') {
      keeps = this.removeItemFromArray(keeps, 'test_completion_rate');
      keeps = this.removeItemFromArray(keeps, 'overall_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'field_service_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'alcm_daily_score');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_ia_completed');
      keeps = this.removeItemFromArray(keeps, 'total_test_volume_cc_completed');
    }
    if (this.columnSets.type === 'tv') {
      keeps = this.removeItemFromArray(keeps, 'test_completion_rate');
      keeps = this.removeItemFromArray(keeps, 'overall_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'field_service_nps_survey_score');
      keeps = this.removeItemFromArray(keeps, 'alcm_daily_score');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
      keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
    }
    // keeps = this.removeItemFromArray(keeps, 'test_completion_rate');
    // keeps = this.removeItemFromArray(keeps, 'overall_nps_survey_score');
    // keeps = this.removeItemFromArray(keeps, 'field_service_nps_survey_score');
    // keeps = this.removeItemFromArray(keeps, 'alcm_daily_score');
    // keeps = this.removeItemFromArray(keeps, 'count_alinity_ia_tor');
    // keeps = this.removeItemFromArray(keeps, 'count_alinity_cc_tor');
    // keeps = this.removeItemFromArray(keeps, 'total_test_volume_ia_completed');
    // keeps = this.removeItemFromArray(keeps, 'total_test_volume_cc_completed');

    this.showCols(keeps);
  }

  removeItemFromArray(array, value) {
    const index = array.indexOf(value);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  hideCols(colsToHide) {
    for (const colKey in this.dataCols) {
      if (colKey in this.dataCols) {
        const colItem = this.dataCols[colKey];
        if (colsToHide.indexOf(colItem.ref) !== -1) {
          this.dataCols[colKey].hidden = true;
        }
      }
    }
  }

  showCols(colsToShow) {
    for (const colKey in this.dataCols) {
      if (colKey in this.dataCols) {
        const colItem = this.dataCols[colKey];
        if (colsToShow.indexOf(colItem.ref) !== -1) {
          this.dataCols[colKey].hidden = false;
        }
      }
    }
  }

  updateTooltipTitle(type) {
    switch (type) {
      case 'overAllHealthScore':
        this.helpWindowTitle = 'Overall Health';
        break;
      case 'tnpsRawScore':
        this.helpWindowTitle = 'tNPS Rating';
        break;
      case 'snCountIA':
        this.helpWindowTitle = 'IA';
        break;
      case 'snCountCC':
        this.helpWindowTitle = 'CC';
        break;
      case 'testVolumeIA':
        this.helpWindowTitle = 'Average Test Volume IA';
        break;
      case 'testVolumeCC':
        this.helpWindowTitle = 'Average Test Volume CC';
        break;
      case 'testCompletionPercent':
        this.helpWindowTitle = 'Test Completion';
        break;
      case 'hardStopCount':
        this.helpWindowTitle = 'Hard Stops';
        break;
      case 'qcFailPercent':
        this.helpWindowTitle = 'Controls Out of Range';
        break;
      case 'retestPercent':
        this.helpWindowTitle = 'Repeat Tests';
        break;
      case 'openTicketCount':
        this.helpWindowTitle = 'Open Tickets';
        break;
    }
  }

  toggleTooltip(type, changeTo = null) {
    this.opened = true;
    if (changeTo === null) {
      this.tooltips[type] = !this.tooltips[type];
    } else {
      this.tooltips[type] = changeTo;
    }
    if (this.tooltips[type]) {
      for (const type1 in this.tooltips) {
        if (type1 !== type) {
          this.tooltips[type1] = false;
        }
      }
    }
    this.updateTooltipTitle(type);
  }

  public onAction(action): void {
    let currentTooltip = null;
    for (const type in this.tooltips) {
      if (type in this.tooltips) {
        if (this.tooltips[type]) {
          currentTooltip = type;
        }
      }
    }
    if (currentTooltip !== null) {
      const keys = Object.keys(this.tooltips);
      const activeTooltipLocation = keys.indexOf(currentTooltip);
      let newKey;
      if (action.text === 'Previous') {
        if (activeTooltipLocation - 1 < 0) {
          newKey = keys[keys.length - 1];
        } else {
          newKey = keys[activeTooltipLocation - 1];
        }
      } else if (action.text === 'Next') {
        if (activeTooltipLocation + 1 >= keys.length) {
          newKey = keys[0];
        } else {
          newKey = keys[activeTooltipLocation + 1];
        }
      }
      this.tooltips[currentTooltip] = false;
      this.tooltips[newKey] = true;
      this.updateTooltipTitle(newKey);
    }
  }

  public close() {
    this.opened = false;
  }

  public exportToExcel(grid: GridComponent): void {
    // grid.saveAsExcel();
  }
}
