<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
  <div *ngIf="apiData">
    <h3>Multi-Line Chart</h3>
    <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
      <kendo-chart [categoryAxis]="{categories: years}">
        <kendo-chart-title contextmenu="Example Pie Chart"></kendo-chart-title>
        <kendo-chart-legend postion="bottom" orientation="vertical"></kendo-chart-legend>
        <kendo-chart-series>
          <kendo-chart-series-item *ngFor="let student of students" type="line" [data]="student.values" [name]="student.name">
          </kendo-chart-series-item>
        </kendo-chart-series>
        <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
      </kendo-chart>
    </div>
    <div *ngIf="!isDataFound(apiData)" class="no-data">
      No data found
    </div>
  </div>


