import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {NavbarBuilderService} from '../../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../../configuration/nav-filter-selection';
import {BrParamsService} from '../../br-params.service';
import {BrHistoryRange} from '../../br-history-range.service';
import {ExpandedViewPages} from '../../configuration/details/expanded-view-pages';
import {DetailQueryParamsService} from '../../detail-query-params.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-br-expanded-chart-view',
  templateUrl: './br-expanded-chart-view.component.html',
  styleUrls: ['./br-expanded-chart-view.component.scss']
})
export class BrExpandedChartViewComponent implements OnInit {
  tileReference;
  expandedViewPages = ExpandedViewPages;
  returnLink;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private brParamsService: BrParamsService,
    private brHistoryRange: BrHistoryRange,
    private location: Location,
    private detailQueryParamsService: DetailQueryParamsService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    const currentRouterLocation = this.detailQueryParamsService.getCleanLocation();
    this.detailQueryParamsService.getBuildCompleteEvent().subscribe(() => {
      if (this.expandedViewPages.hasOwnProperty(currentRouterLocation)) {
        this.tileReference = this.expandedViewPages[currentRouterLocation];
      }

      this.brParamsService.set('br_periods_to_show', this.brParamsService.get('br_history_range_detail'));
      this.navbarBuilderService.setNavbarChoice('business-review');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        dashboardSelector: false,
        toolSelection: ToolSelectionOptions.businessreview,
        bookmarksFlag: false,
        viewTitle: false,
        viewTitleText: '',
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });
    });
    this.detailQueryParamsService.buildPersistedValuesFromQueryParams();

    this.detailQueryParamsService.getReturnLink().subscribe((pathDetails) => {
      this.returnLink = pathDetails;
    });
    this.detailQueryParamsService.processReturnLink();
    this.route.queryParams.subscribe(params => {
      this.detailQueryParamsService.prepReturnParams(params);
    });
    this.route.url.subscribe((url) => {
      this.detailQueryParamsService.prepReturnLink(url);
    });
  }

  getQueryParams() {
    return this.detailQueryParamsService.getQueryParams();
  }

}
