export const DefaultParams = {
  appid: '2.0',
  appversion: '7.0.2',
  culture: 'en-US-US',
  abbottid: 'ADOLPDX',
  managerid: '-99',
  sn: '',
  openstar: 1,
  months12: 12,
  months6: 6,
  months3: 3,
  false: 'false',
  true: 'true',
  emptystring: '',
  all: 'all',
  orgnameAMT: 'AMT',
  accountid: 'all',
  isamb: 'false',
  isfieldservicecontract: 'true',
  assaysid: '',
  diseasestateid: '',
  ln: '',
  iscapital: 'true',
  isother: 'true',
  isreagent: 'true',
  isservice: 'true',
  cuasearchrecordlimit: 100,
  testsearch: 'BAYLOR',
  testorg: '000',
  testcountry: '000',
  placeholder: ''
};
