import { Component, OnInit } from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {DashboardOptions, ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {Style1Pages} from '../../configuration/details/style1-pages';
import {PersonaService} from '../../api/persona.service';
import {DetailChoicesService} from '../../filter_selection/details/detail-choices.service';
import {Personas} from '../../configuration/personas';

@Component({
  selector: 'app-style1',
  templateUrl: './style1.component.html',
  styleUrls: ['./style1.component.scss']
})
export class Style1Component implements OnInit {
  ready = false;
  faSearch = faSearch;

  style1Pages = Style1Pages;

  config = null;
  tileReference = null;
  charts = null;

  constructor(
    private location: Location,
    private navbarBuilderService: NavbarBuilderService,
    private personaService: PersonaService,
    private detailChoices: DetailChoicesService
  ) { }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style1Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style1Pages[currentRouterLocation];
      this.personaService.setServiceVsAmbState(this.config.serviceVsAmb);
      this.tileReference = this.config.tileReference;
      if (this.config.hasOwnProperty('charts')) {
        this.charts = this.config.charts;
      }

      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: true,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: 'Back',
        navigateBackwardsLink: '/lastSelectedDashboard',
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false,
        personaOptions: [Personas.TssAmbassador, Personas.Manager, Personas.GeoLocation],
        detailFilters: this.config.filters,
        detailMetricId: this.config.metricId
      });
    }
  }

  applyDetailFilters() {
    this.detailChoices.applyFilters();
    return this;
  }

  clearDetailFilters() {
    this.detailChoices.clearFilters();
    return this;
  }
}
