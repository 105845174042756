<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div *ngIf="isDataFound(apiData)">
            <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                <div class="container" style="width: 310px;">
                    <div class="row no-gutters" [ngClass]="preferences.shape">
                        <div class="col-6" *ngFor="let shape of apiData.values">
                            <!-- <span class="shape-icon" style="background-color: {{shape.color}}">{{shape.values[0]}} ({{shape.values[1]}})</span> -->
                            <span>
                                <span class="shape-icon" style="background-color: {{shape.color}}" [style.font-size]="getTextSize()">
                                    {{shape.values[0]}}
                                    <span *ngIf="shape.values[1] > 0">({{shape.values[1]}})</span>
                                </span>
                            </span>
                            <span class="shape-description">{{shape.label}}</span>
                        </div>
                    </div>
                </div>
            </a>    
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
            No data found
        </div>
    </div>
</app-state-handler>