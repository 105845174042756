<nav class="navbar navbar-expand-lg navbar-light bg-light nav-fill navbar-fixed-top">
    <div class="container-fluid" style='background-color: #009CDE; padding: 5px'>
        <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
        <app-tool-selector [selectedOption]="ToolSelectionOptions.gsalerts"></app-tool-selector>
        <span class="mx-auto" style="font-weight: 500; color: #fff; font-size: 14pt;">
            Global Service Alerts
        </span>
        <div class="d-flex justify-content-end">

        </div>
    </div>
</nav>