import { Injectable } from '@angular/core';
import {AuthenticationService} from '../authentication/authentication.service';
import {PersonaService} from '../api/persona.service';
import {PersonaTypes} from './persona-types';
import {SelectedTabService} from '../dashboards/selected-tab.service';
import {DashboardOptions} from './nav-filter-selection';
import {DetailChoicesService} from '../filter_selection/details/detail-choices.service';
import {PreferenceSelectionService} from '../preferences/preference-selection.service';
import {ParamSource} from './param-mapping';
import {NavbarBuilderService} from '../navigation/navbar-builder.service';
import {SelectedManagerService} from '../control_tower/selected-manager.service';

@Injectable({
  providedIn: 'root'
})
export class ParamLogicService {
  constructor(
    private authService: AuthenticationService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService,
    private detailChoices: DetailChoicesService,
    private preferenceSelectionService: PreferenceSelectionService,
    private navbarBuilderService: NavbarBuilderService,
    private selectedManagerService: SelectedManagerService
  ) { }

  get(key) {
    const authUserInfo = this.authService.getUserInfo();
    const lastSelectedTab = this.selectedTabService.getLastSelectedTabInfo();

    if (key === 'abbottid_amb_filters') {
      if (authUserInfo.isAMB || authUserInfo.isSuperUser) {
        return authUserInfo.abbottid;
      }
      return '';
    }
    if (key === 'managerid_amb_filters') {
      if (authUserInfo.isAMBManager) {
        return authUserInfo.upi;
      }
      return '';
    }
    if (key === 'detailCustomerIdFilter') {
      if (lastSelectedTab === undefined
        || this.navbarBuilderService.getNavbarChoice() === 'metric-detail'
      ) {
        return this.detailChoices.getFilter('detailCustomerIdFilter');
        // return this.personaService.getPersonaOption(PersonaTypes.Customer);
      } else if (lastSelectedTab === DashboardOptions.customer) {
        const level2PersonaChoice = this.personaService.getLevel2Choice();
        if (level2PersonaChoice.type === PersonaTypes.Customer) {
          return level2PersonaChoice.value;
        }
        return 'all';
      }
      return 'all';
    }
    if (key === 'sgmOrgname') {
      let customerSet = false;
      if (
        (lastSelectedTab === undefined
        || this.navbarBuilderService.getNavbarChoice() === 'metric-detail')
        && this.detailChoices.getFilter('detailCustomerIdFilter') !== 'all'
      ) {
        customerSet = true;
      } else if (lastSelectedTab === DashboardOptions.customer) {
        const level2PersonaChoice = this.personaService.getLevel2Choice();
        if (level2PersonaChoice.type === PersonaTypes.Customer && level2PersonaChoice.value !== 'all') {
          customerSet = true;
        }
      }
      if (([
          undefined,
          DashboardOptions.customer,
          DashboardOptions.dashboard,
          DashboardOptions.instruments
        ].includes(lastSelectedTab)
        || this.navbarBuilderService.getNavbarChoice() === 'metric-detail')
        && !customerSet
      ) {
        return this.personaService.getPersonaOption(PersonaTypes.GEO);
      }
      return '';
    }
    if (key === 'detailProductLineFilter') {
      if (lastSelectedTab === undefined
        || this.navbarBuilderService.getNavbarChoice() === 'metric-detail'
      ) {
        return this.detailChoices.getFilter('detailProductLineFilter');
      } else if (lastSelectedTab === DashboardOptions.instruments) {
        const level2PersonaChoice = this.personaService.getLevel2Choice();
        if (level2PersonaChoice.type === PersonaTypes.ProductLine) {
          return level2PersonaChoice.value;
        }
        return '';
      }
      return '';
    }
    if (key === 'sn') {
      if (lastSelectedTab === DashboardOptions.instruments) {
        return this.personaService.getPersonaOption(PersonaTypes.SN);
      }
      return '';
    }
    if (key === 'ct_persona_type') {
      const level1PersonaChoice = this.personaService.getLevel1Choice();
      switch (level1PersonaChoice.type) {
        case 'TSS':
          return 'tss';
          break;
        case 'Manager':
          return 'mgr';
          break;
        case 'CuaManager':
          return 'mgr';
          break;
        case 'AccountRep':
          return 'account_rep';
          break;
        case 'CuaAmbManager':
          return 'ambmgr';
          break;
        case 'CuaAmb':
          return 'amb';
          break;
        case 'ManagersSpecifcAmb':
          return 'ambmgr';
          break;
        case 'GEO':
          return 'geo';
          break;
        case 'Amb':
          return 'amb';
          break;
        case 'Customer':
          return 'customer';
          break;
        case 'Account':
          return 'account';
          break;
      }
      // ?su, cust //if customer_id is set override of persona_type = cust + persona_value set properly
      return '';
    }
    if (key === 'ct_persona_value') {
      const level1PersonaChoice = this.personaService.getLevel1Choice();
      return [level1PersonaChoice.value];
    }
    if (key === 'ct_uniqueCustomerKey') {
      const level1PersonaChoice = this.personaService.getLevel1Choice();
      return level1PersonaChoice.value;
    }
    if (key === PersonaTypes.Customer) {
      let customerId: any = this.personaService.getPersonaOption(PersonaTypes.Customer);
      if (customerId === 'all') {
        customerId = 0;
      }
      return customerId;
    }
    if (key === 'managerHackForCustomerListAOH') {
      console.log(this.selectedManagerService.getManagerUpi());
      return this.selectedManagerService.getManagerUpi();
    }
    // these need not be prefixed with ct_ or the refresh may not work
    if (key === 'primary') {
      return this.preferenceSelectionService.getPreference('primary') ? '1' : '0';
    }
    if (key === 'secondary') {
      return this.preferenceSelectionService.getPreference('secondary') ? '1' : '0';
    }
    if (key === 'tertiary') {
      return this.preferenceSelectionService.getPreference('tertiary') ? '1' : '0';
    }
    if (key === 'cua_primary') {
      return this.preferenceSelectionService.getPreference('primary') === 'True' ? 'true' : 'false';
    }
    if (key === 'cua_secondary') {
      return this.preferenceSelectionService.getPreference('secondary') === 'True' ? 'true' : 'false';
    }
    if (key === 'cua_tertiary') {
      return this.preferenceSelectionService.getPreference('tertiary') === 'True' ? 'true' : 'false';
    }

    return null;
  }

  isParamReady(key) {
    if (key === 'abbottid_amb_filters') {
      if (!this.authService.isAuth()) {
        return false;
      }
    }
    if (key === 'managerid_amb_filters') {
      if (!this.authService.isAuth()) {
        return false;
      }
    }
    if (key === 'managerHackForCustomerListAOH') {
      return true;
    }
    if (key === 'primary') {
      return this.preferenceSelectionService.arePreferencesLoaded();
    }
    if (key === 'secondary') {
      return this.preferenceSelectionService.arePreferencesLoaded();
    }
    if (key === 'tertiary') {
      return this.preferenceSelectionService.arePreferencesLoaded();
    }
    if (key === 'cua_primary') {
      return this.preferenceSelectionService.arePreferencesLoaded();
    }
    if (key === 'cua_secondary') {
      return this.preferenceSelectionService.arePreferencesLoaded();
    }
    if (key === 'cua_tertiary') {
      return this.preferenceSelectionService.arePreferencesLoaded();
    }

    return true;
  }
}
