<div class="qbr-footer">
    <span class="footer-label">Filters:</span><br/>
    <div *ngIf="productDescription !== ''">
        <span class="filter-desc">Products:</span>
        <span class="filter-value">{{productDescription}}</span>
    </div>
    <div *ngIf="businessSegment !== ''">
        <span class="filter-desc">Business Segment:</span>
        <span class="filter-value">{{businessSegment}}</span>
    </div>
    <div *ngIf="servicingGroup !== ''">
        <span class="filter-desc">Servicing Group:</span>
        <span class="filter-value">{{servicingGroup}}</span>
    </div>
</div>