<div class="ct-section-alerts-title">
    Customer Health Alerts
    <fa-icon [icon]="faInfoCircle" (click)="toggleTooltip('alertHelp', true)"></fa-icon>
</div>
<app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData !== null">
        <kendo-dialog
                title="Alert Help"
                *ngIf="opened"
                (close)="close()"
                [width]="'80%'"
                [height]="'80%'"
        >
            <div class="tooltip2" *ngIf="tooltips.alertHelp">
                <p>Customer Health Alerts are generated to prompt Ambassadors to proactively engage the client regarding Alinity and Architect product lines. Alerts are generated when an instrument falls below the instrument’s normal statistical range in the past 24 hours or meets a defined threshold indicating an issue. New alerts are highlighted in red and indicated as 'New'. Alerts are coded as Low, Medium, or High priority. Alerts will clear from the table after 3 to 7 days, depending on the alert. There are <b>seven</b> alerts:</p>
                <p><b>Daily System Maintenance:</b> Triggers from 4 or more failures of daily maintenance in the past 24 hours. Available for Alinity and Architect. Priority = High; Retention = 3 days</p>
                <p><b>AbbottLink Connection:</b> Triggers if an instrument has not pinged AbbottLink or logged a test in the past 5 days. Available for Alinity and Architect. Priority = High; Retention = 3 days</p>
                <p><b>Test Completion Rate:</b> Triggers when the percentage of tests (specimens, calibrators, and controls) completing without error in the past 24 hours drops below the normal statistical range of that instrument over the past 60 days. Available for Alinity and Architect. Priority = High; Retention = 3 days</p>
                <p><b>Controls Out of Range:</b> Triggers when the percentage of controls out of range in the past 24 hours exceeds the normal statistical range of that instrument compared to the previous 60 days. Control range must be configured on the instrument for this alert to function. Available for Alinity and Architect. Priority = High; Retention = 3 days</p>
                <p><b>Hard Stops:</b> A "hard stop" is defined as the event of an instrument switching directly from <i>Running</i> (or <i>Processing</i>) state to the <i>Stopped</i> state. This indicates that an instrument issue led to an abrupt halt of testing on an instrument. The hard stop alert is available only for Alinity product lines. The Hard Stops alert triggers when the rate of hard stops in the past 24 hours exceeds the normal statistical range of that instrument compared to the previous 60 days. When data is available, the Hard Stop alert will identify the root cause of the instrument's most recent hard stop. Hard stop root cause data is only available for some Alinity i instruments and provides the high level description of root cause provided by instrument software. Priority = Medium; Retention = 3 days</p>
                <p><b>Repeat Tests:</b> A "repeat test" is a specimen that produced a result two or more times for the same assay and on the same instrument, indicating that the customer chose to rerun a specimen that already generated a result. The Repeat Tests alert triggers when the percentage of repeat tests in the past 24 hours exceeds the normal statistical range of that instrument compared to the previous 60 days. Available for Alinity and Architect. Priority = Medium; Retention = 3 days</p>
                <p><b>Total Test Volume:</b> Triggers when an instrument’s weekly test volume from the most recent complete week falls below the normal statistical range of weekly test volumes over the past 6 months. Available for Alinity and Architect. Priority = Low; Retention = 7 days</p>
            </div>
        </kendo-dialog>
        <kendo-grid
            #grid="kendoGrid"
            [data]="gridData"
            [pageable]="true"
            [pageSize]="gridState.take"
            [skip]="gridState.skip"
            [filter]="gridState.filter"
            filterable="menu"
            [sort]="gridState.sort"
            [sortable]="true"
            [resizable]="true"
            (pageChange)="pageChange($event)"
            (sortChange)="sortChange($event)"
            (filterChange)="filterChange($event)"
            (cellClick)="onCellClick($event)"
        >
            <!--scrollable="none"-->
            <kendo-grid-excel [fileName]="'grid'" [fetchData]="allData">
                <ng-container *ngFor="let col of dataCols">
                    <kendo-excelexport-column [field]="col.ref+'.value'" [title]="col.description" *ngIf="col.export"></kendo-excelexport-column>
                </ng-container>
            </kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel" style="position: absolute;margin-top: -31px;z-index: 1;">Export to Excel</button>
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                        [field]="col.ref+'.value'"
                        [title]="col.description"
                        [width]="col.width"
                        [hidden]="col.hidden"
                        [filterable]="col.filterable"
                        [headerStyle]="{'background-color': '#AA0061','color': '#fff','line-height': '1em','font-weight': 'bold'}">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span *ngIf="col.ref === 'customerName'">
                            {{dataItem[col.ref].value}}<br/>
                            <a class="account-info customer-select" *ngIf="inArray(dataItem, 'customerNum')" (click)="selectCustomer(dataItem)">{{dataItem.customerNum.value}}</a>
                        </span>
                        <span *ngIf="col.ref === 'sn'">
                            <span class="link-feel">{{dataItem[col.ref].value}}</span>
                        </span>
                        <span *ngIf="!['customerName', 'sn'].includes(col.ref)" class="whole-cell" [style.color]="dataItem[col.ref].color" [style.background-color]="dataItem[col.ref].bgcolor">
                            {{dataItem[col.ref].value}}
                        </span>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
            <!--<kendo-grid-column field="shipDate" title="Ship Date" width="135" filter="date" format="{0:yyyy-MM-dd}">-->
            <!--<ng-template kendoGridCellTemplate let-dataItem>-->
            <!--{{dataItem.shipDate | date:'yyyy-MM-dd' | emptyMask}}-->
            <!--</ng-template>-->
            <!--</kendo-grid-column>-->
        </kendo-grid>
    </div>
</app-state-handler>
<kendo-popup *ngIf="showContextMenu" [offset]="offset">
    <ul class='menu'>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/sift_icon.gif" /></span>
            <a href="{{gsrUrl}}/gsr/sift/sift_details.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">SIFT</a>
        </li>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/ticket_history.gif" /></span>
            <a href="{{gsrUrl}}/gsr/ticket_details/ticket_load.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">Ticket History</a>
        </li>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/TSB_Button.gif" /></span>
            <a href="{{gsrUrl}}/gsr/tsb/tsb_serial_number_history.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">TSB</a>
        </li>
        <li>
            <span style='padding-right: 8px'><img width='15' height="15" src="./assets/ihr.gif" /></span>
            <a href="{{gsrUrl}}/gsr/ihr/ihr.php?pl={{selectedPl}}&sn={{selectedSn}}" target="blank">IHR</a>
        </li>
    </ul>
</kendo-popup>
