export const PersonaTypes = {
  CuaManager: 'CuaManager',
  Manager: 'Manager',
  TSS: 'TSS',
  GEO: 'GEO',
  Customer: 'Customer',
  CtCustomer: 'CtCustomer',
  CtAccount: 'CtAccount',
  ProductLine: 'ProductLine',
  SN: 'SN',
  ManagerAmb: 'ManagerAmb',
  CuaAmb: 'CuaAmb',
  CuaAmbManager: 'CuaAmbManager',
  AccountRep: 'AccountRep',
  AbbottIdAmb: 'AbbottIdAmb',
  Amb: 'Amb',
  ManagerOverview: 'ManagerOverview',
  ManagersSpecifcAmb: 'ManagersSpecifcAmb',
  Account: 'Account',
  NULL: ''
};
