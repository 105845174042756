import { Component, OnInit, ElementRef, HostListener, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {DashboardOptions, NavFilterSelection} from '../../configuration/nav-filter-selection';
import {Personas} from '../../configuration/personas';
import {AuthenticationService} from '../../authentication/authentication.service';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {SelectedTabService} from '../../dashboards/selected-tab.service';

@Component({
  selector: 'app-persona-selector',
  templateUrl: './persona-selector.component.html',
  styleUrls: ['./persona-selector.component.scss']
})
export class PersonaSelectorComponent implements OnInit {
  DashboardOptions = DashboardOptions;
  Personas = Personas;
  PersonaTypes = PersonaTypes;
  navFilterSelection: NavFilterSelection;
  menuViable = false;
  selectedTab = null;
  isAMB = false;
  isAMBManager = false;
  isSuperUser = false;
  isService = false;

  lastSelectedDashboardTabInfo = null;
  selectedType = null;
  selectedValue = null;
  selectedLabel = null;
  menuText1 = '';
  menuText2 = '';

  @Input() disabled = false;
  @Input() anchorAlign = { horizontal: 'left', vertical: 'bottom' };
  @Input() popupAlign = { horizontal: 'left', vertical: 'top' };

  @ViewChild('anchor') public anchor: ElementRef;
  @ViewChild('popup', { read: ElementRef }) public popup: ElementRef;

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private authService: AuthenticationService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.navbarBuilderService.getNavFilterSelectionSubject().subscribe(
      (navFilterSelection) => {
        setTimeout(() => {
          this.navFilterSelection = navFilterSelection;
        });
      }
    );
    this.navbarBuilderService.tickNavFilterSelectionSubject();

    this.authService.onAuth(() => {
      const userInfo = this.authService.getUserInfo();

      this.isAMB = userInfo.isAMB;
      this.isAMBManager = userInfo.isAMBManager;
      this.isSuperUser = userInfo.isSuperUser;
      this.updateSelections();
    });

    this.personaService.onPersonaChoiceUpdate(() => {
      this.updateSelections();
    });
    this.selectedTabService.onTabChange(() => {
      this.updateSelections();
    });
    this.updateSelections();
    this.isService = this.personaService.getServiceVsAmbState();
    this.onResize();
  }

  updateSelections() {
    this.lastSelectedDashboardTabInfo = this.selectedTabService.getLastSelectedTabInfo();
    const level1Choice = this.personaService.getLevel1Choice();
    this.selectedType = level1Choice.type;
    this.selectedValue = level1Choice.value;
    this.selectedLabel = level1Choice.label;

    if (this.selectedType === null || this.selectedType === undefined) {
      if (this.lastSelectedDashboardTabInfo === DashboardOptions.dashboard) {
        this.menuText1 = 'Select TSS, Manager or Location';
        this.selectedType = PersonaTypes.TSS;
      } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.customer) {
        this.menuText1 = 'Select Location';
      } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.instruments) {
        this.menuText1 = 'Select Location';
      } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.ambassador) {
        const textPieces = [];
        if (this.isAMB) {
          textPieces.push('Ambassador');
        } else if (this.isAMBManager) {
          textPieces.push('Ambassador');
          textPieces.push('Manager');
          this.selectedType = PersonaTypes.AbbottIdAmb;
        } else if (this.isSuperUser) {
          textPieces.push('Location');
        } else if (this.lastSelectedDashboardTabInfo === DashboardOptions.controltower) {
          textPieces.push('Customer');
        }
        this.menuText1 = 'Select ' + this.buildPhrase(textPieces);
      }
      this.menuText2 = '';
    } else {
      if (this.selectedType === PersonaTypes.GEO) {
        this.menuText1 = 'Location';
      } else if (this.selectedType === PersonaTypes.CuaManager) {
        this.menuText1 = 'Manager';
      } else if (this.selectedType === PersonaTypes.ManagerOverview) {
        this.menuText1 = 'Manager';
      } else if (this.selectedType === PersonaTypes.CuaAmbManager) {
        this.menuText1 = 'Ambassador /Manager';
      } else if (this.selectedType === PersonaTypes.AccountRep) {
        this.menuText1 = 'Account Rep';
      } else if (this.selectedType === PersonaTypes.CuaAmb) {
        this.menuText1 = 'Ambassador /Manager';
      } else if (this.selectedType === PersonaTypes.ManagersSpecifcAmb) {
        this.menuText1 = 'Ambassador';
      } else if (this.selectedType === PersonaTypes.Amb) {
        this.menuText1 = 'Ambassador';
      } else if (this.selectedType === PersonaTypes.Account) {
        this.menuText1 = 'Account';
      } else {
        this.menuText1 = this.selectedType;
      }
      this.menuText2 = this.selectedLabel;
    }
    if (this.selectedType === PersonaTypes.Customer) {
      this.selectedType = PersonaTypes.CtCustomer;
    }
    this.onSelectedChange(this.selectedType);
    this.cdRef.detectChanges();
  }

  buildPhrase(list) {
    list[list.length - 1] = 'or ' + list[list.length - 1];
    let str = list.join(', ');
    const pos = str.lastIndexOf(',');
    str = str.substring(0, pos) + str.substring(pos + 1);
    return str;
  }

  onToggle(): void {
    if (!this.disabled) {
      this.menuViable = !this.menuViable;
    }
  }

  isActiveOption(persona) {
    return this.navFilterSelection.personaOptions.includes(persona);
  }

  getMenuTabOptions() {
    const isService = this.personaService.getServiceVsAmbState();
    const menuTabs = [Personas.TssAmbassador, Personas.Manager, Personas.CuaManager, Personas.AccountRep];
    if (this.isAMBManager || this.isAMB || this.isSuperUser) {
      menuTabs.push(Personas.AmbManager);
    }
    if (isService || this.isSuperUser) {
      menuTabs.push(Personas.GeoLocation);
    }
    return menuTabs;
  }

  countActiveOptions(personas) {
    let count = 0;
    for (const persona of personas) {
      if (this.navFilterSelection.personaOptions.includes(persona)) {
        count += 1;
      }
    }
    return count;
  }

  onSelectedChange(selectedPersona) {
    if (
      selectedPersona === PersonaTypes.ManagersSpecifcAmb
      || selectedPersona === PersonaTypes.Amb
    ) {
      this.selectedTab = PersonaTypes.ManagerOverview;
    } else {
      this.selectedTab = selectedPersona;
    }
  }

  @HostListener('keydown', ['$event'])
  public keydown(event: any): void {
    if (event.keyCode === 27 && this.menuViable) {
      this.onToggle();
    }
  }

  @HostListener('document:click', ['$event'])
  public documentClick(event: any): void {
    if (!this.contains(event.target) && this.menuViable) {
      this.onToggle();
    }
  }

  @HostListener('window:resize', [])
  public onResize() {
    const screenWidth = window.innerWidth;
    if (screenWidth >= 768) {
      this.anchorAlign = { horizontal: 'left', vertical: 'bottom' };
    } else {
      this.anchorAlign = { horizontal: 'right', vertical: 'bottom' };
    }
  }

  private contains(target: any): boolean {
    if (target.classList.contains('list-group-item')) {
      return false;
    }
    if (this.anchor !== undefined) {
      return this.anchor.nativeElement.contains(target) ||
        (this.popup ? this.popup.nativeElement.contains(target) : false);
    }
    return false;
  }
}
