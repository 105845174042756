import { Component, OnInit } from '@angular/core';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';

@Component({
  selector: 'app-alerts-dashboard',
  templateUrl: './alerts-dashboard.component.html',
  styleUrls: ['./alerts-dashboard.component.scss']
})
export class AlertsDashboardComponent implements OnInit {

  constructor(private navbarBuilderService: NavbarBuilderService) { }

  ngOnInit(): void {
    this.navbarBuilderService.setNavbarChoice('alerts-dashboard');
  }

}
