<div class="br-snapshot">
    <h3 *ngIf="preferences.showTitle">{{preferences.title}}</h3>
    <app-state-handler [requestState]="state" [data]="apiData">
      <div *ngIf="apiData">
        <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
          <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []" [queryParams]="getQueryParams()">
            <div *ngIf="preferences.renderForServiceContracts === true"
                 style='font-weight: 500; font-size: 12pt; text-align: center; color: black'>{{apiData.subtitle}}</div>
            <!-- <div *ngIf="chartOptions !== null">
                        <kendo-chart
                                [legend]="chartOptions.legend"
                                [series]="chartOptions.series">
                        </kendo-chart>
                    </div> -->
            <div *ngIf="chartOptions !== null">
             <kendo-chart (render)="onRender($event)">
                <kendo-chart-series>
                  <kendo-chart-series-item type="pie"
                                           [data]="chartOptions.pieData"
                                           field="value"
                                           categoryField="category"
                                           [border]="{color: '#000',width: 1, dashType: 'solid'}"
                                           [tooltip]="{visible: true,format: '{0}'}"
                                           [visual]="visualHandler">
                  </kendo-chart-series-item>
                </kendo-chart-series>
                <kendo-chart-legend position="bottom" orientation="horizontal"></kendo-chart-legend>
              </kendo-chart>
            </div>
          </a>
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
          No data found
        </div>
      </div>
    </app-state-handler>
    </div>