<div class="filter-section">
    <div class="filter-header">Product Lines</div>
    <app-autoselect-list
            [state]="apiState"
            [data]="data"
            [keyField]="'key'"
            [displayField]="'label'"
            [selectedOption]="selectedItem"
            (itemSelect)="setSelectedItem($event)">
    </app-autoselect-list>
</div>
<!--<div class="filter-item">APS C SYSTEM IM</div>-->
<!--<div class="filter-item">APS CARTESN CM</div>-->
<!--<div class="filter-item">APS DECAPPER</div>-->
<!--<div class="filter-item">APS DESEALER</div>-->
<!--<div class="filter-item">APS I2000SR IM</div>-->
<!--<div class="filter-item">APS IOM</div>-->
<!--<div class="filter-item">APS RESEALER</div>-->