import {Component, OnDestroy, OnInit} from '@angular/core';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';

@Component({
  selector: 'app-instrument-selector',
  templateUrl: './instrument-selector.component.html',
  styleUrls: ['./instrument-selector.component.scss']
})
export class InstrumentSelectorComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  selectedItem;
  requestHandler;

  constructor(
    private apiService: ApiService,
    private personaService: PersonaService,
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('/productline/details');
    this.requestHandler.sendRequest('/productline/details');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        const buildData = [];
        for (const productline of apiData.productlines) {
          buildData.push({
            label: productline.productname,
            key: productline.productlineid
          });
        }
        this.data = buildData;
      }
    });
    this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.ProductLine);
    this.personaService.onPersonaChoiceUpdate(() => {
      this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.ProductLine);
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  getLabel(key) {
    if (this.data) {
      for (const item of this.data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    const label = this.getLabel(selectedItem);
    this.personaService.setPersonaOption(PersonaTypes.ProductLine, selectedItem, label);
  }
}
