<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div *ngIf="preferences.calculateTotalFromData===true; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
            <!--Current Instrument Quantity-->
            <!--@todo: this should be broken out into a different metric tile-->
            <div *ngIf="isDataFound(apiData)">
                <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                    <div class="{{apiData.visualization}}" style="color: {{apiData.color}}">
                        <span style="font-size: 100px;">&nbsp;</span>
                        {{apiData.totalQuantity}}
                        <span style="font-size: 100px;">&nbsp;</span>
                    </div>
                </a>
            </div>
            <div *ngIf="!isDataFound(apiData)" class="no-data">
                No data found
            </div>
        </ng-template>
        <ng-template #elseBlock>
            <div *ngIf="isDataFound(apiData)">
                <a [routerLink]="(tileReference.detailLink && apiData.displayvalue != 'N/A') ? [tileReference.detailLink] : []">                    
                    <div class="{{apiData.visualization}}" style="color: {{apiData.color}}" [style.font-size]="getTextSize()">
                        <span style="font-size: 100px;">&nbsp;</span>
                        {{apiData.displayvalue}}
                        <span style="font-size: 100px;">&nbsp;</span>
                    </div>
                    <div *ngIf="apiData.label !== ''">
                        <span class="textLabel" style="color: {{getLowerTitleColor(apiData)}}">{{apiData.label}}</span>
                    </div>
                </a>    
            </div>
            <div *ngIf="!isDataFound(apiData)" class="no-data">
                No data found
            </div>                
        </ng-template>
    </div>
</app-state-handler>