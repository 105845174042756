import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-br-detail-component-viewer',
  templateUrl: './br-detail-component-viewer.component.html',
  styleUrls: ['./br-detail-component-viewer.component.scss']
})
export class BrDetailComponentViewerComponent implements OnInit {
  component;
  apiUrl;

  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.hasOwnProperty('component')) {
            this.component = params.component;
          }
          if (params.hasOwnProperty('apiUrl')) {
            this.apiUrl = params.apiUrl;
          }
        }
      );
  }
}
