import { Component, OnInit, OnDestroy } from '@angular/core';
import {RequestState} from '../../../api/request-handler';
import {RefreshHandlerService} from '../../../api/refresh-handler.service';
import {BrParamsService} from '../../br-params.service';
import {ApiService} from '../../../api/api.service';

enum Selected {
  None = 0,
  Complete = 1,
  Partial = 2
}

@Component({
  selector: 'app-br-product-selector',
  templateUrl: './br-product-selector.component.html',
  styleUrls: ['./br-product-selector.component.scss']
})
export class BrProductSelectorComponent implements OnInit, OnDestroy {
  data = null;
  /*{
    column_list: [
      {
        id: 'pc',
        label: 'Product Class',
        post_selected: false,
        single_select: true,
        items: [
          {column: 'pc', id: 'ia', display: 'Immunoassay', selected: Selected.None},
          {column: 'pc', id: 'cc', display: 'Clinical Chemistry', selected: Selected.None}
        ],
        extra_items: []
      },
      {
        id: 'plg',
        label: 'PL Group',
        post_selected: false,
        single_select: true,
        items: [
          {column: 'plg', id: 'alinia', display: 'Alinity - IA', selected: Selected.None},
          {column: 'plg', id: 'archia', display: 'Architect - IA', selected: Selected.None},
          {column: 'plg', id: 'alincc', display: 'Alinity -CC', selected: Selected.None},
          {column: 'plg', id: 'archcc', display: 'Architect - CC', selected: Selected.None}
        ],
        extra_items: []
      },
      {
        id: 'pl',
        label: 'PLs',
        post_selected: true,
        single_select: false,
        items: [
          {column: 'pl', id: '205', display: 'Alinity I', selected: Selected.None},
          {column: 'pl', id: '210', display: 'Alinity C', selected: Selected.None},
          {column: 'pl', id: '126', display: 'C8000', selected: Selected.None},
          {column: 'pl', id: '118', display: 'i2000sr', selected: Selected.None}
        ],
        extra_items: []
      }
    ],
    relationships: [
      {
        column: 'pc',
        id: 'ia',
        display: '',
        children: [
          {column: 'plg', id: 'alinia', display: 'St. Marys\' SubLayer'},
          {column: 'plg', id: 'archia', display: 'St. Mary\'s Group'},
          {column: 'pl', id: '205', display: 'St. Mary\'s Group'},
          {column: 'pl', id: '118', display: 'St. Mary\'s Group'},
        ]
      },
      {
        column: 'pc',
        id: 'cc',
        display: '',
        children: [
          {column: 'plg', id: 'alincc', display: 'St. Marys\' SubLayer'},
          {column: 'plg', id: 'archcc', display: 'St. Mary\'s Group'},
          {column: 'pl', id: '210', display: 'St. Mary\'s Group'},
          {column: 'pl', id: '126', display: 'St. Mary\'s Group'},
        ]
      },
      {
        column: 'plg',
        id: 'alinia',
        display: '',
        children: [
          {column: 'pl', id: '205', display: 'St. Mary\'s Group'},
        ]
      },
      {
        column: 'plg',
        id: 'archia',
        display: '',
        children: [
          {column: 'pl', id: '118', display: 'St. Mary\'s Group'},
        ]
      },
      {
        column: 'plg',
        id: 'alincc',
        display: '',
        children: [
          {column: 'pl', id: '210', display: 'St. Mary\'s Group'},
        ]
      },
      {
        column: 'plg',
        id: 'alinia',
        display: '',
        children: [
          {column: 'pl', id: '126', display: 'St. Mary\'s Group'},
        ]
      },
    ]
  };*/
  protected requestHandler;
  protected apiSource = '/qbr/app_config/products/products';
  protected refreshHandleKey;
  public state = RequestState.Inactive;

  constructor(
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.callApiSource(this.apiSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        const persistedChoices = this.brParamsService.get('products_data');
        if (persistedChoices != null) {
          this.data = persistedChoices;
        } else {
          this.data = apiData;
          // this.data[2][0].selected = 1; // hack to fix all product lines not being selected
        }
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.apiSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.apiSource));
    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'products_data') {
        this.data = this.brParamsService.get('products_data');
      }
    });
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  valueChanged(e) {
    this.brParamsService.set('pl', this.getArrayOfPropertyFromObject(e, 'id'));
    this.brParamsService.set('products_data', this.data);
    this.brParamsService.set('product_description', this.getArrayOfPropertyFromObject(e, 'display').join(', '));
  }

  getArrayOfPropertyFromObject(object, property) {
    const result = [];
    for (const item of object) {
      result.push(item[property]);
    }
    return result;
  }
}
