import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { GridComponent } from '../../grid.component';
import { RequestState } from 'src/app/api/request-handler';
import { ApiService } from 'src/app/api/api.service';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';

@Component({
  selector: 'app-predictive-alerts-level2',
  templateUrl: './predictive-alerts-level2.component.html',
  styleUrls: ['./predictive-alerts-level2.component.scss']
})
export class PredictiveAlertsLevel2Component extends GridComponent implements OnInit {

  stylePages = {
    '/customerswithoutservicecontracts/grid': {
      pageTitle: 'Predictive Alerts w/o Service Contracts - Details',
      apiRequestHandler: '/metrics/customerswithoutservicecontracts/grid',
      backPageLabel: 'Back',
      backPageLink: '/customerswithoutservicecontracts',
      dataArrayKey: 'Details',
      table: {
          type: 'style3',
          exportFileName: 'predictive_alerts_without_service_contract_details.xlsx',
          contextMenuFieldName: 'sn',
          dataCols: [
              {
                  title: 'Country',
                  key: 'country',
                  width: 50
              },
              {
                  title: 'Customer',
                  key: 'customer',
                  width: 80
              },
              {
                  title: 'PL',
                  key: 'instrument',
                  width: 50
              },
              {
                  title: 'SN',
                  key: 'sn',
                  renderRefStyle: true,
                  contextMenuItems: [
                      {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                      {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                      {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                      {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                  ],                        
                  width: 80
              },
              {
                  title: 'Alert Qty (12 Month)',
                  key: 'alert12mo',
                  customStyle:  { 'text-align': 'right' },
                  width: 50
              },
              {
                  title: 'Alert Qty (6 Month)',
                  key: 'alert6mo',
                  customStyle:  { 'text-align': 'right' },
                  width: 50
              },
              {
                  title: 'Alert Qty (3 Month)',
                  key: 'alert3mo',
                  customStyle:  { 'text-align': 'right' },
                  width: 50
              }, 
              {
                  title: 'Alert Qty (30 Days)',
                  key: 'alert30day',
                  customStyle:  { 'text-align': 'right' },
                  width: 50,
              },
              {
                  title: 'SD Ticket Qty (30 Days)',
                  key: 'ticket30day',
                  customStyle:  { 'text-align': 'right' },
                  refLink: 'sd_ticket_details',
                  width: 50,
              }
          ],
          drillDownCols: [
              {
                  title: '',
                  width: 60,
                  drillDownDetailsLabel: '>',
                  drillDownDetailsLink: '/customerswithoutservicecontracts/griddetails'
              }
          ]
      }
    },
    '/amb_customerswithoutservicecontracts/grid': {
      pageTitle: 'Predictive Alerts w/o Service Contracts - Details',
      apiRequestHandler: '/metrics/amb_customerswithoutservicecontracts/grid',
      backPageLabel: 'Back',
      backPageLink: '/amb_customerswithoutservicecontracts',
      dataArrayKey: 'Details',
      table: {
          type: 'style3',
          exportFileName: 'amb_predictive_alerts_without_contracts.xlsx',
          contextMenuFieldName: 'sn',
          dataCols: [
              {
                  title: 'Country',
                  key: 'country',
                  width: '100'
              },
              {
                  title: 'Customer',
                  key: 'customer',
                  width: '120'
              },
              {
                  title: 'PL',
                  key: 'instrument',
                  width: '100'
              },
              {
                  title: 'SN',
                  key: 'sn',
                  renderRefStyle: true,
                  contextMenuItems: [
                      {text: 'SIFT', iconUrl: './assets/sift_icon.gif', fieldName: 'instrumentsrnolinks', columnIndex: 0},
                      {text: 'Ticket History', iconUrl: './assets/ticket_history.gif', fieldName: 'instrumentsrnolinks', columnIndex: 1},
                      {text: 'TSB', iconUrl: './assets/TSB_Button.gif', fieldName: 'instrumentsrnolinks', columnIndex: 2},
                      {text: 'IHR', iconUrl: './assets/ihr.gif', fieldName: 'instrumentsrnolinks', columnIndex: 3}
                  ],                        
                  width: '100'
              },
              {
                  title: 'Alert Qty (12 Month)',
                  key: 'alert12mo',
                  customStyle:  { 'text-align': 'right' },
                  width: '100'
              },
              {
                  title: 'Alert Qty (6 Month)',
                  key: 'alert6mo',
                  customStyle:  { 'text-align': 'right' },
                  width: '100'
              },
              {
                  title: 'Alert Qty (3 Month)',
                  key: 'alert3mo',
                  customStyle:  { 'text-align': 'right' },
                  width: '100'
              }, 
              {
                  title: 'Alert Qty (30 Days)',
                  key: 'alert30day',
                  customStyle:  { 'text-align': 'right' },
                  width: '100',
              },
              {
                  title: 'SD Ticket Qty (30 Days)',
                  key: 'ticket30day',
                  customStyle:  { 'text-align': 'right' },
                  refLink: 'sd_ticket_details',
                  width: '100',
              }
          ],
          drillDownCols: [
              {
                  title: '',
                  width: '80',
                  drillDownDetailsLabel: '>',
                  drillDownDetailsLink: '/amb_customerswithoutservicecontracts/griddetails'
              }
          ]
      }
    }
  };
  
  config = null;
  public state = RequestState.Inactive;

  public menuItems: any[] = [];
  public offset: any;
  public selected: any;
  public showContextMenu: boolean = false;
  private documentClickSubscription: any;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private refreshHandler: RefreshHandlerService,
    private renderer: Renderer2,
  ) { 
    super();
    this.onCellClick = this.onCellClick.bind(this);
    this.documentClickSubscription = this.renderer.listen('document', 'click', () => {
        this.showContextMenu = false;
    });
  }

  ngOnInit(): void {
    
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: true,
        viewSubtitleText: this.config.subTitle,
        filtered: false
      });

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);
      requestHandler.onStateChange((state) => {
        this.state = state;
      });
      requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12 });
      this.refreshHandler.onRefreshEvent(
        (index) => {
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12 });
        },
        requestHandler.getRelativeParams(this.config.apiRequestHandler)
      );

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = requestHandler.getResponse();
          const apiData = JSON.parse(response.body);

          const dataColsConfig = this.config.table.dataCols;
          this.dataCols = [];
          for (let i = 0; i < this.config.table.dataCols.length; i++) {
            this.dataCols.push({
              ref: this.config.table.dataCols[i].key,
              description: this.config.table.dataCols[i].title,
              width: this.config.table.dataCols[i].width,
              refLink: this.config.table.dataCols[i].refLink,
              renderRefStyle: this.config.table.dataCols[i].renderRefStyle,
              customStyle: this.config.table.dataCols[i].customStyle
            });
          }
          if (this.config.table.drillDownCols !== undefined) {
            for (let i = 0; i < this.config.table.drillDownCols.length; i++) {
              this.dataCols.push({
                description: this.config.table.drillDownCols[i].title,
                width: this.config.table.drillDownCols[i].width,
                drillDownDetailsLabel: this.config.table.drillDownCols[i].drillDownDetailsLabel,
                drillDownDetailsLink: this.config.table.drillDownCols[i].drillDownDetailsLink
              });
            }            
          }
          // format dataRows for kendo component
          this.dataRows = [];
          for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
            this.dataRows.push(apiData[this.config.dataArrayKey][r]);
          }
          this.updateGrid();
        }             
      });
    }
  }

  public onCellClick({dataItem, column, type, originalEvent}): void {
    let contextMenuItemArr = [];
    if (type === 'click' && column.field === this.config.table.contextMenuFieldName) {
      originalEvent.preventDefault();
      for (let r = 0; r < this.config.table.dataCols.length; r++) {
        let dataCol = this.config.table.dataCols[r];
        if (dataCol.contextMenuItems !== undefined) {
          for (let k = 0; k < dataCol.contextMenuItems.length; k++) {
            contextMenuItemArr.push({
              'text': dataCol.contextMenuItems[k].text,
              'iconUrl':  dataCol.contextMenuItems[k].iconUrl, 
              'textLink': dataItem[dataCol.contextMenuItems[k].fieldName][dataCol.contextMenuItems[k].columnIndex] });
          }  
        }
      }
      this.menuItems = contextMenuItemArr;
      this.showContextMenu = true;
      this.offset = { left: originalEvent.pageX, top: originalEvent.pageY };
    } else {
      this.showContextMenu = false;
    }    
  }

  public menuItemSelected(item: any): void {
    this.selected = item;
  }

}
