import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {PersonaService} from '../../api/persona.service';
import {PersonaTypes} from '../../configuration/persona-types';
import {SelectedManagerService} from '../../control_tower/selected-manager.service';

@Component({
  selector: 'app-cua-manager-selector',
  templateUrl: './cua-manager-selector.component.html',
  styleUrls: ['./cua-manager-selector.component.scss']
})
export class CuaManagerSelectorComponent implements OnInit, OnDestroy {
  apiState;
  data = [];
  selectedItem;
  requestHandler;

  constructor(
    private apiService: ApiService,
    private personaService: PersonaService,
    private selectedManagerService: SelectedManagerService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.apiService.get('cua/manager_list');
    this.requestHandler.sendRequest('cua/manager_list');
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        const buildData = [];
        for (const user of apiData) {
          buildData.push({
            label: user.firstName + ' ' + user.lastName,
            key: user.mgr511,
            upi: user.mgrUpi
          });
        }
        this.data = buildData;
      }
    });
    this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.CuaManager);
    this.personaService.onPersonaChoiceUpdate(() => {
      this.selectedItem = this.personaService.getPersonaOption(PersonaTypes.CuaManager);
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  getLabel(key) {
    if (this.data) {
      for (const item of this.data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    const label = this.getLabel(selectedItem);
    this.selectedManagerService.setManager(selectedItem.upi, selectedItem.key, selectedItem.label)
    this.personaService.setPersonaOption(PersonaTypes.CuaManager, selectedItem, label);
    this.selectedManagerService.setManager(selectedItem.upi, selectedItem.key, selectedItem.label)
  }
}
