<div *ngIf="config">
    <a [routerLink]="config.table.level2DetailLink ? [config.table.level2DetailLink] : []">
        <!--(click)="location(config.table.level2DetailLink);"-->
        <kendo-grid
            [data]="gridData"
            [reorderable]="false"
            [pageable]="false"
            [navigable]="false"
        >
            <!--scrollable="none"-->
            <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
            </ng-template>
            <ng-container *ngFor="let col of dataCols">
                <kendo-grid-column
                    [field]="col.ref"
                    [title]="col.description"
                    [width]="col.width"
                    [headerStyle]="headerStyle(col.ref)"
                    [style]="colStyle(col.ref)">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <ng-container *ngIf="config.table.currencyStyleForAMB === true; else two">
                            <!-- <span  class="whole-cell">
                                <a href="{{dataItem[col.refLink]}}" target="blank" style="color:#007bff; font-weight: bold;">{{dataItem[col.ref]}}</a>
                            </span> -->
                            <span class="whole-cell" [style]="cellStyle(col.ref, rowIndex)">
                                {{dataItem[col.ref] | abbottBigNumber}}        
                            </span>
                        </ng-container>
                        <ng-template #two>
                            <!-- <span  class="whole-cell">
                                <a href="{{dataItem[col.refLink]}}" target="blank" style="color:#007bff; font-weight: bold;">{{dataItem[col.ref]}}</a>
                            </span> -->
                            <span class="whole-cell" [style]="cellStyle(col.ref, rowIndex)">
                                {{dataItem[col.ref]}}
                            </span>
                        </ng-template>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
            <!--<kendo-grid-column field="shipDate" title="Ship Date" width="135" filter="date" format="{0:yyyy-MM-dd}">-->
            <!--<ng-template kendoGridCellTemplate let-dataItem>-->
            <!--{{dataItem.shipDate | date:'yyyy-MM-dd' | emptyMask}}-->
            <!--</ng-template>-->
            <!--</kendo-grid-column>-->
        </kendo-grid>
    </a>
</div>