import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {NavFilterSelection} from '../configuration/nav-filter-selection';

@Injectable({
  providedIn: 'root'
})
export class NavbarBuilderService {
  isVisible = true;
  isVisibleSubject: Subject<boolean> = new Subject<boolean>();

  navbarChoice: string;
  navbarChoiceSubject: Subject<string> = new Subject<string>();

  navFilterSelection: NavFilterSelection;
  navFilterSelectionSubject: Subject<NavFilterSelection> = new Subject<NavFilterSelection>();

  constructor() { }

  getNavbarChoiceSubject(): Subject<string> {
    return this.navbarChoiceSubject;
  }
  setNavbarChoice(navbarChoice: string): void {
    this.navbarChoice = navbarChoice;
    this.navbarChoiceSubject.next(this.navbarChoice);
  }
  getNavbarChoice() {
    return this.navbarChoice;
  }
  getNavFilterSelectionSubject(): Subject<NavFilterSelection> {
    return this.navFilterSelectionSubject;
  }
  tickNavFilterSelectionSubject() {
    this.navFilterSelectionSubject.next(this.navFilterSelection);
  }
  setNavFilterSelection(navFilterSelection: NavFilterSelection): void {
    this.navFilterSelection = navFilterSelection;
    this.tickNavFilterSelectionSubject();
  }
  getNavFilterSelection() {
    return this.navFilterSelection;
  }
  getNavbarIsVisibleSubject(): Subject<boolean> {
    return this.isVisibleSubject;
  }
  setIsVisible(newValue) {
    this.isVisible = newValue;
    this.isVisibleSubject.next(newValue);
  }
}
