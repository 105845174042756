<span *ngIf="navFilterSelection" class="navbar-collapse" kendoTooltip position="bottom">
    <span class="tooltip-link" title="Current/Legacy Customer List: Tap to show/hide Legacy Customers in Customer Lists (does not affect service metrics)">
        <app-customer-toggle [displayTitles]="displayTitles"></app-customer-toggle>
    </span>
    <span class="tooltip-link" title="Solid Star: View my Instruments for the Customers on MyCRL. Open Star: View all Instruments for the Customers on MyCRL.">
        <app-instrument-toggle [displayTitles]="displayTitles"></app-instrument-toggle>
    </span>
    <span class="tooltip-link" title="Customers with Track Systems: Limits metrics and data to customers that have track systems.">
        <app-track-toggle [displayTitles]="displayTitles"></app-track-toggle>
    </span>
    <span class="tooltip-link" title="Solid Flag: Limit metrics to flagship Instruments. Open Flag: Filter to metrics for all instruments relative to the persona's CRL or organization selected.">
        <app-flagship-toggle [displayTitles]="displayTitles"></app-flagship-toggle>
    </span>
</span>