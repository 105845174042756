import { Input, Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileReference } from '../../../dashboards/tile-reference';
import { TileComponent } from '../../../tiles/base/tile.component';

@ComponentLookup('BrTextIndicatorsComponent')
@Component({
    selector: 'app-br-text-indicator',
    templateUrl: './br-text-indicator.component.html',
    styleUrls: ['./br-text-indicator.component.scss']
})

export class BrTextIndicatorComponent extends TileComponent implements OnInit {
  defaultPreferences() {
    return {
      showTitle: true
    };
  }

  title = 'No Title Listed';
  strScore = 0
  strColor = 'gray';
  strSubTitle = 'No Subtitle Listed';

  translateApiDataForChart(apiData) {
    // console.log(this.tileReference.apiSource);
    // console.log('ALCM Score', apiData.values['score']);
    this.title = apiData.title;
    this.strSubTitle = apiData.subtitle;
    if (apiData.values['score'].score) { this.strScore = apiData.values['score'].score; };
    if (apiData.values['score'].color) { this.strColor = apiData.values['score'].color; };
  }

  isDataFound(apiData) {
    const dataFound = super.isDataFound(apiData);
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }
}
