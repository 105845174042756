import { Component, Input } from '@angular/core';
import {ComponentLookup} from '../../../decorators/component-lookup';
import {ToggleSwitches} from '../toggle-switches';

@ComponentLookup('DetailCapitalServiceAssaySwitchesFilterComponent')
@Component({
  selector: 'app-detail-capital-service-assay-switches-filter',
  templateUrl: './detail-capital-service-assay-switches-filter.component.html',
  styleUrls: ['./detail-capital-service-assay-switches-filter.component.scss']
})
export class DetailCapitalServiceAssaySwitchesFilterComponent extends ToggleSwitches {
  @Input() metricId;

  updateListener = 'capitalServiceAssaySwitches';
  config = [
    {
      key: 'detailIsCapitalFilter',
      label: 'Capital',
      defaultValue: true
    },
    {
      key: 'detailIsServiceFilter',
      label: 'Service',
      defaultValue: true
    },
    {
      key: 'detailIsReagentFilter',
      label: 'Reagent/Assay',
      defaultValue: true
    },
    {
      key: 'detailIsOtherFilter',
      label: 'Other',
      defaultValue: true
    }
  ];
}
