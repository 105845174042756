import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  faSquare,
  faCheck
} from '@fortawesome/free-solid-svg-icons';

enum Selected {
  None = 0,
  Complete = 1,
  Partial = 2
}

// note: this component can only be used when last column is the final selections
@Component({
  selector: 'app-ui-multi-column-list-select',
  templateUrl: './ui-multi-column-list-select.component.html',
  styleUrls: ['./ui-multi-column-list-select.component.scss']
})
export class UiMultiColumnListSelectComponent implements OnInit {
  faSquare = faSquare;
  faCheck = faCheck;
  Selected = Selected;

  @Input() mockData;
  @Input() bringSelectedToTop = false;

  @Output() valueChange = new EventEmitter<any>(true);
  @Output() valueChangeColumnSelected = new EventEmitter<any>(true);
  @Output() valueChangeData = new EventEmitter<any>(true);

  constructor() { }

  ngOnInit(): void {
    this.bringSelectedValuesToTop();
  }

  propertySort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return (a, b) => {
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  bringSelectedValuesToTop() {
    const selectedSortPriority = {1: 0, 2: 1, 0: 3};
    if (this.bringSelectedToTop && this.mockData !== null && this.mockData.column_list !== undefined) {
      for (const column of this.mockData.column_list) {
        column.items.sort((a, b) => {
          return selectedSortPriority[a.selected] - selectedSortPriority[b.selected];
        });
        column.items.sort((a, b) => {
          if (selectedSortPriority[a.selected] !== selectedSortPriority[b.selected]) {
            return 0;
          }
          return (a.display < b.display) ? -1 : (a.display > b.display) ? 1 : 0;
        });
      }
    }
  }

  getSelectedValues() {
    let result = [];
    this.mockData.column_list.forEach((columnItem) => {
      if (columnItem.post_selected === true) {
        result = this.getSelectedItemsInColumn(columnItem.id);
      }
    });
    return result;
  }

  getColumnSelectedValues() {
    const result = [];
    this.mockData.column_list.forEach((columnItem) => {
      const columnSummery = {
        id: columnItem.id,
        postSelected: columnItem.post_selected,
        selectedItems: this.getSelectedItemsInColumn(columnItem.id, [Selected.Complete])
      };
      result.push(columnSummery);
    });
    return result;
  }

  toggleItemSelect(e) {
    this.itemClickSelect(e);
    this.valueChange.emit(this.getSelectedValues());
    this.valueChangeColumnSelected.emit(this.getColumnSelectedValues());
    this.valueChangeData.emit(this.mockData);
    /**
     * Switch selected state for clicked item
     * loop through sub tree to show any missing relative items
     *  - see line 39, add to addon_items
     * loop through sub tree to auto select decedent items
     * loop up parent tree /tags to determine proper selection biased on sub selections
     */
  }

  unselectColumnItems(column, exceptions) {
    column.items.forEach((columnItem) => {
      if (!exceptions.includes(columnItem.id)) {
        columnItem.selected = Selected.None;
      }
    });
  }

  itemHasDescendantItem(itemColumnId, itemId, descendantColumnId, descendantItemId) {

  }

  getColumnById(columnId) {
    let column;
    this.mockData.column_list.forEach((columnItem) => {
      if (columnItem.id === columnId) {
        column = columnItem;
      }
    });
    return column;
  }

  getColumnItemById(column, itemId) {
    let item = null;
    column.items.forEach((columnItem) => {
      if (columnItem.id === itemId) {
        item = columnItem;
      }
    });
    return item;
  }

  getItemRelationshipConfig(columnId, itemId) {
    let result;
    this.mockData.relationships.forEach((relationshipItem) => {
      if (relationshipItem.column === columnId && relationshipItem.id === itemId) {
        result = relationshipItem;
      }
    });
    return result;
  }

  recursiveSetSelectedState(columnId, itemId, selectedState) {
    // console.log('a1', columnId, itemId, selectedState);
    // get itemId and columnId
    const column = this.getColumnById(columnId);

    const columnItem = this.getColumnItemById(column, itemId);

    // set to selected
    if (columnItem !== null) {
      columnItem.selected = selectedState;

      // get child relationships
      const relationship = this.getItemRelationshipConfig(columnId, itemId);
      if (relationship !== undefined) {
        // recurse to this method for each child relationship
        relationship.children.forEach((childItem) => {
          this.recursiveSetSelectedState(childItem.column, childItem.id, selectedState);
        });
      }
    }
  }

  removeExtraItems() {
    this.mockData.column_list.forEach((column) => {
      column.extra_items = [];
    });
  }

  getReverseColumnItemsForChild(columnId, itemId) {
    const reverseTree = [];
    this.mockData.relationships.forEach((relationshipItem) => {
      relationshipItem.children.forEach((childItem) => {
        if (childItem.column === columnId && childItem.id === itemId) {
          const reverseTreeItem = {
            column: relationshipItem.column,
            id: relationshipItem.id,
            display: relationshipItem.display,
            ancestors: this.getReverseColumnItemsForChild(relationshipItem.column, relationshipItem.id)
          };
          reverseTree.push(reverseTreeItem);
        }
      });
    });
    // console.log('ancestry', columnId, reverseTree);
    return reverseTree;
  }

  areColumnItemLowestLevelDecendentsChecked(columnId, itemId) {}

/*{
  column: 'l_2_6',
  id: '001',
  children: [
    {column: 'l_2_6', id: '002', display: 'St. Marys\' SubLayer'},
    {column: 'l_1', id: '001', display: 'St. Mary\'s Group'},
    {column: 'l_1', id: '005', display: 'Joe Bob List'}
    ]
},*/

  recursiveAddExtraItems(columnId, itemId, selectedState) {
    // get itemId and columnId
    const column = this.getColumnById(columnId);
    const columnItem = this.getColumnItemById(column, itemId);

    // set to selected
    columnItem.selected = selectedState;

    // get child relationships
    const relationship = this.getItemRelationshipConfig(columnId, itemId);

    // recurse to this method for each child relationship
    relationship.children.forEach((childItem) => {
      this.recursiveSetSelectedState(childItem.column, childItem.id, selectedState);
    });
  }

  isItemInColumnExtraItem(ancestor, columnItem) {
    let result = false;
    columnItem.extra_items.forEach((childItem) => {
      if (columnItem.id === ancestor.column && childItem.id === ancestor.id) {
        result = true;
      }
    });
    return result;
  }

  getSelectedItemsInColumn(columnId, validSelectedTypes = [Selected.Complete, Selected.Partial]) {
    const returnItems = [];
    const column = this.getColumnById(columnId);
    // console.log(this.mockData, column, JSON.stringify(column));
    column.items.forEach((item) => {
      // console.log(item);
      if (validSelectedTypes.includes(item.selected)) {
        returnItems.push(item);
      }
    });
    // console.log('t3y', returnItems);
    return returnItems;
  }

  refreshSelectionsAndExtraItems(columnId, focusedInputItem, newValue, ss = false) {
    const isSingleSelect = this.getColumnById(columnId).single_select;
    // @todo: handle single select
    if (isSingleSelect && newValue === Selected.Complete && !ss) {
      const selectedItemsInColumn = this.getSelectedItemsInColumn(columnId);
      // console.log('selectedItemsInColumn', selectedItemsInColumn);
      selectedItemsInColumn.forEach((item) => {
        this.refreshSelectionsAndExtraItems(columnId, item, Selected.None, true);
      });
    }

    // - step 1: let any change traverse down to adjust lowest level check selections
    this.recursiveSetSelectedState(columnId, focusedInputItem.id, newValue);

    // - step 3: auto add and lowest level child extra items
    // - step 2: traverse up from lowest layer to cleanup extra items
    this.removeExtraItems();
    /*this.mockData.column_list.forEach((columnItem) => {
      if (columnItem.post_selected) {// is final child layer
        columnItem.items.forEach((childItem) => {
          if (childItem.selected) {
            const ancestry = this.getReverseColumnItemsForChild(columnItem.id, childItem.id);
            ancestry.forEach((ancestor) => {
              if (!this.isItemInColumnExtraItem(ancestor, columnItem)) { // prevent duplicates
                columnItem.extra_items.push(ancestor);
              }
            });
          }

        });
      }
    });*/

    // - step 3: traverse up from lowest level children to fix parent selections
    // for any unselected child, unselect any parents that include it
    const finalChildLayerSelected = [];
    this.mockData.column_list.forEach((columnItem) => {
      if (columnItem.post_selected) {// is final child layer
        columnItem.items.forEach((childItem) => {
          if (childItem.selected) {
            finalChildLayerSelected.push(childItem);
          }
        });
      }
    });

    this.mockData.column_list.forEach((columnItem) => {
      if (!columnItem.post_selected) {// is final child layer
        columnItem.items.forEach((columnChildItem) => {
          columnChildItem.selected = this.getSelectedType(finalChildLayerSelected, columnChildItem);
        });
      }
    });
  }
  isColumnFinalChildColumn(id) {
    let result = false;
    this.mockData.column_list.forEach((columnItem) => {
      if (columnItem.id === id && columnItem.post_selected === true) {
        result = true;
      }
    });
    return result;
  }
  getRecursiveFinalChildrenForColumnItem(columnItem) {
    let finalChildren = [];
    // get child relationships
    const relationship = this.getItemRelationshipConfig(columnItem.column, columnItem.id);
    // recurse to this method for each child relationship
    if (relationship !== undefined) {
      relationship.children.forEach((childItem) => {
        if (this.isColumnFinalChildColumn(childItem.column)) {
          finalChildren.push(childItem);
        } else {
          finalChildren = finalChildren.concat(this.getRecursiveFinalChildrenForColumnItem(childItem));
        }
      });
    }
    return finalChildren;
  }

  inFilteredChildren(filteredChildren, item) {
    let result = false;
    filteredChildren.forEach((filteredItem) => {
      if (filteredItem.column === item.column && filteredItem.id === item.id) {
        result = true;
      }
    });
    return result;
  }

  isItemASelectedChild(selectedItems, item) {
    let result = false;
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.column === item.column && selectedItem.id === item.id) {
        result = true;
      }
    });
    return result;
  }

  getSelectedType(finalChildLayerSelectedItems, columnChildItem) {
    let allSelected;
    let partialSelected;
    // get final children from relationships
    const finalChildren = this.getRecursiveFinalChildrenForColumnItem(columnChildItem);
    // remove duplicates
    const filteredChildren = [];
    finalChildren.forEach((fcItem) => {
      if (!this.inFilteredChildren(filteredChildren, fcItem)) {
        filteredChildren.push(fcItem);
      }
    });

    allSelected = true;
    partialSelected = false;

    // are all the finalChildren for the columnItem in this list of selected children
    filteredChildren.forEach((finalChildForColumn) => {
      if (this.isItemASelectedChild(finalChildLayerSelectedItems, finalChildForColumn)) {
        partialSelected = true;
      } else {
        allSelected = false;
      }
      /*finalChildLayerSelectedItems.forEach((selectedChild) => {

        if (selectedChild.column === finalChildForColumn.column && selectedChild.id === finalChildForColumn.id) {
          if (!finalChildForColumn.selected) {
            allSelected = false;
          } else {
            partialSelected = true;
          }
        }
      });*/
    });
    if (allSelected) {
      return Selected.Complete;
    } else if (partialSelected) {
      return Selected.Partial;
    } else {
      return Selected.None;
    }
    // if so then complete
    // if some then partial
    // else none
  }

  /*selectSubColumnItems(column) {
    // find currently selected items
    column.items.forEach((columnItem) => {
      this.getColumnSubItemsForColumnItem(column.id, columnItem.id, ).forEach((subItem) => {

      });
    });
    // identify and select sub items for each of the currently selected items
  }

  getColumnSubItemsForColumnItem(columnId, columnItemId, searchColumnId) {
    if (searchColumnId === undefined) {
      searchColumnId = columnId;
    }
    const subItems = [];
    this.mockData.relationships.forEach((rItem) => {
      if (rItem.column === columnId && rItem.id === columnItemId) {
        rItem.children.forEach((cItem) => {
          if (cItem.column === searchColumnId) {
            subItems.push(cItem);
          }
        });
      }
    });
    return subItems;
  }*/

  itemClickSelect(e) {
    if (e.column.single_select) {
      this.unselectColumnItems(e.column, [e.columnItem.id]);
    }

    switch (e.columnItem.selected) {
      case Selected.Complete:
        this.refreshSelectionsAndExtraItems(e.column.id, e.columnItem, Selected.None);
        break;
      case Selected.None:
        this.refreshSelectionsAndExtraItems(e.column.id, e.columnItem, Selected.Complete);
        break;
      case Selected.Partial:
        this.refreshSelectionsAndExtraItems(e.column.id, e.columnItem, Selected.Complete);
        break;
      default:
        this.refreshSelectionsAndExtraItems(e.column.id, e.columnItem, Selected.Complete);
    }
    this.bringSelectedValuesToTop();
  }

  itemLogicSelect() {
    // determine if all direct child items are selected
  }
}
