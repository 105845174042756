import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboards/dashboard/dashboard.component';
import { Style1Component } from './details/style1/style1.component';
import { CustomerComponent } from './dashboards/customer/customer.component';
import { InstrumentsComponent } from './dashboards/instruments/instruments.component';
import { AmbassadorComponent } from './dashboards/ambassador/ambassador.component';
import { CtDashboardComponent } from './control_tower/ct-dashboard/ct-dashboard.component';
import { AlertsDashboardComponent } from './alerts/alerts-dashboard/alerts-dashboard.component';
import { Style2Component } from './details/style2/style2.component';
import { Style3Component } from './details/style3/style3.component';
import { Style4Component } from './details/style4/style4.component';
import { ServiceContractsLevel2Component } from './details/service-contracts/service-contracts-level2/service-contracts-level2.component';
import { ServiceContractsLevel3Component } from './details/service-contracts/service-contracts-level3/service-contracts-level3.component';
import { ServiceContractsLevel4Component } from './details/service-contracts/service-contracts-level4/service-contracts-level4.component';
import { UptimeCustomerDetailsComponent } from './details/uptime-customer-details/uptime-customer-details.component';
import { SurveyCommentDetailsComponent } from './details/survey-comment-details/survey-comment-details.component';
import { CustomerOrderDetailsComponent } from './details/customer-order-details/customer-order-details.component';
import { SGMDetailsComponent } from './details/sgm-details/sgm-details.component';
import { PredictiveAlertsLevel2Component } from './details/predictive-alerts/predictive-alerts-level2/predictive-alerts-level2.component';
import { PredictiveAlertsLevel3Component } from './details/predictive-alerts/predictive-alerts-level3/predictive-alerts-level3.component';
import { RnpsSurveyCommentDetailsComponent } from './details/rnps-survey-comment-details/rnps-survey-comment-details.component';
import { SalesSummaryComponent } from './details/sales/sales-summary/sales-summary.component';
import { SalesDetailsComponent } from './details/sales/sales-details/sales-details.component';
import { GsAlertsDashboardComponent } from './alerts/gs-alerts-dashboard/gs-alerts-dashboard.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrDashboardComponent } from './business_review/br-dashboard/br-dashboard.component';
import { BrTemplateEditorComponent } from './business_review/br-template-editor/br-template-editor.component';
import { BrExpandedChartViewComponent } from './business_review/detail_pages/br-expanded-chart-view/br-expanded-chart-view.component';
import { BrListViewComponent } from './business_review/detail_pages/br-list-view/br-list-view.component';
import { BrSystemComparisonListViewComponent } from './business_review/system-comparison-pages/br-system-comparison-list-view/br-system-comparison-list-view.component';
import { BrTimelineViewComponent } from './business_review/timeline-pages/br-timeline-view/br-timeline-view.component';
import {DynamicDataTableComponent} from './business_review/dynamic-data-table/dynamic-data-table.component';
import {DdtLoaderComponent} from './business_review/ddt-loader/ddt-loader.component';
import {BrDetailComponentViewerComponent} from './business_review/br-detail-component-viewer/br-detail-component-viewer.component';

const routes: Routes = [
  { path: 'aoh', component: CtDashboardComponent, canActivate: [MsalGuard] },
  { path: 'aoh/account/:account', component: CtDashboardComponent, canActivate: [MsalGuard] },
  { path: 'alerts', component: AlertsDashboardComponent, canActivate: [MsalGuard] },
  { path: 'gsalerts', component: GsAlertsDashboardComponent, canActivate: [MsalGuard]},
  { path: '', component: DashboardComponent, canActivate: [MsalGuard] },
  { path: 'customerDashboard', component: CustomerComponent, canActivate: [MsalGuard] },
  { path: 'instrumentsDashboard', component: InstrumentsComponent, canActivate: [MsalGuard] },
  { path: 'ambassadorDashboard', component: AmbassadorComponent, canActivate: [MsalGuard] },
  { path: 'hsi', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'hsi/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'hotsheets', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'hotsheets/survey_comments/:customernumber/:country/:interviewdate', component: SurveyCommentDetailsComponent, canActivate: [MsalGuard] },
  { path: 'opencalls', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'cpyr', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'cpyr/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'servicecost', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'servicecost/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'alconnected', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'alconnected/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'alconnectivity', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'alconnectivity/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'hci', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'hci/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'sdfr', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'sdfr/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'ftfr', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'ftfr/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'uptime', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'uptime/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'uptime/details/customerdetails/:customerid/:customername/:country', component: UptimeCustomerDetailsComponent, canActivate: [MsalGuard] },
  { path: 'assay', component: Style4Component, canActivate: [MsalGuard] },
  { path: 'assay/customers/:customerid/:customername', component: Style4Component, canActivate: [MsalGuard] },
  { path: 'cpyp', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'cpyp/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'ticketrending', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'ticketrending/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'trt', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'trt/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'tsb', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'pm', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'customerNps', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'customerNps/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'customerNps/details/survey_comments/:customernumber/:country/:interviewdate', component: SurveyCommentDetailsComponent, canActivate: [MsalGuard] },
  { path: 'servicecosthours', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'servicecosthours/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'utilization', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'servicecontracts', component: ServiceContractsLevel2Component, canActivate: [MsalGuard] },
  { path: 'servicecontracts/entitlements/:customernumber/:serialnumber/:contractnumber', component: ServiceContractsLevel3Component, canActivate: [MsalGuard] },
  { path: 'servicecontracts/entitlements/terms/:customernumber/:serialnumber/:contractnumber/:entitlementid/:entitlementdesc', component: ServiceContractsLevel4Component, canActivate: [MsalGuard] },
  { path: 'utilization/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'CRLSummary', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'CRLSummary/details', component: Style3Component, canActivate: [MsalGuard] },
  { path: 'proactive', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'proactive/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'orders', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'orders/intermediate', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'orders/details/:customername/:ordernumber/:orderstatus', component: CustomerOrderDetailsComponent, canActivate: [MsalGuard] },
  { path: 'alwaysonuptime', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'alwaysonuptime/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'customerswithoutservicecontracts', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'customerswithoutservicecontracts/grid', component: PredictiveAlertsLevel2Component, canActivate: [MsalGuard] },
  { path: 'customerswithoutservicecontracts/griddetails/:countryCode/:customerID/:sn', component: PredictiveAlertsLevel3Component, canActivate: [MsalGuard] },
  { path: 'util_workload', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'util_workload/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'tickets_per_day', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'tickets_per_day/details', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'servicegrossmargin_customerSGM', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'servicegrossmargin_customerSGM/details', component: SGMDetailsComponent, canActivate: [MsalGuard] },
  { path: 'rnps', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'rnps/detail', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'rnps/detail/survey_comments/:score/:customer/:country/:interviewdate/:category', component: RnpsSurveyCommentDetailsComponent, canActivate: [MsalGuard] },
  { path: 'sales', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'sales/summary', component: SalesSummaryComponent, canActivate: [MsalGuard] },
  { path: 'sales/details/:customerid/:ln/:desc/:customername', component: SalesDetailsComponent, canActivate: [MsalGuard] },
  { path: 'rcs', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'rcs/summary', component: SalesSummaryComponent, canActivate: [MsalGuard] },
  { path: 'rcs/details/:customerid/:ln/:desc/:customername', component: SalesDetailsComponent, canActivate: [MsalGuard] },
  { path: 'compliance', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'compliance/details', component: SalesSummaryComponent, canActivate: [MsalGuard] },
  { path: 'amb_orders', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'amb_orders/intermediate', component: Style2Component, canActivate: [MsalGuard] },
  { path: 'amb_orders/details/:customername/:ordernumber/:orderstatus', component: CustomerOrderDetailsComponent, canActivate: [MsalGuard] },
  { path: 'amb_servicegrossmargin_customerSGM', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'amb_servicegrossmargin_customerSGM/details', component: SGMDetailsComponent, canActivate: [MsalGuard] },
  { path: 'amb_customerswithoutservicecontracts', component: Style1Component, canActivate: [MsalGuard] },
  { path: 'amb_customerswithoutservicecontracts/grid', component: PredictiveAlertsLevel2Component, canActivate: [MsalGuard] },
  { path: 'amb_customerswithoutservicecontracts/griddetails/:countryCode/:customerID/:sn', component: PredictiveAlertsLevel3Component, canActivate: [MsalGuard] },
  { path: 'businessReview', component: BrDashboardComponent, canActivate: [MsalGuard]},
  { path: 'businessReview/:devapitools', component: BrDashboardComponent, canActivate: [MsalGuard]},
  { path: 'brTemplateEditor', component: BrTemplateEditorComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/total_inbound_calls', component: BrExpandedChartViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/total_inbound_calls/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/total_inbound_calls/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/total_inbound_calls/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/pm_tickets/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/pm_tickets/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/pm_tickets/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/nps/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/nps/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/nps/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/alcm/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/alcm/list_view', component: DdtLoaderComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/alcm/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/calls_without_service_number/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/calls_without_service_number/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/calls_without_service_number/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/abandoned_calls/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/abandoned_calls/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/abandoned_calls/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/average_wait_time/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/average_wait_time/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/average_wait_time/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/service_level/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/service_level/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/service_level/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/ticket_errors/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/ticket_errors/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/ticket_errors/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/hard_stops/list_view', component: DdtLoaderComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/hard_stops/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/hard_stops/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/uptime/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/uptime/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/uptime/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/tsb/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/tsb/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/tsb/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/response_time/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/response_time/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/response_time/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/phn/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/phn/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/phn/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/instrument_test_counts/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/instrument_test_counts/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/instrument_test_counts/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/assay_test_counts/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/assay_test_counts/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/assay_test_counts/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/mttr/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/mttr/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/mttr/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },

  { path: 'businessReview/ticket_history/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/ticket_history/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/ticket_history/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },


  { path: 'businessReview/example_data/list_view', component: BrListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/example_data/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/example_data/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/tcr/list_view', component: DdtLoaderComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/tcr/system_comparison_view', component: BrSystemComparisonListViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/tcr/timeline_view', component: BrTimelineViewComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/test/example_ddt', component: DdtLoaderComponent, canActivate: [MsalGuard] },
  { path: 'businessReview/test/detail', component: BrDetailComponentViewerComponent, canActivate: [MsalGuard] }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
