<div class="filter-section">
    <div class="filter-header">Accounts</div>
    <div class="filter-results">
        <app-autoselect-list
                [state]="apiState"
                [data]="data"
                [keyField]="'key'"
                [displayField]="'label'"
                [selectedOption]="selectedItem"
                (itemSelect)="setSelectedItem($event)">
        </app-autoselect-list>
    </div>
</div>
