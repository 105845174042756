import { RowParserInterface } from '../row-parser-interface';
import * as _ from 'lodash';

export class PercentGoals implements RowParserInterface {
  get(sourceData, params) {
    const rowData = {
      description: params.label
    };

    for (const value of sourceData.values) {
      const key = value.xlabel;
      // rowData[key] = _.round(value.stackgoals[params.stackGoalsIndex], 2);
      rowData[key] = value.stackgoals[params.stackGoalsIndex];
    }
    return rowData;
  }
}

