import { Component, OnInit } from '@angular/core';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';

@Component({
  selector: 'app-gs-alerts-navbar',
  templateUrl: './gs-alerts-navbar.component.html',
  styleUrls: ['./gs-alerts-navbar.component.scss']
})
export class GsAlertsNavbarComponent implements OnInit {

  ToolSelectionOptions = ToolSelectionOptions;

  constructor() { }

  ngOnInit(): void {
  }

}
