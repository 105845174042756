<a [routerLink]="config.table.level2DetailLink ? [config.table.level2DetailLink] : []">
    <kendo-grid
            [data]="gridData"
            [reorderable]="false"
            [pageable]="false"
            [navigable]="false"
    >
        <!--scrollable="none"-->
        <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
        <ng-template kendoGridToolbarTemplate>
            <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
        </ng-template>
        <ng-container *ngFor="let col of dataCols; let i = index">
            <kendo-grid-column
                    [title]="col.description"
                    [width]="col.width"
                    [headerStyle]="{'background-color': 'blue', 'color': '#FFFFFF'}"
                    [style]="colStyle(i)">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <span class="whole-cell" [style]="cellStyle(rowIndex, i)">
                        {{dataItem[i] | emptyMask | abbottBigNumber:1}}
                    </span>
                </ng-template>
            </kendo-grid-column>
        </ng-container>
    </kendo-grid>
</a>