<div class="container-fluid" *ngIf="config">
    <div class="row">
        <div class="col-12">
            <app-state-handler [requestState]="state" [data]="gridData">
            <kendo-grid 
                [data]="gridData" 
                [group]="groups"
                [ngStyle]="{'max-height': getMaxHeight()}">
                <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
                <ng-template kendoGridToolbarTemplate>
                    <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                </ng-template>
                <ng-container *ngFor="let col of dataCols index as idx;  last as isLast">
                    <kendo-grid-column 
                        [field]="col.ref"
                        [title]="col.description"
                        [hidden]="col.hidden"
                        [headerClass]="'data-grid-header'"
                        [style]="col.customStyle">
                        <ng-template *ngIf='col.groupHeaderColumn === true' kendoGridGroupHeaderTemplate let-dataItem let-group let-field="field" let-value="value">
                            {{value}}
                        </ng-template>
                        <ng-template *ngIf='isLast === true' kendoGridGroupHeaderColumnTemplate let-group let-aggregates="aggregates" let-field="field" let-value="value">
                            <!-- <ng-template *ngIf="checkIf(group)"></ng-template> -->
                            <!-- <ng-template *ngIf="checkIf(aggregates)"></ng-template> -->
                            <span style='text-align: right'> 
                                {{ aggregates[config.groupCountField] }} Unique Assays 
                                <a *ngIf='col.addCustomerDetailsLink === true' [routerLink]="[config.customerDetailsLink, aggregates[config.groupFieldId], value]" 
                                            style="font-weight: 500; font-size: 16pt; color: #007bff">></a>
                            </span>
                        </ng-template>                    
                    </kendo-grid-column>
                </ng-container>
            </kendo-grid>
            </app-state-handler>
        </div>
    </div>
</div>
