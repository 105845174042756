import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpHeaders} from "@angular/common/http";
import { ApiService } from './api.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PushNotificationService {

    subscribeRoute = 'pushnotifications/subscribe';
    sendRoute = 'pushnotifications/send';

    apiUrl = environment.proxyServerUrl + '/proxy2.php';

    constructor(private apiService: ApiService, private http: HttpClient) {
    }

    /*addPushSubscriber(sub: any) {
        const requestHandler = this.apiService.get(this.subscribeRoute);
        let subsJsonString = JSON.stringify(sub);
        console.log(subsJsonString);
        let subsObj = JSON.parse(subsJsonString);
        console.log(subsObj);
        let params = { endpoint: subsObj.endpoint, expirationTime: subsObj.expirationTime, p256dh: subsObj.keys.p256dh, auth: subsObj.keys.auth };
        console.log(params);
        return requestHandler.sendRequest(this.subscribeRoute, params);
    } 

    send() {
        const requestHandler = this.apiService.get(this.sendRoute);
        requestHandler.sendRequest(this.sendRoute);
    } */

    addPushSubscriber(sub:any) {
        const formData = new HttpParams({
            fromObject: {
              route: this.subscribeRoute,
              post: JSON.stringify(sub)
            }
          });
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        return this.http.post(this.apiUrl + '?route=' + this.subscribeRoute, formData, {headers: headers, withCredentials: true });
    }

    send() {        
        return this.http.post(this.apiUrl + '?route=' + this.sendRoute, null);
    }
  
}
