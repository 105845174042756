import { Component, OnInit } from '@angular/core';
import {TileComponent} from '../../base/tile.component';
import {ComponentLookup} from '../../../decorators/component-lookup';

@ComponentLookup('NpsComponent')
@Component({
  selector: 'app-nps',
  templateUrl: './nps.component.html',
  styleUrls: ['./nps.component.scss']
})
export class NpsComponent extends TileComponent implements OnInit {
  translateApiDataForChart(apiData) {
    const categoryList = [];
    const seriesList = [];

    let values = apiData.values;
    if ('periodsToShow' in this.preferences) {
      // only use the last x periods in the chart
      values = values.slice(Math.max(values.length - this.preferences.periodsToShow, 1));
    }
    for (const value of values) {
      categoryList.push(value.xlabel);
      for (let i = 0; i < value.points.length; i++) {
        let excludeLines = [];
        if (this.preferences.hasOwnProperty('excludeLines')) {
          excludeLines = this.preferences.excludeLines;
        }
        // console.log(excludeLines);
        if (!excludeLines.includes(i)) { // if exclusions do not include i
          if (typeof seriesList[i] === 'undefined') {
            seriesList[i] = {
              data: [],
              color: apiData.linecolors[i]
            };
          }
          seriesList[i].data.push(value.points[i]);
        }
      }
    }

    let tickCount = 4;
    if ('tickCount' in this.preferences) {
      tickCount = this.preferences.tickCount;
    }
    const axis2Max = this.nextHighestNumberDivisibleBy(apiData.yleftmax, tickCount - 1);
    this.chartOptions = {
      // title: {
      //     text: apiData.title,
      //     position: 'top',
      //     font: 'bold 14px  Arial,Helvetica,sans-serif'
      // },
      seriesDefaults: {
        type: 'line',
        area: {
          line: {
            style: 'smooth'
          }
        }
      },
      series: seriesList,
      valueAxis: {
        labels: {
          format: '{0}'
        },
        title: {
          text: apiData.yaxisleftlabel
        },
        line: {
          visible: false
        },
        axisCrossingValue: -10,
        max: axis2Max,
        min: 0,
        majorUnit: axis2Max / (tickCount - 1)
      },
      categoryAxis: {
        categories: categoryList,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45
        }
      }
    };
  }
}
