import {StackKeys} from '../../details/row_parsers/stack-keys';
import {StackValues} from '../../details/row_parsers/stack-values';
import {StackTotals} from '../../details/row_parsers/stack-totals';
import {Percents} from '../../details/row_parsers/percents';
import {PercentGoals} from '../../details/row_parsers/percent-goals';
import {PointValues} from '../../details/row_parsers/point-values';

export const RowParsers = {
  stackKeys: StackKeys,
  stackValues: StackValues,
  stackTotals: StackTotals,
  percents: Percents,
  percentGoals: PercentGoals,
  pointValues: PointValues
};
