import {BrTileReference} from './br-tile-reference';

export const DASHBOARD_ARRANGEMENT: BrTileReference[] = [
  {
    apiSource: '/qbr/kpi/alcm/thumbnail',
    componentName: 'BrTextIndicatorComponent',
    detailLink: '/businessReview/alcm/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'AbbottLink Customer Maintenance'
    }
  },
  {
    apiSource: '/qbr/kpi/assay_test_counts/thumbnail',
    componentName: 'BrPieChartComponent',
    detailLink: '/businessReview/assay_test_counts/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Top Assays'
    }
  },
  {
    apiSource: '/qbr/kpi/nps/thumbnail',
    componentName: 'BrTopItemsComponent',
    detailLink: '/businessReview/nps/list_view',
    preferences: {
      periodsToShow: 4,
      title: 'CUSTOMER NPS SCORES'
    }
  },
  {
    apiSource: '/qbr/kpi/calls_without_service_number/thumbnail',
    componentName: 'BrMultiLineChartComponent',
    detailLink: '/businessReview/calls_without_service_number/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Calls w/Out Service Number'
    }
  },
  {
    apiSource: '/qbr/kpi/instrument_test_counts/thumbnail',
    componentName: 'BrLineChartComponent',
    detailLink: '/businessReview/instrument_test_counts/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Instrument Test Counts'
    }
  },
  {
    apiSource: '/qbr/kpi/mttr/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/mttr/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'MTTR'
    }
  },
  {
    apiSource: '/qbr/kpi/phn/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/phn/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'PHN Tickets'
    }
  },
  {
    apiSource: '/qbr/kpi/response_time/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/response_time/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Response Time'
    }
  },
  {
    apiSource: '/qbr/kpi/service_level/thumbnail',
    componentName: 'BrMultiLineChartComponent',
    detailLink: '/businessReview/service_level/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Telephony Service Level'
    }
  },
  {
    apiSource: '/qbr/kpi/tcr/thumbnail',
    componentName: 'BrLineChartComponent',
    detailLink: '/businessReview/tcr/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Test Completion Rate'
    }
  },
  {
    apiSource: '/qbr/kpi/ticket_history/thumbnail',
    componentName: 'BrPieChartComponent',
    detailLink: '/businessReview/ticket_history/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Ticket History'
    }
  },
  {
    apiSource: '/qbr/kpi/total_inbound_calls/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/total_inbound_calls/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Total Inbound Calls'
    }
  },
  {
    apiSource: '/qbr/kpi/pm_tickets/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/pm_tickets/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'PM Tickets'
    }
  },
  {
    apiSource: '/qbr/kpi/ticket_errors/thumbnail',  // WORKING ON NOW /////
    componentName: 'BrPieChartComponent',
    detailLink: '/businessReview/ticket_errors/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Top Issues'
    }
  },
  {
    apiSource: '/qbr/kpi/hard_stops/thumbnail',  // WORKING ON NOW /////
    componentName: 'BrPieChartComponent',
    detailLink: '/businessReview/hard_stops/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Hard Stops'
    }
  },
  {
    apiSource: '/qbr/kpi/tsb/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/tsb/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'TSBs'
    }
  },
  {
    apiSource: '/qbr/kpi/example_data/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    // detailLink: '/example_data',
    preferences: {
      periodsToShow: 4,
      title: 'Example Data'
    }
  },
  {
    apiSource: '/qbr/kpi/uptime/thumbnail',
    componentName: 'BrStackedBarLineChartComponent',
    detailLink: '/businessReview/uptime/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Uptime'
    }
  },
  {
    apiSource: '/qbr/kpi/example_pie_chart/thumbnail',
    componentName: 'BrExamplePieChartComponent',
    // detailLink: '/example_data',
    preferences: {
      periodsToShow: 4,
      title: 'Example Pie Chart'
    }
  },
  {
    apiSource: '/qbr/kpi/abandoned_calls/thumbnail',
    componentName: 'BrMultiLineChartComponent',
    detailLink: '/businessReview/abandoned_calls/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Abandoned / Callbacks'
    }
  },
  {
    apiSource: '/qbr/kpi/average_wait_time/thumbnail',
    componentName: 'BrMultiLineChartComponent',
    detailLink: '/businessReview/average_wait_time/system_comparison_view',
    preferences: {
      periodsToShow: 4,
      title: 'Average & Longest Wait Times'
    }
  }
];
