import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CtServiceService {
  customerScope;
  customerScopeChangeCallbacks = [];
  callbackScopeIndex = 0;
  customerId;
  customerName;
  callbackIndex = 0;
  customerSelectionChangeCallbacks = {};
  hasSetCustomerId = false;
  returnCustomer = null;

  constructor() { }

  setCustomerScope(scope) {
    this.customerScope = scope;
    this.callCustomerScopeChangeCallbacks();
    return this;
  }

  getCustomerScope() {
    return this.customerScope;
  }

  onCustomerScopeChange(callback) {
    this.customerScopeChangeCallbacks[++this.callbackScopeIndex] = callback;
    return this.callbackScopeIndex;
  }

  callCustomerScopeChangeCallbacks() {
    for (const callbackKey in this.customerScopeChangeCallbacks) {
      if (callbackKey in this.customerScopeChangeCallbacks) {
        const callback = this.customerScopeChangeCallbacks[callbackKey];
        callback();
      }
    }
    return this;
  }

  clearCustomerScopeChangeEvent(index) {
    delete this.customerSelectionChangeCallbacks[index];
  }

  setCustomerId(customerId, customerName = null) {
    this.customerId = customerId;
    this.customerName = customerName;

    this.callCustomerSelectionChangeCallbacks();
    return this;
  }

  getCustomerId() {
    return this.customerId;
  }

  getCustomerName() {
    return this.customerName;
  }

  setReturnCustomer(returnCustomerParam) {
    this.returnCustomer = returnCustomerParam;
  }

  isReturnCustomerSet() {
    return (this.returnCustomer !== null);
  }

  getReturnCustomer() {
    return this.returnCustomer;
  }

  onCustomerSelectionChange(callback) {
    this.customerSelectionChangeCallbacks[++this.callbackIndex] = callback;
    if (this.hasSetCustomerId) {
      callback();
    }
    return this.callbackIndex;
  }

  clearCustomerSelectionChangeEvent(index) {
    delete this.customerSelectionChangeCallbacks[index];
  }

  getSize(obj) {
    let size = 0;
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        size++;
      }
    }
    return size;
  }

  callCustomerSelectionChangeCallbacks() {
    this.hasSetCustomerId = true;
    for (const callbackKey in this.customerSelectionChangeCallbacks) {
      if (callbackKey in this.customerSelectionChangeCallbacks) {
        const callback = this.customerSelectionChangeCallbacks[callbackKey];
        callback();
      }
    }
    return this;
  }
}
