import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import {BrParamsService} from '../br-params.service';
import {PresentationToggleHandlerService} from '../presentation-toggle-handler.service';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {DASHBOARD_ARRANGEMENT} from '../configuration/dashboard-arrangement';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {
  faChevronUp,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-br-template-editor',
  templateUrl: './br-template-editor.component.html',
  styleUrls: ['./br-template-editor.component.scss']
})
export class BrTemplateEditorComponent implements OnInit, OnDestroy {
  faUp = faChevronUp;
  faDown = faChevronDown;
  defaultDashboard = DASHBOARD_ARRANGEMENT;

  public stdKpiListData = null;
  protected requestHandler;
  protected stdKpiListSource = '/qbr/template/std/std_kpi_list';
  protected saveUserTemplateSource = '/qbr/template/custom/save_user_template_add';
  protected saveEditUserTemplateSource = '/qbr/template/custom/save_user_template_edit';
  protected refreshHandleKey;
  public state = RequestState.Inactive;
  idSet;
  public templateStringOrder = null;
  public newListOrder = [];
  recommended = [12, 36];

  @Output() saveEvent: EventEmitter<string> = new EventEmitter();
  @Output() cancelEvent: EventEmitter<string> = new EventEmitter();

  constructor(
    private navbarBuilderService: NavbarBuilderService,
    private presentationToggleHandlerService: PresentationToggleHandlerService,
    private brParamsService: BrParamsService,
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
  ) { }

  @Input() set id( value: string ) {
    this.idSet = value;
    this.requestHandler = this.callApiSource(this.stdKpiListSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.stdKpiListData = apiData;
        this.newListOrder = apiData.items;
        for (const item of this.newListOrder) {
          item.active = true;
          if (item.recommended) {
            this.recommended.push(item.id);
          }
        }
        this.buildListOrder();
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.stdKpiListSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.stdKpiListSource));
  }

  @Input() set templateString( value: string ) {
    this.templateStringOrder = value.split(',');
    this.buildListOrder();
  }

  buildListOrder() {
    if (this.stdKpiListData !== null && this.templateStringOrder !== null && this.templateStringOrder.length > 1) {
      // order items
      this.newListOrder = [];
      for (const orderItem of this.templateStringOrder) {
        const item = this.getStdKpiListItem(orderItem);
        if (item !== null && item !== undefined) {
          item.active = true;
        }
        this.newListOrder.push(item);
      }
      // get items missing
      for (const i in this.stdKpiListData.items) {
        if (!this.templateStringOrder.includes(this.stdKpiListData.items[i].id.toString())) {
          this.stdKpiListData.items[i].active = false;
          this.newListOrder.push(this.stdKpiListData.items[i]);
        }
      }
    }
  }

  getStdKpiListItem(id) {
    let returnValue = null;
    for (const i in this.stdKpiListData.items) {
      if (this.stdKpiListData.items[i].id == id) {
        returnValue = this.stdKpiListData.items[i];
      }
    }
    return returnValue;
  }

  ngOnInit(): void {
    this.navbarBuilderService.setNavbarChoice('business-review');
    this.navbarBuilderService.setNavFilterSelection({
      preferences: false,
      dashboardSelector: false,
      toolSelection: ToolSelectionOptions.businessreview,
      bookmarksFlag: false,
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false
    });
    this.navbarBuilderService.setIsVisible(true);
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  nameChange(e) {
    this.brParamsService.set('br_template_name', e);
    // this.refreshHandlerService.triggerCallbacks(['br_template_name']);
  }

  saveAs() {
    let source = this.saveUserTemplateSource;
    if (this.idSet !== 'br_kpi') {
      source = this.saveEditUserTemplateSource;
      this.brParamsService.set('br_template_id', this.idSet);
    }
    const selectedIds = [];
    for (let i = 0; i < this.newListOrder.length; i++) {
      const item = this.newListOrder[i];
      if (item.active === undefined || item.active) {
        selectedIds.push(item.id);
      }
    }
    this.brParamsService.set('br_template_string', selectedIds.join(','));
    // br_template_id

    this.requestHandler = this.callApiSource(source, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.brParamsService.set('list_user_templates_update', this.brParamsService.get('list_user_templates_update') + 1);
        this.refreshHandlerService.triggerCallbacks(['list_user_templates_update']);
        this.cancelEvent.emit('saved');
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(source); // , null, true
    }, this.requestHandler.getRelativeParams(source));
    this.saveEvent.emit('save');
  }

  cancel() {
    this.cancelEvent.emit('cancel');
  }

  moveUp(i) {
    const element = this.newListOrder.splice(i, 1)[0];
    this.newListOrder.splice(i - 1, 0, element);
    // splice(start, deleteCount)
    // splice(start, deleteCount, item1)
  }
  moveDown(i) {
    const element = this.newListOrder.splice(i, 1)[0];
    this.newListOrder.splice(i + 1, 0, element);
  }
  switchActive(i) {
    if (this.newListOrder[i].active === undefined) {
      this.newListOrder[i].active = false;
    } else if (this.newListOrder[i].active) {
      this.newListOrder[i].active = false;
    } else {
      this.newListOrder[i].active = true;
    }
  }
  setRecommended() {
    for (const item of this.newListOrder) {
      if (this.isRecommended(item)) {
        item.active = true;
      } else {
        item.active = false;
      }
    }
  }
  isRecommended(item) {
    if (this.recommended.includes(item.id)) {
      return true;
    }
    return false;
  }
}
