import { RowParserInterface } from '../row-parser-interface';
import * as _ from 'lodash';

export class Percents implements RowParserInterface {
  get(sourceData, params) {
    const rowData = {
      description: params.label
    };
    const totals = sourceData[0];
    const devideBy = sourceData[1];

    for (const index of Object.keys(totals)) {
      if (index !== 'description') {
        rowData[index] = _.round(100 / totals[index] * devideBy[index], 2);
      }
    }
    return rowData;
  }
}
