import {Input, OnDestroy, OnInit} from '@angular/core';
import {TileReference} from '../../dashboards/tile-reference';
import { BaseTileComponent } from './base-tile.component';
import {RequestState} from '../../api/request-handler';

export abstract class MultiAPITileComponent extends BaseTileComponent implements OnInit, OnDestroy {
  @Input() tileReference: TileReference;
  public apiData = [];
  public chartOptions;
  protected refreshHandleKeys = [];
  protected requestHandlers = [];

  ngOnInit(): void {
    this.setupPreferences();
    const apiMappingLinks = this.tileReference.apiMappingLinks;
    apiMappingLinks.forEach((apiMappingLink, index) => {
      this.requestHandlers[index] = this.callApiSource(apiMappingLink, index);
      this.requestHandlers[index].onStateChange((state) => {
        if (state === RequestState.Complete) {
          const response = this.requestHandlers[index].getResponse();
          const apiData = JSON.parse(response.body);
          this.setApiCallResponse(apiData, index);
        }
      });
      this.refreshHandleKeys[index] = this.refreshHandlerService.onRefreshEvent(() => {
        this.requestHandlers[index].sendRequest(apiMappingLink);
      }, this.requestHandlers[index].getRelativeParams(apiMappingLink));
    });
  }

  ngOnDestroy(): void {
    for (let index = 0; index < this.requestHandlers.length; index++) {
      this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKeys[index]);
      this.requestHandlers[index].cancelSubscription();
    }
  }

  abstract translateApiDataForChart(apiData);

  setApiCallResponse(apiData, apiDataIndex) {
    this.apiData[apiDataIndex] = apiData;
    if (apiDataIndex === this.tileReference.apiMappingLinks.length - 1) {
      this.translateApiDataForChart(this.apiData);
    }
  }

}
