<div style="margin-top:-70px;">
  <button [routerLink]="'/businessReview'">KPI Home</button>
  <button *ngIf="this.returnLink !== undefined" [routerLink]="this.returnLink.url" [queryParams]="this.returnLink.params">Return</button>
  <div style="width: 100%;">
    <app-filter-presentation-header [includePeriodRange]="false"></app-filter-presentation-header>
  </div>
  <table class='sales-details-table'>
    <tr>
      <td colspan="2" style="font-weight:bold;font-size:15pt;color:Highlight">{{apiDisplayTitle}} - Timeline Data</td>
    </tr>
    <tr style="color: Highlight; font-weight: bold;">
      <td>Customer: </td>
      <td style="text-align:right;">{{sCustomerName}}</td>
    </tr>
  </table>
  <div *ngIf="apiChartType !== 'MultiBar' && apiChartType !== 'StackBar'">
    <kendo-chart>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [categories]="chartLabels">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-series>
        <kendo-chart-series-item type="column" [data]="chartData">
          <kendo-chart-series-item-highlight [visible]="false"></kendo-chart-series-item-highlight>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
  <div *ngIf="apiChartType === 'MultiBar' || apiChartType === 'StackBar'">
    <kendo-chart>
      <kendo-chart-legend align="center" position="top"></kendo-chart-legend>
      <kendo-chart-category-axis>
        <kendo-chart-category-axis-item [categories]="chartLabels">
        </kendo-chart-category-axis-item>
      </kendo-chart-category-axis>
      <kendo-chart-series>
        <kendo-chart-series-item *ngFor="let item of multiChartSeriesArray"
                                 type="column"
                                 [data]="item.data"
                                 [name]="item.name"
                                 [stack]=item.stack>
          <kendo-chart-series-item-highlight [visible]="false"></kendo-chart-series-item-highlight>
        </kendo-chart-series-item>
      </kendo-chart-series>
    </kendo-chart>
  </div>
  <app-state-handler [requestState]="state" [data]="apiData">
    <div *ngIf="apiData !== null">
      <kendo-grid #grid="kendoGrid"
                  [data]="gridData"
                  [pageable]="false"
                  [pageSize]="gridState.take"
                  [skip]="gridState.skip"
                  [filter]="gridState.filter"
                  filterable="none"
                  [sort]="gridState.sort"
                  [sortable]="false"
                  [resizable]="true"
                  (pageChange)="pageChange($event)"
                  (sortChange)="sortChange($event)"
                  (filterChange)="filterChange($event)"
                  style="max-height: 400px">
        <ng-container *ngFor="let col of dataCols">
          <kendo-grid-column [field]="col.ref+'.value'"
                             [title]="col.description"
                             [width]="col.width">
            <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
              {{column.title}}
            </ng-template>
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span>
                <a [routerLink]="getDetailLink()" [queryParams]="getQueryParams({'period':col.ref})">{{dataItem[col.ref].value}}</a>
              </span>
            </ng-template>
          </kendo-grid-column>
        </ng-container>
      </kendo-grid>
    </div>
  </app-state-handler>
  <div style="width: 100%; text-align: center;">
    <app-filter-presentation-footer></app-filter-presentation-footer>
  </div>
</div>
