<a class="nav-link" *ngIf="navFilterSelection && navFilterSelection.personaSelector">
    <a #anchor (click)="onToggle()" class="persona-button relativity-fix">
        <div class="menuText1">{{menuText1}}</div>
        <div class="menuText2">{{menuText2}}</div>
    </a>
    <kendo-popup #popup [anchor]="anchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" (anchorViewportLeave)="menuViable = false" *ngIf="menuViable">
        <div class='content'>
            <div class="button-row" *ngIf="countActiveOptions([Personas.Customer, Personas.Instrument, Personas.SN, Personas.Account]) > 1">
                <kendo-buttongroup [selection]="'single'" class="mx-auto" *ngIf="navFilterSelection.dashboardSelector">
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.Customer)"
                            (selectedChange)="onSelectedChange(PersonaTypes.Customer)"
                            *ngIf="isActiveOption(Personas.Customer)">Customer</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.ProductLine)"
                            (selectedChange)="onSelectedChange(PersonaTypes.ProductLine)"
                            *ngIf="isActiveOption(Personas.Instrument)">Instrument</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.SN)"
                            (selectedChange)="onSelectedChange(PersonaTypes.SN)"
                            *ngIf="isActiveOption(Personas.SN)">SN</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.Account)"
                            (selectedChange)="onSelectedChange(PersonaTypes.Account)"
                            *ngIf="isActiveOption(Personas.Account)">Account</button>
                </kendo-buttongroup>
            </div>
            <div class="tab-content">
                <app-customer-selector class="tab"
                                       [class.active-tab]="(selectedTab === PersonaTypes.Customer || countActiveOptions([Personas.Customer, Personas.Instrument, Personas.SN]) === 1)"
                                       *ngIf="isActiveOption(Personas.Customer)"></app-customer-selector>
                <app-instrument-selector class="tab"
                                         [class.active-tab]="(selectedTab === PersonaTypes.ProductLine)"
                                         *ngIf="isActiveOption(Personas.Instrument)"></app-instrument-selector>
                <app-sn-selector class="tab"
                                 [class.active-tab]="(selectedTab === PersonaTypes.SN)"
                                 *ngIf="isActiveOption(Personas.SN)"></app-sn-selector>
                <app-account-selector class="tab"
                                 [class.active-tab]="(selectedTab === PersonaTypes.Account)"
                                 *ngIf="isActiveOption(Personas.Account)"></app-account-selector>
            </div>
        </div>
    </kendo-popup>
</a>
