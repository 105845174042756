import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrHistoryRange {
  historyRange;

  constructor() { }

  setHistoryRange(value) {
    this.historyRange = value;
    return this;
  }

  getHistoryRange() {
    return this.historyRange;
  }
}
