import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'emptyMask'})
export class EmptyValueMaskPipe implements PipeTransform {
  transform(value: any, valueIfBlank?: any): any {
    if (value === null || value === '' || value === ' ' || value === 'N/A' || value === 'n/a') {
      if (valueIfBlank !== undefined) {
        return valueIfBlank;
      }
      return '';
      //return '-';
    } else {
      return value;
    }
  }
}
