<div *ngIf="config">
    <a [routerLink]="config.table.level2DetailLink ? [config.table.level2DetailLink] : []">
        <kendo-grid
                [data]="gridData"
                [reorderable]="false"
                [pageable]="false"
                [navigable]="false"
        >
            <!--scrollable="none"-->
            <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
            <ng-template kendoGridToolbarTemplate>
                <button type="button" kendoGridExcelCommand icon="file-excel">Export to Excel</button>
            </ng-template>
            <ng-container *ngFor="let col of dataCols; let i = index">
                <kendo-grid-column
                        [field]="col.ref"
                        [title]="col.description"
                        [width]="col.width"
                        [headerStyle]="{'background-color': 'blue', 'color': '#FFFFFF'}"
                        [style]="col.customStyle">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <span class="whole-cell" [style.color]="color(rowIndex)">
                            {{dataItem[col.ref] | emptyMask}}
                    </span>
                    </ng-template>
                </kendo-grid-column>
            </ng-container>
            <!--<kendo-grid-column field="shipDate" title="Ship Date" width="135" filter="date" format="{0:yyyy-MM-dd}">-->
            <!--<ng-template kendoGridCellTemplate let-dataItem>-->
            <!--{{dataItem.shipDate | date:'yyyy-MM-dd' | emptyMask}}-->
            <!--</ng-template>-->
            <!--</kendo-grid-column>-->
        </kendo-grid>
    </a>
</div>    