<div class="navbar-fixed-top">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="justify-content-between d-flex d-sm-flex d-md-none d-lg-none d-xl-none flex-row flex-fill" *ngIf="navFilterSelection">
                <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                <span class="mx-auto title">
                    <div class="app-title">ONeview - <span class="always-on">Always ON</span><span class="sub-title"><span class="seperator"> » </span><span class="feature-title">MyCRL</span></span></div>
                </span>
                <button class="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        aria-label="Toggle navigation"
                        (click)="clickEvent()">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="navbar-collapse flex-row d-none d-sm-none d-md-flex d-lg-flex d-xl-flex" *ngIf="navFilterSelection">
                <div class="d-flex flex-row flex-fill">
                    <a class="navbar-brand" href="#"><img src="assets/small_abbott_logo_transparent.gif" width="32px"></a>
                    <app-tool-selector [selectedOption]="navFilterSelection.toolSelection"></app-tool-selector>
                    <kendo-buttongroup [selection]="'single'" *ngIf="navFilterSelection.dashboardSelector">
                        <button kendoButton [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.dashboard" routerLink="/" (click)="dashboardChange(DashboardOptions.dashboard)" title="Dashboard: Show Metrics based on the persona or organization selected.">
                            <fa-icon [icon]="faGripHorizontal"></fa-icon>
                        </button>
                        <button kendoButton [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.customer" routerLink="/customerDashboard" (click)="dashboardChange(DashboardOptions.customer)" title="Customer: Show metrics for all instruments for a single customer assigned to a persona.">
                            <fa-icon [icon]="faUsers"></fa-icon>
                        </button>
                        <button kendoButton [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.instruments" routerLink="/instrumentsDashboard" (click)="dashboardChange(DashboardOptions.instruments)" title="Instruments: Show metrics based on the persona's CRL or the organization selected. (This tab allows filtering down to a particular Instrument type or Instrument serial number).">
                            <fa-icon [icon]="faLaptopMedical"></fa-icon>
                        </button>
                        <button kendoButton *ngIf="isAmbTabVisible" [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.ambassador" routerLink="/ambassadorDashboard" (click)="dashboardChange(DashboardOptions.ambassador)" title="Ambassador: Show metrics related to Sales.">
                            <fa-icon [icon]="faUserTie"></fa-icon>
                        </button>
                    </kendo-buttongroup>
                    <span class="mx-auto title">
                        <div class="app-title"><span class="always-on">Always ON</span></div>
                        <!--<div class="app-title">ONeview - <span class="always-on">Always ON</span><span class="sub-title"><span class="seperator"> » </span><span class="feature-title">MyCRL</span></span></div>-->
                    </span>
                    <app-bookmarks *ngIf="navFilterSelection.bookmarksFlag" [displayTitles]="false"></app-bookmarks>&nbsp;
                    <app-preferences *ngIf="navFilterSelection.preferences" [displayTitles]="false"></app-preferences>
                </div>
            </div>
        </div>
    </nav>
    <nav class="navbar navbar-expand-lg navbar-light bg-light d-none d-sm-none d-md-block d-lg-block d-xl-block" kendoTooltip position="bottom">
        <div class="container-fluid">
            <div class="collapse navbar-collapse d-flex flex-row flex-fill" id="navbarSupportedContent" *ngIf="navFilterSelection">
                <div class="filter-text">
                    <!--<fa-icon [icon]="faFilter"></fa-icon><br/>-->
                    Filters
                </div>
                <div>
                    <!--title="Persona: Filter metrics to the user or manager selected or set the CRL based on Location."-->
                    <app-persona-selector [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector>
                </div>
                <div *ngIf="this.lastSelectedDashboardTabInfo === DashboardOptions.customer || this.lastSelectedDashboardTabInfo === DashboardOptions.instruments || this.lastSelectedDashboardTabInfo === DashboardOptions.ambassador">
                    <!--title="Second Level Persona Selection: Drill Down to a Specific Customer or Instrument (Type/Serial#)."-->
                    <app-persona-selector2 [anchorAlign]="{ horizontal: 'left', vertical: 'bottom' }"></app-persona-selector2>
                </div>
                <div class="ml-auto switch-container">
                    <app-parameter-switches [displayTitles]="false"></app-parameter-switches>
                </div>
                <span class="search-button-container" *ngIf="this.lastSelectedDashboardTabInfo !== DashboardOptions.ambassador">
                    <app-search-popup></app-search-popup>
                </span>
            </div>
        </div>
    </nav>
</div>
<div class="wrapper">
    <div class="sidebar" [ngClass]="status ? 'active' : 'inactive'">
        <span *ngIf="navFilterSelection">
            <div class="tool-selector">
                <app-tool-selector [selectedOption]="navFilterSelection.toolSelection"></app-tool-selector>
            </div>
            <div class="v-sidebar-buttons">
                <div class="btn-group btn-group-vertical btn-group-toggle" data-toggle="buttons">
                    <button class="btn btn-secondary" [class.active]="navFilterSelection.selectedDashboard===DashboardOptions.dashboard" routerLink="/" (click)="dashboardChange(DashboardOptions.dashboard)" title="Dashboard: Show Metrics based on the persona or organization selected.">
                        <span class="icon-container">
                            <fa-icon [icon]="faGripHorizontal"></fa-icon>
                        </span>
                        Dashboard
                    </button>
                    <button class="btn btn-secondary" [class.active]="navFilterSelection.selectedDashboard===DashboardOptions.customer" routerLink="/customerDashboard" (click)="dashboardChange(DashboardOptions.customer)" title="Customer: Show metrics for all instruments for a single customer assigned to a persona.">
                        <span class="icon-container">
                            <fa-icon [icon]="faUsers"></fa-icon>
                        </span>
                        Customer
                    </button>
                    <button class="btn btn-secondary" [class.active]="navFilterSelection.selectedDashboard===DashboardOptions.instruments" routerLink="/instrumentsDashboard" (click)="dashboardChange(DashboardOptions.instruments)" title="Instruments: Show metrics based on the persona's CRL or the organization selected. (This tab allows filtering down to a particular Instrument type or Instrument serial number).">
                        <span class="icon-container">
                            <fa-icon [icon]="faLaptopMedical"></fa-icon>
                        </span>
                        Instruments
                    </button>
                    <button class="btn btn-secondary" [class.active]="navFilterSelection.selectedDashboard===DashboardOptions.ambassador" *ngIf="isAmbTabVisible" routerLink="/ambassadorDashboard" (click)="dashboardChange(DashboardOptions.ambassador)" title="Ambassador: Show metrics related to Sales.">
                        <span class="icon-container">
                            <fa-icon [icon]="faUserTie"></fa-icon>
                        </span>
                        Ambassador
                    </button>
                </div>
                <!--<div class="navbar-expanded-only">-->
                <!--&lt;!&ndash;<app-persona-selector></app-persona-selector>&ndash;&gt;-->
                <!--<div class="dropdown" *ngIf="navFilterSelection.dashboardSelector">-->
                <!--<button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">-->
                <!--Dashboard-->
                <!--</button>-->
                <!--<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">-->
                <!--<a class="dropdown-item" routerLink="/" (click)="dashboardChange(DashboardOptions.dashboard)">Dashboard</a>-->
                <!--<a class="dropdown-item" routerLink="/customerDashboard" (click)="dashboardChange(DashboardOptions.customer)">Customer</a>-->
                <!--<a class="dropdown-item" routerLink="/instrumentsDashboard" (click)="dashboardChange(DashboardOptions.instruments)">Instruments</a>-->
                <!--<a class="dropdown-item" *ngIf="isAmbTabVisible" routerLink="/ambassadorDashboard" (click)="dashboardChange(DashboardOptions.ambassador)">Ambassador</a>-->
                <!--</div>-->
                <!--</div>-->
                <!--</div>-->
                <!--<kendo-buttongroup [selection]="'single'" *ngIf="navFilterSelection.dashboardSelector">-->
                    <!--<button kendoButton [toggleable]="true" [selected]="" routerLink="/" (click)="dashboardChange(DashboardOptions.dashboard)" title="Dashboard: Show Metrics based on the persona's or organization selected.">-->
                        <!--<fa-icon [icon]="faGripHorizontal"></fa-icon> Dashboard-->
                    <!--</button>-->
                    <!--<button kendoButton [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.customer" routerLink="/customerDashboard" (click)="dashboardChange(DashboardOptions.customer)" title="Customer: Show metrics for all instruments for a single customer assigned to a persona.">-->
                        <!--<fa-icon [icon]="faClinicMedical"></fa-icon> Customer-->
                    <!--</button>-->
                    <!--<button kendoButton [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.instruments" routerLink="/instrumentsDashboard" (click)="dashboardChange(DashboardOptions.instruments)" title="Instruments: Show metrics based on the persona's CRL or the organization selected. (This tab allows filter down to a particular Instrument type or Instrument serial number).">-->
                        <!--<fa-icon [icon]="faLaptopMedical"></fa-icon> Instruments-->
                    <!--</button>-->
                    <!--<button kendoButton *ngIf="isAmbTabVisible" [toggleable]="true" [selected]="navFilterSelection.selectedDashboard===DashboardOptions.ambassador" routerLink="/ambassadorDashboard" (click)="dashboardChange(DashboardOptions.ambassador)" title="Ambassador: Show metrics related to Sales.">-->
                        <!--<fa-icon [icon]="faUserTie"></fa-icon> Ambassador-->
                    <!--</button>-->
                <!--</kendo-buttongroup>-->
            </div>
            <div>
                <!--title="Persona: Filter metrics to the user or manager selected or set the CRL based on Location."-->
                <app-persona-selector [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"></app-persona-selector>
            </div>
            <div *ngIf="this.lastSelectedDashboardTabInfo === DashboardOptions.customer || this.lastSelectedDashboardTabInfo === DashboardOptions.instruments">
                <!--title="Second Level Persona Selection: Drill Down to a Specific Customer or Instrument (Type/Serial#)."-->
                <app-persona-selector2 [anchorAlign]="{ horizontal: 'right', vertical: 'bottom' }"></app-persona-selector2>
            </div>
            <div>
                <app-parameter-switches [displayTitles]="true"></app-parameter-switches>
            </div>
            <div *ngIf="this.lastSelectedDashboardTabInfo !== DashboardOptions.ambassador">
                <app-search-popup [displayTitles]="true"></app-search-popup>
            </div>
            <app-bookmarks *ngIf="navFilterSelection.bookmarksFlag" [displayTitles]="true"></app-bookmarks>
            <app-preferences *ngIf="navFilterSelection.preferences" [displayTitles]="true"></app-preferences>
        </span>
    </div>
    <div class="overlay" (click)="closeSidebar()" [ngClass]="status ? 'active' : 'inactive'"></div>
</div>