import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { ApiService } from 'src/app/api/api.service';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';
import { RequestState } from 'src/app/api/request-handler';
import { GridComponent } from '../../grid.component';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';


@Component({
  selector: 'app-sales-details',
  templateUrl: './sales-details.component.html',
  styleUrls: ['./sales-details.component.scss']
})
export class SalesDetailsComponent extends GridComponent implements OnInit {

  stylePages = {
    '/sales/details': {
      pageTitle: 'Sales Details',
      apiRequestHandler: '/metrics/sales/details',
      backPageLabel: 'Sales Summary (USD)',
      backPageLink: '/sales/summary',
      dataArrayKey: 'details',
      table: {
        exportFileName: 'ambassador_sales_customer_details.xlsx',
        dataCols: [
            {
                title: 'Order #',
                key: 'orderno',
                customStyle:  { 'text-align': 'right' },
                width: 80
            },
            {
                title: 'Order Date',
                key: 'orderdate',
                width: 60
            },
            {
                title: 'Quantity',
                key: 'orderqty',
                customStyle:  { 'text-align': 'right' },
                width: 60
            },
            {
                title: 'Ship Date',
                key: 'ordershipdate',
                width: 60
            },
            {
                title: 'Amount',
                key: 'orderamt',
                customStyle:  { 'text-align': 'right' },
                width: 60
            }
        ]
      }
    },
    '/rcs/details': {
      pageTitle: 'Reagent/Capital/Service (RCS) Details',
      apiRequestHandler: '/metrics/rcs/details',
      backPageLabel: 'Back',
      backPageLink: '/rcs/summary',
      dataArrayKey: 'details',
      table: {
        exportFileName: 'ambassador_rcs_customer_details.xlsx',
        dataCols: [
            {
                title: 'Order #',
                key: 'orderno',
                customStyle:  { 'text-align': 'right' },
                width: 80
            },
            {
                title: 'Order Date',
                key: 'orderdate',
                width: 60
            },
            {
                title: 'Quantity',
                key: 'orderqty',
                customStyle:  { 'text-align': 'right' },
                width: 60
            },
            {
                title: 'Ship Date',
                key: 'ordershipdate',
                width: 60
            },
            {
                title: 'Amount',
                key: 'orderamt',
                customStyle:  { 'text-align': 'right' },
                width: 60
            }
        ]
      }
    }    
  };
  config = null;
  public state = RequestState.Inactive;
  apiDataRecord = null;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    protected refreshHandler: RefreshHandlerService
  ) { 
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      this.route.paramMap.subscribe(params => {        
        
        let customerid = params.get('customerid');
        let customername = params.get('customername');
        let ln = params.get('ln');
        let desc = params.get('desc');

        this.apiDataRecord = {};

        this.apiDataRecord['customername'] = customername;
        this.apiDataRecord['ln'] = ln;
        this.apiDataRecord['desc'] = desc;

        // set the dashboard
        this.navbarBuilderService.setNavbarChoice('metric-detail');
        this.navbarBuilderService.setNavFilterSelection({
          preferences: false,
          personaSelector: false,
          dashboardSelector: false,
          search: false,
          toolSelection: ToolSelectionOptions.mycrl,
          legacyCustomersFlag: true,
          myVsAllInstrumentsFlag: true,
          customerWTrackSystemsFlag: true,
          flagshipProductLinesFlag: true,
          bookmarksFlag: true,
          navigateBackwards: true,
          navigateBackwardsLabel: this.config.backPageLabel,
          navigateBackwardsLink: this.config.backPageLink,
          viewTitle: true,
          viewTitleText: this.config.pageTitle,
          viewSubtitle: false,
          viewSubtitleText: '',
          filtered: false
        });

        const requestHandler = this.apiService.get(this.config.apiRequestHandler);
        requestHandler.onStateChange((state) => {
          this.state = state;
        });
        requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, customerdetail_id: customerid, detail_ln: ln });
        this.refreshHandler.onRefreshEvent(
          (index) => {
            requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, customerdetail_id: customerid, detail_ln: ln });
          },
          requestHandler.getRelativeParams(this.config.apiRequestHandler)
        );
  
        // no need to send request here as chart tile has already done so
        requestHandler.onStateChange((state) => {
          if (state === RequestState.Complete) {
            const response = requestHandler.getResponse();
            const apiData = JSON.parse(response.body);
  
            this.dataCols = [];
            for (let i = 0; i < this.config.table.dataCols.length; i++) {
              this.dataCols.push({
                ref: this.config.table.dataCols[i].key,
                description: this.config.table.dataCols[i].title,
                width: this.config.table.dataCols[i].width,
                hidden: this.config.table.dataCols[i].hidden,
                customStyle: this.config.table.dataCols[i].customStyle
              });
            }
            // format dataRows for kendo component
            this.dataRows = [];
            for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
              this.dataRows.push(apiData[this.config.dataArrayKey][r]);
            }
            this.updateGrid();
          }
        });
      });  
    }
  }

}
