import { Component, OnInit, Input } from '@angular/core';
import {BrParamsService} from '../br-params.service';

@Component({
  selector: 'app-filter-presentation-header',
  templateUrl: './filter-presentation-header.component.html',
  styleUrls: ['./filter-presentation-header.component.scss']
})
export class FilterPresentationHeaderComponent implements OnInit {
  presentedName = '';
  @Input() viewType = 'details';
  @Input() rangeLiteral = 'History Range';
  @Input() includePeriodRange = true;
  @Input() thumbnailMonths = false;
  singlePeriodHistory = false;
  historyRange = 4;
  detailperiod;
  startDate;
  endDate;
  minDaysInMonthToIncludePartialMonth = 10;

  constructor(
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    this.presentedName = this.brParamsService.get('presentedName');
    this.historyRange = this.brParamsService.get('br_periods_to_show');
    this.detailperiod = this.brParamsService.get('detailperiod');
    if (this.detailperiod === null) {
      this.singlePeriodHistory = false;
    } else {
      this.singlePeriodHistory = true;
    }

    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'presentedName') {
        this.presentedName = this.brParamsService.get('presentedName');
      }
      if ((this.viewType === 'details' && brParamKey === 'br_history_range_detail') && !this.thumbnailMonths) {
        this.historyRange = this.brParamsService.get('br_history_range_detail');
        this.startDate = this.getStartDate();
        this.endDate = this.getEndDate();
      }
      if ((this.viewType === 'dashboard' && brParamKey === 'br_history_range_dashboard') || this.thumbnailMonths) {
        this.historyRange = this.brParamsService.get('br_history_range_dashboard');
        this.startDate = this.getStartDate();
        this.endDate = this.getEndDate();
      }
    });
    if (this.viewType === 'dashboard' || this.thumbnailMonths) {
      this.historyRange = this.brParamsService.get('br_history_range_dashboard');
    }
    if (this.viewType === 'details' && !this.thumbnailMonths) {
      this.historyRange = this.brParamsService.get('br_history_range_detail');
    }
    // console.log('historyRange', this.historyRange);
    this.startDate = this.getStartDate();
    this.endDate = this.getEndDate();
  }

  getStartDate() {
    const d = new Date();
    d.setMonth(d.getMonth() - this.historyRange);
    return d.getFullYear() + '-' + (('' + (d.getMonth() + 1)).padStart(2, '0'));
    // return d.toLocaleString('default', { month: 'long' });
  }

  conditionallyIncludePartialMonth() {
    const d = new Date();
    return (d.getUTCDate() >= this.minDaysInMonthToIncludePartialMonth);
  }

  addPartialMonth() {
    if (this.conditionallyIncludePartialMonth()) {
      return 1;
    }
    return 0;
  }

  getEndDate() {
    const d = new Date();
    d.setMonth(d.getMonth());

    return d.getFullYear() + '-' + (('' + (d.getMonth() + this.addPartialMonth())).padStart(2, '0'));
    // return d.toLocaleString('default', { month: 'long' });
  }
}
