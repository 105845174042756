import { Injectable } from '@angular/core';
import {ParamSource, ParamMapping} from '../configuration/param-mapping';
import {PersonaTypes} from '../configuration/persona-types';

@Injectable({
  providedIn: 'root'
})
export class RefreshHandlerService {
  private keyIndex = 0;
  private apiRefreshCallbacks = {};
  private apiChangeTriggers = {};
  private apiRoutesForDebugging = {};
  private refreshTreeConfig = {
    GEO: 'sgmOrgname'
  };

  onRefreshEvent(callback, changeTriggers = [], route = null) {
    const newIndex = 'rh_' + (++this.keyIndex);
    this.apiRefreshCallbacks[newIndex] = callback;
    this.apiChangeTriggers[newIndex] = changeTriggers;
    this.apiRoutesForDebugging[newIndex] = route;

    return newIndex;
  }

  cancelRefreshEvent(keyIndex) {
    delete this.apiRefreshCallbacks[keyIndex];
    delete this.apiChangeTriggers[keyIndex];
    delete this.apiRoutesForDebugging[keyIndex];
    return this;
  }

  /**
   * @param {any[]} triggers - array of param mapping source keys
   * @returns {this}
   */
  triggerCallbacks(triggers = []) {
    for (const trigger of triggers) {
      if (trigger in this.refreshTreeConfig) {
        this.triggerCallbacks([this.refreshTreeConfig[trigger]]);
      }
    }
    for (const key in this.apiRefreshCallbacks) {
      if (this.apiRefreshCallbacks.hasOwnProperty(key)) {
        const callback = this.apiRefreshCallbacks[key];
        // console.log('callbacks', key, callback, this.apiChangeTriggers[key], this.apiRoutesForDebugging[key]);
        if (this.checkIfChangeTriggerApplies(key, triggers)) {
          // if one of the triggers is a param mapping of type ParamSource.RefreshIndicator then pass special signal to callback
          // so that the request handler can know to force update even tho params have not changed
          // let indicator = false;
          // for (const trigger of triggers) {
          //   if (trigger in ParamMapping && ParamMapping[trigger].source === ParamSource.RefreshIndicator) {
          //     indicator = true;
          //   }
          // }
          callback(key);
        }
      }
    }
    return this;
  }

  getCallbacks() {
    return this.apiRefreshCallbacks;
  }

  getParamMappingKeysForSourceKey(sourceKey) {
    const paramMappingKeys = [];
    for (const paramMappingKey in ParamMapping) {
      if (ParamMapping.hasOwnProperty(paramMappingKey) && ParamMapping[paramMappingKey].sourceKey === sourceKey) {
        paramMappingKeys.push(paramMappingKey);
      }
    }
    return paramMappingKeys;
  }

  protected checkIfChangeTriggerApplies(key, triggers) {
    // console.log('refreshTrigger', triggers, this.apiChangeTriggers[key]);
    if (triggers.length === 0) {
      return true;
    }
    for (const changeTriggerKey in this.apiChangeTriggers[key]) {
      if (changeTriggerKey in this.apiChangeTriggers[key]) {
        const changeTrigger = this.apiChangeTriggers[key][changeTriggerKey];
        if (triggers.includes(changeTrigger)) {
          return true;
        }
      }
    }

    return false;
  }
}
