<div class="dashboard">
    <div class="container-fluid">
        <div class="row">
            <div class="col col-12 col-xs-12 col-sm-6 col-md-4 col-xl-3" *ngFor="let tileReference of arrangement">
                 <!--<a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">-->
                    <app-tile-insert [tileReference]="tileReference"></app-tile-insert>
                 <!--</a>-->
            </div>
        </div>
    </div>
</div>