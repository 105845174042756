import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedManagerService {
  managerUpi;
  manager511;
  managerName;

  constructor() { }

  setManager(managerUpi, manager511, managerName) {
    this.managerUpi = managerUpi;
    this.manager511 = manager511;
    this.managerName = managerName;
  }

  getManager511() {
    return this.manager511;
  }

  getManagerUpi() {
    return this.managerUpi;
  }

  getManagerName() {
    return this.managerName;
  }
}
