export const ExpandedViewPages = {
  '/businessReview/total_inbound_calls': {
    apiSource: '/qbr/kpi/total_inbound_calls/thumbnail',
    detailLink: '/businessReview/total_inbound_calls/list_view',
    componentName: 'BrStackedBarLineChartComponent',
    preferences: {
      title: 'Total Inbound Calls'
    }
  }
};
