import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { ServiceContractStylePages } from '../service-contract-pages';
import { ApiService } from 'src/app/api/api.service';
import { NavbarBuilderService } from 'src/app/navigation/navbar-builder.service';
import { ToolSelectionOptions } from 'src/app/configuration/nav-filter-selection';
import { RequestState } from 'src/app/api/request-handler';
import { GridComponent } from '../../grid.component';
import { RefreshHandlerService } from 'src/app/api/refresh-handler.service';


@Component({
  selector: 'app-service-contracts-level4',
  templateUrl: './service-contracts-level4.component.html',
  styleUrls: ['./service-contracts-level4.component.scss']
})
export class ServiceContractsLevel4Component extends GridComponent implements OnInit {

  stylePages = ServiceContractStylePages;
  config = null;
  apiDataRecord = null;
  entitlementdesc = null;
  public state = RequestState.Inactive;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    protected refreshHandler: RefreshHandlerService
  ) { 
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {

      this.route.paramMap.subscribe(params => {
        
        let customerNumber = params.get('customernumber');
        let serialNumber = params.get('serialnumber');
        let contractnumber = params.get('contractnumber');
        this.entitlementdesc = params.get('entitlementdesc');

        
        let backPageLink = this.config.backPageLink;

        backPageLink += "/" + customerNumber + "/" + serialNumber + "/" + contractnumber;

        // set the dashboard
        this.navbarBuilderService.setNavbarChoice('metric-detail');
        this.navbarBuilderService.setNavFilterSelection({
          preferences: false,
          personaSelector: false,
          dashboardSelector: false,
          search: false,
          toolSelection: ToolSelectionOptions.mycrl,
          legacyCustomersFlag: true,
          myVsAllInstrumentsFlag: true,
          customerWTrackSystemsFlag: true,
          flagshipProductLinesFlag: true,
          bookmarksFlag: true,
          navigateBackwards: true,
          navigateBackwardsLabel: this.config.backPageLabel,
          navigateBackwardsLink: backPageLink,
          viewTitle: true,
          viewTitleText: this.config.pageTitle,
          viewSubtitle: false,
          viewSubtitleText: '',
          filtered: false
        });

        const parentPageRequestHandler = this.apiService.get(this.config.parentPageRequestHandler);
        parentPageRequestHandler.onStateChange((state) => {
          this.state = state;
        });
        parentPageRequestHandler.sendRequest(this.config.parentPageRequestHandler, { months: 12});
        this.refreshHandler.onRefreshEvent(
          (index) => {
            parentPageRequestHandler.sendRequest(this.config.parentPageRequestHandler, { months: 12});
          },
          parentPageRequestHandler.getRelativeParams(this.config.parentPageRequestHandler)
        );
  
        // no need to send request here as chart tile has already done so
        parentPageRequestHandler.onStateChange((state) => {
          if (state === RequestState.Complete) {
            const response = parentPageRequestHandler.getResponse();
            const apiData = JSON.parse(response.body);

            // find the correct apiData record for the routing params
            for (let r = 0; r < apiData['details'].length; r++) {
              let currentRec = apiData['details'][r];
              if (currentRec['customernumber'] == customerNumber 
                  && currentRec['instrumentsrno'] == serialNumber 
                  && currentRec['contractno'] == contractnumber) {
                this.apiDataRecord = currentRec;
                break;
              }
            }
          }
        });         
        if (this.apiDataRecord != null) {
          let entitlementid = params.get('entitlementid');

          const requestHandler = this.apiService.get(this.config.apiRequestHandler);
          requestHandler.onStateChange((state) => {
            this.state = state;
          });
          requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, servicecontract_entitlementid: entitlementid});
          this.refreshHandler.onRefreshEvent(
            (index) => {
              requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12, servicecontract_entitlementid: entitlementid});
            },
            requestHandler.getRelativeParams(this.config.apiRequestHandler)
          );
    
          // no need to send request here as chart tile has already done so
          requestHandler.onStateChange((state) => {
            if (state === RequestState.Complete) {
              const response = requestHandler.getResponse();
              const apiData = JSON.parse(response.body);
    
              this.dataCols = [];
              for (let i = 0; i < this.config.table.dataCols.length; i++) {
                this.dataCols.push({
                  ref: this.config.table.dataCols[i].key,
                  description: this.config.table.dataCols[i].title,
                  width: this.config.table.dataCols[i].width,
                  hidden: this.config.table.dataCols[i].hidden,
                  customStyle: this.config.table.dataCols[i].customStyle
                });
              }
             // format dataRows for kendo component
             this.dataRows = [];
             for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
               this.dataRows.push(apiData[this.config.dataArrayKey][r]);
             }
             this.updateGrid();
            }
          });
        }
     });
    }  
  }
}