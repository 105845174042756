import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {Location} from '@angular/common';
import {ApiService} from '../../api/api.service';
import {RequestState} from '../../api/request-handler';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import { SurveyCommentPages } from './survey-comment-pages';


@Component({
  selector: 'app-survey-comment-details',
  templateUrl: './survey-comment-details.component.html',
  styleUrls: ['./survey-comment-details.component.scss']
})
export class SurveyCommentDetailsComponent implements OnInit {

  stylePages = SurveyCommentPages;
  config = null;
  apiDataRecord = null;
  dataRow = null;
  public state = RequestState.Inactive;

  constructor(
    private location: Location,
    protected apiService: ApiService,
    private navbarBuilderService: NavbarBuilderService,
    private route: ActivatedRoute,
    private refreshHandler: RefreshHandlerService
  ) { }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    Object.keys(this.stylePages).forEach(key => {
      if (currentRouterLocation.indexOf(key) > -1) {
        this.config = this.stylePages[key];
        return;
      }
    });
    if (this.config != null) {
      // set the dashboard
      this.navbarBuilderService.setNavbarChoice('metric-detail');
      this.navbarBuilderService.setNavFilterSelection({
        preferences: false,
        personaSelector: false,
        dashboardSelector: false,
        search: false,
        toolSelection: ToolSelectionOptions.mycrl,
        legacyCustomersFlag: true,
        myVsAllInstrumentsFlag: true,
        customerWTrackSystemsFlag: true,
        flagshipProductLinesFlag: true,
        bookmarksFlag: true,
        navigateBackwards: true,
        navigateBackwardsLabel: this.config.backPageLabel,
        navigateBackwardsLink: this.config.backPageLink,
        viewTitle: true,
        viewTitleText: this.config.pageTitle,
        viewSubtitle: false,
        viewSubtitleText: '',
        filtered: false
      });

      this.route.paramMap.subscribe(params => {
        let customerNumber = params.get('customernumber');
        let country = params.get('country');
        let interviewDate = params.get('interviewdate');

        const requestHandler = this.apiService.get(this.config.apiRequestHandler);
        requestHandler.onStateChange((state) => {
          this.state = state;
        });
        requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
        this.refreshHandler.onRefreshEvent(
          (index) => {
            requestHandler.sendRequest(this.config.apiRequestHandler, { months: 12});
          },
          requestHandler.getRelativeParams(this.config.apiRequestHandler)
        );

        // no need to send request here as chart tile has already done so
        requestHandler.onStateChange((state) => {
          if (state === RequestState.Complete) {
            const response = requestHandler.getResponse();
            const apiData = JSON.parse(response.body);

            // find the correct apiData record for the routing params
            for (let r = 0; r < apiData[this.config.dataArrayKey].length; r++) {
              let currentRec = apiData[this.config.dataArrayKey][r];
              if (currentRec['customernumber'] == customerNumber && currentRec['country'] == country && currentRec['interviewdate'] == interviewDate) {
                this.apiDataRecord = currentRec;
                break;
              }
            } 
            if (this.apiDataRecord != null) {
              // console.log(this.apiDataRecord);
              this.dataRow = {};
              for (let i = 0; i < this.config.table.dataCols.length; i++) {
                this.dataRow[this.config.table.dataCols[i].key] = this.apiDataRecord[this.config.table.dataCols[i].key];
              }
              if (this.config.dataArraySplitColKey !== undefined) {
                let arrayObjToSplit = this.apiDataRecord[this.config.dataArraySplitColKey];              
                for (let i = 0; i < this.config.table.dataArraySplitCols.length; i++) {                
                  for (let j = 0; j < arrayObjToSplit.length; j++) {
                    if (this.config.table.dataArraySplitCols[i].title === arrayObjToSplit[j][this.config.table.dataArraySplitCols[i].arrayMappingKey]) {
                      this.dataRow[this.config.table.dataArraySplitCols[i].key] = arrayObjToSplit[j][this.config.table.dataArraySplitCols[i].arrayMappingValue];
                      break;
                    }                  
                  }
                }
              }
              if (this.config.table.drillDownCols !== undefined) {
                for (let i = 0; i < this.config.table.drillDownCols.length; i++) {                
                  this.dataRow[this.config.table.drillDownCols[i].key] = this.apiDataRecord[this.config.table.drillDownCols[i].key];
                }
              }              
            }            
            // console.log(this.dataRow);
          }             
        });
      });
    }
  }

}
