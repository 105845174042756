<a class="nav-link">
    <a #anchor (click)="onToggle()" class="persona-button relativity-fix">
        <div class="menuText1">{{menuText1}}</div>
        <div class="menuText2">{{menuText2}}</div>
    </a>
    <kendo-popup #popup [anchor]="anchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" (anchorViewportLeave)="menuVisible = false" *ngIf="menuVisible">
        <div class='content'>
            <app-autoselect-list
                    [state]="apiState"
                    [data]="data"
                    [keyField]="'key'"
                    [displayField]="'label'"
                    [selectedOption]="selectedItem"
                    (itemSelect)="setSelectedItem($event)"
                    [sort]="false">
            </app-autoselect-list>
        </div>
    </kendo-popup>
</a>
