import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import {ApiService} from '../../../api/api.service';
import {RequestState} from '../../../api/request-handler';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {BrParamsService} from '../../br-params.service';

@Component({
  selector: 'app-br-business-segment-selector',
  templateUrl: './br-business-segment-selector.component.html',
  styleUrls: ['./br-business-segment-selector.component.scss']
})
export class BrBusinessSegmentSelectorComponent implements OnInit, OnDestroy {
  RequestStateComplete = RequestState.Complete; // temporary until api call is implemented

  protected requestHandler;
  protected apiSource = '/qbr/app_config/cust_bs/cust_bs';
  protected refreshHandleKey;
  public state = RequestState.Inactive;

  /*data = [
    {label: 'All Business Segments', key: 1},
    {label: 'Core Lab', key: 1},
    {label: 'Transfusion', key: 2},
    {label: 'Unassigned', key: 3},
  ];*/
  selectedItem;
  apiState;
  data = [];

  constructor(
    private authService: AuthenticationService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    this.updateSelections();

    this.requestHandler = this.apiService.get(this.apiSource);
    this.requestHandler.sendRequest(this.apiSource);
    this.requestHandler.onStateChange((state) => {
      this.apiState = state;
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        const buildData = [];
        for (const item of apiData.items) {
          buildData.push({
            label: item.display_name,
            key: item.id
          });
        }
        this.data = buildData;
        const presistedValue = this.brParamsService.get('cust_bs');
        this.selectedItem = presistedValue;
      }
    });
    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'cust_bs') {
        this.selectedItem = this.brParamsService.get('cust_bs');
      }
    });
    // this.selectedItem =
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }

  updateSelections() {

    // this.menuText1 = this.selectedType;
    // this.menuText2 = this.selectedLabel;

    this.cdRef.detectChanges();
  }

  getLabel(key) {
    if (this.data) {
      for (const item of this.data) {
        if (item.key === key) {
          return item.label;
        }
      }
    }
    return '';
  }

  setSelectedItem(selectedItem) {
    this.selectedItem = selectedItem;
    const label = this.getLabel(selectedItem);
    this.brParamsService.set('cust_bs', this.selectedItem);
    this.brParamsService.set('cust_bs_description', label);
  }
}
