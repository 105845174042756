export const environment = {
  proxyServerUrl: 'https://oneview-q.abbott.com/proxy',
  gsrUrl: 'https://gsr.abbott.com',
  apiProxyRouteKey: 'gsmm-p',
  production: true,
  authRedirectUrl: 'https://oneview-q.abbott.com',
  clientId: 'a2e0a7ea-5d97-4b2e-b404-5d0756dcc466',
  // clientId: '9a6492f3-a831-4da0-ba8a-d6a8415a07e4',
  tenantId: '5b268d57-2a6f-4e04-b0de-6938583d5ebc'
};
// ng build --configuration=qa
// ng build --configuration=qa --sourceMap=true