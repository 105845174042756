import {PersonaTypes} from './persona-types';

export enum ParamSource {
  AuthUserInfo,
  FilterChoices,
  ApiMapping,
  ApiCallInstance,
  DefaultParams,
  Persona,
  Preferences,
  DetailChoices,
  ParamLogic,
  BrParams
  // RefreshIndicator - was thinking about hacking so non api param could force update
  // example preferences/localCurrency is not passed as param to metric calls, thought it was picked up automatically by api
  // turns out it is updated from the app side
}

export const ParamMapping = {
  // dashboardRefresh: { // used to signal refreshes nothing passed to api and no content source needed
  //   source: ParamSource.RefreshIndicator
  // },
  appid: {
    source: ParamSource.DefaultParams,
    sourceKey: 'appid',
    apiParamName: 'appid'
  },
  appversion: {
    source: ParamSource.DefaultParams,
    sourceKey: 'appversion',
    apiParamName: 'appversion'
  },
  loggedinuserisambmanager: {
    source: ParamSource.AuthUserInfo,
    sourceKey: 'isAMBManager',
    apiParamName: 'loggedinuserisambmanager'
  },
  loggedinuserisambmanager_false: {
    source: ParamSource.DefaultParams,
    sourceKey: 'false',
    apiParamName: 'loggedinuserisambmanager'
  },
  issuperuser: {
    source: ParamSource.AuthUserInfo,
    sourceKey: 'isSuperUser',
    apiParamName: 'isSuperUser'
  },
  issuperuser_false: {
    source: ParamSource.DefaultParams,
    sourceKey: 'false',
    apiParamName: 'issuperuser'
  },
  isSuperUser_false: {
    source: ParamSource.DefaultParams,
    sourceKey: 'false',
    apiParamName: 'isSuperUser'
  },
  issuperuser_true: {
    source: ParamSource.DefaultParams,
    sourceKey: 'true',
    apiParamName: 'issuperuser'
  },
  abbottid: { // for use on service metric calls
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.TSS,
    apiParamName: 'abbottid'
  },
  abbottid_amb: { // for use on amb metric calls
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.AbbottIdAmb,
    apiParamName: 'abbottid'
  },
  abbottid_chandsn: { // for testing
    source: ParamSource.ApiMapping,
    sourceKey: 'abbottid_chandsn',
    apiParamName: 'abbottid'
  },
  abbottid_auth: { // for use on persona menu calls
    source: ParamSource.AuthUserInfo,
    sourceKey: 'abbottid',
    apiParamName: 'abbottid'
  },
  // loginid_auth: { // for use on persona menu calls
  //   source: ParamSource.AuthUserInfo,
  //   sourceKey: 'abbottid',
  //   apiParamName: 'loginid'
  // },
  managerid: {
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.Manager,
    apiParamName: 'managerid'
  },
  manageridHack: {
    source: ParamSource.ParamLogic,
    sourceKey: 'managerHackForCustomerListAOH',
    apiParamName: 'managerid'
  },
  managerid_amb: {
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.ManagerAmb,
    apiParamName: 'managerid'
  },
  managerid_chandsn: { // for testing
    source: ParamSource.ApiMapping,
    sourceKey: 'managerid_chandsn',
    apiParamName: 'managerid'
  },
  managerid_auth: {
    source: ParamSource.AuthUserInfo,
    sourceKey: 'upi',
    apiParamName: 'managerid'
  },
  managerid_empty: {
    source: ParamSource.DefaultParams,
    sourceKey: 'emptystring',
    apiParamName: 'managerid'
  },
  orgname: {
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.GEO,
    apiParamName: 'orgname'
  },
  orgname_empty: {
    source: ParamSource.DefaultParams,
    sourceKey: 'emptystring',
    apiParamName: 'orgname'
  },
  sgm_orgname: {
    source: ParamSource.ParamLogic,
    sourceKey: 'sgmOrgname',
    apiParamName: 'orgname'
  },
  customersearch: {
    source: ParamSource.FilterChoices,
    sourceKey: 'searchQuery',
    apiParamName: 'customersearch',
  },
  accountsearch: {
    source: ParamSource.FilterChoices,
    sourceKey: 'searchQueryCuaAccount',
    apiParamName: 'acctIdNameSearchStr',
  },
  cuacustomersearch: {
    source: ParamSource.FilterChoices,
    sourceKey: 'searchQueryCuaCustomer',
    apiParamName: 'customerNumNameSearchStr',
  },
  cua_search_record_limit: {
    source: ParamSource.DefaultParams,
    sourceKey: 'cuasearchrecordlimit',
    apiParamName: 'recordLimit',
  },
  productlinesearch: {
    source: ParamSource.FilterChoices,
    sourceKey: 'searchQuery',
    apiParamName: 'productlinesearch',
  },
  search_persona_menu_customerid: {
    source: ParamSource.FilterChoices,
    sourceKey: 'searchCustomerId',
    apiParamName: 'customerid',
    default: ''
  },
  search_persona_menu_productlineid: {
    source: ParamSource.FilterChoices,
    sourceKey: 'searchProductLineId',
    apiParamName: 'productlineid',
    default: ''
  },
  customerid: {
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.Customer,
    apiParamName: 'customerid',
    default: 'all'
  },
  customerid_all: {
    source: ParamSource.DefaultParams,
    sourceKey: 'all',
    apiParamName: 'customerid'
  },
  customerdetail_id: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'customerdetail_id',
    apiParamName: 'customerid'
  },
  servicecontract_entitlementid: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'servicecontract_entitlementid',
    apiParamName: 'entitlementid'
  },
  ordernumber: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'ordernumber',
    apiParamName: 'ordernumber'
  },
  productlineid: {
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.ProductLine,
    apiParamName: 'productlineid',
    default: 'all'
  },
  productlineid_all: {
    source: ParamSource.DefaultParams,
    sourceKey: 'all',
    apiParamName: 'productlineid'
  },
  sn: {
    source: ParamSource.ParamLogic,
    sourceKey: 'sn',
    apiParamName: 'sn'
  },
  isAMB: {
    source: ParamSource.AuthUserInfo,
    sourceKey: 'isAMB',
    apiParamName: 'isamb',
    default: 'false'
  },
  // isAMBCall: {
  //   source: ParamSource.ApiCallInstance,
  //   sourceKey: 'isAMBCall',
  //   apiParamName: 'isAMB'
  // },
  // personaCall: {
  //   source: ParamSource.ApiCallInstance,
  //   sourceKey: 'personaCall',
  //   apiParamName: 'persona'
  // },
  // personanameCall: {
  //   source: ParamSource.ApiCallInstance,
  //   sourceKey: 'personanameCall',
  //   apiParamName: 'personaname'
  // },
  // personatypeCall: {
  //   source: ParamSource.ApiCallInstance,
  //   sourceKey: 'personatypeCall',
  //   apiParamName: 'personatype'
  // },
  isAMBfalse: {
    source: ParamSource.DefaultParams,
    sourceKey: 'false',
    apiParamName: 'isamb'
  },
  flagshipfalse: {
    source: ParamSource.DefaultParams,
    sourceKey: 'false',
    apiParamName: 'flagship'
  },
  flagship: {
    source: ParamSource.FilterChoices,
    sourceKey: 'flagshipToggle',
    apiParamName: 'flagship'
  },
  istrackfalse: {
    source: ParamSource.DefaultParams,
    sourceKey: 'false',
    apiParamName: 'istrack'
  },
  openstar: {
    source: ParamSource.FilterChoices, // not sure yet
    sourceKey: 'instrumentToggle',
    apiParamName: 'openstar'
  },
  openstartrue: {
    source: ParamSource.DefaultParams,
    sourceKey: 'true',
    apiParamName: 'openstar'
  },
  istrack: {
    source: ParamSource.FilterChoices,
    sourceKey: 'trackToggle',
    apiParamName: 'istrack'
  },
  metricid: {
    source: ParamSource.ApiMapping,
    sourceKey: 'metric_id',
    apiParamName: 'metricid'
  },
  months: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'months',
    apiParamName: 'months',
    default: '4'
  },
  months12: {
    source: ParamSource.DefaultParams,
    sourceKey: 'months12',
    apiParamName: 'months'
  },
  months6: {
    source: ParamSource.DefaultParams,
    sourceKey: 'months6',
    apiParamName: 'months'
  },
  months3: {
    source: ParamSource.DefaultParams,
    sourceKey: 'months3',
    apiParamName: 'months'
  },
  culture: {
    source: ParamSource.DefaultParams,
    sourceKey: 'culture',
    apiParamName: 'culture'
  },
  primary: {
    source: ParamSource.Preferences,
    sourceKey: 'primary',
    apiParamName: 'primary'
  },
  secondary: {
    source: ParamSource.Preferences,
    sourceKey: 'secondary',
    apiParamName: 'secondary'
  },
  tertiary: {
    source: ParamSource.Preferences,
    sourceKey: 'tertiary',
    apiParamName: 'tertiary'
  },
  isreagent: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'isreagent',
    apiParamName: 'isreagent'
  },
  isfieldservicecontract: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'isfieldservicecontract',
    apiParamName: 'isfieldservicecontract'
  },
  isother: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'isother',
    apiParamName: 'isother'
  },
  isservice: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'isservice',
    apiParamName: 'isservice'
  },
  iscapital: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'iscapital',
    apiParamName: 'iscapital'
  },
  accountid: {
    source: ParamSource.Persona,
    sourceKey: PersonaTypes.Account,
    apiParamName: 'accountid',
    default: 'all'
  },
  accountid_all: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'all',
    apiParamName: 'accountid'
  },
  diseasestateid: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'diseasestateid',
    apiParamName: 'diseasestateid'
  },
  assaysid: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'assaysid',
    apiParamName: 'assaysid'
  },
  ln: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'ln',
    apiParamName: 'LN'
  },
  detail_ln: {
    source: ParamSource.ApiCallInstance, // not sure yet
    sourceKey: 'detail_ln',
    apiParamName: 'LN'
  },
  band: {
    source: ParamSource.ApiCallInstance, // not sure yet
    sourceKey: 'band',
    apiParamName: 'band'
  },
  // openstar: {
  //   source: ParamSource.DefaultParams, // not sure yet
  //   sourceKey: 'openstar',
  //   apiParamName: 'openstar'
  // },
  isamb: {
    source: ParamSource.DefaultParams, // not sure yet
    sourceKey: 'isamb',
    apiParamName: 'isamb'
  },
  abbottid_amb_filters: {
    source: ParamSource.ParamLogic,
    sourceKey: 'abbottid_amb_filters',
    apiParamName: 'abbottid'
  },
  managerid_amb_filters: {
    source: ParamSource.ParamLogic,
    sourceKey: 'managerid_amb_filters',
    apiParamName: 'managerid'
  },
  metricid_call: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'metricid',
    apiParamName: 'metricid'
  },
  detail_customerid: {
    source: ParamSource.ParamLogic,
    sourceKey: 'detailCustomerIdFilter',
    apiParamName: 'customerid',
    default: 'all'
  },
  detail_customerid_accountid: {
    source: ParamSource.ParamLogic,
    sourceKey: 'detailCustomerIdFilter',
    apiParamName: 'accountid',
    default: 'all'
  },
  detail_productlineid: {
    source: ParamSource.ParamLogic,
    sourceKey: 'detailProductLineFilter',
    apiParamName: 'productlineid',
    default: 'all'
  },
  detail_accountid: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailAccountFilter',
    apiParamName: 'accountid',
    default: 'all'
  },
  detail_assayid: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailAssayFilter',
    apiParamName: 'assaysid',
    default: 'all'
  },
  detail_diseasestate: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailDiseaseStateFilter',
    apiParamName: 'diseasestateid',
    default: 'all'
  },
  detail_iscapital: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailIsCapitalFilter',
    apiParamName: 'iscapital',
    default: 'all'
  },
  detail_isservice: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailIsServiceFilter',
    apiParamName: 'isservice',
    default: 'all'
  },
  detail_isreagent: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailIsReagentFilter',
    apiParamName: 'isreagent',
    default: 'all'
  },
  detail_isfieldservicecontract: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailIsFieldServiceContractFilter',
    apiParamName: 'isfieldservicecontract',
    default: 'all'
  },
  detail_isother: {
    source: ParamSource.DetailChoices,
    sourceKey: 'detailIsOtherFilter',
    apiParamName: 'isother',
    default: 'all'
  },
  predictive_detail_sn: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'sn',
    apiParamName: 'sn'
  },
  predictive_detail_customerid: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'customerID',
    apiParamName: 'customerid'
  },
  predictive_detail_orgname: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'countryCode',
    apiParamName: 'orgname'
  },
  product_set_number: {
    source: ParamSource.FilterChoices,
    sourceKey: 'product_set_number',
    apiParamName: 'product_set_number'
  },
  ct_persona_type: {
    source: ParamSource.ParamLogic,
    sourceKey: 'ct_persona_type',
    apiParamName: 'persona_type'
  },
  ct_persona_value: {
    source: ParamSource.ParamLogic,
    sourceKey: 'ct_persona_value',
    apiParamName: 'persona_value'
  },
  cua_persona_type: {
    source: ParamSource.ParamLogic,
    sourceKey: 'ct_persona_type',
    apiParamName: 'personaType'
  },
  cua_persona_value: {
    source: ParamSource.ParamLogic,
    sourceKey: 'ct_persona_value',
    apiParamName: 'personaValue'
  },
  cua_uniqueCustomerKey: {
    source: ParamSource.ParamLogic,
    sourceKey: 'ct_uniqueCustomerKey',
    apiParamName: 'uniqueCustomerKey'
  },
  cua_primary: {
    source: ParamSource.ParamLogic,
    sourceKey: 'cua_primary',
    apiParamName: 'primary'
  },
  cua_secondary: {
    source: ParamSource.ParamLogic,
    sourceKey: 'cua_secondary',
    apiParamName: 'secondary'
  },
  cua_tertiary: {
    source: ParamSource.ParamLogic,
    sourceKey: 'cua_tertiary',
    apiParamName: 'tertiary'
  },
  cua_product_family_set: {
    source: ParamSource.FilterChoices,
    sourceKey: 'product_set_number',
    apiParamName: 'productFamilySetId'
  },
  ct_customer_id: {
    source: ParamSource.ParamLogic,
    sourceKey: PersonaTypes.Customer,
    apiParamName: 'customer_id',
    default: '0'
  },
  ct_primary: {
    source: ParamSource.ParamLogic,
    sourceKey: 'primary',
    apiParamName: 'primary'
  },
  ct_secondary: {
    source: ParamSource.ParamLogic,
    sourceKey: 'secondary',
    apiParamName: 'secondary'
  },
  ct_tertiary: {
    source: ParamSource.ParamLogic,
    sourceKey: 'tertiary',
    apiParamName: 'tertiary'
  },
  startingrow: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'startingrow',
    apiParamName: 'startingrow'
  },
  alerttype: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'alerttype',
    apiParamName: 'alerttype'
  },
  isloadall: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'isloadall',
    apiParamName: 'isloadall'
  },
  subscribe_endpoint: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'endpoint',
    apiParamName: 'endpoint'
  },
  subscribe_expirationTime: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'expirationTime',
    apiParamName: 'expirationTime'
  },
  subscribe_auth: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'auth',
    apiParamName: 'auth'
  },
  subscribe_p256dh: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'p256dh',
    apiParamName: 'p256dh'
  },
  requestTime: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'requestTime',
    apiParamName: 'requestTime'
  },
  customerIdHack: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'customer_id',
    apiParamName: 'customer_id'
  },
  br_tests: {
    source: ParamSource.DefaultParams,
    sourceKey: 'testsearch',
    apiParamName: 'search'
  },
  br_testo: {
    source: ParamSource.DefaultParams,
    sourceKey: 'testorg',
    apiParamName: 'org'
  },
  br_testc: {
    source: ParamSource.DefaultParams,
    sourceKey: 'testcountry',
    apiParamName: 'country'
  },
  br_periods_to_show: {
    source: ParamSource.BrParams,
    sourceKey: 'br_periods_to_show',
    apiParamName: 'periods_to_show'
  },
  br_months: {
    source: ParamSource.BrParams,
    sourceKey: 'br_periods_to_show',
    apiParamName: 'months'
  },
  br_cust_bs: {
    source: ParamSource.BrParams,
    sourceKey: 'cust_bs',
    apiParamName: 'cust_bs'
  },
  br_sg: {
    source: ParamSource.BrParams,
    sourceKey: 'sg',
    apiParamName: 'sg'
  },
  br_org: {
    source: ParamSource.BrParams,
    sourceKey: 'org',
    apiParamName: 'org'
  },
  br_location: {
    source: ParamSource.BrParams,
    sourceKey: 'location',
    apiParamName: 'country'
  },
  br_locallevel: {
    source: ParamSource.BrParams,
    sourceKey: 'locallevel',
    apiParamName: 'locallevel'
  },
  br_uck: {
    source: ParamSource.BrParams,
    sourceKey: 'uck',
    apiParamName: 'uck'
  },
  br_pl: {
    source: ParamSource.BrParams,
    sourceKey: 'pl',
    apiParamName: 'pl'
  },
  br_acct_cust_search: {
    source: ParamSource.BrParams,
    sourceKey: 'acct_customer_query',
    apiParamName: 'search'
  },
  br_template_name: {
    source: ParamSource.BrParams,
    sourceKey: 'br_template_name',
    apiParamName: 'template_name'
  },
  br_template_string: {
    source: ParamSource.BrParams,
    sourceKey: 'br_template_string',
    apiParamName: 'template_string'
  },
  br_template_id: {
    source: ParamSource.BrParams,
    sourceKey: 'br_template_id',
    apiParamName: 'template_id'
  },
  br_presentation_name: {
    source: ParamSource.BrParams,
    sourceKey: 'br_presentation_name',
    apiParamName: 'presentation_name'
  },
  trigger_list_user_presentations_update: {
    source: ParamSource.BrParams,
    sourceKey: 'list_user_presentations_update',
    apiParamName: 'placeholder'
  },
  trigger_list_user_templates_update: {
    source: ParamSource.BrParams,
    sourceKey: 'list_user_templates_update',
    apiParamName: 'placeholder'
  },
  force_call_via_unique_param: {
    source: ParamSource.ApiCallInstance,
    sourceKey: 'force_call_via_unique_param',
    apiParamName: 'force_call_via_unique_param'
  },
  acct_pres_name: {
    source: ParamSource.BrParams,
    sourceKey: 'presentedName',
    apiParamName: 'acct_pres_name'
  },
  br_presentation_settings: {
    source: ParamSource.BrParams,
    sourceKey: 'dump',
    apiParamName: 'br_presentation_settings'
  },
  br_presentation_id: {
    source: ParamSource.BrParams,
    sourceKey: 'presentationId',
    apiParamName: 'presentation_id'
  },
  br_period: {
    source: ParamSource.BrParams,
    sourceKey: 'detailperiod',
    apiParamName: 'period'
  },
  br_show_sql: {
    source: ParamSource.BrParams,
    sourceKey: 'showSql',
    apiParamName: 'show_sql'
  }
};
// @todo:
// sources to add: NavbarBuilderService
// NavbarBuilderService(which filters are currently in scope through nav-filter-selection)
