<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div class="tile-chart-container" *ngIf="isDataFound(apiData)">
            <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                <div *ngIf="chartOptions !== null && chartOptions != undefined">
                    <kendo-chart
                            [seriesDefaults]="chartOptions.seriesDefaults"
                            [series]="chartOptions.series"
                            [valueAxis]="chartOptions.valueAxis"
                            [plotArea]="chartOptions.chartArea">
                    </kendo-chart>
                </div>
            </a>    
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
            No data found
        </div>
    </div>
</app-state-handler>
