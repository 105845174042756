import { RowParserInterface } from '../row-parser-interface';

export class StackValues implements RowParserInterface {
  get(sourceData, params) {
    const rowData = {
      description: params.label
    };

    for (const value of sourceData.values) {
      const key = value.xlabel;
      rowData[key] = value.stacks[params.stackIndex];
    }
    return rowData;
  }
}
