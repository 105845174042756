<div class="container-fluid" *ngIf="config">
    <div class="row">
        <div class="col-12">
            <div style='width: 100%; margin: auto'>
                <div style='width: 60%; padding: 5px; float: left'>
                    Number of records loaded: {{reccount}} out of {{totalrecords}}
                </div>
                <div style='width: 40%; text-align: right; padding: 5px; float: right'>
                    <a style='padding-right: 10px;' href="javascript:void(0);" (click)="loadNextDataSet()">Load More</a>
                    <a href="javascript:void(0);" [attr.disabled]="isDisabled()" (click)="loadAllRecords()">Load All</a>
                </div>
            </div>
            <app-state-handler [requestState]="state" [data]="gridData"></app-state-handler>
            <kendo-grid
                [data]="gridData"
                [pageable]="true"
                [pageSize]="gridState.take"
                [skip]="gridState.skip"
                [filter]="gridState.filter"
                filterable="menu"  
                [sort]="gridState.sort"
                [sortable]="true"
                [ngStyle]="{'max-height': getMaxHeight()}" 
                [resizable]="true"
                (pageChange)="pageChange($event)"
                (sortChange)="sortChange($event)"
                (filterChange)="filterChange($event)">

                <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
                <ng-template kendoGridToolbarTemplate>
                    <kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>
                    <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                    <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
                </ng-template>
                <ng-container *ngFor="let col of dataCols">
                    <kendo-grid-column
                            [field]="col.ref"
                            [title]="col.description"
                            [width]="col.width"
                            [hidden]="col.hidden"
                            [filterable]="(col.filterable === false) ? false: true"
                            [headerClass]="'data-grid-header'"
                            [style]="col.customStyle">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                                <ng-container *ngIf="col.drillDownDetailsLink !== undefined; else two">
                                    <a [routerLink]="getDrillDownDetailsLinkWithParams(col.drillDownDetailsColName, col.drillDownDetailsLink, dataItem)" 
                                        style="font-weight: 500; font-size: 12pt; color: #007bff">{{col.drillDownDetailsLabel}}</a>                                
                                </ng-container>
                                <ng-template #two>
                                    <!-- <span class="whole-cell">{{dataItem[col.ref] | emptyMask}}</span> -->
                                    <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                </ng-template>
                            </ng-template>                        
                    </kendo-grid-column>
                </ng-container>
            </kendo-grid>
        </div>            
    </div>    
</div>