<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData">
        <h3 *ngIf="preferences.showTitle">{{apiData.title}}</h3>
        <div class="tile-chart-container" *ngIf="isDataFound(apiData) && chartOptions !== null && chartOptions !== undefined">
            <a [routerLink]="tileReference.detailLink ? [tileReference.detailLink] : []">
                <kendo-chart
                        [seriesDefaults]="chartOptions.seriesDefaults"
                        [series]="chartOptions.series"
                        [valueAxis]="chartOptions.valueAxis"
                        [categoryAxis]="chartOptions.categoryAxis">
                </kendo-chart>
            </a>
        </div>
        <div *ngIf="!isDataFound(apiData)" class="no-data">
            No data found
        </div>
    </div>
</app-state-handler>