import { Component } from '@angular/core';
import {ToggleSwitchComponent} from '../toggle-switch.component';

@Component({
  selector: 'app-flagship-toggle',
  templateUrl: './flagship-toggle.component.html',
  styleUrls: ['./flagship-toggle.component.scss']
})
export class FlagshipToggleComponent extends ToggleSwitchComponent {
  filterKey() {
    return 'flagshipToggle';
  }
  defaultValue() {
    return false;
  }
}
