import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SelectedTabService {
  selectedDashboard;

  callbacks = [];

  constructor() { }

  selectTab(key) {
    this.selectedDashboard = key;
    this.triggerCallbacks();
    return this;
  }

  getLastSelectedTabInfo() {
    return this.selectedDashboard;
  }

  onTabChange(callback) {
    this.callbacks.push(callback);
    return this;
  }

  triggerCallbacks() {
    for (const callback of this.callbacks) {
      callback();
    }
    return this;
  }
}
