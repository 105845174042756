<span *ngIf="mockData !== null">
<div class="column" *ngFor="let column of mockData.column_list">
    <div class="column-heading">{{column.label}}</div>
    <div class="items">
        <div class="item" [title]="columnItem.display"
             [class.item-selected]="columnItem.selected===Selected.Complete"
             [class.item-selected-partial]="columnItem.selected===Selected.Partial"
             (click)="toggleItemSelect({column: column, columnItem: columnItem})"
             *ngFor="let columnItem of column.items">
            <span class="none"></span>
            <fa-icon [icon]="faSquare" class="square"></fa-icon>
            <fa-icon [icon]="faCheck" class="check"></fa-icon>
            {{columnItem.display}}
        </div>
    </div>
</div>
</span>