<div class="tab-buttons">
    <kendo-buttongroup [selection]="'single'" class="mx-auto geo-button-group">
        <button kendoButton [toggleable]="true"
                [selected]="(selectedTab === 'areas')"
                (selectedChange)="onSelectedChange('areas')">Areas</button>
        <button kendoButton [toggleable]="true"
                [selected]="(selectedTab === 'regions')"
                (selectedChange)="onSelectedChange('regions')">Regions</button>
        <button kendoButton [toggleable]="true"
                [selected]="(selectedTab === 'molecular')"
                (selectedChange)="onSelectedChange('molecular')">Molecular Orgs</button>
        <button kendoButton [toggleable]="true"
                [selected]="(selectedTab === 'service')"
                (selectedChange)="onSelectedChange('service')">Service Orgs</button>
        <button kendoButton [toggleable]="true"
                [selected]="(selectedTab === 'countries')"
                (selectedChange)="onSelectedChange('countries')">Countries</button>
    </kendo-buttongroup>
</div>
<div class="tabs">
    <div class="tab" [class.active-tab]="(selectedTab === 'areas')">
        <app-autoselect-list
                [state]="apiState"
                [data]="areaData"
                [keyField]="'key'"
                [displayField]="'label'"
                [selectedOption]="selectedItem"
                (itemSelect)="setSelectedItem('areas', $event)">
        </app-autoselect-list>
    </div>
    <div class="tab" [class.active-tab]="(selectedTab === 'regions')">
        <app-autoselect-list
                [state]="apiState"
                [data]="regionData"
                [keyField]="'key'"
                [displayField]="'label'"
                [selectedOption]="selectedItem"
                (itemSelect)="setSelectedItem('regions', $event)">
        </app-autoselect-list>
    </div>
    <div class="tab" [class.active-tab]="(selectedTab === 'molecular')">
        <app-autoselect-list
                [state]="apiState"
                [data]="molecularOrgData"
                [keyField]="'key'"
                [displayField]="'label'"
                [selectedOption]="selectedItem"
                (itemSelect)="setSelectedItem('molecular', $event)">
        </app-autoselect-list>
    </div>
    <div class="tab" [class.active-tab]="(selectedTab === 'service')">
        <app-autoselect-list
                [state]="apiState"
                [data]="serviceManagerData"
                [keyField]="'key'"
                [displayField]="'label'"
                [selectedOption]="selectedItem"
                (itemSelect)="setSelectedItem('service', $event)">
        </app-autoselect-list>
    </div>
    <div class="tab" [class.active-tab]="(selectedTab === 'countries')">
        <app-autoselect-list
                [state]="apiState"
                [data]="countryData"
                [keyField]="'key'"
                [displayField]="'label'"
                [selectedOption]="selectedItem"
                (itemSelect)="setSelectedItem('countries', $event)">
        </app-autoselect-list>
    </div>
</div>