<a class="nav-link" *ngIf="navFilterSelection">
    <a #anchor (click)="onToggle()" class="persona-button relativity-fix">
        <div class="menuText1">{{menuText1}}</div>
        <div class="menuText2">{{menuText2}}</div>
    </a>
    <kendo-popup #popup [anchor]="anchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" (anchorViewportLeave)="menuViable = false" *ngIf="menuViable">
        <div class='content'>
            <div *ngFor="let filter of filterSelections">
                <div class="list-item">{{filter.key}}: {{filter.value}}</div>
            </div>
        </div>
    </kendo-popup>
</a>
