import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from '../../../decorators/component-lookup';
import { TileComponent } from '../../../tiles/base/tile.component';
import { RenderEvent } from '@progress/kendo-angular-charts';
import { Rect, Circle as CircleGeometry } from '@progress/kendo-drawing/geometry';
import { Group, Path, Layout, Text } from '@progress/kendo-drawing';

@ComponentLookup('BrStackedBarLineChartComponent')
@Component({
   selector: 'app-br-stacked-bar-line-chart',
   templateUrl: './br-stacked-bar-line-chart.component.html',
   styleUrls: ['./br-stacked-bar-line-chart.component.scss']
})

export class BrStackedBarLineChartComponent extends TileComponent implements OnInit {
  axis1LabelSegments = 4;
  axis2LabelSegments = 4;
  yAxisLabel = '';


  defaultPreferences() {
    return {
      periodsToShow: 4,
      showTitle: true,
      valueAxis1Label: '{0}',
      valueAxis2Label: '{0}'
    };
  }

  translateApiDataForChart(apiData) {
    if (apiData.yAxisLabel) { this.yAxisLabel = apiData.yAxisLabel; }

    const chartStackSeries = [{
      name: '',
      color: '#3498DB',  // '#ff0000',
      type: 'column',
      gap: .5,
      border: {
        color: '#000',
        width: 1,
        dashType: 'solid'
      },
      data: [],
      axis: 'axis1',
      tooltip: {
        visible: true,
        format: '{0}',
        background: '#aa0061',
        padding: '2'
      }
    }];

    const chartCategories = [];

    let maxValue = 0;
    let minValue = 0;
    let markUnits = 10;

    let values = [];
    if ('bar_values' in apiData) {
      values = apiData.bar_values;
    } else if ('values' in apiData) {
      values = apiData.values;
    }
    for (const row of values.reverse()) {
      chartCategories.push(row.key);
      const intValue = parseInt(row.value, 10);
      if (intValue > maxValue) {
        maxValue = intValue;
      }
      chartStackSeries[0].data.push(intValue);
      // stacked bar
      /*
      for (chartDataItemKey = 0; chartDataItemKey < chartDataColumn.stacks.length; chartDataItemKey++) {
        const chartDataItem = chartDataColumn.stacks[chartDataItemKey];
        const intValue = parseInt(chartDataItem, 10);

        chartStackSeries[chartDataItemKey].data.push(intValue);
      }*/

    }

    const zero = '0';
    const valueSize = maxValue.toString().length;
    const roundSizeStr = '1'.padEnd(valueSize, zero);
    const roundSize = parseInt(roundSizeStr, 10);

    markUnits = roundSize;
    maxValue = this.roundUp(maxValue * 1.2, roundSize);
    if (apiData.chartTopLimit) { maxValue = apiData.chartTopLimit; }
    if (apiData.chartBottomLimit) { minValue = apiData.chartBottomLimit; }
    if (this.apiData.yAxisMajorUnit) { markUnits = this.apiData.yAxisMajorUnit; }

    // fix to allow a max of 14 data y axis labels on chart
    const markUnitsPointCount = maxValue / markUnits;
    if (markUnitsPointCount > 14) {
      const markUnitsFactor = this.roundUp(markUnitsPointCount / 14, 1);
      markUnits = markUnits * markUnitsFactor;
    }

    // console.log(chartCategories);
    // console.log(chartSeries);
    const valueAxis1 = {
      name: 'axis1',
      labels: {
        // https://github.com/telerik/kendo-intl/blob/master/docs/num-formatting/index.md
        // cannot currently add format of #K, add issue to https://github.com/telerik/kendo-intl/issues
        format: '{0}',
        content: (e) => {
          return e.value;
        },
        position: 'start',
        visible: true
      },
      max: maxValue,
      min: minValue,
      majorUnit: markUnits,
      line: {
        visible: false
      },
      title: {
        text: this.yAxisLabel,
        color: '#000000'
      },
      tooltip: {
        visible: true,
        format: '{0}',
        background: '#aa0061',
        padding: '2'
      }
    };


    this.chartOptions = {
      seriesDefaults: {
        type: 'column',
        stack: true
      },
      series: chartStackSeries,
      chartArea: {
        border: {
          color: '#000',
          width: 1,
          dashType: 'solid'
        }
      },
      valueAxis: [valueAxis1], // removed valueAxis2
      categoryAxis: {
        categories: chartCategories,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45
        }
      }
    };
  }

  roundUp(value, toTheNearest) {
    return Math.ceil(value / toTheNearest) * toTheNearest;
  }

  isDataFound(value) {
    const dataFound = (value !== undefined && value !== null && (Array.isArray(value.values) && value.values.length > 0));
    this.nodatacheckService.setHasData(this.tileReference.apiSource, dataFound);
    return dataFound;
  }
}
