import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import {RequestState} from '../../api/request-handler';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ApiService} from '../../api/api.service';

@Component({
  selector: 'app-br-template-viewer',
  templateUrl: './br-template-viewer.component.html',
  styleUrls: ['./br-template-viewer.component.scss']
})
export class BrTemplateViewerComponent implements OnInit, OnDestroy {
  public stdKpiListData = null;
  protected requestHandler;
  protected stdKpiListSource = '/qbr/template/std/std_kpi_list';
  protected refreshHandleKey;
  public state = RequestState.Inactive;
  public templateStringOrder = null;
  public newListOrder = [];

  @Input() set templateString( value: string ) {
    this.templateStringOrder = value.split(',');
    this.buildListOrder();
  }

  constructor(
    private apiService: ApiService,
    protected refreshHandlerService: RefreshHandlerService,
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.callApiSource(this.stdKpiListSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.stdKpiListData = apiData;
        this.newListOrder = apiData.items;
        this.buildListOrder();
      }
    });
    this.refreshHandleKey = this.refreshHandlerService.onRefreshEvent((index) => {
      this.requestHandler.sendRequest(this.stdKpiListSource); // , null, true
    }, this.requestHandler.getRelativeParams(this.stdKpiListSource));
  }

  ngOnDestroy(): void {
    this.refreshHandlerService.cancelRefreshEvent(this.refreshHandleKey);
    this.requestHandler.cancelSubscription();
  }

  buildListOrder() {
    if (this.stdKpiListData !== null && this.templateStringOrder !== null && this.templateStringOrder.length > 1) {
      // order items
      this.newListOrder = [];
      for (const orderItem of this.templateStringOrder) {
        const item = this.getStdKpiListItem(orderItem);
        item.active = true;
        this.newListOrder.push(item);
      }
      // get items missing
      // for (const i in this.stdKpiListData.items) {
      //   if (!this.templateStringOrder.includes(this.stdKpiListData.items[i].id)) {
      //     this.stdKpiListData.items[i].active = false;
      //     this.newListOrder.push(this.stdKpiListData.items[i]);
      //   }
      // }
    }
  }

  getStdKpiListItem(id) {
    let returnValue = null;
    for (const i in this.stdKpiListData.items) {
      if (this.stdKpiListData.items[i].id == id) {
        returnValue = this.stdKpiListData.items[i];
      }
    }
    return returnValue;
  }

  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }
}
