<a class="nav-link" [ngClass]="{'menu-disabled': disabled}" *ngIf="navFilterSelection && navFilterSelection.personaSelector">
    <a #anchor (click)="onToggle()" class="persona-button relativity-fix">
        <div class="menuText1">{{menuText1}}</div>
        <div class="menuText2">{{menuText2}}</div>
    </a>
    <kendo-popup #popup [anchor]="anchor" [anchorAlign]="anchorAlign" [popupAlign]="popupAlign" (anchorViewportLeave)="menuViable = false" *ngIf="menuViable">
        <div class='content'>
            <div class="button-row" *ngIf="countActiveOptions(getMenuTabOptions()) > 1">
                <kendo-buttongroup [selection]="'single'" class="mx-auto" *ngIf="navFilterSelection.personaSelector">
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.TSS)"
                            (selectedChange)="onSelectedChange(PersonaTypes.TSS)"
                            *ngIf="isActiveOption(Personas.TssAmbassador)">TSS</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.Manager)"
                            (selectedChange)="onSelectedChange(PersonaTypes.Manager)"
                            *ngIf="isActiveOption(Personas.Manager)">Manager</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.CuaManager)"
                            (selectedChange)="onSelectedChange(PersonaTypes.CuaManager)"
                            *ngIf="isActiveOption(Personas.CuaManager)">Manager</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.ManagerOverview)"
                            (selectedChange)="onSelectedChange(PersonaTypes.ManagerOverview)"
                            *ngIf="isActiveOption(Personas.AmbManager) && (isAMBManager || isAMB || isSuperUser)">Ambassador<span *ngIf="isAMBManager">/Manager</span></button>
                    <!--<button kendoButton [toggleable]="true"-->
                            <!--[selected]="(selectedTab === PersonaTypes.CuaAmbManager || selectedTab === PersonaTypes.CuaAmb)"-->
                            <!--(selectedChange)="onSelectedChange(PersonaTypes.CuaAmbManager)"-->
                            <!--*ngIf="(isActiveOption(Personas.CuaAmbManager) || isActiveOption(Personas.CuaAmb)) && (isAMBManager || isAMB || isSuperUser)">Ambassador<span *ngIf="isAMBManager">/Manager</span></button>-->
                    <button kendoButton [toggleable]="true"
                            [selected]="selectedTab === PersonaTypes.AccountRep"
                            (selectedChange)="onSelectedChange(PersonaTypes.AccountRep)"
                            *ngIf="isActiveOption(Personas.AccountRep)">Account Rep</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.GEO)"
                            (selectedChange)="onSelectedChange(PersonaTypes.GEO)"
                            *ngIf="isActiveOption(Personas.GeoLocation) && (isSuperUser || isService)">Location</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.CtCustomer)"
                            (selectedChange)="onSelectedChange(PersonaTypes.CtCustomer)"
                            *ngIf="isActiveOption(Personas.CtCustomer)">Customer</button>
                    <button kendoButton [toggleable]="true"
                            [selected]="(selectedTab === PersonaTypes.CtAccount || selectedTab === PersonaTypes.Account)"
                            (selectedChange)="onSelectedChange(PersonaTypes.CtAccount)"
                            *ngIf="isActiveOption(Personas.CtAccount)">Account</button>
                </kendo-buttongroup>
            </div>
            <div class="tab-content">
                <app-tss-ambassador-selector class="tab"
                                             [class.active-tab]="(selectedTab === PersonaTypes.TSS)"
                                             *ngIf="isActiveOption(Personas.TssAmbassador)"></app-tss-ambassador-selector>
                <app-manager-selector class="tab"
                                      [class.active-tab]="(selectedTab === PersonaTypes.Manager)"
                                      *ngIf="isActiveOption(Personas.Manager)"></app-manager-selector>
                <app-cua-manager-selector class="tab"
                                          [class.active-tab]="(selectedTab === PersonaTypes.CuaManager)"
                                          *ngIf="isActiveOption(Personas.CuaManager)"></app-cua-manager-selector>
                <app-geo-location-selector class="tab"
                                           [class.active-tab]="(selectedTab === PersonaTypes.GEO)"
                                           *ngIf="isActiveOption(Personas.GeoLocation) && navFilterSelection.selectedDashboard !== DashboardOptions.ambassador"
                                           apiConfig="serviceMetrics"
                ></app-geo-location-selector>
                <app-manager-amb-selector class="tab"
                                          [class.active-tab]="(selectedTab === PersonaTypes.ManagerOverview)"
                                          *ngIf="isActiveOption(Personas.AmbManager) && (isAMBManager || isAMB || isSuperUser)"
                                          apiConfig="ambassador"
                ></app-manager-amb-selector>
                <!--<app-ct-manager-amb-selector class="tab"-->
                                          <!--[class.active-tab]="(selectedTab === PersonaTypes.CuaAmbManager || selectedTab === PersonaTypes.CuaAmb)"-->
                                          <!--*ngIf="(isActiveOption(Personas.CuaAmbManager) || isActiveOption(Personas.CuaAmb)) && (isAMBManager || isAMB || isSuperUser)"-->
                                          <!--apiConfig="ambassador"-->
                <!--&gt;</app-ct-manager-amb-selector>-->
                <app-account-rep  class="tab"
                                  [class.active-tab]="(selectedTab === PersonaTypes.AccountRep)"
                                  *ngIf="isActiveOption(Personas.AccountRep)"
                ></app-account-rep>
                <app-geo-location-selector class="tab"
                                           [class.active-tab]="(selectedTab === PersonaTypes.GEO)"
                                           *ngIf="isActiveOption(Personas.GeoLocation) && isSuperUser && navFilterSelection.selectedDashboard === DashboardOptions.ambassador"
                                           apiConfig="ambassador"
                ></app-geo-location-selector>
                <app-ct-customer-selector class="tab"
                                          [class.active-tab]="(selectedTab === PersonaTypes.CtCustomer)"
                                          *ngIf="isActiveOption(Personas.CtCustomer)"
                ></app-ct-customer-selector>
                <app-ct-account-selector class="tab"
                                         [class.active-tab]="(selectedTab === PersonaTypes.CtAccount) || (selectedTab === PersonaTypes.Account)"
                                         *ngIf="isActiveOption(Personas.CtAccount) || isActiveOption(Personas.Account)"
                ></app-ct-account-selector>
            </div>
        </div>
    </kendo-popup>
</a>
