import { Component, OnInit, OnDestroy } from '@angular/core';
import {RequestState} from '../../../api/request-handler';
import {ApiService} from '../../../api/api.service';
import {faCheck} from '@fortawesome/free-solid-svg-icons';
import {BrParamsService} from '../../br-params.service';

enum Selected {
  None = 0,
  Complete = 1,
  Partial = 2
}

@Component({
  selector: 'app-br-location-selector',
  templateUrl: './br-location-selector.component.html',
  styleUrls: ['./br-location-selector.component.scss']
})
export class BrLocationSelectorComponent implements OnInit, OnDestroy {
  data2 = null;
  protected requestHandler;
  protected apiSource = '/qbr/app_config/location/location';
  state = RequestState.Inactive;
  RequestState = RequestState;
  locationColumn = [];
  localLevelColumn = [];
  selectedOrg;
  selectedLocation;
  selectedLocalLevel;
  faCheck = faCheck;

  constructor(
    private apiService: ApiService,
    private brParamsService: BrParamsService
  ) { }

  ngOnInit(): void {
    this.requestHandler = this.callApiSource(this.apiSource, 0);
    this.requestHandler.onStateChange((state) => {
      if (state === RequestState.Complete) {
        const response = this.requestHandler.getResponse();
        const apiData = JSON.parse(response.body);
        this.data2 = apiData;
        this.selectedOrg = this.brParamsService.get('org');
        this.buildLocationMenu(this.selectedOrg);
        this.selectedLocation = this.brParamsService.get('location');
        this.buildLocalLevelMenu(this.selectedLocation);
        this.selectedLocalLevel = this.brParamsService.get('locallevel');
      }
    });
    this.brParamsService.getParamChangedListener().subscribe((brParamKey) => {
      if (brParamKey === 'org') {
        this.selectedOrg = this.brParamsService.get('org');
        this.selectedLocation = this.brParamsService.get('location');
        this.selectedLocalLevel = this.brParamsService.get('locallevel');
      }
    });
  }

  ngOnDestroy(): void {
    this.requestHandler.cancelSubscription();
  }


  callApiSource(apiSource, apiDataIndex) {
    const requestHandler = this.apiService.get(apiSource + '_' + apiDataIndex);
    requestHandler.onStateChange((state) => {
      this.state = state;
    });
    requestHandler.sendRequest(apiSource);

    return requestHandler;
  }

  getOrgItem(orgId) {
    for (const orgItem of this.data2.org_names.items) {
      if (orgItem.id === orgId) {
        return orgItem;
      }
    }
    return null;
  }

  getLocationItem(locationId) {
    for (const locationItem of this.data2.countries.items) {
      if (locationItem.id === locationId) {
        return locationItem;
      }
    }
    return null;
  }

  getLocalLevelItem(location, localLevelId) {
    for (const localLevelItem of location.relationships) {
      if (localLevelItem.id === localLevelId) {
        return localLevelItem;
      }
    }
    return null;
  }

  buildLocationMenu(forOrgId) {
    const orgItem = this.getOrgItem(forOrgId);
    this.locationColumn = orgItem.relationships;
    return this;
  }

  buildLocalLevelMenu(forLocationId) {
    const locationItem = this.getLocationItem(forLocationId);
    this.localLevelColumn = locationItem.relationships;
    return this;
  }

  buildLocationDescription() {
    const orgId = this.brParamsService.get('org');
    const org = this.getOrgItem(orgId);
    const locationId = this.brParamsService.get('location');
    const location = this.getLocationItem(locationId);
    const locallevelId = this.brParamsService.get('locallevel');
    const locallevel = this.getLocalLevelItem(location, locallevelId);

    this.brParamsService.set('location_description', org.display_name + ', ' + location.display_name + ', ' + locallevel.display_name);
  }

  selectOrg(orgId) {
    this.selectedOrg = orgId;
    this.buildLocationMenu(orgId);
    this.selectedLocation = '000';
    this.brParamsService.set('org', orgId);
    this.brParamsService.set('location', '000');
    this.buildLocationDescription();
    return this;
  }

  selectLocation(locationId) {
    this.buildLocalLevelMenu(locationId);
    this.selectedLocation = locationId;
    this.selectedLocalLevel = '000';
    this.brParamsService.set('location', locationId);
    this.brParamsService.set('locallevel', '000');
    this.buildLocationDescription();
    return this;
  }

  selectLocalLevel(localLevelId) {
    this.selectedLocalLevel = localLevelId;
    this.brParamsService.set('locallevel', localLevelId);
    this.buildLocationDescription();
  }
}
