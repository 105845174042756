import { RowParserInterface } from '../row-parser-interface';

export class PointValues implements RowParserInterface {
  get(sourceData, params) {
    const rowData = {
      description: params.label
    };

    for (const value of sourceData.values) {
      const key = value.xlabel;
      let pointValue = value.points[params.stackIndex];
      rowData[key] = pointValue;
    }
    return rowData;
  }
}
