<div class="container-fluid" *ngIf="config">
    <div class="row">
        <div class="col-12">
            <app-state-handler [requestState]="state" [data]="gridData">
            <kendo-grid
                [data]="gridData"
                [pageable]="true"
                [pageSize]="gridState.take"
                [skip]="gridState.skip"
                [filter]="gridState.filter"
                filterable="menu"
                [sort]="gridState.sort"
                [sortable]="true"
                [ngStyle]="{'max-height': getMaxHeight()}" 
                [resizable]="true"
                (pageChange)="pageChange($event)"
                (sortChange)="sortChange($event)"
                (filterChange)="filterChange($event)"
                (cellClick)="onCellClick($event)">            
                <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData">
                    <ng-container *ngFor="let col of dataCols">
                        <kendo-excelexport-column [field]="col.ref" [title]="col.description" *ngIf="col.export"></kendo-excelexport-column>
                    </ng-container>
                </kendo-grid-excel>
                <ng-template kendoGridToolbarTemplate>
                    <!--<kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>-->
                    <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                    <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
                </ng-template>
                <ng-container *ngFor="let col of dataCols">
                    <kendo-grid-column
                            [field]="col.ref"
                            [title]="col.description"
                            [width]="col.width"
                            [hidden]="col.hidden"
                            [filterable]="(col.filterable === false) ? false: true"
                            [headerClass]="'data-grid-header'">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                                <ng-container *ngIf="col.renderRefStyle === true; else two">
                                    <span class="whole-cell" style="color:#007bff; font-weight: bold;">{{dataItem[col.ref]}}</span>
                                </ng-container>
                                <ng-template #two>
                                    <ng-container *ngIf="col.renderCustomerNameNumStyle === true; else three">
                                        <!-- make sure add customernumber column as hidden column before customer name 
                                            if both needs to be combined and presented as one column -->
                                        <span class="whole-cell">{{dataItem[col.ref]}}&nbsp;({{dataItem['customernumber']}})</span>
                                    </ng-container>
                                </ng-template>
                                <ng-template #three>
                                    <ng-container *ngIf="col.entitlementLink !== undefined; else four">
                                        <a [routerLink]="[col.entitlementLink, dataItem['customernumber'], dataItem['instrumentsrno'], dataItem['contractno']]" 
                                                style="font-weight: 500; font-size: 12pt; color: #007bff">More</a>                                
                                    </ng-container>
                                </ng-template>
                                <ng-template #four>
                                    <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                </ng-template>
                            </ng-template> 
                    </kendo-grid-column>
                </ng-container>
            </kendo-grid>
            </app-state-handler>
            <!-- https://www.telerik.com/kendo-angular-ui/components/grid/how-to/context-menu/ -->
            <kendo-popup *ngIf="showContextMenu" [offset]="offset">
                <ul class='menu'>
                    <li *ngFor="let item of menuItems" (click)="menuItemSelected(item)">
                        <span style='padding-right: 8px' *ngIf="item.iconUrl">
                            <img  width='15' height="15" src="{{item.iconUrl}}" />
                        </span>
                        <a href="{{item.textLink}}" target="blank">{{item.text}}</a>                
                    </li>
                </ul>
            </kendo-popup>
        </div>
    </div>
</div>
