import { Injectable, Injector } from '@angular/core';
import {RequestHandler} from './request-handler';
import {ParameterResolverService} from './parameter-resolver.service';
import {HttpClient} from '@angular/common/http';
import {AuthenticationService} from '../authentication/authentication.service';
import {CachedResponseRepoService} from './cached-response-repo.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  requestHandlers = {};

  constructor(
    private injector: Injector
  ) {
    // this.auth.isAuthenticated();
  }

  // cancelSubscriptions() {
  //   for (const requestHandler in this.requestHandlers) {
  //     if (this.requestHandlers.hasOwnProperty(requestHandler)) {
  //       this.requestHandlers[requestHandler].cancelSubscription();
  //     }
  //   }
  // }

  get(ref: string): RequestHandler {
    if (!this.requestHandlers.hasOwnProperty(ref)) {
      this.requestHandlers[ref] = new RequestHandler(
        this.injector,
        ref
      );
    }
    return this.requestHandlers[ref];
  }
}
