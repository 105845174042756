<div class="container-fluid" *ngIf="gridData">
    <div class="row">
        <div class="col-12">
            <app-state-handler [requestState]="state" [data]="gridData">
                <kendo-grid
                    [data]="gridData"
                    [pageable]="true"
                    [pageSize]="gridState.take"
                    [skip]="gridState.skip"
                    [filter]="gridState.filter"
                    filterable="menu"
                    [sort]="gridState.sort"
                    [sortable]="true"
                    [ngStyle]="{'max-height': getMaxHeight()}" 
                    [resizable]="true"
                    (pageChange)="pageChange($event)"
                    (sortChange)="sortChange($event)"
                    (filterChange)="filterChange($event)">

                    <kendo-grid-excel [fileName]="config.table.exportFileName" [fetchData]="allData"></kendo-grid-excel>
                    <ng-template kendoGridToolbarTemplate>
                        <!--<kendo-grid-column-chooser autoSync='true'></kendo-grid-column-chooser>-->
                        <button type="button" kendoGridExcelCommand icon="file-excel">Export</button>
                        <!-- <a class="k-button k-button-icontext" href="javascript:void(0);" (click)="forceDataReload()"><span class="k-icon k-i-refresh"></span>Refresh</a> -->
                    </ng-template>
                    <ng-container *ngFor="let col of dataCols">
                        <kendo-grid-column
                                [field]="col.ref"
                                [title]="col.description"
                                [width]="col.width"
                                [hidden]="col.hidden"
                                [filterable]="(col.filterable === false) ? false: true"
                                [headerClass]="'data-grid-header'"
                                [style]="col.customStyle">
                                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" let-columnIndex="columnIndex">
                                    <span class="whole-cell">{{dataItem[col.ref]}}</span>
                                </ng-template>
                        </kendo-grid-column>
                    </ng-container>
                </kendo-grid>
            </app-state-handler>
        </div>
    </div>
</div>
