import { Component, OnInit } from '@angular/core';
import {GridComponent} from '../grid.component';
import {RequestState} from '../../api/request-handler';
import {ApiService} from '../../api/api.service';
import {Location} from '@angular/common';
import {Style1Pages} from '../../configuration/details/style1-pages';
import {PreferenceApiConnectorService} from '../../preferences/preference-api-connector.service';

@Component({
  selector: 'app-set-values-grid',
  templateUrl: './set-values-grid.component.html',
  styleUrls: ['./set-values-grid.component.scss']
})
export class SetValuesGridComponent extends GridComponent implements OnInit {
  cellFormatting = [];

  style1Pages = Style1Pages;

  state;
  config = null;

  constructor(
    protected location: Location,
    protected apiService: ApiService,
    protected preferenceApiConnectorService: PreferenceApiConnectorService
  ) {
    super();
  }

  ngOnInit(): void {
    const currentRouterLocation = this.location.path();
    if (this.style1Pages.hasOwnProperty(currentRouterLocation)) {
      this.config = this.style1Pages[currentRouterLocation];

      const requestHandler = this.apiService.get(this.config.apiRequestHandler);

      // no need to send request here as chart tile has already done so
      requestHandler.onStateChange((state) => {
        this.state = state;
        this.buildGridData();
      });

      // override for currency preference
      if (this.config.tileReference.apiSource === '/metrics/rcs') {
        this.preferenceApiConnectorService.onUpdate(() => {
          this.buildGridData();
        });
      }
    }
  }

  buildGridData() {
    if (this.state === RequestState.Complete) {
      // rcs: amtdollar, amtlocal
      let isLocal = false;

      if (this.config.tileReference.apiSource === '/metrics/rcs') {
        if (this.preferenceApiConnectorService.getIsLocalCurrency() === 'True') {
          isLocal = true;
        }
      }

      if (this.state === RequestState.Complete) {
        const requestHandler = this.apiService.get(this.config.apiRequestHandler);
        const response = requestHandler.getResponse();
        const apiData = JSON.parse(response.body);

        // FORMAT API DATA FOR TABLE
        // format dataCols for kendo component
        if (isLocal && this.config.tileReference.apiSource === '/metrics/rcs') {
          this.dataCols = this.config.table.dataColsLocal;
        } else {
          this.dataCols = this.config.table.dataCols;
        }

        // format dataRows for kendo component
        if (isLocal && this.config.tileReference.apiSource === '/metrics/rcs') {
          this.dataRows = [this.config.table.dataValuesLocal(apiData)];
        } else {
          this.dataRows = [this.config.table.dataValues(apiData)];
        }
        for (const dataRowKey in this.dataRows) {
          if (dataRowKey in this.dataRows) {
            const dataRow = this.dataRows[dataRowKey];
            for (const rowCol in dataRow) {
              if (rowCol in dataRow) {
                if (this.numberType(dataRow[rowCol]) === 'int') {
                  this.dataRows[dataRowKey][rowCol] = parseInt(dataRow[rowCol]);
                } else if (this.numberType(dataRow[rowCol]) === 'float') {
                  this.dataRows[dataRowKey][rowCol] = parseFloat(dataRow[rowCol]);

                }
              }
            }
          }
        }

        // setup data for formatting row colors
        this.cellFormatting = this.config.table.valueColors(apiData);

        this.updateGrid();
      }
    }
  }

  numberType(value) {
    if (isNaN(value)) {
      return 'other';
    }
    if (Math.floor(value) === value) {
      return 'int';
    } else {
      return 'float';
    }
  }

  // https://www.telerik.com/kendo-angular-ui/components/grid/styling
  cellStyle(cellIndex) {
    return {
      'color': this.cellFormatting[cellIndex],
      'text-align': 'right',
      'display': 'block'
    };
  }
}
