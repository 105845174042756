import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({name: 'abbottBigNumber'})
export class AbbottBigNumberFormatterPipe implements PipeTransform {
  transform(value: string, precision: number = 1): string {
    if (!isNaN(Number(value))) { // is a number
      let floatValue = parseFloat(value);
      if (floatValue >= 1000000000 || floatValue <= -1000000000) {
        floatValue = _.round(floatValue/1000000000, precision);
        value = floatValue + ' MMM';
      } else if (floatValue >= 1000000 || floatValue <= -1000000) {
        floatValue = _.round(floatValue/1000000, precision);
        value = floatValue + ' MM';
      } else if (floatValue >= 1000 || floatValue <= -1000) {
        floatValue = _.round(floatValue/1000, precision);
        value = floatValue + ' M';
      }
    }
    return value;
  }
}
