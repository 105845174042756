import { Component, OnInit } from '@angular/core';
import { ComponentLookup } from 'src/app/decorators/component-lookup';
import { TileComponent } from '../tile.component';
// import * as _ from 'lodash';

@ComponentLookup('AreaLineChartComponent')
@Component({
  selector: 'app-area-line-chart',
  templateUrl: './area-line-chart.component.html',
  styleUrls: ['./area-line-chart.component.scss']
})
export class AreaLineChartComponent extends TileComponent implements OnInit {
  translateApiDataForChart(apiData) {
    // override for currency preference
    if (this.tileReference.apiSource === '/metrics/sales') {
      this.preferenceApiConnectorService.onUpdate(() => {
        this.buildChartOptions(apiData);
      });
    } else {
      this.buildChartOptions(apiData);
    }
  }

  buildChartOptions(apiData) {
    // console.log(apiData);

    // 0 if local, 2 if not local
    // cumulativeLocal, MonthlyLocal, Cumulative Dollar, MonthlyDollar
    let offset = 0;

    if (this.tileReference.apiSource === '/metrics/sales') {
      const isLocal = this.preferenceApiConnectorService.getIsLocalCurrency();
      if (isLocal === 'True') {
        offset = 0;
      } else if (isLocal === 'False') {
        offset = 2;
      }
    }

    const chartSeries = [];
    const seriesColorsList = [];

    for (const chartLineColor of apiData.linecolors) {
      if (chartLineColor && chartLineColor.length > 0) {
        seriesColorsList.push(chartLineColor);
        chartSeries.push({
          name: '',
          color: chartLineColor,
          border: {
            color: '#000',
            width: 1,
            dashType: 'solid'
          },
          markers: { visible: true, background: chartLineColor  },
          data: []
          // axis: 'axis1'
        });
      }
    }

    let chartData = apiData.values;
    if ('periodsToShow' in this.preferences) {
      // only use the last x periods in the chart
      chartData = chartData.slice(Math.max(chartData.length - this.preferences.periodsToShow, 1));
    }

    let currencyDivideVal = (1000 * 1000);
    let currencyFormat = '{0}MM';
    loop1:
      for (const chartDataObj of chartData) {
        // loop2:
          for (let dataItemKey = offset; dataItemKey < chartSeries.length + offset; dataItemKey++) {
            const chartDataItem = chartDataObj.points[dataItemKey];
            const floatValue = parseFloat(chartDataItem);
            if (floatValue >= 1000000000 || floatValue <= -1000000000) {
              currencyDivideVal = (1000 * 1000 * 1000);
              currencyFormat = '{0}MMM';
              break loop1;
            }
          }
      }

    for (const chartDataObj of chartData) {
      for (let dataItemKey = offset; dataItemKey < chartSeries.length + offset; dataItemKey++) {
        const chartDataItem = chartDataObj.points[dataItemKey];
        const roundVal = (parseFloat(chartDataItem) / currencyDivideVal).toFixed(1);
        chartSeries[dataItemKey - offset].data.push(roundVal);
      }
    }
    // console.log(chartCategories);
    // console.log(chartSeries);

    this.chartOptions = {
      seriesColors: seriesColorsList,
      seriesDefaults: {
        type: 'area',
        area: {
          line: {
            style: 'smooth'
          }
        }
      },
      series: chartSeries,
      valueAxis: {
        labels: {
          format: currencyFormat
        },
        line: {
          visible: false
        },
        title: {
          text: apiData.yaxisleftlabel
        },
        axisCrossingValue: -10
      },
      categoryAxis: {
        // categories: chartCategories,
        majorGridLines: {
          visible: false
        },
        labels: {
          rotation: -45
        }
      }
    };
  }
}
