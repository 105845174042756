<app-state-handler [requestState]="state" [data]="apiData" [showTitle]="preferences.showTitle" [title]="preferences.title">
    <div *ngIf="apiData" class="tile-chart-container">
        <div *ngIf="preferences.useDataArray===true; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
            <div *ngFor="let apiDataVal of apiData; index as i; last as isLast">
                <div *ngFor="let dataVal of apiDataVal.data; index as idx; last as isLast">
                    <a [routerLink]="detailLinkExist(idx) ? [preferences.detailLinks[idx]] : []">
                        <div *ngIf="preferences.showTitle===false; then noTitleBlock else titleBlock"></div>
                        <ng-template #noTitleBlock>
                            <!-- <div class="{{apiDataVal.visualization}}" style="color: {{dataVal.color}}"> -->
                                <h3>{{dataVal.label}}</h3>
                                <div class="billboard" style="color: {{dataVal.color}}">{{dataVal.displayvalue}}</div>
                            <!-- </div> -->
                        </ng-template>
                        <ng-template #titleBlock>
                            <h3 *ngIf="idx == 0">{{apiDataVal.title}}</h3>
                            <span *ngIf="idx == 0 && 'preSpacing' in preferences">
                                <br *ngFor="let i of [].constructor(preferences.preSpacing)" />
                            </span>
                            <div class="{{apiDataVal.visualization}}" style="color: {{dataVal.color}}">
                                <span class="textLabel">{{dataVal.label}}</span>
                                <span class="textLabel">{{dataVal.displayvalue}}</span>
                            </div>
                        </ng-template>
                    </a>
                    <span *ngIf="!isLast"><hr class="tile_separator" /></span>
                </div>
            </div>
        </ng-template>
        <ng-template #elseBlock>
            <div *ngFor="let dataVal of apiData; index as idx; last as isLast">
                <a [routerLink]="detailLinkExist(idx) ? [preferences.detailLinks[idx]] : []">
                    <h3>{{dataVal.title}}</h3>
                    <div class="{{dataVal.visualization}}" style="color: {{dataVal.color}}">{{dataVal.displayvalue}}</div>
                    <span *ngIf="!isLast"><hr class="tile_separator" /></span>
                    <!-- <div *ngIf="dataVal.label !== ''">
                        <span class="textLabel" style="color: {{dataVal.color}}">{{dataVal.label}}</span>
                    </div> -->
                </a>
            </div>
        </ng-template>
    </div>
</app-state-handler>