import { Component, OnInit } from '@angular/core';
import {TileReference} from '../tile-reference';
import {AuthenticationService} from '../../authentication/authentication.service';
import {NavbarBuilderService} from '../../navigation/navbar-builder.service';
import {Personas} from '../../configuration/personas';
import {SERVICE_METRIC_TILE_REFERENCE_DEFAULT_ARRANGEMENT} from '../../configuration/service-metric-tile-reference-default-arrangement';
import {RefreshHandlerService} from '../../api/refresh-handler.service';
import {ToolSelectionOptions} from '../../configuration/nav-filter-selection';
import {DashboardOptions} from '../../configuration/nav-filter-selection';
import {PersonaService} from '../../api/persona.service';
import {SelectedTabService} from '../selected-tab.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {
  arrangement: TileReference[] = [];

  constructor(
    private authService: AuthenticationService,
    private navbarBuilderService: NavbarBuilderService,
    private personaService: PersonaService,
    private selectedTabService: SelectedTabService
  ) { }

  ngOnInit(): void {
    // remove apiSource: '/metrics/fsrutilization',
    this.arrangement = SERVICE_METRIC_TILE_REFERENCE_DEFAULT_ARRANGEMENT.map((x) => x);
    for (let x = 0; x < this.arrangement.length; x++) {
      const item = this.arrangement[x];
      if (item.apiSource === '/metrics/fsrutilization') {
        this.arrangement.splice(x, 1);
      }
    }
    this.personaService.setServiceVsAmbState(true);
    this.personaService.refreshDetailChoices();
    this.navbarBuilderService.setNavbarChoice('dashboard');
    this.navbarBuilderService.setNavFilterSelection({
      preferences: true,
      personaSelector: true,
      dashboardSelector: true,
      selectedDashboard: DashboardOptions.customer,
      search: true,
      toolSelection: ToolSelectionOptions.mycrl,
      legacyCustomersFlag: true,
      myVsAllInstrumentsFlag: true,
      customerWTrackSystemsFlag: true,
      flagshipProductLinesFlag: true,
      bookmarksFlag: true,
      navigateBackwards: false,
      navigateBackwardsLabel: 'Back',
      viewTitle: false,
      viewTitleText: '',
      viewSubtitle: false,
      viewSubtitleText: '',
      filtered: false,
      personaOptions: [Personas.TssAmbassador, Personas.Manager, Personas.GeoLocation, Personas.Customer]
    });
    this.selectedTabService.selectTab(DashboardOptions.customer);
  }
}
